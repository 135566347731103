import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneSavingPlanApiCallExecutor from '../../../savingPlan/item/api/FortuneSavingPlanApiCallExecutor';

import FortuneSavingRate from '../../model/FortuneSavingRate';
import FortuneSavingRateApi from '../../api/FortuneSavingRateApi';
import FortuneSavingRateUrlPath from '../FortuneSavingRateUrlPath';

import FortuneSavingPlan from '../../../savingPlan/item/model/FortuneSavingPlan';

import CreateUtils from '../../../../commons/ui/page/template/create/CreateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:   boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item:        FortuneSavingRate;
    SavingPlans: FortuneSavingPlan[];
}

class Create extends Component<IProps, IState> {
    private apiCalls:    ApiCallExecutor;
    private api:     FortuneSavingRateApi;
    private form:        Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.state = {
            isLoading:   true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item:        new FortuneSavingRate(),
            SavingPlans: []
        };

        this.apiCalls = new ApiCallExecutor(this);

        this.api = FortuneSavingRateApi.getInstance();

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            FortuneSavingRateUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private fortuneSavingPlanApiCallExecutor: FortuneSavingPlanApiCallExecutor<Create>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneSavingPlanApiCallExecutor = 
                new FortuneSavingPlanApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneSavingPlanGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneSavingPlanGetAll() : IApiCallback {
        return {
            callback: this.fortuneSavingPlanApiCallExecutor.getAll,
            pointerToApiCall: this.fortuneSavingPlanApiCallExecutor,
            variableNameInState: "SavingPlans"
        };
    }
}

export default Create;