import Country from "../../../../general/country/model/Country";
import LetterPersonSalutation from "../../../person/salutation/model/LetterPersonSalutation";
import LetterPersonTitle from "../../../person/title/model/LetterPersonTitle";

class LetterAddresseeCompany {
    id!: number;
    name!: string;
    department!: string;
    contactSalutation!: LetterPersonSalutation;
    contactTitle!: LetterPersonTitle;
    contactFirstName!: string;
    contactLastName!: string;
    street!: string;
    houseNumber!: string;
    zip!: string;
    city!: string;
    postOfficeBox!: string;
    country!: Country;

    public constructor() {
        this.contactSalutation = new LetterPersonSalutation();
        this.contactTitle = new LetterPersonTitle();
        this.country = new Country();
    }
}

export default LetterAddresseeCompany;