import Earning from '../../model/Earning';
import EarningGroup from '../../../group/model/EarningGroup';
import EarningApi from '../../api/EarningApi';
import EarningGroupApi from '../../../group/api/EarningGroupApi';
import EarningsExpensesUrlPath from '../../../../item/ui/EarningsExpensesUrlPath';
import Utils from '../../../../../utilities/Utils';
import UpdateBaseWithGroup from '../../../../../commons/ui/page/template/update/UpdateBaseWithGroup';
import Form from './Form';
import FormButtonUpdate from '../../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBaseWithGroup<Earning, EarningGroup,
                                         EarningApi, EarningGroupApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated =
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new Earning(),
              EarningApi.getInstance(),
              EarningGroupApi.getInstance(),
              idOfItemToBeUpdated,
              EarningsExpensesUrlPath.BASE
        );
        
        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;