import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "fortuneSavingRate";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static TITLE_CREATE_ALL_VIA_WIZARD = "titleCreateAllViaWizard";
    public static TITLE_SHORT_CREATE_ALL_VIA_WIZARD = "titleShortCreateAllViaWizard";

    public static SUM = "sum";

    public static FORM_SAVING_PLAN = "form.savingPlan";
    public static FORM_DESCRIPTION = "form.description";
    public static FORM_AMOUNT = "form.amount";
    public static FORM_DATE = "form.date";
    public static FORM_CURRENT_QUOTATION = "form.currentQuotation";
    public static FORM_INVESTMENT_AMOUNT = "form.investmentAmount";
    public static FORM_SKIP_SAVING_RATE = "form.skipSavingRate";

    public static DIAGRAM_DATE = "diagram.date";
    public static DIAGRAM_AMOUNT = "diagram.amount";

    public static OVERVIEW_CURRENT_PERCENTAGE_TO_MIN_AMOUNT = "overview.currentPercentageToMinAmount";
    public static OVERVIEW_CURRENT_PERCENTAGE_TO_MAX_AMOUNT = "overview.currentPercentageToMaxAmount";
    public static OVERVIEW_CURRENT_AMOUNT = "overview.currentAmount";
    public static OVERVIEW_SAVING_RATE_PLANNED = "overview.savingRatePlanned";
    public static OVERVIEW_MINIMUM_AMOUNT_PLANNED = "overview.minimumAmountPlanned";
    public static OVERVIEW_MAXIMUM_AMOUNT_PLANNED = "overview.maximumAmountPlanned";
    public static OVERVIEW_RESIDUAL_AMOUNT_TO_MIN_AMOUNT = "overview.residualAmountToMinAmount";
    public static OVERVIEW_RESIDUAL_AMOUNT_TO_MAX_AMOUNT = "overview.residualAmountToMaxAmount";
    public static OVERVIEW_REMAINING_MONTHS = "overview.remainingMonths";
    public static OVERVIEW_NECESSARY_SAVING_RATE_IN_REMAINING_TIME_FOR_MIN_AMOUNT = "overview.necessarySavingRateInRemainingTimeForMinAmount";
    public static OVERVIEW_NECESSARY_SAVING_RATE_IN_REMAINING_TIME_FOR_MAX_AMOUNT = "overview.necessarySavingRateInRemainingTimeForMaxAmount";
    public static OVERVIEW_PRIORITY = "overview.priority";
    public static OVERVIEW_PLANNED_END_DATE = "overview.plannedEndDate";
    public static OVERVIEW_CALCULATED_END_DATE_FOR_MIN_AMOUNT_WITH_PLANNED_SAVING_RATE = "overview.calculatedEndDateForMinAmountWithPlannedSavingRate";
    public static OVERVIEW_CALCULATED_END_DATE_FOR_MAX_AMOUNT_WITH_PLANNED_SAVING_RATE = "overview.calculatedEndDateForMaxAmountWithPlannedSavingRate";
    public static OVERVIEW_ADD_TO_FORTUNE = "overview.addToFortune";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static TITLE_CREATE_ALL_VIA_WIZARD_DEFAULT = DefaultJson.titleCreateAllViaWizard;
    public static TITLE_SHORT_CREATE_ALL_VIA_WIZARD_DEFAULT = DefaultJson.titleShortCreateAllViaWizard;

    public static SUM_DEFAULT = DefaultJson.sum;

    public static FORM_SAVING_PLAN_DEFAULT = DefaultJson.form.savingPlan;
    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
    public static FORM_AMOUNT_DEFAULT = DefaultJson.form.amount;
    public static FORM_DATE_DEFAULT = DefaultJson.form.date;
    public static FORM_CURRENT_QUOTATION_DEFAULT = DefaultJson.form.currentQuotation;
    public static FORM_INVESTMENT_AMOUNT_DEFAULT = DefaultJson.form.investmentAmount;
    public static FORM_SKIP_SAVING_RATE_DEFAULT = DefaultJson.form.skipSavingRate;

    public static DIAGRAM_DATE_DEFAULT = DefaultJson.diagram.date;
    public static DIAGRAM_AMOUNT_DEFAULT = DefaultJson.diagram.amount;

    public static OVERVIEW_CURRENT_PERCENTAGE_TO_MIN_AMOUNT_DEFAULT = DefaultJson.overview.currentPercentageToMinAmount;
    public static OVERVIEW_CURRENT_PERCENTAGE_TO_MAX_AMOUNT_DEFAULT = DefaultJson.overview.currentPercentageToMaxAmount;
    public static OVERVIEW_CURRENT_AMOUNT_DEFAULT = DefaultJson.overview.currentAmount;
    public static OVERVIEW_SAVING_RATE_PLANNED_DEFAULT = DefaultJson.overview.savingRatePlanned;
    public static OVERVIEW_MINIMUM_AMOUNT_PLANNED_DEFAULT = DefaultJson.overview.minimumAmountPlanned;
    public static OVERVIEW_MAXIMUM_AMOUNT_PLANNED_DEFAULT = DefaultJson.overview.maximumAmountPlanned;
    public static OVERVIEW_RESIDUAL_AMOUNT_TO_MIN_AMOUNT_DEFAULT = DefaultJson.overview.residualAmountToMinAmount;
    public static OVERVIEW_RESIDUAL_AMOUNT_TO_MAX_AMOUNT_DEFAULT = DefaultJson.overview.residualAmountToMaxAmount;
    public static OVERVIEW_REMAINING_MONTHS_DEFAULT = DefaultJson.overview.remainingMonths;
    public static OVERVIEW_NECESSARY_SAVING_RATE_IN_REMAINING_TIME_FOR_MIN_AMOUNT_DEFAULT = DefaultJson.overview.necessarySavingRateInRemainingTimeForMinAmount;
    public static OVERVIEW_NECESSARY_SAVING_RATE_IN_REMAINING_TIME_FOR_MAX_AMOUNT_DEFAULT = DefaultJson.overview.necessarySavingRateInRemainingTimeForMaxAmount;
    public static OVERVIEW_PRIORITY_DEFAULT = DefaultJson.overview.priority;
    public static OVERVIEW_PLANNED_END_DATE_DEFAULT = DefaultJson.overview.plannedEndDate;
    public static OVERVIEW_CALCULATED_END_DATE_FOR_MIN_AMOUNT_WITH_PLANNED_SAVING_RATE_DEFAULT = DefaultJson.overview.calculatedEndDateForMinAmountWithPlannedSavingRate;
    public static OVERVIEW_CALCULATED_END_DATE_FOR_MAX_AMOUNT_WITH_PLANNED_SAVING_RATE_DEFAULT = DefaultJson.overview.calculatedEndDateForMaxAmountWithPlannedSavingRate;
    public static OVERVIEW_ADD_TO_FORTUNE_DEFAULT = DefaultJson.overview.addToFortune;
}

export default I18nConstants;