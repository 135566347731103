import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "fortuneInvestmentAim";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static FORM_DESCRIPTION = "form.description";
    public static FORM_PLANNED_AMOUNT = "form.plannedAmount";
    public static FORM_PLANNED_DATE = "form.plannedDate";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
    public static FORM_PLANNED_AMOUNT_DEFAULT = DefaultJson.form.plannedAmount;
    public static FORM_PLANNED_DATE_DEFAULT = DefaultJson.form.plannedDate;
}

export default I18nConstants;