import DrivingStyle from "../../drivingStyle/model/DrivingStyle";
import KindOfStreet from "../../kindOfStreet/model/KindOfStreet";
import Tire from "../../tire/model/Tire";
import Vehicle from "../../vehicle/item/model/Vehicle";

class FuelConsumption {
    id!: number;
    vehicle!: Vehicle;
    odometerMileage!: number;
    drivenKm!: number;
    mileage!: number;
    expense!: number;
    pricePerUnit!: number;
    date!: Date;
    airConditioningUsed!: boolean;
    tire!: Tire;
    kindOfStreet!: KindOfStreet;
    drivingStyle!: DrivingStyle;
    note!: string;

    public constructor() {
        this.vehicle             = new Vehicle();
        this.airConditioningUsed = false;
        this.tire                = new Tire();
        this.kindOfStreet        = new KindOfStreet();
        this.drivingStyle        = new DrivingStyle();
    }
}

export default FuelConsumption;