import PasswordListFolder from '../../folder/model/PasswordListFolder';

class PasswordList {
    id!: number;
    folder!: PasswordListFolder;
    description!: string;
    username!: string;
    password!: string;
    link!: string;
    notes!: string;

    public constructor() {
        this.folder = new PasswordListFolder();
    }
}

export default PasswordList;