import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import BikeModelMapper from '../model/BikeModelMapper';

class BikeApi implements IGenericApi {
    private static instance: BikeApi;

    private static apiUrlPath = "cyclingTour/bike";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): BikeApi {
        if (!BikeApi.instance) {
            BikeApi.instance = new BikeApi();
        }

        return BikeApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    BikeApi.apiUrlPath,
                    new BikeModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    BikeApi.apiUrlPath,
                    id,
                    new BikeModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    BikeApi.apiUrlPath,
                    body,
                    new BikeModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    BikeApi.apiUrlPath,
                    id,
                    body,
                    new BikeModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    BikeApi.apiUrlPath,
                    id,
                    new BikeModelMapper());
    }

    public getUrl() {
        return BikeApi.apiUrlPath;
    }
}

export default BikeApi;