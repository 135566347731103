import BankingListGroup from '../../model/BankingListGroup';
import BankingListGroupApi from '../../api/BankingListGroupApi';
import BankingListGroupUrlPath from '../../ui/BankingListGroupUrlPath';
import CreateBase from '../../../../commons/ui/page/template/group/create/CreateBase';

class Create extends CreateBase<BankingListGroup, BankingListGroupApi> {
    public constructor(props) {
        super(props,
              new BankingListGroup(),
              BankingListGroupApi.getInstance(),
              BankingListGroupUrlPath.BASE
        );
    }
}

export default Create;