import CreateBaseWithGroup from "../../../../commons/ui/page/template/create/CreateBaseWithGroup";
import FormButtonCreate from "../../../../commons/ui/page/template/form/button/FormButtonCreate";
import PasswordListFolderApi from "../../../folder/api/PasswordListFolderApi";
import PasswordListFolder from "../../../folder/model/PasswordListFolder";
import PasswordListApi from "../../api/PasswordListApi";
import PasswordList from "../../model/PasswordList";
import PasswordListUrlPath from "../PasswordListUrlPath";
import Form from "./Form";

class Create extends CreateBaseWithGroup<PasswordList, PasswordListFolder, 
                                         PasswordListApi, PasswordListFolderApi> {
    private form: Form;

    public constructor(props) {
        super(
            props,
            new PasswordList(),
            PasswordListApi.getInstance(),
            PasswordListFolderApi.getInstance(),
            PasswordListUrlPath.BASE
        );

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;