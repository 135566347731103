import I18nConstantsBase from '../../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "fuelConsumptionVehicleType";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;
}

export default I18nConstants;