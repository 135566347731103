import React from 'react';

import FormGroupDescription from '../../../../item/ui/fragment/FormGroupDescription';
import FormGroupGroupDropdown from '../../../../item/ui/fragment/FormGroupGroupDropdown';
import FormGroupAmount from '../../../../item/ui/fragment/FormGroupAmount';

class Form {
    private fromGroupDescription: FormGroupDescription;
    private formGroupGroup:       FormGroupGroupDropdown;
    private formGroupAmount:      FormGroupAmount;

    public constructor(props, pointerToComponent) {
        this.fromGroupDescription = new FormGroupDescription(props, pointerToComponent);
        this.formGroupGroup       = new FormGroupGroupDropdown(props, pointerToComponent);
        this.formGroupAmount      = new FormGroupAmount(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.fromGroupDescription.render() }
                { this.formGroupGroup.render() }
                { this.formGroupAmount.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.fromGroupDescription.isInvalidValue()
             || this.formGroupGroup.isInvalidValue()
             || this.formGroupAmount.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;