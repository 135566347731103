import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "shoppingListItem";

    public static TITLE = "title";

    public static CHECKBOX_IS_ADDED_TO_CHART = "checkboxIsAddedToChart";

    public static FORM_DESCRIPTION = "form.description";
    public static FORM_QUANTITY = "form.quantity";
    public static FORM_KIND_OF_QUANTITY = "form.kindOfQuantity";
    public static FORM_AMOUNT = "form.amount";
    public static FORM_GROUP = "form.group";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;

    public static CHECKBOX_IS_ADDED_TO_CHART_DEFAULT = DefaultJson.checkboxIsAddedToChart;

    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
    public static FORM_QUANTITY_DEFAULT = DefaultJson.form.quantity;
    public static FORM_KIND_OF_QUANTITY_DEFAULT = DefaultJson.form.kindOfQuantity;
    public static FORM_AMOUNT_DEFAULT = DefaultJson.form.amount;
    public static FORM_GROUP_DEFAULT = DefaultJson.form.group;
}

export default I18nConstants;