import EarningOverhead from '../../model/EarningOverhead';
import EarningOverheadApi from '../../api/EarningOverheadApi';
import EarningGroup from '../../../../../earningsExpenses/earning/group/model/EarningGroup';
import EarningGroupApi from '../../../../../earningsExpenses/earning/group/api/EarningGroupApi';
import EarningOverheadUrlPath from '../../../../item/ui/EarningsExpensesOverheadUrlPath';
import CreateBaseWithGroup from '../../../../../commons/ui/page/template/create/CreateBaseWithGroup';
import Form from './Form';
import FormButtonCreate from '../../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBaseWithGroup<EarningOverhead, EarningGroup, 
                                         EarningOverheadApi, EarningGroupApi> {
    private form: Form;

    public constructor(props) {
        super(props, 
              new EarningOverhead(),
              EarningOverheadApi.getInstance(),
              EarningGroupApi.getInstance(),
              EarningOverheadUrlPath.BASE);
              
        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;