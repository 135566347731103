import React from 'react';

import FormGroupPriority from '../fragment/FormGroupPriority';
import FormGroupDescription from '../fragment/FormGroupDescription';

import IForm from '../../../../commons/ui/page/template/form/IForm';

class Form implements IForm {
    private formGroupPriority:    FormGroupPriority;
    private formGroupDescription: FormGroupDescription;

    public constructor(props, pointerToComponent) {
        this.formGroupPriority    = new FormGroupPriority(props, pointerToComponent);
        this.formGroupDescription = new FormGroupDescription(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupPriority.render() }
                { this.formGroupDescription.render() }

                { renderButtonCallback() }
            </div>);
    }

    public isFormValid(): boolean {
        if (    this.formGroupPriority.isInvalidValue() 
             || this.formGroupDescription.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;