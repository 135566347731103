import React from 'react';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import I18nConstantsEarningsExpenses from '../../../../item/i18n/I18nConstants';
import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import EarningApiCallExecutor from '../../api/EarningApiCallExecutor';
import EarningGroupApiCallExecutor from '../../../group/api/EarningGroupApiCallExecutor';
import EarningsExpensesApiCallExecutor from '../../../../item/api/EarningsExpensesApiCallExecutor';
import Utils from '../../../../../utilities/Utils';
import EarningGroup from "../../../group/model/EarningGroup";
import EarningGroupApi from "../../../group/api/EarningGroupApi";
import OverviewBase from '../../../../../commons/ui/page/template/overview/OverviewBase';
import PieChartWithSumAndDiagram from '../../../../../commons/ui/page/diagram/PieChartWithSumAndPercentage';

class OverviewGroupedByMonthAndYear extends OverviewBase<EarningGroup, EarningGroupApi> {
    private apiCalls: ApiCallExecutor;
    private diagram: PieChartWithSumAndDiagram;

    public constructor(props) {
        super(props,
              EarningGroupApi.getInstance());

        this.diagram = new PieChartWithSumAndDiagram();
        this.apiCalls = new ApiCallExecutor(this, this.diagram);

        this.setShowNavbar(false);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> 
        );
    }

    protected getSum() {
        return (
            <div>
                <p>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                           i18nKey={I18nConstantsEarningsExpenses.SUM}>
                        {I18nConstantsEarningsExpenses.SUM_DEFAULT}
                    </Trans>
                    : {this.state.sum}
                </p>
            </div> 
        );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                           i18nKey={I18nConstantsEarningsExpenses.FORM_DESCRIPTION}>
                        {I18nConstantsEarningsExpenses.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                           i18nKey={I18nConstantsEarningsExpenses.FORM_PERCENTAGE}>
                        {I18nConstantsEarningsExpenses.FORM_PERCENTAGE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                           i18nKey={I18nConstantsEarningsExpenses.FORM_AMOUNT}>
                        {I18nConstantsEarningsExpenses.FORM_AMOUNT_DEFAULT}
                    </Trans>
                </th>
            </tr>
        );
    }

    protected getTableBody() {
        return this.state.List.map( item => 
            <tr key={(item as any).id}>
                <td>
                    {(item as any).name}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces((item as any).sum * 100 / this.state.sum)}
                </td>

                <td>
                    {(item as any).sum}
                </td>
            </tr> 
        );
    }

    protected getDiagramAfterContent() {
        return this.diagram.getHtmlElement(this.state.diagram);
    }

    protected getInitialValuesForListInState() {
        this.apiCalls.executeAll();
    }

    protected updateListInState(nextProps) {
        let currentYearAndMonthSelection = nextProps.currentYearAndMonthSelection;

        let monthAndYearObject = Utils.getMonthAndYearFromStringSeparatedBySlash(
                                            currentYearAndMonthSelection);

        if (monthAndYearObject) {
            this.apiCalls.executeStartingAfterMonthAndYearSelection(monthAndYearObject);
        } else {
            console.log("TODO");
        }
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: OverviewGroupedByMonthAndYear;
    private pointerToDiagram: PieChartWithSumAndDiagram;

    private helper: ApiCallExecutorHelper;

    private earningsExpensesApiCallExecutor: EarningsExpensesApiCallExecutor<OverviewGroupedByMonthAndYear>;
    private earningApiCallExecutor: EarningApiCallExecutor<OverviewGroupedByMonthAndYear>;
    private earningGroupApiCallExecutor: EarningGroupApiCallExecutor<OverviewGroupedByMonthAndYear>;

    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.earningsExpensesApiCallExecutor = new EarningsExpensesApiCallExecutor(pointerToComponent);
        this.earningApiCallExecutor = new EarningApiCallExecutor(pointerToComponent);
        this.earningGroupApiCallExecutor = new EarningGroupApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningsExpensesgGetYearsAndMonthsAsString());
        orderOfCalls.push(this.addEarningGroupGetSumAsListByMonthAndYear());
        orderOfCalls.push(this.addEarningGetSumByMonthAndYear());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
            this.pointerToComponent,
            this.helper, 
            orderOfCalls, 
            parameters
        );

        this.helper.startWithFirstCall(context);
    }

    public executeStartingAfterMonthAndYearSelection(monthAndYearObject) {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningGroupGetSumAsListByMonthAndYear());
        orderOfCalls.push(this.addEarningGetSumByMonthAndYear());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
            this.pointerToComponent,
            this.helper, 
            orderOfCalls, 
            parameters
        );

        this.helper.addParameterToContext(context, "monthAndYearObject", monthAndYearObject);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addEarningsExpensesgGetYearsAndMonthsAsString() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getYearsAndMonthsAsStringLastMonth,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: ""
        };
    }

    private addEarningGroupGetSumAsListByMonthAndYear() : IApiCallback {
        return {
            callback: this.earningGroupApiCallExecutor.getSumAsListByMonthAndYear,
            pointerToApiCall: this.earningGroupApiCallExecutor,
            variableNameInState: "List"
        };
    }

    private addEarningGetSumByMonthAndYear() : IApiCallback {
        return {
            callback: this.earningApiCallExecutor.getSumByMonthAndYear,
            pointerToApiCall: this.earningApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addDiagramData() : IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagram"
        };
    }
}

export default OverviewGroupedByMonthAndYear;