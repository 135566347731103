import I18nConstantsBase from '../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "logout";

    public static TITLE = "title";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
}

export default I18nConstants;