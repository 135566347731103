import React from 'react';
import { Component } from 'react';
import { Input, Button } from 'reactstrap';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import EarningsExpensesApiCallExecutor from '../../api/EarningsExpensesApiCallExecutor';

import Utils from '../../../../utilities/Utils';

import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';

import Navigation from '../../../../commons/ui/fragment/Navigation';

import EarningOverview from '../../../earning/item/ui/page/Overview';
import ExpenseOverview from '../../../expense/item/ui/page/Overview';

import EarningsExpensesOverheadActionBarButton from '../../../../earningsExpensesOverhead/item/ui/fragment/ActionBarButton';
import EarningsExpensesGroupedByMonthAndYearActionBarButton from '../fragment/ActionBarButtonGroupedByMonthAndYear';
import EarningsExpensesGroupedByYearActionBarButton from '../fragment/ActionBarGroupedByYear';
import EarningsExpensesEarningGroupActionBarButton from '../../../earning/group/ui/fragment/ActionBarButtonLongText';
import EarningsExpensesExpenseGroupActionBarButton from '../../../expense/group/ui/fragment/ActionBarButtonLongText';
import FortuneSavingRatioCreateActionBarButton from '../../../../fortune/savingRatio/ui/fragment/ActionBarButtonForCreate';
import EarningsExpensesPostOverheadCurrentMonthActionBarButton from '../fragment/dialog/ActionBarButtonPostOverheadCurrentMonth';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:                    boolean;
    yearsAndMonthsAsString:       string[];
    currentYearAndMonthSelection: string;
    sum:                          number;
    savingRatio:                  number;
}

class Overview extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;

    private overheadActionBarButton: EarningsExpensesOverheadActionBarButton;
    private groupedByMonthAndYearActionBarButton: EarningsExpensesGroupedByMonthAndYearActionBarButton;
    private groupedByYearActionBarButton: EarningsExpensesGroupedByYearActionBarButton;
    private earningGroupActionBarButton: EarningsExpensesEarningGroupActionBarButton;
    private expenseGroupActionBarButton: EarningsExpensesExpenseGroupActionBarButton;
    private fortuneSavingRatioCreateActionBarButton: FortuneSavingRatioCreateActionBarButton;
    private postOverheadCurrentMonthActionBarButton: EarningsExpensesPostOverheadCurrentMonthActionBarButton;

    private showNavbar:                boolean;

    public constructor(props) {
        super(props);

        this.apiCalls                  = new ApiCallExecutor(this);

        this.overheadActionBarButton = new EarningsExpensesOverheadActionBarButton(props.history);
        this.groupedByMonthAndYearActionBarButton = new EarningsExpensesGroupedByMonthAndYearActionBarButton(props.history);
        this.groupedByYearActionBarButton = new EarningsExpensesGroupedByYearActionBarButton(props.history);
        this.earningGroupActionBarButton = new EarningsExpensesEarningGroupActionBarButton(props.history);
        this.expenseGroupActionBarButton = new EarningsExpensesExpenseGroupActionBarButton(props.history);
        this.fortuneSavingRatioCreateActionBarButton = new FortuneSavingRatioCreateActionBarButton(props.history);
        this.postOverheadCurrentMonthActionBarButton = new EarningsExpensesPostOverheadCurrentMonthActionBarButton(props.history, props);

        this.showNavbar = true;

        this.state = {
            isLoading:                    true,
            yearsAndMonthsAsString:       [],
            currentYearAndMonthSelection: "",
            sum:                          0.0,
            savingRatio:                  0.0
        };

        this.handleChange = this.handleChange.bind(this);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (this.state.isLoading) {
            return LoadingScreen.render();
        } else {
            let selectValues = this.getGroupSelectValues();

            return (
                <div>
                    { this.getNavbar() }

                    <h1>
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.TITLE}>
                            {I18nConstants.TITLE_DEFAULT}
                        </Trans>
                    </h1>

                    <Input 
                        type="select"
                        name="monthsAndYears"
                        id="monthsAndYears"
                        value={this.state.currentYearAndMonthSelection}
                        onChange={this.handleChange}>
                        {selectValues}
                    </Input>

                    <p>
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.PROFIT_OR_LOSS}>
                            {I18nConstants.PROFIT_OR_LOSS_DEFAULT}
                        </Trans>
                        : {this.state.sum} 
                        <br />
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.SAVING_RATIO}>
                            {I18nConstants.SAVING_RATIO_DEFAULT}
                        </Trans>
                        : {this.state.savingRatio} %
                    </p>

                    { this.getActionBar() }

                    <EarningOverview history={this.props.history} location={this.props.location} 
                                     {...this.state} />
                    <ExpenseOverview history={this.props.history} location={this.props.location} 
                                     {...this.state} />
                </div>
            );
        }
    }

    private getNavbar() {
        if (this.showNavbar) {
            return ( <Navigation /> );
        } else {
            return ( <div></div> );
        }
    }

    // --------------------------------------------------------------------------------------------

    private getGroupSelectValues() {
        let yearsAndMonthsAsString = this.state.yearsAndMonthsAsString;

        let yearsAndMonths = yearsAndMonthsAsString.map( element => 
            <option value={element}>{element}</option> );

        return yearsAndMonths;
    }

    // --------------------------------------------------------------------------------------------

    private handleChange(event) {
        const target = event.target;

        let currentYearAndMonthSelection = target.value;

        this.getCurrentSumAndSetToState(currentYearAndMonthSelection);
    }

    private async getCurrentSumAndSetToState(yearAndMonthSelection) {
        let monthAndYearObject = Utils.getMonthAndYearFromStringSeparatedBySlash(yearAndMonthSelection);

        this.apiCalls.executeStartingAfterMonthAndYearSelection(monthAndYearObject);
    }

    // --------------------------------------------------------------------------------------------

    private getActionBar() {
        return (
            <div>
                { this.overheadActionBarButton.render() }
                { this.postOverheadCurrentMonthActionBarButton.render() }
                { this.groupedByMonthAndYearActionBarButton.render() }
                { this.groupedByYearActionBarButton.render() }
                { this.fortuneSavingRatioCreateActionBarButton.render(
                            this.state.currentYearAndMonthSelection) }
                { this.earningGroupActionBarButton.render() }
                { this.expenseGroupActionBarButton.render() }
                { this.getTargetActualComparisonButton() }
            </div> );
    }

    private getTargetActualComparisonButton() {
        // TODO: Create own action bar-class
        return (
            <Button onClick={ () => this.onClickTargetActualComparison() }>
               <Trans i18nKey="">
                    Target/actual comparison
                </Trans> 
            </Button> );
    }

    private onClickTargetActualComparison() {
        //TODO: Delete after own action bar-class was created
        //this.props.history.push(this.targetActualComparisonUrl);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private helper: ApiCallExecutorHelper;

    private earningsExpensesApiCallExecutor: EarningsExpensesApiCallExecutor<Overview>;
    
    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.earningsExpensesApiCallExecutor = new EarningsExpensesApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------
 
    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningsExpensesgGetYearsAndMonthsAsString());
        orderOfCalls.push(this.addEarningsExpensesSetMonthAndYearObjectFromParametersToState());
        orderOfCalls.push(this.addEarningsExpensesGetSumByMonthAndYear());
        orderOfCalls.push(this.addEarningsExpensesGetSavingRatioByMonthAndYear());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    public executeStartingAfterMonthAndYearSelection(monthAndYearObject) {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningsExpensesGetSumByMonthAndYear());
        orderOfCalls.push(this.addEarningsExpensesSetMonthAndYearObjectFromParametersToState());
        orderOfCalls.push(this.addEarningsExpensesGetSavingRatioByMonthAndYear());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "monthAndYearObject", monthAndYearObject);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------
 
    private addEarningsExpensesgGetYearsAndMonthsAsString() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getYearsAndMonthsAsStringLastMonth,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "yearsAndMonthsAsString"
        };
    }

    private addEarningsExpensesSetMonthAndYearObjectFromParametersToState() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.setMonthAndYearObjectFromParametersToState,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "currentYearAndMonthSelection"
        };
    }

    private addEarningsExpensesGetSumByMonthAndYear() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getSumByMonthAndYear,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addEarningsExpensesGetSavingRatioByMonthAndYear() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getSavingRatioByMonthAndYear,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "savingRatio"
        };
    }
}

export default Overview;