import Earning from '../../../../earningsExpenses/earning/item/model/Earning';
import Expense from '../../../../earningsExpenses/expense/item/model/Expense';

class EarningsExpensesOverheadTransactionCreator {
    earnings!: Array<Earning>;
    expenses!: Array<Expense>;

    constructor() {
        this.earnings = new Array<Earning>();
        this.expenses = new Array<Expense>();
    }
}

export default EarningsExpensesOverheadTransactionCreator;