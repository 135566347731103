import ActionBarButtonBase from '../../../../commons/ui/page/actionBar/ActionBarButtonBase';

import I18nConstants from '../../i18n/I18nConstants';

import EarningsExpensesUrlPath from '../EarningsExpensesUrlPath';

class ActionBarButtonGroupedByMonthAndYear extends ActionBarButtonBase {
    public constructor(propsHistory) {
        super(propsHistory,
              EarningsExpensesUrlPath.GROUPED_BY_MONTH_AND_YEAR,
              I18nConstants.NS,
              I18nConstants.ACTION_BAR_GROUPED_BY_MONTH_AND_YEAR,
              I18nConstants.ACTION_BAR_GROUPED_BY_MONTH_AND_YEAR_DEFAULT);
    }
}

export default ActionBarButtonGroupedByMonthAndYear;