import React from 'react';
import { Component } from 'react';
import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import UpdateUtils from '../../../../../commons/ui/page/template/update/UpdateUtils';
import Form from './Form';
import Utils from '../../../../../utilities/Utils';
import LetterSenderCompany from '../../model/LetterSenderCompany';
import LetterSenderCompanyApi from '../../api/LetterSenderCompanyApi';
import LetterSenderCompanyUrlPath from '../LetterSenderCompanyUrlPath';
import LetterPersonSalutation from '../../../../person/salutation/model/LetterPersonSalutation';
import LetterPersonTitle from '../../../../person/title/model/LetterPersonTitle';
import Country from '../../../../../general/country/model/Country';
import LetterSenderCompanyApiCallExecutor from '../../api/LetterSenderCompanyApiCallExecutor';
import LetterPersonSalutationApiCallExecutor from '../../../../person/salutation/api/LetterPersonSalutationApiCallExecutor';
import LetterPersonTitleApiCallExecutor from '../../../../person/title/api/LetterPersonTitleApiCallExecutor';
import CountryApiCallExecutor from '../../../../../general/country/api/CountryApiCallExecutor';
import LetterSenderCompanyUndefinedObjectDeletionModelMapper from '../../model/LetterSenderCompanyUndefinedObjectDeletionModelMapper';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: LetterSenderCompany;
    ContactSalutations: LetterPersonSalutation[];
    ContactTitles: LetterPersonTitle[];
    Countries: Country[];
}

class Update extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: LetterSenderCompanyApi;
    private form: Form;
    private updateUtils: UpdateUtils;

    private idOfItemToBeUpdated: number;

    public constructor(props) {
        super(props);

        this.idOfItemToBeUpdated =
            Utils.getIdFromGetParameters(props.location.search);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = LetterSenderCompanyApi.getInstance();

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: new LetterSenderCompany(),
            ContactSalutations: [],
            ContactTitles: [],
            Countries: []
        };

        this.form = new Form(props, this);

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            LetterSenderCompanyUrlPath.BASE
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return (
            <h1>

            </h1>
        );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.updateUtils.handleSubmit(event,
                                      null,
                                      new LetterSenderCompanyUndefinedObjectDeletionModelMapper());
    }

    // --------------------------------------------------------------------------------------------

    public getIdToBeUpdated() {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private senderCompanyApiCallExecutor: LetterSenderCompanyApiCallExecutor<Update>;
    private salutationApiCallExecutor: LetterPersonSalutationApiCallExecutor<Update>;
    private titleApiCallExecutor: LetterPersonTitleApiCallExecutor<Update>;
    private countryApiCallExecutor: CountryApiCallExecutor<Update>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.senderCompanyApiCallExecutor = 
                new LetterSenderCompanyApiCallExecutor(pointerToComponent);
        this.salutationApiCallExecutor = 
                new LetterPersonSalutationApiCallExecutor(pointerToComponent);
        this.titleApiCallExecutor = new LetterPersonTitleApiCallExecutor(pointerToComponent);
        this.countryApiCallExecutor = new CountryApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addSenderCompanyGetById());
        orderOfCalls.push(this.addSalutationGetAll());
        orderOfCalls.push(this.addTitleGetAll());
        orderOfCalls.push(this.addCountryGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "letterSenderCompanyId",
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addSenderCompanyGetById() : IApiCallback {
        return {
            callback: this.senderCompanyApiCallExecutor.getById,
            pointerToApiCall: this.senderCompanyApiCallExecutor,
            variableNameInState: "Item"
        };
    }

    private addSalutationGetAll() : IApiCallback {
        return {
            callback: this.salutationApiCallExecutor.getAll,
            pointerToApiCall: this.salutationApiCallExecutor,
            variableNameInState: "ContactSalutations"
        };
    }

    private addTitleGetAll(): IApiCallback {
        return {
            callback: this.titleApiCallExecutor.getAll,
            pointerToApiCall: this.titleApiCallExecutor,
            variableNameInState: "ContactTitles"
        };
    }

    private addCountryGetAll(): IApiCallback {
        return {
            callback: this.countryApiCallExecutor.getAll,
            pointerToApiCall: this.countryApiCallExecutor,
            variableNameInState: "Countries"
        };
    }
}

export default Update;