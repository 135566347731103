import React from 'react';

import FormGroupYear from '../fragment/FormGroupYear';
import FormGroupMonth from '../fragment/FormGroupMonth';
import FormGroupEarnings from '../fragment/FormGroupEarnings';
import FormGroupExpenses from '../fragment/FormGroupExpenses';

class Form {
    private formGroupMonth:    FormGroupMonth;
    private formGroupYear:     FormGroupYear;
    private formGroupEarnings: FormGroupEarnings;
    private formGroupExpenses: FormGroupExpenses;

    public constructor(props, pointerToComponent) {
        this.formGroupMonth    = new FormGroupMonth(props, pointerToComponent);
        this.formGroupYear     = new FormGroupYear(props, pointerToComponent);
        this.formGroupEarnings = new FormGroupEarnings(props, pointerToComponent);
        this.formGroupExpenses = new FormGroupExpenses(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupMonth.render() }
                { this.formGroupYear.render() }
                { this.formGroupEarnings.render() }
                { this.formGroupExpenses.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.formGroupMonth.isInvalidValue() 
             || this.formGroupYear.isInvalidValue()
             || this.formGroupEarnings.isInvalidValue()
             || this.formGroupExpenses.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;