import React from 'react';
import { Component } from 'react';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import CreateUtils from '../../../../commons/ui/page/template/create/CreateUtils';
import Form from './Form';
import LetterSignature from '../../model/LetterSignature';
import LetterSenderPerson from '../../../sender/person/model/LetterSenderPerson';
import LetterSenderCompany from '../../../sender/company/model/LetterSenderCompany';
import LetterSignatureApi from '../../api/LetterSignatureApi';
import LetterSignatureUrlPath from '../LetterSignatureUrlPath';
import LetterSignatureUndefinedObjectDeletionMapper from '../../model/LetterSignatureUndefinedObjectDeletionMapper';
import LetterSenderPersonApiCallExecutor from '../../../sender/person/api/LetterSenderPersonApiCallExecutor';
import LetterSenderCompanyApiCallExecutor from '../../../sender/company/api/LetterSenderCompanyApiCallExecutor';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: LetterSignature;
    SenderPeople: LetterSenderPerson[];
    SenderCompanies: LetterSenderCompany[];
}

class Create extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: LetterSignatureApi;
    private form: Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: new LetterSignature(),
            SenderPeople: [],
            SenderCompanies: []
        };

        this.apiCalls = new ApiCallExecutor(this);

        this.api = LetterSignatureApi.getInstance();

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            LetterSignatureUrlPath.BASE
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return (
            <h1>

            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleFileChange(event) {
        const file = event.target.files[0];

        if (file) {
            let item = this.state.Item;
            item.contentType = event.target.files[0].type;
            this.setState({ Item: item });

            const reader = new FileReader();
            reader.onload = this.handleFileUpload.bind(this);
            reader.readAsBinaryString(file);
        }        
    }

    private handleFileUpload(event) { 
        let item = this.state.Item;
        item.content = btoa(event.target.result);
        this.setState({ Item: item });
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event,
                                      null,
                                      new LetterSignatureUndefinedObjectDeletionMapper());
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private senderPersonApiCallExecutor: LetterSenderPersonApiCallExecutor<Create>;
    private senderCompanyApiCallExecutor: LetterSenderCompanyApiCallExecutor<Create>;
    
    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.senderPersonApiCallExecutor = 
                new LetterSenderPersonApiCallExecutor(pointerToComponent);
        this.senderCompanyApiCallExecutor = 
                new LetterSenderCompanyApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addSenderPeopleGetAll());
        orderOfCalls.push(this.addSenderCompaniesGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addSenderPeopleGetAll() : IApiCallback {
        return {
            callback: this.senderPersonApiCallExecutor.getAll,
            pointerToApiCall: this.senderPersonApiCallExecutor,
            variableNameInState: "SenderPeople"
        };
    }

    private addSenderCompaniesGetAll() : IApiCallback {
        return {
            callback: this.senderCompanyApiCallExecutor.getAll,
            pointerToApiCall: this.senderCompanyApiCallExecutor,
            variableNameInState: "SenderCompanies"
        };
    }
}

export default Create;