import FortuneSavingRatio from '../../model/FortuneSavingRatio';
import FortuneSavingRatioApi from '../../api/FortuneSavingRatioApi';
import FortuneSavingRatioUrlPath from '../FortuneSavingRatioUrlPath';
import ExpenseApi from '../../../../earningsExpenses/expense/item/api/ExpenseApi';
import EarningApi from '../../../../earningsExpenses/earning/item/api/EarningApi';
import CreateBase from '../../../../commons/ui/page/template/create/CreateBase';
import Form from './Form';
import FormButtonCreate from '../../../../commons/ui/page/template/form/button/FormButtonCreate';
import Utils from '../../../../utilities/Utils';

class Create extends CreateBase<FortuneSavingRatio, FortuneSavingRatioApi> {
    private form: Form;

    private monthValue: string;
    private yearValue:  string;

    public constructor(props) {
        super(props, 
              new FortuneSavingRatio(),
              FortuneSavingRatioApi.getInstance(),
              FortuneSavingRatioUrlPath.BASE);

        this.monthValue = "";
        this.yearValue  = "";

        this.getDefaultValuesFromGetParameters(props);

        this.form = new Form(props, this);
    }

    private getDefaultValuesFromGetParameters(props) {
        const monthAndYearValue =
              Utils.getMonthAndYearFromGetParameters(props.location.search);

        this.monthValue = (monthAndYearValue as any).month;
        this.yearValue  = (monthAndYearValue as any).year;

        let fortuneSavingRatio = new FortuneSavingRatio();

        if (    (this.monthValue)
             && (parseInt(this.monthValue) > 0)
             && (this.yearValue)
             && (parseInt(this.yearValue) > 0)) {
            fortuneSavingRatio.month = parseInt(this.monthValue);
            fortuneSavingRatio.year  = parseInt(this.yearValue);

            EarningApi.getInstance().getSumByMonthAndYear(
                this.monthValue,
                this.yearValue)
            .then(  response => {
                        fortuneSavingRatio.earnings = (response as any);

                        ExpenseApi.getInstance().getSumByMonthAndYear(
                            this.monthValue,
                            this.yearValue)
                        .then(  response => {
                                    fortuneSavingRatio.expenses = (response as any);
                                
                                    this.setState({
                                        Item:        fortuneSavingRatio,
                                        wasHandleSubmitClickedAtLeastOnce: false
                                    });
                                })
                        .catch( error => {
                                    console.log("TODO");
                                });
                    })
            .catch( error    => {
                        console.log("TODO");
                    });
        }
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;