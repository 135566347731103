import I18nConstants from '../../../../../i18n/I18nConstantsBase';
import OverviewHeadingBase from "./OverviewHeadingBase";

class OverviewHeadingShowDetails extends OverviewHeadingBase {
    public static render() {
        return OverviewHeadingBase.renderByI18nKeyAndDefaultValue(
                    I18nConstants.ACTION_SHOW_DETAILS,
                    I18nConstants.ACTION_SHOW_DETAILS_DEFAULT
        );
    }
}

export default OverviewHeadingShowDetails;