import NavigationLinkBase from "../../../commons/ui/page/navigationLink/NavigationLinkBase";
import I18nConstants from '../../i18n/I18nConstants';
import LogoutUrlPath from '../LogoutUrlPath';

class NavigationItem extends NavigationLinkBase {
    public constructor() {
        super(
            LogoutUrlPath.BASE,
            I18nConstants.NS,
            I18nConstants.TITLE,
            I18nConstants.TITLE_DEFAULT
        );
    }
}

export default NavigationItem;