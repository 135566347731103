import I18nConstantsBase from '../../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "fortuneSavingPlanGroup";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static FORM_RISK_CLASS = "form.riskClass";
    public static FORM_NAME = "form.name";
    public static FORM_MONTHLY_SAVING_RATE = "form.monthlySavingRate";
    public static FORM_AVERAGE_PROFITS = "form.averageProfits";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static FORM_RISK_CLASS_DEFAULT = DefaultJson.form.riskClass;
    public static FORM_NAME_DEFAULT = DefaultJson.form.name;
    public static FORM_MONTHLY_SAVING_RATE_DEFAULT = DefaultJson.form.monthlySavingRate;
    public static FORM_AVERAGE_PROFITS_DEFAULT = DefaultJson.form.averageProfits;
}

export default I18nConstants;