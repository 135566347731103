import React from 'react';
import { Component } from 'react';
import { Input } from 'reactstrap';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import BikeApiCallExecutor from '../../../bike/item/api/BikeApiCallExecutor';
import CyclingTourApiCallExecutor from '../../api/CyclingTourApiCallExecutor';
import Utils from '../../../../utilities/Utils';
import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';
import CyclingTour from '../../model/CyclingTour';
import CyclingTourApi from '../../api/CyclingTourApi';
import Bike from '../../../bike/item/model/Bike';
import OverviewUtils from '../../../../commons/ui/page/template/overview/OverviewUtils';
import CyclingTourUrlPath from '../CyclingTourUrlPath';
import BikeActionBarButton from '../../../bike/item/ui/fragment/ActionBarButton';
import BikeTypeActionBarButton from '../../../bike/type/ui/fragment/ActionBarButton';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    cyclingTours: CyclingTour[];
    bikes: Bike[];
    currentBikeSelectionId: number;
    sumDistance: number;
}

class Overview extends Component<IProps, IState> {
    private api: CyclingTourApi;
    private apiCalls: ApiCallExecutor;

    private overviewUtils: OverviewUtils;

    private bikeActionBarButton: BikeActionBarButton;
    private bikeTypeActionBarButton: BikeTypeActionBarButton;

    public constructor(props) {
        super(props);

        this.api = CyclingTourApi.getInstance();

        this.apiCalls = new ApiCallExecutor(this);

        this.bikeActionBarButton = new BikeActionBarButton(props.history);
        this.bikeTypeActionBarButton = new BikeTypeActionBarButton(props.history);

        this.state = {
            isLoading: true,
            cyclingTours: [],
            bikes: [],
            currentBikeSelectionId: 0,
            sumDistance: 0
        };

        this.overviewUtils = new OverviewUtils(
            this,
            this.getHeading(),
            CyclingTourUrlPath.CREATE,
            CyclingTourUrlPath.UPDATE,
            undefined,
            true,
            this.getActionBar()
        );

        this.handleChange = this.handleChange.bind(this);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (this.state.isLoading) {
            return LoadingScreen.render();
        } else {
            return (
                <div>
                    { this.overviewUtils.render(
                        this.getTableHead(),
                        this.getTableBody(),
                        this.getSelectionField()) }
                </div>
            );
        }
    }

    private getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE}>
                    {I18nConstants.TITLE_DEFAULT}
                </Trans>
            </h1> 
        );
    }

    private getSelectionField() {
        let selectValues = this.getGroupSelectValues();

        return (
            <div>
                <Input
                    type="select"
                    name="bikes"
                    id="bikes"
                    value={this.state.currentBikeSelectionId}
                    onChange={this.handleChange}>
                        {selectValues}
                </Input>

                { this.getSumsToBeDisplayed() }
            </div>
        );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DATE}>
                        {I18nConstants.FORM_DATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_TRIP_DISTANCE_IN_KM}>
                        {I18nConstants.FORM_TRIP_DISTANCE_IN_KM_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_RIDE_TIME}>
                        {I18nConstants.FORM_RIDE_TIME_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AVERAGE_SPEED_IN_KMH}>
                        {I18nConstants.FORM_AVERAGE_SPEED_IN_KMH}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_MAX_SPEED_IN_KMH}>
                        {I18nConstants.FORM_MAX_SPEED_IN_KMH}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AVERAGE_POWER_IN_WATT}>
                        {I18nConstants.FORM_AVERAGE_POWER_IN_WATT_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_ALTITUDE_ASCENDING_IN_M}>
                        {I18nConstants.FORM_ALTITUDE_ASCENDING_IN_M_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DISTANCE_ASCENDING_IN_KM}>
                        {I18nConstants.FORM_DISTANCE_ASCENDING_IN_KM_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_MAX_ALTITUDE_IN_M}>
                        {I18nConstants.FORM_MAX_ALTITUDE_IN_M_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AVERAGE_HEART_RATE}>
                        {I18nConstants.FORM_AVERAGE_HEART_RATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_MAX_HEART_RATE}>
                        {I18nConstants.FORM_MAX_HEART_RATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_KILO_CALORIES}>
                        {I18nConstants.FORM_KILO_CALORIES_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_NOTE}>
                        {I18nConstants.FORM_NOTE_DEFAULT}
                    </Trans>
                </th>

                { this.overviewUtils.getUpdateHeadingWrappedInThTag() }
                { this.overviewUtils.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    protected getTableBody() {
        return this.state.cyclingTours.map( item => 
            <tr key={item.id}>
                <td>
                    {item.date}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.tripDistanceInKm)}
                </td>

                <td>
                    {item.rideTime}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.averageSpeedInKmh)}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.maxSpeedInKmh)}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.averagePowerInWatt)}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.altitudeAscendingInM)}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.distanceAscendingInKm)}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.maxAltitudeInM)}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.averageHeartRate)}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.maxHeartRate)}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.kiloCalories)}
                </td>

                <td>
                    {item.note}
                </td>

                { this.overviewUtils.getUpdateButtonWrappedInTdTag(item.id) }
                { this.overviewUtils.getDeleteButtonWrappedInTdTag(item.id) }
            </tr>
        );
    }

    private getSumsToBeDisplayed() {
        return (
            <div>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.SUM_DISTANCE}>
                    {I18nConstants.SUM_DISTANCE_DEFAULT}
                </Trans>
                :&nbsp;
                {this.state.sumDistance}
            </div>
        );
    }

    // --------------------------------------------------------------------------------------------

    private getGroupSelectValues() {
        return this.state.bikes.map( element => 
            <option value={element.id}>
                {element.brand}&nbsp;
                {element.model}
            </option>
        );
    }

    // --------------------------------------------------------------------------------------------

    private handleChange(event) {
        const target = event.target;

        let bikeSelelectionId = target.value;

        this.apiCalls.executeStartingAfterBikeSelection(bikeSelelectionId);
    }

    // --------------------------------------------------------------------------------------------

    private getActionBar() {
        return (
            <div>
                { this.bikeActionBarButton.render() }
                { this.bikeTypeActionBarButton.render() }
            </div>
        );
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private helper: ApiCallExecutorHelper;

    private bikeApiCallExecutor: BikeApiCallExecutor<Overview>;
    private cyclingTourApiCallExecutor: CyclingTourApiCallExecutor<Overview>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.bikeApiCallExecutor = new BikeApiCallExecutor(pointerToComponent);
        this.cyclingTourApiCallExecutor = new CyclingTourApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addBikeGetAll());
        orderOfCalls.push(this.addBikeFromParametersToState());
        orderOfCalls.push(this.addCyclingTourGetAllByBikeId());
        orderOfCalls.push(this.addCyclingTourGetSumDistanceByBikeId());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    public executeStartingAfterBikeSelection(bikeId) {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addBikeFromParametersToState());
        orderOfCalls.push(this.addCyclingTourGetAllByBikeId());
        orderOfCalls.push(this.addCyclingTourGetSumDistanceByBikeId());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "bikeId", bikeId);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addBikeGetAll() : IApiCallback {
        return {
            callback: this.bikeApiCallExecutor.getAll,
            pointerToApiCall: this.bikeApiCallExecutor,
            variableNameInState: "bikes"
        };
    }

    private addBikeFromParametersToState() : IApiCallback {
        return {
            callback: this.bikeApiCallExecutor.setBikeIdFromParametersToState,
            pointerToApiCall: this.bikeApiCallExecutor,
            variableNameInState: "currentBikeSelectionId"
        };
    }

    private addCyclingTourGetAllByBikeId() : IApiCallback {
        return {
            callback: this.cyclingTourApiCallExecutor.getAllByBikeId,
            pointerToApiCall: this.cyclingTourApiCallExecutor,
            variableNameInState: "cyclingTours"
        };
    }

    private addCyclingTourGetSumDistanceByBikeId() : IApiCallback {
        return {
            callback: this.cyclingTourApiCallExecutor.getSumDistanceByBikeId,
            pointerToApiCall: this.cyclingTourApiCallExecutor,
            variableNameInState: "sumDistance"
        };
    }
}

export default Overview;