import LetterSenderCompany from './LetterSenderCompany';
import LetterSenderCompanyModelMapper from './LetterSenderCompanyModelMapper';

class LetterSenderCompanyUndefinedObjectDeletionModelMapper
extends LetterSenderCompanyModelMapper {
    fromObjectToJson(object: LetterSenderCompany) {
        let jsonObject = super.fromObjectToJson(object);

        // Check for empty salutation and set it to 'null'.
        if (     (jsonObject.contactSalutation.id === undefined)
             || ((jsonObject.contactSalutation.id as any) === "-1")
             || ((jsonObject.contactSalutation.id as any) === "none")) {
            jsonObject.contactSalutation = (null as any);
        }

        // Check for empty title and set it to 'null'.
        if (     (jsonObject.contactTitle.id === undefined)
             || ((jsonObject.contactTitle.id as any) === "-1")
             || ((jsonObject.contactTitle.id as any) === "none")) {
            jsonObject.contactTitle = (null as any);
        }

        return jsonObject;
    }
}

export default LetterSenderCompanyUndefinedObjectDeletionModelMapper;