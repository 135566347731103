import ApiCallExecutorBase from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';

import ExpenseOverheadTransactionApi from './ExpenseOverheadTransactionApi';

class ExpenseOverheadTransactionApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private expenseOverheadTransactionApi: ExpenseOverheadTransactionApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.expenseOverheadTransactionApi = ExpenseOverheadTransactionApi.getInstance();
    }

    public getById(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const id = (context.pointerToHelper.getParameterInArrayWithObjects(
                        context.parameters, "overheadId") as any);

        context.currentData.pointerToApiCall.expenseOverheadTransactionApi.getById(id)
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }

    public getByOverheadId(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const idToBeDisplayed = (context.pointerToHelper.getParameterInArrayWithObjects(
                                    context.parameters, "overheadId") as any);

        context.currentData.pointerToApiCall.expenseOverheadTransactionApi.getByOverheadId(idToBeDisplayed)
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }
}

export default ExpenseOverheadTransactionApiCallExecutor;