import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupIssueFee extends FormGroupBase {
    private ID_AND_NAME = "issueFee";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_ISSUE_FEE,
                    I18nConstants.FORM_ISSUE_FEE_DEFAULT) }

                <Input 
                    type="number" 
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.issueFee}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()} />
                    
                { this.renderFormFeedbackWithInsertValidValueMessage() }
            </FormGroup> );
    }

    public isInvalidValue(): boolean {
        if (parseInt(this.pointerToComponent.state.Item.issueFee) >= 0) {
            return false;
        } else {
            return true;
        }
    }
}

export default FormGroupIssueFee;