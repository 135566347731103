import React from 'react';
import { Component } from 'react';
import LetterSignature from '../../model/LetterSignature';
import LetterSenderPerson from '../../../sender/person/model/LetterSenderPerson';
import LetterSenderCompany from '../../../sender/company/model/LetterSenderCompany';
import UpdateUtils from '../../../../commons/ui/page/template/update/UpdateUtils';
import LetterSignatureApi from '../../api/LetterSignatureApi';
import Form from './Form';
import Utils from '../../../../utilities/Utils';
import LetterSignatureUrlPath from '../LetterSignatureUrlPath';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import LetterSenderPersonApiCallExecutor from '../../../sender/person/api/LetterSenderPersonApiCallExecutor';
import LetterSenderCompanyApiCallExecutor from '../../../sender/company/api/LetterSenderCompanyApiCallExecutor';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import LetterSignatureApiCallExecutor from '../../api/LetterSignatureApiCallExecutor';
import LetterSignatureUndefinedObjectDeletionMapper from '../../model/LetterSignatureUndefinedObjectDeletionMapper';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: LetterSignature;
    SenderPeople: LetterSenderPerson[];
    SenderCompanies: LetterSenderCompany[];
}

class Update extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: LetterSignatureApi;
    private form: Form;
    private updateUtils: UpdateUtils;

    private idOfItemToBeUpdated: number;

    public constructor(props) {
        super(props);

        this.idOfItemToBeUpdated =
            Utils.getIdFromGetParameters(props.location.search);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = LetterSignatureApi.getInstance();

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: new LetterSignature(),
            SenderPeople: [],
            SenderCompanies: []
        };

        this.form = new Form(props, this);

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            LetterSignatureUrlPath.BASE
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return (
            <h1>

            </h1>
        );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) { 
        this.updateUtils.handleSubmit(event,
                                      null,
                                      new LetterSignatureUndefinedObjectDeletionMapper());
    }

    // --------------------------------------------------------------------------------------------

    public getIdToBeUpdated() {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private signatureApiCallExecutor: LetterSignatureApiCallExecutor<Update>;
    private senderPersonApiCallExecutor: LetterSenderPersonApiCallExecutor<Update>;
    private senderCompanyApiCallExecutor: LetterSenderCompanyApiCallExecutor<Update>;
    
    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.signatureApiCallExecutor = 
                new LetterSignatureApiCallExecutor(pointerToComponent);
        this.senderPersonApiCallExecutor = 
                new LetterSenderPersonApiCallExecutor(pointerToComponent);
        this.senderCompanyApiCallExecutor = 
                new LetterSenderCompanyApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addSignatureGetById());
        orderOfCalls.push(this.addSenderPeopleGetAll());
        orderOfCalls.push(this.addSenderCompaniesGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "letterSignatureId",
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addSignatureGetById() : IApiCallback {
        return {
            callback: this.signatureApiCallExecutor.getById,
            pointerToApiCall: this.signatureApiCallExecutor,
            variableNameInState: "Item"
        };
    }

    private addSenderPeopleGetAll() : IApiCallback {
        return {
            callback: this.senderPersonApiCallExecutor.getAll,
            pointerToApiCall: this.senderPersonApiCallExecutor,
            variableNameInState: "SenderPeople"
        };
    }

    private addSenderCompaniesGetAll() : IApiCallback {
        return {
            callback: this.senderCompanyApiCallExecutor.getAll,
            pointerToApiCall: this.senderCompanyApiCallExecutor,
            variableNameInState: "SenderCompanies"
        };
    }
}

export default Update;