const CREATE_URL = 'create';
const UPDATE_URL = 'update';
const SHOW_DETAILS_URL = '';

// -------------------------------------------------------------------------------------------------

class AppVariables {
    private constructor() {
        throw new Error('Constructor can\'t be called, because it\'s a singleton!');
    }

    public static getCreateUrl() {
        return CREATE_URL;
    }

    public static getUpdateUrl() {
        return UPDATE_URL;
    }

    public static getShowDetailsUrl() {
        return SHOW_DETAILS_URL;
    }
}

export default AppVariables;