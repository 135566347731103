import React from 'react';
import { Component } from 'react';
import { Form } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import I18nConstants from '../../../../../i18n/I18nConstants';
import AuthenticationApi from '../../../../../../security/api/AuthenticationApi';
import LoginUrlPath from '../../../../../../login/ui/LoginUrlPath';
import GroupForm from '../GroupForm';
import FormButtonCreate from '../../form/button/FormButtonCreate';
import NavigationRenderer from '../../../../fragment/NavigationRenderer';
import FormEventHandlerCreateGroup from '../../form/eventHandler/FormEventHandlerCreateGroup';

interface IProps<Group> {
    history: any;
}

interface IState<Group> {
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Group: Group;
}

class CreateBase<Group, ApiForGroup> 
  extends Component<IProps<Group>, IState<Group>> {
    private eventHandler: FormEventHandlerCreateGroup;

    private group: Group;
    private api: ApiForGroup;

    private form: GroupForm;

    private nextPageUrl: string;

    private showNavbar: boolean;

    // --------------------------------------------------------------------------------------------
    // --- Constructor ---

    public constructor(props, group, api, nextPageUrl) {
        super(props);

        this.group = group;
        this.api = api;

        this.nextPageUrl = nextPageUrl;

        this.showNavbar = true;

        this.eventHandler = new FormEventHandlerCreateGroup(this, this.api, this.nextPageUrl);

        this.form = new GroupForm(props, this);

        this.state = {
            wasHandleSubmitClickedAtLeastOnce: false,
            Group: this.group
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // --------------------------------------------------------------------------------------------
    // --- Render ---
    
    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            return (
                <div>
                    { NavigationRenderer.renderIfNecessary(this.showNavbar) }

                    { this.getHeading() }

                    <div>
                        <Form onSubmit={this.handleSubmit}>
                            { this.form.getFormGroupContent(FormButtonCreate.render) }                            
                        </Form>
                    </div>
                </div> 
            );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different Sections (for sub-classes) - Optional ---

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.FORM_GROUP_ADD}>
                    {I18nConstants.FORM_GROUP_ADD_DEFAULT}
                </Trans>
            </h1> 
        );
    }

    // --------------------------------------------------------------------------------------------
    // --- Form validators ---

    private isFormValid(): boolean {
        // Used as callback for checking, before submitting to API.
        return this.form.isFormValid();
    }

    // --------------------------------------------------------------------------------------------
    // --- Event handlers ---
    
    private handleChange(event) {
        this.eventHandler.handleChange(event);
    }

    private handleSubmit(event) {
        this.eventHandler.handleSubmit(event);
    }
};

export default CreateBase;