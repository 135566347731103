import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneRiskClassApiCallExecutor from '../../../../riskClass/api/FortuneRiskClassApiCallExecutor';

import FortuneSavingPlanGroup from '../../model/FortuneSavingPlanGroup';
import FortuneSavingPlanGroupApi from '../../api/FortuneSavingPlanGroupApi';
import FortuneSavingPlanGroupUrlPath from '../FortuneSavingPlanGroupUrlPath';

import FortuneRiskClass from '../../../../riskClass/model/FortuneRiskClass';

import CreateUtils from '../../../../../commons/ui/page/template/create/CreateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:   boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item:        FortuneSavingPlanGroup;
    riskClasses: FortuneRiskClass[];
}

class Create extends Component<IProps, IState> {
    private apiCalls:    ApiCallExecutor;
    private api:     FortuneSavingPlanGroupApi;
    private form:        Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = FortuneSavingPlanGroupApi.getInstance();

        this.state = {
            isLoading:   true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item:        new FortuneSavingPlanGroup(),
            riskClasses: []
        };

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            FortuneSavingPlanGroupUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1> );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private fortuneRiskClassApiCallExecutor: FortuneRiskClassApiCallExecutor<Create>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneRiskClassApiCallExecutor = new FortuneRiskClassApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneRiskClassGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneRiskClassGetAll() : IApiCallback {
        return {
            callback: this.fortuneRiskClassApiCallExecutor.getAll,
            pointerToApiCall: this.fortuneRiskClassApiCallExecutor,
            variableNameInState: "riskClasses"
        };
    }
}

export default Create;