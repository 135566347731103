import CreateBase from '../../../../../commons/ui/page/template/group/create/CreateBase';

import KindOfFuel from '../../model/KindOfFuel';
import KindOfFuelApi from '../../api/KindOfFuelApi';
import KindOfFuelUrlPath from '../KindOfFuelUrlPath';

class Create extends CreateBase<KindOfFuel, KindOfFuelApi> {
    public constructor(props) {
        super(props,
              new KindOfFuel(),
              KindOfFuelApi.getInstance(),
              KindOfFuelUrlPath.BASE);
    }
}

export default Create;