import CreateBase from "../../../../../commons/ui/page/template/create/CreateBase";
import LetterPersonTitleApi from "../../api/LetterPersonTitleApi";
import LetterPersonTitle from "../../model/LetterPersonTitle";
import LetterPersonTitleUrlPath from "../LetterPersonTitleUrlPath";
import Form from './Form';
import FormButtonCreate from '../../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBase<LetterPersonTitle, LetterPersonTitleApi> {
    private form: Form;

    public constructor(props) {
        super(props,
              new LetterPersonTitle(),
              LetterPersonTitleApi.getInstance(),
              LetterPersonTitleUrlPath.BASE
        );

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;