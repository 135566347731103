import GenericApi from '../../../commons/api/GenericApi';

class EarningsExpensesOverheadApi {
    private static instance: EarningsExpensesOverheadApi;

    private static apiUrlPath = "earningsExpenses/overhead";

    private static endpointSum = "/sum";
    private static endpointSavingRatio = "/savingRatio";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): EarningsExpensesOverheadApi {
        if (!EarningsExpensesOverheadApi.instance) {
            EarningsExpensesOverheadApi.instance = new EarningsExpensesOverheadApi();
        }

        return EarningsExpensesOverheadApi.instance;
    }

    public getSum() {
        let endpoint = EarningsExpensesOverheadApi.endpointSum;

        return GenericApi.executeGetAllCall(
                    EarningsExpensesOverheadApi.apiUrlPath + endpoint,
                    null);
    }

    public getSavingRatio() {
        let endpoint = EarningsExpensesOverheadApi.endpointSavingRatio;

        return GenericApi.executeGetAllCall(
                    EarningsExpensesOverheadApi.apiUrlPath + endpoint,
                    null);
    }

    public getUrl() {
        return EarningsExpensesOverheadApi.apiUrlPath;
    }
}

export default EarningsExpensesOverheadApi;