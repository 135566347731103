import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupKindOfQuantity extends FormGroupBase {
    private ID_AND_NAME = "kindOfQuantity";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const kindOfQuantities = this.getKindOfQuantitiesSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_KIND_OF_QUANTITY,
                    I18nConstants.FORM_KIND_OF_QUANTITY_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.kindOfQuantity.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {kindOfQuantities}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getKindOfQuantitiesSelectValues() {
        const { KindOfQuantities } = this.pointerToComponent.state;

        return this.getSelectValues(KindOfQuantities, "name");
    }
}

export default FormGroupKindOfQuantity;