import IModelMapper from "../../../commons/model/IModelMapper";

import FortunePriority from './FortunePriority';

class FortunePriorityModelMapper implements IModelMapper<FortunePriority> {
    fromJsonToObject(json: any): FortunePriority {
        let fortunePriority = new FortunePriority();

        fortunePriority.id          = json.id;
        fortunePriority.priorityId  = json.priorityId;
        fortunePriority.description = json.description;

        return fortunePriority;
    }

    fromObjectToJson(object: FortunePriority) {
        return {
            id:          object.id,
            priorityId:  (object.priorityId || 0),
            description: (object.description || "")
        };
    }
}

export default FortunePriorityModelMapper;