import OverviewApiCallBase from "../../../overview/apiCall/OverviewApiCallBase";

class OverviewApiCallDelete extends OverviewApiCallBase {
    public execute(pointerToComponent, api, id) {
        api.delete(id)
        .then(  result => {
                    let groups = pointerToComponent.state.Groups;

                    // Remove the currently in backend removed item also in frontend.
                    let indexToBeRemoved = groups.map(function(group) { return group.id }).indexOf(id);

                    if (indexToBeRemoved >= 0) {
                        groups.splice(indexToBeRemoved, 1);
                    }

                    pointerToComponent.setState({ Groups: groups });
                })
        .catch( error => { console.log("TODO"); })
    }
}

export default OverviewApiCallDelete;