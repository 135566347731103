import ApiCallExecutorBase from "../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from "../../../commons/ui/page/apiCallExecutor/IApiCallContext";
import FortuneSavingRatioApi from "./FortuneSavingRatioApi";

class FortuneSavingRatioApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private fortuneSavingRatioApi: FortuneSavingRatioApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.fortuneSavingRatioApi = FortuneSavingRatioApi.getInstance();
    }

    public getAll(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingRatioApi.getAll()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getByYear(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const year = (context.pointerToHelper.getParameterInArrayWithObjects(
                        context.parameters, "year") as number);

        context.currentData.pointerToApiCall.fortuneSavingRatioApi.getByYear(year)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getYearsAsString(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingRatioApi.getYearsAsString()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getAverageTotal(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingRatioApi.getAverageTotal()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getAverageByYear(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const year = (context.pointerToHelper.getParameterInArrayWithObjects(
                        context.parameters, "year") as number);

        context.currentData.pointerToApiCall.fortuneSavingRatioApi.getAverageByYear(year)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public setInitialYearSelectionToParametersAndState(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const years = context.pointerToComponent.state.yearsAsString;

        // Get the last year combination.
        // The last one in the list is always the latest one.
        const initialYearSelection = (years[years.length -1] as string);

        context.pointerToHelper.addParameterToContext(context, "year", initialYearSelection);

        const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    initialYearSelection);

        context.pointerToComponent.setState(state);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }

    public setYearSelectionFromParametersToState(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const year = (context.pointerToHelper.getParameterInArrayWithObjects(
                            context.parameters, "year") as number);

        const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    year);

        context.pointerToComponent.setState(state);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }

    public setDiagramDataCurrentYearToParameters(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const diagramData = context.pointerToComponent.state.List;

        context.pointerToHelper.addParameterToContext(
                                    context, 
                                    context.currentData.variableNameInState, 
                                    diagramData);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }

    public setDiagramDataAllYearsToParameters(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const diagramData = context.pointerToComponent.state.ListTotal;

        context.pointerToHelper.addParameterToContext(
                                    context, 
                                    context.currentData.variableNameInState, 
                                    diagramData);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }

    public setDiagramDataAllYearsFromOverviewToParameters(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const diagramData = context.pointerToComponent.state.savingRatioCurrentYear;

        context.pointerToHelper.addParameterToContext(
                                    context, 
                                    context.currentData.variableNameInState, 
                                    diagramData);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }
}

export default FortuneSavingRatioApiCallExecutor;