import ActionBarButtonBase from '../../../../commons/ui/page/actionBar/ActionBarButtonBase';

import I18nConstants from '../../i18n/I18nConstants';

import FortuneSavingRateUrlPath from '../FortuneSavingRateUrlPath';

class ActionBarButton extends ActionBarButtonBase {
    public constructor(propsHistory) {
        super(propsHistory,
              FortuneSavingRateUrlPath.CREATE_IN_WIZARD_FOR_ALL_SAVING_PLANS,
              I18nConstants.NS,
              I18nConstants.TITLE_SHORT_CREATE_ALL_VIA_WIZARD,
              I18nConstants.TITLE_SHORT_CREATE_ALL_VIA_WIZARD_DEFAULT);
    }
}

export default ActionBarButton;