import IModelMapper from '../../../commons/model/IModelMapper';
import ShoppingListGroup from './ShoppingListGroup';

class ShoppingListGroupModelMapper implements IModelMapper<ShoppingListGroup> {
    fromJsonToObject(json: any): ShoppingListGroup {
        let shoppingListGroup = new ShoppingListGroup();

        shoppingListGroup.id   = json.id;
        shoppingListGroup.name = json.name;

        return shoppingListGroup;
    }

    fromObjectToJson(object: ShoppingListGroup) {
        return {
            id:   object.id,
            name: (object.name || "")
        };
    }
}

export default ShoppingListGroupModelMapper;