import React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import AuthenticationApi from '../../../../security/api/AuthenticationApi';
import LoginUrlPath from '../../../../login/ui/LoginUrlPath';
import NavigationRenderer from '../../../../commons/ui/fragment/NavigationRenderer';
import OverviewItemsNotStarted from './OverviewItemsNotStarted';
import OverviewItemsInProcess from './OverviewItemsInProcess';
import OverviewItemsFinished from './OverviewItemsFinished';
import ToDoListGroupActionBarButton from '../../../group/ui/fragment/ActionBarButton';
import OverviewEventHandlerCreate from '../../../../commons/ui/page/template/overview/eventHandler/OverviewEventHandlerCreate';
import OverviewButtonCreate from '../../../../commons/ui/page/template/overview/button/OverviewButtonCreate';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
}

class Overview extends Component<IProps, IState> {
    private showNavbar: boolean;

    private groupActionBarButton: ToDoListGroupActionBarButton;

    public constructor(props) {
        super(props);

        this.showNavbar = true;

        this.groupActionBarButton = new ToDoListGroupActionBarButton(props.history);
    }

    public async componentDidMount() {
        // Everything is done in sub-components.
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            return (
                <div>
                    { NavigationRenderer.renderIfNecessary(this.showNavbar) }

                    { this.getHeading() }
                    <div>
                        <div>
                            { this.getActionBar() }
                        </div>

                        <div>
                            { this.getCreateButton() }
                        </div>

                        <div>
                        <OverviewItemsNotStarted history={this.props.history} 
                                location={this.props.location} {...this.state} />
                        </div>

                        <div>
                            <OverviewItemsInProcess history={this.props.history} 
                                location={this.props.location} {...this.state} />
                        </div>

                        <div>
                            <OverviewItemsFinished history={this.props.history} 
                                location={this.props.location} {...this.state} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE}>
                    {I18nConstants.TITLE_DEFAULT}
                </Trans>
            </h1> );
    }

    private getActionBar() {
        return (
            <div>
                { this.groupActionBarButton.render() }
            </div>
        );
    }

    private getCreateButton() {
        let eventHandlerCreate = new OverviewEventHandlerCreate();

        return OverviewButtonCreate.render(this, eventHandlerCreate.onAction);
    }
}
 
export default Overview;