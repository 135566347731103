import ApiCallExecutorBase from "../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../commons/ui/page/apiCallExecutor/IApiCallContext';
import LetterTemplateApi from './LetterTemplateApi';

class LetterTemplateApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private letterTemplateApi: LetterTemplateApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.letterTemplateApi = LetterTemplateApi.getInstance();
    }

    public getAll(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.letterTemplateApi.getAll()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }
}

export default LetterTemplateApiCallExecutor;