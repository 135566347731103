import React from "react";
import { Component } from "react";
import ApiCallExecutorHelper from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper";
import IApiCallback from "../../../../commons/ui/page/apiCallExecutor/IApiCallback";
import UpdateUtils from "../../../../commons/ui/page/template/update/UpdateUtils";
import Utils from "../../../../utilities/Utils";
import InsuranceListGroupApiCallExecutor from "../../../group/api/InsuranceListGroupApiCallExecutor";
import InsuranceListGroup from "../../../group/model/InsuranceListGroup";
import InsuranceListPaymentIntervalApiCallExecutor from "../../../paymentInterval/api/InsuranceListPaymentIntervalApiCallExecutor";
import InsuranceListPaymentInterval from "../../../paymentInterval/model/InsuranceListPaymentInterval";
import InsuranceListApi from "../../api/InsuranceListApi";
import InsuranceListApiCallExecutor from "../../api/InsuranceListApiCallExecutor";
import InsuranceList from "../../model/InsuranceList";
import InsuranceListUrlPath from "../InsuranceListUrlPath";
import Form from "./Form";

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: InsuranceList;
    Groups: InsuranceListGroup[];
    PaymentIntervals: InsuranceListPaymentInterval[];
}

class Update extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: InsuranceListApi;
    private form: Form;
    private updateUtils: UpdateUtils;

    private idOfItemToBeUpdated: number;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = InsuranceListApi.getInstance();

        this.idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        this.state = {
            isLoading: true,
                wasHandleSubmitClickedAtLeastOnce: false,
                Item: new InsuranceList(),
                Groups: [],
                PaymentIntervals: []
        };

        this.form = new Form(props, this);

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            InsuranceListUrlPath.BASE
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1>
        );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.updateUtils.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------

    public getIdToBeUpdated() : number {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private insuranceListApiCallExecutor: InsuranceListApiCallExecutor<Update>;
    private insuranceListGroupApiCallExecutor: InsuranceListGroupApiCallExecutor<Update>;
    private insuranceListPaymentIntervalApiCallExecutor: InsuranceListPaymentIntervalApiCallExecutor<Update>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.insuranceListApiCallExecutor = 
            new InsuranceListApiCallExecutor(pointerToComponent);
        this.insuranceListGroupApiCallExecutor = 
            new InsuranceListGroupApiCallExecutor(pointerToComponent);
        this.insuranceListPaymentIntervalApiCallExecutor =
            new InsuranceListPaymentIntervalApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addInsuranceListGetById());
        orderOfCalls.push(this.addInsuranceListGroupGetAll());
        orderOfCalls.push(this.addInsuranceListPaymentIntervalGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "insuranceListItemId", 
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);     
    }

    // --------------------------------------------------------------------------------------------

    private addInsuranceListGetById() : IApiCallback {
        return {
            callback: this.insuranceListApiCallExecutor.getById,
            pointerToApiCall: this.insuranceListApiCallExecutor,
            variableNameInState: "Item"
        };
    }

    private addInsuranceListGroupGetAll() : IApiCallback {
        return {
            callback: this.insuranceListGroupApiCallExecutor.getAll,
            pointerToApiCall: this.insuranceListGroupApiCallExecutor,
            variableNameInState: "Groups"
        };
    }

    private addInsuranceListPaymentIntervalGetAll() : IApiCallback {
        return {
            callback: this.insuranceListPaymentIntervalApiCallExecutor.getAll,
            pointerToApiCall: this.insuranceListPaymentIntervalApiCallExecutor,
            variableNameInState: "PaymentIntervals"
        };
    }
}

export default Update;