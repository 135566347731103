import FormButtonUpdate from "../../../../commons/ui/page/template/form/button/FormButtonUpdate";
import UpdateBaseWithGroup from "../../../../commons/ui/page/template/update/UpdateBaseWithGroup";
import Utils from "../../../../utilities/Utils";
import PasswordListFolderApi from "../../../folder/api/PasswordListFolderApi";
import PasswordListFolder from "../../../folder/model/PasswordListFolder";
import PasswordListApi from "../../api/PasswordListApi";
import PasswordList from "../../model/PasswordList";
import PasswordListUrlPath from "../PasswordListUrlPath";
import Form from "./Form";

class Update extends UpdateBaseWithGroup<PasswordList, PasswordListFolder,
                                         PasswordListApi, PasswordListFolderApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(
            props,
            new PasswordList(),
            PasswordListApi.getInstance(),
            PasswordListFolderApi.getInstance(),
            idOfItemToBeUpdated,
            PasswordListUrlPath.BASE
        );

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;