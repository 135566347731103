import IModelMapper from '../../../commons/model/IModelMapper';
import PasswordListFolder from './PasswordListFolder';

class PasswordListFolderModelMapper implements IModelMapper<PasswordListFolder> {
    fromJsonToObject(json: any): PasswordListFolder {
        let passwordListFolder = new PasswordListFolder();

        passwordListFolder.id = json.id;
        passwordListFolder.name = json.name;

        return passwordListFolder;
    }

    fromObjectToJson(object: PasswordListFolder) {
        return {
            id: object.id,
            name: (object.name || "")
        };
    }
}

export default PasswordListFolderModelMapper;