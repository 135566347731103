import ActionBarButtonBase from '../../../../../commons/ui/page/actionBar/ActionBarButtonBase';

import I18nConstants from '../../i18n/I18nConstants';

import VehicleUrlPath from '../VehicleUrlPath';

class ActionBarButton extends ActionBarButtonBase {
    public constructor(propsHistory) {
        super(propsHistory,
              VehicleUrlPath.BASE,
              I18nConstants.NS,
              I18nConstants.TITLE_SHORT,
              I18nConstants.TITLE_SHORT_DEFAULT);
    }
}

export default ActionBarButton;