import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "passwordListItem";

    public static TITLE = "title";  
    
    public static FORM_FOLDER = "form.folder";
    public static FORM_DESCRIPTION = "form.description";
    public static FORM_USERNAME = "form.username";
    public static FORM_PASSWORD = "form.password";
    public static FORM_LINK = "form.link";
    public static FORM_NOTES = "form.notes";

    public static CLIPBOARD_USERNAMECOPIED = "clipboard.usernameCopied";
    public static CLIPBOARD_PASSWORDCOPIED = "clipboard.passwordCopied";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;

    public static FORM_FOLDER_DEFAULT = DefaultJson.form.folder;
    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
    public static FORM_USERNAME_DEFAULT = DefaultJson.form.username;
    public static FORM_PASSWORD_DEFAULT = DefaultJson.form.password;
    public static FORM_LINK_DEFAULT = DefaultJson.form.link;
    public static FORM_NOTES_DEFAULT = DefaultJson.form.notes;

    public static CLIPBOARD_USERNAMECOPIED_DEFAULT = DefaultJson.clipboard.usernameCopied;
    public static CLIPBOARD_PASSWORDCOPIED_DEFAULT = DefaultJson.clipboard.passwordCopied;
}

export default I18nConstants;