import React from "react";
import { Trans } from "react-i18next";
import OverviewBase from "../../../../commons/ui/page/template/overview/OverviewBase";
import ClipboardApi from "../../../../utilities/ClipboardApi";
import MessageToastApi from "../../../../utilities/MessageToastApi";
import PasswordListFolderUrlPath from "../../../folder/ui/PasswordListFolderUrlPath";
import PasswordListApi from "../../api/PasswordListApi";
import I18nConstants from "../../i18n/I18nConstants";
import PasswordList from "../../model/PasswordList";
import PasswordListUrlPath from "../PasswordListUrlPath";

class Overview extends OverviewBase<PasswordList, PasswordListApi> {
    public constructor(props) {
        super(
            props,
            PasswordListApi.getInstance()
        );

        this.setCreateUrl(PasswordListUrlPath.CREATE);
        this.setGroupsOverviewUrl(PasswordListFolderUrlPath.BASE);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE}>
                    {I18nConstants.TITLE_DEFAULT}
                </Trans>
            </h1> );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_FOLDER}>
                        {I18nConstants.FORM_FOLDER_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_USERNAME}>
                        {I18nConstants.FORM_USERNAME_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_PASSWORD}>
                        {I18nConstants.FORM_PASSWORD_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_LINK}>
                        {I18nConstants.FORM_LINK_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_NOTES}>
                        {I18nConstants.FORM_NOTES_DEFAULT}
                    </Trans>
                </th>

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr> );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).folder.name}
                </td>

                <td>
                    {(item as any).description}
                </td>

                <td>
                    <span onClick={ (event) => { 
                            ClipboardApi.getInstance().copyFromTextContentValue(event); 
                            MessageToastApi.getInstance().showI18nTextOnBottomCenter(
                                I18nConstants.NS, I18nConstants.CLIPBOARD_USERNAMECOPIED
                            );
                          } }>
                        {(item as any).username}
                    </span>
                </td>

                <td>
                    <span title={(item as any).password} 
                          onClick={ (event) => { 
                              ClipboardApi.getInstance().copyFromTitleAttributeValue(event);
                              MessageToastApi.getInstance().showI18nTextOnBottomCenter(
                                  I18nConstants.NS, I18nConstants.CLIPBOARD_PASSWORDCOPIED
                              );
                          } }>
                        **********
                    </span>
                </td>

                <td>
                    <a href={(item as any).link} target="_blank">{(item as any).link}</a>
                </td>

                <td>
                    {(item as any).notes}
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }

    protected getActionBar() {
        return (
            <div>
                { this.getGroupsOverviewButton() }
            </div> 
        );
    }
}

export default Overview;