import I18nConstants from '../../../../../i18n/I18nConstantsBase';
import OverviewHeadingBase from "./OverviewHeadingBase";

class OverviewHeadingUpdate extends OverviewHeadingBase {
    public static render() {
        return OverviewHeadingBase.renderByI18nKeyAndDefaultValue(
                    I18nConstants.ACTION_UPDATE,
                    I18nConstants.ACTION_UPDATE_DEFAULT
        );
    }
}

export default OverviewHeadingUpdate;