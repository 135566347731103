import ToDoList from '../../model/ToDoList';
import ToDoListGroup from '../../../group/model/ToDoListGroup';
import ToDoListApi from '../../api/ToDoListApi';
import ToDoListGroupApi from '../../../group/api/ToDoListGroupApi';
import ToDoListUrlPath from '../ToDoListUrlPath';
import CreateBaseWithGroup from '../../../../commons/ui/page/template/create/CreateBaseWithGroup';
import Form from './Form';
import FormButtonCreate from '../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBaseWithGroup<ToDoList, ToDoListGroup, 
                                         ToDoListApi, ToDoListGroupApi> {
    private form: Form;

    public constructor(props) {
        super(props, 
              new ToDoList(),
              ToDoListApi.getInstance(),
              ToDoListGroupApi.getInstance(),
              ToDoListUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;