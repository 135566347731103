import IModelMapper from '../../../../commons/model/IModelMapper';

import ExpenseGroup from './ExpenseGroup';

class ExpenseGroupModelMapper implements IModelMapper<ExpenseGroup> {
    fromJsonToObject(json: any): ExpenseGroup {
        let expenseGroup = new ExpenseGroup();

        expenseGroup.id   = json.id;
        expenseGroup.name = json.name;

        return expenseGroup;
    }

    fromObjectToJson(object: ExpenseGroup) {
        return {
            id:   object.id,
            name: (object.name || "") };
    }
}

export default ExpenseGroupModelMapper;