import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import FortuneSavingPlanGroupModelMapper from '../model/FortuneSavingPlanGroupModelMapper';

class FortuneSavingPlanGroupApi implements IGenericApi {
    private static instance: FortuneSavingPlanGroupApi;

    private static apiUrlPath = "fortune/savingPlan/group";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): FortuneSavingPlanGroupApi {
        if (!FortuneSavingPlanGroupApi.instance) {
            FortuneSavingPlanGroupApi.instance = new FortuneSavingPlanGroupApi();
        }

        return FortuneSavingPlanGroupApi.instance;
    }
    
    public getAll() {
        return GenericApi.executeGetAllCall(
                    FortuneSavingPlanGroupApi.apiUrlPath,
                    new FortuneSavingPlanGroupModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    FortuneSavingPlanGroupApi.apiUrlPath,
                    id,
                    new FortuneSavingPlanGroupModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    FortuneSavingPlanGroupApi.apiUrlPath,
                    body,
                    new FortuneSavingPlanGroupModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    FortuneSavingPlanGroupApi.apiUrlPath,
                    id,
                    body,
                    new FortuneSavingPlanGroupModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    FortuneSavingPlanGroupApi.apiUrlPath,
                    id,
                    new FortuneSavingPlanGroupModelMapper());
    }

    public getUrl() {
        return FortuneSavingPlanGroupApi.apiUrlPath;
    }
}

export default FortuneSavingPlanGroupApi;