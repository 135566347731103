import IModelMapper from "../../../commons/model/IModelMapper";
import FortuneInvestmentPlan from './FortuneInvestmentPlan';

class FortuneInvestmentPlanModelMapper implements IModelMapper<FortuneInvestmentPlan> {
    fromJsonToObject(json: any): FortuneInvestmentPlan {
        let fortuneInvestmentPlan = new FortuneInvestmentPlan();

        fortuneInvestmentPlan.id =                json.id;
        fortuneInvestmentPlan.priority =          json.priority;
        fortuneInvestmentPlan.description =       json.description;
        fortuneInvestmentPlan.issueFee =          json.issueFee;
        fortuneInvestmentPlan.ter =               json.ter;
        fortuneInvestmentPlan.fondsAmountInMio =  json.fondsAmountInMio;
        fortuneInvestmentPlan.distributionType =  json.distributionType;
        fortuneInvestmentPlan.replicationMethod = json.replicationMethod;
        fortuneInvestmentPlan.bankName =          json.bankName;
        fortuneInvestmentPlan.vendor =            json.vendor;
        fortuneInvestmentPlan.countryLocation =   json.countryLocation;
        fortuneInvestmentPlan.isin =              json.isin;
        fortuneInvestmentPlan.firstIssueDate =    json.firstIssueDate;
        fortuneInvestmentPlan.url =               json.url;

        return fortuneInvestmentPlan;
    }

    fromObjectToJson(object: FortuneInvestmentPlan) {
        return {
            id:                object.id,
            priority:          (object.priority || undefined),
            description:       (object.description || ""),
            issueFee:          (object.issueFee || 0),
            ter:               (object.ter || 0),
            fondsAmountInMio:  (object.fondsAmountInMio || 0),
            distributionType:  (object.distributionType || ""),
            replicationMethod: (object.replicationMethod || ""),
            bankName:          (object.bankName || ""),
            vendor:            (object.vendor || ""),
            countryLocation:   (object.countryLocation || ""),
            isin:              (object.isin || ""),
            firstIssueDate:    (object.firstIssueDate || "0001-01-01"),
            url:               (object.url || "")
        };
    }
}

export default FortuneInvestmentPlanModelMapper;