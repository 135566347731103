import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupRiskClassDropdown extends FormGroupBase {
    private ID_AND_NAME = "riskClass";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const riskClassesSelectValues = this.getRiskClassesSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_RISK_CLASS,
                    I18nConstants.FORM_RISK_CLASS_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.riskClass.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {riskClassesSelectValues}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getRiskClassesSelectValues() {
        const { riskClasses } = this.pointerToComponent.state;

        return this.getSelectValues(riskClasses, "name");
    }
}

export default FormGroupRiskClassDropdown;