import React from 'react';
import { Trans } from 'react-i18next';

import I18nConstants from '../../../../item/i18n/I18nConstants';

import Utils from '../../../../../utilities/Utils';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import ExpenseOverheadApiCallExecutor from '../../api/ExpenseOverheadApiCallExecutor';
import ExpenseOverheadGroupApiCallExecutor from '../../../group/api/ExpenseOverheadGroupApiCallExecutor';

import ExpenseOverhead from '../../model/ExpenseOverhead';
import ExpenseOverheadApi from '../../api/ExpenseOverheadApi';

import OverviewBase from '../../../../../commons/ui/page/template/overview/OverviewBase';

import PieChartWithSumAndDiagram from '../../../../../commons/ui/page/diagram/PieChartWithSumAndPercentage';

class OverviewGrouped extends OverviewBase<ExpenseOverhead, ExpenseOverheadApi> {
    private apiCalls: ApiCallExecutor;
    private diagram: PieChartWithSumAndDiagram;

    public constructor(props) {
        super(props,
              ExpenseOverheadApi.getInstance());

        this.diagram = new PieChartWithSumAndDiagram();
        this.apiCalls = new ApiCallExecutor(this, this.diagram);

        this.setShowNavbar(false);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.EXPENSES}>
                    {I18nConstants.EXPENSES_DEFAULT}
                </Trans>
            </h1> );
    }

    protected getSum() {
        return (
            <div>
                <p>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.SUM}>
                        {I18nConstants.SUM_DEFAULT}
                    </Trans>
                    : {this.state.sum}</p>
            </div> );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_PERCENTAGE}>
                        {I18nConstants.FORM_PERCENTAGE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_AMOUNT}>
                        {I18nConstants.FORM_AMOUNT_DEFAULT}
                    </Trans>
                </th>
            </tr> );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).name}
                </td>

                <td>
                    { Utils.roundTwoDecimalPlaces((item as any).sum * 100 / this.state.sum) }
                </td>

                <td>
                    {(item as any).sum}
                </td>
            </tr>);
    }

    protected getDiagramAfterContent() {
        return this.diagram.getHtmlElement(this.state.diagram);
    }

    protected getInitialValuesForListInState() {
        this.apiCalls.executeAll();
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: OverviewGrouped;
    private pointerToDiagram: PieChartWithSumAndDiagram;

    private helper: ApiCallExecutorHelper;

    private expenseOverheadApiCallExecutor: ExpenseOverheadApiCallExecutor<OverviewGrouped>;
    private expenseOverheadGroupApiCallExecutor: ExpenseOverheadGroupApiCallExecutor<OverviewGrouped>;

    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.expenseOverheadApiCallExecutor = new ExpenseOverheadApiCallExecutor(pointerToComponent);
        this.expenseOverheadGroupApiCallExecutor = new ExpenseOverheadGroupApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addExpenseOverheadGetSum());
        orderOfCalls.push(this.addExpenseOverheadGroupGetSumAsList());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                this.pointerToComponent,
                                this.helper, 
                                orderOfCalls, 
                                parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addExpenseOverheadGetSum() : IApiCallback {
        return {
            callback: this.expenseOverheadApiCallExecutor.getSum,
            pointerToApiCall: this.expenseOverheadApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addExpenseOverheadGroupGetSumAsList() : IApiCallback {
        return {
            callback: this.expenseOverheadGroupApiCallExecutor.getSumAsList,
            pointerToApiCall: this.expenseOverheadGroupApiCallExecutor,
            variableNameInState: "List"
        };
    }

    private addDiagramData() : IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagram"
        };
    }
}

export default OverviewGrouped;