import React from 'react';

import FormGroupDescription from '../fragment/FormGroupDescription';
import FormGroupGroupDropdown from '../fragment/FormGroupGroupDropdown';
import FormGroupToDoUntil from '../fragment/FormGroupToDoUntil';
import FormGroupStatusInPercent from '../fragment/FormGroupStatusInPercent';

class Form {
    private fromGroupDescription:     FormGroupDescription;
    private formGroupGroup:           FormGroupGroupDropdown;
    private formGroupToDoUntil:       FormGroupToDoUntil;
    private formGroupStatusInPercent: FormGroupStatusInPercent;

    public constructor(props, pointerToComponent) {
        this.fromGroupDescription     = new FormGroupDescription(props, pointerToComponent);
        this.formGroupGroup           = new FormGroupGroupDropdown(props, pointerToComponent);
        this.formGroupToDoUntil       = new FormGroupToDoUntil(props, pointerToComponent);
        this.formGroupStatusInPercent = new FormGroupStatusInPercent(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.fromGroupDescription.render() }
                { this.formGroupGroup.render() }
                { this.formGroupToDoUntil.render() }
                { this.formGroupStatusInPercent.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.fromGroupDescription.isInvalidValue()  
             || this.formGroupGroup.isInvalidValue()
             || this.formGroupToDoUntil.isInvalidValue()
             || this.formGroupStatusInPercent.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;