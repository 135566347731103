import I18nConstantsBase from '../../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "fortuneSavingPlanItem";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static FORM_GROUP = "form.group";
    public static FORM_PRIORITY = "form.priority";
    public static FORM_DESCRIPTION = "form.description";
    public static FORM_MONTHLY_RATE = "form.monthlyRate";
    public static FORM_PLANNED_END_DATE = "form.plannedEndDate";
    public static FORM_PLANNED_AMOUNT_MIN = "form.plannedAmountMin";
    public static FORM_PLANNED_AMOUNT_MAX = "form.plannedAmountMax";
    public static FORM_INVESTMENT_PLAN = "form.investmentPlan";
    public static FORM_ADD_TO_FORTUNE = "form.addToFortune";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static FORM_GROUP_DEFAULT = DefaultJson.form.group;
    public static FORM_PRIORITY_DEFAULT = DefaultJson.form.priority;
    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
    public static FORM_MONTHLY_RATE_DEFAULT = DefaultJson.form.monthlyRate;
    public static FORM_PLANNED_END_DATE_DEFAULT = DefaultJson.form.plannedEndDate;
    public static FORM_PLANNED_AMOUNT_MIN_DEFAULT = DefaultJson.form.plannedAmountMin;
    public static FORM_PLANNED_AMOUNT_MAX_DEFAULT = DefaultJson.form.plannedAmountMax;
    public static FORM_INVESTMENT_PLAN_DEFAULT = DefaultJson.form.investmentPlan;
    public static FORM_ADD_TO_FORTUNE_DEFAULT = DefaultJson.form.addToFortune;
}

export default I18nConstants;