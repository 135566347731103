import ApiCallExecutorBase from "../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../commons/ui/page/apiCallExecutor/IApiCallContext';

import EarningsExpensesOverheadApi from './EarningsExpensesOverheadApi';

import Utils from "../../../utilities/Utils";

class EarningsExpensesOverheadApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private earningsExpensesOverheadApi: EarningsExpensesOverheadApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.earningsExpensesOverheadApi = EarningsExpensesOverheadApi.getInstance();
    }

    public getSum(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.earningsExpensesOverheadApi.getSum()
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }

    public getSavingRatio(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.earningsExpensesOverheadApi.getSavingRatio()
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    Utils.roundTwoDecimalPlaces(response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }
}

export default EarningsExpensesOverheadApiCallExecutor;