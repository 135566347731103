import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';

import VehicleTypeModelMapper from '../model/VehicleTypeModelMapper';

class VehicleTypeApi implements IGenericApi {
    private static instance: VehicleTypeApi;

    private static apiUrlPath = "fuelConsumption/vehicle/type";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): VehicleTypeApi {
        if (!VehicleTypeApi.instance) {
            VehicleTypeApi.instance = new VehicleTypeApi();
        }

        return VehicleTypeApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    VehicleTypeApi.apiUrlPath,
                    new VehicleTypeModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    VehicleTypeApi.apiUrlPath,
                    id,
                    new VehicleTypeModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    VehicleTypeApi.apiUrlPath,
                    body,
                    new VehicleTypeModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    VehicleTypeApi.apiUrlPath,
                    id,
                    body,
                    new VehicleTypeModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    VehicleTypeApi.apiUrlPath,
                    id,
                    new VehicleTypeModelMapper());
    }

    public getUrl() {
        return VehicleTypeApi.apiUrlPath;
    }
}

export default VehicleTypeApi;