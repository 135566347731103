import LinkList from '../../model/LinkList';
import LinkListUrlPath from '../LinkListUrlPath';
import LinkListGroup from '../../../group/model/LinkListGroup';
import LinkListApi from '../../api/LinkListApi';
import LinkListGroupApi from '../../../group/api/LinkListGroupApi';
import CreateBaseWithGroup from '../../../../commons/ui/page/template/create/CreateBaseWithGroup';
import Form from './Form';
import FormButtonCreate from '../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBaseWithGroup<LinkList, LinkListGroup, 
                                         LinkListApi, LinkListGroupApi> {
    private form: Form;

    public constructor(props) {
        super(props,
              new LinkList(),
              LinkListApi.getInstance(),
              LinkListGroupApi.getInstance(),
              LinkListUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;