import Bike from "../../bike/item/model/Bike";

class CyclingTour {
    id!: number;
    bike!: Bike;
    date!: Date;
    tripDistanceInKm!: number;
    rideTime!: string;
    averageSpeedInKmh!: number;
    maxSpeedInKmh!: number;
    averagePowerInWatt!: number;
    altitudeAscendingInM!: number;
    distanceAscendingInKm!: number;
    maxAltitudeInM!: number;
    averageHeartRate!: number;
    maxHeartRate!: number;
    kiloCalories!: number;
    note!: string;

    public constructor() {
        this.bike = new Bike();
    }
}

export default CyclingTour;