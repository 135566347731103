import IApiCallContext from "./IApiCallContext";
import IApiCallback from "./IApiCallback";

class ApiCallExecutorHelper {
    public createContext(pointerToComponent: any,
                         pointerToHelper: any, 
                         successcors : Array<IApiCallback>, 
                         parameters: Array<any>) : IApiCallContext {
        let currentData: IApiCallback = ({ } as IApiCallback);

        let context = {
            pointerToComponent: pointerToComponent,
            pointerToHelper: pointerToHelper,
            currentData: currentData,
            successors: successcors,
            parameters: parameters
        };

        return context;
    }

    public startWithFirstCall(context: IApiCallContext) {
        let first = this.getCurrentSuccessor(context);

        first.callback(context);
    }

    public getCurrentSuccessorAndRemoveFromList(context: IApiCallContext) {
        let currentSuccessor = this.getCurrentSuccessor(context);

        // Store current successor in another variable.
        context.currentData = currentSuccessor;

        // Remove the first item from order, 
        // because it will be processed within this API-call.
        context.successors.shift();

        // Get the current successor, after removing, again.
        currentSuccessor = this.getCurrentSuccessor(context);

        return currentSuccessor;
    }

    public getCurrentSuccessor(context: IApiCallContext) {
        return context.successors[0];
    }

    public addParameterToContext(context: IApiCallContext, key: String, value: {}) {
        let parameterObject = {};

        parameterObject[(key as any)] = value;
        
        let foundParameter = this.getParameterInArrayWithObjects(context.parameters, key);

        if (    (foundParameter) 
             && (Object.keys(foundParameter).length === 0)
             && (foundParameter.constructor === Object)) {
            context.parameters.push(parameterObject);
        } else if (foundParameter === undefined) {
            context.parameters.push(parameterObject);
        } else {
            console.log("TODO");
        }
    }

    public getParameterInArrayWithObjects(parameters: Array<any>, key: String) {
        let foundParameter = {};

        parameters.forEach( element => {
            if (element[(key as any)]) {
                foundParameter = element[(key as any)];
            }
        });

        return foundParameter;
    }

    public setIsLoadingToFalseIfNecessary(error: any, context: IApiCallContext) {
        if (    (error instanceof TypeError)
             && (error.message.includes("undefined"))) {
            context.pointerToComponent.setState({
                isLoading: false
            });
        }        
    }

    public createStateObject(key: string, value: any) {
        let stateObject = {};

        stateObject[(key as any)] = value;
        
        return stateObject;
    }
}

export default ApiCallExecutorHelper;