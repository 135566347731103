import UpdateBase from '../../../../commons/ui/page/template/group/update/UpdateBase';

import Tire from '../../model/Tire';
import TireApi from '../../api/TireApi';
import TireUrlPath from '../TireUrlPath';

class Update extends UpdateBase<Tire, TireApi> {
    public constructor(props) {
        super(props, 
              new Tire(),
              TireApi.getInstance(),
              TireUrlPath.BASE);
    }
}

export default Update;