import FortuneExpensePlannedGroup from '../../model/FortuneExpensePlannedGroup';
import FortuneExpensePlannedGroupApi from '../../api/FortuneExpensePlannedGroupApi';

import OverviewBase from '../../../../../commons/ui/page/template/group/overview/OverviewBase';

class Overview extends OverviewBase<FortuneExpensePlannedGroup, FortuneExpensePlannedGroupApi> {
    public constructor(props) {
        super(props,
              FortuneExpensePlannedGroupApi.getInstance());
    }
}

export default Overview;