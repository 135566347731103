import React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Trans } from 'react-i18next';
import i18n from '../../../../commons/i18n/i18n';
import I18nConstants from '../../i18n/I18nConstants';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneSavingRatioApiCallExecutor from '../../api/FortuneSavingRatioApiCallExecutor';
import Utils from '../../../../utilities/Utils';
import AuthenticationApi from '../../../../security/api/AuthenticationApi';
import FortuneSavingRatioApi from '../../api/FortuneSavingRatioApi';
import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';
import FortuneSavingRatioUrlPath from '../FortuneSavingRatioUrlPath';
import LoginUrlPath from '../../../../login/ui/LoginUrlPath';
import OverviewUtils from '../../../../commons/ui/page/template/overview/OverviewUtils';
import XYCoordinateSystemTime from '../../../../commons/ui/page/diagram/XYCoordinateSystemTime';
import FortuneOverviewActionBarButton from '../../../dashboard/ui/fragment/ActionBarButton';
import NavigationRenderer from '../../../../commons/ui/fragment/NavigationRenderer';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:            boolean;
    yearsAsString:        string[];
    currentYearSelection: string;
    averageInYear:        number;
    averageTotal:         number;
    List:                 [];
    diagram:              [];
    ListTotal:            [];
    diagramTotal:         [];
}

class Overview extends Component<IProps, IState> {
    private api: FortuneSavingRatioApi;
    private apiCalls: ApiCallExecutor;

    private diagramCurrentYear: DiagramCurrentYear;
    private diagramAllYears: DiagramAllYears;

    private overviewUtils:      OverviewUtils;

    private fortuneOverviewActionBarButton: FortuneOverviewActionBarButton;

    public constructor(props) {
        super(props);

        this.diagramCurrentYear = new DiagramCurrentYear();
        this.diagramAllYears = new DiagramAllYears();

        this.api = FortuneSavingRatioApi.getInstance();

        this.apiCalls = new ApiCallExecutor(this, this.diagramCurrentYear, this.diagramAllYears);

        this.fortuneOverviewActionBarButton = new FortuneOverviewActionBarButton(props.history);

        this.state = {
            isLoading:            true,
            yearsAsString:        [],
            currentYearSelection: "",
            averageInYear:        0.0,
            averageTotal:         0.0,
            List:                 [],
            diagram:              [],
            ListTotal:            [],
            diagramTotal:         []          
        };

        this.overviewUtils = new OverviewUtils(
            this,
            null,   // No heading should be displayed, here (already printed in another position)
            FortuneSavingRatioUrlPath.CREATE,
            FortuneSavingRatioUrlPath.UPDATE,
            undefined,
            false,
            this.getActionBar());

        this.handleChange = this.handleChange.bind(this);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            let selectValues = this.getGroupSelectValues();
            let diagram      = this.getDiagramDataElement();
            let diagramTotal = this.getDiagramDataTotalElement();

            return (
                <div>
                    { NavigationRenderer.renderIfNecessary(true) }

                    { this.getHeading() }

                    <Input
                        type="select"
                        name="years"
                        id="years"
                        value={this.state.currentYearSelection}
                        onChange={this.handleChange}>
                        {selectValues}
                    </Input>

                    <p>
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.AVERAGE_CURRENT_YEAR}>
                            {I18nConstants.AVERAGE_CURRENT_YEAR_DEFAULT}
                        </Trans> 
                        :&nbsp;
                        {Utils.roundTwoDecimalPlaces(this.state.averageInYear)}
                    </p>

                    <p>
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.AVERAGE_TOTAL}>
                            {I18nConstants.AVERAGE_TOTAL_DEFAULT}
                        </Trans> 
                        :&nbsp;
                        {Utils.roundTwoDecimalPlaces(this.state.averageTotal)}
                    </p>

                    { this.overviewUtils.render(
                        this.getTableHead(),
                        this.getTableBody()) }

                    {diagram}
                    {diagramTotal}
                </div> );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return(                    
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> ) ;
    }

    private getActionBar() {
        return (
            <div>
                { this.fortuneOverviewActionBarButton.render() }
            </div> );
    }

    private getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_MONTH}>
                        {I18nConstants.FORM_MONTH_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_SAVING_RATIO}>
                        {I18nConstants.FORM_SAVING_RATIO_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_EARNINGS}>
                        {I18nConstants.FORM_EARNINGS_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_EXPENSES}>
                        {I18nConstants.FORM_EXPENSES_DEFAULT}
                    </Trans>
                </th>

                { this.overviewUtils.getUpdateHeadingWrappedInThTag() }
                { this.overviewUtils.getDeleteHeadingWrappedInThTag() }
            </tr> );
    }

    private getTableBody() {
        return this.state.List.map( item => 
            <tr key={(item as any).id}>
                <td>
                    {(item as any).month}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(((((item as any).earnings - (item as any).expenses) * 100) / (item as any).earnings))}
                </td>

                <td>
                    {(item as any).earnings}
                </td>

                <td>
                    {(item as any).expenses}
                </td>

                { this.overviewUtils.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.overviewUtils.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }

    private getDiagramDataElement() {
         const xAxisTitle = i18n.t(I18nConstants.NS + ":" + I18nConstants.DIAGRAM_DATE);
         const yAxisTitle = i18n.t(I18nConstants.NS + ":" + I18nConstants.DIAGRAM_PERCENTAGE);

        return this.diagramCurrentYear.getHtmlElement(
                    this.state.diagram,
                    xAxisTitle,
                    yAxisTitle
                );
    }

    private getDiagramDataTotalElement() {
        const xAxisTitle = i18n.t(I18nConstants.NS + ":" + I18nConstants.DIAGRAM_DATE);
        const yAxisTitle = i18n.t(I18nConstants.NS + ":" + I18nConstants.DIAGRAM_PERCENTAGE);

       return this.diagramAllYears.getHtmlElement(
                   this.state.diagramTotal,
                   xAxisTitle,
                   yAxisTitle
               );
    }

    // --------------------------------------------------------------------------------------------

    private getGroupSelectValues() {
        let yearsAsString = this.state.yearsAsString;

        let years = yearsAsString.map( element => 
            <option value={element}>{element}</option> );

        return years;
    }

    // --------------------------------------------------------------------------------------------

    private handleChange(event) {
        const target = event.target;

        let currentYearSelection = target.value;

        this.getCurrentAverageAndSetToState(currentYearSelection);
    }

    private async getCurrentAverageAndSetToState(yearSelection) {
        this.apiCalls.executeStaringAfterYearSelection(yearSelection);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private diagramCurrentYear: DiagramCurrentYear;
    private diagramAllYears: DiagramAllYears;

    private helper: ApiCallExecutorHelper;

    private fortuneSavingRatioApiCallExecutor: FortuneSavingRatioApiCallExecutor<Overview>;

    public constructor(pointerToComponent, diagramCurrentYear, diagramAllYears) {
        this.pointerToComponent = pointerToComponent;

        this.diagramCurrentYear = diagramCurrentYear;
        this.diagramAllYears = diagramAllYears;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneSavingRatioApiCallExecutor = 
                new FortuneSavingRatioApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneSavingRatioGetYearsAsString());
        orderOfCalls.push(this.addFortuneSavingRatioSetInitialYearSelectionToParametersAndState());
        orderOfCalls.push(this.addFortuneSavingRatioGetAverageByYear());
        orderOfCalls.push(this.addFortuneSavingRatioGetAverageTotal());
        orderOfCalls.push(this.addFortuneSavingRatioGetByYear());
        orderOfCalls.push(this.addFortuneSavingRatioGetAll());
        orderOfCalls.push(this.addDiagramDataCurrentYearSetToParameters());
        orderOfCalls.push(this.addDiagramDataAllYearsSetToParameters());
        orderOfCalls.push(this.addDiagramDataCurrentYear());
        orderOfCalls.push(this.addDiagramDataAllYears());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    public executeStaringAfterYearSelection(year) {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneSavingRatioSetYearSelectionFromParametersToState());
        orderOfCalls.push(this.addFortuneSavingRatioGetAverageByYear());
        orderOfCalls.push(this.addFortuneSavingRatioGetByYear());
        orderOfCalls.push(this.addDiagramDataCurrentYearSetToParameters());
        orderOfCalls.push(this.addDiagramDataAllYearsSetToParameters());
        orderOfCalls.push(this.addDiagramDataCurrentYear());
        orderOfCalls.push(this.addDiagramDataAllYears());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "year", year);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneSavingRatioGetYearsAsString() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.getYearsAsString,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "yearsAsString"
        };
    }

    private addFortuneSavingRatioSetInitialYearSelectionToParametersAndState() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.setInitialYearSelectionToParametersAndState,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "currentYearSelection"
        };
    }

    private addFortuneSavingRatioSetYearSelectionFromParametersToState() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.setYearSelectionFromParametersToState,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "currentYearSelection"
        };
    }

    private addFortuneSavingRatioGetAverageByYear() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.getAverageByYear,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "averageInYear"
        };
    }

    private addFortuneSavingRatioGetAverageTotal() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.getAverageTotal,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "averageTotal"
        };
    }

    private addFortuneSavingRatioGetByYear() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.getByYear,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "List"
        };
    }

    private addFortuneSavingRatioGetAll() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.getAll,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "ListTotal"
        }
    }

    private addDiagramDataCurrentYearSetToParameters() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.setDiagramDataCurrentYearToParameters,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "listDataForDiagramCurrentYear"
        };
    }

    private addDiagramDataAllYearsSetToParameters() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.setDiagramDataAllYearsToParameters,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "listDataForDiagramAllYears"
        };
    }

    private addDiagramDataCurrentYear() : IApiCallback {
        return {
            callback: this.diagramCurrentYear.updateDiagramData,
            pointerToApiCall: this.diagramCurrentYear,
            variableNameInState: "diagram"
        };
    }

    private addDiagramDataAllYears() : IApiCallback {
        return {
            callback: this.diagramAllYears.updateDiagramData,
            pointerToApiCall: this.diagramAllYears,
            variableNameInState: "diagramTotal"
        }
    }
}

// ================================================================================================

class DiagramCurrentYear extends XYCoordinateSystemTime {
    public updateDiagramData(context: IApiCallContext) {
        let date;

        let month       = 0;
        let year        = 0;
        let day         = 1;
        let savingRatio = 0;

        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const listData = (context.pointerToHelper.getParameterInArrayWithObjects(
                                context.parameters, "listDataForDiagramCurrentYear") as any);

        const diagramData = listData.map( item => {
            month       = item.month;
            year        = item.year;
            savingRatio = (item.earnings - item.expenses) * 100 / item.earnings;
            savingRatio = Utils.roundTwoDecimalPlaces(savingRatio);

            date = Utils.createDateByDayMonthYear(day, month, year);

            return { x: date,
                     y: savingRatio };
        });

        const state = context.pointerToHelper.createStateObject(
                            context.currentData.variableNameInState,
                            diagramData);

        context.pointerToComponent.setState(state);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }
}

// ================================================================================================

class DiagramAllYears extends XYCoordinateSystemTime {
    public updateDiagramData(context: IApiCallContext) {
        let date;

        let month       = 0;
        let year        = 0;
        let day         = 1;
        let savingRatio = 0;

        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const listData = (context.pointerToHelper.getParameterInArrayWithObjects(
                                context.parameters, "listDataForDiagramAllYears") as any);

        const diagramData = listData.map(item => {
            month       = item.month;
            year        = item.year;
            savingRatio = (item.earnings - item.expenses) * 100 / item.earnings;
            savingRatio = Utils.roundTwoDecimalPlaces(savingRatio);

            date = Utils.createDateByDayMonthYear(day, month, year);

            return { x: date,
                     y: savingRatio };
        });

        const state = context.pointerToHelper.createStateObject(
                            context.currentData.variableNameInState,
                            diagramData);

        context.pointerToComponent.setState(state);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }
}

export default Overview;