import OverviewBase from '../../../../../commons/ui/page/template/group/overview/OverviewBase';
import BikeType from '../../model/BikeType';
import BikeTypeApi from '../../api/BikeTypeApi';

class Overview extends OverviewBase<BikeType, BikeTypeApi> {
    public constructor(props) {
        super(props,
              BikeTypeApi.getInstance()
        );
    }
}

export default Overview;