import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupPriorityDropdown extends FormGroupBase {
    private ID_AND_NAME = "priority";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const prioritiesSelectValues = this.getPrioritiesSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_PRIORITY,
                    I18nConstants.FORM_PRIORITY_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.priority.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {prioritiesSelectValues}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getPrioritiesSelectValues() {
        const { Priorities } = this.pointerToComponent.state;

        return this.getSelectValues(Priorities, "description");
    }
}

export default FormGroupPriorityDropdown;