import Utils from '../../../../../utilities/Utils';
import FortuneExpensePlanned from '../../model/FortuneExpensePlanned';
import FortuneExpensePlannedGroup from '../../../group/model/FortuneExpensePlannedGroup';
import FortuneExpensePlannedUrlPath from '../FortuneExpensePlannedUrlPath';
import FortuneExpensePlannedApi from '../../api/FortuneExpensePlannedApi';
import FortuneExpensePlannedGroupApi from '../../../group/api/FortuneExpensePlannedGroupApi';
import UpdateBaseWithGroup from '../../../../../commons/ui/page/template/update/UpdateBaseWithGroup';
import Form from './Form';
import FormButtonUpdate from '../../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBaseWithGroup<FortuneExpensePlanned,
                                         FortuneExpensePlannedGroup,
                                         FortuneExpensePlannedApi,
                                         FortuneExpensePlannedGroupApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new FortuneExpensePlanned(),
              FortuneExpensePlannedApi.getInstance(),
              FortuneExpensePlannedGroupApi.getInstance(),
              idOfItemToBeUpdated,
              FortuneExpensePlannedUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;