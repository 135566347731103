import FormEventHandlerUpdate from './FormEventHandlerUpdate';

class FormEventHandlerUpdateGroup extends FormEventHandlerUpdate {
    public constructor(pointerToComponent, api, nextPageUrl) {
        super(pointerToComponent, api, nextPageUrl);
    }

    protected getValueFromState() {
        return (this.pointerToComponent as any).state.Group;
    }

    protected setValueToState(value) {
        (this.pointerToComponent as any).setState({ Group: value });
    }
}

export default FormEventHandlerUpdateGroup;