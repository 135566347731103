import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupPaymentIntervalDropdown extends FormGroupBase {
    private ID_AND_NAME = "paymentInterval";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const paymentIntervalValues = this.getPaymentIntervalSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_PAYMENTINTERVAL,
                    I18nConstants.FORM_PAYMENTINTERVAL_DEFAULT) }

                <Input 
                    type="select" 
                    name={this.ID_AND_NAME} 
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.paymentInterval.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                        {paymentIntervalValues}
                </Input>
            </FormGroup> 
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getPaymentIntervalSelectValues() {
        const { PaymentIntervals } = this.pointerToComponent.state;

        return this.getSelectValues(PaymentIntervals, "name");
    }
}

export default FormGroupPaymentIntervalDropdown;