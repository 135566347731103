import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupCountryDropdown extends FormGroupBase {
    private ID_AND_NAME = "country";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const countries = this.getCountriesSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_COUNTRY,
                    I18nConstants.FORM_COUNTRY_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.country.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {countries}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getCountriesSelectValues() {
        const { Countries } = this.pointerToComponent.state;

        return this.getSelectValues(Countries, "name");
    }
}

export default FormGroupCountryDropdown;