import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';

import DrivingStyleModelMapper from '../model/DrivingStyleModelMapper';

class DrivingStyleApi implements IGenericApi {
    private static instance: DrivingStyleApi;

    private static apiUrlPath = "/fuelConsumption/drivingStyle";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): DrivingStyleApi {
        if (!DrivingStyleApi.instance) {
            DrivingStyleApi.instance = new DrivingStyleApi();
        }

        return DrivingStyleApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    DrivingStyleApi.apiUrlPath,
                    new DrivingStyleModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    DrivingStyleApi.apiUrlPath,
                    id,
                    new DrivingStyleModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    DrivingStyleApi.apiUrlPath,
                    body,
                    new DrivingStyleModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    DrivingStyleApi.apiUrlPath,
                    id,
                    body,
                    new DrivingStyleModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    DrivingStyleApi.apiUrlPath,
                    id,
                    new DrivingStyleModelMapper());
    }

    public getUrl() {
        return DrivingStyleApi.apiUrlPath;
    }
}

export default DrivingStyleApi;