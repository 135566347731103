import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import LetterSenderPersonModelMapper from '../model/LetterSenderPersonModelMapper';

class LetterSenderPersonApi implements IGenericApi {
    private static instance: LetterSenderPersonApi;

    private static apiUrlPath = "/letter/sender/person";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): LetterSenderPersonApi {
        if (!LetterSenderPersonApi.instance) {
            LetterSenderPersonApi.instance = new LetterSenderPersonApi();
        }

        return LetterSenderPersonApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    LetterSenderPersonApi.apiUrlPath,
                    new LetterSenderPersonModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    LetterSenderPersonApi.apiUrlPath,
                    id,
                    new LetterSenderPersonModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    LetterSenderPersonApi.apiUrlPath,
                    body,
                    new LetterSenderPersonModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    LetterSenderPersonApi.apiUrlPath,
                    id,
                    body,
                    new LetterSenderPersonModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    LetterSenderPersonApi.apiUrlPath,
                    id,
                    new LetterSenderPersonModelMapper());
    }

    public getUrl() {
        return LetterSenderPersonApi.apiUrlPath;
    }
}

export default LetterSenderPersonApi;