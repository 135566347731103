import React from 'react';
import { Component } from 'react';
import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import CreateUtils from '../../../../../commons/ui/page/template/create/CreateUtils';
import Form from './Form';
import LetterAddresseePerson from '../../model/LetterAddresseePerson';
import LetterPersonSalutation from '../../../../person/salutation/model/LetterPersonSalutation';
import LetterPersonTitle from '../../../../person/title/model/LetterPersonTitle';
import Country from '../../../../../general/country/model/Country';
import LetterAddresseePersonApi from '../../api/LetterAddresseePersonApi';
import LetterAddresseePersonUrlPath from '../LetterAddresseePersonUrlPath';
import LetterPersonSalutationApiCallExecutor from '../../../../person/salutation/api/LetterPersonSalutationApiCallExecutor';
import LetterPersonTitleApiCallExecutor from '../../../../person/title/api/LetterPersonTitleApiCallExecutor';
import CountryApiCallExecutor from '../../../../../general/country/api/CountryApiCallExecutor';
import LetterAddresseePersonUndefinedObjectDeletionModelMapper from '../../model/LetterAddresseePersonUndefinedObjectDeletionModelMapper';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: LetterAddresseePerson;
    Salutations: LetterPersonSalutation[];
    Titles: LetterPersonTitle[];
    Countries: Country[];
}

class Create extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: LetterAddresseePersonApi;
    private form: Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: new LetterAddresseePerson(),
            Salutations: [],
            Titles: [],
            Countries: []
        };

        this.apiCalls = new ApiCallExecutor(this);

        this.api = LetterAddresseePersonApi.getInstance();

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            LetterAddresseePersonUrlPath.BASE
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return (
            <h1>

            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event,
                                      null,
                                      new LetterAddresseePersonUndefinedObjectDeletionModelMapper());
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private salutationApiCallExecutor: LetterPersonSalutationApiCallExecutor<Create>;
    private titleApiCallExecutor: LetterPersonTitleApiCallExecutor<Create>;
    private countryApiCallExecutor: CountryApiCallExecutor<Create>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.salutationApiCallExecutor = 
                new LetterPersonSalutationApiCallExecutor(pointerToComponent);
        this.titleApiCallExecutor = new LetterPersonTitleApiCallExecutor(pointerToComponent);
        this.countryApiCallExecutor = new CountryApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addSalutationGetAll());
        orderOfCalls.push(this.addTitleGetAll());
        orderOfCalls.push(this.addCountryGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addSalutationGetAll() : IApiCallback {
        return {
            callback: this.salutationApiCallExecutor.getAll,
            pointerToApiCall: this.salutationApiCallExecutor,
            variableNameInState: "Salutations"
        };
    }

    private addTitleGetAll(): IApiCallback {
        return {
            callback: this.titleApiCallExecutor.getAll,
            pointerToApiCall: this.titleApiCallExecutor,
            variableNameInState: "Titles"
        };
    }

    private addCountryGetAll(): IApiCallback {
        return {
            callback: this.countryApiCallExecutor.getAll,
            pointerToApiCall: this.countryApiCallExecutor,
            variableNameInState: "Countries"
        };
    }
}

export default Create;