import React from 'react';

import FormGroupGroupDropdown from '../fragment/FormGroupGroupDropdown';
import FormGroupPriorityDropdown from '../fragment/FormGroupPriorityDropdown';
import FormGroupDescription from '../fragment/FormGroupDescription';
import FormGroupMonthlyRate from '../fragment/FormGroupMonthlyRate';
import FormGroupPlannedEndDate from '../fragment/FormGroupPlannedEndDate';
import FormGroupPlannedAmountMin from '../fragment/FormGroupPlannedAmountMin';
import FormGroupPlannedAmountMax from '../fragment/FormGroupPlannedAmountMax';
import FormGroupAddToFortune from '../fragment/FormGroupAddToFortune';
import FormGroupInvestmentPlanDropdown from '../fragment/FormGroupInvestmentPlanDropdown';

class Form {
    private formGroupGroup:            FormGroupGroupDropdown;
    private formGroupPriority:         FormGroupPriorityDropdown;
    private formGroupDescription:      FormGroupDescription;
    private formGroupMonthlyRate:      FormGroupMonthlyRate;
    private formGroupPlannedEndDate:   FormGroupPlannedEndDate;
    private formGroupPlannedAmountMin: FormGroupPlannedAmountMin;
    private formGroupPlannedAmountMax: FormGroupPlannedAmountMax;
    private formGroupAddToFortune:     FormGroupAddToFortune;
    private formGroupInvestmentPlan:   FormGroupInvestmentPlanDropdown;

    public constructor(props, pointerToComponent) {
        this.formGroupGroup            = new FormGroupGroupDropdown(props, pointerToComponent);
        this.formGroupPriority         = new FormGroupPriorityDropdown(props, pointerToComponent);
        this.formGroupDescription      = new FormGroupDescription(props, pointerToComponent);
        this.formGroupMonthlyRate      = new FormGroupMonthlyRate(props, pointerToComponent);
        this.formGroupPlannedEndDate   = new FormGroupPlannedEndDate(props, pointerToComponent);
        this.formGroupPlannedAmountMin = new FormGroupPlannedAmountMin(props, pointerToComponent);
        this.formGroupPlannedAmountMax = new FormGroupPlannedAmountMax(props, pointerToComponent);
        this.formGroupAddToFortune     = new FormGroupAddToFortune(props, pointerToComponent);
        this.formGroupInvestmentPlan   = new FormGroupInvestmentPlanDropdown(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupGroup.render() }
                { this.formGroupPriority.render() }
                { this.formGroupDescription.render() }
                { this.formGroupMonthlyRate.render() }
                { this.formGroupPlannedEndDate.render() }
                { this.formGroupPlannedAmountMin.render() }
                { this.formGroupPlannedAmountMax.render() }
                { this.formGroupAddToFortune.render() }
                { this.formGroupInvestmentPlan.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.formGroupGroup.isInvalidValue()
             || this.formGroupPriority.isInvalidValue() 
             || this.formGroupDescription.isInvalidValue() 
             || this.formGroupMonthlyRate.isInvalidValue()
             || this.formGroupPlannedEndDate.isInvalidValue() 
             || this.formGroupPlannedAmountMin.isInvalidValue() 
             || this.formGroupPlannedAmountMax.isInvalidValue()
             || this.formGroupAddToFortune.isInvalidValue() 
             || this.formGroupInvestmentPlan.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;