import OverviewApiCallBase from "../../../overview/apiCall/OverviewApiCallBase";

class OverviewApiCallGetAll extends OverviewApiCallBase {
    public execute(pointerToComponent, api, id = -1) {
        api.getAll()
        .then(  result => {
                    pointerToComponent.setState({
                        isLoading: false,
                        Groups: result
                    });
                })
        .catch( error => console.log("TODO") );
    }
}

export default OverviewApiCallGetAll;