import I18nConstantsBase from '../../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "letterPersonTitle";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static FORM_NAME = "form.name";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static FORM_NAME_DEFAULT = DefaultJson.form.name;
}

export default I18nConstants;