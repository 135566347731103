import React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortunePriorityApiCallExecutor from '../../api/FortunePriorityApiCallExecutor';

import FortunePriorityApi from '../../api/FortunePriorityApi';

import AuthenticationApi from '../../../../security/api/AuthenticationApi';

import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';

import FortunePriorityUrlPath from '../FortunePriorityUrlPath';

import LoginUrlPath from '../../../../login/ui/LoginUrlPath';

import OverviewUtils from '../../../../commons/ui/page/template/overview/OverviewUtils';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading: boolean;
    List:      [];
}

class Overview extends Component<IProps, IState> {
    private api: FortunePriorityApi;    // Needed by delete-callback.

    private apiCalls:      ApiCallExecutor;

    private overviewUtils: OverviewUtils;

    public constructor(props) {
        super(props);
        
        this.api = FortunePriorityApi.getInstance();

        this.apiCalls = new ApiCallExecutor(this);

        this.state = {
            isLoading: true,
            List:      []
        };

        this.overviewUtils = new OverviewUtils(
            this,
            this.getHeading(),
            FortunePriorityUrlPath.CREATE,
            FortunePriorityUrlPath.UPDATE);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return(
                <div>
                    { this.overviewUtils.render(
                        this.getTableHead(),
                        this.getTableBody()) }
                </div> );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return(
            <h1>

            </h1>) ;
    }

    private getTableHead() {
        return(
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_PRIORITY}>
                        {I18nConstants.FORM_PRIORITY_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                { this.overviewUtils.getUpdateHeadingWrappedInThTag() }
                { this.overviewUtils.getDeleteHeadingWrappedInThTag() }
            </tr> );
    }

    private getTableBody() {
        return this.state.List.map( item => 
            <tr key={(item as any).id}>
                <td>
                    {(item as any).priorityId}
                </td>

                <td>
                    {(item as any).description}
                </td>

                { this.overviewUtils.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.overviewUtils.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private helper: ApiCallExecutorHelper;

    private fortunePriorityApiCallExecutor: FortunePriorityApiCallExecutor<Overview>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.fortunePriorityApiCallExecutor = new FortunePriorityApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortunePriorityGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortunePriorityGetAll() : IApiCallback {
        return {
            callback: this.fortunePriorityApiCallExecutor.getAll,
            pointerToApiCall: this.fortunePriorityApiCallExecutor,
            variableNameInState: "List"
        };
    }
}

export default Overview;