import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupGroupDropdown extends FormGroupBase {
    private ID_AND_NAME = "group";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_GROUP,
                    I18nConstants.FORM_GROUP_DEFAULT) }

                <Input 
                    type="select" 
                    name={this.ID_AND_NAME} 
                    id={this.ID_AND_NAME} 
                    value={this.pointerToComponent.state.Item.group.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                        {this.pointerToComponent.getGroupSelectValues()}
                </Input>

                { this.renderFormFeedbackWithChooseValidValueMessage() }
            </FormGroup> );
    }

    public isInvalidValue(): boolean {
        return this.pointerToComponent.isInvalidValue(this.pointerToComponent.state.Item.group.id);
    }
}

export default FormGroupGroupDropdown;