import Utils from '../../../../../utilities/Utils';
import LetterPersonTitleApi from '../../api/LetterPersonTitleApi';
import LetterPersonTitle from '../../model/LetterPersonTitle';
import LetterPersonTitleUrlPath from '../LetterPersonTitleUrlPath';
import UpdateBase from '../../../../../commons/ui/page/template/update/UpdateBase';
import Form from './Form';
import FormButtonUpdate from '../../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBase<LetterPersonTitle, LetterPersonTitleApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new LetterPersonTitle(),
              LetterPersonTitleApi.getInstance(),
              idOfItemToBeUpdated,
              LetterPersonTitleUrlPath.BASE
        );

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;