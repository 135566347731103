import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupAmount extends FormGroupBase {
    private ID_AND_NAME = "amount";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_AMOUNT,
                    I18nConstants.FORM_AMOUNT_DEFAULT) }

                <Input 
                    type="text" 
                    name={this.ID_AND_NAME} 
                    id={this.ID_AND_NAME} 
                    value={this.pointerToComponent.state.Item.amount}
                    onChange={this.pointerToComponent.handleChange} />
            </FormGroup> );
    }

    public isInvalidValue(): boolean {
        // An 'undefined'-value is always an invalid value.
        if (this.pointerToComponent.state.Item.amount === undefined) {
            return true;
        }

        // Positive and negative amounts should be possible, but no '0'-value.
        if (    (parseInt(this.pointerToComponent.state.Item.amount) < 0)
             || (parseInt(this.pointerToComponent.state.Item.amount) > 0)) {
            return false;
        } else {
            return true;
        }
    }
}

export default FormGroupAmount;