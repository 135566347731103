import IModelMapper from '../../../../commons/model/IModelMapper';
import LetterPersonSalutation from '../../../person/salutation/model/LetterPersonSalutation';
import LetterPersonTitle from '../../../person/title/model/LetterPersonTitle';
import LetterSenderCompany from './LetterSenderCompany';

class LetterSenderCompanyModelMapper implements IModelMapper<LetterSenderCompany> {
    fromJsonToObject(json: any): LetterSenderCompany {
        let letterSenderCompany = new LetterSenderCompany();

        letterSenderCompany.id = json.id;
        letterSenderCompany.name = json.name;
        letterSenderCompany.department = json.department;
        // Must be done like this, because on UPDATE, it could be that it has
        // a null-value because it isn't a mandatory parameter.
        letterSenderCompany.contactSalutation = (json.contactSalutation == null) 
                                                 ? new LetterPersonSalutation() 
                                                 : json.contactSalutation;
        letterSenderCompany.contactTitle = (json.contactTitle === null) 
                                            ? new LetterPersonTitle() 
                                            : json.contactTitle;
        letterSenderCompany.contactFirstName = json.contactFirstName;
        letterSenderCompany.contactLastName = json.contactLastName;
        letterSenderCompany.street = json.street;
        letterSenderCompany.houseNumber = json.houseNumber;
        letterSenderCompany.zip = json.zip;
        letterSenderCompany.city = json.city;
        letterSenderCompany.postOfficeBox = json.postOfficeBox;
        letterSenderCompany.country = json.country;
        letterSenderCompany.phone = json.phone;
        letterSenderCompany.mail = json.mail;
        letterSenderCompany.url = json.url;

        return letterSenderCompany;
    }

    fromObjectToJson(object: LetterSenderCompany) {
        return {
            id: object.id,
            name: (object.name || ""),
            department: (object.department || ""),
            contactSalutation: (object.contactSalutation || undefined),
            contactTitle: (object.contactTitle || undefined),
            contactFirstName: (object.contactFirstName || ""),
            contactLastName: (object.contactLastName || ""),
            street: (object.street || ""),
            houseNumber: (object.houseNumber || ""),
            zip: (object.zip || ""),
            city: (object.city || ""),
            postOfficeBox: (object.postOfficeBox || ""),
            country: (object.country || undefined),
            phone: (object.phone || ""),
            mail: (object.mail || ""),
            url: (object.url || "")
        };
    }
}

export default LetterSenderCompanyModelMapper;