import LinkListGroup from '../../model/LinkListGroup';
import LinkListGroupApi from '../../api/LinkListGroupApi';
import LinkListGroupUrlPath from '../LinkListGroupUrlPath';

import CreateBase from '../../../../commons/ui/page/template/group/create/CreateBase';

class Create extends CreateBase<LinkListGroup, LinkListGroupApi> {
    public constructor(props) {
        super(props,
              new LinkListGroup(),
              LinkListGroupApi.getInstance(),
              LinkListGroupUrlPath.BASE);
    }
}

export default Create;