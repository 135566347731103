import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupTemplateDropdown extends FormGroupBase {
    private ID_AND_NAME = "template";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const templates = this.getTemplatesSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_TEMPLATE,
                    I18nConstants.FORM_TEMPLATE_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.template.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {templates}
                </Input>

                { this.renderFormFeedbackWithChooseValidValueMessage() }
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {  
        return this.pointerToComponent.isInvalidValue(this.pointerToComponent.state.Item.template.id);
    }

    protected getTemplatesSelectValues() {
        const { Templates } = this.pointerToComponent.state;

        return this.getSelectValues(Templates, "description");
    }
}

export default FormGroupTemplateDropdown;