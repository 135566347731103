import React from 'react';
import { Component } from 'react';
import { Button } from 'reactstrap';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import Utils from '../../../../utilities/Utils';

import FortuneSavingRate from '../../model/FortuneSavingRate';
import FortuneSavingRateApi from '../../api/FortuneSavingRateApi';
import FortuneSavingRateUrlPath from '../FortuneSavingRateUrlPath';

import FortuneSavingPlan from '../../../savingPlan/item/model/FortuneSavingPlan';
import FortuneSavingPlanApi from '../../../savingPlan/item/api/FortuneSavingPlanApi';

import CreateUtils from '../../../../commons/ui/page/template/create/CreateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:        boolean;
    wasHandleSubmitClickedAtLeastOnce:      boolean;
    Item:             FortuneSavingRate;
    SavingPlans:      FortuneSavingPlan[];
    CurrentSavingPlan: FortuneSavingPlan;
}

class CreateInWizardForAllSavingPlans extends Component<IProps, IState> {
    private api:     FortuneSavingRateApi;
    private form:        Form;
    private createUtils: CreateUtils;

    private ownUrl:      string;
    private overviewUrl: string;

    private currentId:   number;

    public constructor(props) {
        super(props);

        this.state = {
            isLoading:         true,
            wasHandleSubmitClickedAtLeastOnce:       false,
            Item:              new FortuneSavingRate(),
            SavingPlans:       [],
            CurrentSavingPlan: new FortuneSavingPlan()
        };

        this.ownUrl      = FortuneSavingRateUrlPath.CREATE_IN_WIZARD_FOR_ALL_SAVING_PLANS;
        this.overviewUrl = FortuneSavingRateUrlPath.BASE;

        this.api     = FortuneSavingRateApi.getInstance();

        this.form        = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            this.overviewUrl);

        this.currentId = Utils.getIdFromGetParameters(props.location.search);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return (
            <div>
                <h1>

                </h1>

                { this.getSkipCurrentSavingRateButton() }
            </div>) ;
    }

    private getSkipCurrentSavingRateButton() {
        return (
            <Button onClick={ () => this.onClickSkipCurrentSavingRate() }>
               <Trans ns={I18nConstants.NS}
                      i18nKey={I18nConstants.FORM_SKIP_SAVING_RATE}>
                    {I18nConstants.FORM_SKIP_SAVING_RATE_DEFAULT}
                </Trans> 
            </Button> );
    }

    private onClickSkipCurrentSavingRate() {
        this.callbackForActionAfterSubmitExecutedSuccessfully(this);
    }

    public async componentDidMount() {
        FortuneSavingPlanApi.getInstance().getAll()
        .then(  response => {
                    this.setState({
                        isLoading:   false,
                        SavingPlans: (response as any)
                    });

                    this.setCurrentSavingPlan();
                    this.setValuesToItemForCurrentSavingPlan();
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }

    public async componentDidUpdate() {
        let currentIdBefore = this.currentId;

        this.currentId      = Utils.getIdFromGetParameters(this.props.location.search);

        if (currentIdBefore != this.currentId) {
            let savingPlan = this.setCurrentSavingPlan();
            this.setValuesToItemForCurrentSavingPlan(savingPlan);
        }
    }

    private setCurrentSavingPlan() {
        let savingPlan;

        // If no ID is defined, start with the first one.
        if (this.currentId) {
            let id             = 0;
            let nextSavingPlan;

            let savingPlans    = this.state.SavingPlans;

            for (let i = 0; i < savingPlans.length; ++i) {
                if (savingPlans[i].id === this.currentId) {
                    id = i;
                    break;
                }
            }

            nextSavingPlan = this.state.SavingPlans[id];

            this.setState({
                CurrentSavingPlan: nextSavingPlan
            });

            savingPlan = nextSavingPlan;
        } else {
            if (this.state.SavingPlans.length > 0) {
                let currentSavingPlan = this.state.SavingPlans[0];
                
                this.setState({
                    CurrentSavingPlan: currentSavingPlan
                });

                savingPlan = currentSavingPlan;
            } else {
                console.log("TODO");
                console.log("No saving plan exists!");
            }
        }

        return savingPlan;
    }

    private setValuesToItemForCurrentSavingPlan(savingPlan = undefined) {
        let currentSavingPlan;

        if (savingPlan) {
            currentSavingPlan = savingPlan;
        } else {
            currentSavingPlan = this.state.CurrentSavingPlan; 
        }
        
        let filledItem           = new FortuneSavingRate();

        filledItem.savingPlan.id = currentSavingPlan.id;
        filledItem.description   = Utils.getCurrentMonthNameI18n() + " " + 
                                   Utils.getCurrentYear();
        (filledItem.date as any) = new Date().toISOString().substr(0, 10);

        // Distinguish between investment plan and saving plan.
        if (    (currentSavingPlan.investmentPlan === undefined)
             || (currentSavingPlan.investmentPlan === null)
             || (currentSavingPlan.investmentPlan.id === undefined)
             || (currentSavingPlan.investmentPlan.id === "none")) {
            filledItem.amount           = currentSavingPlan.monthlyRate;

            filledItem.currentQuotation = ("" as any);
            filledItem.investmentAmount = ("" as any);
        } else {
            filledItem.investmentAmount = currentSavingPlan.monthlyRate;

            filledItem.amount           = ("" as any);
            filledItem.currentQuotation = ("" as any); 
        }

        this.setState({
            Item: filledItem
        });
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event, 
                                      this.callbackForActionAfterSubmitExecutedSuccessfully);
    }

    private callbackForActionAfterSubmitExecutedSuccessfully(pointerToComponent) {
        let nextId      = 0;
        let nextItem    = 0;
        let savingPlans = pointerToComponent.state.SavingPlans;

        let currentId = pointerToComponent.state.CurrentSavingPlan.id;

        for (let i = 0; i < savingPlans.length; ++i) {
            if (savingPlans[i].id === currentId) {
                nextItem = i + 1;
                break;
            }
        }

        if (nextItem < pointerToComponent.state.SavingPlans.length) {
            nextId = pointerToComponent.state.SavingPlans[nextItem].id;

            pointerToComponent.props.history.push(pointerToComponent.ownUrl + 
                                    "?" + "id" + "=" + nextId.toString()); 
        } else {
            pointerToComponent.props.history.push(pointerToComponent.overviewUrl);
        }
    }
}

export default CreateInWizardForAllSavingPlans;