import UpdateBase from '../../../../commons/ui/page/template/group/update/UpdateBase';

import DrivingStyle from '../../model/DrivingStyle';
import DrivingStyleApi from '../../api/DrivingStyleApi';
import DrivingStyleUrlPath from '../DrivingStyleUrlPath';

class Update extends UpdateBase<DrivingStyle, DrivingStyleApi> {
    public constructor(props) {
        super(props,
              new DrivingStyle(),
              DrivingStyleApi.getInstance(),
              DrivingStyleUrlPath.BASE);
    }
}

export default Update;