import I18nConstantsBase from '../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "dashboard";

    public static WELCOME = "welcome";

    // --------------------------------------------------------------------------------------------

    public static WELCOME_DEFUALT = DefaultJson.welcome;
}

export default I18nConstants;