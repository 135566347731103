import ApiCallExecutorBase from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';

import ExpenseOverheadGroupApi from './ExpenseOverheadGroupApi';

class ExpenseOverheadGroupApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private expenseOverheadGroupApi: ExpenseOverheadGroupApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.expenseOverheadGroupApi = ExpenseOverheadGroupApi.getInstance();
    }

    public getSumAsList(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.expenseOverheadGroupApi.getSumAsList()
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    context.pointerToHelper.addParameterToContext(context, 
                        "listDataForDiagram", context.pointerToComponent.state[
                                                context.currentData.variableNameInState]);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }
}

export default ExpenseOverheadGroupApiCallExecutor;