import OverviewBase from '../../../../../commons/ui/page/template/group/overview/OverviewBase';

import VehicleType from '../../model/VehicleType';
import VehicleTypeApi from '../../api/VehicleTypeApi';

class Overview extends OverviewBase<VehicleType, VehicleTypeApi> {
    public constructor(props) {
        super(props,
              VehicleTypeApi.getInstance());
    }
}

export default Overview;