import Earning from '../../model/Earning';
import EarningApi from '../../api/EarningApi';
import EarningGroup from '../../../group/model/EarningGroup';
import EarningGroupApi from '../../../group/api/EarningGroupApi';
import EarningsExpensesUrlPath from '../../../../item/ui/EarningsExpensesUrlPath';
import CreateBaseWithGroup from '../../../../../commons/ui/page/template/create/CreateBaseWithGroup';
import Form from './Form';
import FormButtonCreate from '../../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBaseWithGroup<Earning, EarningGroup, EarningApi, EarningGroupApi> {
    private form: Form;

    public constructor(props) {
        super(props, 
              new Earning(),
              EarningApi.getInstance(),
              EarningGroupApi.getInstance(),
              EarningsExpensesUrlPath.BASE
        );

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;