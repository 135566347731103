import React from 'react';
import { Table } from 'reactstrap';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import ToDoListApiCallExecutor from '../../api/ToDoListApiCallExecutor';
import OverviewBase from './OverviewBase';

class OverviewItemsNotStarted extends OverviewBase {
    private apiCalls: ApiCallExecutor;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    protected getHeading() {
        return (
            <h3>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.NOT_STARTED}>
                    {I18nConstants.NOT_STARTED_DEFAULT}
                </Trans>
            </h3>
        );
    }

    protected getTable() {
        var tableHead = this.getTableHead();
        var tableBody = this.getTableBody();

        return (
            <Table>
                <thead>
                    {tableHead}
                </thead>
                <tbody>
                    {tableBody}
                </tbody>
            </Table>
        );
    }

    private getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_GROUP}>
                        {I18nConstants.FORM_GROUP_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_TODO_UNTIL}>
                        {I18nConstants.FORM_TODO_UNTIL_DEFAULT}
                    </Trans>
                </th>
                
                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    private getTableBody() {
        return this.state.ItemsNotStarted.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).description}
                </td>

                <td>
                    {(item as any).group.name}
                </td>

                <td>
                    {(item as any).toDoUntil}
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: OverviewItemsNotStarted;

    private helper: ApiCallExecutorHelper;

    private toDoListApiCallExecutor:
                ToDoListApiCallExecutor<OverviewItemsNotStarted>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.toDoListApiCallExecutor = new ToDoListApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();
    
        orderOfCalls.push(this.addGetItemsNotStarted());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addGetItemsNotStarted(): IApiCallback {
        return {
            callback: this.toDoListApiCallExecutor.getAllNotStarted,
            pointerToApiCall: this.toDoListApiCallExecutor,
            variableNameInState: "ItemsNotStarted"
        };
    }
}

export default OverviewItemsNotStarted;