import IModelMapper from '../../../../commons/model/IModelMapper';
import EarningsExpensesOverheadTransactionCreator from './EarningsExpensesOverheadTransactionCreator';

class EarningsExpensesOverheadTransactionCreatorModelMapper implements IModelMapper<EarningsExpensesOverheadTransactionCreator> {
    fromJsonToObject(json: any): EarningsExpensesOverheadTransactionCreator {
        let earningsExpensesOverheadTransactionCreator = new EarningsExpensesOverheadTransactionCreator();

        earningsExpensesOverheadTransactionCreator.earnings = json.earnings;
        earningsExpensesOverheadTransactionCreator.expenses = json.expenses;

        return earningsExpensesOverheadTransactionCreator;
    }

    fromObjectToJson(object: EarningsExpensesOverheadTransactionCreator) {
        return {
            earnings: (object.earnings || undefined),
            expenses: (object.expenses || undefined)
        };
    }
}

export default EarningsExpensesOverheadTransactionCreatorModelMapper;