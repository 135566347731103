import OverviewBase from '../../../../../commons/ui/page/template/group/overview/OverviewBase';

import KindOfFuel from '../../model/KindOfFuel';
import KindOfFuelApi from '../../api/KindOfFuelApi';

class Overview extends OverviewBase<KindOfFuel, KindOfFuelApi> {
    public constructor(props) {
        super(props,
              KindOfFuelApi.getInstance());
    }
}

export default Overview;