import LetterSenderPerson from "./LetterSenderPerson";
import LetterSenderPersonModelMapper from './LetterSenderPersonModelMapper';

class LetterSenderPersonUndefinedObjectDeletionModelMapper 
extends LetterSenderPersonModelMapper {
    fromObjectToJson(object: LetterSenderPerson) {
        let jsonObject = super.fromObjectToJson(object);

        // Check for empty title and set it to 'null'.
        if (     (jsonObject.title.id === undefined)
             || ((jsonObject.title.id as any) === "-1")
             || ((jsonObject.title.id as any) === "none")) {
            jsonObject.title = (null as any);
        }
        
        return jsonObject;
    }
}

export default LetterSenderPersonUndefinedObjectDeletionModelMapper;