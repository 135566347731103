import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import EnvironmentVariables from '../config/EnvironmentVariables';
// --- EN ---
import commons_en from "./en/common.json";
import login_en from '../../login/i18n/en/common.json';
import logout_en from '../../logout/i18n/en/common.json';
import dashboard_en from "../../dashboard/i18n/en/common.json";
import bankingListGroup_en from "../../bankingList/group/i18n/en/common.json";
import bankingListItem_en from "../../bankingList/item/i18n/en/common.json";
import cyclingTour_en from '../../cyclingTour/item/i18n/en/common.json';
import cyclingTourBikeItem_en from '../../cyclingTour/bike/item/i18n/en/common.json';
import cyclingTourBikeType_en from '../../cyclingTour/bike/type/i18n/en/common.json';
import earningsExpensesEarningGroup_en from '../../earningsExpenses/earning/group/i18n/en/common.json';
import earningsExpensesEarningItem_en from '../../earningsExpenses/earning/item/i18n/en/common.json';
import earningsExpensesExpenseGroup_en from '../../earningsExpenses/expense/group/i18n/en/common.json';
import earningsExpensesExpenseItem_en from '../../earningsExpenses/expense/item/i18n/en/common.json';
import earningsExpensesItem_en from "../../earningsExpenses/item/i18n/en/common.json";
import earningsExpensesOverheadEarningGroup_en from '../../earningsExpensesOverhead/earning/group/i18n/en/common.json';
import earningsExpensesOverheadEarningItem_en from '../../earningsExpensesOverhead/earning/item/i18n/en/common.json';
import earningsExpensesOverheadExpenseGroup_en from '../../earningsExpensesOverhead/expense/group/i18n/en/common.json';
import earningsExpensesOverheadExpenseItem_en from '../../earningsExpensesOverhead/expense/item/i18n/en/common.json';
import earningsExpensesOverheadItem_en from '../../earningsExpensesOverhead/item/i18n/en/common.json';
import earningsExpensesOverheadTransactionEarning_en from '../../earningsExpensesOverhead/transaction/earning/i18n/en/common.json';
import earningsExpensesOverheadTransactionExpense_en from '../../earningsExpensesOverhead/transaction/expense/i18n/en/common.json';
import earningsExpensesOverheadTransactionItem_en from '../../earningsExpensesOverhead/transaction/item/i18n/en/common.json';
import fortuneDashboard_en from '../../fortune/dashboard/i18n/en/common.json';
import fortuneExpensePlannedGroup_en from '../../fortune/expensePlanned/group/i18n/en/common.json';
import fortuneExpensePlannedItem_en from '../../fortune/expensePlanned/item/i18n/en/common.json';
import fortuneInvestmentAim_en from '../../fortune/investmentAim/i18n/en/common.json';
import fortuneInvestmentPlan_en from '../../fortune/investmentPlan/i18n/en/common.json';
import fortunePriority_en from '../../fortune/priority/i18n/en/common.json';
import fortuneRiskClass_en from '../../fortune/riskClass/i18n/en/common.json';
import fortuneSavingPlanGroup_en from '../../fortune/savingPlan/group/i18n/en/common.json';
import fortuneSavingPlanItem_en from '../../fortune/savingPlan/item/i18n/en/common.json';
import fortuneSavingRate_en from '../../fortune/savingRate/i18n/en/common.json';
import fortuneSavingRatio_en from '../../fortune/savingRatio/i18n/en/common.json';
import fuelConsumptionDrivingStyle_en from '../../fuelConsumption/drivingStyle/i18n/en/common.json';
import fuelConsumptionItem_en from '../../fuelConsumption/item/i18n/en/common.json';
import fuelConsumptionKindOfStreet_en from '../../fuelConsumption/kindOfStreet/i18n/en/common.json';
import fuelConsumptionTire_en from '../../fuelConsumption/tire/i18n/en/common.json';
import fuelConsumptionVehicleFuelUnit_en from '../../fuelConsumption/vehicle/fuelUnit/i18n/en/common.json';
import fuelConsumptionVehicleItem_en from '../../fuelConsumption/vehicle/item/i18n/en/common.json';
import fuelConsumptionVehicleKindOfFuel_en from '../../fuelConsumption/vehicle/kindOfFuel/i18n/en/common.json';
import fuelConsumptionVehicleType_en from '../../fuelConsumption/vehicle/type/i18n/en/common.json';
import letter_en from '../../letter/item/i18n/en/common.json';
import letterAddressGeneral_en from '../../letter/addressGeneral/i18n/en/common.json';
import letterPersonSalutation_en from '../../letter/person/salutation/i18n/en/common.json';
import letterPersonTitle_en from '../../letter/person/title/i18n/en/common.json';
import letterSenderPerson_en from '../../letter/sender/person/i18n/en/common.json';
import letterSenderCompany_en from '../../letter/sender/company/i18n/en/common.json';
import letterAddresseePerson_en from '../../letter/addressee/person/i18n/en/common.json';
import letterAddresseeCompany_en from '../../letter/addressee/company/i18n/en/common.json';
import letterTemplate_en from '../../letter/template/i18n/en/common.json';
import letterSignature_en from '../../letter/signature/i18n/en/common.json';
import linkListGroup_en from '../../linkList/group/i18n/en/common.json';
import linkListItem_en from '../../linkList/item/i18n/en/common.json';
import shoppingListGroup_en from '../../shoppingList/group/i18n/en/common.json';
import shoppingListItem_en from '../../shoppingList/item/i18n/en/common.json';
import shoppingListKindOfQuantity_en from '../../shoppingList/kindOfQuantity/i18n/en/common.json';
import toDoListGroup_en from '../../toDoList/group/i18n/en/common.json';
import toDoListItem_en from '../../toDoList/item/i18n/en/common.json';
import passwordListItem_en from '../../passwordList/item/i18n/en/common.json';
import passwordListFolder_en from '../../passwordList/folder/i18n/en/common.json';
import insuranceListGroup_en from '../../insuranceList/group/i18n/en/common.json';
import insuranceListPaymentInterval_en from '../../insuranceList/paymentInterval/i18n/en/common.json';
import insuranceListItem_en from '../../insuranceList/item/i18n/en/common.json';
// --- DE ---
import commons_de from "./de/common.json";
import login_de from '../../login/i18n/de/common.json';
import logout_de from '../../logout/i18n/de/common.json';
import dashboard_de from "../../dashboard/i18n/de/common.json";
import bankingListGroup_de from "../../bankingList/group/i18n/de/common.json";
import bankingListItem_de from "../../bankingList/item/i18n/de/common.json";
import cyclingTour_de from '../../cyclingTour/item/i18n/de/common.json';
import cyclingTourBikeItem_de from '../../cyclingTour/bike/item/i18n/de/common.json';
import cyclingTourBikeType_de from '../../cyclingTour/bike/type/i18n/de/common.json';
import earningsExpensesEarningGroup_de from '../../earningsExpenses/earning/group/i18n/de/common.json';
import earningsExpensesEarningItem_de from '../../earningsExpenses/earning/item/i18n/de/common.json';
import earningsExpensesExpenseGroup_de from '../../earningsExpenses/expense/group/i18n/de/common.json';
import earningsExpensesExpenseItem_de from '../../earningsExpenses/expense/item/i18n/de/common.json';
import earningsExpensesItem_de from "../../earningsExpenses/item/i18n/de/common.json";
import earningsExpensesOverheadEarningGroup_de from '../../earningsExpensesOverhead/earning/group/i18n/de/common.json';
import earningsExpensesOverheadEarningItem_de from '../../earningsExpensesOverhead/earning/item/i18n/de/common.json';
import earningsExpensesOverheadExpenseGroup_de from '../../earningsExpensesOverhead/expense/group/i18n/de/common.json';
import earningsExpensesOverheadExpenseItem_de from '../../earningsExpensesOverhead/expense/item/i18n/de/common.json';
import earningsExpensesOverheadItem_de from '../../earningsExpensesOverhead/item/i18n/de/common.json';
import earningsExpensesOverheadTransactionEarning_de from '../../earningsExpensesOverhead/transaction/earning/i18n/de/common.json';
import earningsExpensesOverheadTransactionExpense_de from '../../earningsExpensesOverhead/transaction/expense/i18n/de/common.json';
import earningsExpensesOverheadTransactionItem_de from '../../earningsExpensesOverhead/transaction/item/i18n/de/common.json';
import fortuneDashboard_de from '../../fortune/dashboard/i18n/de/common.json';
import fortuneExpensePlannedGroup_de from '../../fortune/expensePlanned/group/i18n/de/common.json';
import fortuneExpensePlannedItem_de from '../../fortune/expensePlanned/item/i18n/de/common.json';
import fortuneInvestmentAim_de from '../../fortune/investmentAim/i18n/de/common.json';
import fortuneInvestmentPlan_de from '../../fortune/investmentPlan/i18n/de/common.json';
import fortunePriority_de from '../../fortune/priority/i18n/de/common.json';
import fortuneRiskClass_de from '../../fortune/riskClass/i18n/de/common.json';
import fortuneSavingPlanGroup_de from '../../fortune/savingPlan/group/i18n/de/common.json';
import fortuneSavingPlanItem_de from '../../fortune/savingPlan/item/i18n/de/common.json';
import fortuneSavingRate_de from '../../fortune/savingRate/i18n/de/common.json';
import fortuneSavingRatio_de from '../../fortune/savingRatio/i18n/de/common.json';
import fuelConsumptionDrivingStyle_de from '../../fuelConsumption/drivingStyle/i18n/de/common.json';
import fuelConsumptionItem_de from '../../fuelConsumption/item/i18n/de/common.json';
import fuelConsumptionKindOfStreet_de from '../../fuelConsumption/kindOfStreet/i18n/de/common.json';
import fuelConsumptionTire_de from '../../fuelConsumption/tire/i18n/de/common.json';
import fuelConsumptionVehicleFuelUnit_de from '../../fuelConsumption/vehicle/fuelUnit/i18n/de/common.json';
import fuelConsumptionVehicleItem_de from '../../fuelConsumption/vehicle/item/i18n/de/common.json';
import fuelConsumptionVehicleKindOfFuel_de from '../../fuelConsumption/vehicle/kindOfFuel/i18n/de/common.json';
import fuelConsumptionVehicleType_de from '../../fuelConsumption/vehicle/type/i18n/de/common.json';
import letter_de from '../../letter/item/i18n/de/common.json';
import letterAddressGeneral_de from '../../letter/addressGeneral/i18n/de/common.json';
import letterPersonSalutation_de from '../../letter/person/salutation/i18n/de/common.json';
import letterPersonTitle_de from '../../letter/person/title/i18n/de/common.json';
import letterSenderPerson_de from '../../letter/sender/person/i18n/de/common.json';
import letterSenderCompany_de from '../../letter/sender/company/i18n/de/common.json';
import letterAddresseePerson_de from '../../letter/addressee/person/i18n/de/common.json';
import letterAddresseeCompany_de from '../../letter/addressee/company/i18n/de/common.json';
import letterTemplate_de from '../../letter/template/i18n/de/common.json';
import letterSignature_de from '../../letter/signature/i18n/de/common.json';
import linkListGroup_de from '../../linkList/group/i18n/de/common.json';
import linkListItem_de from '../../linkList/item/i18n/de/common.json';
import shoppingListGroup_de from '../../shoppingList/group/i18n/de/common.json';
import shoppingListItem_de from '../../shoppingList/item/i18n/de/common.json';
import shoppingListKindOfQuantity_de from '../../shoppingList/kindOfQuantity/i18n/de/common.json';
import toDoListGroup_de from '../../toDoList/group/i18n/de/common.json';
import toDoListItem_de from '../../toDoList/item/i18n/de/common.json';
import passwordListItem_de from '../../passwordList/item/i18n/de/common.json';
import passwordListFolder_de from '../../passwordList/folder/i18n/de/common.json';
import insuranceListGroup_de from '../../insuranceList/group/i18n/de/common.json';
import insuranceListPaymentInterval_de from '../../insuranceList/paymentInterval/i18n/de/common.json';
import insuranceListItem_de from '../../insuranceList/item/i18n/de/common.json';

// Debugging should be disabled in quality- and prod-environments.
const isDebuggingUsed = (    (EnvironmentVariables.getEnvironment() === "quality") 
                          || (EnvironmentVariables.getEnvironment() === "prod")) ?
                        false :
                        true; 

i18n
  .use(LanguageDetector)    // The locale will be detected automatically by the browser-settings.
  .use(initReactI18next)
  .init({
    //lng: 'de',    // This parameter is just for testing-purposes!
    fallbackLng: 'en',
    defaultNS: 'common',
    debug: isDebuggingUsed,

    interpolation: {
      escapeValue: false,
    },

    resources: {
        en: {
          commons: commons_en,
          login: login_en,
          logout: logout_en,
          dashboard: dashboard_en,
          bankingListGroup: bankingListGroup_en,
          bankingListItem: bankingListItem_en,
          cyclingTour: cyclingTour_en,
          cyclingTourBikeItem: cyclingTourBikeItem_en,
          cyclingTourBikeType: cyclingTourBikeType_en,
          earningsExpensesEarningGroup: earningsExpensesEarningGroup_en,
          earningsExpensesEarningItem: earningsExpensesEarningItem_en,
          earningsExpensesExpenseGroup: earningsExpensesExpenseGroup_en,
          earningsExpensesExpenseItem: earningsExpensesExpenseItem_en,
          earningsExpensesItem: earningsExpensesItem_en,
          earningsExpensesOverheadEarningGroup: earningsExpensesOverheadEarningGroup_en,
          earningsExpensesOverheadEarningItem: earningsExpensesOverheadEarningItem_en,
          earningsExpensesOverheadExpenseGroup: earningsExpensesOverheadExpenseGroup_en,
          earningsExpensesOverheadExpenseItem: earningsExpensesOverheadExpenseItem_en,
          earningsExpensesOverheadItem: earningsExpensesOverheadItem_en,
          earningsExpensesOverheadTransactionEarning: earningsExpensesOverheadTransactionEarning_en,
          earningsExpensesOverheadTransactionExpense: earningsExpensesOverheadTransactionExpense_en,
          earningsExpensesOverheadTransactionItem: earningsExpensesOverheadTransactionItem_en,
          fortuneDashboard: fortuneDashboard_en,
          fortuneExpensePlannedGroup: fortuneExpensePlannedGroup_en,
          fortuneExpensePlannedItem: fortuneExpensePlannedItem_en,
          fortuneInvestmentAim: fortuneInvestmentAim_en,
          fortuneInvestmentPlan: fortuneInvestmentPlan_en,
          fortunePriority: fortunePriority_en,
          fortuneRiskClass: fortuneRiskClass_en,
          fortuneSavingPlanGroup: fortuneSavingPlanGroup_en,
          fortuneSavingPlanItem: fortuneSavingPlanItem_en,
          fortuneSavingRate: fortuneSavingRate_en,
          fortuneSavingRatio: fortuneSavingRatio_en,
          fuelConsumptionDrivingStyle: fuelConsumptionDrivingStyle_en,
          fuelConsumptionItem: fuelConsumptionItem_en,
          fuelConsumptionKindOfStreet: fuelConsumptionKindOfStreet_en,
          fuelConsumptionTire: fuelConsumptionTire_en,
          fuelConsumptionVehicleFuelUnit: fuelConsumptionVehicleFuelUnit_en,
          fuelConsumptionVehicleItem: fuelConsumptionVehicleItem_en,
          fuelConsumptionVehicleKindOfFuel: fuelConsumptionVehicleKindOfFuel_en,
          fuelConsumptionVehicleType: fuelConsumptionVehicleType_en,
          letter: letter_en,
          letterAddressGeneral: letterAddressGeneral_en,
          letterPersonSalutation: letterPersonSalutation_en,
          letterPersonTitle: letterPersonTitle_en,
          letterSenderPerson: letterSenderPerson_en,
          letterSenderCompany: letterSenderCompany_en,
          letterAddresseePerson: letterAddresseePerson_en,
          letterAddresseeCompany: letterAddresseeCompany_en,
          letterTemplate: letterTemplate_en,
          letterSignature: letterSignature_en,
          linkListGroup: linkListGroup_en,
          linkListItem: linkListItem_en,
          shoppingListGroup: shoppingListGroup_en,
          shoppingListItem: shoppingListItem_en,
          shoppingListKindOfQuantity: shoppingListKindOfQuantity_en,
          toDoListGroup: toDoListGroup_en,
          toDoListItem: toDoListItem_en,
          passwordListItem: passwordListItem_en,
          passwordListFolder: passwordListFolder_en,
          insuranceListGroup: insuranceListGroup_en,
          insuranceListPaymentInterval: insuranceListPaymentInterval_en,
          insuranceListItem: insuranceListItem_en
        },
        de: {
          commons: commons_de,
          login: login_de,
          logout: logout_de,
          dashboard: dashboard_de,
          bankingListGroup: bankingListGroup_de,
          bankingListItem: bankingListItem_de,
          cyclingTour: cyclingTour_de,
          cyclingTourBikeItem: cyclingTourBikeItem_de,
          cyclingTourBikeType: cyclingTourBikeType_de,
          earningsExpensesEarningGroup: earningsExpensesEarningGroup_de,
          earningsExpensesEarningItem: earningsExpensesEarningItem_de,
          earningsExpensesExpenseGroup: earningsExpensesExpenseGroup_de,
          earningsExpensesExpenseItem: earningsExpensesExpenseItem_de,
          earningsExpensesItem: earningsExpensesItem_de,
          earningsExpensesOverheadEarningGroup: earningsExpensesOverheadEarningGroup_de,
          earningsExpensesOverheadEarningItem: earningsExpensesOverheadEarningItem_de,
          earningsExpensesOverheadExpenseGroup: earningsExpensesOverheadExpenseGroup_de,
          earningsExpensesOverheadExpenseItem: earningsExpensesOverheadExpenseItem_de,
          earningsExpensesOverheadItem: earningsExpensesOverheadItem_de,
          earningsExpensesOverheadTransactionEarning: earningsExpensesOverheadTransactionEarning_de,
          earningsExpensesOverheadTransactionExpense: earningsExpensesOverheadTransactionExpense_de,
          earningsExpensesOverheadTransactionItem: earningsExpensesOverheadTransactionItem_de,
          fortuneDashboard: fortuneDashboard_de,
          fortuneExpensePlannedGroup: fortuneExpensePlannedGroup_de,
          fortuneExpensePlannedItem: fortuneExpensePlannedItem_de,
          fortuneInvestmentAim: fortuneInvestmentAim_de,
          fortuneInvestmentPlan: fortuneInvestmentPlan_de,
          fortunePriority: fortunePriority_de,
          fortuneRiskClass: fortuneRiskClass_de,
          fortuneSavingPlanGroup: fortuneSavingPlanGroup_de,
          fortuneSavingPlanItem: fortuneSavingPlanItem_de,
          fortuneSavingRate: fortuneSavingRate_de,
          fortuneSavingRatio: fortuneSavingRatio_de,
          fuelConsumptionDrivingStyle: fuelConsumptionDrivingStyle_de,
          fuelConsumptionItem: fuelConsumptionItem_de,
          fuelConsumptionKindOfStreet: fuelConsumptionKindOfStreet_de,
          fuelConsumptionTire: fuelConsumptionTire_de,
          fuelConsumptionVehicleFuelUnit: fuelConsumptionVehicleFuelUnit_de,
          fuelConsumptionVehicleItem: fuelConsumptionVehicleItem_de,
          fuelConsumptionVehicleKindOfFuel: fuelConsumptionVehicleKindOfFuel_de,
          fuelConsumptionVehicleType: fuelConsumptionVehicleType_de,
          letter: letter_de,
          letterAddressGeneral: letterAddressGeneral_de,
          letterPersonSalutation: letterPersonSalutation_de,
          letterPersonTitle: letterPersonTitle_de,
          letterSenderPerson: letterSenderPerson_de,
          letterSenderCompany: letterSenderCompany_de,
          letterAddresseePerson: letterAddresseePerson_de,
          letterAddresseeCompany: letterAddresseeCompany_de,
          letterTemplate: letterTemplate_de,
          letterSignature: letterSignature_de,
          linkListGroup: linkListGroup_de,
          linkListItem: linkListItem_de,
          shoppingListGroup: shoppingListGroup_de,
          shoppingListItem: shoppingListItem_de,
          shoppingListKindOfQuantity: shoppingListKindOfQuantity_de,
          toDoListGroup: toDoListGroup_de,
          toDoListItem: toDoListItem_de,
          passwordListItem: passwordListItem_de,
          passwordListFolder: passwordListFolder_de,
          insuranceListGroup: insuranceListGroup_de,
          insuranceListPaymentInterval: insuranceListPaymentInterval_de,
          insuranceListItem: insuranceListItem_de
        }
    }
  });

export default i18n;