import IModelMapper from '../../../commons/model/IModelMapper';
import CyclingTour from './CyclingTour';

class CyclingTourModelMapper implements IModelMapper<CyclingTour> {
    fromJsonToObject(json: any): CyclingTour {
        let cyclingTour = new CyclingTour();

        cyclingTour.id = json.id;
        cyclingTour.bike = json.bike;
        cyclingTour.date = json.date;
        cyclingTour.tripDistanceInKm = json.tripDistanceInKm;
        cyclingTour.rideTime = json.rideTime;
        cyclingTour.averageSpeedInKmh = json.averageSpeedInKmh;
        cyclingTour.maxSpeedInKmh = json.maxSpeedInKmh;
        cyclingTour.averagePowerInWatt = json.averagePowerInWatt;
        cyclingTour.altitudeAscendingInM = json.altitudeAscendingInM;
        cyclingTour.distanceAscendingInKm = json.distanceAscendingInKm;
        cyclingTour.maxAltitudeInM = json.maxAltitudeInM;
        cyclingTour.averageHeartRate = json.averageHeartRate;
        cyclingTour.maxHeartRate = json.maxHeartRate;
        cyclingTour.kiloCalories = json.kiloCalories;
        cyclingTour.note = json.note;

        return cyclingTour;
    }

    fromObjectToJson(object: CyclingTour) {
        return {
            id: object.id,
            bike: (object.bike || undefined),
            date: (object.date || "0001-01-01"),
            tripDistanceInKm: (object.tripDistanceInKm || 0),
            rideTime: (object.rideTime || ""),
            averageSpeedInKmh: (object.averageSpeedInKmh || 0),
            maxSpeedInKmh: (object.maxSpeedInKmh || 0),
            averagePowerInWatt: (object.averagePowerInWatt || 0),
            altitudeAscendingInM: (object.altitudeAscendingInM || 0),
            distanceAscendingInKm: (object.distanceAscendingInKm || 0),
            maxAltitudeInM: (object.maxAltitudeInM || 0),
            averageHeartRate: (object.averageHeartRate || 0),
            maxHeartRate: (object.maxHeartRate || 0),
            kiloCalories: (object.kiloCalories || 0),
            note: (object.note || "")
        };
    }
}

export default CyclingTourModelMapper;