import React from 'react';

import FormGroupPriorityDropdown from '../fragment/FormGroupPriorityDropdown';
import FormGroupDescription from '../fragment/FormGroupDescription';
import FormGroupIssueFee from '../fragment/FormGroupIssueFee';
import FormGroupTer from '../fragment/FormGroupTer';
import FormGroupFondsAmountInMio from '../fragment/FormGroupFondsAmountInMio';
import FormGroupDistributionType from '../fragment/FormGroupDistributionType';
import FormGroupReplicationMethod from '../fragment/FormGroupReplicationMethod';
import FormGroupBankName from '../fragment/FormGroupBankName';
import FormGroupVendor from '../fragment/FormGroupVendor';
import FormGroupCountryLocation from '../fragment/FormGroupCountryLocation';
import FormGroupIsin from '../fragment/FormGroupIsin';
import FormGroupFirstIssueDate from '../fragment/FormGroupFirstIssueDate';
import FormGroupLink from '../fragment/FormGroupLink';

class Form {
    private formGroupPriority:          FormGroupPriorityDropdown;
    private formGroupDescription:       FormGroupDescription;
    private formGroupIssueFee:          FormGroupIssueFee;
    private formGroupTer:               FormGroupTer;
    private formGroupFondsAmountInMio:  FormGroupFondsAmountInMio;
    private formGroupDistributionType:  FormGroupDistributionType;
    private formGroupReplicationMethod: FormGroupReplicationMethod;
    private formGroupBankName:          FormGroupBankName;
    private formGroupVendor:            FormGroupVendor;
    private formGroupCountryLocation:    FormGroupCountryLocation;
    private formGroupIsin:              FormGroupIsin;
    private formGroupFirstIssueDate:    FormGroupFirstIssueDate;
    private formGroupLink:               FormGroupLink;

    public constructor(props, pointerToComponent) {
        this.formGroupPriority          = new FormGroupPriorityDropdown(props, pointerToComponent);
        this.formGroupDescription       = new FormGroupDescription(props, pointerToComponent);
        this.formGroupIssueFee          = new FormGroupIssueFee(props, pointerToComponent);
        this.formGroupTer               = new FormGroupTer(props, pointerToComponent);
        this.formGroupFondsAmountInMio  = new FormGroupFondsAmountInMio(props, pointerToComponent);
        this.formGroupDistributionType  = new FormGroupDistributionType(props, pointerToComponent);
        this.formGroupReplicationMethod = new FormGroupReplicationMethod(props, pointerToComponent);
        this.formGroupBankName          = new FormGroupBankName(props, pointerToComponent);
        this.formGroupVendor            = new FormGroupVendor(props, pointerToComponent);
        this.formGroupCountryLocation   = new FormGroupCountryLocation(props, pointerToComponent);
        this.formGroupIsin              = new FormGroupIsin(props, pointerToComponent);
        this.formGroupFirstIssueDate    = new FormGroupFirstIssueDate(props, pointerToComponent);
        this.formGroupLink               = new FormGroupLink(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupPriority.render() }
                { this.formGroupDescription.render() }
                { this.formGroupIssueFee.render() }
                { this.formGroupTer.render() }
                { this.formGroupFondsAmountInMio.render() }
                { this.formGroupDistributionType.render() }
                { this.formGroupReplicationMethod.render() }
                { this.formGroupBankName.render() }
                { this.formGroupVendor.render() }
                { this.formGroupCountryLocation.render() }
                { this.formGroupIsin.render() }
                { this.formGroupFirstIssueDate.render() }
                { this.formGroupLink.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.formGroupPriority.isInvalidValue()
             || this.formGroupDescription.isInvalidValue()
             || this.formGroupIssueFee.isInvalidValue()
             || this.formGroupTer.isInvalidValue()
             || this.formGroupFondsAmountInMio.isInvalidValue()
             || this.formGroupDistributionType.isInvalidValue()
             || this.formGroupReplicationMethod.isInvalidValue()
             || this.formGroupBankName.isInvalidValue()
             || this.formGroupVendor.isInvalidValue()
             || this.formGroupCountryLocation.isInvalidValue()
             || this.formGroupIsin.isInvalidValue()
             || this.formGroupFirstIssueDate.isInvalidValue()
             || this.formGroupLink.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;