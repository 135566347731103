import React from 'react';
import { Component } from 'react';
import { Table } from 'reactstrap';
import { Trans } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import I18nConstants from '../../../i18n/I18nConstants';
import i18n from '../../../../../commons/i18n/i18n';
import EarningsExpensesOverheadTransactionApi from '../../../../../earningsExpensesOverhead/transaction/item/api/EarningsExpensesOverheadTransactionApi';
import EarningsExpensesUrlPath from '../../EarningsExpensesUrlPath';

interface IProps {
    history: any;
}

interface IState {
}

class EarningsExpensesPostOverheadCurrentMonthDialog extends Component<IProps, IState> {
    public constructor(props) {
        super(props);
    }

    public renderMessageBoxOnly() {
        let yesLabel = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_YES);
        let noLabel  = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_NO);

        EarningsExpensesOverheadTransactionApi.getInstance().getPostingsForCurrentMonthAndYear()
        .then( response => {
                    let earnings = (response as any).earnings;
                    let expenses = (response as any).expenses;

                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (
                                <div className="react-confirm-alert">
                                    <div className="react-confirm-alert-body">
                                        <h1>
                                            <Trans ns={I18nConstants.NS}
                                                   i18nKey={I18nConstants.POST_OVERHEAD_CURRENT_MONTH}>
                                                {I18nConstants.POST_OVERHEAD_CURRENT_MONTH_DEFAULT}
                                            </Trans>
                                        </h1>

                                        <p>
                                            <Trans ns={I18nConstants.NS}
                                                   i18nKey={I18nConstants.EARNINGS}>
                                                {I18nConstants.EARNINGS_DEFAULT}
                                            </Trans> 
                                        </p>

                                        <Table>
                                            <thead>
                                                { this.getTableHeadWrappedInTrTag() }
                                            </thead>
                                            <tbody>
                                                { this.getTableBodyEarnings(earnings) }
                                            </tbody>
                                        </Table>

                                        <p>
                                            <Trans ns={I18nConstants.NS}
                                                   i18nKey={I18nConstants.EXPENSES}>
                                                {I18nConstants.EXPENSES_DEFAULT}
                                            </Trans> 
                                        </p>

                                        <Table>
                                            <thead>
                                                { this.getTableHeadWrappedInTrTag() }
                                            </thead>
                                            <tbody>
                                                { this.getTableBodyExpenses(expenses) }
                                            </tbody>
                                        </Table>

                                        <div className="react-confirm-alert-button-group">
                                            <button onClick={ () => { this.onClickPostTransactions(onClose) } }>
                                                {yesLabel}
                                            </button>
                                            
                                            <button onClick={onClose}>{noLabel}</button>
                                        </div>
                                    </div>
                                </div> );
                        }
                    });
               })
        .catch( error   => {
                    console.log("TODO");
                });        
    }

    private getTableHeadWrappedInTrTag() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DATE}>
                        {I18nConstants.FORM_DATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_GROUP}>
                        {I18nConstants.FORM_GROUP_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AMOUNT}>
                        {I18nConstants.FORM_AMOUNT_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_IS_OVERHEAD}>
                        {I18nConstants.FORM_IS_OVERHEAD_DEFAULT}
                    </Trans>
                </th>
            </tr> );
    }

    private getTableBodyEarnings(earnings) {
        return earnings.map( item => 
            <tr key={item.id}>
                <td>
                    {item.description}
                </td>

                <td>
                    {item.date}
                </td>

                <td>
                    {item.group.name}
                </td>

                <td>
                    {item.amount}
                </td>

                <td>
                    {item.isOverhead}
                </td>
            </tr> );
    }

    private getTableBodyExpenses(expenses) {
        return expenses.map( item => 
            <tr key={item.id}>
                <td>
                    {item.description}
                </td>

                <td>
                    {item.date}
                </td>

                <td>
                    {item.group.name}
                </td>

                <td>
                    {item.amount}
                </td>

                <td>
                    {item.isOverhead}
                </td>
            </tr> );
    }

    private onClickPostTransactions(onClose) {
        onClose();

        EarningsExpensesOverheadTransactionApi.getInstance()
        .createPostingsForCurrentMonthAndYear()
            .then(  response => { 
                        this.props.history.push(EarningsExpensesUrlPath.BASE);
                    })
            .catch( error    => { 
                        console.log("TODO");
                    });
    }
}

export default EarningsExpensesPostOverheadCurrentMonthDialog;