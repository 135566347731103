import FortuneInvestmentAim from '../../model/FortuneInvestmentAim';
import FortuneInvestmentAimApi from '../../api/FortuneInvestmentAimApi';
import FortuneInvestmentAimUrlPath from '../FortuneInvestmentAimUrlPath';
import CreateBase from '../../../../commons/ui/page/template/create/CreateBase';
import Form from './Form';
import FormButtonCreate from '../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBase<FortuneInvestmentAim, FortuneInvestmentAimApi> {
    private form: Form;

    public constructor(props) {
        super(props, 
              new FortuneInvestmentAim(),
              FortuneInvestmentAimApi.getInstance(),
              FortuneInvestmentAimUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;