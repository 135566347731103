import IModelMapper from '../../../../commons/model/IModelMapper';

import KindOfFuel from './KindOfFuel';

class KindOfFuelModelMapper implements IModelMapper<KindOfFuel> {
    fromJsonToObject(json: any): KindOfFuel {
        let kindOfFuel = new KindOfFuel();

        kindOfFuel.id   = json.id;
        kindOfFuel.name = json.name;

        return kindOfFuel;
    }

    fromObjectToJson(object: KindOfFuel) {
        return {
            id: object.id,
            name: (object.name || "") };
    }
}

export default KindOfFuelModelMapper;