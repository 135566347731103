import CreateBase from '../../../../commons/ui/page/template/group/create/CreateBase';

import KindOfStreet from '../../model/KindOfStreet';
import KindOfStreetApi from '../../api/KindOfStreetApi';
import KindOfStreetUrlPath from '../KindOfStreetUrlPath';

class Create extends CreateBase<KindOfStreet, KindOfStreetApi> {
    public constructor(props) {
        super(props,
              new KindOfStreet(),
              KindOfStreetApi.getInstance(),
              KindOfStreetUrlPath.BASE);
    }
}

export default Create;