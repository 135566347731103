import React from 'react';
import Navigation from './Navigation';

class NavigationRenderer {
    public static renderIfNecessary(shouldBeRendered) {
        if (shouldBeRendered) {
            return ( <Navigation /> );
        } else {
            return ( <div></div> );
        }
    }
}

export default NavigationRenderer;