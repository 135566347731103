import React from 'react';
import { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthenticationApi from '../security/api/AuthenticationApi';
import Dashbaord from '../dashboard/ui/pages/Dashboard'; 
import DashboardUrlPath from '../dashboard/ui/DashboardUrlPath';
import Login from '../login/ui/page/Login';
import LoginUrlPath from '../login/ui/LoginUrlPath';
import Logout from '../logout/ui/page/Logout';
import LogoutUrlPath from '../logout/ui/LogoutUrlPath';
import ToDoListUrlPath from '../toDoList/item/ui/ToDoListUrlPath';
import ToDoListOverview from '../toDoList/item/ui/page/Overview';
import ToDoListCreate from '../toDoList/item/ui/page/Create';
import ToDoListUpdate from '../toDoList/item/ui/page/Update';
import ToDoListGroupUrlPath from '../toDoList/group/ui/ToDoListGroupUrlPath';
import ToDoListGroupOverview from '../toDoList/group/ui/page/Overview';
import ToDoListGroupCreate from '../toDoList/group/ui/page/Create';
import ToDoListGroupUpdate from '../toDoList/group/ui/page/Update';
import ShoppingListUrlPath from '../shoppingList/item/ui/ShoppingListUrlPath';
import ShoppingListOverview from '../shoppingList/item/ui/page/Overview';
import ShoppingListCreate from '../shoppingList/item/ui/page/Create';
import ShoppingListUpdate from '../shoppingList/item/ui/page/Update';
import ShoppingListGroupUrlPath from '../shoppingList/group/ui/ShoppingListGroupUrlPath';
import ShoppingListGroupOverview from '../shoppingList/group/ui/page/Overview';
import ShoppingListGroupCreate from '../shoppingList/group/ui/page/Create';
import ShoppingListGroupUpdate from '../shoppingList/group/ui/page/Update';
import ShoppingListKindOfQuantityUrlPath from '../shoppingList/kindOfQuantity/ui/ShoppingListKindOfQuantityUrlPath';
import ShoppingListKindOfQuantityOverview from '../shoppingList/kindOfQuantity/ui/page/Overview';
import ShoppingListKindOfQuantityCreate from '../shoppingList/kindOfQuantity/ui/page/Create';
import ShoppingListKindOfQuantityUpdate from '../shoppingList/kindOfQuantity/ui/page/Update';
import EarningsExpensesUrlPath from '../earningsExpenses/item/ui/EarningsExpensesUrlPath';
import EarningsExpensesOverview from '../earningsExpenses/item/ui/page/Overview';
import EarningsExpensesOverviewGroupedByMonthAndYear from '../earningsExpenses/item/ui/page/OverviewGroupedByMonthAndYear';
import EarningsExpensesOverviewGroupedByYear from '../earningsExpenses/item/ui/page/OverviewGroupedByYear';
import EarningsExpensesEarningUrlPath from '../earningsExpenses/earning/item/ui/EarningsExpensesEarningUrlPath';
import EarningsExpensesEarningCreate from '../earningsExpenses/earning/item/ui/page/Create';
import EarningsExpensesEarningUpdate from '../earningsExpenses/earning/item/ui/page/Update';
import EarningsExpensesEarningGroupUrlPath from '../earningsExpenses/earning/group/ui/EarningsExpensesEarningGroupUrlPath';
import EarningsExpensesEarningGroupOverview from '../earningsExpenses/earning/group/ui/page/Overview';
import EarningsExpensesEarningGroupCreate from '../earningsExpenses/earning/group/ui/page/Create';
import EarningsExpensesEarningGroupUpdate from '../earningsExpenses/earning/group/ui/page/Update';
import EarningsExpensesExpenseUrlPath from '../earningsExpenses/expense/item/ui/EarningsExpensesExpenseUrlPath';
import EarningsExpensesExpenseCreate from '../earningsExpenses/expense/item/ui/page/Create';
import EarningsExpensesExpenseUpdate from '../earningsExpenses/expense/item/ui/page/Update';
import EarningsExpensesExpenseGroupUrlPath from '../earningsExpenses/expense/group/ui/EarningsExpensesExpenseGroupUrlPath';
import EarningsExpensesExpenseGroupOverview from '../earningsExpenses/expense/group/ui/page/Overview';
import EarningsExpensesExpenseGroupCreate from '../earningsExpenses/expense/group/ui/page/Create';
import EarningsExpensesExpenseGroupUpdate from '../earningsExpenses/expense/group/ui/page/Update';
import EarningsExpensesOverheadUrlPath from '../earningsExpensesOverhead/item/ui/EarningsExpensesOverheadUrlPath';
import EarningsExpensesOverheadOverview from '../earningsExpensesOverhead/item/ui/page/Overview';
import EarningsExpensesOverheadOverviewGrouped from '../earningsExpensesOverhead/item/ui/page/OverviewGrouped';
import EarningsExpensesOverheadEarningUrlPath from '../earningsExpensesOverhead/earning/item/ui/EarningsExpensesOverheadEarningUrlPath';
import EarningsExpensesOverheadEarningCreate from '../earningsExpensesOverhead/earning/item/ui/page/Create';
import EarningsExpensesOverheadEarningUpdate from '../earningsExpensesOverhead/earning/item/ui/page/Update';
import EarningsExpensesOverheadTransactionEarningUrlPath from '../earningsExpensesOverhead/transaction/earning/ui/EarningsExpensesOverheadTransactionEarningUrlPath';
import EarningsExpensesOverheadTransactionEarningOverview from '../earningsExpensesOverhead/transaction/earning/ui/page/Overview';
import EarningsExpensesOverheadTransactionEarningCreate from '../earningsExpensesOverhead/transaction/earning/ui/page/Create';
import EarningsExpensesOverheadTransactionEarningUpdate from '../earningsExpensesOverhead/transaction/earning/ui/page/Update';
import EarningsExpensesOverheadTransactionEarningUpdateAll from '../earningsExpensesOverhead/transaction/earning/ui/page/UpdateAll';
import EarningsExpensesOverheadExpenseUrlPath from '../earningsExpensesOverhead/expense/item/ui/EarningsExpensesOverheadExpenseUrlPath';
import EarningsExpensesOverheadExpenseCreate from '../earningsExpensesOverhead/expense/item/ui/page/Create';
import EarningsExpensesOverheadExpenseUpdate from '../earningsExpensesOverhead/expense/item/ui/page/Update';
import EarningsExpensesOverheadTransactionExpenseUrlPath from '../earningsExpensesOverhead/transaction/expense/ui/EarningsExpensesOverheadTransactionExpenseUrlPath';
import EarningsExpensesOverheadTransactionExpenseOverview from '../earningsExpensesOverhead/transaction/expense/ui/page/Overview';
import EarningsExpensesOverheadTransactionExpenseCreate from '../earningsExpensesOverhead/transaction/expense/ui/page/Create';
import EarningsExpensesOverheadTransactionExpenseUpdate from '../earningsExpensesOverhead/transaction/expense/ui/page/Update';
import EarningsExpensesOverheadTransactionExpenseUpdateAll from '../earningsExpensesOverhead/transaction/expense/ui/page/UpdateAll';
import BankingListUrlPath from '../bankingList/item/ui/BankingListUrlPath';
import BankingListOverview from '../bankingList/item/ui/page/Overview';
import BankingListCreate from '../bankingList/item/ui/page/Create';
import BankingListUpdate from '../bankingList/item/ui/page/Update';
import BankingListGroupUrlPath from '../bankingList/group/ui/BankingListGroupUrlPath';
import BankingListGroupOverview from '../bankingList/group/ui/page/Overview';
import BankingListGroupCreate from '../bankingList/group/ui/page/Create';
import BankingListGroupUpdate from '../bankingList/group/ui/page/Update';
import LinkListUrlPath from '../linkList/item/ui/LinkListUrlPath';
import LinkListOverview from '../linkList/item/ui/page/Overview';
import LinkListCreate from '../linkList/item/ui/page/Create';
import LinkListUpdate from '../linkList/item/ui/page/Update';
import LinkListGroupUrlPath from '../linkList/group/ui/LinkListGroupUrlPath';
import LinkListGroupOverview from '../linkList/group/ui/page/Overview';
import LinkListGroupCreate from '../linkList/group/ui/page/Create';
import LinkListGroupUpdate from '../linkList/group/ui/page/Update';
import FortuneDashboardUrlPath from '../fortune/dashboard/ui/FortuneDashboardUrlPath';
import FortuneOverview from '../fortune/dashboard/ui/page/Overview';
import FortunePriorityUrlPath from '../fortune/priority/ui/FortunePriorityUrlPath';
import FortunePriorityOverview from '../fortune/priority/ui/page/Overview';
import FortunePriorityCreate from '../fortune/priority/ui/page/Create';
import FortunePriorityUpdate from '../fortune/priority/ui/page/Update';
import FortuneRiskClassUrlPath from '../fortune/riskClass/ui/FortuneRiskClassUrlPath';
import FortuneRiskClassOverview from '../fortune/riskClass/ui/page/Overview';
import FortuneRiskClassCreate from '../fortune/riskClass/ui/page/Create';
import FortuneRiskClassUpdate from '../fortune/riskClass/ui/page/Update';
import FortuneSavingRatioUrlPath from '../fortune/savingRatio/ui/FortuneSavingRatioUrlPath';
import FortuneSavingRatioOverview from '../fortune/savingRatio/ui/page/Overview';
import FortuneSavingRatioCreate from '../fortune/savingRatio/ui/page/Create';
import FortuneSavingRatioUpdate from '../fortune/savingRatio/ui/page/Update';
import FortuneSavingPlanUrlPath from '../fortune/savingPlan/item/ui/FortuneSavingPlanUrlPath';
import FortuneSavingPlanOverview from '../fortune/savingPlan/item/ui/page/Overview';
import FortuneSavingPlanCreate from '../fortune/savingPlan/item/ui/page/Create';
import FortuneSavingPlanUpdate from '../fortune/savingPlan/item/ui/page/Update';
import FortuneSavingPlanGroupUrlPath from '../fortune/savingPlan/group/ui/FortuneSavingPlanGroupUrlPath';
import FortuneSavingPlanGroupOverview from '../fortune/savingPlan/group/ui/page/Overview';
import FortuneSavingPlanGroupCreate from '../fortune/savingPlan/group/ui/page/Create';
import FortuneSavingPlanGroupUpdate from '../fortune/savingPlan/group/ui/page/Update';
import FortuneSavingRateUrlPath from '../fortune/savingRate/ui/FortuneSavingRateUrlPath';
import FortuneSavingRateOverview from '../fortune/savingRate/ui/page/Overview';
import FortuneSavingRateCreate from '../fortune/savingRate/ui/page/Create';
import FortuneSavingRateCreateInWizardForAllSavingPlans from '../fortune/savingRate/ui/page/CreateInWizardForAllSavingPlans';
import FortuneSavingRateUpdate from '../fortune/savingRate/ui/page/Update';
import FortuneInvestmentPlanUrlPath from '../fortune/investmentPlan/ui/FortuneInvestmentPlanUrlPath';
import FortuneInvestmentPlanOverview from '../fortune/investmentPlan/ui/page/Overview';
import FortuneInvestmentPlanCreate from '../fortune/investmentPlan/ui/page/Create';
import FortuneInvestmentPlanUpdate from '../fortune/investmentPlan/ui/page/Update';
import FortuneInvestmentAimUrlPath from '../fortune/investmentAim/ui/FortuneInvestmentAimUrlPath';
import FortuneInvestmentAimOverview from '../fortune/investmentAim/ui/page/Overview';
import FortuneInvestmentAimCreate from '../fortune/investmentAim/ui/page/Create';
import FortuneInvestmentAimUpdate from '../fortune/investmentAim/ui/page/Update';
import FortuneExpensePlannedUrlPath from '../fortune/expensePlanned/item/ui/FortuneExpensePlannedUrlPath';
import FortuneExpensePlannedOverview from '../fortune/expensePlanned/item/ui/page/Overview';
import FortuneExpensePlannedCreate from '../fortune/expensePlanned/item/ui/page/Create';
import FortuneExpensePlannedUpdate from '../fortune/expensePlanned/item/ui/page/Update';
import FortuneExpensePlannedGroupUrlPath from '../fortune/expensePlanned/group/ui/FortuneExpensePlannedGroupUrlPath';
import FortuneExpensePlannedGroupOverview from '../fortune/expensePlanned/group/ui/page/Overview';
import FortuneExpensePlannedGroupCreate from '../fortune/expensePlanned/group/ui/page/Create';
import FortuneExpensePlannedGroupUpdate from '../fortune/expensePlanned/group/ui/page/Update';
import FuelConsumptionUrlPath from '../fuelConsumption/item/ui/FuelConsumptionUrlPath';
import FuelConsumptionOverview from '../fuelConsumption/item/ui/page/Overview';
import FuelConsumptionCreate from '../fuelConsumption/item/ui/page/Create';
import FuelConsumptionUpdate from '../fuelConsumption/item/ui/page/Update';
import FuelConsumptionDrivingStyleUrlPath from '../fuelConsumption/drivingStyle/ui/DrivingStyleUrlPath';
import FuelConsumptionDrivingStyleOverview from '../fuelConsumption/drivingStyle/ui/page/Overview';
import FuelConsumptionDrivingStyleCreate from '../fuelConsumption/drivingStyle/ui/page/Create';
import FuelConsumptionDrivingStyleUpdate from '../fuelConsumption/drivingStyle/ui/page/Update';
import FuelConsumptionKindOfStreetUrlPath from '../fuelConsumption/kindOfStreet/ui/KindOfStreetUrlPath';
import FuelConsumptionKindOfStreetOverview from '../fuelConsumption/kindOfStreet/ui/page/Overview';
import FuelConsumptionKindOfStreetCreate from '../fuelConsumption/kindOfStreet/ui/page/Create';
import FuelConsumptionKindOfStreetUpdate from '../fuelConsumption/kindOfStreet/ui/page/Update';
import FuelConsumptionTireUrlPath from '../fuelConsumption/tire/ui/TireUrlPath';
import FuelConsumptionTireOverview from '../fuelConsumption/tire/ui/page/Overview';
import FuelConsumptionTireCreate from '../fuelConsumption/tire/ui/page/Create';
import FuelConsumptionTireUpdate from '../fuelConsumption/tire/ui/page/Update';
import FuelConsumptionVehicleUrlPath from '../fuelConsumption/vehicle/item/ui/VehicleUrlPath';
import FuelConsumptionVehicleOverview from '../fuelConsumption/vehicle/item/ui/page/Overview';
import FuelConsumptionVehicleCreate from '../fuelConsumption/vehicle/item/ui/page/Create';
import FuelConsumptionVehicleUpdate from '../fuelConsumption/vehicle/item/ui/page/Update';
import FuelConsumptionVehicleFuelUnitUrlPath from '../fuelConsumption/vehicle/fuelUnit/ui/FuelUnitUrlPath';
import FuelConsumptionVehicleFuelUnitOverview from '../fuelConsumption/vehicle/fuelUnit/ui/page/Overview';
import FuelConsumptionVehicleFuelUnitCreate from '../fuelConsumption/vehicle/fuelUnit/ui/page/Create';
import FuelConsumptionVehicleFuelUnitUpdate from '../fuelConsumption/vehicle/fuelUnit/ui/page/Update';
import FuelConsumptionVehicleKindOfFuelUrlPath from '../fuelConsumption/vehicle/kindOfFuel/ui/KindOfFuelUrlPath';
import FuelConsumptionVehicleKindOfFuelOverview from '../fuelConsumption/vehicle/kindOfFuel/ui/page/Overview';
import FuelConsumptionVehicleKindOfFuelCreate from '../fuelConsumption/vehicle/kindOfFuel/ui/page/Create';
import FuelConsumptionVehicleKindOfFuelUpdate from '../fuelConsumption/vehicle/kindOfFuel/ui/page/Update';
import FuelConsumptionVehicleTypeUrlPath from '../fuelConsumption/vehicle/type/ui/VehicleTypeUrlPath';
import FuelConsumptionVehicleTypeOverview from '../fuelConsumption/vehicle/type/ui/page/Overview';
import FuelConsumptionVehicleTypeCreate from '../fuelConsumption/vehicle/type/ui/page/Create';
import FuelConsumptionVehicleTypeUpdate from '../fuelConsumption/vehicle/type/ui/page/Update';
import CyclingTourUrlPath from '../cyclingTour/item/ui/CyclingTourUrlPath';
import CyclingTourOverview from '../cyclingTour/item/ui/page/Overview';
import CyclingTourCreate from '../cyclingTour/item/ui/page/Create';
import CyclingTourUpdate from '../cyclingTour/item/ui/page/Update';
import CyclingTourBikeUrlPath from '../cyclingTour/bike/item/ui/BikeUrlPath';
import CyclingTourBikeOverview from '../cyclingTour/bike/item/ui/page/Overview';
import CyclingTourBikeCreate from '../cyclingTour/bike/item/ui/page/Create';
import CyclingTourBikeUpdate from '../cyclingTour/bike/item/ui/page/Update';
import CyclingTourBikeTypeUrlPath from '../cyclingTour/bike/type/ui/BikeTypeUrlPath';
import CyclingTourBikeTypeOverview from '../cyclingTour/bike/type/ui/page/Overview';
import CyclingTourBikeTypeCreate from '../cyclingTour/bike/type/ui/page/Create';
import CyclingTourBikeTypeUpdate from '../cyclingTour/bike/type/ui/page/Update';
import LetterUrlPath from '../letter/item/ui/LetterUrlPath';
import LetterOverview from '../letter/item/ui/page/Overview';
import LetterCreate from '../letter/item/ui/page/Create';
import LetterUpdate from '../letter/item/ui/page/Update';
import LetterDetails from '../letter/item/ui/page/Details';
import LetterPersonSalutationUrlPath from '../letter/person/salutation/ui/LetterPersonSalutationUrlPath';
import LetterPersonSalutationOverview from '../letter/person/salutation/ui/page/Overview';
import LetterPersonSalutationCreate from '../letter/person/salutation/ui/page/Create';
import LetterPersonSalutationUpdate from '../letter/person/salutation/ui/page/Update';
import LetterPersonTitleUrlPath from '../letter/person/title/ui/LetterPersonTitleUrlPath';
import LetterPersonTitleOverview from '../letter/person/title/ui/page/Overview';
import LetterPersonTitleCreate from '../letter/person/title/ui/page/Create';
import LetterPersonTitleUpdate from '../letter/person/title/ui/page/Update';
import LetterTemplateUrlPath from '../letter/template/ui/LetterTemplateUrlPath';
import LetterTemplateOverview from '../letter/template/ui/page/Overview';
import LetterTemplateCreate from '../letter/template/ui/page/Create';
import LetterTemplateUpdate from '../letter/template/ui/page/Update';
import LetterSenderPersonUrlPath from '../letter/sender/person/ui/LetterSenderPersonUrlPath';
import LetterSenderPersonOverview from '../letter/sender/person/ui/page/Overview';
import LetterSenderPersonCreate from '../letter/sender/person/ui/page/Create';
import LetterSenderPersonUpdate from '../letter/sender/person/ui/page/Update';
import LetterSenderCompanyUrlPath from '../letter/sender/company/ui/LetterSenderCompanyUrlPath';
import LetterSenderCompanyOverview from '../letter/sender/company/ui/page/Overview';
import LetterSenderCompanyCreate from '../letter/sender/company/ui/page/Create';
import LetterSenderCompanyUpdate from '../letter/sender/company/ui/page/Update';
import LetterAddresseePersonUrlPath from '../letter/addressee/person/ui/LetterAddresseePersonUrlPath';
import LetterAddresseePersonOverview from '../letter/addressee/person/ui/page/Overview';
import LetterAddresseePersonCreate from '../letter/addressee/person/ui/page/Create';
import LetterAddresseePersonUpdate from '../letter/addressee/person/ui/page/Update';
import LetterAddresseeCompanyUrlPath from '../letter/addressee/company/ui/LetterAddresseeCompanyUrlPath';
import LetterAddresseeCompanyOverview from '../letter/addressee/company/ui/page/Overview';
import LetterAddresseeCompanyCreate from '../letter/addressee/company/ui/page/Create';
import LetterAddresseeCompanyUpdate from '../letter/addressee/company/ui/page/Update';
import LetterSignatureUrlPath from '../letter/signature/ui/LetterSignatureUrlPath';
import LetterSignatureOverview from '../letter/signature/ui/page/Overview';
import LetterSignatureCreate from '../letter/signature/ui/page/Create';
import LetterSignatureUpdate from '../letter/signature/ui/page/Update';
import PasswordListUrlPath from '../passwordList/item/ui/PasswordListUrlPath';
import PasswordListOverview from '../passwordList/item/ui/page/Overview';
import PasswordListCreate from '../passwordList/item/ui/page/Create';
import PasswordListUpdate from '../passwordList/item/ui/page/Update';
import PasswordListFolderUrlPath from '../passwordList/folder/ui/PasswordListFolderUrlPath';
import PasswordListFolderOverview from '../passwordList/folder/ui/page/Overview';
import PasswordListFolderCreate from '../passwordList/folder/ui/page/Create';
import PasswordListFolderUpdate from '../passwordList/folder/ui/page/Update';
import InsuranceListUrlPath from '../insuranceList/item/ui/InsuranceListUrlPath';
import InsuranceListOverview from '../insuranceList/item/ui/page/Overview';
import InsuranceListCreate from '../insuranceList/item/ui/page/Create';
import InsuranceListUpdate from '../insuranceList/item/ui/page/Update';
import InsuranceListGroupUrlPath from '../insuranceList/group/ui/InsuranceListGroupUrlPath';
import InsuranceListGroupOverview from '../insuranceList/group/ui/page/Overview';
import InsuranceListGroupCreate from '../insuranceList/group/ui/page/Create';
import InsuranceListGroupUpdate from '../insuranceList/group/ui/page/Update';
import InsuranceListPaymentIntervalUrlPath from '../insuranceList/paymentInterval/ui/InsuranceListPaymentIntervalUrlPath';
import InsuranceListPaymentIntervalOverview from '../insuranceList/paymentInterval/ui/page/Overview';
import InsuranceListPaymentIntervalCreate from '../insuranceList/paymentInterval/ui/page/Create';
import InsuranceListPaymentIntervalUpdate from '../insuranceList/paymentInterval/ui/page/Update';

class App extends Component {
  render() {
    return ( 
      <Router>
        <Switch>
          <Route path="/" exact={true} component={Dashbaord} />
          
          <Route path={LoginUrlPath.BASE} exact={true} component={Login} />
          <Route path={LogoutUrlPath.BASE} exact={true} component={Logout} />

          <Route path={DashboardUrlPath.BASE} exact={true} component={Dashbaord} />

          <Route path={ToDoListUrlPath.BASE} exact={true} component={ToDoListOverview} />
          <Route path={ToDoListUrlPath.CREATE} exact={true} component={ToDoListCreate} />
          <Route path={ToDoListUrlPath.UPDATE} exact={true} component={ToDoListUpdate} />
          <Route path={ToDoListGroupUrlPath.BASE} exact={true} component={ToDoListGroupOverview} />
          <Route path={ToDoListGroupUrlPath.CREATE} exact={true} component={ToDoListGroupCreate} />
          <Route path={ToDoListGroupUrlPath.UPDATE} exact={true} component={ToDoListGroupUpdate} />
          
          <Route path={ShoppingListUrlPath.BASE} exact={true} component={ShoppingListOverview} />
          <Route path={ShoppingListUrlPath.CREATE} exact={true} component={ShoppingListCreate} />
          <Route path={ShoppingListUrlPath.UPDATE} exact={true} component={ShoppingListUpdate} />
          <Route path={ShoppingListGroupUrlPath.BASE} exact={true} component={ShoppingListGroupOverview} />
          <Route path={ShoppingListGroupUrlPath.CREATE} exact={true} component={ShoppingListGroupCreate} />
          <Route path={ShoppingListGroupUrlPath.UPDATE} exact={true} component={ShoppingListGroupUpdate} />
          <Route path={ShoppingListKindOfQuantityUrlPath.BASE} exact={true} component={ShoppingListKindOfQuantityOverview} />
          <Route path={ShoppingListKindOfQuantityUrlPath.CREATE} exact={true} component={ShoppingListKindOfQuantityCreate} />
          <Route path={ShoppingListKindOfQuantityUrlPath.UPDATE} exact={true} component={ShoppingListKindOfQuantityUpdate} />

          <Route path={EarningsExpensesUrlPath.BASE} exact={true} component={EarningsExpensesOverview} />
          <Route path={EarningsExpensesUrlPath.GROUPED_BY_MONTH_AND_YEAR} exact={true} component={EarningsExpensesOverviewGroupedByMonthAndYear} />
          <Route path={EarningsExpensesUrlPath.GROUPED_BY_YEAR} exact={true} component={EarningsExpensesOverviewGroupedByYear} />

          <Route path={EarningsExpensesEarningUrlPath.CREATE} exact={true} component={EarningsExpensesEarningCreate} />
          <Route path={EarningsExpensesEarningUrlPath.UPDATE} exact={true} component={EarningsExpensesEarningUpdate} />
          <Route path={EarningsExpensesEarningGroupUrlPath.BASE} exact={true} component={EarningsExpensesEarningGroupOverview} />
          <Route path={EarningsExpensesEarningGroupUrlPath.CREATE} exact={true} component={EarningsExpensesEarningGroupCreate} />
          <Route path={EarningsExpensesEarningGroupUrlPath.UPDATE} exact={true} component={EarningsExpensesEarningGroupUpdate} />

          <Route path={EarningsExpensesExpenseUrlPath.CREATE} exact={true} component={EarningsExpensesExpenseCreate} />
          <Route path={EarningsExpensesExpenseUrlPath.UPDATE} exact={true} component={EarningsExpensesExpenseUpdate} />
          <Route path={EarningsExpensesExpenseGroupUrlPath.BASE} exact={true} component={EarningsExpensesExpenseGroupOverview} />
          <Route path={EarningsExpensesExpenseGroupUrlPath.CREATE} exact={true} component={EarningsExpensesExpenseGroupCreate} />
          <Route path={EarningsExpensesExpenseGroupUrlPath.UPDATE} exact={true} component={EarningsExpensesExpenseGroupUpdate} />

          <Route path={EarningsExpensesOverheadUrlPath.BASE} exact={true} component={EarningsExpensesOverheadOverview} />
          <Route path={EarningsExpensesOverheadUrlPath.GROUPED} exact={true} component={EarningsExpensesOverheadOverviewGrouped} />
          
          <Route path={EarningsExpensesOverheadEarningUrlPath.CREATE} exact={true} component={EarningsExpensesOverheadEarningCreate} />
          <Route path={EarningsExpensesOverheadEarningUrlPath.UPDATE} exact={true} component={EarningsExpensesOverheadEarningUpdate} />
          <Route path={EarningsExpensesOverheadTransactionEarningUrlPath.BASE} exact={true} component={EarningsExpensesOverheadTransactionEarningOverview} />
          <Route path={EarningsExpensesOverheadTransactionEarningUrlPath.CREATE} exact={true} component={EarningsExpensesOverheadTransactionEarningCreate} />
          <Route path={EarningsExpensesOverheadTransactionEarningUrlPath.UPDATE} exact={true} component={EarningsExpensesOverheadTransactionEarningUpdate} />
          <Route path={EarningsExpensesOverheadTransactionEarningUrlPath.UPDATE_ALL} exact={true} component={EarningsExpensesOverheadTransactionEarningUpdateAll} />

          <Route path={EarningsExpensesOverheadExpenseUrlPath.CREATE} exact={true} component={EarningsExpensesOverheadExpenseCreate} />
          <Route path={EarningsExpensesOverheadExpenseUrlPath.UPDATE} exact={true} component={EarningsExpensesOverheadExpenseUpdate} />
          <Route path={EarningsExpensesOverheadTransactionExpenseUrlPath.BASE} exact={true} component={EarningsExpensesOverheadTransactionExpenseOverview} />
          <Route path={EarningsExpensesOverheadTransactionExpenseUrlPath.CREATE} exact={true} component={EarningsExpensesOverheadTransactionExpenseCreate} />
          <Route path={EarningsExpensesOverheadTransactionExpenseUrlPath.UPDATE} exact={true} component={EarningsExpensesOverheadTransactionExpenseUpdate} />
          <Route path={EarningsExpensesOverheadTransactionExpenseUrlPath.UPDATE_ALL} exact={true} component={EarningsExpensesOverheadTransactionExpenseUpdateAll} />

          <Route path={BankingListUrlPath.BASE} exact={true} component={BankingListOverview} />
          <Route path={BankingListUrlPath.CREATE} exact={true} component={BankingListCreate} />
          <Route path={BankingListUrlPath.UPDATE} exact={true} component={BankingListUpdate} />
          <Route path={BankingListGroupUrlPath.BASE} exact={true} component={BankingListGroupOverview} />
          <Route path={BankingListGroupUrlPath.CREATE} exact={true} component={BankingListGroupCreate} />
          <Route path={BankingListGroupUrlPath.UPDATE} exact={true} component={BankingListGroupUpdate} />

          <Route path={LinkListUrlPath.BASE} exact={true} component={LinkListOverview} />
          <Route path={LinkListUrlPath.CREATE} exact={true} component={LinkListCreate} />
          <Route path={LinkListUrlPath.UPDATE} exact={true} component={LinkListUpdate} />
          <Route path={LinkListGroupUrlPath.BASE} exact={true} component={LinkListGroupOverview} />
          <Route path={LinkListGroupUrlPath.CREATE} exact={true} component={LinkListGroupCreate} />
          <Route path={LinkListGroupUrlPath.UPDATE} exact={true} component={LinkListGroupUpdate} />

          <Route path={FortuneDashboardUrlPath.BASE} exact={true} component={FortuneOverview} />

          <Route path={FortunePriorityUrlPath.BASE} exact={true} component={FortunePriorityOverview} />
          <Route path={FortunePriorityUrlPath.CREATE} exact={true} component={FortunePriorityCreate} />
          <Route path={FortunePriorityUrlPath.UPDATE} exact={true} component={FortunePriorityUpdate} />

          <Route path={FortuneRiskClassUrlPath.BASE} exact={true} component={FortuneRiskClassOverview} />
          <Route path={FortuneRiskClassUrlPath.CREATE} exact={true} component={FortuneRiskClassCreate} />
          <Route path={FortuneRiskClassUrlPath.UPDATE} exact={true} component={FortuneRiskClassUpdate} />

          <Route path={FortuneSavingRatioUrlPath.BASE} exact={true} component={FortuneSavingRatioOverview} />
          <Route path={FortuneSavingRatioUrlPath.CREATE} exact={true} component={FortuneSavingRatioCreate} />
          <Route path={FortuneSavingRatioUrlPath.UPDATE} exact={true} component={FortuneSavingRatioUpdate} />

          <Route path={FortuneSavingPlanUrlPath.BASE} exact={true} component={FortuneSavingPlanOverview} />
          <Route path={FortuneSavingPlanUrlPath.CREATE} exact={true} component={FortuneSavingPlanCreate} />
          <Route path={FortuneSavingPlanUrlPath.UPDATE} exact={true} component={FortuneSavingPlanUpdate} />
          <Route path={FortuneSavingPlanGroupUrlPath.BASE} exact={true} component={FortuneSavingPlanGroupOverview} />
          <Route path={FortuneSavingPlanGroupUrlPath.CREATE} exact={true} component={FortuneSavingPlanGroupCreate} />
          <Route path={FortuneSavingPlanGroupUrlPath.UPDATE} exact={true} component={FortuneSavingPlanGroupUpdate} />

          <Route path={FortuneSavingRateUrlPath.BASE} exact={true} component={FortuneSavingRateOverview} />
          <Route path={FortuneSavingRateUrlPath.CREATE} exact={true} component={FortuneSavingRateCreate} />
          <Route path={FortuneSavingRateUrlPath.CREATE_IN_WIZARD_FOR_ALL_SAVING_PLANS} exact={true} component={FortuneSavingRateCreateInWizardForAllSavingPlans} />
          <Route path={FortuneSavingRateUrlPath.UPDATE} exact={true} component={FortuneSavingRateUpdate} />

          <Route path={FortuneInvestmentPlanUrlPath.BASE} exact={true} component={FortuneInvestmentPlanOverview} />
          <Route path={FortuneInvestmentPlanUrlPath.CREATE} exact={true} component={FortuneInvestmentPlanCreate} />
          <Route path={FortuneInvestmentPlanUrlPath.UPDATE} exact={true} component={FortuneInvestmentPlanUpdate} /> 

          <Route path={FortuneInvestmentAimUrlPath.BASE} exact={true} component={FortuneInvestmentAimOverview} />
          <Route path={FortuneInvestmentAimUrlPath.CREATE} exact={true} component={FortuneInvestmentAimCreate} />
          <Route path={FortuneInvestmentAimUrlPath.UPDATE} exact={true} component={FortuneInvestmentAimUpdate} /> 

          <Route path={FortuneExpensePlannedUrlPath.BASE} exact={true} component={FortuneExpensePlannedOverview} />
          <Route path={FortuneExpensePlannedUrlPath.CREATE} exact={true} component={FortuneExpensePlannedCreate} />
          <Route path={FortuneExpensePlannedUrlPath.UPDATE} exact={true} component={FortuneExpensePlannedUpdate} /> 
          <Route path={FortuneExpensePlannedGroupUrlPath.BASE} exact={true} component={FortuneExpensePlannedGroupOverview} />
          <Route path={FortuneExpensePlannedGroupUrlPath.CREATE} exact={true} component={FortuneExpensePlannedGroupCreate} />
          <Route path={FortuneExpensePlannedGroupUrlPath.UPDATE} exact={true} component={FortuneExpensePlannedGroupUpdate} /> 

          <Route path={FuelConsumptionUrlPath.BASE} exact={true} component={FuelConsumptionOverview} />
          <Route path={FuelConsumptionUrlPath.CREATE} exact={true} component={FuelConsumptionCreate} />
          <Route path={FuelConsumptionUrlPath.UPDATE} exact={true} component={FuelConsumptionUpdate} />

          <Route path={FuelConsumptionDrivingStyleUrlPath.BASE} exact={true} component={FuelConsumptionDrivingStyleOverview} />
          <Route path={FuelConsumptionDrivingStyleUrlPath.CREATE} exact={true} component={FuelConsumptionDrivingStyleCreate} />
          <Route path={FuelConsumptionDrivingStyleUrlPath.UPDATE} exact={true} component={FuelConsumptionDrivingStyleUpdate} />

          <Route path={FuelConsumptionKindOfStreetUrlPath.BASE} exact={true} component={FuelConsumptionKindOfStreetOverview} />
          <Route path={FuelConsumptionKindOfStreetUrlPath.CREATE} exact={true} component={FuelConsumptionKindOfStreetCreate} />
          <Route path={FuelConsumptionKindOfStreetUrlPath.UPDATE} exact={true} component={FuelConsumptionKindOfStreetUpdate} />

          <Route path={FuelConsumptionTireUrlPath.BASE} exact={true} component={FuelConsumptionTireOverview} />
          <Route path={FuelConsumptionTireUrlPath.CREATE} exact={true} component={FuelConsumptionTireCreate} />
          <Route path={FuelConsumptionTireUrlPath.UPDATE} exact={true} component={FuelConsumptionTireUpdate} />

          <Route path={FuelConsumptionVehicleUrlPath.BASE} exact={true} component={FuelConsumptionVehicleOverview} />
          <Route path={FuelConsumptionVehicleUrlPath.CREATE} exact={true} component={FuelConsumptionVehicleCreate} />
          <Route path={FuelConsumptionVehicleUrlPath.UPDATE} exact={true} component={FuelConsumptionVehicleUpdate} />

          <Route path={FuelConsumptionVehicleFuelUnitUrlPath.BASE} exact={true} component={FuelConsumptionVehicleFuelUnitOverview} />
          <Route path={FuelConsumptionVehicleFuelUnitUrlPath.CREATE} exact={true} component={FuelConsumptionVehicleFuelUnitCreate} />
          <Route path={FuelConsumptionVehicleFuelUnitUrlPath.UPDATE} exact={true} component={FuelConsumptionVehicleFuelUnitUpdate} />

          <Route path={FuelConsumptionVehicleKindOfFuelUrlPath.BASE} exact={true} component={FuelConsumptionVehicleKindOfFuelOverview} />
          <Route path={FuelConsumptionVehicleKindOfFuelUrlPath.CREATE} exact={true} component={FuelConsumptionVehicleKindOfFuelCreate} />
          <Route path={FuelConsumptionVehicleKindOfFuelUrlPath.UPDATE} exact={true} component={FuelConsumptionVehicleKindOfFuelUpdate} />

          <Route path={FuelConsumptionVehicleTypeUrlPath.BASE} exact={true} component={FuelConsumptionVehicleTypeOverview} />
          <Route path={FuelConsumptionVehicleTypeUrlPath.CREATE} exact={true} component={FuelConsumptionVehicleTypeCreate} />
          <Route path={FuelConsumptionVehicleTypeUrlPath.UPDATE} exact={true} component={FuelConsumptionVehicleTypeUpdate} />

          <Route path={CyclingTourUrlPath.BASE} exact={true} component={CyclingTourOverview} />
          <Route path={CyclingTourUrlPath.CREATE} exact={true} component={CyclingTourCreate} />
          <Route path={CyclingTourUrlPath.UPDATE} exact={true} component={CyclingTourUpdate} />
          
          <Route path={CyclingTourBikeUrlPath.BASE} exact={true} component={CyclingTourBikeOverview} />
          <Route path={CyclingTourBikeUrlPath.CREATE} exact={true} component={CyclingTourBikeCreate} />
          <Route path={CyclingTourBikeUrlPath.UPDATE} exact={true} component={CyclingTourBikeUpdate} />

          <Route path={CyclingTourBikeTypeUrlPath.BASE} exact={true} component={CyclingTourBikeTypeOverview} />
          <Route path={CyclingTourBikeTypeUrlPath.CREATE} exact={true} component={CyclingTourBikeTypeCreate} />
          <Route path={CyclingTourBikeTypeUrlPath.UPDATE} exact={true} component={CyclingTourBikeTypeUpdate} />

          <Route path={LetterUrlPath.BASE} exact={true} component={LetterOverview} />
          <Route path={LetterUrlPath.CREATE} exact={true} component={LetterCreate} />
          <Route path={LetterUrlPath.UPDATE} exact={true} component={LetterUpdate} />
          <Route path={LetterUrlPath.DETAILS} exact={true} component={LetterDetails} />

          <Route path={LetterPersonSalutationUrlPath.BASE} exact={true} component={LetterPersonSalutationOverview} />
          <Route path={LetterPersonSalutationUrlPath.CREATE} exact={true} component={LetterPersonSalutationCreate} />
          <Route path={LetterPersonSalutationUrlPath.UPDATE} exact={true} component={LetterPersonSalutationUpdate} />

          <Route path={LetterPersonTitleUrlPath.BASE} exact={true} component={LetterPersonTitleOverview} />
          <Route path={LetterPersonTitleUrlPath.CREATE} exact={true} component={LetterPersonTitleCreate} />
          <Route path={LetterPersonTitleUrlPath.UPDATE} exact={true} component={LetterPersonTitleUpdate} />

          <Route path={LetterTemplateUrlPath.BASE} exact={true} component={LetterTemplateOverview} />
          <Route path={LetterTemplateUrlPath.CREATE} exact={true} component={LetterTemplateCreate} />
          <Route path={LetterTemplateUrlPath.UPDATE} exact={true} component={LetterTemplateUpdate} />

          <Route path={LetterSenderPersonUrlPath.BASE} exact={true} component={LetterSenderPersonOverview} />
          <Route path={LetterSenderPersonUrlPath.CREATE} exact={true} component={LetterSenderPersonCreate} />
          <Route path={LetterSenderPersonUrlPath.UPDATE} exact={true} component={LetterSenderPersonUpdate} />
          <Route path={LetterSenderCompanyUrlPath.BASE} exact={true} component={LetterSenderCompanyOverview} />
          <Route path={LetterSenderCompanyUrlPath.CREATE} exact={true} component={LetterSenderCompanyCreate} />
          <Route path={LetterSenderCompanyUrlPath.UPDATE} exact={true} component={LetterSenderCompanyUpdate} />
          
          <Route path={LetterAddresseePersonUrlPath.BASE} exact={true} component={LetterAddresseePersonOverview} />
          <Route path={LetterAddresseePersonUrlPath.CREATE} exact={true} component={LetterAddresseePersonCreate} />
          <Route path={LetterAddresseePersonUrlPath.UPDATE} exact={true} component={LetterAddresseePersonUpdate} />
          <Route path={LetterAddresseeCompanyUrlPath.BASE} exact={true} component={LetterAddresseeCompanyOverview} />
          <Route path={LetterAddresseeCompanyUrlPath.CREATE} exact={true} component={LetterAddresseeCompanyCreate} />
          <Route path={LetterAddresseeCompanyUrlPath.UPDATE} exact={true} component={LetterAddresseeCompanyUpdate} />

          <Route path={LetterSignatureUrlPath.BASE} exact={true} component={LetterSignatureOverview} />
          <Route path={LetterSignatureUrlPath.CREATE} exact={true} component={LetterSignatureCreate} />
          <Route path={LetterSignatureUrlPath.UPDATE} exact={true} component={LetterSignatureUpdate} />

          <Route path={PasswordListUrlPath.BASE} exact={true} component={PasswordListOverview} />
          <Route path={PasswordListUrlPath.CREATE} exact={true} component={PasswordListCreate} />
          <Route path={PasswordListUrlPath.UPDATE} exact={true} component={PasswordListUpdate} />
          <Route path={PasswordListFolderUrlPath.BASE} exact={true} component={PasswordListFolderOverview} />
          <Route path={PasswordListFolderUrlPath.CREATE} exact={true} component={PasswordListFolderCreate} />
          <Route path={PasswordListFolderUrlPath.UPDATE} exact={true} component={PasswordListFolderUpdate} />

          <Route path={InsuranceListUrlPath.BASE} exact={true} component={InsuranceListOverview} />
          <Route path={InsuranceListUrlPath.CREATE} exact={true} component={InsuranceListCreate} />
          <Route path={InsuranceListUrlPath.UPDATE} exact={true} component={InsuranceListUpdate} />
          <Route path={InsuranceListGroupUrlPath.BASE} exact={true} component={InsuranceListGroupOverview} />
          <Route path={InsuranceListGroupUrlPath.CREATE} exact={true} component={InsuranceListGroupCreate} />
          <Route path={InsuranceListGroupUrlPath.UPDATE} exact={true} component={InsuranceListGroupUpdate} />
          <Route path={InsuranceListPaymentIntervalUrlPath.BASE} exact={true} component={InsuranceListPaymentIntervalOverview} />
          <Route path={InsuranceListPaymentIntervalUrlPath.CREATE} exact={true} component={InsuranceListPaymentIntervalCreate} />
          <Route path={InsuranceListPaymentIntervalUrlPath.UPDATE} exact={true} component={InsuranceListPaymentIntervalUpdate} />

          <Route path="/">
            { AuthenticationApi.isLoggedIn() ?
                <Redirect to={DashboardUrlPath.BASE} /> :
                <Redirect to={LoginUrlPath.BASE} /> }
          </Route>          
        </Switch>
      </Router>
     );
  }
}
 
export default App;