/**
* Get all environment variables by config-files (e. g. .env.development)
* or by command line parameters while building the app.
*/
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : "default";
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
const IS_API_TOKEN_AUTH_USED = process.env.REACT_APP_IS_API_TOKEN_AUTH_USED ? process.env.REACT_APP_IS_API_TOKEN_AUTH_USED : false;
const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME ? process.env.REACT_APP_TOKEN_NAME : "spiesolorganize-token";

// -------------------------------------------------------------------------------------------------

class EnvironmentVariables {
   private constructor() {
       throw new Error('Constructor can\'t be called, because it\'s a singleton!');
   }

   public static getEnvironment() {
       return ENVIRONMENT;
   }

   public static getBackendApiUrl() {
       return BACKEND_API_URL;
   }

   public static isApiTokenAuthUsed() {
       return IS_API_TOKEN_AUTH_USED;
   }

   public static getTokenName() {
       return TOKEN_NAME;
   }
}

export default EnvironmentVariables;