import React from 'react';

import FormGroupVehicle from '../fragment/FormGroupVehicle';
import FormGroupOdometerMileage from '../fragment/FormGroupOdometerMileage';
import FormGroupDrivenKm from '../fragment/FormGroupDrivenKm';
import FormGroupMileage from '../fragment/FormGroupMileage';
import FormGroupExpense from '../fragment/FormGroupExpense';
import FormGroupPricePerUnit from '../fragment/FormGroupPricePerUnit';
import FormGroupDate from '../fragment/FormGroupDate';
import FormGroupAirConditioningUsed from '../fragment/FormGroupAirConditioningUsed';
import FormGroupTire from '../fragment/FormGroupTire';
import FormGroupKindOfStreet from '../fragment/FormGroupKindOfStreet';
import FormGroupDrivingStyle from '../fragment/FormGroupDrivingStyle';
import FormGroupNote from '../fragment/FormGroupNote';

class Form {
    private formGroupVehicle: FormGroupVehicle;
    private formGroupOdometerMileage: FormGroupOdometerMileage;
    private formGroupDrivenKm: FormGroupDrivenKm;
    private formGroupMileage: FormGroupMileage;
    private formGroupExpense: FormGroupExpense;
    private formGroupPricePerUnit: FormGroupPricePerUnit;
    private formGroupDate: FormGroupDate;
    private formGroupAirConditioningUsed: FormGroupAirConditioningUsed;
    private formGroupTire: FormGroupTire;
    private formGroupKindOfStreet: FormGroupKindOfStreet;
    private formGroupDrivingStyle: FormGroupDrivingStyle;
    private formGroupNote: FormGroupNote;

    public constructor(props, pointerToComponent) {
        this.formGroupVehicle = new FormGroupVehicle(props, pointerToComponent);
        this.formGroupOdometerMileage = new FormGroupOdometerMileage(props, pointerToComponent);
        this.formGroupDrivenKm = new FormGroupDrivenKm(props, pointerToComponent);
        this.formGroupMileage = new FormGroupMileage(props, pointerToComponent);
        this.formGroupExpense = new FormGroupExpense(props, pointerToComponent);
        this.formGroupPricePerUnit = new FormGroupPricePerUnit(props, pointerToComponent);
        this.formGroupDate = new FormGroupDate(props, pointerToComponent);
        this.formGroupAirConditioningUsed = new FormGroupAirConditioningUsed(props, pointerToComponent);
        this.formGroupTire = new FormGroupTire(props, pointerToComponent);
        this.formGroupKindOfStreet = new FormGroupKindOfStreet(props, pointerToComponent);
        this.formGroupDrivingStyle = new FormGroupDrivingStyle(props, pointerToComponent);
        this.formGroupNote = new FormGroupNote(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupVehicle.render() }
                { this.formGroupOdometerMileage.render() }
                { this.formGroupDrivenKm.render() }
                { this.formGroupMileage.render() }
                { this.formGroupExpense.render() }
                { this.formGroupPricePerUnit.render() }
                { this.formGroupDate.render() }
                { this.formGroupAirConditioningUsed.render() }
                { this.formGroupTire.render() }
                { this.formGroupKindOfStreet.render() }
                { this.formGroupDrivingStyle.render() }
                { this.formGroupNote.render() }

               { renderButtonCallback() } 
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupVehicle.isInvalidValue()
             || this.formGroupOdometerMileage.isInvalidValue()
             || this.formGroupDrivenKm.isInvalidValue()
             || this.formGroupMileage.isInvalidValue()
             || this.formGroupExpense.isInvalidValue()
             || this.formGroupPricePerUnit.isInvalidValue()
             || this.formGroupDate.isInvalidValue()
             || this.formGroupAirConditioningUsed.isInvalidValue()
             || this.formGroupTire.isInvalidValue()
             || this.formGroupKindOfStreet.isInvalidValue()
             || this.formGroupDrivingStyle.isInvalidValue()
             || this.formGroupNote.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;