import React from 'react';
import { Trans } from 'react-i18next';
import OverviewBase from "../../../../commons/ui/page/template/overview/OverviewBase";
import InsuranceListGroup from "../../../group/model/InsuranceListGroup";
import InsuranceListPaymentInterval from "../../../paymentInterval/model/InsuranceListPaymentInterval";
import InsuranceListApi from "../../api/InsuranceListApi";
import InsuranceList from "../../model/InsuranceList";
import InsuranceListGroupActionBarButton from '../../../group/ui/fragment/ActionBarButton';
import InsuranceListPaymentIntervalActionBarButton from '../../../paymentInterval/ui/fragment/ActionBarButton';
import InsuranceListUrlPath from "../InsuranceListUrlPath";
import I18nConstants from '../../i18n/I18nConstants';

class Overview extends OverviewBase<InsuranceList, InsuranceListApi> {
    private groupActionBarButton: InsuranceListGroupActionBarButton;
    private paymentIntervalActionBarButton: InsuranceListPaymentIntervalActionBarButton;

    public constructor(props) {
        super(
            props,
            InsuranceListApi.getInstance()
        );

        this.setCreateUrl(InsuranceListUrlPath.CREATE);
        this.setGroupsOverviewUrl(InsuranceListUrlPath.BASE);

        this.groupActionBarButton = new InsuranceListGroupActionBarButton(props.history);
        this.paymentIntervalActionBarButton = new InsuranceListPaymentIntervalActionBarButton(props.history);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE}>
                    {I18nConstants.TITLE_DEFAULT}
                </Trans>
            </h1>
        );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_GROUP}>
                        {I18nConstants.FORM_GROUP_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_COMPANY}>
                        {I18nConstants.FORM_COMPANY_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_NAME}>
                        {I18nConstants.FORM_NAME_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_NUMBER}>
                        {I18nConstants.FORM_NUMBER_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DATEOFBEGIN}>
                        {I18nConstants.FORM_DATEOFBEGIN_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DUEDATEYEARLY}>
                        {I18nConstants.FORM_DUEDATEYEARLY_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AMOUNTYEARLY}>
                        {I18nConstants.FORM_AMOUNTYEARLY_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_PAYMENTINTERVAL}>
                        {I18nConstants.FORM_PAYMENTINTERVAL_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).group.name}
                </td>

                <td>
                    {(item as any).company}
                </td>

                <td>
                    {(item as any).name}
                </td>

                <td>
                    {(item as any).number}
                </td>

                <td>
                    {(item as any).dateOfBegin}
                </td>

                <td>
                    {(item as any).dueDateYearly}
                </td>

                <td>
                    {(item as any).amountYearly}
                </td>

                <td>
                    {(item as any).paymentInterval.name}
                </td>

                <td>
                    {(item as any).description}
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr>
        );
    }

    protected getActionBar() {
        return (
            <div>
                { this.groupActionBarButton.render() }
                { this.paymentIntervalActionBarButton.render() }
            </div>
        );
    }
}

export default Overview;