import IModelMapper from '../../../../commons/model/IModelMapper';
import LetterPersonSalutation from './LetterPersonSalutation';

class LetterPersonSalutationModelMapper implements IModelMapper<LetterPersonSalutation> {
    fromJsonToObject(json: any): LetterPersonSalutation {
        let letterPersonSalutation = new LetterPersonSalutation();

        letterPersonSalutation.id = json.id;
        letterPersonSalutation.name = json.name;

        return letterPersonSalutation;
    }

    fromObjectToJson(object: LetterPersonSalutation) {
        return {
            id: object.id,
            name: (object.name || "")
        };
    }
}

export default LetterPersonSalutationModelMapper;