import ActionBarButtonBase from '../../../../commons/ui/page/actionBar/ActionBarButtonBase';

import I18nConstants from '../../i18n/I18nConstants';

import EarningsExpensesUrlPath from '../EarningsExpensesUrlPath';

class ActionBarButtonGroupedByYear extends ActionBarButtonBase {
    public constructor(propsHistory) {
        super(propsHistory,
              EarningsExpensesUrlPath.GROUPED_BY_YEAR,
              I18nConstants.NS,
              I18nConstants.ACTION_BAR_GROUPED_BY_YEAR,
              I18nConstants.ACTION_BAR_GROUPED_BY_YEAR_DEFAULT);
    }
}

export default ActionBarButtonGroupedByYear;