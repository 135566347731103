import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import PasswordListModelMapper from '../model/PasswordListModelMapper';

class PasswordListApi implements IGenericApi {
    private static instance: PasswordListApi;

    private static apiUrlPath = "passwordList";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): PasswordListApi {
        if (!PasswordListApi.instance) {
            PasswordListApi.instance = new PasswordListApi();
        }

        return PasswordListApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    PasswordListApi.apiUrlPath,
                    new PasswordListModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    PasswordListApi.apiUrlPath,
                    id,
                    new PasswordListModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    PasswordListApi.apiUrlPath,
                    body,
                    new PasswordListModelMapper());
    }

    public update(id ,body) {
        return GenericApi.executePutCall(
                    PasswordListApi.apiUrlPath,
                    id,
                    body,
                    new PasswordListModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    PasswordListApi.apiUrlPath,
                    id,
                    new PasswordListModelMapper());
    }

    public getUrl() {
        return PasswordListApi.apiUrlPath;
    }
}

export default PasswordListApi;