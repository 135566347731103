import BankingList from '../../model/BankingList';
import BankingListUrlPath from '../../ui/BankingListUrlPath';
import BankingListGroup from '../../../group/model/BankingListGroup';
import BankingListApi from '../../api/BankingListApi';
import BankingListGroupApi from '../../../group/api/BankingListGroupApi';
import UpdateBaseWithGroup from '../../../../commons/ui/page/template/update/UpdateBaseWithGroup';
import Utils from '../../../../utilities/Utils';
import Form from './Form';
import FormButtonUpdate from '../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBaseWithGroup<BankingList, BankingListGroup, 
                                         BankingListApi, BankingListGroupApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new BankingList(),
              BankingListApi.getInstance(),
              BankingListGroupApi.getInstance(),
              idOfItemToBeUpdated,
              BankingListUrlPath.BASE
        );

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;