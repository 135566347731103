import IModelMapper from "../../../../commons/model/IModelMapper";
import FortuneSavingRateSumGroupedByRiskClassName from './FortuneSavingRateSumGroupedByRiskClassName';

class FortuneSavingRateSumGroupedByRiskClassNameModelMapper
        implements IModelMapper<FortuneSavingRateSumGroupedByRiskClassName> {
    fromJsonToObject(json: any): FortuneSavingRateSumGroupedByRiskClassName {
        let fortuneSavingRateSumGroupedByRiskClassName = 
            new FortuneSavingRateSumGroupedByRiskClassName();

        fortuneSavingRateSumGroupedByRiskClassName.name = json.name;
        fortuneSavingRateSumGroupedByRiskClassName.sum  = json.sum;

        return fortuneSavingRateSumGroupedByRiskClassName;
    }

    fromObjectToJson(object: FortuneSavingRateSumGroupedByRiskClassName) {
        return {
            name: (object.name || ""),
            sum:  (object.sum || 0)
        };
    }
}

export default FortuneSavingRateSumGroupedByRiskClassNameModelMapper;