import OverviewEventHandlerBase from './OverviewEventHandlerBase';
import Utils from '../../../../../../utilities/Utils';

class OverviewEventHandlerShowDetails extends OverviewEventHandlerBase {
    public onAction(pointerToComponentOrUtil, id) {
        let showDetailsUrl = "";

        if (pointerToComponentOrUtil.showDetailsUrl) {
            showDetailsUrl = pointerToComponentOrUtil.showDetailsUrl;
        } else {
            showDetailsUrl = Utils.getShowDetailsUrl(
                pointerToComponentOrUtil.props.location.pathname);
        }

        showDetailsUrl += "?id=" + id.toString();

        if (pointerToComponentOrUtil.props === undefined) {
            // For OverviewUtils
            pointerToComponentOrUtil.propsHistory.push(showDetailsUrl);
        } else {
            // For OverviewBase
            pointerToComponentOrUtil.props.history.push(showDetailsUrl);
        }
    }
}

export default OverviewEventHandlerShowDetails;