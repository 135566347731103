import ApiCallExecutorBase from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';
import FortuneSavingPlanGroupApi from './FortuneSavingPlanGroupApi';

class FortuneSavingPlanGroupApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private fortuneSavingPlanGroupApi: FortuneSavingPlanGroupApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.fortuneSavingPlanGroupApi = FortuneSavingPlanGroupApi.getInstance();
    }

    public getAll(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingPlanGroupApi.getAll()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getById(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const id = (context.pointerToHelper.getParameterInArrayWithObjects(
                        context.parameters, "fortuneSavingPlanGroupId") as number);

        context.currentData.pointerToApiCall.fortuneSavingPlanGroupApi.getById(id)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }
}

export default FortuneSavingPlanGroupApiCallExecutor;