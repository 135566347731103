import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import ShoppingListGroupApiCallExecutor from '../../../group/api/ShoppingListGroupApiCallExecutor';
import ShoppingListKindOfQuantityApiCallExecutor from '../../../kindOfQuantity/api/ShoppingListKindOfQuantityApiCallExecutor';

import ShoppingList from '../../model/ShoppingList';
import ShoppingListApi from '../../api/ShoppingListApi';
import ShoppingListUrlPath from '../ShoppingListUrlPath';

import ShoppingListGroup from '../../../group/model/ShoppingListGroup';
import ShoppingListKindOfQuantity from '../../../kindOfQuantity/model/ShoppingListKindOfQuantity';

import CreateUtils from '../../../../commons/ui/page/template/create/CreateUtils';

import Form from './Form';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: ShoppingList;
    Groups: ShoppingListGroup[];
    KindOfQuantities: ShoppingListKindOfQuantity[];
}

class Create extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: ShoppingListApi;
    private form: Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = ShoppingListApi.getInstance();

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: new ShoppingList(),
            Groups: [],
            KindOfQuantities: []
        };

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            ShoppingListUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private shoppingListGroupApiCallExecutor: ShoppingListGroupApiCallExecutor<Create>;
    private shoppingListKindOfQuantityApiCallExecutor: 
                ShoppingListKindOfQuantityApiCallExecutor<Create>; 

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.shoppingListGroupApiCallExecutor = 
            new ShoppingListGroupApiCallExecutor(pointerToComponent);
        this.shoppingListKindOfQuantityApiCallExecutor =
            new ShoppingListKindOfQuantityApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addShoppingListGroupGetAll());
        orderOfCalls.push(this.addShoppingListKindOfQuantityGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);
        
        this.helper.startWithFirstCall(context);  
    }

    // --------------------------------------------------------------------------------------------

    private addShoppingListGroupGetAll() : IApiCallback {
        return {
            callback: this.shoppingListGroupApiCallExecutor.getAll,
            pointerToApiCall: this.shoppingListGroupApiCallExecutor,
            variableNameInState: "Groups"
        };
    }

    private addShoppingListKindOfQuantityGetAll() : IApiCallback {
        return {
            callback: this.shoppingListKindOfQuantityApiCallExecutor.getAll,
            pointerToApiCall: this.shoppingListKindOfQuantityApiCallExecutor,
            variableNameInState: "KindOfQuantities"
        };
    }
}

export default Create;