import Letter from './Letter';
import LetterModelMapper from './LetterModelMapper';

class LetterUndefinedObjectDeletionMapper
extends LetterModelMapper {
    fromObjectToJson(object: Letter) {
        let jsonObject = super.fromObjectToJson(object);

        // Check for empty sender - person and set it to 'null'.
        if (     (jsonObject.senderPerson.id === undefined)
             || ((jsonObject.senderPerson.id as any) === "-1")
             || ((jsonObject.senderPerson.id as any) === "none")) {
            jsonObject.senderPerson = (null as any);
        }

        // Check for empty sender - company and set it to 'null'.
        if (     (jsonObject.senderCompany.id === undefined)
             || ((jsonObject.senderCompany.id as any) === "-1")
             || ((jsonObject.senderCompany.id as any) === "none")) {
            jsonObject.senderCompany = (null as any);
        }

        // Check for empty addressee - person and set it to 'null'.
        if (     (jsonObject.addresseePerson.id === undefined)
             || ((jsonObject.addresseePerson.id as any) === "-1")
             || ((jsonObject.addresseePerson.id as any) === "none")) {
            jsonObject.addresseePerson = (null as any);
        }

        // Check for empty addressee - company and set it to 'null'.
        if (     (jsonObject.addresseeCompany.id === undefined)
             || ((jsonObject.addresseeCompany.id as any) === "-1")
             || ((jsonObject.addresseeCompany.id as any) === "none")) {
            jsonObject.addresseeCompany = (null as any);
        }

        return jsonObject;
    }
}

export default LetterUndefinedObjectDeletionMapper;