import AppVariables from '../commons/config/AppVariables';
import i18n from '../commons/i18n/i18n';
import I18nConstants from '../commons/i18n/I18nConstantsBase';

class Utils {
    private constructor() {
        throw new Error('Constructor can\'t be called, because it\'s a singleton!');
    }

    public static roundTwoDecimalPlaces(value: number): number {
        return Math.round(value * 100) / 100;
    }

    public static createDateByDayMonthYear(day: number, month: number, year: number) {
        return new Date(year, (month - 1), day, 0, 0, 0, 0);
    }

    public static createDateByIsoString(dateInIsoString: string) {
        let splittedDateInIsoFormat = dateInIsoString.split("-");

        let year  = parseInt(splittedDateInIsoFormat[0]);
        let month = parseInt(splittedDateInIsoFormat[1]);
        let day   = parseInt(splittedDateInIsoFormat[2]);

        return new Date(year, (month - 1), day, 0, 0, 0, 0);
    }

    public static convertDateToIsoString(date: Date): string {
        let splittedInDateAndTime = date.toISOString().split("T");

        return splittedInDateAndTime[0];
    }

    public static calculateDaysBetweenTwoDates(earlierDate: Date, laterDate: Date) {
        return Math.round(((laterDate as any) - (earlierDate as any)) / 
                          (1000 * 60 * 60 * 24));
    }

    public static calculateMonthsBetweenTwoDates(earlierDate: Date, laterDate: Date) {
        let months = 0;

        months  = (laterDate.getFullYear() - earlierDate.getFullYear()) * 12;
        months -= earlierDate.getMonth();
        months += laterDate.getMonth();

        months = ((months <= 0) ? 0 : months);

        return months;
    }

    public static getCurrentMonthNameI18n(): string {
        let result = "";

        let today = new Date();
        let month = today.getMonth() + 1;


        switch (month) {
            case 1:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_1);
                break;
            case 2:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_2);
                break;
            case 3:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_3);
                break;
            case 4:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_4);
                break;
            case 5:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_5);
                break;
            case 6:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_6);
                break;
            case 7:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_7);
                break;
            case 8:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_8);
                break;
            case 9:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_9);
                break;
            case 10:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_10);
                break;
            case 11:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_11);
                break;
            case 12:
                result = i18n.t(I18nConstants.NS_BASE + ":" + I18nConstants.GENERAL_MONTHS_12);
                break;
            default:
                result = "";
                break;
        }

        return result;
    }

    public static getCurrentYear(): number {
        return new Date().getFullYear();
    }

    public static getCreateUrl(listUrl: string): string {
        let result = Utils.getListUrlWithSlashInTheEnd(listUrl);

        result += AppVariables.getCreateUrl();

        return result;
    }

    public static getUpdateUrl(listUrl: string): string {
        let result = Utils.getListUrlWithSlashInTheEnd(listUrl);

        result += AppVariables.getUpdateUrl();

        return result;
    }

    public static getShowDetailsUrl(listUrl: string): string {
        let result = Utils.getListUrlWithSlashInTheEnd(listUrl);

        result += AppVariables.getShowDetailsUrl();

        return result;
    }

    public static getIdFromGetParameters(getParameters: string): number {
        let result: number;

        let id = Utils.getValueFromGetParameters(getParameters, "id");

        if (id) {
            result = parseInt(id);
        } else {
            result = 0;
        }

        return result;
    }

    public static getOverheadIdFromGetParameters(getParameters: string): number {
        let result: number;

        let overheadId = Utils.getValueFromGetParameters(getParameters, "overheadId");

        if (overheadId) {
            result = parseInt(overheadId);
        } else {
            result = 0;
        }

        return result;
    }

    public static getMonthAndYearFromGetParameters(getParameters: string) {
        let result: object;

        let month = Utils.getValueFromGetParameters(getParameters, "month");
        let year  = Utils.getValueFromGetParameters(getParameters, "year");

        if (    (month)
             && (year)) {
            if (month.charAt(0) === "0") {
                month = month.charAt(1);
            }

            result = {
                month: month,
                year:  year
            };
        } else {
            result = { };
        }

        return result;
    }

    public static getValueFromGetParameters(getParameters: string, parameterName: string): string {
        let result:       string = "";
        let keyValuePair: string = "";
        
        getParameters = getParameters.replace("?", "");

        let getParametersArray = getParameters.split("&");

        for (let i = 0; i < getParametersArray.length; ++i) {
            keyValuePair = getParametersArray[i];

            if (keyValuePair.includes(parameterName)) {
                keyValuePair = keyValuePair.replace(parameterName, "");
                keyValuePair = keyValuePair.replace("=", "");

                result = keyValuePair;

                break;
            } else {
                result = "";
            }
        }

        return result;
    }

    public static getMonthAndYearFromStringSeparatedBySlash(monthAndYearString): object {
        if (monthAndYearString) {
            let month = -1;
            let year  = -1;

            let monthAndYearSplitted = monthAndYearString.split("/");

            month = monthAndYearSplitted[0];
            year  = monthAndYearSplitted[1];

            if (    (month != -1)
                 && (year  != -1)) {
                return {
                    "month": month,
                    "year":  year
                };
            } else {
                return {};
            }
        } else {
            return {};
        }
    }

    public static convertMonthAndYearObjectToStringSeparatedBySlash(monthAndYearObject): string {
        return monthAndYearObject.month + "/" + monthAndYearObject.year;
    }

    public static checkForNaNAndInfinityAndReturnPlaceholder(value): string {
        if (    (isNaN(value))
             || (!isFinite(value))) {
            return "-";
        } else {
            return value;
        }
    }

    public static convertTextWithLineBreaksToArray(textWithLinebreaks: string): Array<string> {
        return textWithLinebreaks.split("\n");
    }

    // --------------------------------------------------------------------------------------------

    private static getListUrlWithSlashInTheEnd(url: string): string {
        let result = "";

        let lastCharacterOfUrl = url.toString().slice(-1);

        if (lastCharacterOfUrl === "/") {
            result = url;
        } else {
            result = url + "/";
        }

        return result;
    }
}

export default Utils;