import React from 'react';
import { Component } from 'react';
import { FormGroup, Form, Button, Input, Label } from 'reactstrap';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import AuthenticationApi from '../../../security/api/AuthenticationApi';
import User from '../../../security/model/User';
import DashboardUrlPath from '../../../dashboard/ui/DashboardUrlPath';
import FormLogin from './Form';

interface IProps {
    history: any;
}

interface IState {
    wasHandleSubmitClickedAtLeastOnce: boolean;
    user: User;
}

class Login extends Component<IProps, IState> {
    private form: FormLogin;

    public constructor(props) {
        super(props);

        this.state = {
            wasHandleSubmitClickedAtLeastOnce: false,
            user: new User()
        };

        this.form = new FormLogin(props, this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() { 
        return ( 
            <div>
                <h1>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.TITLE}>
                        {I18nConstants.TITLE_DEFAULT}
                    </Trans>
                </h1>
                
                <div>
                    <Form onSubmit={this.handleSubmit}>
                        { this.form.getFormGroupContent() }
                    </Form>
                </div>
            </div>
        );
    }

    
    private handleChange(event) {
        const target = event.target;

        let user = {...this.state.user};

        user[target.name] = target.value;

        this.setState({ user });
    }

    private handleSubmit(event) {
        event.preventDefault();

        const { user } = this.state;

        var that = this;

        // This event was fired at least, once.
        this.setState({ wasHandleSubmitClickedAtLeastOnce: true },
        // This function is used to make sure that 'wasHandleSubmitClickedAtLeastOnce' is updated, correctly. 
        function () {
            // Check, if all input-fields are valid before sending the request.
            if (    (that.form.isFormValid())
                && (that.state.wasHandleSubmitClickedAtLeastOnce)) {
                that.executeApiCallLogin(user.username, user.password);
            } else {
                console.log("TODO - Form is not valid");
            }
        });        
    }

    // --------------------------------------------------------------------------------------------
    // --- API-calls ---

    private executeApiCallLogin(username, password) {
        AuthenticationApi.login(username, password)
            .then( response => {
                        if (response === true) {
                            this.props.history.push(DashboardUrlPath.BASE);
                        }
            });
    }
}
 
export default Login;