import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';

import FortuneRiskClassModelMapper from '../model/FortuneRiskClassModelMapper';

class FortuneRiskClassApi implements IGenericApi {
    private static instance: FortuneRiskClassApi;

    private static apiUrlPath = "fortune/riskClass";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): FortuneRiskClassApi {
        if (!FortuneRiskClassApi.instance) {
            FortuneRiskClassApi.instance = new FortuneRiskClassApi();
        }

        return FortuneRiskClassApi.instance;
    }
    
    public getAll() {
        return GenericApi.executeGetAllCall(
                    FortuneRiskClassApi.apiUrlPath,
                    new FortuneRiskClassModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    FortuneRiskClassApi.apiUrlPath,
                    id,
                    new FortuneRiskClassModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    FortuneRiskClassApi.apiUrlPath,
                    body,
                    new FortuneRiskClassModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    FortuneRiskClassApi.apiUrlPath,
                    id,
                    body,
                    new FortuneRiskClassModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    FortuneRiskClassApi.apiUrlPath,
                    id,
                    new FortuneRiskClassModelMapper());
    }

    public getUrl() {
        return FortuneRiskClassApi.apiUrlPath;
    }
}

export default FortuneRiskClassApi;