import IModelMapper from '../../../../commons/model/IModelMapper';
import EarningGroup from './EarningGroup';

class EarningGroupModelMapper implements IModelMapper<EarningGroup> {
    fromJsonToObject(json: any): EarningGroup {
        let earningGroup = new EarningGroup();

        earningGroup.id = json.id;
        earningGroup.name = json.name;

        return earningGroup;
    }

    fromObjectToJson(object: EarningGroup) {
        return {
            id: object.id,
            name: (object.name || "") 
        };
    }
}

export default EarningGroupModelMapper;