import IModelMapper from '../../../commons/model/IModelMapper';

import DrivingStyle from './DrivingStyle';

class DrivingStyleModelMapper implements IModelMapper<DrivingStyle> {
    fromJsonToObject(json: any): DrivingStyle {
        let drivingStyle = new DrivingStyle();

        drivingStyle.id   = json.id;
        drivingStyle.name = json.name;

        return drivingStyle;
    }

    fromObjectToJson(object: DrivingStyle) {
        return {
            id: object.id,
            name: (object.name || "") };
    }
}

export default DrivingStyleModelMapper;