import React from 'react';
import { Button } from 'reactstrap';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import FortuneSavingRatioUrlPath from '../FortuneSavingRatioUrlPath';

class ActionBarButtonForCreate {
    private propsHistory: any;

    private month: string;
    private year: string;

    private url: string;

    public constructor(propsHistory) {
        this.propsHistory = propsHistory;

        this.month = "0";
        this.year = "0";

        this.url = "";
    }

    public render(monthAndYear) {
        this.setSplittedMonthAndYearToMembers(monthAndYear);
        this.setAndCreateUrl();

        return (
            <Button onClick={ () => this.onClick() }>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.CREATE}>
                    {I18nConstants.CREATE_DEFAULT}
                </Trans>
            </Button>
        );
    }

    private setSplittedMonthAndYearToMembers(monthAndYear) {
        let monthAndYearSplitted = monthAndYear.split("/");

        this.month = monthAndYearSplitted[0];
        this.year = monthAndYearSplitted[1];
    }

    private setAndCreateUrl() {
        this.url = FortuneSavingRatioUrlPath.CREATE +
                        "?" + "month" + "=" + this.month.toString() + 
                        "&" + "year"  + "=" + this.year.toString();
    }

    private onClick() {
        this.propsHistory.push(this.url);
    }
}

export default ActionBarButtonForCreate;