import React from 'react';

import FormGroupSalutationDropdown from '../../../../addressGeneral/ui/fragment/FormGroupSalutationDropdown';
import FormGroupTitleDropdown from '../../../../addressGeneral/ui/fragment/FormGroupTitleDropdown';
import FormGroupFirstName from '../../../../addressGeneral/ui/fragment/FormGroupFirstName';
import FormGroupLastName from '../../../../addressGeneral/ui/fragment/FormGroupLastName';
import FormGroupStreet from '../../../../addressGeneral/ui/fragment/FormGroupStreet';
import FormGroupHouseNumber from '../../../../addressGeneral/ui/fragment/FormGroupHouseNumber';
import FormGroupZip from '../../../../addressGeneral/ui/fragment/FormGroupZip';
import FormGroupCity from '../../../../addressGeneral/ui/fragment/FormGroupCity';
import FormGroupPostOfficeBox from '../../../../addressGeneral/ui/fragment/FormGroupPostOfficeBox';
import FormGroupCountryDropdown from '../../../../addressGeneral/ui/fragment/FormGroupCountryDropdown';

class Form {
    private formGroupSalutationDropdown: FormGroupSalutationDropdown;
    private formGroupTitleDropdown: FormGroupTitleDropdown;
    private formGroupFirstName: FormGroupFirstName;
    private formGroupLastName: FormGroupLastName;
    private formGroupStreet: FormGroupStreet;
    private formGroupHouseNumber: FormGroupHouseNumber;
    private formGroupZip: FormGroupZip;
    private formGroupCity: FormGroupCity;
    private formGroupPostOfficeBox: FormGroupPostOfficeBox;
    private formGroupCountryDropdown: FormGroupCountryDropdown;

    public constructor(props, pointerToComponent) {
        this.formGroupSalutationDropdown = new FormGroupSalutationDropdown(props, pointerToComponent);
        this.formGroupTitleDropdown = new FormGroupTitleDropdown(props, pointerToComponent);
        this.formGroupFirstName = new FormGroupFirstName(props, pointerToComponent);
        this.formGroupLastName = new FormGroupLastName(props, pointerToComponent);
        this.formGroupStreet = new FormGroupStreet(props, pointerToComponent);
        this.formGroupHouseNumber = new FormGroupHouseNumber(props, pointerToComponent);
        this.formGroupZip = new FormGroupZip(props, pointerToComponent);
        this.formGroupCity = new FormGroupCity(props, pointerToComponent);
        this.formGroupPostOfficeBox = new FormGroupPostOfficeBox(props, pointerToComponent);
        this.formGroupCountryDropdown = new FormGroupCountryDropdown(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupSalutationDropdown.render() }
                { this.formGroupTitleDropdown.render() }
                { this.formGroupFirstName.render() }
                { this.formGroupLastName.render() }
                { this.formGroupStreet.render() }
                { this.formGroupHouseNumber.render() }
                { this.formGroupZip.render() }
                { this.formGroupCity.render() }
                { this.formGroupPostOfficeBox.render() }
                { this.formGroupCountryDropdown.render() }

                { renderButtonCallback() } 
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupSalutationDropdown.isInvalidValue() 
             || this.formGroupTitleDropdown.isInvalidValue() 
             || this.formGroupFirstName.isInvalidValue() 
             || this.formGroupLastName.isInvalidValue() 
             || this.formGroupStreet.isInvalidValue() 
             || this.formGroupHouseNumber.isInvalidValue() 
             || this.formGroupZip.isInvalidValue() 
             || this.formGroupCity.isInvalidValue() 
             || this.formGroupPostOfficeBox.isInvalidValue() 
             || this.formGroupCountryDropdown.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;