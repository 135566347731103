import React from 'react';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import Bike from '../../model/Bike';
import BikeApi from '../../api/BikeApi';
import BikeUrlPath from '../BikeUrlPath';
import BikeTypeActionBarButton from '../../../type/ui/fragment/ActionBarButton';
import OverviewBase from '../../../../../commons/ui/page/template/overview/OverviewBase';

class Overview extends OverviewBase<Bike, BikeApi> {
    private bikeTypeActionBarButton: BikeTypeActionBarButton;

    public constructor(props) {
        super(props,
              BikeApi.getInstance());

        this.setCreateUrl(BikeUrlPath.CREATE);

        this.bikeTypeActionBarButton = new BikeTypeActionBarButton(props.history);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> 
        );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_TYPE}>
                        {I18nConstants.FORM_TYPE_DEFAULT}
                    </Trans> 
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_BRAND}>
                        {I18nConstants.FORM_BRAND_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_MODEL}>
                        {I18nConstants.FORM_MODEL_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_IDENTIFICATION_NUMBER}>
                        {I18nConstants.FORM_IDENTIFICATION_NUMBER_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).bikeType.name}
                </td>

                <td>
                    {(item as any).brand}
                </td>

                <td>
                    {(item as any).model}
                </td>

                <td>
                    {(item as any).identificationNumber}
                </td>

                <td>
                    {(item as any).description}
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr>
        );
    }

    protected getActionBar() {
        return (
            <div>
                { this.bikeTypeActionBarButton.render() }
            </div>
        );
    }
}

export default Overview;