import IModelMapper from "../../../commons/model/IModelMapper";

import FortuneRiskClass from './FortuneRiskClass';

class FortuneRiskClassModelMapper implements IModelMapper<FortuneRiskClass> {
    fromJsonToObject(json: any): FortuneRiskClass {
        let fortuneRiskClass = new FortuneRiskClass();

        fortuneRiskClass.id   = json.id;
        fortuneRiskClass.name = json.name;

        return fortuneRiskClass;
    }

    fromObjectToJson(object: FortuneRiskClass) {
        return {
            id:   object.id,
            name: object.name
        }
    }
}

export default FortuneRiskClassModelMapper;