import ToDoListGroup from '../../model/ToDoListGroup';
import ToDoListGroupApi from '../../api/ToDoListGroupApi';
import ToDoListGroupUrlPath from '../ToDoListGroupUrlPath';

import CreateBase from '../../../../commons/ui/page/template/group/create/CreateBase';

class Create extends CreateBase<ToDoListGroup, ToDoListGroupApi> {
    public constructor(props) {
        super(props, 
              new ToDoListGroup(),
              ToDoListGroupApi.getInstance(),
              ToDoListGroupUrlPath.BASE);
    }
}

export default Create;