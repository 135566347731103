import CreateBase from '../../../../commons/ui/page/template/group/create/CreateBase';
import ShoppingListGroup from '../../model/ShoppingListGroup';
import ShoppingListGroupApi from '../../api/ShoppingListGroupApi';
import ShoppingListGroupUrlPath from '../ShoppingListGroupUrlPath';

class Create extends CreateBase<ShoppingListGroup, ShoppingListGroupApi> {
    public constructor(props) {
        super(props,
              new ShoppingListGroup(),
              ShoppingListGroupApi.getInstance(),
              ShoppingListGroupUrlPath.BASE);
    }
}

export default Create;