import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';

import TireModelMapper from '../model/TireModelMapper';

class TireApi implements IGenericApi {
    private static instance: TireApi;

    private static apiUrlPath = "/fuelConsumption/tire";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): TireApi {
        if (!TireApi.instance) {
            TireApi.instance = new TireApi();
        }

        return TireApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    TireApi.apiUrlPath,
                    new TireModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    TireApi.apiUrlPath,
                    id,
                    new TireModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    TireApi.apiUrlPath,
                    body,
                    new TireModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    TireApi.apiUrlPath,
                    id,
                    body,
                    new TireModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    TireApi.apiUrlPath,
                    id,
                    new TireModelMapper());
    }

    public getUrl() {
        return TireApi.apiUrlPath;
    }
}

export default TireApi;