import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../../../../i18n/I18nConstants';
import FormGroupBase from '../FormGroupBase';

class FormGroupGroup extends FormGroupBase {
    private ID_AND_NAME = "name";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_NAME,
                    I18nConstants.FORM_NAME_DEFAULT) }

                <Input 
                    type="text" 
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Group.name}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()} />

                { this.renderFormFeedbackWithInsertValidValueMessage() }
            </FormGroup> 
        );
    }

    public isInvalidValue() : boolean {
        return this.isInvalidValueByParameter(this.pointerToComponent.state.Group.name);
    }

    private isInvalidValueByParameter(valueToBeChecked) : boolean {
        if (!this.wasHandleSubmitClickedAtLeastOnce()) {
            return false;
        }

        if (!valueToBeChecked) {
            return true;
        } else {
            return false;
        }
    }

    private wasHandleSubmitClickedAtLeastOnce() : boolean {
        return this.pointerToComponent.state.wasHandleSubmitClickedAtLeastOnce;
    }
}

export default FormGroupGroup;