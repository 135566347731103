import React from 'react';
import { Button } from 'reactstrap';
import { Trans } from 'react-i18next';
import I18nConstants from '../../../../../i18n/I18nConstants';

class OverviewButtonBase {
    protected static render(i18nKey, i18nDefaultValue, pointerToComponentOrUtil, 
                            callback, callbackId: number = -1,
                            withoutTdTag: boolean = false) {
        if (callbackId == -1) {
            return OverviewButtonBase.renderWithoutCallbackId(i18nKey, i18nDefaultValue, 
                                                              pointerToComponentOrUtil, 
                                                              callback, withoutTdTag);
        } else {
            return OverviewButtonBase.renderWithCallbackId(i18nKey, i18nDefaultValue, 
                                                           pointerToComponentOrUtil,
                                                           callback, callbackId, withoutTdTag);
        }
    }

    // --- With callback ID ---

    private static renderWithCallbackId(i18nKey, i18nDefaultValue, pointerToComponentOrUtil, 
                                        callback, callbackId, withoutTdTag) {
        if (withoutTdTag === true) {
            return OverviewButtonBase.renderWithCallbackIdJustButton(
                                        i18nKey, i18nDefaultValue, pointerToComponentOrUtil, 
                                        callback, callbackId);
        } else {
            return (
                <td>
                    { OverviewButtonBase.renderWithCallbackIdJustButton(
                            i18nKey, i18nDefaultValue, pointerToComponentOrUtil, 
                            callback, callbackId) }
                </td> 
            );
        }
    }

    private static renderWithCallbackIdJustButton(i18nKey, i18nDefaultValue, 
                                                  pointerToComponentOrUtil, 
                                                  callback, callbackId) {
        return (
            <Button onClick={ () => callback(pointerToComponentOrUtil, callbackId) }>
                <Trans ns={I18nConstants.NS}
                       i18nKey={i18nKey}>
                    {i18nDefaultValue}
                </Trans>
            </Button>
        );
    }

    // --- Without callback ID ---

    private static renderWithoutCallbackId(i18nKey, i18nDefaultValue, pointerToComponentOrUtil, 
                                           callback, withoutTdTag) {
        if (withoutTdTag === true) {
            return OverviewButtonBase.renderWithoutCallbackIdJustButton(
                                        i18nKey, i18nDefaultValue, pointerToComponentOrUtil, 
                                        callback);
        } else {
            return (
                <td>
                    { OverviewButtonBase.renderWithoutCallbackIdJustButton(
                            i18nKey, i18nDefaultValue, pointerToComponentOrUtil, callback) }
                </td> 
            );
        }
    }

    private static renderWithoutCallbackIdJustButton(i18nKey, i18nDefaultValue, 
                                                     pointerToComponentOrUtil, callback) {
        return (
            <Button onClick={ () => callback(pointerToComponentOrUtil) }>
                <Trans ns={I18nConstants.NS_BASE}
                       i18nKey={i18nKey}>
                    {i18nDefaultValue}
                </Trans>
            </Button>
        );
    }
}

export default OverviewButtonBase;