import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "insuranceListItem";

    public static TITLE = "title";

    public static FORM_GROUP = "form.group";
    public static FORM_COMPANY = "form.company";
    public static FORM_NAME = "form.name";
    public static FORM_NUMBER = "form.number";
    public static FORM_DATEOFBEGIN = "form.dateOfBegin";
    public static FORM_DUEDATEYEARLY = "form.dueDateYearly";
    public static FORM_AMOUNTYEARLY = "form.amountYearly";
    public static FORM_PAYMENTINTERVAL = "form.paymentInterval";
    public static FORM_DESCRIPTION = "form.description";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;

    public static FORM_GROUP_DEFAULT = DefaultJson.form.group;
    public static FORM_COMPANY_DEFAULT = DefaultJson.form.company;
    public static FORM_NAME_DEFAULT = DefaultJson.form.name;
    public static FORM_NUMBER_DEFAULT = DefaultJson.form.number;
    public static FORM_DATEOFBEGIN_DEFAULT = DefaultJson.form.dateOfBegin;
    public static FORM_DUEDATEYEARLY_DEFAULT = DefaultJson.form.dueDateYearly;
    public static FORM_AMOUNTYEARLY_DEFAULT = DefaultJson.form.amountYearly;
    public static FORM_PAYMENTINTERVAL_DEFAULT = DefaultJson.form.paymentInterval;
    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
}

export default I18nConstants;