import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';

import EarningOverheadModelMapper from '../model/EarningOverheadModelMapper';

class EarningOverheadApi implements IGenericApi {
    private static instance: EarningOverheadApi;

    private static apiUrlPath = "earning/overhead";

    private static endpointSum = "/sum";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): EarningOverheadApi {
        if (!EarningOverheadApi.instance) {
            EarningOverheadApi.instance = new EarningOverheadApi();
        }

        return EarningOverheadApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    EarningOverheadApi.apiUrlPath,
                    new EarningOverheadModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    EarningOverheadApi.apiUrlPath,
                    id,
                    new EarningOverheadModelMapper());
    }

    public getSum() {
        let endpoint = EarningOverheadApi.endpointSum;

        return GenericApi.executeGetAllCall(
                    EarningOverheadApi.apiUrlPath + endpoint,
                    null);
    }

    public create(body) {
        return GenericApi.executePostCall(
                    EarningOverheadApi.apiUrlPath,
                    body,
                    new EarningOverheadModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    EarningOverheadApi.apiUrlPath,
                    id,
                    body,
                    new EarningOverheadModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    EarningOverheadApi.apiUrlPath,
                    id,
                    new EarningOverheadModelMapper());
    }

    public getUrl() {
        return EarningOverheadApi.apiUrlPath;
    }
}

export default EarningOverheadApi;