import IModelMapper from '../../../../../commons/model/IModelMapper';
import EarningGroupSum from './EarningGroupSum';

class EarningGroupSumModelMapper implements IModelMapper<EarningGroupSum> {
    fromJsonToObject(json: any): EarningGroupSum {
        let earningGroupSum = new EarningGroupSum();

        earningGroupSum.name = json.name;
        earningGroupSum.sum = json.sum;

        return earningGroupSum;
    }

    fromObjectToJson(object: EarningGroupSum) {
        return {
            name: (object.name || ""),
            sum: (object.sum || 0)
        };
    }
}

export default EarningGroupSumModelMapper;