import OverviewBase from '../../../../commons/ui/page/template/group/overview/OverviewBase';

import DrivingStyle from '../../model/DrivingStyle';
import DrivingStyleApi from '../../api/DrivingStyleApi';

class Overview extends OverviewBase<DrivingStyle, DrivingStyleApi> {
    public constructor(props) {
        super(props,
              DrivingStyleApi.getInstance());
    }
}

export default Overview;