import IModelMapper from '../../../commons/model/IModelMapper';
import InsuranceListGroup from './InsuranceListGroup';

class InsuranceListGroupModelMapper implements IModelMapper<InsuranceListGroup> {
    fromJsonToObject(json: any): InsuranceListGroup {
        let insuranceListGroup = new InsuranceListGroup();

        insuranceListGroup.id = json.id;
        insuranceListGroup.name = json.name;

        return insuranceListGroup;
    }

    fromObjectToJson(object: InsuranceListGroup) {
        return {
            id: object.id,
            name: (object.name || "")
        };
    }
}

export default InsuranceListGroupModelMapper;