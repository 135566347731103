import React from 'react';
import { Component } from 'react';
import { Form } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import AuthenticationApi from '../../../../../security/api/AuthenticationApi';
import LoginUrlPath from '../../../../../login/ui/LoginUrlPath';
import NavigationRenderer from '../../../fragment/NavigationRenderer';
import FormEventHandlerCreate from '../form/eventHandler/FormEventHandlerCreate';
import FormValidator from '../form/validator/FormValidator';

interface IProps<Item> {
    history: any;
}

interface IState<Item> {
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: Item;
}

abstract class CreateBase<Item, ApiForItem> 
  extends Component<IProps<Item>, IState<Item>> {
    private eventHandler: FormEventHandlerCreate;
    private formValidator: FormValidator;

    protected item: Item;
    protected api: ApiForItem;

    protected nextPageUrl: string;

    private showNavbar: boolean;

    // --------------------------------------------------------------------------------------------
    // --- Constructor ---

    public constructor(props, item, api, nextPageUrl) {
        super(props);

        this.item = item;
        this.api = api;

        this.nextPageUrl = nextPageUrl;

        this.showNavbar = true;

        this.eventHandler = new FormEventHandlerCreate(this, this.api, this.nextPageUrl);
        this.formValidator = new FormValidator(this);

        this.state = {
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: this.item
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // --------------------------------------------------------------------------------------------
    // --- Render ---

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            return (
                <div>
                    { NavigationRenderer.renderIfNecessary(this.showNavbar) }

                    <h1>

                    </h1>

                    <div>
                        <Form onSubmit={this.handleSubmit}>
                            { this.getFormGroupContent() }
                        </Form>
                    </div>
                </div>
            );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different Sections (for sub-classes) - Mandatory ---

    protected abstract getFormGroupContent();

    protected abstract isFormValid() : boolean;

    // --------------------------------------------------------------------------------------------
    // --- Form validations ---
    
    protected isInvalidValue(valueToBeChecked) : boolean {
        return this.formValidator.isInvalidValue(valueToBeChecked);
    }

    // --------------------------------------------------------------------------------------------
    // --- Event handlers ---

    private handleChange(event) {
        this.eventHandler.handleChange(event);
    }

    private handleSubmit(event) {
        this.eventHandler.handleSubmit(event);
    }
}

export default CreateBase;