import FortuneRiskClass from '../../model/FortuneRiskClass';
import FortuneRiskClassApi from '../../api/FortuneRiskClassApi';
import FortuneRiskClassUrlPath from '../FortuneRiskClassUrlPath';
import CreateBase from '../../../../commons/ui/page/template/create/CreateBase';
import Form from './Form';
import FormButtonCreate from '../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBase<FortuneRiskClass, FortuneRiskClassApi> {
    private form: Form;

    public constructor(props) {
        super(props,
              new FortuneRiskClass(),
              FortuneRiskClassApi.getInstance(),
              FortuneRiskClassUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;