import React from 'react';
import { XYPlot, HorizontalGridLines, VerticalGridLines, XAxis, YAxis, LineSeries } from 'react-vis';
import IApiCallContext from '../apiCallExecutor/IApiCallContext';
import Utils from '../../../../utilities/Utils';

class XYCoordinateSystemTime {
    private static WIDTH = 300;
    private static HEIGHT = 300;

    public getHtmlElement(data, xAxisTitle, yAxisTitle) {
        return (
            <XYPlot
                xType="time"
                width={XYCoordinateSystemTime.WIDTH}
                height={XYCoordinateSystemTime.HEIGHT}>
                    <HorizontalGridLines />
                    <VerticalGridLines />
                    <XAxis title={xAxisTitle} />
                    <YAxis title={yAxisTitle} />
                    <LineSeries data={data} />
            </XYPlot>
        );
    }

    public updateDiagramData(context: IApiCallContext) {
        let date;
        let sum = 0.0;

        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const listData = (context.pointerToHelper.getParameterInArrayWithObjects(
                                context.parameters, "listDataForDiagram") as any);

        const diagramData = listData.map(item => {
            sum += item.amount;

            date = Utils.createDateByIsoString(item.date);

            return { 
                x: date,
                y: sum 
            };
        });

        const state = context.pointerToHelper.createStateObject(
                            context.currentData.variableNameInState,
                            diagramData);

        context.pointerToComponent.setState(state);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }
}

export default XYCoordinateSystemTime;