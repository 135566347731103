import React from 'react';
import { FormGroup, Button } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupSubmitButton extends FormGroupBase {
    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup>
                <Button color="primary" type="submit">
                    { this.renderTranslation(
                        I18nConstants.NS,
                        I18nConstants.FORM_BUTTON_LOGIN,
                        I18nConstants.FORM_BUTTON_LOGIN_DEFAULT) }
                </Button>
            </FormGroup> 
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }
}

export default FormGroupSubmitButton;