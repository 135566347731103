import GenericApi from '../../../../commons/api/GenericApi';

import EarningOverheadGroupSumModelMapper from '../model/helper/EarningOverheadGroupSumModelMapper';

class EarningOverheadGroupApi {
    private static instance: EarningOverheadGroupApi;

    private static apiUrlPath = "earning/overhead/group";

    private static endpointSum = "/sumAsList";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): EarningOverheadGroupApi {
        if (!EarningOverheadGroupApi.instance) {
            EarningOverheadGroupApi.instance = new EarningOverheadGroupApi();
        }

        return EarningOverheadGroupApi.instance;
    }

    public getSumAsList() {
        let endpoint = EarningOverheadGroupApi.endpointSum;

        return GenericApi.executeGetAllCall(
                    EarningOverheadGroupApi.apiUrlPath + endpoint,
                    new EarningOverheadGroupSumModelMapper());
    }
}

export default EarningOverheadGroupApi;