import React from 'react';
import { Component } from 'react';
import { Input, Button, Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import i18n from '../../../../commons/i18n/i18n';
import I18nConstants from '../../i18n/I18nConstants';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneSavingRateApiCallExecutor from '../../api/FortuneSavingRateApiCallExecutor';
import FortuneSavingPlanApiCallExecutor from '../../../savingPlan/item/api/FortuneSavingPlanApiCallExecutor';

import Utils from '../../../../utilities/Utils';

import AuthenticationApi from '../../../../security/api/AuthenticationApi';

import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';

import FortuneSavingRateApi from '../../api/FortuneSavingRateApi';
import FortuneSavingRateUrlPath from '../FortuneSavingRateUrlPath';

import FortuneSavingPlan from '../../../savingPlan/item/model/FortuneSavingPlan';

import LoginUrlPath from '../../../../login/ui/LoginUrlPath';

import OverviewUtils from '../../../../commons/ui/page/template/overview/OverviewUtils';

import XYCoordinateSystemTime from '../../../../commons/ui/page/diagram/XYCoordinateSystemTime';

import FortuneOverviewActionBarButton from '../../../dashboard/ui/fragment/ActionBarButton';
import FortuneSavingRateCreateInWizardActionBarButton from '../fragment/ActionBarButtonCreateInWizardForAllSavingPlans';
import FortuneSavingPlanActionBarButton from '../../../savingPlan/item/ui/fragment/ActionBarButton';
import FortuneInvestmentPlanActionBarButton from '../../../investmentPlan/ui/fragment/ActionBarButton';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:                  boolean;
    List:                       [];
    savingPlans:                FortuneSavingPlan[],
    currentSavingPlanSelection: FortuneSavingPlan,
    sum:                        number;
    diagram:                    [];
}

class Overview extends Component<IProps, IState> {
    private api:              FortuneSavingRateApi;
    private apiCalls:             ApiCallExecutor;
    private diagram:              XYCoordinateSystemTime;
    private statisticsCalculator: StatisticsFragment;
    
    private overviewUtils:      OverviewUtils;

    private fortuneOverviewActionBarButton: FortuneOverviewActionBarButton;
    private fortuneSavingRateCreateInWizardActionBarButton: FortuneSavingRateCreateInWizardActionBarButton;
    private fortuneSavingPlanActionBarButton: FortuneSavingPlanActionBarButton;
    private fortuneInvestmentPlanActionBarButton: FortuneInvestmentPlanActionBarButton;

    public constructor(props) {
        super(props);

        this.api = FortuneSavingRateApi.getInstance();
        this.diagram = new XYCoordinateSystemTime();
        this.apiCalls = new ApiCallExecutor(this, this.diagram);
        this.statisticsCalculator = new StatisticsFragment(this);

        this.fortuneOverviewActionBarButton = new FortuneOverviewActionBarButton(props.history);
        this.fortuneSavingRateCreateInWizardActionBarButton = new FortuneSavingRateCreateInWizardActionBarButton(props.history);
        this.fortuneSavingPlanActionBarButton = new FortuneSavingPlanActionBarButton(props.history);
        this.fortuneInvestmentPlanActionBarButton = new FortuneInvestmentPlanActionBarButton(props.history);

        this.state = {
            isLoading:                  true,
            List:                       [],
            savingPlans:                [],
            currentSavingPlanSelection: new FortuneSavingPlan(),
            sum:                        0.0,
            diagram:                    []
        };

        this.overviewUtils = new OverviewUtils(
            this,
            this.getHeading(),
            FortuneSavingRateUrlPath.CREATE,
            FortuneSavingRateUrlPath.UPDATE,
            undefined,
            true,
            this.getActionBar());

        this.handleChange = this.handleChange.bind(this);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            let diagram = this.getDiagramDataElement();

            return(
                <div>
                    { this.overviewUtils.render(
                        this.getTableHead(),
                        this.getTableBody(),
                        this.getInitialSelections()) }

                    { this.statisticsCalculator.render() }

                    {diagram}                    
                </div> );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return(
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> );
    }

    // --------------------------------------------------------------------------------------------

    private getActionBar() {
        return (
            <div>
                { this.fortuneOverviewActionBarButton.render() }
                { this.fortuneSavingRateCreateInWizardActionBarButton.render() }
                { this.fortuneSavingPlanActionBarButton.render() }
                { this.fortuneInvestmentPlanActionBarButton.render() }
            </div> );
    }
    // --------------------------------------------------------------------------------------------

    private getInitialSelections() {
        let savingPlanValues = this.getGroupSavingPlanValues();

        return (
            <div>
                <Input 
                    type="select"
                    name="savingPlan"
                    id="savingPlan"
                    value={this.state.currentSavingPlanSelection.id}
                    onChange={this.handleChange}>
                    {savingPlanValues}
                </Input>

                <p>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.SUM}>
                        {I18nConstants.SUM_DEFAULT}
                    </Trans>
                    : {this.state.sum}
                </p>
            </div> );
    }

    private getTableHead() {
        return(
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AMOUNT}>
                        {I18nConstants.FORM_AMOUNT_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DATE}>
                        {I18nConstants.FORM_DATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_CURRENT_QUOTATION}>
                        {I18nConstants.FORM_CURRENT_QUOTATION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_INVESTMENT_AMOUNT}>
                        {I18nConstants.FORM_INVESTMENT_AMOUNT_DEFAULT}
                    </Trans>
                </th>

                { this.overviewUtils.getUpdateHeadingWrappedInThTag() }
                { this.overviewUtils.getDeleteHeadingWrappedInThTag() }
            </tr> );
    }

    private getTableBody() {
        return this.state.List.map( item => 
            <tr key={(item as any).id}>
                <td>
                    {(item as any).description}
                </td>

                <td>
                    {(item as any).amount}
                </td>

                <td>
                    {(item as any).date}
                </td>

                <td>
                    {(item as any).currentQuotation}
                </td>

                <td>
                    {(item as any).investmentAmount}
                </td>

                { this.overviewUtils.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.overviewUtils.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }

    private getGroupSavingPlanValues() {
        let savingPlansAsArray = this.state.savingPlans;

        let savingPlans = savingPlansAsArray.map( element => 
            <option value={element.id} label={element.description}>
                {element.description}
            </option> );

        return savingPlans;
    }

    private getDiagramDataElement() {
        const xAxisTitle = i18n.t(I18nConstants.NS + ":" + I18nConstants.DIAGRAM_DATE);
        const yAxisTitle = i18n.t(I18nConstants.NS + ":" + I18nConstants.DIAGRAM_AMOUNT);

        return this.diagram.getHtmlElement(this.state.diagram,
                                           xAxisTitle, yAxisTitle);
    }

    // --------------------------------------------------------------------------------------------

    private handleChange(event) {
        const target = event.target;

        let currentSavingPlanSelection = target.value;

        this.getCurrentSumAndSetToState(currentSavingPlanSelection);
    }

    private async getCurrentSumAndSetToState(savingPlanSelection) {
        this.apiCalls.executeStartingWithSavingRateSelection(savingPlanSelection);
    }    
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;
    private pointerToDiagram: XYCoordinateSystemTime;

    private helper: ApiCallExecutorHelper;

    private fortuneSavingRateApiCallExecutor: FortuneSavingRateApiCallExecutor<Overview>;
    private fortuneSavingPlanApiCallExecutor: FortuneSavingPlanApiCallExecutor<Overview>;

    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneSavingRateApiCallExecutor = 
                new FortuneSavingRateApiCallExecutor(pointerToComponent);

        this.fortuneSavingPlanApiCallExecutor = 
                new FortuneSavingPlanApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneSavingPlanGetAll());
        orderOfCalls.push(this.addFortuneSavingPlanSetFirstSavingPlanToState());
        orderOfCalls.push(this.addFortuneSavingRateGetAllBySavingPlanId());
        orderOfCalls.push(this.addFortuneSavingPlanGetSumById());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    public executeStartingWithSavingRateSelection(savingRateId) {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneSavingPlanGetById());
        orderOfCalls.push(this.addFortuneSavingRateGetAllBySavingPlanId());
        orderOfCalls.push(this.addFortuneSavingPlanGetSumById());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "fortuneSavingPlanId", savingRateId);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneSavingPlanGetAll() : IApiCallback {
        return {
            callback: this.fortuneSavingPlanApiCallExecutor.getAll,
            pointerToApiCall: this.fortuneSavingPlanApiCallExecutor,
            variableNameInState: "savingPlans"
        };
    }

    private addFortuneSavingPlanSetFirstSavingPlanToState() : IApiCallback {
        return {
            callback: this.fortuneSavingPlanApiCallExecutor.setFirstSavingPlanToParametersAndState,
            pointerToApiCall: this.fortuneSavingPlanApiCallExecutor,
            variableNameInState: "currentSavingPlanSelection"
        };
    }

    private addFortuneSavingPlanGetById() : IApiCallback {
        return {
            callback: this.fortuneSavingPlanApiCallExecutor.getById,
            pointerToApiCall: this.fortuneSavingPlanApiCallExecutor,
            variableNameInState: "currentSavingPlanSelection"
        };
    }

    private addFortuneSavingRateGetAllBySavingPlanId() : IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.getAllBySavingPlanId,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "List"
        };
    }

    private addFortuneSavingPlanGetSumById() : IApiCallback {
        return {
            callback: this.fortuneSavingPlanApiCallExecutor.getSumById,
            pointerToApiCall: this.fortuneSavingPlanApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addDiagramData() : IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagram"
        }
    }
}

// ================================================================================================

class StatisticsFragment {
    private pointerToComponent: Overview;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;
    }

    // --------------------------------------------------------------------------------------------

    public render() {
        return (
            <div>
                <Table>
                    <tbody>
                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS}
                                       i18nKey={I18nConstants.OVERVIEW_CURRENT_PERCENTAGE_TO_MIN_AMOUNT}>
                                    {I18nConstants.OVERVIEW_CURRENT_PERCENTAGE_TO_MIN_AMOUNT_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                { this.calculatePercentForMin() } %
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS}
                                       i18nKey={I18nConstants.OVERVIEW_CURRENT_PERCENTAGE_TO_MAX_AMOUNT}>
                                    {I18nConstants.OVERVIEW_CURRENT_PERCENTAGE_TO_MAX_AMOUNT_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                { this.calculatePercentForMax() } %
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS}
                                       i18nKey={I18nConstants.OVERVIEW_CURRENT_AMOUNT}>
                                    {I18nConstants.OVERVIEW_CURRENT_AMOUNT_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                { this.pointerToComponent.state.sum }
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS}
                                       i18nKey={I18nConstants.OVERVIEW_SAVING_RATE_PLANNED}>
                                    {I18nConstants.OVERVIEW_SAVING_RATE_PLANNED_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                {this.pointerToComponent.state.currentSavingPlanSelection.monthlyRate}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS}
                                       i18nKey={I18nConstants.OVERVIEW_MINIMUM_AMOUNT_PLANNED}>
                                    {I18nConstants.OVERVIEW_MINIMUM_AMOUNT_PLANNED_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                {this.pointerToComponent.state.currentSavingPlanSelection.plannedAmountMin}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS} 
                                       i18nKey={I18nConstants.OVERVIEW_MAXIMUM_AMOUNT_PLANNED}>
                                    {I18nConstants.OVERVIEW_MAXIMUM_AMOUNT_PLANNED_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                {this.pointerToComponent.state.currentSavingPlanSelection.plannedAmountMax}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS} 
                                       i18nKey={I18nConstants.OVERVIEW_RESIDUAL_AMOUNT_TO_MIN_AMOUNT}>
                                    {I18nConstants.OVERVIEW_RESIDUAL_AMOUNT_TO_MIN_AMOUNT_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                { this.calculateResidualAmountForMin() }
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS} 
                                       i18nKey={I18nConstants.OVERVIEW_RESIDUAL_AMOUNT_TO_MAX_AMOUNT}>
                                    {I18nConstants.OVERVIEW_RESIDUAL_AMOUNT_TO_MAX_AMOUNT_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                { this.calculateResidualAmountForMax() }
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS} 
                                       i18nKey={I18nConstants.OVERVIEW_REMAINING_MONTHS}>
                                    {I18nConstants.OVERVIEW_REMAINING_MONTHS_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                { this.calculateResidualAmountRemainingMonths() }
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS} 
                                       i18nKey={I18nConstants.OVERVIEW_NECESSARY_SAVING_RATE_IN_REMAINING_TIME_FOR_MIN_AMOUNT}>
                                    {I18nConstants.OVERVIEW_NECESSARY_SAVING_RATE_IN_REMAINING_TIME_FOR_MIN_AMOUNT_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                { this.calculateResidualAmountForMinDividedByMonths() }
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS} 
                                       i18nKey={I18nConstants.OVERVIEW_NECESSARY_SAVING_RATE_IN_REMAINING_TIME_FOR_MAX_AMOUNT}>
                                    {I18nConstants.OVERVIEW_NECESSARY_SAVING_RATE_IN_REMAINING_TIME_FOR_MAX_AMOUNT_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                { this.calculateResidualAmountForMaxDividedByMonths() }
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS} 
                                       i18nKey={I18nConstants.OVERVIEW_PRIORITY}>
                                    {I18nConstants.OVERVIEW_PRIORITY_DEFAULT}
                                </Trans> 
                            </td>

                            <td>
                                {this.pointerToComponent.state.currentSavingPlanSelection.priority.description}
                                &nbsp;
                                ({this.pointerToComponent.state.currentSavingPlanSelection.priority.priorityId})
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS} 
                                       i18nKey={I18nConstants.OVERVIEW_PLANNED_END_DATE}>
                                    {I18nConstants.OVERVIEW_PLANNED_END_DATE_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                {this.pointerToComponent.state.currentSavingPlanSelection.plannedEndDate}
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS} 
                                       i18nKey={I18nConstants.OVERVIEW_CALCULATED_END_DATE_FOR_MIN_AMOUNT_WITH_PLANNED_SAVING_RATE}>
                                    {I18nConstants.OVERVIEW_CALCULATED_END_DATE_FOR_MIN_AMOUNT_WITH_PLANNED_SAVING_RATE_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                { this.calculateEndDateForMinAmountWithPlannedSavingRate() }
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS} 
                                       i18nKey={I18nConstants.OVERVIEW_CALCULATED_END_DATE_FOR_MAX_AMOUNT_WITH_PLANNED_SAVING_RATE}>
                                    {I18nConstants.OVERVIEW_CALCULATED_END_DATE_FOR_MAX_AMOUNT_WITH_PLANNED_SAVING_RATE_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                { this.calculateEndDateForMaxAmountWithPlannedSavingRate() }
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Trans ns={I18nConstants.NS} 
                                       i18nKey={I18nConstants.OVERVIEW_ADD_TO_FORTUNE}>
                                    {I18nConstants.OVERVIEW_ADD_TO_FORTUNE_DEFAULT}
                                </Trans>
                            </td>

                            <td>
                                {this.pointerToComponent.state.currentSavingPlanSelection.addToFortune}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

    // --------------------------------------------------------------------------------------------

    private calculatePercentForMin() {
        try {
            const currentSum       = this.pointerToComponent.state.sum;
            const plannedAmountMin = this.pointerToComponent.state.currentSavingPlanSelection.plannedAmountMin;

            const result = Utils.roundTwoDecimalPlaces(currentSum * 100 / plannedAmountMin);

            return Utils.checkForNaNAndInfinityAndReturnPlaceholder(result);
        } catch (error) {
            return "-";
        }
    }

    private calculatePercentForMax() {
        try {
            const currentSum       = this.pointerToComponent.state.sum;
            const plannedAmountMax = this.pointerToComponent.state.currentSavingPlanSelection.plannedAmountMax;

            const result = Utils.roundTwoDecimalPlaces(currentSum * 100 / plannedAmountMax);

            return Utils.checkForNaNAndInfinityAndReturnPlaceholder(result);
        } catch (error) {
            return "-";
        }
    }

    private calculateResidualAmountForMin() {
        try {
            const currentSum       = this.pointerToComponent.state.sum;
            const plannedAmountMin = this.pointerToComponent.state.currentSavingPlanSelection.plannedAmountMin;

            const result = Utils.roundTwoDecimalPlaces(plannedAmountMin - currentSum);
        
            return Utils.checkForNaNAndInfinityAndReturnPlaceholder(result);
        } catch (error) {
            return "-";
        }
    }

    private calculateResidualAmountForMax() {
        try {
            const currentSum       = this.pointerToComponent.state.sum;
            const plannedAmountMax = this.pointerToComponent.state.currentSavingPlanSelection.plannedAmountMax;

            const result = Utils.roundTwoDecimalPlaces(plannedAmountMax - currentSum);
        
            return Utils.checkForNaNAndInfinityAndReturnPlaceholder(result);
        } catch (error) {
            return "-";
        }
    }

    private calculateResidualAmountRemainingMonths() {
        try {
            let plannedEndDate = this.pointerToComponent.state.currentSavingPlanSelection.plannedEndDate;
            const today          = new Date();

            plannedEndDate     = Utils.createDateByIsoString(plannedEndDate.toString());

            const result = Utils.calculateMonthsBetweenTwoDates(today, plannedEndDate);
        
            return Utils.checkForNaNAndInfinityAndReturnPlaceholder(result);
        } catch (error) {
            return "-";
        }
    }

    private calculateResidualAmountForMinDividedByMonths() {
        try {
            const currentSum       = this.pointerToComponent.state.sum;
            const plannedAmountMin = this.pointerToComponent.state.currentSavingPlanSelection.plannedAmountMin;

            let plannedEndDate   = this.pointerToComponent.state.currentSavingPlanSelection.plannedEndDate;
            const today            = new Date();

            plannedEndDate       = Utils.createDateByIsoString(plannedEndDate.toString());

            let remainingMonths  = Utils.calculateMonthsBetweenTwoDates(today, plannedEndDate);
            
            const result = Utils.roundTwoDecimalPlaces((plannedAmountMin - currentSum) / remainingMonths);
        
            return Utils.checkForNaNAndInfinityAndReturnPlaceholder(result);
        } catch (error) {
            return "-";
        }
    }

    private calculateResidualAmountForMaxDividedByMonths() {
        try {
            const currentSum       = this.pointerToComponent.state.sum;
            const plannedAmountMax = this.pointerToComponent.state.currentSavingPlanSelection.plannedAmountMax;

            let plannedEndDate   = this.pointerToComponent.state.currentSavingPlanSelection.plannedEndDate;
            const today            = new Date();

            plannedEndDate       = Utils.createDateByIsoString(plannedEndDate.toString());

            let remainingMonths  = Utils.calculateMonthsBetweenTwoDates(today, plannedEndDate);
            
            const result = Utils.roundTwoDecimalPlaces((plannedAmountMax - currentSum) / remainingMonths);
        
            return Utils.checkForNaNAndInfinityAndReturnPlaceholder(result);
        } catch (error) {
            return "-";
        }
    }

    private calculateEndDateForMinAmountWithPlannedSavingRate() {
        try {
            const currentSum        = this.pointerToComponent.state.sum;
            const plannedAmountMin  = this.pointerToComponent.state.currentSavingPlanSelection.plannedAmountMin;
            const savingRate        = this.pointerToComponent.state.currentSavingPlanSelection.monthlyRate;

            const residualAmount    = plannedAmountMin - currentSum;

            let months            = residualAmount / savingRate;

            months                = Math.ceil(months);

            const today             = new Date();
            let calculatedEndDate = new Date(today.setMonth(today.getMonth() + months));

            // Always use the first or last day of a month (depending on time zone).
            calculatedEndDate     = Utils.createDateByDayMonthYear(
                                        2, 
                                        (calculatedEndDate.getMonth() + 1), 
                                        calculatedEndDate.getFullYear());

            const result = Utils.convertDateToIsoString(calculatedEndDate);
        
            return Utils.checkForNaNAndInfinityAndReturnPlaceholder(result);
        } catch (error) {
            return "-";
        }
    }

    private calculateEndDateForMaxAmountWithPlannedSavingRate() {
        try {
            const currentSum        = this.pointerToComponent.state.sum;
            const plannedAmountMax  = this.pointerToComponent.state.currentSavingPlanSelection.plannedAmountMax;
            const savingRate        = this.pointerToComponent.state.currentSavingPlanSelection.monthlyRate;

            const residualAmount    = plannedAmountMax - currentSum;

            let months            = residualAmount / savingRate;

            months                = Math.ceil(months);

            const today             = new Date();
            let calculatedEndDate = new Date(today.setMonth(today.getMonth() + months));

            // Always use the first or last day of a month (depending on time zone).
            calculatedEndDate     = Utils.createDateByDayMonthYear(
                                        2, 
                                        (calculatedEndDate.getMonth() + 1), 
                                        calculatedEndDate.getFullYear());

            const result = Utils.convertDateToIsoString(calculatedEndDate);

            return Utils.checkForNaNAndInfinityAndReturnPlaceholder(result);
        } catch (error) {
            return "-";
        }
    }
}

export default Overview;