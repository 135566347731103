import React from 'react';
import { Component } from 'react';
import { Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneSavingRateApiCallExecutor from '../../../savingRate/api/FortuneSavingRateApiCallExecutor';
import Utils from '../../../../utilities/Utils';
import FortuneSavingRateSumGroupedByInvestmentPlanName from '../../../savingRate/model/helper/FortuneSavingRateSumGroupedByInvestmentPlanName';
import AuthenticationApi from '../../../../security/api/AuthenticationApi';
import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';
import LoginUrlPath from '../../../../login/ui/LoginUrlPath';
import PieChartWithSumAndDiagram from '../../../../commons/ui/page/diagram/PieChartWithSumAndPercentage';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    SumGroupedByInvestmentPlan: FortuneSavingRateSumGroupedByInvestmentPlanName[];
    diagramData: [];
    sum: number;
}

class FortuneGroupedByInvestmentPlanOverview extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;

    private diagram: PieChartWithSumAndDiagram;

    public constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            SumGroupedByInvestmentPlan: [],
            diagramData: [],
            sum: 0.0
        };

        this.diagram = new PieChartWithSumAndDiagram();

        this.apiCalls = new ApiCallExecutor(this, this.diagram);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            } else {
                return (
                    <div>
                        { this.getHeading() }
                        { this.getSum() }
                        { this.getTable() }
                        { this.getDiagramDataElement() }
                    </div>
                );
            }
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return (
            <h3>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.GROUPED_BY_INVESTMENT_PLAN_TITLE}>
                    {I18nConstants.GROUPED_BY_INVESTMENT_PLAN_TITLE_DEFAULT}
                </Trans>
            </h3>
        );
    }

    private getSum() {
        return (
            <p>
                <Trans ns={I18nConstants.NS_BASE}
                       i18nKey={I18nConstants.GENERAL_SUM}>
                    {I18nConstants.GENERAL_SUM_DEFAULT}
                </Trans>
                : {this.state.sum}
            </p>
        );
    }

    private getTable() {
        return (
            <Table>
                <tbody>
                    { this.getTableBody() }
                </tbody>
            </Table>
        );
    }

    private getTableBody() {
        return this.state.SumGroupedByInvestmentPlan.map( item =>
            <tr>
                <td>
                    {item.name}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.sum)}
                </td>
            </tr> );
    }

    private getDiagramDataElement() {
        return this.diagram.getHtmlElement(this.state.diagramData);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: FortuneGroupedByInvestmentPlanOverview;
    private pointerToDiagram: PieChartWithSumAndDiagram;

    private helper: ApiCallExecutorHelper;

    private fortuneSavingRateApiCallExecutor: 
                FortuneSavingRateApiCallExecutor<FortuneGroupedByInvestmentPlanOverview>;
    
    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneSavingRateApiCallExecutor = 
                new FortuneSavingRateApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();
    
        orderOfCalls.push(this.addFortuneGroupedByInvestmentPlanOverview());
        orderOfCalls.push(this.addFortuneGroupedByInvestmentPlanOverviewToParameters());
        orderOfCalls.push(this.addSumInvestmentPlans());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneGroupedByInvestmentPlanOverview() : IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.getSumByInvestmentPlan,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "SumGroupedByInvestmentPlan"
        };
    }

    private addFortuneGroupedByInvestmentPlanOverviewToParameters(): IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.setSumByInvestmentPlanToParameters,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "listDataForDiagram"
        };
    }

    private addSumInvestmentPlans(): IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.getSumInvestmentPlanCurrentQuotation,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addDiagramData(): IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagramData"
        };
    }
}

export default FortuneGroupedByInvestmentPlanOverview;