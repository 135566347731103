import Utils from '../../../../utilities/Utils';
import LinkList from '../../model/LinkList';
import LinkListUrlPath from '../LinkListUrlPath';
import LinkListGroup from '../../../group/model/LinkListGroup';
import LinkListApi from '../../api/LinkListApi';
import LinkListGroupApi from '../../../group/api/LinkListGroupApi';
import UpdateBaseWithGroup from '../../../../commons/ui/page/template/update/UpdateBaseWithGroup';
import Form from './Form';
import FormButtonUpdate from '../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBaseWithGroup<LinkList, LinkListGroup, 
                                         LinkListApi, LinkListGroupApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new LinkList(),
              LinkListApi.getInstance(),
              LinkListGroupApi.getInstance(),
              idOfItemToBeUpdated,
              LinkListUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;