import I18nConstantsBase from '../../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "fuelConsumptionVehicleItem";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static FORM_TYPE = "form.type";
    public static FORM_BRAND = "form.brand";
    public static FORM_MODEL = "form.model";
    public static FORM_DESCRIPTION = "form.description";
    public static FORM_VIN = "form.vin";
    public static FORM_KIND_OF_FUEL = "form.kindOfFuel";
    public static FORM_FUEL_UNIT = "form.fuelUnit";

    public static FORM_ERROR_INSERT_VALID_VALUE = "form.error.insertValidValue";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static FORM_TYPE_DEFAULT = DefaultJson.form.type;
    public static FORM_BRAND_DEFAULT = DefaultJson.form.brand;
    public static FORM_MODEL_DEFAULT = DefaultJson.form.model;
    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
    public static FORM_VIN_DEFAULT = DefaultJson.form.vin;
    public static FORM_KIND_OF_FUEL_DEFAULT = DefaultJson.form.kindOfFuel;
    public static FORM_FUEL_UNIT_DEFAULT = DefaultJson.form.fuelUnit;

    public static FORM_ERROR_INSERT_VALID_VALUE_DEFAULT = DefaultJson.form.error.insertValidValue;
}

export default I18nConstants;