import Utils from '../../../../../utilities/Utils';
import EarningOverhead from '../../model/EarningOverhead';
import EarningOverheadApi from '../../api/EarningOverheadApi';
import EarningGroup from '../../../../../earningsExpenses/earning/group/model/EarningGroup';
import EarningGroupApi from '../../../../../earningsExpenses/earning/group/api/EarningGroupApi';
import EarningsExpensesOverheadUrlPath from '../../../../item/ui/EarningsExpensesOverheadUrlPath';
import UpdateBaseWithGroup from '../../../../../commons/ui/page/template/update/UpdateBaseWithGroup';
import Form from './Form';
import FormButtonUpdate from '../../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBaseWithGroup<EarningOverhead, EarningGroup,
                                         EarningOverheadApi, EarningGroupApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new EarningOverhead(),
              EarningOverheadApi.getInstance(),
              EarningGroupApi.getInstance(),
              idOfItemToBeUpdated,
              EarningsExpensesOverheadUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;