import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupUsername extends FormGroupBase {
    private ID_AND_NAME = "username";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_USERNAME,
                    I18nConstants.FORM_USERNAME_DEFAULT) }

                <Input 
                    type="text" 
                    name={this.ID_AND_NAME} 
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.user.username}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()} />
                        
                { this.renderFormFeedbackWithInsertValidValueMessage() }
            </FormGroup> 
        );
    }

    public isInvalidValue(): boolean {
        // Initial state: No submit was clicked by the user and username is 'undefined'.
        if (    (!this.pointerToComponent.state.wasHandleSubmitClickedAtLeastOnce)
             && (this.pointerToComponent.state.user.username === undefined)) {
            return false;
        }

        // Submit was clicked at least once, but no username was inserted.
        if (    (this.pointerToComponent.state.wasHandleSubmitClickedAtLeastOnce)
             && (this.pointerToComponent.state.user.username === undefined)) {
            return true;
        }
        
        // A username wasn't inserted.
        if (this.pointerToComponent.state.user.username.length === 0) {
            return true;
        } else {
            return false;
        }
    }
}

export default FormGroupUsername;