import IModelMapper from "../../../commons/model/IModelMapper";

import FortuneSavingRate from './FortuneSavingRate';

class FortuneSavingRateModelMapper implements IModelMapper<FortuneSavingRate> {
    fromJsonToObject(json: any): FortuneSavingRate {
        let fortuneSavingRate = new FortuneSavingRate();

        fortuneSavingRate.id               = json.id;
        fortuneSavingRate.savingPlan       = json.savingPlan;
        fortuneSavingRate.description      = json.description;
        fortuneSavingRate.amount           = json.amount;
        fortuneSavingRate.date             = json.date;
        fortuneSavingRate.currentQuotation = json.currentQuotation;
        fortuneSavingRate.investmentAmount = json.investmentAmount;

        return fortuneSavingRate;
    }

    fromObjectToJson(object: FortuneSavingRate) {
        return {
            id:               object.id,
            savingPlan:       (object.savingPlan || undefined),
            description:      (object.description || ""),
            amount:           (object.amount || 0),
            date:             (object.date || "0001-01-01"),
            currentQuotation: (object.currentQuotation || 0),
            investmentAmount: (object.investmentAmount || 0)
        };
    }
}

export default FortuneSavingRateModelMapper;