import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "shoppingListKindOfQuantity";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static CRUD_CREATE = "crud.create";
    public static CRUD_UPDATE = "crud.update";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static CRUD_CREATE_DEFAULT = DefaultJson.crud.create;
    public static CRUD_UPDATE_DEFAULT = DefaultJson.crud.update;
}

export default I18nConstants;