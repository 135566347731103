import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import FortuneSavingPlanModelMapper from '../model/FortuneSavingPlanModelMapper';
import FortuneSavingPlanMonthlyRateByInvestmentPlanModelMapper from '../model/helper/FortuneSavingPlanMonthlyRateByInvestmentPlanModelMapper';
import FortuneSavingPlanMonthlyRateBySavingPlanModelMapper from '../model/helper/FortuneSavingPlanMonthlyRateBySavingPlanModelMapper';

class FortuneSavingPlanApi implements IGenericApi {
    private static instance: FortuneSavingPlanApi;

    private static apiUrlPath = "fortune/savingPlan";

    private static endpointSum = "/sum";
    private static endpointMonthlyRateByInvestmentPlan = "/monthlyRate/byInvestmentPlan";
    private static endpointMonthlyRateBySavingPlan = "/monthlyRate/bySavingPlan";
    private static endpointSumMonthlyRateByInvestmentPlan = "/sum/monthlyRate/byInvestmentPlan";
    private static endpointSumMonthlyRateBySavingPlan = "/sum/monthlyRate/bySavingPlan";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): FortuneSavingPlanApi {
        if (!FortuneSavingPlanApi.instance) {
            FortuneSavingPlanApi.instance = new FortuneSavingPlanApi();
        }

        return FortuneSavingPlanApi.instance;
    }
    
    public getAll() {
        return GenericApi.executeGetAllCall(
                    FortuneSavingPlanApi.apiUrlPath,
                    new FortuneSavingPlanModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    FortuneSavingPlanApi.apiUrlPath,
                    id,
                    new FortuneSavingPlanModelMapper());
    }

    public getSumById(id) {
        let endpoint = FortuneSavingPlanApi.endpointSum;

        let filterAsString = "id" + "=" + id.toString();

        return GenericApi.executeGetCallWithFilter(
                    FortuneSavingPlanApi.apiUrlPath + endpoint,
                    filterAsString,
                    null);   // No mapper needed, because the result is just a double-value.
    }

    public getMonthlyRateByInvestmentPlan() {
        let endpoint = FortuneSavingPlanApi.endpointMonthlyRateByInvestmentPlan;
    
        return GenericApi.executeGetAllCall(
            FortuneSavingPlanApi.apiUrlPath + endpoint,
            new FortuneSavingPlanMonthlyRateByInvestmentPlanModelMapper()
        );
    }

    public getMonthlyRateBySavingPlan() {
        let endpoint = FortuneSavingPlanApi.endpointMonthlyRateBySavingPlan;
    
        return GenericApi.executeGetAllCall(
            FortuneSavingPlanApi.apiUrlPath + endpoint,
            new FortuneSavingPlanMonthlyRateBySavingPlanModelMapper()
        );
    }

    public getSumMonthlyRateByInvestmentPlan() {
        let endpoint = FortuneSavingPlanApi.endpointSumMonthlyRateByInvestmentPlan;

        return GenericApi.executeGetAllCall(
                FortuneSavingPlanApi.apiUrlPath + endpoint,
                null);
    }

    public getSumMonthlyRateBySavingPlan() {
        let endpoint = FortuneSavingPlanApi.endpointSumMonthlyRateBySavingPlan;

        return GenericApi.executeGetAllCall(
                FortuneSavingPlanApi.apiUrlPath + endpoint,
                null);
    }

    public create(body) {
        body = this.setInvestmentPlanFromNoneToUndefined(body);

        return GenericApi.executePostCall(
                    FortuneSavingPlanApi.apiUrlPath,
                    body,
                    new FortuneSavingPlanModelMapper());
    }

    public update(id, body) {
        body = this.setInvestmentPlanFromNoneToUndefined(body);

        return GenericApi.executePutCall(
                    FortuneSavingPlanApi.apiUrlPath,
                    id,
                    body,
                    new FortuneSavingPlanModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    FortuneSavingPlanApi.apiUrlPath,
                    id,
                    new FortuneSavingPlanModelMapper());
    }

    public getUrl() {
        return FortuneSavingPlanApi.apiUrlPath;
    }

    private setInvestmentPlanFromNoneToUndefined(body) {
        if (body.investmentPlan.id === "none") {
            body.investmentPlan= undefined;
        }

        return body;
    }
}

export default FortuneSavingPlanApi;