import React from 'react';
import { Component } from 'react';
import { Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import AuthenticationApi from '../../../../../security/api/AuthenticationApi';
import LoadingScreen from '../../loadingScreen/LoadingScreen';
import LoginUrlPath from '../../../../../login/ui/LoginUrlPath';
import DeleteConfirm from '../delete/DeleteConfirm';
import NavigationRenderer from '../../../fragment/NavigationRenderer';
import OverviewButtonCreate from './button/OverviewButtonCreate';
import OverviewButtonUpdate from './button/OverviewButtonUpdate';
import OverviewButtonDelete from './button/OverviewButtonDelete';
import OverviewButtonGroups from './button/OverviewButtonGroups';
import OverviewEventHandlerCreate from './eventHandler/OverviewEventHandlerCreate';
import OverviewEventHandlerUpdate from './eventHandler/OverviewEventHandlerUpdate';
import OverviewEventHandlerDelete from './eventHandler/OverviewEventHandlerDelete';
import OverviewEventHandlerGroups from './eventHandler/OverviewEventHandlerGroups';
import OverviewHeadingUpdate from './heading/OverviewHeadingUpdate';
import OverviewHeadingDelete from './heading/OverviewHeadingDelete';
import OverviewApiCallGetAll from './apiCall/OverviewApiCallGetAll';
import OverviewApiCallDelete from './apiCall/OverviewApiCallDelete';

interface IProps<List> {
    history: any;
    location: any;
}

interface IState<List> {
    isLoading: boolean;
    sum: number;
    List: List[]; 
    Group: object;
    diagram: [];
}

abstract class OverviewBase<List, ApiForList> 
  extends Component<IProps<List>, IState<List>> {
    private api: ApiForList;

    private deleteConfirm: DeleteConfirm;   // Used within callbacks.

    private createUrl: string;  // Used within callbacks.
    private updateUrl: string;  // Used within callbacks.
    private groupsOverviewUrl: string;  // Used within callbacks.

    private showNavbar: boolean;

    // --------------------------------------------------------------------------------------------
    // --- Constructor ---

    public constructor(props, api) {
        super(props);

        this.api = api;

        this.deleteConfirm = new DeleteConfirm(props);

        // --- Could by changed by setters ---
        this.createUrl = "";
        this.updateUrl = "";
        this.groupsOverviewUrl = "";

        this.showNavbar = true;
        // --- END - Could be changed by setters ---

        this.state = {
            isLoading: true,
            sum: 0.0,
            List: [],
            Group: {},
            diagram: []
        };
    }

    // --------------------------------------------------------------------------------------------
    // --- Members - Could be set in sub-classes ---

    public setCreateUrl(url) {
        this.createUrl = url;
    }

    public setUpdateUrl(url) {
        this.updateUrl = url;
    }

    public setGroupsOverviewUrl(url) {
        this.groupsOverviewUrl = url;
    }

    public setShowNavbar(showNavbar: boolean) {
        this.showNavbar = showNavbar;
    }

    // --------------------------------------------------------------------------------------------
    // --- Render ---

    public render() { 
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return (
                <div>
                    { NavigationRenderer.renderIfNecessary(this.showNavbar) }

                    { this.getHeading() }
                    <div>
                        <div>
                            { this.getActionBar() }
                        </div>

                        <div>
                            { this.getSum() }
                        </div>
                        
                        <div>
                            { (this.getCreateButton() as any) }
                        </div>

                        <Table>
                            <thead>
                                { this.getTableHead() }
                            </thead>

                            <tbody>
                                { this.getTableBody() }
                            </tbody>
                        </Table>

                        { this.getDiagramAfterContent() }
                    </div>
                </div>
            );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different Sections (for sub-classes) - Mandatory ---

    protected abstract getHeading();

    protected abstract getTableHead();

    protected abstract getTableBody();

    // --------------------------------------------------------------------------------------------
    // --- Render - Different Sections (for sub-classes) - Optional ---

    protected getActionBar() {
        // Could be implemented in sub-classes, but it's no need to do this in every sub-class.
        return ( <div></div> );
    }

    protected getSum() {
        // Could be implemented in sub-classes, but it's no need to do this in every sub-class.
        return ( <div></div> );
    }

    protected getDiagramAfterContent() {
        // Could be implemented in sub-classes, but it's no need to do this in every sub-class.
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different sections (for sub-classes) - Headings - Optional ---

    protected getUpdateHeadingWrappedInThTag() {
        return OverviewHeadingUpdate.render();
    }

    protected getDeleteHeadingWrappedInThTag() {
        return OverviewHeadingDelete.render();
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different sections - Buttons (with event handlers) - Optional ---

    private getCreateButton() {
        let eventHandlerCreate = new OverviewEventHandlerCreate();

        return OverviewButtonCreate.render(this, eventHandlerCreate.onAction);
    }

    protected getUpdateButtonWrappedInTdTag(id) {
        let eventHandlerUpdate = new OverviewEventHandlerUpdate();

        return OverviewButtonUpdate.render(this, eventHandlerUpdate.onAction, id);
    } 

    protected getDeleteButtonWrappedInTdTag(id) {
        let eventHandlerDelete = new OverviewEventHandlerDelete();

        return OverviewButtonDelete.render(this, eventHandlerDelete.onAction, id);
    }

    protected getGroupsOverviewButton() {
        let eventHandlerGroups = new OverviewEventHandlerGroups();

        return OverviewButtonGroups.render(this, eventHandlerGroups.onAction);
    }

    // --------------------------------------------------------------------------------------------
    // --- Get/update/delete values (via API) ---

    public async componentDidMount() {
        this.getInitialValuesForListInState();
    }

    public async componentWillReceiveProps(nextProps) {
        this.updateListInState(nextProps);
    }

    protected getInitialValuesForListInState() {
        new OverviewApiCallGetAll().execute(this, this.api);
    }

    protected updateListInState(nextProps) {
        // Could be implemented in sub-classes, but it's no need to do this in every sub-class.
    }

    protected updateDiagramData(listData) {
        // Could be implemented in sub-classes, but it's no need to do this in every sub-class.
    }

    public deleteItemById(pointerToComponent, id) {
        // This is a method which is used as a callback for delete confirm dialog.
        new OverviewApiCallDelete().execute(pointerToComponent, pointerToComponent.api, id);
    }
}

export default OverviewBase;