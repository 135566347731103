import IModelMapper from '../../../commons/model/IModelMapper';
import Country from './Country';

class CountryModelMapper implements IModelMapper<Country> {
    fromJsonToObject(json: any): Country {
        let country = new Country();

        country.id = json.id;
        country.name = json.name;
        country.isoCode = json.country.isoCode;

        return country;
    }

    fromObjectToJson(object: Country) {
        return {
            id: object.id,
            name: object.name,
            country: {
                isoCode: (object.isoCode || "")
            }
        };
    }
}

export default CountryModelMapper;