import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import I18nConstantsEarningsExpenses from '../../../../item/i18n/I18nConstants';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import ExpenseApiCallExecutor from '../../api/ExpenseApiCallExecutor';
import ExpenseGroupApiCallExecutor from '../../../group/api/ExpenseGroupApiCallExecutor';
import EarningsExpensesApiCallExecutor from '../../../../item/api/EarningsExpensesApiCallExecutor';

import Utils from '../../../../../utilities/Utils';

import Expense from '../../model/Expense';
import ExpenseApi from '../../api/ExpenseApi';
import ExpenseUrlPath from '../EarningsExpensesExpenseUrlPath';

import OverviewBase from '../../../../../commons/ui/page/template/overview/OverviewBase';

import PieChartWithSumAndDiagram from '../../../../../commons/ui/page/diagram/PieChartWithSumAndPercentage';

class Overview extends OverviewBase<Expense, ExpenseApi> {
    private apiCalls: ApiCallExecutor;
    private diagram: PieChartWithSumAndDiagram;

    public constructor(props) {
        super(props,
              ExpenseApi.getInstance());

        this.diagram = new PieChartWithSumAndDiagram();
        this.apiCalls = new ApiCallExecutor(this, this.diagram);

        this.setCreateUrl(ExpenseUrlPath.CREATE);
        this.setUpdateUrl(ExpenseUrlPath.UPDATE);

        this.setShowNavbar(false);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> );
    }

    protected getSum() {
        return (
            <div>
                <p>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                        i18nKey={I18nConstantsEarningsExpenses.SUM}>
                        {I18nConstantsEarningsExpenses.SUM_DEFAULT}
                    </Trans>
                    : {this.state.sum}
                </p>
            </div> );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                           i18nKey={I18nConstantsEarningsExpenses.FORM_DESCRIPTION}>
                        {I18nConstantsEarningsExpenses.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                           i18nKey={I18nConstantsEarningsExpenses.FORM_DATE}>
                        {I18nConstantsEarningsExpenses.FORM_DATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                           i18nKey={I18nConstantsEarningsExpenses.FORM_GROUP}>
                        {I18nConstantsEarningsExpenses.FORM_GROUP_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                           i18nKey={I18nConstantsEarningsExpenses.FORM_AMOUNT}>
                        {I18nConstantsEarningsExpenses.FORM_AMOUNT_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                           i18nKey={I18nConstantsEarningsExpenses.FORM_IS_OVERHEAD}>
                        {I18nConstantsEarningsExpenses.FORM_IS_OVERHEAD_DEFAULT}
                    </Trans>
                </th>

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr> );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).description}
                </td>

                <td>
                    {(item as any).date}
                </td>

                <td>
                    {(item as any).group.name}
                </td>

                <td>
                    {(item as any).amount}
                </td>

                <td id="checkbox">
                    <FormGroup check>
                        <Label check>
                            <Input 
                                type="checkbox"
                                checked={(item as any).isOverhead}
                                disabled="true" />
                        </Label>
                    </FormGroup>
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }

    protected getDiagramAfterContent() {
        return this.diagram.getHtmlElement(this.state.diagram);
    }

    protected getInitialValuesForListInState() {
        this.apiCalls.executeAll();
    }

    protected updateListInState(nextProps) {
        let currentYearAndMonthSelection = nextProps.currentYearAndMonthSelection;

        let monthAndYearObject = Utils.getMonthAndYearFromStringSeparatedBySlash(
                                            currentYearAndMonthSelection);

        if (monthAndYearObject) {
            this.apiCalls.executeStartingAfterMonthAndYearSelection(monthAndYearObject);
        } else {
            console.log("TODO");
        }
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;
    private pointerToDiagram: PieChartWithSumAndDiagram;

    private helper: ApiCallExecutorHelper;

    private earningsExpensesApiCallExecutor: EarningsExpensesApiCallExecutor<Overview>;
    private expenseApiCallExecutor: ExpenseApiCallExecutor<Overview>;
    private expenseGroupApiCallExecutor: ExpenseGroupApiCallExecutor<Overview>;

    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.earningsExpensesApiCallExecutor = new EarningsExpensesApiCallExecutor(pointerToComponent);
        this.expenseApiCallExecutor = new ExpenseApiCallExecutor(pointerToComponent);
        this.expenseGroupApiCallExecutor = new ExpenseGroupApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningsExpensesgGetYearsAndMonthsAsString());
        orderOfCalls.push(this.addExpenseGetByMonthAndYear());
        orderOfCalls.push(this.addExpenseGetSumByMonthAndYear());
        orderOfCalls.push(this.addExpenseGroupGetSumAsListByMonthAndYear());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    public executeStartingAfterMonthAndYearSelection(monthAndYearObject) {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addExpenseGetByMonthAndYear());
        orderOfCalls.push(this.addExpenseGetSumByMonthAndYear());
        orderOfCalls.push(this.addExpenseGroupGetSumAsListByMonthAndYear());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "monthAndYearObject", monthAndYearObject);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addEarningsExpensesgGetYearsAndMonthsAsString() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getYearsAndMonthsAsStringLastMonth,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: ""
        };
    }

    private addExpenseGetByMonthAndYear() : IApiCallback {
        return {
            callback: this.expenseApiCallExecutor.getByMonthAndYear,
            pointerToApiCall: this.expenseApiCallExecutor,
            variableNameInState: "List"
        };
    }

    private addExpenseGetSumByMonthAndYear() : IApiCallback {
        return {
            callback: this.expenseApiCallExecutor.getSumByMonthAndYear,
            pointerToApiCall: this.expenseApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addExpenseGroupGetSumAsListByMonthAndYear() : IApiCallback {
        return {
            callback: this.expenseGroupApiCallExecutor.getSumAsListByMonthAndYear,
            pointerToApiCall: this.expenseGroupApiCallExecutor,
            variableNameInState: "Group"
        };
    }

    private addDiagramData() : IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagram"
        };
    }
}

export default Overview;