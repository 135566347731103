import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import LetterTemplateModelMapper from '../model/LetterTemplateModelMapper';

class LetterTemplateApi implements IGenericApi {
    private static instance: LetterTemplateApi;

    private static apiUrlPath = "letter/template";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): LetterTemplateApi {
        if (!LetterTemplateApi.instance) {
            LetterTemplateApi.instance = new LetterTemplateApi();
        }

        return LetterTemplateApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    LetterTemplateApi.apiUrlPath,
                    new LetterTemplateModelMapper()
        );
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    LetterTemplateApi.apiUrlPath,
                    id,
                    new LetterTemplateModelMapper()
        );
    }

    public create(body) {
        return GenericApi.executePostCall(
                    LetterTemplateApi.apiUrlPath,
                    body,
                    new LetterTemplateModelMapper()
        );
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    LetterTemplateApi.apiUrlPath,
                    id,
                    body,
                    new LetterTemplateModelMapper()
        );
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    LetterTemplateApi.apiUrlPath,
                    id,
                    new LetterTemplateModelMapper()
        );
    }

    public getUrl() {
        return LetterTemplateApi.apiUrlPath;
    }
}

export default LetterTemplateApi;