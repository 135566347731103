import IModelMapper from '../../../../../commons/model/IModelMapper';

import ExpenseOverheadGroupSum from './ExpenseOverheadGroupSum';

class ExpenseOverheadGroupSumModelMapper implements IModelMapper<ExpenseOverheadGroupSum> {
    fromJsonToObject(json: any): ExpenseOverheadGroupSum {
        let expenseOverheadGroupSum = new ExpenseOverheadGroupSum();

        expenseOverheadGroupSum.name = json.name;
        expenseOverheadGroupSum.sum  = json.sum;

        return expenseOverheadGroupSum;
    }

    fromObjectToJson(object: ExpenseOverheadGroupSum) {
        return {
            name: (object.name || ""),
            sum:  (object.sum || 0)
        };
    }
}

export default ExpenseOverheadGroupSumModelMapper;