import IModelMapper from "../../../commons/model/IModelMapper";
import FortuneInvestmentAim from './FortuneInvestmentAim';

class FortuneInvestmentAimModelMapper implements IModelMapper<FortuneInvestmentAim> {
    fromJsonToObject(json: any): FortuneInvestmentAim {
        let fortuneInvestmentAim = new FortuneInvestmentAim();

        fortuneInvestmentAim.id            = json.id;
        fortuneInvestmentAim.description   = json.description;
        fortuneInvestmentAim.plannedAmount = json.plannedAmount;
        fortuneInvestmentAim.plannedDate   = json.plannedDate;

        return fortuneInvestmentAim;
    }

    fromObjectToJson(object: FortuneInvestmentAim) {
        return {
            id:            object.id,
            description:   (object.description || ""),
            plannedAmount: (object.plannedAmount || 0),
            plannedDate:   (object.plannedDate || "0001-01-01")
        };
    }
}

export default FortuneInvestmentAimModelMapper;