import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import FuelConsumptionModelMapper from '../model/FuelConsumptionModelMapper';

class FuelConsumptionApi implements IGenericApi {
    private static instance: FuelConsumptionApi;

    private static apiUrlPath = "/fuelConsumption";

    private static endpointByVehicleId = "/byVehicle";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): FuelConsumptionApi {
        if (!FuelConsumptionApi.instance) {
            FuelConsumptionApi.instance = new FuelConsumptionApi();
        }

        return FuelConsumptionApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    FuelConsumptionApi.apiUrlPath,
                    new FuelConsumptionModelMapper());
    }

    public getAllByVehicleId(vehicleId) {
        return GenericApi.executeGetAllCall(
                    FuelConsumptionApi.apiUrlPath + 
                    FuelConsumptionApi.endpointByVehicleId + "/" + vehicleId,
                    new FuelConsumptionModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    FuelConsumptionApi.apiUrlPath,
                    id,
                    new FuelConsumptionModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    FuelConsumptionApi.apiUrlPath,
                    body,
                    new FuelConsumptionModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    FuelConsumptionApi.apiUrlPath,
                    id,
                    body,
                    new FuelConsumptionModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    FuelConsumptionApi.apiUrlPath,
                    id,
                    new FuelConsumptionModelMapper());
    }

    public getUrl() {
        return FuelConsumptionApi.apiUrlPath;
    }
}

export default FuelConsumptionApi;