import ApiCallExecutorBase from "../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../commons/ui/page/apiCallExecutor/IApiCallContext';

import FortuneSavingRateApi from './FortuneSavingRateApi';

class FortuneSavingRateApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private fortuneSavingRateApi: FortuneSavingRateApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.fortuneSavingRateApi = FortuneSavingRateApi.getInstance();
    }

    public getById(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const id = (context.pointerToHelper.getParameterInArrayWithObjects(
                        context.parameters, "savingRateId") as number);

        context.currentData.pointerToApiCall.fortuneSavingRateApi.getById(id)
            .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    // For updates, the amount-field should be recalculated in backend.
                    // Therefore, no value will be set to the amount-field.
                    (state as any).amount = null;

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getAllBySavingPlanId(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const savingPlanId = (context.pointerToHelper.getParameterInArrayWithObjects(
                                context.parameters, "fortuneSavingPlanId") as number);

        context.currentData.pointerToApiCall.fortuneSavingRateApi.getAllBySavingPlanId(savingPlanId)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    // Add data also for diagram
                    context.pointerToHelper.addParameterToContext(context, "listDataForDiagram", 
                                                                  (response as any));

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });             
    }

    public getSum(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingRateApi.getSum()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });      
    }

    public getSumInvestmentPlanCurrentQuotation(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingRateApi.getSumInvestmentPlanCurrentQuotation()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSumSavingPlan(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingRateApi.getSumSavingPlan()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSumEverythingSavingPlan(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingRateApi.getSumEverythingSavingPlan()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSumByRiskClass(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingRateApi.getSumByRiskClass()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSumBySavingPlan(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingRateApi.getSumBySavingPlan()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public setSumBySavingPlanToParameters(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);
        
        const sumBySavingPlan = context.pointerToComponent.state.SumGroupedBySavingPlan;

        context.pointerToHelper.addParameterToContext(
                                    context,
                                    context.currentData.variableNameInState, 
                                    sumBySavingPlan);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }


    public getSumByInvestmentPlan(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingRateApi.getSumByInvestmentPlan()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public setSumByInvestmentPlanToParameters(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);
        
        const sumByInvestmentPlan = context.pointerToComponent.state.SumGroupedByInvestmentPlan;

        context.pointerToHelper.addParameterToContext(
                                    context,
                                    context.currentData.variableNameInState, 
                                    sumByInvestmentPlan);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }

    public getSumInvestmentPlanYield(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingRateApi.getSumInvestmentPlanYield()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public setSumGroupedByRiskClassToParameters(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const sumGroupedByRiskClass = context.pointerToComponent.state.SumGroupedByRiskClass;

        context.pointerToHelper.addParameterToContext(
                                    context,
                                    context.currentData.variableNameInState, 
                                    sumGroupedByRiskClass);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }
}

export default FortuneSavingRateApiCallExecutor;