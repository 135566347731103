import React from 'react';
import { Component } from 'react';
import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import BikeApiCallExecutor from '../../api/BikeApiCallExecutor';
import BikeTypeApiCallExecutor from '../../../type/api/BikeTypeApiCallExecutor';
import Utils from '../../../../../utilities/Utils';
import Bike from '../../model/Bike';
import BikeApi from '../../api/BikeApi';
import BikeUrlPath from '../BikeUrlPath';
import BikeType from '../../../type/model/BikeType';
import UpdateUtils from '../../../../../commons/ui/page/template/update/UpdateUtils';
import Form from './Form';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: Bike;
    BikeTypes: BikeType[];
}

class Update extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: BikeApi;
    private form: Form;
    private updateUtils: UpdateUtils;

    private idOfItemToBeUpdated: number;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = BikeApi.getInstance();

        this.idOfItemToBeUpdated =
            Utils.getIdFromGetParameters(props.location.search);

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: new Bike(),
            BikeTypes: []
        };

        this.form = new Form(props, this);

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            BikeUrlPath.BASE
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return (
            <h1>

            </h1>
        ) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.updateUtils.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------

    public getIdToBeUpdated() {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private bikeApiCallExecutor: BikeApiCallExecutor<Update>;
    private bikeTypeApiCallExecutor: BikeTypeApiCallExecutor<Update>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.bikeApiCallExecutor = new BikeApiCallExecutor(pointerToComponent);
        this.bikeTypeApiCallExecutor = new BikeTypeApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addBikeGetById());
        orderOfCalls.push(this.addBikeTypeGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "bikeId", 
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addBikeGetById() : IApiCallback {
        return {
            callback: this.bikeApiCallExecutor.getById,
            pointerToApiCall: this.bikeApiCallExecutor,
            variableNameInState: "Item"
        };
    }

    private addBikeTypeGetAll() : IApiCallback {
        return {
            callback: this.bikeTypeApiCallExecutor.getAll,
            pointerToApiCall: this.bikeTypeApiCallExecutor,
            variableNameInState: "BikeTypes"
        };
    }
}

export default Update;