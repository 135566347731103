import GenericApi from '../../../../commons/api/GenericApi';

import ExpenseOverheadGroupSumModelMapper from '../model/helper/ExpenseOverheadGroupSumModelMapper';

class ExpenseOverheadGroupApi {
    private static instance: ExpenseOverheadGroupApi;

    private static apiUrlPath = "expense/overhead/group";

    private static endpointSum = "/sumAsList";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): ExpenseOverheadGroupApi {
        if (!ExpenseOverheadGroupApi.instance) {
            ExpenseOverheadGroupApi.instance = new ExpenseOverheadGroupApi();
        }

        return ExpenseOverheadGroupApi.instance;
    }

    public getSumAsList() {
        let endpoint = ExpenseOverheadGroupApi.endpointSum;

        return GenericApi.executeGetAllCall(
                    ExpenseOverheadGroupApi.apiUrlPath + endpoint,
                    new ExpenseOverheadGroupSumModelMapper());
    }
}

export default ExpenseOverheadGroupApi;