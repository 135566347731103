import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "letterAddressGeneral";

    public static FORM_SALUTATION = "form.salutation";
    public static FORM_TITLE = "form.title";
    public static FORM_FIRST_NAME = "form.firstName";
    public static FORM_LAST_NAME = "form.lastName";

    public static FORM_NAME = "form.name";
    public static FORM_DEPARTMENT = "form.department";
    public static FORM_CONTACT_SALUTATION = "form.contactSalutation";
    public static FORM_CONTACT_TITLE = "form.contactTitle";
    public static FORM_CONTACT_FIRST_NAME = "form.contactFirstName";
    public static FORM_CONTACT_LAST_NAME = "form.contactLastName";

    public static FORM_STREET = "form.street";
    public static FORM_HOUSE_NUMBER = "form.houseNumber";
    public static FORM_ZIP = "form.zip";
    public static FORM_CITY = "form.city";
    public static FORM_POST_OFFICE_BOX = "form.postOfficeBox";
    public static FORM_COUNTRY = "form.country";
    public static FORM_PHONE = "form.phone";
    public static FORM_MAIL = "form.mail";
    public static FORM_URL = "form.url";

    // --------------------------------------------------------------------------------------------

    public static FORM_SALUTATION_DEFAULT = DefaultJson.form.salutation;
    public static FORM_TITLE_DEFAULT = DefaultJson.form.title;
    public static FORM_FIRST_NAME_DEFAULT = DefaultJson.form.firstName;
    public static FORM_LAST_NAME_DEFAULT = DefaultJson.form.lastName;
    
    public static FORM_NAME_DEFAULT = DefaultJson.form.name;
    public static FORM_DEPARTMENT_DEFAULT = DefaultJson.form.department;
    public static FORM_CONTACT_SALUTATION_DEFAULT = DefaultJson.form.contactSalutation;
    public static FORM_CONTACT_TITLE_DEFAULT = DefaultJson.form.contactTitle;
    public static FORM_CONTACT_FIRST_NAME_DEFAULT = DefaultJson.form.contactFirstName;
    public static FORM_CONTACT_LAST_NAME_DEFAULT = DefaultJson.form.contactLastName;
    
    public static FORM_STREET_DEFAULT = DefaultJson.form.street;
    public static FORM_HOUSE_NUMBER_DEFAULT = DefaultJson.form.houseNumber;
    public static FORM_ZIP_DEFAULT = DefaultJson.form.zip;
    public static FORM_CITY_DEFAULT = DefaultJson.form.city;
    public static FORM_POST_OFFICE_BOX_DEFAULT = DefaultJson.form.postOfficeBox;
    public static FORM_COUNTRY_DEFAULT = DefaultJson.form.country;
    public static FORM_PHONE_DEFAULT = DefaultJson.form.phone;
    public static FORM_MAIL_DEFAULT = DefaultJson.form.mail;
    public static FORM_URL_DEFAULT = DefaultJson.form.url;
}

export default I18nConstants;