import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import i18n from '../../../../../commons/i18n/i18n';
import FormGroupBase from '../../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupRecurrenceDropdown extends FormGroupBase {
    private ID_AND_NAME = "recurrence";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const recurrenceSelectValues = this.getRecurrenceSelectValues();

        return(
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_RECURRENCE,
                    I18nConstants.FORM_RECURRENCE_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.recurrence}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {recurrenceSelectValues}
                </Input>
            </FormGroup> );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getRecurrenceSelectValues() {
        let optionValues: JSX.Element[] = [];

        const defaultValue  = this.getDefaultValue();
        const everyMonth    = this.getEveryMonthValue();
        const everyQuarter  = this.getEveryQuarterValue();
        const everyHalfYear = this.getEveryHalfYearValue();
        const everyYear     = this.getEveryYearValue();

        optionValues.push(defaultValue);
        optionValues.push(everyMonth);
        optionValues.push(everyQuarter);
        optionValues.push(everyHalfYear);
        optionValues.push(everyYear);

        return optionValues;
    }

    private getDefaultValue() {
        let optionValue = i18n.t(I18nConstants.NS_BASE + ":" + 
                                 I18nConstants.FORM_DROPDOWN_VALUES_DEFAULT);

        return (
            <option value="-1">
                --- {optionValue} ---
            </option> );
    }

    private getEveryMonthValue() {
        let optionValue = i18n.t(I18nConstants.NS + ":" +
                                 I18nConstants.FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_MONTH);

        return (
            <option value="*">
                {optionValue}
            </option> );
    }

    private getEveryQuarterValue() {
        let optionValue = i18n.t(I18nConstants.NS + ":" +
                                 I18nConstants.FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_QUARTER);

        return (
            <option value="3,6,9,12">
                {optionValue}
            </option> );
    }

    private getEveryHalfYearValue() {
        let optionValue = i18n.t(I18nConstants.NS + ":" +
                                 I18nConstants.FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_HALF_YEAR);

        return (
            <option value="6,12">
                {optionValue}
            </option> );
    }

    private getEveryYearValue() {
        let optionValue = i18n.t(I18nConstants.NS + ":" +
                                 I18nConstants.FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_YEAR);

        return (
            <option value="12">
                {optionValue}
            </option> );
    }
}

export default FormGroupRecurrenceDropdown;