import IModelMapper from "../../../commons/model/IModelMapper";
import LetterSenderCompany from "../../sender/company/model/LetterSenderCompany";
import LetterSenderPerson from "../../sender/person/model/LetterSenderPerson";
import LetterSignature from "./LetterSignature";

class LetterSignatureModelMapper implements IModelMapper<LetterSignature> {
    fromJsonToObject(json: any): LetterSignature {
        let letterSignature = new LetterSignature();

        letterSignature.id = json.id;
        letterSignature.content = json.content;
        letterSignature.contentType = json.contentType;
        letterSignature.width = json.width;
        letterSignature.height = json.height;
        // Must be done like this, because on UPDATE, it could be that it has
        // a null-value because it isn't a mandatory parameter.
        letterSignature.senderPerson = (json.senderPerson === null)
                                        ? new LetterSenderPerson()
                                        : json.senderPerson;
        letterSignature.senderCompany = (json.senderCompany === null)
                                         ? new LetterSenderCompany()
                                         : json.senderCompany;

        return letterSignature;
    }

    fromObjectToJson(object: LetterSignature) {
        return {
            id: object.id,
            content: (object.content || undefined),
            contentType: (object.contentType || ""),
            width: (object.width || 0),
            height: (object.height || 0),
            senderPerson: (object.senderPerson || undefined),
            senderCompany: (object.senderCompany || undefined)
        };
    }
}

export default LetterSignatureModelMapper;