import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import ShoppingListGroupModelMapper from '../model/ShoppingListGroupModelMapper';

class ShoppingListGroupApi implements IGenericApi {
    private static instance: ShoppingListGroupApi;

    private static apiUrlPath = "shoppingList/group";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): ShoppingListGroupApi {
        if (!ShoppingListGroupApi.instance) {
            ShoppingListGroupApi.instance = new ShoppingListGroupApi();
        }

        return ShoppingListGroupApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    ShoppingListGroupApi.apiUrlPath,
                    new ShoppingListGroupModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    ShoppingListGroupApi.apiUrlPath,
                    id,
                    new ShoppingListGroupModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    ShoppingListGroupApi.apiUrlPath,
                    body,
                    new ShoppingListGroupModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    ShoppingListGroupApi.apiUrlPath,
                    id,
                    body,
                    new ShoppingListGroupModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    ShoppingListGroupApi.apiUrlPath,
                    id,
                    new ShoppingListGroupModelMapper());
    }

    public getUrl() {
        return ShoppingListGroupApi.apiUrlPath;
    }
}

export default ShoppingListGroupApi;