import ApiCallExecutorBase from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';

import ExpenseApi from './ExpenseApi';

class ExpenseApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private expenseApi: ExpenseApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.expenseApi = ExpenseApi.getInstance();
    }

    public getByMonthAndYear(context: IApiCallContext) { 
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const monthAndYearObject = (context.pointerToHelper.getParameterInArrayWithObjects(
                                        context.parameters, "monthAndYearObject") as any);
                                        
        context.currentData.pointerToApiCall.expenseApi.getByMonthAndYear(
                                                monthAndYearObject.month,
                                                monthAndYearObject.year)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSumByMonthAndYear(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const monthAndYearObject = (context.pointerToHelper.getParameterInArrayWithObjects(
                                        context.parameters, "monthAndYearObject") as any);
            
        context.currentData.pointerToApiCall.expenseApi.getSumByMonthAndYear(
                                                                monthAndYearObject.month,
                                                                monthAndYearObject.year)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                        context.currentData.variableNameInState,
                        (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => { 
                    console.log("TODO");
                });
    }

    public getSumByYear(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const year = context.pointerToHelper.getParameterInArrayWithObjects(context.parameters, "year");
        
        context.currentData.pointerToApiCall.expenseApi.getSumByYear(year)
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                        context.currentData.variableNameInState,
                        (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => { 
                    console.log("TODO");
                });
    }
}

export default ExpenseApiCallExecutor;