import React from 'react';
import { Component } from 'react';
import { Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneSavingRateApiCallExecutor from '../../../savingRate/api/FortuneSavingRateApiCallExecutor';

import Utils from '../../../../utilities/Utils';

import FortuneSavingRateSumGroupedByRiskClassName from '../../../savingRate/model/helper/FortuneSavingRateSumGroupedByRiskClassName';

import AuthenticationApi from '../../../../security/api/AuthenticationApi';

import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';

import LoginUrlPath from '../../../../login/ui/LoginUrlPath';

import PieChartWithSumAndDiagram from '../../../../commons/ui/page/diagram/PieChartWithSumAndPercentage';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:             boolean;
    sumTotal:              number;
    sumInvestmentPlans:    number;
    sumSavingPlans:        number;
    SumGroupedByRiskClass: FortuneSavingRateSumGroupedByRiskClassName[];
    diagramRiskClasses:    [];
    yieldInvestmentPlans:  number;
}

class FortuneGroupedByRiskClassesAndGroupsOverview extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;

    private diagram: PieChartWithSumAndDiagram;

    public constructor(props) {
        super(props);

        this.state = {
            isLoading:             false,
            sumTotal:              0.0,
            sumInvestmentPlans:    0.0,
            sumSavingPlans:        0.0,
            SumGroupedByRiskClass: [],
            diagramRiskClasses:    [],
            yieldInvestmentPlans:  0.0
        };

        this.diagram = new PieChartWithSumAndDiagram();

        this.apiCalls = new ApiCallExecutor(this, this.diagram);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            } else {
                return (
                    <div>
                         { this.getTotalTable() }
                         { this.getYield() }
                         { this.getSumByRiskClass() } 

                         { this.getDiagramDataElement() }              
                    </div>
                );
            }
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getTotalTable() {
        return (
            <Table>
                <tbody>
                    <tr>
                        <td>
                            <Trans ns={I18nConstants.NS}
                                   i18nKey={I18nConstants.GROUPED_BY_RISK_CLASSES_AND_GROUPS_TOTAL}>
                                {I18nConstants.GROUPED_BY_RISK_CLASSES_AND_GROUPS_TOTAL_DEFAULT}
                            </Trans>
                        </td>

                        <td>
                            {this.state.sumTotal}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Trans ns={I18nConstants.NS}
                                   i18nKey={I18nConstants.GROUPED_BY_RISK_CLASSES_AND_GROUPS_INVESTMENT_PLANS}>
                                {I18nConstants.GROUPED_BY_RISK_CLASSES_AND_GROUPS_INVESTMENT_PLANS_DEFAULT}
                            </Trans>
                        </td>

                        <td>
                            {this.state.sumInvestmentPlans}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Trans ns={I18nConstants.NS}
                                   i18nKey={I18nConstants.GROUPED_BY_RISK_CLASSES_AND_GROUPS_SAVING_PLANS}>
                                {I18nConstants.GROUPED_BY_RISK_CLASSES_AND_GROUPS_SAVING_PLANS_DEFAULT}
                            </Trans>
                        </td>

                        <td>
                            {this.state.sumSavingPlans}
                        </td>
                    </tr>
                </tbody>
            </Table> );
    }

    private getYield() {
        return (
            <Table>
                <tbody>
                    <tr>
                        <td>
                            <Trans ns={I18nConstants.NS}
                                   i18nKey={I18nConstants.GROUPED_BY_RISK_CLASSES_AND_GROUPS_INVESTMENT_PLANS_YIELD}>
                                {I18nConstants.GROUPED_BY_RISK_CLASSES_AND_GROUPS_INVESTMENT_PLANS_YIELD_DEFAULT}
                            </Trans>
                        </td>

                        <td>
                            { Utils.roundTwoDecimalPlaces(this.state.yieldInvestmentPlans) } %
                        </td>
                    </tr>
                </tbody>
            </Table> );
    }

    private getSumByRiskClass() {
        const content = this.state.SumGroupedByRiskClass.map( item =>
            <tr key={item.name}>
                <td>
                    {item.name}
                </td>

                <td>
                    {item.sum}
                </td>
            </tr> );

        return (
            <Table>
                <tbody>
                    {content}
                </tbody>
            </Table> );
    }

    private getDiagramDataElement() {
        return this.diagram.getHtmlElement(this.state.diagramRiskClasses);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: FortuneGroupedByRiskClassesAndGroupsOverview;
    private pointerToDiagram: PieChartWithSumAndDiagram;

    private helper: ApiCallExecutorHelper;

    private fortuneSavingRateApiCallExecutor: 
                FortuneSavingRateApiCallExecutor<FortuneGroupedByRiskClassesAndGroupsOverview>;

    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneSavingRateApiCallExecutor = 
                new FortuneSavingRateApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneSavingRateGetSum());
        orderOfCalls.push(this.addFortuneSavingRateGetSumInvestmentPlanCurrentQuotation());
        orderOfCalls.push(this.addFortuneSavingRateGetSumSavingPlan());
        orderOfCalls.push(this.addFortuneSavingRateGetSumByRiskClass());
        orderOfCalls.push(this.addFortuneSavingRateGetSumInvestmentPlanYield());
        orderOfCalls.push(this.addFortuneSavingRateSetSumGroupedByRiskClassToParameters());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneSavingRateGetSum() : IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.getSum,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "sumTotal"
        };
    }

    private addFortuneSavingRateGetSumInvestmentPlanCurrentQuotation() : IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.getSumInvestmentPlanCurrentQuotation,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "sumInvestmentPlans"
        };
    }

    private addFortuneSavingRateGetSumSavingPlan() : IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.getSumSavingPlan,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "sumSavingPlans"
        };
    }

    private addFortuneSavingRateGetSumByRiskClass() : IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.getSumByRiskClass,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "SumGroupedByRiskClass"
        };
    }

    private addFortuneSavingRateGetSumInvestmentPlanYield() : IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.getSumInvestmentPlanYield,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "yieldInvestmentPlans"
        };
    }

    private addFortuneSavingRateSetSumGroupedByRiskClassToParameters() : IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.setSumGroupedByRiskClassToParameters,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "listDataForDiagram"
        };
    }

    private addDiagramData() : IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagramRiskClasses"
        };
    }
}

export default FortuneGroupedByRiskClassesAndGroupsOverview;