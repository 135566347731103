import EarningGroup from '../../../../earningsExpenses/earning/group/model/EarningGroup';

class EarningOverhead {
    id!: number;
    group!: EarningGroup;
    description!: string;
    amount!: number;

    public constructor() {
        this.group = new EarningGroup();
    }
}

export default EarningOverhead;