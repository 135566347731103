import React from 'react';
import { Trans } from 'react-i18next';

import I18nConstants from '../../../../item/i18n/I18nConstants';

import Utils from '../../../../../utilities/Utils';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import EarningOverheadApiCallExecutor from '../../api/EarningOverheadApiCallExecutor';
import EarningOverheadGroupApiCallExecutor from '../../../group/api/EarningOverheadGroupApiCallExecutor';

import EarningOverhead from '../../model/EarningOverhead';
import EarningOverheadApi from '../../api/EarningOverheadApi';

import OverviewBase from '../../../../../commons/ui/page/template/overview/OverviewBase';

import PieChartWithSumAndDiagram from '../../../../../commons/ui/page/diagram/PieChartWithSumAndPercentage';

class OverviewGrouped extends OverviewBase<EarningOverhead, EarningOverheadApi> {
    private apiCalls: ApiCallExecutor;
    private diagram: PieChartWithSumAndDiagram;
    
    public constructor(props) {
        super(props,
              EarningOverheadApi.getInstance());

        this.diagram = new PieChartWithSumAndDiagram();
        this.apiCalls = new ApiCallExecutor(this, this.diagram);

        this.setShowNavbar(false);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.EARNINGS}>
                    {I18nConstants.EARNINGS_DEFAULT}
                </Trans>
            </h1> );
    }

    protected getSum() {
        return (
            <div>
                <p>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.SUM}>
                        {I18nConstants.SUM_DEFAULT}
                    </Trans>
                    : {this.state.sum}
                </p>
            </div> );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_PERCENTAGE}>
                        {I18nConstants.FORM_PERCENTAGE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AMOUNT}>
                        {I18nConstants.FORM_AMOUNT_DEFAULT}
                    </Trans>
                </th>
            </tr> );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).name}
                </td>

                <td>
                    { Utils.roundTwoDecimalPlaces((item as any).sum * 100 / this.state.sum) }
                </td>

                <td>
                    {(item as any).sum}
                </td>
            </tr>);
    }

    protected getDiagramAfterContent() {
        return this.diagram.getHtmlElement(this.state.diagram);
    }

    protected getInitialValuesForListInState() {
        this.apiCalls.executeAll();
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: OverviewGrouped;
    private pointerToDiagram: PieChartWithSumAndDiagram;

    private helper: ApiCallExecutorHelper;

    private earningOverheadApiCallExecutor: EarningOverheadApiCallExecutor<OverviewGrouped>;
    private earningOverheadGroupApiCallExecutor: EarningOverheadGroupApiCallExecutor<OverviewGrouped>;

    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.earningOverheadApiCallExecutor = new EarningOverheadApiCallExecutor(pointerToComponent);
        this.earningOverheadGroupApiCallExecutor = new EarningOverheadGroupApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningOverheadGetSum());
        orderOfCalls.push(this.addEarningOverheadGroupGetSumAsList());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                this.pointerToComponent,
                                this.helper, 
                                orderOfCalls, 
                                parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addEarningOverheadGetSum() : IApiCallback {
        return {
            callback: this.earningOverheadApiCallExecutor.getSum,
            pointerToApiCall: this.earningOverheadApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addEarningOverheadGroupGetSumAsList() : IApiCallback {
        return {
            callback: this.earningOverheadGroupApiCallExecutor.getSumAsList,
            pointerToApiCall: this.earningOverheadGroupApiCallExecutor,
            variableNameInState: "List"
        };
    }

    private addDiagramData() : IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagram"
        };
    }
}

export default OverviewGrouped;