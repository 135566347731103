import ApiCallExecutorBase from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';

import EarningOverheadTransactionApi from './EarningOverheadTransactionApi';

class EarningOverheadTransactionApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private earningOverheadTransactionApi: EarningOverheadTransactionApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.earningOverheadTransactionApi = EarningOverheadTransactionApi.getInstance();
    }

    public getById(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const id = (context.pointerToHelper.getParameterInArrayWithObjects(
                        context.parameters, "overheadId") as any);

        context.currentData.pointerToApiCall.earningOverheadTransactionApi.getById(id)
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }

    public getByOverheadId(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const idToBeDisplayed = (context.pointerToHelper.getParameterInArrayWithObjects(
                                    context.parameters, "overheadId") as any);

        context.currentData.pointerToApiCall.earningOverheadTransactionApi.getByOverheadId(idToBeDisplayed)
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }
}

export default EarningOverheadTransactionApiCallExecutor;