import CreateBase from '../../../../commons/ui/page/template/group/create/CreateBase';

import DrivingStyle from '../../model/DrivingStyle';
import DrivingStyleApi from '../../api/DrivingStyleApi';
import DrivingStyleUrlPath from '../DrivingStyleUrlPath';

class Create extends CreateBase<DrivingStyle, DrivingStyleApi> {
    public constructor(props) {
        super(props,
              new DrivingStyle(),
              DrivingStyleApi.getInstance(),
              DrivingStyleUrlPath.BASE);
    }
}

export default Create;