import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "letter";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static FORM_SENDER_PERSON = "form.sender.person";
    public static FORM_SENDER_COMPANY = "form.sender.company";

    public static FORM_ADDRESSEE_PERSON = "form.addressee.person";
    public static FORM_ADDRESSEE_COMPANY = "form.addressee.company";

    public static FORM_TEMPLATE = "form.template";
    public static FORM_DATE = "form.date";
    public static FORM_SUBJECT = "form.subject";
    public static FORM_TEXT = "form.text";
    public static FORM_ENCLOSURES = "form.enclosures";
    public static FORM_USE_SIGNATURE = "form.useSignature";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static FORM_SENDER_PERSON_DEFAULT = DefaultJson.form.sender.person;
    public static FORM_SENDER_COMPANY_DEFAULT = DefaultJson.form.sender.company;

    public static FORM_ADDRESSEE_PERSON_DEFAULT = DefaultJson.form.addressee.person;
    public static FORM_ADDRESSEE_COMPANY_DEFAULT = DefaultJson.form.addressee.company;

    public static FORM_TEMPLATE_DEFAULT = DefaultJson.form.template;
    public static FORM_DATE_DEFAULT = DefaultJson.form.date;
    public static FORM_SUBJECT_DEFAULT = DefaultJson.form.subject;
    public static FORM_TEXT_DEFAULT = DefaultJson.form.text;
    public static FORM_ENCLOSURES_DEFAULT = DefaultJson.form.enclosures;
    public static FORM_USE_SIGNATURE_DEFAULT = DefaultJson.form.useSignature;
}

export default I18nConstants;