import EarningGroup from '../../group/model/EarningGroup';

class Earning {
    id!: number;
    group!: EarningGroup;
    description!: string;
    date!: Date;
    amount!: number;
    isOverhead!: boolean;

    public constructor() {
        this.group = new EarningGroup();
        this.isOverhead = false;
    }
}

export default Earning;