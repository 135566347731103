import React from "react";
import { Trans } from "react-i18next";
import I18nConstants from "../../i18n/I18nConstants";
import UpdateBase from '../../../../commons/ui/page/template/group/update/UpdateBase';
import InsuranceListPaymentIntervalApi from '../../api/InsuranceListPaymentIntervalApi';
import InsuranceListPaymentInterval from '../../model/InsuranceListPaymentInterval';
import InsuranceListPaymentIntervalUrlPath from '../InsuranceListPaymentIntervalUrlPath';

class Update extends UpdateBase<InsuranceListPaymentInterval, InsuranceListPaymentIntervalApi> {
    public constructor(props) {
        super(
            props,
            new InsuranceListPaymentInterval(),
            InsuranceListPaymentIntervalApi.getInstance(),
            InsuranceListPaymentIntervalUrlPath.BASE
        );
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> 
        );
    }
}

export default Update;