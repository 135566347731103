import CreateBase from '../../../../commons/ui/page/template/group/create/CreateBase';
import InsuranceListGroupApi from '../../api/InsuranceListGroupApi';
import InsuranceListGroup from '../../model/InsuranceListGroup';
import InsuranceListGroupUrlPath from '../InsuranceListGroupUrlPath';

class Create extends CreateBase<InsuranceListGroup, InsuranceListGroupApi> {
    public constructor(props) {
        super(
            props,
            new InsuranceListGroup(),
            InsuranceListGroupApi.getInstance(),
            InsuranceListGroupUrlPath.BASE
        );
    }
}

export default Create;