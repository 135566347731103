import React from 'react';

import FormGroupSavingPlanDropdown from '../fragment/FormGroupSavingPlanDropdown';
import FormGroupDescription from '../fragment/FormGroupDescription';
import FormGroupAmount from '../fragment/FormGroupAmount';
import FormGroupDate from '../fragment/FormGroupDate';
import FormGroupCurrentQuotation from '../fragment/FormGroupCurrentQuotation';
import FormGroupInvestmentAmount from '../fragment/FormGroupInvestmentAmount';

class Form {
    private formGroupSavingPlan:       FormGroupSavingPlanDropdown;
    private formGroupDescription:      FormGroupDescription;
    private formGroupAmount:           FormGroupAmount;
    private formGroupDate:             FormGroupDate;
    private formGroupCurrentQuotation: FormGroupCurrentQuotation;
    private formGroupInvestmentAmount: FormGroupInvestmentAmount;

    public constructor(props, pointerToComponent) {
        this.formGroupSavingPlan       = new FormGroupSavingPlanDropdown(props, pointerToComponent);
        this.formGroupDescription      = new FormGroupDescription(props, pointerToComponent);
        this.formGroupAmount           = new FormGroupAmount(props, pointerToComponent);
        this.formGroupDate             = new FormGroupDate(props, pointerToComponent);
        this.formGroupCurrentQuotation = new FormGroupCurrentQuotation(props, pointerToComponent);
        this.formGroupInvestmentAmount = new FormGroupInvestmentAmount(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupSavingPlan.render() }
                { this.formGroupDescription.render() }
                { this.formGroupAmount.render() }
                { this.formGroupDate.render() }
                { this.formGroupCurrentQuotation.render() }
                { this.formGroupInvestmentAmount.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.formGroupSavingPlan.isInvalidValue()
             || this.formGroupDescription.isInvalidValue()
             || this.formGroupDate.isInvalidValue()
             || (!this.checkAmountFieldsFilledCorrectly())) {
            return false;
        } else {
            return true;
        }
    }

    private checkAmountFieldsFilledCorrectly() {
        if (!(    this.isJustAmountFieldFilled()
               || this.isCurrentQuotationAndInvestmentAmountFieldFilled())) {
            return false;
        } else {
            return true;
        }
    }

    private isJustAmountFieldFilled() {
        let isAmountFilledWithNumber           = !(this.formGroupAmount.isInvalidValue());
        let isCurrentQuotationFilledWithNumber = !(this.formGroupCurrentQuotation.isInvalidValue());
        let isInvestmentAmountFilledWithNumber = !(this.formGroupInvestmentAmount.isInvalidValue());

        if (    (isAmountFilledWithNumber)
             && (!(isCurrentQuotationFilledWithNumber))
             && (!(isInvestmentAmountFilledWithNumber))) {
            return true;
        } else {
            return false;
        }
    }

    private isCurrentQuotationAndInvestmentAmountFieldFilled() {
        let isAmountFilledWithNumber           = !(this.formGroupAmount.isInvalidValue());
        let isCurrentQuotationFilledWithNumber = !(this.formGroupCurrentQuotation.isInvalidValue());
        let isInvestmentAmountFilledWithNumber = !(this.formGroupInvestmentAmount.isInvalidValue());

        if (    (!(isAmountFilledWithNumber))
             && (isCurrentQuotationFilledWithNumber)
             && (isInvestmentAmountFilledWithNumber)) {
            return true;
        } else {
            return false;
        }
    }
}

export default Form;