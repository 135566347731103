import FortunePriority from "../../priority/model/FortunePriority";

class FortuneInvestmentPlan {
    id!:                number;
    priority!:          FortunePriority;
    description!:       string;
    issueFee!:          number;
    ter!:               number;
    fondsAmountInMio!:  number;
    distributionType!:  string;
    replicationMethod!: string;
    bankName!:          string;
    vendor!:            string;
    countryLocation!:   string;
    isin!:              string;
    firstIssueDate!:    Date;
    url!:               string; 

    public constructor() {
        this.priority = new FortunePriority();
    }
}

export default FortuneInvestmentPlan;