import axios from "axios";

import GenericApi from '../../../commons/api/GenericApi';

import EnvironmentVariables from '../../../commons/config/EnvironmentVariables';
import AuthHeader from "../../../security/api/AuthHeader";

class EarningsExpensesApi {
    private static instance: EarningsExpensesApi;

    private static apiUrlPath = "earningsExpenses";

    private static endpointYearsAndMonthsAsString = "/yearsAndMonthsAsString";
    private static endpointYearsAsString = "/yearsAsString";
    private static endpointSum = "/sum";
    private static endpointSavingRatio = "/savingRatio";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): EarningsExpensesApi {
        if (!EarningsExpensesApi.instance) {
            EarningsExpensesApi.instance = new EarningsExpensesApi();
        }

        return EarningsExpensesApi.instance;
    }

    public getYearsAndMonthsAsString() {
        let endpoint = EarningsExpensesApi.endpointYearsAndMonthsAsString;

        return new Promise((resolve, reject) => { 
            axios
            .get(EnvironmentVariables.getBackendApiUrl() + 
                 EarningsExpensesApi.apiUrlPath + endpoint,
                 { headers: AuthHeader.get() })
             .then( response => {
                        resolve(response.data);
                    })
             .catch( error => {
                        GenericApi.createErrorMessage(error.response, reject);
                     })
        });
    }

    public getYearsAsString() {
        let endpoint = EarningsExpensesApi.endpointYearsAsString;

        return new Promise((resolve, reject) => {
            axios
            .get(EnvironmentVariables.getBackendApiUrl() +
                 EarningsExpensesApi.apiUrlPath + endpoint,
                 { headers: AuthHeader.get() })
            .then(response => {
                        resolve(response.data);
                  })
            .catch(error   => {
                        GenericApi.createErrorMessage(error.response, reject);
                   })
        });
    }

    public getSumByMonthAndYear(month, year) {
        let endpoint = EarningsExpensesApi.endpointSum;

        let filterAsString = "month" + "=" + month.toString() + "&" +
                             "year"  + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                    EarningsExpensesApi.apiUrlPath + endpoint,
                    filterAsString,
                    null);   // No mapper needed, because the result is just a double-value.
    }

    public getSumByYear(year) {
        let endpoint = EarningsExpensesApi.endpointSum;

        let filterAsString = "year"  + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                    EarningsExpensesApi.apiUrlPath + endpoint,
                    filterAsString,
                    null);   // No mapper needed, because the result is just a double-value.
    }

    public getSavingRatioByMonthAndYear(month, year) {
        let endpoint = EarningsExpensesApi.endpointSavingRatio;

        let filterAsString = "month" + "=" + month.toString() + "&" +
                             "year"  + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                    EarningsExpensesApi.apiUrlPath + endpoint,
                    filterAsString,
                    null);   // No mapper needed, because the result is just a double-value.
    }

    public getSavingRatioByYear(year) {
        let endpoint = EarningsExpensesApi.endpointSavingRatio;

        let filterAsString = "year"  + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                    EarningsExpensesApi.apiUrlPath + endpoint,
                    filterAsString,
                    null);   // No mapper needed, because the result is just a double-value.
    }
}

export default EarningsExpensesApi;