import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import LetterModelMapper from '../model/LetterModelMapper';

class LetterApi implements IGenericApi {
    private static instance: LetterApi;

    private static apiUrlPath = "/letter";

    private static endpointXmlPaths = "/xmlPaths";
    private static endpointPdfById = "/pdf";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): LetterApi {
        if (!LetterApi.instance) {
            LetterApi.instance = new LetterApi();
        }

        return LetterApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    LetterApi.apiUrlPath,
                    new LetterModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    LetterApi.apiUrlPath,
                    id,
                    new LetterModelMapper());
    }

    public getXmlPaths() {
        return GenericApi.executeGetAllCall(
                    LetterApi.apiUrlPath + LetterApi.endpointXmlPaths,
                    null    // No mapper needed, because the result is just an array of strings.
        );
    }

    public create(body) {
        return GenericApi.executePostCall(
                    LetterApi.apiUrlPath,
                    body,
                    new LetterModelMapper());
    }

    public createPDF(id) {
        return GenericApi.executePostCallAsBlob(
                    LetterApi.apiUrlPath + LetterApi.endpointPdfById + "/" + id,
                    null,   // No body should be sent to the backend.
                    null    // No mapper needed, because the response contains the PDF-document.
        );
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    LetterApi.apiUrlPath,
                    id,
                    body,
                    new LetterModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    LetterApi.apiUrlPath,
                    id,
                    new LetterModelMapper());
    }

    public getUrl() {
        return LetterApi.apiUrlPath;
    }
}

export default LetterApi;