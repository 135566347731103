import EnvironmentVariables from '../../commons/config/EnvironmentVariables';

class LocalStorageToken {
    public constructor() {
        throw new Error('Constructor can\'t be called, because it\'s a singleton!');
    }

    public static get() {
        return localStorage.getItem(EnvironmentVariables.getTokenName());
    }

    public static set(token : string) {
        localStorage.setItem(EnvironmentVariables.getTokenName(), token);
    }

    public static clear() {
        localStorage.removeItem(EnvironmentVariables.getTokenName());
    }
}

export default LocalStorageToken;