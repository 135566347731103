import OverviewBase from '../../../../commons/ui/page/template/group/overview/OverviewBase';

import Tire from '../../model/Tire';
import TireApi from '../../api/TireApi';

class Overview extends OverviewBase<Tire, TireApi> {
    public constructor(props) {
        super(props,
              TireApi.getInstance());
    }
}

export default Overview;