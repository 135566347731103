import React from 'react';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import FortuneExpensePlanned from '../../model/FortuneExpensePlanned';
import FortuneExpensePlannedApi from '../../api/FortuneExpensePlannedApi';
import FortuneExpensePlannedUrlPath from '../FortuneExpensePlannedUrlPath';

import OverviewBase from '../../../../../commons/ui/page/template/overview/OverviewBase';

class Overview extends OverviewBase<FortuneExpensePlanned, FortuneExpensePlannedApi> {
    public constructor(props) {
        super(props,
              FortuneExpensePlannedApi.getInstance());

        this.setCreateUrl(FortuneExpensePlannedUrlPath.CREATE);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_GROUP}>
                        {I18nConstants.FORM_GROUP_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AMOUNT}>
                        {I18nConstants.FORM_AMOUNT_DEFAULT}
                    </Trans>
                </th>

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr> );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).group.name}
                </td>

                <td>
                    {(item as any).description}
                </td>

                <td>
                    {(item as any).amount}
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }
}

export default Overview;