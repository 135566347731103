import Utils from '../../../../utilities/Utils';
import ToDoList from '../../model/ToDoList';
import ToDoListGroup from '../../../group/model/ToDoListGroup';
import ToDoListApi from '../../api/ToDoListApi';
import ToDoListGroupApi from '../../../group/api/ToDoListGroupApi';
import ToDoListUrlPath from '../ToDoListUrlPath';
import UpdateBaseWithGroup from '../../../../commons/ui/page/template/update/UpdateBaseWithGroup';
import Form from './Form';
import FormButtonUpdate from '../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBaseWithGroup<ToDoList, ToDoListGroup, 
                                         ToDoListApi, ToDoListGroupApi> {
    private form: Form;
    
    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(props, 
              new ToDoList(),
              ToDoListApi.getInstance(),
              ToDoListGroupApi.getInstance(),
              idOfItemToBeUpdated,
              ToDoListUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;