import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "fortuneDashboard";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static JUST_ADD_TO_FORTUNE = "justAddToFortune";
    public static NOT_ADDED_TO_FORTUNE = "notAddedToFortune";
    public static EVERYTHING = "everything";

    public static GROUPED_BY_RISK_CLASSES_AND_GROUPS_TOTAL = "groupedByRiskClassesAndGroups.total";
    public static GROUPED_BY_RISK_CLASSES_AND_GROUPS_INVESTMENT_PLANS = "groupedByRiskClassesAndGroups.investmentPlans";
    public static GROUPED_BY_RISK_CLASSES_AND_GROUPS_SAVING_PLANS = "groupedByRiskClassesAndGroups.savingPlans";
    public static GROUPED_BY_RISK_CLASSES_AND_GROUPS_INVESTMENT_PLANS_YIELD = "groupedByRiskClassesAndGroups.investmentPlansYield";

    public static GROUPED_BY_INVESTMENT_PLAN_TITLE = "groupedByInvestmentPlan.title";

    public static GROUPED_BY_SAVING_PLAN_TITLE = "groupedBySavingPlan.title";

    public static MONTHLY_RATE_BY_INVESTMENT_PLAN_TITLE = "monthlyRateByInvestmentPlan.title";
    
    public static MONTHLY_RATE_BY_SAVING_PLAN_TITLE = "monthlyRateBySavingPlan.title";

    public static SAVING_RATIO_CURRENT_YEAR = "savingRatio.currentYear";
    public static SAVING_RATIO_TOTAL = "savingRatio.total";
    public static SAVING_RATIO_DIAGRAM_DATE = "savingRatio.diagram.date";
    public static SAVING_RATIO_DIAGRAM_PERCENTAGE = "savingRatio.diagram.percentage";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static JUST_ADD_TO_FORTUNE_DEFAULT = DefaultJson.justAddToFortune;
    public static NOT_ADDED_TO_FORTUNE_DEFAULT = DefaultJson.notAddedToFortune;
    public static EVERYTHING_DEFAULT = DefaultJson.everything;

    public static GROUPED_BY_RISK_CLASSES_AND_GROUPS_TOTAL_DEFAULT = DefaultJson.groupedByRiskClassesAndGroups.total;
    public static GROUPED_BY_RISK_CLASSES_AND_GROUPS_INVESTMENT_PLANS_DEFAULT = DefaultJson.groupedByRiskClassesAndGroups.investmentPlans;
    public static GROUPED_BY_RISK_CLASSES_AND_GROUPS_SAVING_PLANS_DEFAULT = DefaultJson.groupedByRiskClassesAndGroups.savingPlans;
    public static GROUPED_BY_RISK_CLASSES_AND_GROUPS_INVESTMENT_PLANS_YIELD_DEFAULT = DefaultJson.groupedByRiskClassesAndGroups.investmentPlansYield;

    public static GROUPED_BY_INVESTMENT_PLAN_TITLE_DEFAULT = DefaultJson.groupedByInvestmentPlan.title;

    public static GROUPED_BY_SAVING_PLAN_TITLE_DEFAULT = DefaultJson.groupedBySavingPlan.title;

    public static MONTHLY_RATE_BY_INVESTMENT_PLAN_TITLE_DEFAULT = DefaultJson.monthlyRateByInvestmentPlan.title;

    public static MONTHLY_RATE_BY_SAVING_PLAN_TITLE_DEFAULT = DefaultJson.monthlyRateBySavingPlan.title;

    public static SAVING_RATIO_CURRENT_YEAR_DEFAULT = DefaultJson.savingRatio.currentYear;
    public static SAVING_RATIO_TOTAL_DEFAULT = DefaultJson.savingRatio.total;
    public static SAVING_RATIO_DIAGRAM_DATE_DEFAULT = DefaultJson.savingRatio.diagram.date;
    public static SAVING_RATIO_DIAGRAM_PERCENTAGE_DEFAULT = DefaultJson.savingRatio.diagram.percentage;
}

export default I18nConstants;