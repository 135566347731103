import React from 'react';
import { FormGroup, Button } from 'reactstrap';
import { Trans } from 'react-i18next';
import I18nConstants from '../../../../../i18n/I18nConstantsBase';

class FormButtonBase {
    protected static renderByI18nKeyAndDefaultValue(i18nKey, defaultValue) {
        return (
            <FormGroup>
                <Button color="primary" type="submit">
                    <Trans ns={I18nConstants.NS_BASE}
                           i18nKey={i18nKey}>
                        {defaultValue}
                    </Trans>
                </Button>
            </FormGroup> 
        );
    }
}

export default FormButtonBase;