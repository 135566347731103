import IModelMapper from '../../../../commons/model/IModelMapper';

import Vehicle from './Vehicle';

class VehicleModelMapper implements IModelMapper<Vehicle> {
    fromJsonToObject(json: any): Vehicle {
        let vehicle = new Vehicle();

        vehicle.id                          = json.id;
        vehicle.vehicleType                 = json.vehicleType;
        vehicle.brand                       = json.brand;
        vehicle.model                       = json.model;
        vehicle.description                 = json.description;
        vehicle.vehicleIdentificationNumber = json.vehicleIdentificationNumber;
        vehicle.kindOfFuel                  = json.kindOfFuel;
        vehicle.fuelUnit                    = json.fuelUnit;

        return vehicle;
    }

    fromObjectToJson(object: Vehicle) {
        return {
            id:                          object.id,
            vehicleType:                 (object.vehicleType || undefined),
            brand:                       (object.brand || ""),
            model:                       (object.model || ""),
            description:                 (object.description || ""),
            vehicleIdentificationNumber: (object.vehicleIdentificationNumber || ""),
            kindOfFuel:                  (object.kindOfFuel || undefined),
            fuelUnit:                    (object.fuelUnit || undefined)
        };
    }
}

export default VehicleModelMapper;