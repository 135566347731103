import React from 'react';
import { Component } from 'react';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:   boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    // TODO
}

class UpdateAll extends Component<IProps, IState> {

}

export default UpdateAll;