import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupInvestmentAmount extends FormGroupBase {
    private ID_AND_NAME = "investmentAmount";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_INVESTMENT_AMOUNT,
                    I18nConstants.FORM_INVESTMENT_AMOUNT_DEFAULT) }

                <Input 
                    type="text" 
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.investmentAmount}
                    onChange={this.pointerToComponent.handleChange} />
            </FormGroup> );
    }

    public isInvalidValue(): boolean {
        // '0'-investment amounts must be possible, because just adding the current quotation 
        // has no investment amount.
        if (parseInt(this.pointerToComponent.state.Item.investmentAmount) >= 0) {
            return false;
        } else {
            return true;
        }
    }
}

export default FormGroupInvestmentAmount;