import React from 'react';
import { Component } from 'react';
import { Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneSavingPlanApiCallExecutor from '../../../savingPlan/item/api/FortuneSavingPlanApiCallExecutor';
import Utils from '../../../../utilities/Utils';
import FortuneSavingPlanMonthlyRateBySavingPlan from '../../../savingPlan/item/model/helper/FortuneSavingPlanMonthlyRateBySavingPlan';
import AuthenticationApi from '../../../../security/api/AuthenticationApi';
import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';
import LoginUrlPath from '../../../../login/ui/LoginUrlPath';
import PieChartWithSumAndDiagram from '../../../../commons/ui/page/diagram/PieChartWithSumAndPercentage';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    MonthlyRateBySavingPlan: FortuneSavingPlanMonthlyRateBySavingPlan[];
    diagramData: [];
    sum: number;
}

class FortuneSavingPlanMonthlyRateBySavingPlanOverview extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;

    private diagram: PieChartWithSumAndDiagram;

    public constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            MonthlyRateBySavingPlan: [],
            diagramData: [],
            sum: 0.0
        };

        this.diagram = new PieChartWithSumAndDiagram();

        this.apiCalls = new ApiCallExecutor(this, this.diagram);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            } else {
                return (
                    <div>
                        { this.getHeading() }

                        { this.getSum() }

                        { this.getTable() }

                        { this.getDiagramDataElement() }
                    </div>
                );
            }
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return (
            <h3>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.MONTHLY_RATE_BY_SAVING_PLAN_TITLE}>
                    {I18nConstants.MONTHLY_RATE_BY_SAVING_PLAN_TITLE_DEFAULT}
                </Trans>
            </h3>
        );
    }

    private getSum() {
        return (
            <p>
                <Trans ns={I18nConstants.NS_BASE}
                       i18nKey={I18nConstants.GENERAL_SUM}>
                    {I18nConstants.GENERAL_SUM_DEFAULT}
                </Trans>
                : {this.state.sum}
            </p>
        );
    }

    private getTable() {
        return (
            <Table>
                <tbody>
                    { this.getTableBody() }
                </tbody>
            </Table>
        );
    }

    private getTableBody() {
        return this.state.MonthlyRateBySavingPlan.map( item =>
            <tr>
                <td>
                    {item.name}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.monthlyRate)}
                </td>
            </tr> );
    }

    private getDiagramDataElement() {
        return this.diagram.getHtmlElement(this.state.diagramData);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: FortuneSavingPlanMonthlyRateBySavingPlanOverview;
    private pointerToDiagram: PieChartWithSumAndDiagram;

    private helper: ApiCallExecutorHelper;

    private fortuneSavingPlanApiCallExecutor:
                FortuneSavingPlanApiCallExecutor<FortuneSavingPlanMonthlyRateBySavingPlanOverview>;
    
    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneSavingPlanApiCallExecutor =
                new FortuneSavingPlanApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();
    
        orderOfCalls.push(this.addMonthlyRateBySavingPlan());
        orderOfCalls.push(this.addMonthlyRateBySavingPlanToParameters());
        orderOfCalls.push(this.addSumMonthlyRateBySavingPlan());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addMonthlyRateBySavingPlan(): IApiCallback {
        return {
            callback: this.fortuneSavingPlanApiCallExecutor.getMonthlyRateBySavingPlan,
            pointerToApiCall: this.fortuneSavingPlanApiCallExecutor,
            variableNameInState: "MonthlyRateBySavingPlan"
        };
    }
    
    private addMonthlyRateBySavingPlanToParameters(): IApiCallback {
        return {
            callback: this.fortuneSavingPlanApiCallExecutor.setMonthlyRateBySavingPlanToParameters,
            pointerToApiCall: this.fortuneSavingPlanApiCallExecutor,
            variableNameInState: "listDataForDiagram"
        };
    }

    private addSumMonthlyRateBySavingPlan(): IApiCallback {
        return {
            callback: this.fortuneSavingPlanApiCallExecutor.getSumMonthlyRateBySavingPlan,
            pointerToApiCall: this.fortuneSavingPlanApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addDiagramData(): IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagramData"
        };
    }
}

export default FortuneSavingPlanMonthlyRateBySavingPlanOverview;