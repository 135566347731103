import axios from "axios";
import EnvironmentVariables from "../config/EnvironmentVariables";
import AuthHeader from "../../security/api/AuthHeader";

class GenericApi {
    public constructor() {
        throw new Error('Constructor can\'t be called, because it\'s a singleton!');
    }

    public static executeGetAllCall(endpoint, mapper) {
        endpoint = this.removeFirstSlashIfNecessary(endpoint);
        
        return new Promise((resolve, reject) => {
            axios
            .get(EnvironmentVariables.getBackendApiUrl() + endpoint,
                 { headers: AuthHeader.get() })
            .then(response => {
                        if (mapper) {
                            GenericApi.createResponseWithArray(response, mapper, resolve); 
                        // If no mapper is provided, the response-data should just be returned.
                        } else {
                            if (GenericApi.isValidStatusCode(response.status)) {
                                resolve(response.data);
                            } else {
                                console.log("TODO");
                            }
                        }
                   })
            .catch(error => {
                        GenericApi.createErrorMessage(error.response, reject); 
                   });
        });
    }

    public static executeGetCallWithFilter(endpoint, filterAsString, mapper) {
        endpoint = this.removeFirstSlashIfNecessary(endpoint);
        
        return new Promise((resolve, reject) => {
            axios
            .get(EnvironmentVariables.getBackendApiUrl() + endpoint + "?" + filterAsString,
                 { headers: AuthHeader.get() })
            .then(response => {
                        if (mapper) {
                            GenericApi.createResponseWithArray(response, mapper, resolve);
                        // If no mapper is provided, the response-data should just be returned.
                        } else {
                            if (GenericApi.isValidStatusCode(response.status)) {
                                resolve(response.data);
                            } else {
                                console.log("TODO");
                            }
                        }                        
                  })
            .catch(error => {
                        GenericApi.createErrorMessage(error.response, reject);
                   });
        });
    }

    public static executeGetCall(endpoint, id, mapper) {
        endpoint = this.removeFirstSlashIfNecessary(endpoint);
        
        return new Promise((resolve, reject) => {
            axios
            .get(EnvironmentVariables.getBackendApiUrl() + endpoint + "/" + id,
                 { headers: AuthHeader.get() })
            .then(response => {
                        if (mapper) {
                            GenericApi.createResponse(response, mapper, resolve);
                        // If no mapper is provided, the response-data should just be returned.
                        } else {
                            if (GenericApi.isValidStatusCode(response.status)) {
                                resolve(response.data);
                            } else {
                                console.log("TODO");
                            }
                        }
                   })
            .catch(error => {
                        GenericApi.createErrorMessage(error.response, reject); 
                   });
        });
    }

    public static executeGetCallWithoutId(endpoint, mapper) {
        endpoint = this.removeFirstSlashIfNecessary(endpoint);
        
        return new Promise((resolve, reject) => {
            axios
            .get(EnvironmentVariables.getBackendApiUrl() + endpoint,
                 { headers: AuthHeader.get() })
            .then(response => {
                        if (mapper) {
                            GenericApi.createResponse(response, mapper, resolve);
                        // If no mapper is provided, the response-data should just be returned.
                        } else {
                            if (GenericApi.isValidStatusCode(response.status)) {
                                resolve(response.data);
                            } else {
                                console.log("TODO");
                            }
                        }
                   })
            .catch(error => {
                        GenericApi.createErrorMessage(error.response, reject); 
                   });
        });
    }

    public static executePostCall(endpoint, body, mapper, withAuthHeader = true) {
        endpoint = this.removeFirstSlashIfNecessary(endpoint);
        
        if (withAuthHeader) {
            return this._executePostCallWithAuthHeader(endpoint, body, mapper);
        } else {
            return this._executePostCallWithoutAuthHeader(endpoint, body, mapper);
        }
    }

    public static executePostCallAsBlob(endpoint, body, mapper, withAuthHeader = true) {
        endpoint = this.removeFirstSlashIfNecessary(endpoint);
        
        if (withAuthHeader) {
            return this._executePostCallWithAuthHeader(endpoint, body, mapper, true);
        } else {
            return this._executePostCallWithoutAuthHeader(endpoint, body, mapper, true);
        }
    }

    private static _executePostCallWithAuthHeader(endpoint, body, mapper, blob = false) {
        let axiosConfig;

        if (blob) {
            axiosConfig = { headers: AuthHeader.get(), 
                                responseType: 'blob' };
        } else {
            axiosConfig = { headers: AuthHeader.get() };
        }

        return new Promise((resolve, reject) => {
            axios
            .post(EnvironmentVariables.getBackendApiUrl() + endpoint,
                  body,
                  axiosConfig)
            .then(response => {
                        if (mapper) {
                            GenericApi.createResponse(response, mapper, resolve);
                        // If no mapper is provided, the response-data should just be returned.
                        } else {
                            if (GenericApi.isValidStatusCode(response.status)) {
                                resolve(response.data);
                            } else {
                                console.log("TODO");
                            }
                        }
                   })
            .catch(error => {
                        GenericApi.createErrorMessage(error.response, reject);
                   });
        });
    }

    private static _executePostCallWithoutAuthHeader(endpoint, body, mapper, blob = false) {
        let axiosConfig;

        if (blob) {
            axiosConfig = { responseType: 'blob' };
        } else {
            axiosConfig = { };
        }

        return new Promise((resolve, reject) => {
            axios
            .post(EnvironmentVariables.getBackendApiUrl() + endpoint,
                  body,
                  axiosConfig)
            .then(response => {
                        if (mapper) {
                            GenericApi.createResponse(response, mapper, resolve, false);
                        // If no mapper is provided, the response-data should just be returned.
                        } else {
                            if (GenericApi.isValidStatusCode(response.status)) {
                                resolve(response.data);
                            } else {
                                console.log("TODO");
                            }
                        }
                        
                   })
            .catch(error => {
                        GenericApi.createErrorMessage(error.response, reject);
                   });
        });
    }

    public static executePutCall(endpoint, id, body, mapper) {
        endpoint = this.removeFirstSlashIfNecessary(endpoint);
        
        return new Promise((resolve, reject) => {
            axios
            .put(EnvironmentVariables.getBackendApiUrl() + endpoint + "/" + id,
                 body,
                 { headers: AuthHeader.get() })
            .then(response => {
                        GenericApi.createResponse(response, mapper, resolve);
                   })
            .catch(error => {
                        GenericApi.createErrorMessage(error.response, reject);
                   });
        });
    }

    public static executeDeleteCall(endpoint, id, mapper) {
        endpoint = this.removeFirstSlashIfNecessary(endpoint);
        
        return new Promise((resolve, reject) => {
            axios
            .delete(EnvironmentVariables.getBackendApiUrl() + endpoint + "/" + id,
                    { headers: AuthHeader.get() })
            .then(response => {
                        GenericApi.createResponse(response, mapper, resolve);
                   })
            .catch(error => {
                        GenericApi.createErrorMessage(error.response, reject);
                   });
        });
    }

    // --------------------------------------------------------------------------------------------
    // Helpers
    // --------------------------------------------------------------------------------------------

    public static isValidStatusCode(statusCode) {
        statusCode = parseInt(statusCode);

        if (    (statusCode === 200)
             || (statusCode === 201)
             || (statusCode === 202)
             || (statusCode === 203)
             || (statusCode === 204)
             || (statusCode === 205)
             || (statusCode === 206)) {
            return true;
        } else {
            return false;
        }
    }

    private static createResponse(response, mapper, resolve, withAuthHeader = true) {
        if (GenericApi.isValidStatusCode(response.status)) {
            if (withAuthHeader) {
                resolve(mapper.fromJsonToObject(response.data));
            } else {
                // For a request without auth-headers, the whole response
                // will be given to the caller.
                resolve(response);
            }
        } else {
            resolve(response.data);
        }
    }

    private static createResponseWithArray(response, mapper, resolve) {
        if (GenericApi.isValidStatusCode(response.status)) {
            let objects : any = [];
            
            response.data.forEach((item) => {
                objects.push(mapper.fromJsonToObject(item));
            });
            
            resolve(objects);
        } else {
            resolve(response.data);
        }
    }

    public static createErrorMessage(response, reject) {
        let errorMessage = "";

        if (response.data.message) {
            errorMessage = response.data.message;
        } else {
            // TODO
            // Distinguish between status-codes (e. g. 404)
            // and print user-readable message!
            errorMessage = response.status;
        }

        reject(errorMessage);
    }  
    
    private static removeFirstSlashIfNecessary(endpoint) {
        if (endpoint.startsWith("/")) {
            endpoint = endpoint.substring(1, endpoint.length);
        }

        return endpoint;
    }
}

export default GenericApi;