import UpdateBase from '../../../../../commons/ui/page/template/group/update/UpdateBase';

import KindOfFuel from '../../model/KindOfFuel';
import KindOfFuelApi from '../../api/KindOfFuelApi';
import KindOfFuelUrlPath from '../KindOfFuelUrlPath';

class Update extends UpdateBase<KindOfFuel, KindOfFuelApi> {
    public constructor(props) {
        super(props,
              new KindOfFuel(),
              KindOfFuelApi.getInstance(),
              KindOfFuelUrlPath.BASE);
    }
}

export default Update;