import Utils from '../../../../../utilities/Utils';
import LetterPersonSalutationApi from '../../api/LetterPersonSalutationApi';
import LetterPersonSalutation from '../../model/LetterPersonSalutation';
import LetterPersonSalutationUrlPath from '../LetterPersonSalutationUrlPath';
import UpdateBase from '../../../../../commons/ui/page/template/update/UpdateBase';
import Form from './Form';
import FormButtonUpdate from '../../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBase<LetterPersonSalutation, LetterPersonSalutationApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new LetterPersonSalutation(),
              LetterPersonSalutationApi.getInstance(),
              idOfItemToBeUpdated,
              LetterPersonSalutationUrlPath.BASE
        );

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;