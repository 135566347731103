import React from 'react';
import { Component } from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import FuelConsumptionApi from '../../api/FuelConsumptionApi';
import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import VehicleApiCallExecutor from '../../../vehicle/item/api/VehicleApiCallExecutor';
import FuelConsumptionApiCallExecutor from '../../api/FuelConsumptionApiCallExecutor';
import Utils from '../../../../utilities/Utils';
import FuelConsumption from '../../model/FuelConsumption';
import FuelConsumptionUrlPath from '../FuelConsumptionUrlPath';
import Vehicle from '../../../vehicle/item/model/Vehicle';
import OverviewUtils from '../../../../commons/ui/page/template/overview/OverviewUtils';
import FuelConsumptionVehicleActionBarButton from '../../../vehicle/item/ui/fragment/ActionBarButton';
import FuelConsumptionTireActionBarButton from '../../../tire/ui/fragment/ActionBarButton';
import FuelConsumptionKindOfSteetActionBarButton from '../../../kindOfStreet/ui/fragment/ActionBarButton';
import FuelConsumptionDrivingStyleActionBarButton from '../../../drivingStyle/ui/fragment/ActionBarButton';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:                 boolean;
    fuelConsumptions:          FuelConsumption[];
    vehicles:                  Vehicle[];
    currentVehicleSelectionId: number;
}

class Overview extends Component<IProps, IState> {
    private api: FuelConsumptionApi;

    private apiCalls: ApiCallExecutor;

    private overviewUtils: OverviewUtils;

    private createUrl: string;
    private updateUrl: string;

    private vehicleActionBarButton: FuelConsumptionVehicleActionBarButton;
    private tireActionBarButton: FuelConsumptionTireActionBarButton;
    private kindOfSteetActionBarButton: FuelConsumptionKindOfSteetActionBarButton;
    private drivingStyleActionBarButton: FuelConsumptionDrivingStyleActionBarButton;

    public constructor(props) {
        super(props);

        this.api = FuelConsumptionApi.getInstance();

        this.apiCalls = new ApiCallExecutor(this);

        this.createUrl = FuelConsumptionUrlPath.CREATE;
        this.updateUrl = FuelConsumptionUrlPath.UPDATE;

        this.vehicleActionBarButton = new FuelConsumptionVehicleActionBarButton(props.history);
        this.tireActionBarButton = new FuelConsumptionTireActionBarButton(props.history);
        this.kindOfSteetActionBarButton = new FuelConsumptionKindOfSteetActionBarButton(props.history);
        this.drivingStyleActionBarButton = new FuelConsumptionDrivingStyleActionBarButton(props.history);

        this.state = {
            isLoading:                 true,
            fuelConsumptions:          [],
            vehicles:                  [],
            currentVehicleSelectionId: 0
        };

        this.overviewUtils = new OverviewUtils(
            this,
            this.getHeading(),
            this.createUrl,
            this.updateUrl,
            undefined,
            true,
            this.getActionBar()
        );

        this.handleChange = this.handleChange.bind(this);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (this.state.isLoading) {
            return LoadingScreen.render();
        } else {
            return (
                <div>
                    { this.overviewUtils.render(
                        this.getTableHead(),
                        this.getTableBody(),
                        this.getSelectionField()) }
                </div>
            );
        }
    }

    private getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE}>
                    {I18nConstants.TITLE_DEFAULT}
                </Trans>
            </h1> );
    }

    private getSelectionField() {
        let selectValues = this.getGroupSelectValues();

        return (
            <div>
                <Input
                    type="select"
                    name="vehicles"
                    id="vehicles"
                    value={this.state.currentVehicleSelectionId}
                    onChange={this.handleChange}>
                    {selectValues}
                </Input>
            </div>
        );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.CONSUMPTION_PER_100_KM}>
                        {I18nConstants.CONSUMPTION_PER_100_KM_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_ODOMETER_MILEAGE}>
                        {I18nConstants.FORM_ODOMETER_MILEAGE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DRIVEN_KM}>
                        {I18nConstants.FORM_DRIVEN_KM_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_MILEAGE}>
                        {I18nConstants.FORM_MILEAGE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_EXPENSE}>
                        {I18nConstants.FORM_EXPENSE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_PRICE_PER_UNIT}>
                        {I18nConstants.FORM_PRICE_PER_UNIT_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DATE}>
                        {I18nConstants.FORM_DATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AIR_CONDITIONING_USED}>
                        {I18nConstants.FORM_AIR_CONDITIONING_USED_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_TIRE}>
                        {I18nConstants.FORM_TIRE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_KIND_OF_STREET}>
                        {I18nConstants.FORM_KIND_OF_STREET_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DRIVING_STYLE}>
                        {I18nConstants.FORM_DRIVING_STYLE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_NOTE}>
                        {I18nConstants.FORM_NOTE_DEFAULT}
                    </Trans>
                </th>

                { this.overviewUtils.getUpdateHeadingWrappedInThTag() }
                { this.overviewUtils.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    protected getTableBody() {
        return this.state.fuelConsumptions.map( item => 
            <tr key={item.id}>
                <td>
                    {Utils.roundTwoDecimalPlaces(item.mileage * 100 / item.drivenKm)}
                </td>
                
                <td>
                    {item.odometerMileage}
                </td>

                <td>
                    {item.drivenKm}
                </td>

                <td>
                    {item.mileage}
                </td>

                <td>
                    {item.expense}
                </td>

                <td>
                    {item.pricePerUnit}
                </td>

                <td>
                    {item.date}
                </td>

                <td id="checkbox">
                    <FormGroup check>
                        <Label check>
                            <Input 
                                type="checkbox"
                                checked={item.airConditioningUsed}
                                disabled="true" />
                        </Label>
                    </FormGroup>
                </td>

                <td>
                    {item.tire.name}
                </td>

                <td>
                    {item.kindOfStreet.name}
                </td>

                <td>
                    {item.drivingStyle.name}
                </td>

                <td>
                    {item.note}
                </td>

                { this.overviewUtils.getUpdateButtonWrappedInTdTag(item.id) }
                { this.overviewUtils.getDeleteButtonWrappedInTdTag(item.id) }
            </tr>
            );
    }

    // --------------------------------------------------------------------------------------------

    private getGroupSelectValues() {
        return this.state.vehicles.map( element => 
            <option value={element.id}>
                {element.brand}&nbsp;
                {element.model}&nbsp;
                ({element.vehicleIdentificationNumber})
            </option>
        );
    }

    // --------------------------------------------------------------------------------------------

    private handleChange(event) {
        const target = event.target;

        let vehicleSelectionId = target.value;

        this.apiCalls.executeStartingAfterVehicleSelection(vehicleSelectionId);
    }

    // --------------------------------------------------------------------------------------------

    private getActionBar() {
        return (
            <div>
                { this.vehicleActionBarButton.render() }
                { this.tireActionBarButton.render() }
                { this.kindOfSteetActionBarButton.render() }
                { this.drivingStyleActionBarButton.render() }
            </div>
        );
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private helper: ApiCallExecutorHelper;

    private vehicleApiCallExecutor:
                VehicleApiCallExecutor<Overview>;
    private fuelConsumptionApiCallExecutor:
                FuelConsumptionApiCallExecutor<Overview>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.vehicleApiCallExecutor = new VehicleApiCallExecutor(pointerToComponent);
        this.fuelConsumptionApiCallExecutor = new FuelConsumptionApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addVehicleGetAll());
        orderOfCalls.push(this.addVehicleIdFromParametersToState());
        orderOfCalls.push(this.addFuelConsumptionGetAllByVehicleId());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    public executeStartingAfterVehicleSelection(vehicleId) {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addVehicleIdFromParametersToState());
        orderOfCalls.push(this.addFuelConsumptionGetAllByVehicleId());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "vehicleId", vehicleId);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addVehicleGetAll() : IApiCallback {
        return {
            callback: this.vehicleApiCallExecutor.getAll,
            pointerToApiCall: this.vehicleApiCallExecutor,
            variableNameInState: "vehicles"
        }
    }

    private addVehicleIdFromParametersToState() : IApiCallback {
        return {
            callback: this.vehicleApiCallExecutor.setVehicleIdFromParametersToState,
            pointerToApiCall: this.vehicleApiCallExecutor,
            variableNameInState: "currentVehicleSelectionId"
        };
    }

    private addFuelConsumptionGetAllByVehicleId() : IApiCallback {
        return {
            callback: this.fuelConsumptionApiCallExecutor.getAllByVehicleId,
            pointerToApiCall: this.fuelConsumptionApiCallExecutor,
            variableNameInState: "fuelConsumptions"
        };
    }
}

export default Overview;