import ExpenseGroup from '../../model/ExpenseGroup';
import ExpenseGroupApi from '../../api/ExpenseGroupApi';
import ExpenseGroupUrlPath from '../../ui/EarningsExpensesExpenseGroupUrlPath';

import CreateBase from '../../../../../commons/ui/page/template/group/create/CreateBase';

class Create extends CreateBase<ExpenseGroup, ExpenseGroupApi> {
    public constructor(props) {
        super(props,
              new ExpenseGroup(),
              ExpenseGroupApi.getInstance(),
              ExpenseGroupUrlPath.BASE);
    }
}

export default Create;