import CreateBase from "../../../../commons/ui/page/template/create/CreateBase";
import LetterTemplateApi from '../../api/LetterTemplateApi';
import LetterTemplate from '../../model/LetterTemplate';
import LetterTemplateUrlPath from '../LetterTemplateUrlPath';
import Form from './Form';
import FormButtonCreate from '../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBase<LetterTemplate, LetterTemplateApi> {
    private form: Form;

    public constructor(props) {
        super(props,
              new LetterTemplate(),
              LetterTemplateApi.getInstance(),
              LetterTemplateUrlPath.BASE
        );

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;