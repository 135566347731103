import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import FortuneExpensePlannedGroupModelMapper from '../model/FortuneExpensePlannedGroupModelMapper';

class FortuneExpensePlannedGroupApi implements IGenericApi {
    private static instance: FortuneExpensePlannedGroupApi;

    private static apiUrlPath = "fortune/expensePlanned/group";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): FortuneExpensePlannedGroupApi {
        if (!FortuneExpensePlannedGroupApi.instance) {
            FortuneExpensePlannedGroupApi.instance = new FortuneExpensePlannedGroupApi();
        }

        return FortuneExpensePlannedGroupApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    FortuneExpensePlannedGroupApi.apiUrlPath,
                    new FortuneExpensePlannedGroupModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    FortuneExpensePlannedGroupApi.apiUrlPath,
                    id,
                    new FortuneExpensePlannedGroupModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    FortuneExpensePlannedGroupApi.apiUrlPath,
                    body,
                    new FortuneExpensePlannedGroupModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    FortuneExpensePlannedGroupApi.apiUrlPath,
                    id,
                    body,
                    new FortuneExpensePlannedGroupModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    FortuneExpensePlannedGroupApi.apiUrlPath,
                    id,
                    new FortuneExpensePlannedGroupModelMapper());
    }

    public getUrl() {
        return FortuneExpensePlannedGroupApi.apiUrlPath;
    }
}

export default FortuneExpensePlannedGroupApi;