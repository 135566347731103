import BankingListGroup from '../../model/BankingListGroup';
import BankingListGroupApi from '../../api/BankingListGroupApi';
import BankingListGroupUrlPath from '../../ui/BankingListGroupUrlPath';
import UpdateBase from '../../../../commons/ui/page/template/group/update/UpdateBase';

class Update extends UpdateBase<BankingListGroup, BankingListGroupApi> {
    public constructor(props) {
        super(props,
              new BankingListGroup(),
              BankingListGroupApi.getInstance(),
              BankingListGroupUrlPath.BASE
        );
    }
}

export default Update;