import FortuneExpensePlanned from '../../model/FortuneExpensePlanned';
import FortuneExpensePlannedGroup from '../../../group/model/FortuneExpensePlannedGroup';
import FortuneExpensePlannedUrlPath from '../FortuneExpensePlannedUrlPath';
import FortuneExpensePlannedApi from '../../api/FortuneExpensePlannedApi';
import FortuneExpensePlannedGroupApi from '../../../group/api/FortuneExpensePlannedGroupApi';
import CreateBaseWithGroup from '../../../../../commons/ui/page/template/create/CreateBaseWithGroup';
import Form from './Form';
import FormButtonCreate from '../../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBaseWithGroup<FortuneExpensePlanned, 
                                         FortuneExpensePlannedGroup,
                                         FortuneExpensePlannedApi,
                                         FortuneExpensePlannedGroupApi> {
    private form: Form;

    public constructor(props) {
        super(props,
              new FortuneExpensePlanned(),
              FortuneExpensePlannedApi.getInstance(),
              FortuneExpensePlannedGroupApi.getInstance(),
              FortuneExpensePlannedUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;