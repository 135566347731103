import CreateBase from "../../../../../commons/ui/page/template/create/CreateBase";
import LetterPersonSalutationApi from "../../api/LetterPersonSalutationApi";
import LetterPersonSalutation from "../../model/LetterPersonSalutation";
import LetterPersonSalutationUrlPath from "../LetterPersonSalutationUrlPath";
import Form from './Form';
import FormButtonCreate from '../../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBase<LetterPersonSalutation, LetterPersonSalutationApi> {
    private form: Form;

    public constructor(props) {
        super(props,
              new LetterPersonSalutation(),
              LetterPersonSalutationApi.getInstance(),
              LetterPersonSalutationUrlPath.BASE
        );

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;