import CreateBase from '../../../../commons/ui/page/template/group/create/CreateBase';

import Tire from '../../model/Tire';
import TireApi from '../../api/TireApi';
import TireUrlPath from '../TireUrlPath';

class Create extends CreateBase<Tire, TireApi> {
    public constructor(props) {
        super(props,
              new Tire(),
              TireApi.getInstance(),
              TireUrlPath.BASE);
    }
}

export default Create;