import ExpenseGroup from '../../model/ExpenseGroup';
import ExpenseGroupApi from '../../api/ExpenseGroupApi';

import OverviewBase from '../../../../../commons/ui/page/template/group/overview/OverviewBase';

class Overview extends OverviewBase<ExpenseGroup, ExpenseGroupApi> {
    public constructor(props) {
        super(props,
              ExpenseGroupApi.getInstance());
    }
}

export default Overview;