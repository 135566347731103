import ApiCallExecutorBase from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';
import ExpenseGroupApi from './ExpenseGroupApi';

class ExpenseGroupApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private expenseGroupApi: ExpenseGroupApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.expenseGroupApi = ExpenseGroupApi.getInstance();
    }

    public getSumAsListByMonthAndYear(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const monthAndYearObject = (context.pointerToHelper.getParameterInArrayWithObjects(
                                        context.parameters, "monthAndYearObject") as any);
 
        context.currentData.pointerToApiCall.expenseGroupApi.getSumAsListByMonthAndYear(
                                                                monthAndYearObject.month,
                                                                monthAndYearObject.year)
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                        context.currentData.variableNameInState,
                        (response as any));

                    context.pointerToComponent.setState(state);
                    
                    context.pointerToHelper.addParameterToContext(context, 
                        "listDataForDiagram", context.pointerToComponent.state[
                                                context.currentData.variableNameInState]);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }

    public getSumAsListByYear(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const year = context.pointerToHelper.getParameterInArrayWithObjects(
                                                context.parameters, "year");

        context.currentData.pointerToApiCall.expenseGroupApi.getSumAsListByYear(year)
        .then( response => { 
                    const state = context.pointerToHelper.createStateObject(
                        context.currentData.variableNameInState,
                        (response as any));

                    context.pointerToComponent.setState(state);

                    context.pointerToHelper.addParameterToContext(context, 
                        "listDataForDiagram", context.pointerToComponent.state[
                                                context.currentData.variableNameInState]);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
               })
        .catch( error   => {
                    console.log("TODO");
                });
    }
}

export default ExpenseGroupApiCallExecutor;