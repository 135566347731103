import React from 'react';
import FormGroupGroupDropdown from '../fragment/FormGroupGroupDropdown';
import FormGroupCompany from '../fragment/FormGroupCompany';
import FormGroupName from '../fragment/FormGroupName';
import FormGroupNumber from '../fragment/FormGroupNumber';
import FormGroupDateOfBegin from '../fragment/FormGroupDateOfBegin';
import FormGroupDueDateYearly from '../fragment/FormGroupDueDateYearly';
import FormGroupAmountYearly from '../fragment/FormGroupAmountYearly';
import FormGroupPaymentIntervalDropdown from '../fragment/FormGroupPaymentIntervalDropdown';
import FormGroupDescription from '../fragment/FormGroupDescription';

class Form {
    private formGroupGroup: FormGroupGroupDropdown;
    private formGroupCompany: FormGroupCompany;
    private formGroupName: FormGroupName;
    private formGroupNumber: FormGroupNumber;
    private formGroupDateOfBegin: FormGroupDateOfBegin;
    private formGroupDueDateYearly: FormGroupDueDateYearly;
    private formGroupAmountYearly: FormGroupAmountYearly;
    private formGroupPaymentInterval: FormGroupPaymentIntervalDropdown;
    private formGroupDescription: FormGroupDescription;

    public constructor(props, pointerToComponent) {
        this.formGroupGroup = new FormGroupGroupDropdown(props, pointerToComponent);
        this.formGroupCompany = new FormGroupCompany(props, pointerToComponent);
        this.formGroupName = new FormGroupName(props, pointerToComponent);
        this.formGroupNumber = new FormGroupNumber(props, pointerToComponent);
        this.formGroupDateOfBegin = new FormGroupDateOfBegin(props, pointerToComponent);
        this.formGroupDueDateYearly = new FormGroupDueDateYearly(props, pointerToComponent);
        this.formGroupAmountYearly = new FormGroupAmountYearly(props, pointerToComponent);
        this.formGroupPaymentInterval = new FormGroupPaymentIntervalDropdown(props, pointerToComponent);
        this.formGroupDescription = new FormGroupDescription(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupGroup.render() }
                { this.formGroupCompany.render() }
                { this.formGroupName.render() }
                { this.formGroupNumber.render() }
                { this.formGroupDateOfBegin.render() }
                { this.formGroupDueDateYearly.render() }
                { this.formGroupAmountYearly.render() }
                { this.formGroupPaymentInterval.render() }
                { this.formGroupDescription.render() }

                { renderButtonCallback() }
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupGroup.isInvalidValue()
             || this.formGroupCompany.isInvalidValue()
             || this.formGroupName.isInvalidValue()
             || this.formGroupNumber.isInvalidValue()
             || this.formGroupDateOfBegin.isInvalidValue()
             || this.formGroupDueDateYearly.isInvalidValue()
             || this.formGroupAmountYearly.isInvalidValue()
             || this.formGroupPaymentInterval.isInvalidValue()
             || this.formGroupDescription.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;