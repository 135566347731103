import I18nConstants from '../../../../../i18n/I18nConstants';
import OverviewButtonBase from "./OverviewButtonBase";

class OverviewButtonShowDetails extends OverviewButtonBase {
    public static render(pointerToComponentOrUtil, callback, callbackId: number = -1) {
        return OverviewButtonBase.render(
                    I18nConstants.ACTION_SHOW_DETAILS,
                    I18nConstants.ACTION_SHOW_DETAILS_DEFAULT,
                    pointerToComponentOrUtil,
                    callback,
                    callbackId
        );
    }
}

export default OverviewButtonShowDetails;