import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortunePriorityApiCallExecutor from '../../../priority/api/FortunePriorityApiCallExecutor';
import FortuneInvestmentPlanApiCallExecutor from '../../api/FortuneInvestmentPlanApiCallExecutor';

import Utils from '../../../../utilities/Utils';

import FortuneInvestmentPlan from '../../model/FortuneInvestmentPlan';
import FortuneInvestmentPlanApi from '../../api/FortuneInvestmentPlanApi';
import FortuneInvestmentPlanUrlPath from '../FortuneInvestmentPlanUrlPath';

import FortunePriority from '../../../priority/model/FortunePriority';

import UpdateUtils from '../../../../commons/ui/page/template/update/UpdateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:   boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item:        FortuneInvestmentPlan;
    Priorities:  FortunePriority[];
}

class Update extends Component<IProps, IState> {
    private apiCalls:            ApiCallExecutor;
    private api:             FortuneInvestmentPlanApi;
    private form:                Form;
    private updateUtils:         UpdateUtils;

    private idOfItemToBeUpdated: number;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = FortuneInvestmentPlanApi.getInstance();

        this.idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        this.state = {
            isLoading:   true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item:        new FortuneInvestmentPlan(),
            Priorities:  []
        };
        
        this.form = new Form(props, this);

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            FortuneInvestmentPlanUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1> );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.updateUtils.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------

    public getIdToBeUpdated() : number {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private fortunePriorityApiCallExecutor: FortunePriorityApiCallExecutor<Update>;
    private fortuneInvestmentPlanApiCallExecutor: FortuneInvestmentPlanApiCallExecutor<Update>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.fortunePriorityApiCallExecutor = new FortunePriorityApiCallExecutor(pointerToComponent);
        this.fortuneInvestmentPlanApiCallExecutor = 
                new FortuneInvestmentPlanApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneInvestmentPlanGetById());
        orderOfCalls.push(this.addFortunePriorityGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "fortuneInvestmentPlanId", 
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortunePriorityGetAll() : IApiCallback {
        return {
            callback: this.fortunePriorityApiCallExecutor.getAll,
            pointerToApiCall: this.fortunePriorityApiCallExecutor,
            variableNameInState: "Priorities"
        };
    }

    private addFortuneInvestmentPlanGetById() : IApiCallback {
        return {
            callback: this.fortuneInvestmentPlanApiCallExecutor.getById,
            pointerToApiCall: this.fortuneInvestmentPlanApiCallExecutor,
            variableNameInState: "Item"
        };
    }
}

export default Update;