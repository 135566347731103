import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneSavingPlanGroupApiCallExecutor from '../../../group/api/FortuneSavingPlanGroupApiCallExecutor';
import FortunePriorityApiCallExecutor from '../../../../priority/api/FortunePriorityApiCallExecutor';
import FortuneInvestmentPlanApiCallExecutor from '../../../../investmentPlan/api/FortuneInvestmentPlanApiCallExecutor';

import FortuneSavingPlan from '../../model/FortuneSavingPlan';
import FortuneSavingPlanApi from '../../api/FortuneSavingPlanApi';
import FortuneSavingPlanUrlPath from '../FortuneSavingPlanUrlPath';

import FortuneSavingPlanGroup from '../../../group/model/FortuneSavingPlanGroup';
import FortunePriority from '../../../../priority/model/FortunePriority';
import FortuneInvestmentPlan from '../../../../investmentPlan/model/FortuneInvestmentPlan';

import CreateUtils from '../../../../../commons/ui/page/template/create/CreateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:       boolean;
    wasHandleSubmitClickedAtLeastOnce:     boolean;
    Item:            FortuneSavingPlan;
    Groups:          FortuneSavingPlanGroup[];
    Priorities:      FortunePriority[];
    InvestmentPlans: FortuneInvestmentPlan[];
}

class Create extends Component<IProps, IState> {
    private apiCalls:    ApiCallExecutor;
    private api:     FortuneSavingPlanApi;
    private form:        Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.state = {
            isLoading:       true,
            wasHandleSubmitClickedAtLeastOnce:     false,
            Item:            new FortuneSavingPlan(),
            Groups:          [],
            Priorities:      [],
            InvestmentPlans: [] 
        };

        this.apiCalls = new ApiCallExecutor(this);

        this.api = FortuneSavingPlanApi.getInstance();

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            FortuneSavingPlanUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private fortuneSavingPlanGroupApiCallExecutor: FortuneSavingPlanGroupApiCallExecutor<Create>;
    private fortunePriorityApiCallExecutor: FortunePriorityApiCallExecutor<Create>;
    private fortuneInvestmentPlanApiCallExecutor: FortuneInvestmentPlanApiCallExecutor<Create>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneSavingPlanGroupApiCallExecutor = 
                new FortuneSavingPlanGroupApiCallExecutor(pointerToComponent);
        this.fortunePriorityApiCallExecutor = new FortunePriorityApiCallExecutor(pointerToComponent);
        this.fortuneInvestmentPlanApiCallExecutor = 
                new FortuneInvestmentPlanApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneSavingPlanGroupGetAll());
        orderOfCalls.push(this.addFortunePriorityGetAll());
        orderOfCalls.push(this.addFortuneInvestmentPlanGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneSavingPlanGroupGetAll() : IApiCallback {
        return {
            callback: this.fortuneSavingPlanGroupApiCallExecutor.getAll,
            pointerToApiCall: this.fortuneSavingPlanGroupApiCallExecutor,
            variableNameInState: "Groups"
        };
    }

    private addFortunePriorityGetAll() : IApiCallback {
        return {
            callback: this.fortunePriorityApiCallExecutor.getAll,
            pointerToApiCall: this.fortunePriorityApiCallExecutor,
            variableNameInState: "Priorities"
        };
    }

    private addFortuneInvestmentPlanGetAll() : IApiCallback {
        return {
            callback: this.fortuneInvestmentPlanApiCallExecutor.getAll,
            pointerToApiCall: this.fortuneInvestmentPlanApiCallExecutor,
            variableNameInState: "InvestmentPlans"
        };
    }
}

export default Create;