import React from 'react';
import { NavLink } from 'reactstrap';
import { Trans } from 'react-i18next';

class NavigationLinkBase {
    protected url: string;

    protected i18nNamespace: string;
    protected i18nKey: string;
    protected nameInEnglish: string;

    private static instance: NavigationLinkBase;

    public constructor(url, i18nNamespace, i18nKey, nameInEnglish) {
        this.url = url;

        this.i18nNamespace = i18nNamespace;
        this.i18nKey = i18nKey;
        this.nameInEnglish = nameInEnglish;
    }

    public render() {
        return (
            <NavLink href={this.url}>
                <Trans ns={this.i18nNamespace}
                       i18nKey={this.i18nKey}>
                    {this.nameInEnglish}
                </Trans>
            </NavLink>
        );
    }
}

export default NavigationLinkBase;