import React from 'react';
import { Component } from 'react';
import { Form } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import I18nConstants from '../../../../../i18n/I18nConstants';
import AuthenticationApi from '../../../../../../security/api/AuthenticationApi';
import LoadingScreen from '../../../loadingScreen/LoadingScreen';
import LoginUrlPath from '../../../../../../login/ui/LoginUrlPath';
import NavigationRenderer from '../../../../fragment/NavigationRenderer';
import GroupForm from '../GroupForm';
import Utils from '../../../../../../utilities/Utils';
import FormButtonUpdate from '../../form/button/FormButtonUpdate';
import FormEventHandlerUpdateGroup from '../../form/eventHandler/FormEventHandlerUpdateGroup';

interface IProps<Group> {
    history: any;
    location: any;
}

interface IState<Group> {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Group: Group; 
}

class UpdateBase<Group, ApiForGroup> 
  extends Component<IProps<Group>, IState<Group>> {
    private eventHandler: FormEventHandlerUpdateGroup;
    private form: GroupForm;

    private group: Group;
    private api: ApiForGroup;
    
    private idOfItemToBeUpdated: number;

    private nextPageUrl: string;

    private showNavbar: boolean;

    // --------------------------------------------------------------------------------------------
    // --- Constructor ---

    public constructor(props, group, api, nextPageUrl) {
        super(props);

        this.group = group;
        this.api = api;

        this.nextPageUrl = nextPageUrl;

        this.showNavbar = true;

        this.idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(this.props.location.search);

        this.eventHandler = new FormEventHandlerUpdateGroup(this, this.api, this.nextPageUrl);

        this.form = new GroupForm(props, this);

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Group: this.group
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    // --------------------------------------------------------------------------------------------
    // --- Render ---

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return (
                <div>
                    { NavigationRenderer.renderIfNecessary(this.showNavbar) }

                    { this.getHeading() }

                    <div>
                        <Form onSubmit={this.handleSubmit}>
                            { this.form.getFormGroupContent(
                                FormButtonUpdate.render
                              ) }                            
                        </Form>
                    </div>
                </div> 
            );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different Sections (for sub-classes) - Optional ---

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.FORM_GROUP_UPDATE}>
                    {I18nConstants.FORM_GROUP_UPDATE_DEFAULT}
                </Trans>
            </h1> 
        );
    }

    // --------------------------------------------------------------------------------------------
    // --- Form validators ---

    private isFormValid(): boolean {
        // Used as callback for checking, before submitting to API.
        return this.form.isFormValid();
    }

    // --------------------------------------------------------------------------------------------

    private handleChange(event) {
        this.eventHandler.handleChange(event);
    }

    private handleSubmit(event) {
        this.eventHandler.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------
    // --- Get values (via API) ---

    public async componentDidMount() {
        this.getInitialValueForGroupInSate();
    }

    private getInitialValueForGroupInSate() {
        (this.api as any).getById(this.idOfItemToBeUpdated)
        .then( result => {
                    this.setState({
                        isLoading: false,
                        Group: result
                    });
                })
        .catch( error => { console.log("TODO"); } );
    }
};

export default UpdateBase;