import ActionBarButtonBase from '../../../../../commons/ui/page/actionBar/ActionBarButtonBase';
import I18nConstants from '../../i18n/I18nConstants';
import EarningsExpensesEarningGroupUrlPath from '../EarningsExpensesEarningGroupUrlPath';

class ActionBarButtonLongText extends ActionBarButtonBase {
    public constructor(propsHistory) {
        super(propsHistory,
              EarningsExpensesEarningGroupUrlPath.BASE,
              I18nConstants.NS,
              I18nConstants.TITLE,
              I18nConstants.TITLE_DEFAULT
        );
    }
}

export default ActionBarButtonLongText;