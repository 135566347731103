import LocalStorageToken from './LocalStorageToken';

class AuthHeader {
    public static get() {
        const token = LocalStorageToken.get();

        if (token) {
            return { "Authorization": token };
        } else {
            return {};
        }
    }
}

export default AuthHeader;