import FormEventHandlerBase from './FormEventHandlerBase';

class FormEventHandlerCreate extends FormEventHandlerBase {
    public constructor(pointerToComponent, api, nextPageUrl) {
        super(pointerToComponent, api, nextPageUrl);
    }

    protected executeSubmitApiCall(item) {
        item = this.executeModelMapperIfNecessary(item);

        (this.api as any).create(item)
        .then(  result => { 
                    if (this.callbackForActionAfterSubmitExecutedSuccessfully) {
                        (this.callbackForActionAfterSubmitExecutedSuccessfully as any)
                         (this.pointerToComponent);
                    } else {
                        (this.pointerToComponent as any).props.history.push(this.nextPageUrl);
                    }
                })
        .catch( error =>  { 
                    console.log("TODO");
                });
    } 
}

export default FormEventHandlerCreate;