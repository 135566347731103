import React from 'react';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import ShoppingListKindOfQuantity from '../../model/ShoppingListKindOfQuantity';
import ShoppingListKindOfQuantityApi from '../../api/ShoppingListKindOfQuantityApi';

import OverviewBase from '../../../../commons/ui/page/template/group/overview/OverviewBase';

class Overview extends OverviewBase<ShoppingListKindOfQuantity, ShoppingListKindOfQuantityApi> {
    public constructor(props) {
        super(props, 
              ShoppingListKindOfQuantityApi.getInstance());
    }

    protected getHeading() {
        return(
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> );
    }
}

export default Overview;