import React from 'react';
import { Component } from 'react';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import CreateUtils from '../../../../commons/ui/page/template/create/CreateUtils';
import Form from './Form';
import Letter from '../../model/Letter';
import LetterApi from '../../api/LetterApi';
import LetterUrlPath from '../LetterUrlPath';
import LetterSenderPerson from '../../../sender/person/model/LetterSenderPerson';
import LetterSenderCompany from '../../../sender/company/model/LetterSenderCompany';
import LetterAddresseePerson from '../../../addressee/person/model/LetterAddresseePerson';
import LetterAddresseeCompany from '../../../addressee/company/model/LetterAddresseeCompany';
import LetterTemplate from '../../../template/model/LetterTemplate';
import LetterSenderPersonApiCallExecutor from '../../../sender/person/api/LetterSenderPersonApiCallExecutor';
import LetterSenderCompanyApiCallExecutor from '../../../sender/company/api/LetterSenderCompanyApiCallExecutor';
import LetterAddresseePersonApiCallExecutor from '../../../addressee/person/api/LetterAddresseePersonApiCallExecutor';
import LetterAddresseeCompanyApiCallExecutor from '../../../addressee/company/api/LetterAddresseeCompanyApiCallExecutor';
import LetterTemplateApiCallExecutor from '../../../template/api/LetterTemplateApiCallExecutor';
import LetterUndefinedObjectDeletionMapper from '../../model/LetterUndefinedObjectDeletionModelMapper';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: Letter;
    SenderPeople: LetterSenderPerson[];
    SenderCompanies: LetterSenderCompany[];
    AddresseePeople: LetterAddresseePerson[];
    AddresseeCompanies: LetterAddresseeCompany[];
    Templates: LetterTemplate[];
}

class Create extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: LetterApi;
    private form: Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: new Letter(),
            SenderPeople: [],
            SenderCompanies: [],
            AddresseePeople: [],
            AddresseeCompanies: [],
            Templates: []
        };

        this.apiCalls = new ApiCallExecutor(this);

        this.api = LetterApi.getInstance();

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            LetterUrlPath.BASE
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return (
            <h1>

            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event,
                                      null,
                                      new LetterUndefinedObjectDeletionMapper());
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private senderPersonApiCallExecutor: LetterSenderPersonApiCallExecutor<Create>;
    private senderCompanyApiCallExecutor: LetterSenderCompanyApiCallExecutor<Create>;
    private addresseePersonApiCallExecutor: LetterAddresseePersonApiCallExecutor<Create>;
    private addresseeCompanyApiCallExecutor: LetterAddresseeCompanyApiCallExecutor<Create>;
    private templateApiCallExecutor: LetterTemplateApiCallExecutor<Create>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.senderPersonApiCallExecutor = 
                new LetterSenderPersonApiCallExecutor(pointerToComponent);
        this.senderCompanyApiCallExecutor = 
                new LetterSenderCompanyApiCallExecutor(pointerToComponent);
        this.addresseePersonApiCallExecutor =
                new LetterAddresseePersonApiCallExecutor(pointerToComponent);
        this.addresseeCompanyApiCallExecutor = 
                new LetterAddresseeCompanyApiCallExecutor(pointerToComponent);
        this.templateApiCallExecutor = 
                new LetterTemplateApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();
    
        orderOfCalls.push(this.addSenderPeopleGetAll());
        orderOfCalls.push(this.addSenderCompaniesGetAll());
        orderOfCalls.push(this.addAddresseePeopleGetAll());
        orderOfCalls.push(this.addAddresseeCompaniesGetAll());
        orderOfCalls.push(this.addTemplatesGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addSenderPeopleGetAll() : IApiCallback {
        return {
            callback: this.senderPersonApiCallExecutor.getAll,
            pointerToApiCall: this.senderPersonApiCallExecutor,
            variableNameInState: "SenderPeople"
        };
    }

    private addSenderCompaniesGetAll() : IApiCallback {
        return {
            callback: this.senderCompanyApiCallExecutor.getAll,
            pointerToApiCall: this.senderCompanyApiCallExecutor,
            variableNameInState: "SenderCompanies"
        };
    }

    private addAddresseePeopleGetAll() : IApiCallback {
        return {
            callback: this.addresseePersonApiCallExecutor.getAll,
            pointerToApiCall: this.addresseePersonApiCallExecutor,
            variableNameInState: "AddresseePeople"
        };
    }

    private addAddresseeCompaniesGetAll() : IApiCallback {
        return {
            callback: this.addresseeCompanyApiCallExecutor.getAll,
            pointerToApiCall: this.addresseeCompanyApiCallExecutor,
            variableNameInState: "AddresseeCompanies"
        };
    }

    private addTemplatesGetAll() : IApiCallback {
        return {
            callback: this.templateApiCallExecutor.getAll,
            pointerToApiCall: this.templateApiCallExecutor,
            variableNameInState: "Templates"
        };
    }
}

export default Create;