import IModelMapper from "../../../commons/model/IModelMapper";

import ToDoList from './ToDoList';

class ToDoListModelMapper implements IModelMapper<ToDoList> {
    fromJsonToObject(json: any): ToDoList {
        let toDoList = new ToDoList();

        toDoList.id              = json.id;
        toDoList.group           = json.group;
        toDoList.description     = json.description;
        toDoList.toDoUntil       = json.toDoUntil;
        toDoList.statusInPercent = json.statusInPercent;

        return toDoList;
    }

    fromObjectToJson(object: ToDoList) {
        return {
            id:              object.id,
            group:           (object.group || undefined),
            description:     (object.description || ""),
            toDoUntil:       (object.toDoUntil || "0001-01-01"),
            statusInPercent: (object.statusInPercent || 0)
        };
    }
}

export default ToDoListModelMapper;