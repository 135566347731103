import OverviewApiCallBase from "./OverviewApiCallBase";

class OverviewApiCallGetAll extends OverviewApiCallBase {
    public execute(pointerToComponent, api, id = -1) {
        api.getAll()
        .then(  result => {
                    pointerToComponent.setState({
                        isLoading: false,
                        List: result
                    });
                })
        .catch( error => console.log("TODO") );
    }
}

export default OverviewApiCallGetAll;