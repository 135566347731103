import React from 'react';
import { Component } from 'react';
import { Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneSavingRateApiCallExecutor from '../../../savingRate/api/FortuneSavingRateApiCallExecutor';
import Utils from '../../../../utilities/Utils';
import FortuneSavingRateSumGroupedBySavingPlanName from '../../../savingRate/model/helper/FortuneSavingRateSumGroupedBySavingPlanName';
import AuthenticationApi from '../../../../security/api/AuthenticationApi';
import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';
import LoginUrlPath from '../../../../login/ui/LoginUrlPath';
import PieChartWithSumAndDiagram from '../../../../commons/ui/page/diagram/PieChartWithSumAndPercentage';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    SumGroupedBySavingPlan: FortuneSavingRateSumGroupedBySavingPlanName[];
    diagramData: [];
    sumJustAddToFortune: number;
    sumEverything: number;
}

class FortuneGroupedBySavingPlanOverview extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;

    private diagram: PieChartWithSumAndDiagram;

    public constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            SumGroupedBySavingPlan: [],
            diagramData: [],
            sumJustAddToFortune: 0.0,
            sumEverything: 0.0
        };

        this.diagram = new PieChartWithSumAndDiagram();

        this.apiCalls = new ApiCallExecutor(this, this.diagram);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            } else {
                return (
                    <div>
                        { this.getHeading() }
                        { this.getSumJustAddToFortune() }
                        { this.getSumNotAddedToFortune() }
                        { this.getSumEverything() }
                        { this.getTable() }
                        { this.getDiagramDataElement() }
                    </div>
                );
            }
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return (
            <h3>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.GROUPED_BY_SAVING_PLAN_TITLE}>
                    {I18nConstants.GROUPED_BY_SAVING_PLAN_TITLE_DEFAULT}
                </Trans>
            </h3>
        );
    }

    private getSumJustAddToFortune() {
        return (
            <p>
                <Trans ns={I18nConstants.NS_BASE}
                       i18nKey={I18nConstants.GENERAL_SUM}>
                    {I18nConstants.GENERAL_SUM_DEFAULT}
                </Trans>&nbsp;(
                <Trans ns={I18nConstants.NS}
                        i18nKey={I18nConstants.JUST_ADD_TO_FORTUNE}>
                    {I18nConstants.JUST_ADD_TO_FORTUNE_DEFAULT}
                </Trans>): {this.state.sumJustAddToFortune}
            </p>
        );
    }

    private getSumNotAddedToFortune() {
        let sumNotAddedToFortune = Utils.roundTwoDecimalPlaces(this.state.sumEverything - 
                                            this.state.sumJustAddToFortune);

        return (
            <p>
                <Trans ns={I18nConstants.NS_BASE}
                       i18nKey={I18nConstants.GENERAL_SUM}>
                    {I18nConstants.GENERAL_SUM_DEFAULT}
                </Trans>&nbsp;(
                <Trans ns={I18nConstants.NS}
                        i18nKey={I18nConstants.NOT_ADDED_TO_FORTUNE}>
                    {I18nConstants.NOT_ADDED_TO_FORTUNE_DEFAULT}
                </Trans>): {sumNotAddedToFortune}
            </p>
        );
    }

    private getSumEverything() {
        return (
            <p>
                <Trans ns={I18nConstants.NS_BASE}
                       i18nKey={I18nConstants.GENERAL_SUM}>
                    {I18nConstants.GENERAL_SUM_DEFAULT}
                </Trans>&nbsp;(
                    <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.EVERYTHING}>
                    {I18nConstants.EVERYTHING_DEFAULT}
                </Trans>): {this.state.sumEverything}
            </p>
        );
    }

    private getTable() {
        return (
            <Table>
                <tbody>
                    { this.getTableBody() }
                </tbody>
            </Table>
        );
    }

    private getTableBody() {
        return this.state.SumGroupedBySavingPlan.map( item =>
            <tr>
                <td>
                    {item.name}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces(item.sum)}
                </td>
            </tr> );
    }

    private getDiagramDataElement() {
        return this.diagram.getHtmlElement(this.state.diagramData);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: FortuneGroupedBySavingPlanOverview;
    private pointerToDiagram: PieChartWithSumAndDiagram;

    private helper: ApiCallExecutorHelper;

    private fortuneSavingRateApiCallExecutor: 
                FortuneSavingRateApiCallExecutor<FortuneGroupedBySavingPlanOverview>;

    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneSavingRateApiCallExecutor = 
                new FortuneSavingRateApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();
    
        orderOfCalls.push(this.addFortuneGroupedBySavingPlanOverview());
        orderOfCalls.push(this.addFortuneGroupedBySavingPlanOverviewToParameters());
        orderOfCalls.push(this.addSumSavingPlansJustAddToFortune());
        orderOfCalls.push(this.addSumSavingPlansEverything());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneGroupedBySavingPlanOverview() : IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.getSumBySavingPlan,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "SumGroupedBySavingPlan"
        };
    }

    private addFortuneGroupedBySavingPlanOverviewToParameters(): IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.setSumBySavingPlanToParameters,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "listDataForDiagram"
        };
    }

    private addSumSavingPlansJustAddToFortune(): IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.getSumSavingPlan,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "sumJustAddToFortune"
        };
    }

    private addSumSavingPlansEverything(): IApiCallback {
        return {
            callback: this.fortuneSavingRateApiCallExecutor.getSumEverythingSavingPlan,
            pointerToApiCall: this.fortuneSavingRateApiCallExecutor,
            variableNameInState: "sumEverything"
        };
    }

    private addDiagramData() : IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagramData"
        };
    }
}

export default FortuneGroupedBySavingPlanOverview;