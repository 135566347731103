import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupAddresseePersonDropdown extends FormGroupBase {
    private ID_AND_NAME = "addresseePerson";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const addresseePeople = this.getAddresseePeopleSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_ADDRESSEE_PERSON,
                    I18nConstants.FORM_ADDRESSEE_PERSON_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={(this.pointerToComponent.state.Item.addresseePerson == undefined) 
                            ? "-1" 
                            : this.pointerToComponent.state.Item.addresseePerson.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {addresseePeople}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {  
        return false;
    }

    protected getAddresseePeopleSelectValues() {
        const defaultValue = this.getDefaultSelectValue();

        const { AddresseePeople } = this.pointerToComponent.state;

        let values = AddresseePeople.map( person =>
            <option value={person.id}>
                {(person as any).firstName}&nbsp;
                {(person as any).lastName}&nbsp;/&nbsp;
                {(person as any).street}&nbsp;
                {(person as any).houseNumber}&nbsp;/&nbsp;
                {(person as any).zip}&nbsp;
                {(person as any).city}
            </option>
        );

        values.unshift(defaultValue);

        return values;
    }
}

export default FormGroupAddresseePersonDropdown;