import Country from "../../../../general/country/model/Country";
import LetterPersonSalutation from "../../../person/salutation/model/LetterPersonSalutation";
import LetterPersonTitle from "../../../person/title/model/LetterPersonTitle";

class LetterAddresseePerson {
    id!: number;
    salutation!: LetterPersonSalutation;
    title!: LetterPersonTitle;
    firstName!: string;
    lastName!: string;
    street!: string;
    houseNumber!: string;
    zip!: string;
    city!: string;
    postOfficeBox!: string;
    country!: Country;

    public constructor() {
        this.salutation = new LetterPersonSalutation();
        this.title = new LetterPersonTitle();
        this.country = new Country();
    }
}

export default LetterAddresseePerson;