import React from 'react';

import FormGroupGroupDropdown from '../fragment/FormGroupGroupDropdown';
import FormGroupDescription from '../fragment/FormGroupDescription';
import FormGroupAmount from '../fragment/FormGroupAmount';

class Form {
    private formGroupGroup:       FormGroupGroupDropdown;
    private formGroupDescription: FormGroupDescription;
    private formGroupAmount:      FormGroupAmount;

    public constructor(props, pointerToComponent) {
        this.formGroupGroup       = new FormGroupGroupDropdown(props, pointerToComponent);
        this.formGroupDescription = new FormGroupDescription(props, pointerToComponent);
        this.formGroupAmount      = new FormGroupAmount(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupGroup.render() }
                { this.formGroupDescription.render() }
                { this.formGroupAmount.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (   this.formGroupGroup.isInvalidValue()
            || this.formGroupDescription.isInvalidValue()
            || this.formGroupAmount.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;