import React from 'react';

import FormGroupCompanyName from '../../../../addressGeneral/ui/fragment/FormGroupCompanyName';
import FormGroupCompanyDepartment from '../../../../addressGeneral/ui/fragment/FormGroupCompanyDepartment';
import FormGroupContactSalutationDropdown from '../../../../addressGeneral/ui/fragment/FormGroupContactSalutationDropdown';
import FormGroupContactTitleDropdown from '../../../../addressGeneral/ui/fragment/FormGroupContactTitleDropdown';
import FormGroupContactFirstName from '../../../../addressGeneral/ui/fragment/FormGroupContactFirstName';
import FormGroupContactLastName from '../../../../addressGeneral/ui/fragment/FormGroupContactLastName';
import FormGroupStreet from '../../../../addressGeneral/ui/fragment/FormGroupStreet';
import FormGroupHouseNumber from '../../../../addressGeneral/ui/fragment/FormGroupHouseNumber';
import FormGroupZip from '../../../../addressGeneral/ui/fragment/FormGroupZip';
import FormGroupCity from '../../../../addressGeneral/ui/fragment/FormGroupCity';
import FormGroupPostOfficeBox from '../../../../addressGeneral/ui/fragment/FormGroupPostOfficeBox';
import FormGroupCountryDropdown from '../../../../addressGeneral/ui/fragment/FormGroupCountryDropdown';
import FormGroupPhone from '../../../../addressGeneral/ui/fragment/FormGroupPhone';
import FormGroupMail from '../../../../addressGeneral/ui/fragment/FormGroupMail';
import FormGroupUrl from '../../../../addressGeneral/ui/fragment/FormGroupUrl';

class Form {
    private formGroupCompanyName: FormGroupCompanyName;
    private formGroupCompanyDepartment: FormGroupCompanyDepartment;
    private formGroupContactSalutationDropdown: FormGroupContactSalutationDropdown;
    private formGroupContactTitleDropdown: FormGroupContactTitleDropdown;
    private formGroupContactFirstName: FormGroupContactFirstName;
    private formGroupContactLastName: FormGroupContactLastName;
    private formGroupStreet: FormGroupStreet;
    private formGroupHouseNumber: FormGroupHouseNumber;
    private formGroupZip: FormGroupZip;
    private formGroupCity: FormGroupCity;
    private formGroupPostOfficeBox: FormGroupPostOfficeBox;
    private formGroupCountryDropdown: FormGroupCountryDropdown;
    private formGroupPhone: FormGroupPhone;
    private formGroupMail: FormGroupMail;
    private formGroupUrl: FormGroupUrl;

    public constructor(props, pointerToComponent) {
        this.formGroupCompanyName = new FormGroupCompanyName(props, pointerToComponent);
        this.formGroupCompanyDepartment = new FormGroupCompanyDepartment(props, pointerToComponent);
        this.formGroupContactSalutationDropdown = new FormGroupContactSalutationDropdown(props, pointerToComponent);
        this.formGroupContactTitleDropdown = new FormGroupContactTitleDropdown(props, pointerToComponent);
        this.formGroupContactFirstName = new FormGroupContactFirstName(props, pointerToComponent);
        this.formGroupContactLastName = new FormGroupContactLastName(props, pointerToComponent);
        this.formGroupStreet = new FormGroupStreet(props, pointerToComponent);
        this.formGroupHouseNumber = new FormGroupHouseNumber(props, pointerToComponent);
        this.formGroupZip = new FormGroupZip(props, pointerToComponent);
        this.formGroupCity = new FormGroupCity(props, pointerToComponent);
        this.formGroupPostOfficeBox = new FormGroupPostOfficeBox(props, pointerToComponent);
        this.formGroupCountryDropdown = new FormGroupCountryDropdown(props, pointerToComponent);
        this.formGroupPhone = new FormGroupPhone(props, pointerToComponent);
        this.formGroupMail = new FormGroupMail(props, pointerToComponent);
        this.formGroupUrl = new FormGroupUrl(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupCompanyName.render() }
                { this.formGroupCompanyDepartment.render() }
                { this.formGroupContactSalutationDropdown.render() }
                { this.formGroupContactTitleDropdown.render() }
                { this.formGroupContactFirstName.render() }
                { this.formGroupContactLastName.render() }
                { this.formGroupStreet.render() }
                { this.formGroupHouseNumber.render() }
                { this.formGroupZip.render() }
                { this.formGroupCity.render() }
                { this.formGroupPostOfficeBox.render() }
                { this.formGroupCountryDropdown.render() }
                { this.formGroupPhone.render() }
                { this.formGroupMail.render() }
                { this.formGroupUrl.render() }

                { renderButtonCallback() } 
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupCompanyName.isInvalidValue()
             || this.formGroupCompanyDepartment.isInvalidValue()
             || this.formGroupContactSalutationDropdown.isInvalidValue()
             || this.formGroupContactTitleDropdown.isInvalidValue()
             || this.formGroupContactFirstName.isInvalidValue()
             || this.formGroupContactLastName.isInvalidValue()
             || this.formGroupStreet.isInvalidValue() 
             || this.formGroupHouseNumber.isInvalidValue() 
             || this.formGroupZip.isInvalidValue() 
             || this.formGroupCity.isInvalidValue() 
             || this.formGroupPostOfficeBox.isInvalidValue() 
             || this.formGroupCountryDropdown.isInvalidValue()
             || this.formGroupPhone.isInvalidValue()
             || this.formGroupMail.isInvalidValue()
             || this.formGroupUrl.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;