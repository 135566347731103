import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "fuelConsumptionItem";

    public static TITLE = "title";

    public static CONSUMPTION_PER_100_KM = "consumptionPer100Km";

    public static FORM_VEHICLE = "form.vehicle";
    public static FORM_ODOMETER_MILEAGE = "form.odometerMileage";
    public static FORM_DRIVEN_KM = "form.drivenKm";
    public static FORM_MILEAGE = "form.mileage";
    public static FORM_EXPENSE = "form.expense";
    public static FORM_PRICE_PER_UNIT = "form.pricePerUnit";
    public static FORM_DATE = "form.date";
    public static FORM_AIR_CONDITIONING_USED = "form.airConditioningUsed";
    public static FORM_TIRE = "form.tire";
    public static FORM_KIND_OF_STREET = "form.kindOfStreet";
    public static FORM_DRIVING_STYLE = "form.drivingStyle";
    public static FORM_NOTE = "form.note";

    public static FORM_ERROR_INSERT_VALID_VALUE = "form.error.insertValidValue";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;

    public static CONSUMPTION_PER_100_KM_DEFAULT = DefaultJson.consumptionPer100Km;

    public static FORM_VEHICLE_DEFAULT = DefaultJson.form.vehicle;
    public static FORM_ODOMETER_MILEAGE_DEFAULT = DefaultJson.form.odometerMileage;
    public static FORM_DRIVEN_KM_DEFAULT = DefaultJson.form.drivenKm;
    public static FORM_MILEAGE_DEFAULT = DefaultJson.form.mileage;
    public static FORM_EXPENSE_DEFAULT = DefaultJson.form.expense;
    public static FORM_PRICE_PER_UNIT_DEFAULT = DefaultJson.form.pricePerUnit;
    public static FORM_DATE_DEFAULT = DefaultJson.form.date;
    public static FORM_AIR_CONDITIONING_USED_DEFAULT = DefaultJson.form.airConditioningUsed;
    public static FORM_TIRE_DEFAULT = DefaultJson.form.tire;
    public static FORM_KIND_OF_STREET_DEFAULT = DefaultJson.form.kindOfStreet;
    public static FORM_DRIVING_STYLE_DEFAULT = DefaultJson.form.drivingStyle;
    public static FORM_NOTE_DEFAULT = DefaultJson.form.note;

    public static FORM_ERROR_INSERT_VALID_VALUE_DEFAULT = DefaultJson.form.error.insertValidValue;
}

export default I18nConstants;