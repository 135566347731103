import FortuneExpensePlannedGroup from '../../model/FortuneExpensePlannedGroup';
import FortuneExpensePlannedGroupApi from '../../api/FortuneExpensePlannedGroupApi';
import FortuneExpensePlannedGroupUrlPath from '../FortuneExpensePlannedGroupUrlPath';

import CreateBase from '../../../../../commons/ui/page/template/group/create/CreateBase';

class Create extends CreateBase<FortuneExpensePlannedGroup, FortuneExpensePlannedGroupApi> {
    public constructor(props) {
        super(props,
              new FortuneExpensePlannedGroup(),
              FortuneExpensePlannedGroupApi.getInstance(),
              FortuneExpensePlannedGroupUrlPath.BASE);
    }
}

export default Create;