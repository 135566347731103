import ShoppingListGroup from '../../group/model/ShoppingListGroup';
import ShoppingListKindOfQuantity from '../../kindOfQuantity/model/ShoppingListKindOfQuantity';

class ShoppingList {
    id!: number;
    group!: ShoppingListGroup;
    description!: string;
    quantity!: number;
    kindOfQuantity!: ShoppingListKindOfQuantity;
    amount!: number;

    public constructor() {
        this.group          = new ShoppingListGroup();
        this.kindOfQuantity = new ShoppingListKindOfQuantity();
    }
}

export default ShoppingList;