import React from "react";
import { Component } from "react";
import ApiCallExecutorHelper from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper";
import IApiCallback from "../../../../commons/ui/page/apiCallExecutor/IApiCallback";
import CreateUtils from "../../../../commons/ui/page/template/create/CreateUtils";
import InsuranceListGroupApiCallExecutor from "../../../group/api/InsuranceListGroupApiCallExecutor";
import InsuranceListGroup from "../../../group/model/InsuranceListGroup";
import InsuranceListPaymentIntervalApiCallExecutor from "../../../paymentInterval/api/InsuranceListPaymentIntervalApiCallExecutor";
import InsuranceListPaymentInterval from "../../../paymentInterval/model/InsuranceListPaymentInterval";
import InsuranceListApi from "../../api/InsuranceListApi";
import InsuranceList from "../../model/InsuranceList";
import InsuranceListUrlPath from "../InsuranceListUrlPath";
import Form from "./Form";

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: InsuranceList;
    Groups: InsuranceListGroup[];
    PaymentIntervals: InsuranceListPaymentInterval[];
}

class Create extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: InsuranceListApi;
    private form: Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = InsuranceListApi.getInstance();

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: new InsuranceList(),
            Groups: [],
            PaymentIntervals: []
        };

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            InsuranceListUrlPath.BASE
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private insuranceListGroupApiCallExecutor: InsuranceListGroupApiCallExecutor<Create>;
    private insuranceListPaymentIntervalApiCallExecutor: 
                InsuranceListPaymentIntervalApiCallExecutor<Create>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.insuranceListGroupApiCallExecutor = 
            new InsuranceListGroupApiCallExecutor(pointerToComponent);
        this.insuranceListPaymentIntervalApiCallExecutor =
            new InsuranceListPaymentIntervalApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addInsuranceListGroupGetAll());
        orderOfCalls.push(this.addInsuranceListPaymentIntervalGetAll());

        let context = this.helper.createContext(
            this.pointerToComponent,
            this.helper, 
            orderOfCalls, 
            parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addInsuranceListGroupGetAll() : IApiCallback {
        return {
            callback: this.insuranceListGroupApiCallExecutor.getAll,
            pointerToApiCall: this.insuranceListGroupApiCallExecutor,
            variableNameInState: "Groups"
        };
    }

    private addInsuranceListPaymentIntervalGetAll() : IApiCallback {
        return {
            callback: this.insuranceListPaymentIntervalApiCallExecutor.getAll,
            pointerToApiCall: this.insuranceListPaymentIntervalApiCallExecutor,
            variableNameInState: "PaymentIntervals"
        };
    }
}

export default Create;