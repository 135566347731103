import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';

import FortuneSavingRateModelMapper from '../model/FortuneSavingRateModelMapper';
import FortuneSavingRateSumGroupedByRiskClassNameModelMapper from '../model/helper/FortuneSavingRateSumGroupedByRiskClassNameModelMapper';
import FortuneSavingRateSumGroupedByGroupNameModelMapper from '../model/helper/FortuneSavingRateSumGroupedByGroupNameModelMapper';
import FortuneSavingRateSumGroupedBySavingPlanNameModelMapper from '../model/helper/FortuneSavingRateSumGroupedBySavingPlanNameModelMapper';
import FortuneSavingRateSumGroupedByInvestmentPlanNameModelMapper from '../model/helper/FortuneSavingRateSumGroupedByInvestmentPlanNameModelMapper';

class FortuneSavingRateApi implements IGenericApi {
    private static instance: FortuneSavingRateApi;

    private static apiUrlPath = "fortune/savingRate";

    private static endpointSum = "/sum";
    private static endpointSumEverything = "/sumEverything";
    private static endpointSumSavingPlan = "/sum/savingPlan";
    private static endpointSumEverythingSavingPlan = "/sumEverything/savingPlan";
    private static endpointSumInvestmentPlanCurrentQuotation = "/sum/investmentPlan/currentQuotation";
    private static endpointSumInvestmentPlanInvestmentAmount = "/sum/investmentPlan/investmentAmount";
    private static endpointSumInvestmentPlanYield = "/sum/investmentPlan/yield";
    private static endpointSumByRiskClass = "/sum/byRiskClass";
    private static endpointSumByGroup = "/sum/byGroup";
    private static endpointSumBySavingPlan = "/sum/bySavingPlan";
    private static endpointSumByInvestmentPlan = "/sum/byInvestmentPlan";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): FortuneSavingRateApi {
        if (!FortuneSavingRateApi.instance) {
            FortuneSavingRateApi.instance = new FortuneSavingRateApi();
        }

        return FortuneSavingRateApi.instance;
    }
    
    public getAll() {
        return GenericApi.executeGetAllCall(
                    FortuneSavingRateApi.apiUrlPath,
                    new FortuneSavingRateModelMapper());
    }

    public getAllBySavingPlanId(savingPlanId) {
        let filterAsString = "savingPlanId" + "=" + savingPlanId.toString();

        return GenericApi.executeGetCallWithFilter(
                    FortuneSavingRateApi.apiUrlPath,
                    filterAsString,
                    new FortuneSavingRateModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    FortuneSavingRateApi.apiUrlPath,
                    id,
                    new FortuneSavingRateModelMapper());
    }

    public getSum() {
        let endpoint = FortuneSavingRateApi.endpointSum;

        return GenericApi.executeGetCallWithoutId(
            FortuneSavingRateApi.apiUrlPath + endpoint,
            null);      // No mapper needed, because the result is just a double-value.
    }

    public getSumEverything() {
        let endpoint = FortuneSavingRateApi.endpointSumEverything;

        return GenericApi.executeGetCallWithoutId(
            FortuneSavingRateApi.apiUrlPath + endpoint,
            null);      // No mapper needed, because the result is just a double-value.
    }

    public getSumSavingPlan() {
        let endpoint = FortuneSavingRateApi.endpointSumSavingPlan;

        return GenericApi.executeGetCallWithoutId(
            FortuneSavingRateApi.apiUrlPath + endpoint,
            null);      // No mapper needed, because the result is just a double-value.
    }

    public getSumEverythingSavingPlan() {
        let endpoint = FortuneSavingRateApi.endpointSumEverythingSavingPlan;

        return GenericApi.executeGetCallWithoutId(
            FortuneSavingRateApi.apiUrlPath + endpoint,
            null);      // No mapper needed, because the result is just a double-value.
    }

    public getSumInvestmentPlanCurrentQuotation() {
        let endpoint = FortuneSavingRateApi.endpointSumInvestmentPlanCurrentQuotation;

        return GenericApi.executeGetCallWithoutId(
            FortuneSavingRateApi.apiUrlPath + endpoint,
            null);      // No mapper needed, because the result is just a double-value.
    }

    public getSumInvestmentPlanInvestmentAmount() {
        let endpoint = FortuneSavingRateApi.endpointSumInvestmentPlanInvestmentAmount;

        return GenericApi.executeGetCallWithoutId(
            FortuneSavingRateApi.apiUrlPath + endpoint,
            null);      // No mapper needed, because the result is just a double-value.
    }

    public getSumInvestmentPlanYield() {
        let endpoint = FortuneSavingRateApi.endpointSumInvestmentPlanYield;

        return GenericApi.executeGetCallWithoutId(
            FortuneSavingRateApi.apiUrlPath + endpoint,
            null);      // No mapper needed, because the result is just a double-value.
    }

    public getSumByRiskClass() {
        let endpoint = FortuneSavingRateApi.endpointSumByRiskClass;

        return GenericApi.executeGetAllCall(
            FortuneSavingRateApi.apiUrlPath + endpoint,
            new FortuneSavingRateSumGroupedByRiskClassNameModelMapper());
    }

    public getSumByGroup() {
        let endpoint = FortuneSavingRateApi.endpointSumByGroup;

        return GenericApi.executeGetAllCall(
            FortuneSavingRateApi.apiUrlPath + endpoint,
            new FortuneSavingRateSumGroupedByGroupNameModelMapper());
    }

    public getSumBySavingPlan() {
        let endpoint = FortuneSavingRateApi.endpointSumBySavingPlan;

        return GenericApi.executeGetAllCall(
            FortuneSavingRateApi.apiUrlPath + endpoint,
            new FortuneSavingRateSumGroupedBySavingPlanNameModelMapper()
        );
    }

    public getSumByInvestmentPlan() {
        let endpoint = FortuneSavingRateApi.endpointSumByInvestmentPlan;

        return GenericApi.executeGetAllCall(
            FortuneSavingRateApi.apiUrlPath + endpoint,
            new FortuneSavingRateSumGroupedByInvestmentPlanNameModelMapper()
        );
    }

    public create(body) {
        return GenericApi.executePostCall(
                    FortuneSavingRateApi.apiUrlPath,
                    body,
                    new FortuneSavingRateModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    FortuneSavingRateApi.apiUrlPath,
                    id,
                    body,
                    new FortuneSavingRateModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    FortuneSavingRateApi.apiUrlPath,
                    id,
                    new FortuneSavingRateModelMapper());
    }

    public getUrl() {
        return FortuneSavingRateApi.apiUrlPath;
    }
}

export default FortuneSavingRateApi;