import IModelMapper from '../../../../../commons/model/IModelMapper';

import ExpenseGroupSum from './ExpenseGroupSum';

class ExpenseGroupSumModelMapper implements IModelMapper<ExpenseGroupSum> {
    fromJsonToObject(json: any): ExpenseGroupSum {
        let expenseGroupSum = new ExpenseGroupSum();

        expenseGroupSum.name = json.name;
        expenseGroupSum.sum  = json.sum;

        return expenseGroupSum;
    }   

    fromObjectToJson(object: ExpenseGroupSum) {
        return {
            name: (object.name || ""),
            sum:  (object.sum || 0)
        }
    }
}

export default ExpenseGroupSumModelMapper;