import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import ShoppingListKindOfQuantityModelMapper from '../model/ShoppingListKindOfQuantityModelMapper';

class ShoppingListKindOfQuantityApi implements IGenericApi {
    private static instance: ShoppingListKindOfQuantityApi;

    private static apiUrlPath = "shoppingList/kindOfQuantity";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): ShoppingListKindOfQuantityApi {
        if (!ShoppingListKindOfQuantityApi.instance) {
            ShoppingListKindOfQuantityApi.instance = new ShoppingListKindOfQuantityApi();
        }

        return ShoppingListKindOfQuantityApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    ShoppingListKindOfQuantityApi.apiUrlPath,
                    new ShoppingListKindOfQuantityModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    ShoppingListKindOfQuantityApi.apiUrlPath,
                    id,
                    new ShoppingListKindOfQuantityModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    ShoppingListKindOfQuantityApi.apiUrlPath,
                    body,
                    new ShoppingListKindOfQuantityModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    ShoppingListKindOfQuantityApi.apiUrlPath,
                    id,
                    body,
                    new ShoppingListKindOfQuantityModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    ShoppingListKindOfQuantityApi.apiUrlPath,
                    id,
                    new ShoppingListKindOfQuantityModelMapper());
    }

    public getUrl() {
        return ShoppingListKindOfQuantityApi.apiUrlPath;
    }
}

export default ShoppingListKindOfQuantityApi;