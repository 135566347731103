import UpdateBase from '../../../../../commons/ui/page/template/group/update/UpdateBase';

import VehicleType from '../../model/VehicleType';
import VehicleTypeApi from '../../api/VehicleTypeApi';
import VehicleTypeUrlPath from '../VehicleTypeUrlPath';

class Update extends UpdateBase<VehicleType, VehicleTypeApi> {
    public constructor(props) {
        super(props,
              new VehicleType(),
              VehicleTypeApi.getInstance(),
              VehicleTypeUrlPath.BASE);
    }
}

export default Update;