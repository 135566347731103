import React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';

import AuthenticationApi from '../../../security/api/AuthenticationApi';
import Navigation from '../../../commons/ui/fragment/Navigation';
import LoginUrlPath from '../../../login/ui/LoginUrlPath';

import ToDoListNavigationLink from '../../../toDoList/item/ui/fragment/NavigationLink';
import LinkListNavigationLink from '../../../linkList/item/ui/fragment/NavigationLink';
import PasswordListNavigationLink from '../../../passwordList/item/ui/fragment/NavigationLink';
import ShoppingListNavigationLink from '../../../shoppingList/item/ui/fragment/NavigationLink';
import LetterNavigationLink from '../../../letter/item/ui/fragment/NavigationLink';
import BankingListNavigationLink from '../../../bankingList/item/ui/fragment/NavigationLink';
import InsuranceListNavigationLink from '../../../insuranceList/item/ui/fragment/NavigationLink';
import EarningsExpensesNavigationLink from '../../../earningsExpenses/item/ui/fragment/NavigationLink';
import EarningsExpensesOverheadNavigationLink from '../../../earningsExpensesOverhead/item/ui/fragment/NavigationLink';
import FortuneDashboardNavigationLink from '../../../fortune/dashboard/ui/fragment/NavigationLink';
import FortuneSavingRatioNavigationLink from '../../../fortune/savingRatio/ui/fragment/NavigationLink';
import FortuneSavingRateNavigationLink from '../../../fortune/savingRate/ui/fragment/NavigationLink';
import FortuneSavingPlanNavigationLink from '../../../fortune/savingPlan/item/ui/fragment/NavigationLink';
import FortuneInvestmentPlanNavigationLink from '../../../fortune/investmentPlan/ui/fragment/NavigationLink';
import FortuneInvestmentAimNavigationLink from '../../../fortune/investmentAim/ui/fragment/NavigationLink';
import FortuneExpensePlannedNavigationLink from '../../../fortune/expensePlanned/item/ui/fragment/NavigationLink';
import FuelConsumptionNavigationLink from '../../../fuelConsumption/item/ui/fragment/NavigationLink';
import CyclingTourNavigationLink from '../../../cyclingTour/item/ui/fragment/NavigationLink';

class Dashboard extends Component {
    public render() { 
        if (AuthenticationApi.isLoggedIn()) {
            return ( 
                <div>
                    <Navigation />
                    
                    <h1>
                        <Trans ns={I18nConstants.NS} 
                               i18nKey={I18nConstants.WELCOME}>
                            {I18nConstants.WELCOME_DEFUALT}
                        </Trans>
                    </h1>

                    <div>
                        { new ToDoListNavigationLink().render() }
                        { new LinkListNavigationLink().render() }
                        { new PasswordListNavigationLink().render() }
                        { new ShoppingListNavigationLink().render() }
                        { new LetterNavigationLink().render() }
                        { new BankingListNavigationLink().render() }
                        { new InsuranceListNavigationLink().render() }
                        { new EarningsExpensesNavigationLink().render() }
                        { new EarningsExpensesOverheadNavigationLink().render() }
                        { new FortuneDashboardNavigationLink().render() }
                        { new FortuneSavingRatioNavigationLink().render() }
                        { new FortuneSavingRateNavigationLink().render() }
                        { new FortuneSavingPlanNavigationLink().render() }
                        { new FortuneInvestmentPlanNavigationLink().render() }
                        { new FortuneInvestmentAimNavigationLink().render() }
                        { new FortuneExpensePlannedNavigationLink().render() }
                        { new FuelConsumptionNavigationLink().render() }
                        { new CyclingTourNavigationLink().render() }
                    </div>
                </div>
            );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }
}
 
export default Dashboard;