import React from 'react';
import { Component } from 'react';
import { Input } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import LetterSignature from "../../model/LetterSignature";
import LetterSignatureApi from '../../api/LetterSignatureApi';
import OverviewUtils from '../../../../commons/ui/page/template/overview/OverviewUtils';
import LetterSignatureUrlPath from '../LetterSignatureUrlPath';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import LetterSignatureApiCallExecutor from '../../api/LetterSignatureApiCallExecutor';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import AuthenticationApi from '../../../../security/api/AuthenticationApi';
import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';
import LoginUrlPath from '../../../../login/ui/LoginUrlPath';
import I18nConstants from '../../i18n/I18nConstants';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    List: LetterSignature[];
}

class Overview extends Component<IProps, IState> {
    private api: LetterSignatureApi;
    private apiCalls: ApiCallExecutor;

    private overviewUtils: OverviewUtils;

    public constructor(props) {
        super(props);

        this.api = LetterSignatureApi.getInstance();

        this.apiCalls = new ApiCallExecutor(this);

        this.state = {
            isLoading: true,
            List: []
        };

        this.overviewUtils = new OverviewUtils(
            this,
            this.getHeading(),
            LetterSignatureUrlPath.CREATE,
            LetterSignatureUrlPath.UPDATE,
            undefined,
            true
        );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return(
                <div>
                    { this.overviewUtils.render(
                        this.getTableHead(),
                        this.getTableBody()) }
                </div> );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> );
    }

    private getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_SENDER_PERSON}>
                        {I18nConstants.FORM_SENDER_PERSON_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_SENDER_COMPANY}>
                        {I18nConstants.FORM_SENDER_COMPANY_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_CONTENT}>
                        {I18nConstants.FORM_CONTENT_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_CONTENT_TYPE}>
                        {I18nConstants.FORM_CONTENT_TYPE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_WIDTH}>
                        {I18nConstants.FORM_WIDTH_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_HEIGHT}>
                        {I18nConstants.FORM_HEIGHT_DEFAULT}
                    </Trans>
                </th>

                { this.overviewUtils.getUpdateHeadingWrappedInThTag() }
                { this.overviewUtils.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    private getTableBody() {
        return this.state.List.map( item => 
            <tr key={(item as any).id}>
                <td>
                    {((item as any).senderPerson == undefined)
                      ? ""
                      : ((item as any).senderPerson.firstName + " " +
                         (item as any).senderPerson.lastName)}
                </td>

                <td>
                    {((item as any).senderCompany == undefined)
                        ? ""
                        : (item as any).senderCompany.name}
                </td>

                <td>
                    <img src={"data:" + (item as any).contentType + ";base64," +
                              (item as any).content} width="100px" />
                </td>

                <td>
                    {(item as any).contentType}
                </td>

                <td>
                    {(item as any).width}
                </td>

                <td>
                    {(item as any).height}
                </td>

                { this.overviewUtils.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.overviewUtils.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr>
        );
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private helper: ApiCallExecutorHelper;

    private letterSignatureApiCallExecutor: LetterSignatureApiCallExecutor<Overview>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.letterSignatureApiCallExecutor = 
                new LetterSignatureApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addLetterSignatureGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addLetterSignatureGetAll() : IApiCallback {
        return {
            callback: this.letterSignatureApiCallExecutor.getAll,
            pointerToApiCall: this.letterSignatureApiCallExecutor,
            variableNameInState: "List"
        };
    }
}

export default Overview;