import copy from "copy-to-clipboard"; 

class ClipboardApi {
    private static instance: ClipboardApi;

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): ClipboardApi {
        if (!ClipboardApi.instance) {
            ClipboardApi.instance = new ClipboardApi();
        }

        return ClipboardApi.instance;
    }

    public copyFromTextContentValue(event) {
        copy(event.target.textContent);
    }

    public copyFromTitleAttributeValue(event) {
        copy(event.target.title);
    }
}

export default ClipboardApi;