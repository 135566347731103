import IModelMapper from "../../../../commons/model/IModelMapper";
import FortuneInvestmentPlan from "../../../investmentPlan/model/FortuneInvestmentPlan";

import FortuneSavingPlan from './FortuneSavingPlan';

class FortuneSavingPlanModelMapper implements IModelMapper<FortuneSavingPlan> {
    fromJsonToObject(json: any): FortuneSavingPlan {
        let fortuneSavingPlan = new FortuneSavingPlan();

        fortuneSavingPlan.id               = json.id;
        fortuneSavingPlan.group            = json.group;
        fortuneSavingPlan.priority         = json.priority;
        fortuneSavingPlan.description      = json.description;
        fortuneSavingPlan.monthlyRate      = json.monthlyRate;
        fortuneSavingPlan.plannedEndDate   = json.plannedEndDate;
        fortuneSavingPlan.plannedAmountMin = json.plannedAmountMin;
        fortuneSavingPlan.plannedAmountMax = json.plannedAmountMax;
        fortuneSavingPlan.addToFortune     = json.addToFortune;

        // Just add the investment plan, if it isn't null!
        // If it is null, then create a new investment plan object and set the id to "none".
        if (json.investmentPlan !== null) {
            fortuneSavingPlan.investmentPlan = json.investmentPlan;
        } else {
            fortuneSavingPlan.investmentPlan = new FortuneInvestmentPlan();
            (fortuneSavingPlan.investmentPlan.id as any) = "none";
        }
        
        return fortuneSavingPlan;
    }

    fromObjectToJson(object: FortuneSavingPlan) {
        return {
            id:               object.id,
            group:            (object.group || undefined),
            priority:         (object.priority || undefined),
            description:      (object.description || ""),
            monthlyRate:      (object.monthlyRate || 0),
            plannedEndDate:   (object.plannedEndDate || "0001-01-01"),
            plannedAmountMin: (object.plannedAmountMin || 0),
            plannedAmountMax: (object.plannedAmountMax || 0),
            addToFortune:     (object.addToFortune || undefined),
            investmentPlan:   (object.investmentPlan || undefined)
        }
    }
}

export default FortuneSavingPlanModelMapper;