import axios from "axios";

import EnvironmentVariables from "../../../commons/config/EnvironmentVariables";
import AuthHeader from "../../../security/api/AuthHeader";

import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';

import FortuneSavingRatioModelMapper from '../model/FortuneSavingRatioModelMapper';

class FortuneSavingRatioApi implements IGenericApi {
    private static instance: FortuneSavingRatioApi;

    private static apiUrlPath = "fortune/savingRatio";

    private static endpointYearsAsString = "/yearsAsString";
    private static endpointAverage = "/average";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): FortuneSavingRatioApi {
        if (!FortuneSavingRatioApi.instance) {
            FortuneSavingRatioApi.instance = new FortuneSavingRatioApi();
        }

        return FortuneSavingRatioApi.instance;
    }
    
    public getAll() {
        return GenericApi.executeGetAllCall(
                    FortuneSavingRatioApi.apiUrlPath,
                    new FortuneSavingRatioModelMapper());
    }

    public getByYear(year) {
        let filterAsString = "year" + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                    FortuneSavingRatioApi.apiUrlPath,
                    filterAsString,
                    new FortuneSavingRatioModelMapper());
    } 

    public getYearsAsString() {
        let endpoint = FortuneSavingRatioApi.endpointYearsAsString;

        return new Promise((resolve, reject) => {
            axios
            .get(EnvironmentVariables.getBackendApiUrl() + 
                 FortuneSavingRatioApi.apiUrlPath + endpoint,
                 { headers: AuthHeader.get() })
            .then( response => { 
                        resolve(response.data);
                   })
            .catch( error =>   {
                        GenericApi.createErrorMessage(error.response, reject);
                    })
        });
    }

    public getAverageByYear(year) {
        let endpoint = FortuneSavingRatioApi.endpointAverage;

        let filterAsString = "year" + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                    FortuneSavingRatioApi.apiUrlPath + endpoint,
                    filterAsString,
                    null);   // No mapper needed, because the result is just a double-value.
    }

    public getAverageTotal() {
        let endpoint = FortuneSavingRatioApi.endpointAverage;

        return GenericApi.executeGetCallWithoutId(
                    FortuneSavingRatioApi.apiUrlPath + endpoint,
                    null);  // No mapper needed, because the result is just a double-value.
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    FortuneSavingRatioApi.apiUrlPath,
                    id,
                    new FortuneSavingRatioModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    FortuneSavingRatioApi.apiUrlPath,
                    body,
                    new FortuneSavingRatioModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    FortuneSavingRatioApi.apiUrlPath,
                    id,
                    body,
                    new FortuneSavingRatioModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    FortuneSavingRatioApi.apiUrlPath,
                    id,
                    new FortuneSavingRatioModelMapper());
    }

    public getUrl() {
        return FortuneSavingRatioApi.apiUrlPath;
    }
}

export default FortuneSavingRatioApi;