import { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import I18nConstants from '../../../../i18n/I18nConstantsBase';
import i18n from '../../../../i18n/i18n';

interface IProps {
    history: any;
}

interface IState {
}

class DeleteConfirm extends Component<IProps, IState> {
    public constructor(props) {
        super(props);
    }

    public renderMessageBoxOnly(pointerToComponentOrUtil, idToBeDeleted, 
                                pointerToStateObject : any = undefined) {
        let title = i18n.t(I18nConstants.NS_BASE + ":" + 
                           I18nConstants.MESSAGE_BOX_DELETE_TITLE);
        let message = i18n.t(I18nConstants.NS_BASE + ":" + 
                             I18nConstants.MESSAGE_BOX_DELETE_MESSAGE);
        let yesLabel = i18n.t(I18nConstants.NS_BASE + ":" + 
                              I18nConstants.GENERAL_YES);
        let noLabel  = i18n.t(I18nConstants.NS_BASE + ":" +
                              I18nConstants.GENERAL_NO);

        confirmAlert({
            title: title,
            message: message,
            buttons: [
            {
                label: yesLabel,
                onClick: () => {
                    // If a utility-class is used, then there must be an additional pointer
                    // to the object containing the state.
                    if (pointerToStateObject) {
                        pointerToComponentOrUtil.deleteItemById(
                            pointerToStateObject, idToBeDeleted
                        );
                    } else {
                        if (pointerToComponentOrUtil.api === undefined) {
                            // For OverviewUtils and other classes
                            if (pointerToComponentOrUtil.pointerToComponent == undefined) {
                                pointerToComponentOrUtil.deleteItemById(
                                    pointerToComponentOrUtil, idToBeDeleted
                                );
                            } else {
                                pointerToComponentOrUtil.deleteItemById(
                                    pointerToComponentOrUtil.pointerToComponent, idToBeDeleted
                                );
                            }
                        } else {
                            // For OverviewBase
                            pointerToComponentOrUtil.deleteItemById(
                                pointerToComponentOrUtil, idToBeDeleted
                            );
                        }
                    }
                }
            },
            {
                label: noLabel,
                onClick: () => { /* Do nothing in this case! */ }
            }]
        });
    }
}

export default DeleteConfirm;