import IModelMapper from '../../../../commons/model/IModelMapper';

import ExpenseOverhead from './ExpenseOverhead';

class ExpenseOverheadModelMapper implements IModelMapper<ExpenseOverhead> {
    fromJsonToObject(json: any): ExpenseOverhead {
        let expenseOverhead = new ExpenseOverhead();

        expenseOverhead.id          = json.id;
        expenseOverhead.group       = json.group;
        expenseOverhead.description = json.description;
        expenseOverhead.amount      = json.amount;

        return expenseOverhead;
    }

    fromObjectToJson(object: ExpenseOverhead) {
        return {
            id:          object.id,
            group:       (object.group || undefined),
            description: (object.description || ""),
            amount:      (object.amount || 0)
        };
    }
}

export default ExpenseOverheadModelMapper;