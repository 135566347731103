import React from 'react';
import FormGroupUsername from '../fragment/FormGroupUsername';
import FormGroupPassword from '../fragment/FormGroupPassword';
import FormGroupSubmitButton from '../fragment/FormGroupSubmitButton';

class Form {
    private formGroupUsername: FormGroupUsername;
    private formGroupPassword: FormGroupPassword;
    private formGroupSubmitButton: FormGroupSubmitButton;

    public constructor(props, pointerToComponent) {
        this.formGroupUsername = new FormGroupUsername(props, pointerToComponent);
        this.formGroupPassword = new FormGroupPassword(props, pointerToComponent);
        this.formGroupSubmitButton = new FormGroupSubmitButton(props, pointerToComponent);
    }

    public getFormGroupContent() {
        return (
            <div>
                { this.formGroupUsername.render() }
                { this.formGroupPassword.render() }
                { this.formGroupSubmitButton.render() }
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupUsername.isInvalidValue()
             || this.formGroupPassword.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;