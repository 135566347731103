import React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import I18nConstantsAddressGeneral from '../../../../addressGeneral/i18n/I18nConstants';
import LetterSenderPersonApi from '../../api/LetterSenderPersonApi';
import LoadingScreen from '../../../../../commons/ui/page/loadingScreen/LoadingScreen';
import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import OverviewUtils from '../../../../../commons/ui/page/template/overview/OverviewUtils';
import LetterSenderPerson from '../../model/LetterSenderPerson';
import LetterSenderPersonUrlPath from '../LetterSenderPersonUrlPath';
import AuthenticationApi from '../../../../../security/api/AuthenticationApi';
import LoginUrlPath from '../../../../../login/ui/LoginUrlPath';
import LetterSenderPersonApiCallExecutor from '../../api/LetterSenderPersonApiCallExecutor';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading: boolean;
    List: LetterSenderPerson[];
}

class Overview extends Component<IProps, IState> {
    private api: LetterSenderPersonApi;
    private apiCalls: ApiCallExecutor;

    private overviewUtils: OverviewUtils;

    public constructor(props) {
        super(props);

        this.api = LetterSenderPersonApi.getInstance();

        this.apiCalls = new ApiCallExecutor(this);

        this.state = {
            isLoading: true,
            List: []
        };

        this.overviewUtils = new OverviewUtils(
            this,
            this.getHeading(),
            LetterSenderPersonUrlPath.CREATE,
            LetterSenderPersonUrlPath.UPDATE,
            undefined,
            true
        );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return(
                <div>
                    { this.overviewUtils.render(
                        this.getTableHead(),
                        this.getTableBody()) }
                </div> );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> );
    }

    private getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_SALUTATION}>
                        {I18nConstantsAddressGeneral.FORM_SALUTATION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_TITLE}>
                        {I18nConstantsAddressGeneral.FORM_TITLE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_FIRST_NAME}>
                        {I18nConstantsAddressGeneral.FORM_FIRST_NAME_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_LAST_NAME}>
                        {I18nConstantsAddressGeneral.FORM_LAST_NAME_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_STREET}>
                        {I18nConstantsAddressGeneral.FORM_STREET_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_HOUSE_NUMBER}>
                        {I18nConstantsAddressGeneral.FORM_HOUSE_NUMBER_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_ZIP}>
                        {I18nConstantsAddressGeneral.FORM_ZIP_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_CITY}>
                        {I18nConstantsAddressGeneral.FORM_CITY_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_POST_OFFICE_BOX}>
                        {I18nConstantsAddressGeneral.FORM_POST_OFFICE_BOX_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_COUNTRY}>
                        {I18nConstantsAddressGeneral.FORM_COUNTRY_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_PHONE}>
                        {I18nConstantsAddressGeneral.FORM_PHONE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_MAIL}>
                        {I18nConstantsAddressGeneral.FORM_MAIL_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsAddressGeneral.NS}
                           i18nKey={I18nConstantsAddressGeneral.FORM_URL}>
                        {I18nConstantsAddressGeneral.FORM_URL_DEFAULT}
                    </Trans>
                </th>

                { this.overviewUtils.getUpdateHeadingWrappedInThTag() }
                { this.overviewUtils.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    private getTableBody() {
        return this.state.List.map( item => 
            <tr key={(item as any).id}>
                <td>
                    {(item as any).salutation.name}
                </td>

                <td>
                    {((item as any).title == undefined)
                    ? ""
                    : (item as any).title.name}
                </td>

                <td>
                    {(item as any).firstName}
                </td>

                <td>
                    {(item as any).lastName}
                </td>

                <td>
                    {(item as any).street}
                </td>

                <td>
                    {(item as any).houseNumber}
                </td>

                <td>
                    {(item as any).zip}
                </td>

                <td>
                    {(item as any).city}
                </td>

                <td>
                    {(item as any).postOfficeBox}
                </td>

                <td>
                    {(item as any).country.isoCode}
                </td>

                <td>
                    {(item as any).phone}
                </td>

                <td>
                    {(item as any).mail}
                </td>

                <td>
                    {(item as any).url}
                </td>

                { this.overviewUtils.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.overviewUtils.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr>
        );
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private helper: ApiCallExecutorHelper;

    private letterSenderPersonApiCallExecutor: LetterSenderPersonApiCallExecutor<Overview>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.letterSenderPersonApiCallExecutor = 
                new LetterSenderPersonApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addLetterSenderPersonGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addLetterSenderPersonGetAll() : IApiCallback {
        return {
            callback: this.letterSenderPersonApiCallExecutor.getAll,
            pointerToApiCall: this.letterSenderPersonApiCallExecutor,
            variableNameInState: "List"
        };
    }
}

export default Overview;