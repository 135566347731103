import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FuelConsumptionApiCallExecutor from '../../api/FuelConsumptionApiCallExecutor';
import VehicleApiCallExecutor from '../../../vehicle/item/api/VehicleApiCallExecutor';
import TireApiCallExecutor from '../../../tire/api/TireApiCallExecutor';
import KindOfStreetApiCallExecutor from '../../../kindOfStreet/api/KindOfStreetApiCallExecutor';
import DrivingStyleApiCallExecutor from '../../../drivingStyle/api/DrivingStyleApiCallExecutor';

import Utils from '../../../../utilities/Utils';

import FuelConsumption from '../../model/FuelConsumption';
import FuelConsumptionUrlPath from '../FuelConsumptionUrlPath';
import FuelConsumptionApi from '../../api/FuelConsumptionApi';

import Vehicle from '../../../vehicle/item/model/Vehicle';
import Tire from '../../../tire/model/Tire';
import KindOfStreet from '../../../kindOfStreet/model/KindOfStreet';
import DrivingStyle from '../../../drivingStyle/model/DrivingStyle';

import UpdateUtils from '../../../../commons/ui/page/template/update/UpdateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:     boolean;
    wasHandleSubmitClickedAtLeastOnce:   boolean;
    Item:          FuelConsumption;
    Vehicles:      Vehicle[];
    Tires:         Tire[];
    KindOfStreets: KindOfStreet[];
    DrivingStyles: DrivingStyle[];
}

class Update extends Component<IProps, IState> {
    private apiCalls:    ApiCallExecutor;
    private api:     FuelConsumptionApi;
    private form:        Form;
    private updateUtils: UpdateUtils;

    private idOfItemToBeUpdated: number;

    public constructor(props) {
        super(props);

        this.api = FuelConsumptionApi.getInstance();

        this.apiCalls = new ApiCallExecutor(this);

        this.idOfItemToBeUpdated =
            Utils.getIdFromGetParameters(props.location.search);

        this.state = {
            isLoading:     true,
            wasHandleSubmitClickedAtLeastOnce:   false,
            Item:          new FuelConsumption(),
            Vehicles:      [],
            Tires:         [],
            KindOfStreets: [],
            DrivingStyles: []
        };

        this.form = new Form(props, this);

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            FuelConsumptionUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.updateUtils.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------

    public getIdToBeUpdated() {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private fuelConsumptionApiCallExecutor: FuelConsumptionApiCallExecutor<Update>;
    private vehicleApiCallExecutor: VehicleApiCallExecutor<Update>;
    private tireApiCallExecutor: TireApiCallExecutor<Update>;
    private kindOfStreetApiCallExecutor: KindOfStreetApiCallExecutor<Update>;
    private drivingStyleApiCallExecutor: DrivingStyleApiCallExecutor<Update>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.fuelConsumptionApiCallExecutor = new FuelConsumptionApiCallExecutor(pointerToComponent);
        this.vehicleApiCallExecutor = new VehicleApiCallExecutor(pointerToComponent);
        this.tireApiCallExecutor = new TireApiCallExecutor(pointerToComponent);
        this.kindOfStreetApiCallExecutor = new KindOfStreetApiCallExecutor(pointerToComponent);
        this.drivingStyleApiCallExecutor = new DrivingStyleApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFuelConsumptionGetById());
        orderOfCalls.push(this.addVehicleGetAll());
        orderOfCalls.push(this.addTireGetAll());
        orderOfCalls.push(this.addKindOfStreetGetAll());
        orderOfCalls.push(this.addDrivingStyleGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "fuelConsumptionId", 
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFuelConsumptionGetById() : IApiCallback {
        return {
            callback: this.fuelConsumptionApiCallExecutor.getById,
            pointerToApiCall: this.fuelConsumptionApiCallExecutor,
            variableNameInState: "Item"
        }
    }

    private addVehicleGetAll() : IApiCallback {
        return {
            callback: this.vehicleApiCallExecutor.getAll,
            pointerToApiCall: this.vehicleApiCallExecutor,
            variableNameInState: "Vehicles"
        }
    }

    private addTireGetAll() : IApiCallback {
        return {
            callback: this.tireApiCallExecutor.getAll,
            pointerToApiCall: this.tireApiCallExecutor,
            variableNameInState: "Tires"
        };
    }

    private addKindOfStreetGetAll() : IApiCallback {
        return {
            callback: this.kindOfStreetApiCallExecutor.getAll,
            pointerToApiCall: this.kindOfStreetApiCallExecutor,
            variableNameInState: "KindOfStreets"
        };
    }

    private addDrivingStyleGetAll() : IApiCallback {
        return {
            callback: this.drivingStyleApiCallExecutor.getAll,
            pointerToApiCall: this.drivingStyleApiCallExecutor,
            variableNameInState: "DrivingStyles"
        };
    }
}

export default Update;