import React from 'react';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import LinkList from '../../model/LinkList';
import LinkListApi from '../../api/LinkListApi';
import LinkListUrlPath from '../LinkListUrlPath';
import LinkListGroupUrlPath from '../../../group/ui/LinkListGroupUrlPath';
import OverviewBase from '../../../../commons/ui/page/template/overview/OverviewBase';

class Overview extends OverviewBase<LinkList, LinkListApi> {
    public constructor(props) {
        super(props,
              LinkListApi.getInstance());

        this.setCreateUrl(LinkListUrlPath.CREATE);
        this.setGroupsOverviewUrl(LinkListGroupUrlPath.BASE);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE}>
                    {I18nConstants.TITLE_DEFAULT}
                </Trans>
            </h1> );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_NAME}>
                        {I18nConstants.FORM_NAME_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_GROUP}>
                        {I18nConstants.FORM_GROUP_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_LINK}>
                        {I18nConstants.FORM_LINK_DEFAULT}
                    </Trans>
                </th>

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr> );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).name}
                </td>

                <td>
                    {(item as any).description}
                </td>

                <td>
                    {(item as any).group.name}
                </td>

                <td>
                    <a href={(item as any).link} target="_blank">{(item as any).link}</a>
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }

    protected getActionBar() {
        return (
            <div>
                { this.getGroupsOverviewButton() }
            </div> );
    }
}

export default Overview;