import ApiCallExecutorBase from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';
import BikeApi from './BikeApi';

class BikeApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private bikeApi: BikeApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.bikeApi = BikeApi.getInstance();
    }

    public getAll(context: IApiCallContext) {
        let bikeIdSelection = 0;
        
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.bikeApi.getAll()
        .then(  response => {
                    // Get the last bike.
                    bikeIdSelection = (response as any)[(response as any).length - 1].id;

                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    context.pointerToHelper.addParameterToContext(context,
                                                "bikeId", bikeIdSelection);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    } 

    public getById(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const bikeId = (context.pointerToHelper.getParameterInArrayWithObjects(
                                context.parameters, "bikeId") as number);

        context.currentData.pointerToApiCall.bikeApi.getById(bikeId)
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }

    public setBikeIdFromParametersToState(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const bikeId = (context.pointerToHelper.getParameterInArrayWithObjects(
                            context.parameters, "bikeId") as number);

        const state = context.pointerToHelper.createStateObject(
                        context.currentData.variableNameInState,
                        bikeId);

        context.pointerToComponent.setState(state);

        try {
            successorCallback.callback(context);
        } catch (error) { 
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }
}

export default BikeApiCallExecutor;