import React from 'react';
import { Component } from 'react';
import { Input, Button } from 'reactstrap';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import EarningsExpensesApiCallExecutor from '../../api/EarningsExpensesApiCallExecutor';

import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';

import Utils from '../../../../utilities/Utils';

import Navigation from '../../../../commons/ui/fragment/Navigation';

import EarningOverviewGroupedByMonthAndYear from '../../../earning/item/ui/page/OverviewGroupedByMonthAndYear';
import ExpenseOverviewGroupedByMonthAndYear from '../../../expense/item/ui/page/OverviewGroupedByMonthAndYear';
import EarningsExpensesUrlPath from '../EarningsExpensesUrlPath';

import EarningsExpensesActionBarButton from '../fragment/ActionBarButton';
import EarningsExpensesGroupedByYearActionButton from '../fragment/ActionBarGroupedByYear';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:                    boolean;
    yearsAndMonthsAsString:       string[];
    currentYearAndMonthSelection: string;
    sum:                          number;
    savingRatio:                  number;
}

class OverviewGroupedByMonthAndYear extends Component<IProps, IState> {
    private apiCalls:            ApiCallExecutor;

    private groupingByYearUrl:   string;
    private earningsExpensesUrl: string;

    private showNavbar:          boolean;

    private earningsExpensesActionBarButton: EarningsExpensesActionBarButton;
    private earningsExpensesGroupedByYearActionButton: EarningsExpensesGroupedByYearActionButton;

    public constructor(props) {
        super(props);

        this.apiCalls            = new ApiCallExecutor(this);

        this.earningsExpensesActionBarButton = new EarningsExpensesActionBarButton(props.history);
        this.earningsExpensesGroupedByYearActionButton = new EarningsExpensesGroupedByYearActionButton(props.history);

        this.groupingByYearUrl   = EarningsExpensesUrlPath.GROUPED_BY_YEAR;
        this.earningsExpensesUrl = EarningsExpensesUrlPath.BASE;

        this.showNavbar = true;

        this.state = {
            isLoading:                    true,
            yearsAndMonthsAsString:       [],
            currentYearAndMonthSelection: "",
            sum:                          0.0,
            savingRatio:                  0.0
        };

        this.handleChange = this.handleChange.bind(this);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (this.state.isLoading) {
            return LoadingScreen.render();
        } else {
            let selectValues = this.getGroupSelectValues();

            return (
                <div>
                    { this.getNavbar() }

                    <Input 
                        type="select"
                        name="monthsAndYears"
                        id="monthsAndYears"
                        value={this.state.currentYearAndMonthSelection}
                        onChange={this.handleChange}>
                            {selectValues}
                    </Input>

                    <p>
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.PROFIT_OR_LOSS}>
                            {I18nConstants.PROFIT_OR_LOSS_DEFAULT}
                        </Trans>
                        : {this.state.sum} 
                        <br />
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.SAVING_RATIO}>
                            {I18nConstants.SAVING_RATIO_DEFAULT}
                        </Trans>
                        : {this.state.savingRatio} %
                    </p>

                    { this.getActionBar() }

                    <EarningOverviewGroupedByMonthAndYear history={this.props.history} location={this.props.location} {...this.state} />
                    <ExpenseOverviewGroupedByMonthAndYear history={this.props.history} location={this.props.location} {...this.state} />
                </div> );
        }
    }

    private getNavbar() {
        if (this.showNavbar) {
            return ( <Navigation /> );
        } else {
            return ( <div></div> );
        }
    }

    // --------------------------------------------------------------------------------------------

    private getGroupSelectValues() {
        let yearsAndMonthsAsString = this.state.yearsAndMonthsAsString;

        let yearsAndMonths = yearsAndMonthsAsString.map( element => 
            <option value={element}>{element}</option> );

        return yearsAndMonths;
    }

    // --------------------------------------------------------------------------------------------

    private handleChange(event) {
        const target = event.target;

        let currentYearAndMonthSelection = target.value;

        this.getCurrentSumAndSetToState(currentYearAndMonthSelection);
    }

    private async getCurrentSumAndSetToState(yearAndMonthSelection) {
        let monthAndYearObject = Utils.getMonthAndYearFromStringSeparatedBySlash(yearAndMonthSelection);

        this.apiCalls.executeStartingAfterMonthAndYearSelection(monthAndYearObject);
    }

    // --------------------------------------------------------------------------------------------

    private getActionBar() {
        return (
            <div>
                { this.earningsExpensesActionBarButton.render() }
                { this.earningsExpensesGroupedByYearActionButton.render() }
            </div> );
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: OverviewGroupedByMonthAndYear;

    private helper: ApiCallExecutorHelper;

    private earningsExpensesApiCallExecutor: EarningsExpensesApiCallExecutor<OverviewGroupedByMonthAndYear>;
    
    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.earningsExpensesApiCallExecutor = new EarningsExpensesApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------
 
    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningsExpensesgGetYearsAndMonthsAsString());
        orderOfCalls.push(this.addEarningsExpensesSetMonthAndYearObjectFromParametersToState());
        orderOfCalls.push(this.addEarningsExpensesGetSumByMonthAndYear());
        orderOfCalls.push(this.addEarningsExpensesGetSavingRatioByMonthAndYear());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    public executeStartingAfterMonthAndYearSelection(monthAndYearObject) {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningsExpensesGetSumByMonthAndYear());
        orderOfCalls.push(this.addEarningsExpensesSetMonthAndYearObjectFromParametersToState());
        orderOfCalls.push(this.addEarningsExpensesGetSavingRatioByMonthAndYear());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "monthAndYearObject", monthAndYearObject);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------
 
    private addEarningsExpensesgGetYearsAndMonthsAsString() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getYearsAndMonthsAsStringLastMonth,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "yearsAndMonthsAsString"
        };
    }

    private addEarningsExpensesSetMonthAndYearObjectFromParametersToState() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.setMonthAndYearObjectFromParametersToState,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "currentYearAndMonthSelection"
        };
    }

    private addEarningsExpensesGetSumByMonthAndYear() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getSumByMonthAndYear,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addEarningsExpensesGetSavingRatioByMonthAndYear() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getSavingRatioByMonthAndYear,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "savingRatio"
        };
    }
}

export default OverviewGroupedByMonthAndYear;