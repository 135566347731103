import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import LetterAddresseePersonModelMapper from '../model/LetterAddresseePersonModelMapper';

class LetterAddresseePersonApi implements IGenericApi {
    private static instance: LetterAddresseePersonApi;

    private static apiUrlPath = "/letter/addressee/person";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): LetterAddresseePersonApi {
        if (!LetterAddresseePersonApi.instance) {
            LetterAddresseePersonApi.instance = new LetterAddresseePersonApi();
        }

        return LetterAddresseePersonApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    LetterAddresseePersonApi.apiUrlPath,
                    new LetterAddresseePersonModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    LetterAddresseePersonApi.apiUrlPath,
                    id,
                    new LetterAddresseePersonModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    LetterAddresseePersonApi.apiUrlPath,
                    body,
                    new LetterAddresseePersonModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    LetterAddresseePersonApi.apiUrlPath,
                    id,
                    body,
                    new LetterAddresseePersonModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    LetterAddresseePersonApi.apiUrlPath,
                    id,
                    new LetterAddresseePersonModelMapper());
    }

    public getUrl() {
        return LetterAddresseePersonApi.apiUrlPath;
    }
}

export default LetterAddresseePersonApi;