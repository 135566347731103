import React from 'react';

import FormGroupName from '../fragment/FormGroupName';
import FormGroupDescription from '../fragment/FormGroupDescription';
import FormGroupGroupDropdown from '../fragment/FormGroupGroupDropdown';
import FormGroupLink from '../fragment/FormGroupLink';

class Form {
    private formGroupName:        FormGroupName;
    private fromGroupDescription: FormGroupDescription;
    private formGroupGroup:       FormGroupGroupDropdown;
    private formGroupLink:        FormGroupLink;

    public constructor(props, pointerToComponent) {
        this.formGroupName        = new FormGroupName(props, pointerToComponent);
        this.fromGroupDescription = new FormGroupDescription(props, pointerToComponent);
        this.formGroupGroup       = new FormGroupGroupDropdown(props, pointerToComponent);
        this.formGroupLink        = new FormGroupLink(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupName.render() }
                { this.fromGroupDescription.render() }
                { this.formGroupGroup.render() }
                { this.formGroupLink.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.formGroupName.isInvalidValue() 
             || this.fromGroupDescription.isInvalidValue()
             || this.formGroupGroup.isInvalidValue()
             || this.formGroupLink.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;