import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import EarningOverheadTransactionApiCallExecutor from '../../api/EarningOverheadTransactionApiCallExecutor';

import Utils from '../../../../../utilities/Utils';

import EarningOverheadTransaction from '../../model/EarningOverheadTransaction';
import EarningOverheadTransactionUrlPath from '../EarningsExpensesOverheadTransactionEarningUrlPath';
import EarningOverheadTransactionApi from '../../api/EarningOverheadTransactionApi';

import UpdateUtils from '../../../../../commons/ui/page/template/update/UpdateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:   boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item:        EarningOverheadTransaction;
}

class Update extends Component<IProps, IState> {
    private apiCalls:            ApiCallExecutor;
    private api:             EarningOverheadTransactionApi;
    private form:                Form;
    private updateUtils:         UpdateUtils;

    private idOfItemToBeUpdated: number;
    private idOverheadDataSet:   number;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = EarningOverheadTransactionApi.getInstance();

        this.form = new Form(props, this, true);

        this.idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        this.idOverheadDataSet = 
            Utils.getOverheadIdFromGetParameters(props.location.search);

        this.state = {
            isLoading:   true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item:        new EarningOverheadTransaction()
        };

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            EarningOverheadTransactionUrlPath.BASE +
                "?overheadId=" + 
                this.idOverheadDataSet.toString());

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return(
            <h1>
                
            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) {
        // For an update, the recurrence must always be set to a year.
        const { Item } = this.state;
        Item.recurrence = "12";     // 12 means every year.
        this.setState({ Item: Item });

        this.updateUtils.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------
    
    public getIdToBeUpdated() : number {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private earningOverheadTransactionApiCallExecutor: 
                EarningOverheadTransactionApiCallExecutor<Update>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.earningOverheadTransactionApiCallExecutor = 
                new EarningOverheadTransactionApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningOverheadTransactionGetById());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "overheadId", 
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addEarningOverheadTransactionGetById() : IApiCallback {
        return {
            callback: this.earningOverheadTransactionApiCallExecutor.getById,
            pointerToApiCall: this.earningOverheadTransactionApiCallExecutor,
            variableNameInState: "Item"
        };
    }
}

export default Update;