import LinkListGroup from '../../model/LinkListGroup';
import LinkListGroupApi from '../../api/LinkListGroupApi';

import OverviewBase from '../../../../commons/ui/page/template/group/overview/OverviewBase';

class Overview extends OverviewBase<LinkListGroup, LinkListGroupApi> {
    public constructor(props) {
        super(props,
              LinkListGroupApi.getInstance());
    }
}

export default Overview;