import React from 'react';
import i18n from '../../../../../i18n/i18n';
import I18nConstants from '../../../../../i18n/I18nConstantsBase';

class FormGroupRenderer {
    public getValuesWrappedInOptionTag(valueObject, withDefaultValue = true) {
        let values = valueObject.map( group => 
            <option value={(group as any).id}>{(group as any).name}</option> 
        );
    
        if (withDefaultValue === true) {
            values.unshift(this.getGroupDefaultValue());
        }

        return values;
    }

    private getGroupDefaultValue() {
        let optionValue = i18n.t(
            I18nConstants.NS_BASE + ":" +
            I18nConstants.FORM_DROPDOWN_VALUES_DEFAULT
        );

        return (
            <option value="-1">
                --- {optionValue} ---
            </option>
        );
    }
}

export default FormGroupRenderer;