import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupKindOfStreet extends FormGroupBase {
    private ID_AND_NAME = "kindOfStreet";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const kindOfStreets = this.getKindOfStreetsSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_KIND_OF_STREET,
                    I18nConstants.FORM_KIND_OF_STREET_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.kindOfStreet.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {kindOfStreets}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getKindOfStreetsSelectValues() {
        const { KindOfStreets } = this.pointerToComponent.state;

        return this.getSelectValues(KindOfStreets, "name");
    }
    
}

export default FormGroupKindOfStreet;