import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import ShoppingListModelMapper from '../model/ShoppingListModelMapper';

class ShoppingListApi implements IGenericApi {
    private static instance: ShoppingListApi;

    private static apiUrlPath = "shoppingList";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): ShoppingListApi {
        if (!ShoppingListApi.instance) {
            ShoppingListApi.instance = new ShoppingListApi();
        }

        return ShoppingListApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    ShoppingListApi.apiUrlPath,
                    new ShoppingListModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    ShoppingListApi.apiUrlPath,
                    id,
                    new ShoppingListModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    ShoppingListApi.apiUrlPath,
                    body,
                    new ShoppingListModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    ShoppingListApi.apiUrlPath,
                    id,
                    body,
                    new ShoppingListModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    ShoppingListApi.apiUrlPath,
                    id,
                    new ShoppingListModelMapper());
    }

    public getUrl() {
        return ShoppingListApi.apiUrlPath;
    }
}

export default ShoppingListApi;