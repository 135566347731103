import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../commons/i18n/i18n';

class MessageToastApi {
    private static instance: MessageToastApi;

    private constructor() {
        // Singleton, so the constructor can't be called!

        toast.configure();
    }

    public static getInstance(): MessageToastApi {
        if (!MessageToastApi.instance) {
            MessageToastApi.instance = new MessageToastApi();
        }

        return MessageToastApi.instance;
    }

    public showI18nTextOnBottomCenter(i18nNamespace, i18nText) {
        let text = i18n.t(i18nNamespace + ":" + i18nText);

        toast.success(text, 
            { 
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 1500 
            });
    }
}

export default MessageToastApi;