import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupVehicle extends FormGroupBase {
    private ID_AND_NAME = "vehicle";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const vehicles = this.getVehiclesSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_VEHICLE,
                    I18nConstants.FORM_VEHICLE_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.vehicle.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {vehicles}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getVehiclesSelectValues() {
        const defaultValue = this.getDefaultSelectValue();

        const { Vehicles } = this.pointerToComponent.state;

        let values = Vehicles.map( vehicle =>
            <option value={vehicle.id}>
                {(vehicle as any).brand}&nbsp;
                {(vehicle as any).model}&nbsp;
                ({(vehicle as any).vehicleIdentificationNumber})
            </option> 
        );

        values.unshift(defaultValue);

        return values;
    }
}

export default FormGroupVehicle;