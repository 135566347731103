import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import InsuranceListModelMapper from '../model/InsuranceListModelMapper';

class InsuranceListApi implements IGenericApi {
    private static instance: InsuranceListApi;

    private static apiUrlPath = "insuranceList";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): InsuranceListApi {
        if (!InsuranceListApi.instance) {
            InsuranceListApi.instance = new InsuranceListApi();
        }

        return InsuranceListApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    InsuranceListApi.apiUrlPath,
                    new InsuranceListModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    InsuranceListApi.apiUrlPath,
                    id,
                    new InsuranceListModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    InsuranceListApi.apiUrlPath,
                    body,
                    new InsuranceListModelMapper());
    }

    public update(id ,body) {
        return GenericApi.executePutCall(
                    InsuranceListApi.apiUrlPath,
                    id,
                    body,
                    new InsuranceListModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    InsuranceListApi.apiUrlPath,
                    id,
                    new InsuranceListModelMapper());
    }

    public getUrl() {
        return InsuranceListApi.apiUrlPath;
    }
}

export default InsuranceListApi;