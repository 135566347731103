import OverviewBase from '../../../../../commons/ui/page/template/group/overview/OverviewBase';

import FuelUnit from '../../model/FuelUnit';
import FuelUnitApi from '../../api/FuelUnitApi';

class Overview extends OverviewBase<FuelUnit, FuelUnitApi> {
    public constructor(props) {
        super(props,
              FuelUnitApi.getInstance());
    }
}

export default Overview;