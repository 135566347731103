import I18nConstants from '../../../../../i18n/I18nConstantsBase';
import OverviewButtonBase from "./OverviewButtonBase";

class OverviewButtonSaveAsPDF extends OverviewButtonBase {
    public static render(pointerToComponentOrUtil, callback, id) {
        return OverviewButtonBase.render(
                    I18nConstants.ACTION_SAVE_AS_PDF,
                    I18nConstants.ACTION_SAVE_AS_PDF_DEFAULT,
                    pointerToComponentOrUtil,
                    callback,
                    id,
                    true
        );
    }
}

export default OverviewButtonSaveAsPDF;