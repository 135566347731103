import IModelMapper from '../../../commons/model/IModelMapper';

import KindOfStreet from './KindOfStreet';

class KindOfStreetModelMapper implements IModelMapper<KindOfStreet> {
    fromJsonToObject(json: any): KindOfStreet {
        let kindOfStreet = new KindOfStreet();

        kindOfStreet.id   = json.id;
        kindOfStreet.name = json.name;

        return kindOfStreet;
    }

    fromObjectToJson(object: KindOfStreet) {
        return {
            id: object.id,
            name: (object.name || "") };
    }
}

export default KindOfStreetModelMapper;