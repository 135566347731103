import I18nConstants from '../../../../../i18n/I18nConstantsBase';
import OverviewButtonBase from "./OverviewButtonBase";

class OverviewButtonUpdate extends OverviewButtonBase {
    public static render(pointerToComponentOrUtil, callback, callbackId: number = -1) {
        return OverviewButtonBase.render(
                    I18nConstants.ACTION_UPDATE,
                    I18nConstants.ACTION_UPDATE_DEFAULT,
                    pointerToComponentOrUtil,
                    callback,
                    callbackId
        );
    }
}

export default OverviewButtonUpdate;