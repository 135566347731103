import IModelMapper from '../../../commons/model/IModelMapper';

import ShoppingListKindOfQuantity from './ShoppingListKindOfQuantity';

class ShoppingListKindOfQuantityModelMapper implements IModelMapper<ShoppingListKindOfQuantity> {
    fromJsonToObject(json: any): ShoppingListKindOfQuantity {
        let shoppingListKindOfQuantity = new ShoppingListKindOfQuantity();

        shoppingListKindOfQuantity.id   = json.id;
        shoppingListKindOfQuantity.name = json.name;

        return shoppingListKindOfQuantity;
    }

    fromObjectToJson(object: ShoppingListKindOfQuantity) {
        return {
            id:   object.id,
            name: (object.name || "")
        }
    }
}

export default ShoppingListKindOfQuantityModelMapper;