import React from 'react';
import { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import EarningsExpensesApiCallExecutor from '../../api/EarningsExpensesApiCallExecutor';

import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';

import Navigation from '../../../../commons/ui/fragment/Navigation';

import EarningOverviewGroupedByYear from '../../../earning/item/ui/page/OverviewGroupedByYear';
import ExpenseOverviewGroupedByYear from '../../../expense/item/ui/page/OverviewGroupedByYear';

import EarningsExpensesUrlPath from '../EarningsExpensesUrlPath';

import EarningsExpensesActionBarButton from '../fragment/ActionBarButton';
import EarningsExpensesGroupedByMonthAndYearActionBarButton from '../fragment/ActionBarButtonGroupedByMonthAndYear';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:            boolean;
    yearsAsString:        string[];
    currentYearSelection: string;
    sum:                  number;
    savingRatio:          number;
}

class OverviewGroupedByYear extends Component<IProps, IState> {
    private apiCalls:                  ApiCallExecutor;

    private groupingByMonthAndYearUrl: string;
    private earningsExpensesUrl:       string;

    private showNavbar:                boolean;

    private earningsExpensesActionBarButton: EarningsExpensesActionBarButton;
    private earningsExpensesGroupedByMonthAndYearActionBarButton: EarningsExpensesGroupedByMonthAndYearActionBarButton;

    public constructor(props) {
        super(props);

        this.apiCalls                  = new ApiCallExecutor(this);

        this.groupingByMonthAndYearUrl = EarningsExpensesUrlPath.GROUPED_BY_MONTH_AND_YEAR;
        this.earningsExpensesUrl       = EarningsExpensesUrlPath.BASE;

        this.showNavbar = true;

        this.earningsExpensesActionBarButton = new EarningsExpensesActionBarButton(props.history);
        this.earningsExpensesGroupedByMonthAndYearActionBarButton = new EarningsExpensesGroupedByMonthAndYearActionBarButton(props.history);

        this.state = {
            isLoading:            true,
            yearsAsString:        [],
            currentYearSelection: "",
            sum:                  0.0,
            savingRatio:          0.0
        };

        this.handleChange = this.handleChange.bind(this);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (this.state.isLoading) {
            return LoadingScreen.render();
        } else {
            let selectValues = this.getGroupSelectValues();

            return (
                <div>
                    { this.getNavbar() }

                    <Input
                        type="select"
                        name="years"
                        id="years"
                        value={this.state.currentYearSelection}
                        onChange={this.handleChange}>
                            {selectValues}
                    </Input>

                    <p>
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.PROFIT_OR_LOSS}>
                            {I18nConstants.PROFIT_OR_LOSS_DEFAULT}
                        </Trans>
                        : {this.state.sum} 
                        <br />
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.SAVING_RATIO}>
                            {I18nConstants.SAVING_RATIO_DEFAULT}
                        </Trans>
                        : {this.state.savingRatio} %
                    </p>

                    { this.getActionBar() }

                    <EarningOverviewGroupedByYear history={this.props.history} location={this.props.location} {...this.state} />
                    <ExpenseOverviewGroupedByYear history={this.props.history} location={this.props.location} {...this.state} />
                </div> );
        }
    }

    private getNavbar() {
        if (this.showNavbar) {
            return ( <Navigation /> );
        } else {
            return ( <div></div> );
        }
    }

    // --------------------------------------------------------------------------------------------

    private getGroupSelectValues() {
        let yearsAsString = this.state.yearsAsString;

        let years = yearsAsString.map( element => 
            <option value={element}>{element}</option> );

        return years;
    }

    // --------------------------------------------------------------------------------------------

    private handleChange(event) {
        const target = event.target;

        let currentYearSelection = target.value;

        this.getCurrentSumAndSetToState(currentYearSelection);
    }

    private async getCurrentSumAndSetToState(yearSelection) {
        this.apiCalls.executeStartingAfterYearSelection(yearSelection);
    }

    // --------------------------------------------------------------------------------------------

    private getActionBar() {
        return (
            <div>
                { this.earningsExpensesActionBarButton.render() }
                { this.earningsExpensesGroupedByMonthAndYearActionBarButton.render() }
            </div> );
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: OverviewGroupedByYear;

    private helper: ApiCallExecutorHelper;

    private earningsExpensesApiCallExecutor: EarningsExpensesApiCallExecutor<OverviewGroupedByYear>;
    
    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.earningsExpensesApiCallExecutor = new EarningsExpensesApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------
 
    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningsExpensesGetYearsAsStringLastYear());
        orderOfCalls.push(this.addEarningsExpensesSetYearFromParameterToState());
        orderOfCalls.push(this.addEarningsExpensesGetSumByYear());
        orderOfCalls.push(this.addEarningsExpensesGetSavingRatioByYear());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    public executeStartingAfterYearSelection(yearSelection) {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningsExpensesGetSumByYear());
        orderOfCalls.push(this.addEarningsExpensesSetYearFromParameterToState());
        orderOfCalls.push(this.addEarningsExpensesGetSavingRatioByYear());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "year", yearSelection);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------
 
    private addEarningsExpensesGetYearsAsStringLastYear() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getYearsAsStringLastYear,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "yearsAsString"
        };
    }

    private addEarningsExpensesSetYearFromParameterToState() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.setYearFromParameterToState,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "currentYearSelection"
        };
    }

    private addEarningsExpensesGetSumByYear() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getSumByYear,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addEarningsExpensesGetSavingRatioByYear() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getSavingRatioByYear,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: "savingRatio"
        };
    }
}

export default OverviewGroupedByYear;