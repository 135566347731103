import React from "react";
import { Trans } from "react-i18next";
import I18nConstants from "../../i18n/I18nConstants";

import PasswordListFolder from "../../model/PasswordListFolder";
import PasswordListFolderApi from "../../api/PasswordListFolderApi";
import PasswordListFolderUrlPath from "../PasswordListFolderUrlPath";

import CreateBase from '../../../../commons/ui/page/template/group/create/CreateBase';

class Create extends CreateBase<PasswordListFolder, PasswordListFolderApi> {
    public constructor(props) {
        super(
            props,
            new PasswordListFolder(),
            PasswordListFolderApi.getInstance(),
            PasswordListFolderUrlPath.BASE
        );
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> 
        );
    }
}

export default Create;