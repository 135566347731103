import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupInvestmentPlanDropdown extends FormGroupBase {
    private ID_AND_NAME = "investmentPlan";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);

        this.setUseNoneValueForDropdown(true);
    }

    public render() {
        const investmentPlanValues = this.getInvestmentPlanSelectValues();

        // Returns "none", if the investmentPlan has already been reset by
        // model mapper. This special logic is necessary to avoid an exception
        // in backend, when id = "none" would be sent to backend.
        const investmentPlanId = this.getInvestmentPlanIdOrFillWithNoneString();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_INVESTMENT_PLAN,
                    I18nConstants.FORM_INVESTMENT_PLAN_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={investmentPlanId}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {investmentPlanValues}
                </Input>

                { this.renderFormFeedbackWithInsertValidValueMessage() }
            </FormGroup>
        );
    }

    public getInvestmentPlanIdOrFillWithNoneString() {
        if (this.pointerToComponent.state.Item.investmentPlan === undefined) {
            return "none";
        } else {
            return this.pointerToComponent.state.Item.investmentPlan.id;
        }
    }

    public isInvalidValue(): boolean {
        if (this.pointerToComponent.state.Item.investmentPlan !== undefined) {
            return this.pointerToComponent.isInvalidValue(this.pointerToComponent.state.Item.investmentPlan.id);
        } else {
            return false;
        }
    }

    protected getInvestmentPlanSelectValues() {
        const { InvestmentPlans } = this.pointerToComponent.state;

        return this.getSelectValues(InvestmentPlans, "description");
    }
}

export default FormGroupInvestmentPlanDropdown;