import React from 'react';
import { Component } from 'react';
import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import BikeTypeApiCallExecutor from '../../../type/api/BikeTypeApiCallExecutor';
import Bike from '../../model/Bike';
import BikeApi from '../../api/BikeApi';
import BikeUrlPath from '../BikeUrlPath';
import BikeType from '../../../type/model/BikeType';
import CreateUtils from '../../../../../commons/ui/page/template/create/CreateUtils';
import Form from './Form';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: Bike;
    BikeTypes: BikeType[];
}

class Create extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: BikeApi;
    private form: Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);
        this.api = BikeApi.getInstance();

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: new Bike(),
            BikeTypes: []
        };

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            BikeUrlPath.BASE
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return (
            <h1>

            </h1>
        );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private bikeTypeApiCallExecutor: BikeTypeApiCallExecutor<Create>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.bikeTypeApiCallExecutor = new BikeTypeApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addBikeTypeGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addBikeTypeGetAll() : IApiCallback {
        return {
            callback: this.bikeTypeApiCallExecutor.getAll,
            pointerToApiCall: this.bikeTypeApiCallExecutor,
            variableNameInState: "BikeTypes"
        };
    }
}

export default Create;