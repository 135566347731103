import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupKindOfFuel extends FormGroupBase {
    private ID_AND_NAME = "kindOfFuel";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const kindOfFuels = this.getKindOfFuelsSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_KIND_OF_FUEL,
                    I18nConstants.FORM_KIND_OF_FUEL_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.kindOfFuel.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {kindOfFuels}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getKindOfFuelsSelectValues() {
        const { KindOfFuels } = this.pointerToComponent.state;

        return this.getSelectValues(KindOfFuels, "name");
    }
}

export default FormGroupKindOfFuel;