import IModelMapper from "../../../../../model/IModelMapper";
import IFormEventHandler from "./IFormEventHandler";

abstract class FormEventHandlerBase implements IFormEventHandler {
    protected pointerToComponent: object;
    protected api: object;
    protected nextPageUrl: string;

    protected callbackForActionAfterSubmitExecutedSuccessfully: object;

    protected modelMapper: IModelMapper<object>;

    public constructor(pointerToComponent, api, nextPageUrl) {
        this.pointerToComponent = pointerToComponent;
        this.api = api;
        this.nextPageUrl = nextPageUrl;

        this.callbackForActionAfterSubmitExecutedSuccessfully = (undefined as any);
        
        this.modelMapper = (undefined as any);
    }

    public setCallbackForActionAfterSubmitExecutedSuccessfully(callback) {
        this.callbackForActionAfterSubmitExecutedSuccessfully = callback;
    }

    public setModelMapper(modelMapper) {
        this.modelMapper = modelMapper;
    }

    protected getValueFromState() {
        return (this.pointerToComponent as any).state.Item;
    }

    protected setValueToState(value) {
        (this.pointerToComponent as any).setState({ Item: value });
    }

    protected abstract executeSubmitApiCall(item);

    public handleChange(event) {
        const target = event.target;

        let item = this.getValueFromState();

        // Distinguish between object type.
        switch (target.type) {
            case "select-one":  // Dropdown
                if (typeof item[target.name] === "object") {
                    item[target.name].id = target.value;
                } else {
                    item[target.name] = target.value;
                }
                break;
            case "checkbox":    // Checkbox
                item[target.name] = target.checked;
                break;
            default:    // Text field
                item[target.name] = target.value;
                break;
        }

        this.setValueToState(item);
    }

    public handleSubmit(event) {
        event.preventDefault();

        var that = this;

        // This event was fired at least, once.
        (this.pointerToComponent as any).setState({ wasHandleSubmitClickedAtLeastOnce: true }, 
        // This function is used to make sure the 'wasHandleSubmitClickedAtLeastOnce' is updated, correctly.
        function() {
            let item = that.getValueFromState();

            // Check, if all input-fields are valid before sending the request.
            if (    ((that.pointerToComponent as any).isFormValid())
                && ((that.pointerToComponent as any).state.wasHandleSubmitClickedAtLeastOnce)) {
                // Implmeneted in subclasses.
                that.executeSubmitApiCall(item);
            } else {
                console.log("TODO - Form is not valid!");
            }
        }); 
    }

    protected executeModelMapperIfNecessary(item) {
        if (this.modelMapper) {
            item = this.modelMapper.fromObjectToJson(item);
        }

        return item;
    }
}

export default FormEventHandlerBase;