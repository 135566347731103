import OverviewEventHandlerBase from './OverviewEventHandlerBase';
import Utils from '../../../../../../utilities/Utils';

class OverviewEventHandlerUpdate extends OverviewEventHandlerBase {
    public onAction(pointerToComponentOrUtil, id) {
        let updateUrl = "";

        if (pointerToComponentOrUtil.updateUrl) {
            updateUrl = pointerToComponentOrUtil.updateUrl;
        } else {
            updateUrl = Utils.getUpdateUrl(pointerToComponentOrUtil.props.location.pathname);
        }

        updateUrl += "?id=" + id.toString();

        if (pointerToComponentOrUtil.props === undefined) {
            // For OverviewUtils
            pointerToComponentOrUtil.propsHistory.push(updateUrl);
        } else {
            // For OverviewBase
            pointerToComponentOrUtil.props.history.push(updateUrl);
        }
    }
}

export default OverviewEventHandlerUpdate;