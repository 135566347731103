import React from 'react';
import FormGroupGroupDropdown from '../fragment/FormGroupGroupDropdown';
import FormGroupBankName from '../fragment/FormGroupBankName';
import FormGroupIban from '../fragment/FormGroupIban';
import FormGroupBic from '../fragment/FormGroupBic';
import FormGroupNote from '../fragment/FormGroupNote';
import FormGroupLink from '../fragment/FormGroupLink';

class Form {
    private formGroupGroupDropdown: FormGroupGroupDropdown;
    private formGroupBankName: FormGroupBankName;
    private formGroupIban: FormGroupIban;
    private formGroupBic: FormGroupBic;
    private formGroupNote: FormGroupNote;
    private formGroupLink: FormGroupLink;

    public constructor(props, pointerToComponent) {
        this.formGroupGroupDropdown = new FormGroupGroupDropdown(props, pointerToComponent);
        this.formGroupBankName = new FormGroupBankName(props, pointerToComponent);
        this.formGroupIban = new FormGroupIban(props, pointerToComponent);
        this.formGroupBic = new FormGroupBic(props, pointerToComponent);
        this.formGroupNote = new FormGroupNote(props, pointerToComponent);
        this.formGroupLink = new FormGroupLink(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupGroupDropdown.render() }
                { this.formGroupBankName.render() }
                { this.formGroupIban.render() }
                { this.formGroupBic.render() }
                { this.formGroupNote.render() }
                { this.formGroupLink.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.formGroupGroupDropdown.isInvalidValue() 
             || this.formGroupBankName.isInvalidValue()
             || this.formGroupIban.isInvalidValue()
             || this.formGroupBic.isInvalidValue()
             || this.formGroupNote.isInvalidValue()
             || this.formGroupLink.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;