import React from 'react';

import FormGroupName from '../fragment/FormGroupName';

class Form {
    private formGroupName: FormGroupName;

    public constructor(props, pointerToComponent) {
        this.formGroupName = new FormGroupName(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupName.render() }

                { renderButtonCallback() }
            </div>);
    }

    public isFormValid(): boolean {
        if (this.formGroupName.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;
