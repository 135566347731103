import CreateBase from '../../../../../commons/ui/page/template/group/create/CreateBase';

import FuelUnit from '../../model/FuelUnit';
import FuelUnitApi from '../../api/FuelUnitApi';
import FuelUnitUrlPath from '../FuelUnitUrlPath';

class Create extends CreateBase<FuelUnit, FuelUnitApi> {
    public constructor(props) {
        super(props,
              new FuelUnit(),
              FuelUnitApi.getInstance(),
              FuelUnitUrlPath.BASE);
    }
}

export default Create;