import React from 'react';
import { Form } from 'reactstrap';
import { Redirect } from 'react-router';
import AuthenticationApi from '../../../../../security/api/AuthenticationApi';
import LoginUrlPath from '../../../../../login/ui/LoginUrlPath';
import NavigationRenderer from '../../../fragment/NavigationRenderer';
import LoadingScreen from '../../loadingScreen/LoadingScreen';
import FormButtonCreate from '../form/button/FormButtonCreate';
import FormEventHandlerCreate from '../form/eventHandler/FormEventHandlerCreate';
import FormValidator from '../form/validator/FormValidator';

class CreateUtils {
    private eventHandler: FormEventHandlerCreate;
    private formValidator: FormValidator;

    private pointerToComponent: object;

    private api: object;
    private form: object;

    private heading: object;
    private nextPageUrl: string;

    private showNavbar: boolean;

    // --------------------------------------------------------------------------------------------
    // --- Constructor ---

    public constructor(pointerToComponent, api, form, heading, nextPageUrl,
                       showNavbar = true) {
        this.pointerToComponent = pointerToComponent;

        this.api = api;
        this.form = form;

        this.heading = heading;
        this.nextPageUrl = nextPageUrl;

        this.showNavbar = showNavbar;

        this.eventHandler = new FormEventHandlerCreate(this.pointerToComponent, this.api, this.nextPageUrl);
        this.formValidator = new FormValidator(this.pointerToComponent);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // --------------------------------------------------------------------------------------------
    // --- Render ---

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if ((this.pointerToComponent as any).state.isLoading) {
                return LoadingScreen.render();
            }

            const formGroupContent = (this.form as any).getFormGroupContent(
                FormButtonCreate.render
            );

            return (
                <div>
                    { NavigationRenderer.renderIfNecessary(this.showNavbar) }

                    {this.heading}

                    <div>
                        <Form onSubmit={(this.pointerToComponent as any).handleSubmit}>
                            {formGroupContent}
                        </Form>
                    </div>
                </div> 
            );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different Sections (for sub-classes) - Optional ---

    public isFormValid(): boolean {
        return (this.pointerToComponent as any).form.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.formValidator.isInvalidValue(valueToBeChecked);
    }

    // --------------------------------------------------------------------------------------------
    // --- Event handlers ---

    public handleChange(event) {
        this.eventHandler.handleChange(event);
    }

    public handleSubmit(event,
                        callbackForActionAfterSubmitExecutedSuccessfully = (undefined as any),
                        modelMapper = (undefined as any)) {
        this.eventHandler.setCallbackForActionAfterSubmitExecutedSuccessfully(
                                callbackForActionAfterSubmitExecutedSuccessfully);
        this.eventHandler.setModelMapper(modelMapper);

        this.eventHandler.handleSubmit(event);
    }
}

export default CreateUtils;