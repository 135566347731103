import ActionBarButtonBase from '../../../../commons/ui/page/actionBar/ActionBarButtonBase';

import I18nConstants from '../../i18n/I18nConstants';

import EarningsExpensesUrlPath from '../EarningsExpensesUrlPath';

class ActionBarButton extends ActionBarButtonBase {
    public constructor(propsHistory) {
        super(propsHistory,
              EarningsExpensesUrlPath.BASE,
              I18nConstants.NS,
              I18nConstants.TITLE,
              I18nConstants.TITLE_DEFAULT);
    }
}

export default ActionBarButton;