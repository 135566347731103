import I18nConstants from '../../../../../i18n/I18nConstantsBase';
import OverviewHeadingBase from "./OverviewHeadingBase";

class OverviewHeadingSaveAsPDF extends OverviewHeadingBase {
    public static render() {
        return OverviewHeadingBase.renderByI18nKeyAndDefaultValue(
                    I18nConstants.ACTION_SAVE_AS_PDF,
                    I18nConstants.ACTION_SAVE_AS_PDF_DEFAULT
        );
    }
}

export default OverviewHeadingSaveAsPDF;