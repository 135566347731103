import KindOfStreet from '../../model/KindOfStreet';
import KindOfStreetApi from '../../api/KindOfStreetApi';

import OverviewBase from '../../../../commons/ui/page/template/group/overview/OverviewBase';

class Overview extends OverviewBase<KindOfStreet, KindOfStreetApi> {
    public constructor(props) {
        super(props,
              KindOfStreetApi.getInstance());
    }
}

export default Overview;