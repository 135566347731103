import IModelMapper from '../../../commons/model/IModelMapper';

import Tire from './Tire';

class TireModelMapper implements IModelMapper<Tire> {
    fromJsonToObject(json: any): Tire {
        let tire = new Tire();

        tire.id   = json.id;
        tire.name = json.name;

        return tire;
    }

    fromObjectToJson(object: Tire) {
        return {
            id: object.id,
            name: (object.name || "") };
    }
}

export default TireModelMapper;