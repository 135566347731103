import IModelMapper from "../../../../commons/model/IModelMapper";
import FortuneSavingRateSumGroupedByInvestmentPlanName from './FortuneSavingRateSumGroupedByInvestmentPlanName';

class FortuneSavingRateSumGroupedByInvestmentPlanNameModelMapper
        implements IModelMapper<FortuneSavingRateSumGroupedByInvestmentPlanName> {
    fromJsonToObject(json: any): FortuneSavingRateSumGroupedByInvestmentPlanName {
        let fortuneSavingRateSumGroupedByInvestmentPlanName =
            new FortuneSavingRateSumGroupedByInvestmentPlanName();

        fortuneSavingRateSumGroupedByInvestmentPlanName.name = json.name;
        fortuneSavingRateSumGroupedByInvestmentPlanName.sum = json.sum;

        return fortuneSavingRateSumGroupedByInvestmentPlanName;
    }

    fromObjectToJson(object: FortuneSavingRateSumGroupedByInvestmentPlanName) {
        return {
            name: (object.name || ""),
            sum: (object.sum || 0)
        };
    }
}

export default FortuneSavingRateSumGroupedByInvestmentPlanNameModelMapper;