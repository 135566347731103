import IModelMapper from '../../../../commons/model/IModelMapper';
import LetterPersonTitle from '../../../person/title/model/LetterPersonTitle';
import LetterSenderPerson from "./LetterSenderPerson";

class LetterSenderPersonModelMapper implements IModelMapper<LetterSenderPerson> {
    fromJsonToObject(json: any): LetterSenderPerson {
        let letterSenderPerson = new LetterSenderPerson();

        letterSenderPerson.id = json.id;
        letterSenderPerson.salutation = json.salutation;
        // Must be done like this, because on UPDATE, it could be that it has
        // a null-value because it isn't a mandatory parameter.
        letterSenderPerson.title = (json.title == null)
                                    ? new LetterPersonTitle()
                                    : json.title;
        letterSenderPerson.firstName = json.firstName;
        letterSenderPerson.lastName = json.lastName;
        letterSenderPerson.street = json.street;
        letterSenderPerson.houseNumber = json.houseNumber;
        letterSenderPerson.zip = json.zip;
        letterSenderPerson.city = json.city;
        letterSenderPerson.postOfficeBox = json.postOfficeBox;
        letterSenderPerson.country = json.country;
        letterSenderPerson.phone = json.phone;
        letterSenderPerson.mail = json.mail;
        letterSenderPerson.url = json.url;
        
        return letterSenderPerson;
    }

    fromObjectToJson(object: LetterSenderPerson) {
        return {
            id: object.id,
            salutation: (object.salutation || undefined),
            title: (object.title || undefined),
            firstName: (object.firstName || ""),
            lastName: (object.lastName || ""),
            street: (object.street || ""),
            houseNumber: (object.houseNumber || ""),
            zip: (object.zip || ""),
            city: (object.city || ""),
            postOfficeBox: (object.postOfficeBox || ""),
            country: (object.country || undefined),
            phone: (object.phone || ""),
            mail: (object.mail || ""),
            url: (object.url || "")
        };
    }
}

export default LetterSenderPersonModelMapper;