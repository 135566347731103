import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneSavingPlanGroupApiCallExecutor from '../../../group/api/FortuneSavingPlanGroupApiCallExecutor';
import FortunePriorityApiCallExecutor from '../../../../priority/api/FortunePriorityApiCallExecutor';
import FortuneInvestmentPlanApiCallExecutor from '../../../../investmentPlan/api/FortuneInvestmentPlanApiCallExecutor';
import FortuneSavingPlanApiCallExecutor from '../../../../savingPlan/item/api/FortuneSavingPlanApiCallExecutor';

import Utils from '../../../../../utilities/Utils';

import FortuneSavingPlan from '../../model/FortuneSavingPlan';
import FortuneSavingPlanApi from '../../api/FortuneSavingPlanApi';
import FortuneSavingPlanUrlPath from '../FortuneSavingPlanUrlPath';

import FortuneSavingPlanGroup from '../../../group/model/FortuneSavingPlanGroup';
import FortunePriority from '../../../../priority/model/FortunePriority';
import FortuneInvestmentPlan from '../../../../investmentPlan/model/FortuneInvestmentPlan';

import UpdateUtils from '../../../../../commons/ui/page/template/update/UpdateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:       boolean;
    wasHandleSubmitClickedAtLeastOnce:     boolean;
    Item:            FortuneSavingPlan;
    Groups:          FortuneSavingPlanGroup[];
    Priorities:      FortunePriority[];
    InvestmentPlans: FortuneInvestmentPlan[];
}

class Update extends Component<IProps, IState> {
    private apiCalls:            ApiCallExecutor;
    private api:             FortuneSavingPlanApi;
    private form:                Form;
    private updateUtils:         UpdateUtils;

    private idOfItemToBeUpdated: number;

    public constructor(props) {
        super(props);

        this.idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        this.state = {
            isLoading:       true,
            wasHandleSubmitClickedAtLeastOnce:     false,
            Item:            new FortuneSavingPlan(),
            Groups:          [],
            Priorities:      [],
            InvestmentPlans: []
        };

        this.apiCalls = new ApiCallExecutor(this);

        this.api = FortuneSavingPlanApi.getInstance();

        this.form = new Form(props, this);

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            FortuneSavingPlanUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.updateUtils.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------
    
    public getIdToBeUpdated() : number {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private fortuneSavingPlanGroupApiCallExecutor: FortuneSavingPlanGroupApiCallExecutor<Update>;
    private fortunePriorityApiCallExecutor: FortunePriorityApiCallExecutor<Update>;
    private fortuneInvestmentPlanApiCallExecutor: FortuneInvestmentPlanApiCallExecutor<Update>;
    private fortuneSavingPlanApiCallExecutor: FortuneSavingPlanApiCallExecutor<Update>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneSavingPlanGroupApiCallExecutor = new FortuneSavingPlanGroupApiCallExecutor(pointerToComponent);
        this.fortunePriorityApiCallExecutor = new FortunePriorityApiCallExecutor(pointerToComponent);
        this.fortuneInvestmentPlanApiCallExecutor = new FortuneInvestmentPlanApiCallExecutor(pointerToComponent);
        this.fortuneSavingPlanApiCallExecutor = new FortuneSavingPlanApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneSavingPlanGetById());
        orderOfCalls.push(this.addFortuneSavingPlanGroupGetAll());
        orderOfCalls.push(this.addFortunePriorityGetAll());
        orderOfCalls.push(this.addFortuneInvestmentPlanGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "fortuneSavingPlanId", 
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneSavingPlanGetById() : IApiCallback {
        return {
            callback: this.fortuneSavingPlanApiCallExecutor.getById,
            pointerToApiCall: this.fortuneSavingPlanApiCallExecutor,
            variableNameInState: "Item"
        };
    }

    private addFortuneSavingPlanGroupGetAll() : IApiCallback {
        return {
            callback: this.fortuneSavingPlanGroupApiCallExecutor.getAll,
            pointerToApiCall: this.fortuneSavingPlanGroupApiCallExecutor,
            variableNameInState: "Groups"
        };
    }

    private addFortunePriorityGetAll() : IApiCallback {
        return {
            callback: this.fortunePriorityApiCallExecutor.getAll,
            pointerToApiCall: this.fortunePriorityApiCallExecutor,
            variableNameInState: "Priorities"
        }
    }

    private addFortuneInvestmentPlanGetAll() : IApiCallback {
        return {
            callback: this.fortuneInvestmentPlanApiCallExecutor.getAll,
            pointerToApiCall: this.fortuneInvestmentPlanApiCallExecutor,
            variableNameInState: "InvestmentPlans"
        };
    }
}

export default Update;