import I18nConstants from '../../../../../i18n/I18nConstantsBase';
import OverviewButtonBase from "./OverviewButtonBase";

class OverviewButtonDelete extends OverviewButtonBase {
    public static render(pointerToComponentOrUtil, callback, callbackId: number = -1) {
        return OverviewButtonBase.render(
                    I18nConstants.ACTION_DELETE,
                    I18nConstants.ACTION_DELETE_DEFAULT,
                    pointerToComponentOrUtil,
                    callback,
                    callbackId
        );
    }
}

export default OverviewButtonDelete;