import React from 'react';
import { Component } from 'react';
import { FormFeedback, Label } from 'reactstrap';
import { Trans } from 'react-i18next';
import I18nConstants from '../../../../i18n/I18nConstantsBase';
import i18n from '../../../../i18n/i18n';

interface IProps {
    history: any;
}

interface IState {
}

abstract class FormGroupBase 
  extends Component<IProps, IState> {
    private useNoneValue: boolean;

    protected pointerToComponent;

    // --------------------------------------------------------------------------------------------
    // --- Constructor ---

    public constructor(props, pointerToComponent) {
        super(props);

        this.useNoneValue = false;

        this.pointerToComponent = pointerToComponent;
    }

    // --------------------------------------------------------------------------------------------
    // --- Members - Could be set in other classes ---

    protected setUseNoneValueForDropdown(useNoneValue: boolean) {
        this.useNoneValue = useNoneValue;
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different sections (for sub-classes) - Mandatory ---

    public abstract render();

    public abstract isInvalidValue() : boolean;

    // --------------------------------------------------------------------------------------------
    // --- Render - Different sections (for sub-classes) - Optional ---

    public renderLabelForInput(forId, i18nNS, i18nKey, i18nDefault) {
        return (
            <Label for={forId}>
                { this.renderTranslation(i18nNS, i18nKey, i18nDefault) }
            </Label>
        );
    }

    public renderTranslation(i18nNS, i18nKey, i18nDefault) {
        return (
            <Trans ns={i18nNS}
                   i18nKey={i18nKey}>
                {i18nDefault}
            </Trans>
        );
    }

    public renderFormFeedbackWithInsertValidValueMessage() {
        return (
            <FormFeedback>
                <Trans ns={I18nConstants.NS_BASE}
                       i18nKey={I18nConstants.FORM_ERROR_INSERT_VALID_VALUE}>
                    {I18nConstants.FORM_ERROR_INSERT_VALID_VALUE_DEFAULT}
                </Trans>
            </FormFeedback>
        );
    }

    public renderFormFeedbackWithChooseValidValueMessage() {
        return (
            <FormFeedback>
                <Trans ns={I18nConstants.NS_BASE}
                       i18nKey={I18nConstants.FORM_ERROR_CHOOSE_VALID_VALUE}>
                    {I18nConstants.FORM_ERROR_CHOOSE_VALID_VALUE_DEFAULT}
                </Trans>
            </FormFeedback>
        );
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Select values ---

    protected getSelectValues(valuesToBeDisplayed, titleString) {
        const defaultValue = this.getDefaultSelectValue();
        const noneValue = this.getNoneSelectValue();

        let values = valuesToBeDisplayed.map( valueToBeDisplayed =>
            <option value={valueToBeDisplayed.id}>
                {(valueToBeDisplayed as any)[titleString]}
            </option>
        );

        if (this.useNoneValue) {
            values.unshift(noneValue);
        }

        values.unshift(defaultValue);

        return values;
    }

    protected getDefaultSelectValue() {
        let optionValue = i18n.t(I18nConstants.NS_BASE + ":" + 
                                 I18nConstants.FORM_DROPDOWN_VALUES_DEFAULT);

        return (
            <option value="-1">
                --- {optionValue} ---
            </option>
        );
    }

    protected getNoneSelectValue() {
        let noneValue = i18n.t(I18nConstants.NS_BASE + ":" + 
                               I18nConstants.FORM_DROPDOWN_VALUES_NONE);

        return (
            <option value="none">
                {noneValue}
            </option> 
        );
    }
}

export default FormGroupBase;