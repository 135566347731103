import React from 'react';
import { Component } from 'react';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import UpdateUtils from '../../../../commons/ui/page/template/update/UpdateUtils';
import Form from './Form';
import Utils from '../../../../utilities/Utils';
import Letter from '../../model/Letter';
import LetterApi from '../../api/LetterApi';
import LetterUrlPath from '../LetterUrlPath';
import LetterSenderPerson from '../../../sender/person/model/LetterSenderPerson';
import LetterSenderCompany from '../../../sender/company/model/LetterSenderCompany';
import LetterAddresseePerson from '../../../addressee/person/model/LetterAddresseePerson';
import LetterAddresseeCompany from '../../../addressee/company/model/LetterAddresseeCompany';
import LetterTemplate from '../../../template/model/LetterTemplate';
import LetterApiCallExecutor from '../../api/LetterApiCallExecutor';
import LetterSenderPersonApiCallExecutor from '../../../sender/person/api/LetterSenderPersonApiCallExecutor';
import LetterSenderCompanyApiCallExecutor from '../../../sender/company/api/LetterSenderCompanyApiCallExecutor';
import LetterAddresseePersonApiCallExecutor from '../../../addressee/person/api/LetterAddresseePersonApiCallExecutor';
import LetterAddresseeCompanyApiCallExecutor from '../../../addressee/company/api/LetterAddresseeCompanyApiCallExecutor';
import LetterTemplateApiCallExecutor from '../../../template/api/LetterTemplateApiCallExecutor';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: Letter;
    SenderPeople: LetterSenderPerson[];
    SenderCompanies: LetterSenderCompany[];
    AddresseePeople: LetterAddresseePerson[];
    AddresseeCompanies: LetterAddresseeCompany[];
    Templates: LetterTemplate[];
}

class Update extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: LetterApi;
    private form: Form;
    private updateUtils: UpdateUtils;

    private idOfItemToBeUpdated: number;

    public constructor(props) {
        super(props);

        this.idOfItemToBeUpdated =
            Utils.getIdFromGetParameters(props.location.search);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = LetterApi.getInstance();

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: new Letter(),
            SenderPeople: [],
            SenderCompanies: [],
            AddresseePeople: [],
            AddresseeCompanies: [],
            Templates: []
        };

        this.form = new Form(props, this);

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            LetterUrlPath.BASE
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return (
            <h1>

            </h1>
        );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.updateUtils.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------

    public getIdToBeUpdated() {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private letterApiCallExecutor: LetterApiCallExecutor<Update>;
    private senderPersonApiCallExecutor: LetterSenderPersonApiCallExecutor<Update>;
    private senderCompanyApiCallExecutor: LetterSenderCompanyApiCallExecutor<Update>;
    private addresseePersonApiCallExecutor: LetterAddresseePersonApiCallExecutor<Update>;
    private addresseeCompanyApiCallExecutor: LetterAddresseeCompanyApiCallExecutor<Update>;
    private templateApiCallExecutor: LetterTemplateApiCallExecutor<Update>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.letterApiCallExecutor = 
                new LetterApiCallExecutor(pointerToComponent);
        this.senderPersonApiCallExecutor = 
                new LetterSenderPersonApiCallExecutor(pointerToComponent);
        this.senderCompanyApiCallExecutor = 
                new LetterSenderCompanyApiCallExecutor(pointerToComponent);
        this.addresseePersonApiCallExecutor =
                new LetterAddresseePersonApiCallExecutor(pointerToComponent);
        this.addresseeCompanyApiCallExecutor = 
                new LetterAddresseeCompanyApiCallExecutor(pointerToComponent);
        this.templateApiCallExecutor = 
                new LetterTemplateApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();
    
        orderOfCalls.push(this.addLetterGetById());
        orderOfCalls.push(this.addSenderPeopleGetAll());
        orderOfCalls.push(this.addSenderCompaniesGetAll());
        orderOfCalls.push(this.addAddresseePeopleGetAll());
        orderOfCalls.push(this.addAddresseeCompaniesGetAll());
        orderOfCalls.push(this.addTemplatesGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "letterId",
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addLetterGetById() : IApiCallback {
        return {
            callback: this.letterApiCallExecutor.getById,
            pointerToApiCall: this.letterApiCallExecutor,
            variableNameInState: "Item"
        };
    }

    private addSenderPeopleGetAll() : IApiCallback {
        return {
            callback: this.senderPersonApiCallExecutor.getAll,
            pointerToApiCall: this.senderPersonApiCallExecutor,
            variableNameInState: "SenderPeople"
        };
    }

    private addSenderCompaniesGetAll() : IApiCallback {
        return {
            callback: this.senderCompanyApiCallExecutor.getAll,
            pointerToApiCall: this.senderCompanyApiCallExecutor,
            variableNameInState: "SenderCompanies"
        };
    }

    private addAddresseePeopleGetAll() : IApiCallback {
        return {
            callback: this.addresseePersonApiCallExecutor.getAll,
            pointerToApiCall: this.addresseePersonApiCallExecutor,
            variableNameInState: "AddresseePeople"
        };
    }

    private addAddresseeCompaniesGetAll() : IApiCallback {
        return {
            callback: this.addresseeCompanyApiCallExecutor.getAll,
            pointerToApiCall: this.addresseeCompanyApiCallExecutor,
            variableNameInState: "AddresseeCompanies"
        };
    }

    private addTemplatesGetAll() : IApiCallback {
        return {
            callback: this.templateApiCallExecutor.getAll,
            pointerToApiCall: this.templateApiCallExecutor,
            variableNameInState: "Templates"
        };
    }
}

export default Update;