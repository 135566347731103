import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupContactSalutationDropdown extends FormGroupBase {
    private ID_AND_NAME = "contactSalutation";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const salutations = this.getSalutationsSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_CONTACT_SALUTATION,
                    I18nConstants.FORM_CONTACT_SALUTATION_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={(this.pointerToComponent.state.Item.contactSalutation == undefined) 
                            ? "-1" 
                            : this.pointerToComponent.state.Item.contactSalutation.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {salutations}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {  
        return false;
    }

    protected getSalutationsSelectValues() {
        const { ContactSalutations } = this.pointerToComponent.state;

        return this.getSelectValues(ContactSalutations, "name");
    }
}

export default FormGroupContactSalutationDropdown;