import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "toDoListItem";

    public static TITLE = "title";

    public static NOT_STARTED = "notStarted";
    public static IN_PROCESS_ITEMS = "inProcessItems";
    public static FINISHED_ITEMS = "finishedItems"; 

    public static FORM_DESCRIPTION = "form.description";
    public static FORM_GROUP = "form.group";
    public static FORM_TODO_UNTIL = "form.toDoUntil";
    public static FORM_STATUS_IN_PERCENT = "form.statusInPercent";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;

    public static NOT_STARTED_DEFAULT = DefaultJson.notStarted;
    public static IN_PROCESS_ITEMS_DEFAULT = DefaultJson.inProcessItems;
    public static FINISHED_ITEMS_DEFAULT = DefaultJson.finishedItems; 

    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
    public static FORM_GROUP_DEFAULT = DefaultJson.form.group;
    public static FORM_TODO_UNTIL_DEFAULT = DefaultJson.form.toDoUntil;
    public static FORM_STATUS_IN_PERCENT_DEFAULT = DefaultJson.form.statusInPercent;
}

export default I18nConstants;