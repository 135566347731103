import React from 'react';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import LetterPersonSalutation from '../../model/LetterPersonSalutation';
import LetterPersonSalutationApi from '../../api/LetterPersonSalutationApi';
import LetterPersonSalutationUrlPath from '../LetterPersonSalutationUrlPath';
import OverviewBase from '../../../../../commons/ui/page/template/overview/OverviewBase';

class Overview extends OverviewBase<LetterPersonSalutation, LetterPersonSalutationApi> {
    public constructor(props) {
        super(props,
              LetterPersonSalutationApi.getInstance()
        );

        this.setCreateUrl(LetterPersonSalutationUrlPath.CREATE);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1>
        );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_NAME}>
                        {I18nConstants.FORM_NAME_DEFAULT}
                    </Trans>
                </th>

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).name}
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr>
        );
    }
}

export default Overview;