import IModelMapper from '../../../commons/model/IModelMapper';

import ShoppingList from './ShoppingList';

class ShoppingListModelMapper implements IModelMapper<ShoppingList> {
    fromJsonToObject(json: any): ShoppingList {
        let shoppingList = new ShoppingList();

        shoppingList.id             = json.id;
        shoppingList.group          = json.group;
        shoppingList.description    = json.description;
        shoppingList.quantity       = json.quantity;
        shoppingList.kindOfQuantity = json.kindOfQuantity;
        shoppingList.amount         = json.amount;

        return shoppingList;
    }

    fromObjectToJson(object: ShoppingList) {
        return {
            id:             object.id,
            group:          (object.group || undefined),
            description:    (object.description || ""),
            quantity:       (object.quantity || 0),
            kindOfQuantity: (object.kindOfQuantity || undefined),
            amount:         (object.amount || 0)
        };
    }
}

export default ShoppingListModelMapper;