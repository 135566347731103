import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';

import EarningOverheadTransactionModelMapper from '../model/EarningOverheadTransactionModelMapper';

class EarningOverheadTransactionApi implements IGenericApi {
    private static instance: EarningOverheadTransactionApi;

    private static apiUrlPath = "earning/overhead/transaction";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): EarningOverheadTransactionApi {
        if (!EarningOverheadTransactionApi.instance) {
            EarningOverheadTransactionApi.instance = new EarningOverheadTransactionApi();
        }

        return EarningOverheadTransactionApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    EarningOverheadTransactionApi.apiUrlPath,
                    new EarningOverheadTransactionModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    EarningOverheadTransactionApi.apiUrlPath,
                    id,
                    new EarningOverheadTransactionModelMapper());
    }

    public getByOverheadId(id) {
        let filter = "overheadId" + "=" + id.toString();

        return GenericApi.executeGetCallWithFilter(
                    EarningOverheadTransactionApi.apiUrlPath,
                    filter,
                    new EarningOverheadTransactionModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    EarningOverheadTransactionApi.apiUrlPath,
                    body,
                    new EarningOverheadTransactionModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    EarningOverheadTransactionApi.apiUrlPath,
                    id,
                    body,
                    new EarningOverheadTransactionModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    EarningOverheadTransactionApi.apiUrlPath,
                    id,
                    new EarningOverheadTransactionModelMapper());
    }

    public getUrl() {
        return EarningOverheadTransactionApi.apiUrlPath;
    }
}

export default EarningOverheadTransactionApi;