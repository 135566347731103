import OverviewApiCallBase from "./OverviewApiCallBase";

class OverviewApiCallDelete extends OverviewApiCallBase {
    public execute(pointerToComponent, api, id) {
        api.delete(id)
        .then( response => { 
                let list = pointerToComponent.state.List;

                // Remove the currently in backend removed item also in frontend.
                let indexToBeRemoved = list.map(function(item) { return item.id }).indexOf(id);

                if (indexToBeRemoved >= 0) {
                    list.splice(indexToBeRemoved, 1);
                }

                pointerToComponent.setState({ List: list });
        })
        .catch( error   => { console.log("TODO"); });
    }
}

export default OverviewApiCallDelete;