import ApiCallExecutorBase from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';

import VehicleApi from './VehicleApi';

class VehicleApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private vehicleApi: VehicleApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.vehicleApi = VehicleApi.getInstance();
    }

    public getAll(context: IApiCallContext) {
        let vehicleIdSelection = 0;

        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.vehicleApi.getAll()
        .then(  response => {
                    // Get the last vehicle.
                    vehicleIdSelection = (response as any)[(response as any).length -1].id;

                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    context.pointerToHelper.addParameterToContext(context,
                                                "vehicleId", vehicleIdSelection);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }

    public getById(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const vehicleId = (context.pointerToHelper.getParameterInArrayWithObjects(
                                context.parameters, "vehicleId") as number);

        context.currentData.pointerToApiCall.vehicleApi.getById(vehicleId)
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }

    public setVehicleIdFromParametersToState(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const vehicleId = (context.pointerToHelper.getParameterInArrayWithObjects(
                                context.parameters, "vehicleId") as number);

        const state = context.pointerToHelper.createStateObject(
                        context.currentData.variableNameInState,
                        vehicleId);

        context.pointerToComponent.setState(state);

        try {
            successorCallback.callback(context);
        } catch (error) { 
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }
}

export default VehicleApiCallExecutor;