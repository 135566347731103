import ApiCallExecutorBase from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';

import FuelUnitApi from './FuelUnitApi';

class FuelUnitApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private fuelUnitApi: FuelUnitApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.fuelUnitApi = FuelUnitApi.getInstance();
    }

    public getAll(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fuelUnitApi.getAll()
        .then(  response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error    => {
                    console.log("TODO");
                });
    }
}

export default FuelUnitApiCallExecutor;