import BikeType from '../../type/model/BikeType';

class Bike {
    id!: number;
    bikeType!: BikeType;
    brand!: string;
    model!: string;
    identificationNumber!: string;
    description!: string;

    public constructor() {
        this.bikeType = new BikeType();
    }
}

export default Bike;