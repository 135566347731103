import IModelMapper from "../../../../commons/model/IModelMapper";
import FortuneExpensesPlannedGroup from './FortuneExpensePlannedGroup';

class FortuneExpensePlannedGroupModelMapper implements IModelMapper<FortuneExpensesPlannedGroup> {
    fromJsonToObject(json: any): FortuneExpensesPlannedGroup {
        let fortuneExpensesPlannedGroup = new FortuneExpensesPlannedGroup();

        fortuneExpensesPlannedGroup.id   = json.id;
        fortuneExpensesPlannedGroup.name = json.name;

        return fortuneExpensesPlannedGroup;
    }

    fromObjectToJson(object: FortuneExpensesPlannedGroup) {
        return {
            id:   object.id,
            name: (object.name || "")
        };
    }
}

export default FortuneExpensePlannedGroupModelMapper;