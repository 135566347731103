import React from 'react';
import { Button, Input, Label, FormGroup } from 'reactstrap';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import I18nConstants from '../../../item/i18n/I18nConstants';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import ExpenseOverheadTransactionApiCallExecutor from '../../api/ExpenseOverheadTransactionApiCallExecutor';

import Utils from '../../../../../utilities/Utils';

import AuthenticationApi from '../../../../../security/api/AuthenticationApi';

import LoadingScreen from '../../../../../commons/ui/page/loadingScreen/LoadingScreen';

import ExpenseOverheadTransaction from '../../model/ExpenseOverheadTransaction';
import ExpenseOverheadTransactionUrlPath from '../EarningsExpensesOverheadTransactionExpenseUrlPath';
import ExpenseOverheadTransactionApi from '../../api/ExpenseOverheadTransactionApi';

import LoginUrlPath from '../../../../../login/ui/LoginUrlPath';

import OverviewUtils from '../../../../../commons/ui/page/template/overview/OverviewUtils';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:   boolean;
    List:        ExpenseOverheadTransaction[];
}

class Overview extends Component<IProps, IState> {
    private apiCalls:        ApiCallExecutor;
    private api:         ExpenseOverheadTransactionApi;
    private overviewUtils:   OverviewUtils;

    private idToBeDisplayed: number;

    private createUrl:       string;
    private updateUrl:       string;
    private updateAllUrl:    string;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = ExpenseOverheadTransactionApi.getInstance();

        this.idToBeDisplayed =
            Utils.getOverheadIdFromGetParameters(props.location.search);

        this.createUrl = ExpenseOverheadTransactionUrlPath.CREATE;
        this.updateUrl = ExpenseOverheadTransactionUrlPath.UPDATE;
        this.updateAllUrl = ExpenseOverheadTransactionUrlPath.UPDATE_ALL;

        this.state = {
            isLoading: true,
            List:      []
        };

        this.overviewUtils = new OverviewUtils(
            this,
            this.getHeading(),
            this.createUrl,
            this.updateUrl,
            this.getCreateButton());
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (    (!this.idToBeDisplayed)
                 || (this.idToBeDisplayed <= 0)) {
                // TODO
                return (<div>No ID specified!</div>);
            }

            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return (
                <div>
                    { this.overviewUtils.render(
                        this.getTableHead(),
                        this.getTableBody(),
                        this.getButtonUpdateAll()) }
                </div> );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return(
            <h1>

            </h1>) ;
    }

    private getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DAY}>
                        {I18nConstants.FORM_DAY_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_MONTH}>
                        {I18nConstants.FORM_MONTH_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AMOUNT}>
                        {I18nConstants.FORM_AMOUNT_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_MUST_BE_CHANGED}>
                        {I18nConstants.FORM_MUST_BE_CHANGED_DEFAULT}
                    </Trans>
                </th>

                { this.overviewUtils.getUpdateHeadingWrappedInThTag() }
                { this.overviewUtils.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    private getTableBody() {
        return this.state.List.map( item => 
            <tr key={(item as any).id}>
                <td>
                    {(item as any).day}
                </td>

                <td>
                    {(item as any).month}
                </td>

                <td>
                    {(item as any).amount}
                </td>

                <td id="checkbox">
                    <FormGroup check>
                        <Label check>
                            <Input 
                                type="checkbox"
                                checked={(item as any).mustBeChanged}
                                disabled="true" />
                        </Label>
                    </FormGroup>
                </td>

                { this.getUpdateButton((item as any).id) }
                { this.overviewUtils.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }

    public getCreateButton() {
        const overheadId = this.idToBeDisplayed;

        return (
            <Button onClick={() => this.onCreate(overheadId) }>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.ACTION_CREATE}>
                    {I18nConstants.ACTION_CREATE_DEFAULT}
                </Trans>
            </Button> );
    }

    public getUpdateButton(id) {
        return (
            <td>
                <Button onClick={ () => this.onUpdate(id) }>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.ACTION_UPDATE}>
                        {I18nConstants.ACTION_UPDATE_DEFAULT}
                    </Trans>
                </Button>
            </td> );
    }

    public getButtonUpdateAll() {
        const overheadId = this.idToBeDisplayed;

        return (
            <Button onClick={() => this.onUpdateAll(overheadId)}>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.ACTION_UPDATE_ALL}>
                    {I18nConstants.ACTION_UPDATE_ALL_DEFAULT}
                </Trans>
            </Button>
        );
    }

    // --------------------------------------------------------------------------------------------
    
    private onCreate(overheadId) {
        const createUrl = this.createUrl + "?" + "overheadId" + "=" + overheadId.toString();

        this.props.history.push(createUrl);
    }

    private onUpdate(id) {
        let updateUrl    = "";
        const overheadId = this.idToBeDisplayed;

        if (id) {
            updateUrl = this.updateUrl + "?overheadId" + "=" + overheadId.toString() + "&" + 
                                         "id" + "=" + id.toString();

            this.props.history.push(updateUrl);
        }
    }

    private onUpdateAll(overheadId) {
        let updateAllUrl = "";
        
        if (overheadId) {
            updateAllUrl = this.updateAllUrl + "?overheadId" + "=" + overheadId.toString();

            this.props.history.push(updateAllUrl);
        }
    }

    // --------------------------------------------------------------------------------------------
    
    public getIdToBeDisplayed() : number {
        return this.idToBeDisplayed;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private helper: ApiCallExecutorHelper;

    private expenseOverheadTransactionApiCallExecutor:
                ExpenseOverheadTransactionApiCallExecutor<Overview>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.expenseOverheadTransactionApiCallExecutor = 
                new ExpenseOverheadTransactionApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addExpenseOverheadTransactionGetByOverheadId());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "overheadId", 
                                          this.pointerToComponent.getIdToBeDisplayed());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addExpenseOverheadTransactionGetByOverheadId() : IApiCallback {
        return {
            callback: this.expenseOverheadTransactionApiCallExecutor.getByOverheadId,
            pointerToApiCall: this.expenseOverheadTransactionApiCallExecutor,
            variableNameInState: "List"
        };
    }
}

export default Overview;