import React from 'react';

import FormGroupDescription from '../../../../item/ui/fragment/FormGroupDescription';
import FormGroupGroupDropdown from '../../../../item/ui/fragment/FormGroupGroupDropdown';
import FormGroupDate from '../../../../item/ui/fragment/FormGroupDate';
import FormGroupAmount from '../../../../item/ui/fragment/FormGroupAmount';
import FormGroupIsOverhead from '../../../../item/ui/fragment/FormGroupIsOverhead';

class Form {
    private fromGroupDescription: FormGroupDescription;
    private formGroupGroup:       FormGroupGroupDropdown;
    private formGroupDate:        FormGroupDate;
    private formGroupAmount:      FormGroupAmount;
    private formGroupIsOverhead:  FormGroupIsOverhead;

    public constructor(props, pointerToComponent) {
        this.fromGroupDescription = new FormGroupDescription(props, pointerToComponent);
        this.formGroupGroup       = new FormGroupGroupDropdown(props, pointerToComponent);
        this.formGroupDate        = new FormGroupDate(props, pointerToComponent);
        this.formGroupAmount      = new FormGroupAmount(props, pointerToComponent);
        this.formGroupIsOverhead  = new FormGroupIsOverhead(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.fromGroupDescription.render() }
                { this.formGroupGroup.render() }
                { this.formGroupDate.render() }
                { this.formGroupAmount.render() }
                { this.formGroupIsOverhead.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.fromGroupDescription.isInvalidValue()
             || this.formGroupGroup.isInvalidValue()
             || this.formGroupDate.isInvalidValue()
             || this.formGroupAmount.isInvalidValue()
             || this.formGroupIsOverhead.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;