import BankingListGroup from '../../model/BankingListGroup';
import BankingListGroupApi from '../../api/BankingListGroupApi';
import OverviewBase from '../../../../commons/ui/page/template/group/overview/OverviewBase';

class Overview extends OverviewBase<BankingListGroup, BankingListGroupApi> {
    public constructor(props) {
        super(props,
              BankingListGroupApi.getInstance()
        );
    }
}

export default Overview;