import Utils from '../../../../../utilities/Utils';
import Expense from '../../model/Expense';
import ExpenseGroup from '../../../group/model/ExpenseGroup';
import ExpenseApi from '../../api/ExpenseApi';
import ExpenseGroupApi from '../../../group/api/ExpenseGroupApi';
import EarningsExpensesUrlPath from '../../../../item/ui/EarningsExpensesUrlPath';
import UpdateBaseWithGroup from '../../../../../commons/ui/page/template/update/UpdateBaseWithGroup';
import Form from './Form';
import FormButtonUpdate from '../../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBaseWithGroup<Expense, ExpenseGroup,
                                         ExpenseApi, ExpenseGroupApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated =
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new Expense(),
              ExpenseApi.getInstance(),
              ExpenseGroupApi.getInstance(),
              idOfItemToBeUpdated,
              EarningsExpensesUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;