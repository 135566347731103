import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupAddToFortune extends FormGroupBase {
    private ID_AND_NAME = "addToFortune";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup check>
                <Label check>
                    <Input 
                        type="checkbox"
                        name={this.ID_AND_NAME}
                        id={this.ID_AND_NAME}
                        checked={this.pointerToComponent.state.Item.addToFortune}
                        onChange={this.pointerToComponent.handleChange}
                        invalid={this.isInvalidValue()} />{' '}
                        
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_ADD_TO_FORTUNE}>
                        {I18nConstants.FORM_ADD_TO_FORTUNE_DEFAULT}
                    </Trans>
                </Label>
            </FormGroup> );
    }

    public isInvalidValue(): boolean {
        // A checkbox is always not mandatory.
        return false;
    }
}

export default FormGroupAddToFortune;