import IModelMapper from '../../../../commons/model/IModelMapper';
import ExpenseOverheadTransaction from './ExpenseOverheadTransaction';

class ExpenseOverheadTransactionModelMapper implements IModelMapper<ExpenseOverheadTransaction> {
    fromJsonToObject(json: any): ExpenseOverheadTransaction {
        let expenseOverheadTransaction = new ExpenseOverheadTransaction();
        
        expenseOverheadTransaction.id              = json.id;
        expenseOverheadTransaction.expenseOverhead = json.expenseOverhead;
        expenseOverheadTransaction.day             = json.day;
        expenseOverheadTransaction.month           = json.month;
        expenseOverheadTransaction.recurrence      = json.recurrence;
        expenseOverheadTransaction.amount          = json.amount;
        expenseOverheadTransaction.mustBeChanged   = json.mustBeChanged;

        return expenseOverheadTransaction;
    }

    fromObjectToJson(object: ExpenseOverheadTransaction) {
        return {
            id:              object.id,
            expenseOverhead: (object.expenseOverhead || undefined),
            day:             (object.day || 1),
            month:           (object.month || ""),
            recurrence:      (object.recurrence || ""),
            amount:          (object.amount || 0),
            mustBeChanged:   (object.mustBeChanged || false)
        }
    }
}

export default ExpenseOverheadTransactionModelMapper;