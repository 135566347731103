import IModelMapper from '../../../commons/model/IModelMapper';
import LetterTemplate from './LetterTemplate';

class LetterTemplateModelMapper implements IModelMapper<LetterTemplate> {
    fromJsonToObject(json: any): LetterTemplate {
        let letterTemplate = new LetterTemplate();

        letterTemplate.id = json.id;
        letterTemplate.description = json.description;
        letterTemplate.content = json.content;

        return letterTemplate;
    }

    fromObjectToJson(object: LetterTemplate) {
        return {
            id: object.id,
            description: (object.description || ""),
            content: (object.content || "")
        };
    }
}

export default LetterTemplateModelMapper;