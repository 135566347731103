import React from 'react';
import { Component } from 'react';
import { Button } from 'reactstrap';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import EarningsExpensesOverheadApiCallExecutor from '../../api/EarningsExpensesOverheadApiCallExecutor';

import EarningsExpensesUrlPath from '../../../../earningsExpenses/item/ui/EarningsExpensesUrlPath';
import EarningsExpensesOverheadUrlPath from '../EarningsExpensesOverheadUrlPath';

import Navigation from '../../../../commons/ui/fragment/Navigation';

import EarningOverheadOverview from '../../../earning/item/ui/page/Overview';
import ExpenseOverheadOverview from '../../../expense/item/ui/page/Overview';

import EarningsExpensesActionBarButton from '../../../../earningsExpenses/item/ui/fragment/ActionBarButton';
import EarningsExpensesOverheadGroupingActionBarButton from '../fragment/ActionBarButtonGrouped';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:   boolean;
    sum:         number;
    savingRatio: number;
}

class Overview extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;

    private groupingUrl:         string;
    private earningsExpensesUrl: string;

    private showNavbar:  boolean;

    private earningsExpensesActionBarButton: EarningsExpensesActionBarButton;
    private earningsExpensesOverheadGroupingActionBarButton: EarningsExpensesOverheadGroupingActionBarButton;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);
        
        this.groupingUrl         = EarningsExpensesOverheadUrlPath.GROUPED;
        this.earningsExpensesUrl = EarningsExpensesUrlPath.BASE;

        this.showNavbar = true;

        this.earningsExpensesActionBarButton = new EarningsExpensesActionBarButton(props.history);
        this.earningsExpensesOverheadGroupingActionBarButton = new EarningsExpensesOverheadGroupingActionBarButton(props.history);

        this.state = {
            isLoading:   true,
            sum:         0.0,
            savingRatio: 0.0
        };
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (this.state.isLoading) {
            return LoadingScreen.render();
        } else {
            return (
                <div>
                    { this.getNavbar() }

                    <h1>
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.TITLE}>
                            {I18nConstants.TITLE_DEFAULT}
                        </Trans>
                    </h1>

                    <p>
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.PROFIT_OR_LOSS}>
                            {I18nConstants.PROFIT_OR_LOSS_DEFAULT}
                        </Trans>
                        : {this.state.sum} 
                        <br />
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.SAVING_RATIO}>
                            {I18nConstants.SAVING_RATIO_DEFAULT}
                        </Trans>
                        : {this.state.savingRatio} %
                    </p>

                    { this.getActionBar() }

                    <EarningOverheadOverview history={this.props.history} location={this.props.location} {...this.state} />
                    <ExpenseOverheadOverview history={this.props.history} location={this.props.location} {...this.state} />
                </div>
            );
        }
    }

    private getNavbar() {
        if (this.showNavbar) {
            return ( <Navigation /> );
        } else {
            return ( <div></div> );
        }
    }

    private getActionBar() {
        return (
            <div>
                { this.earningsExpensesActionBarButton.render() }
                { this.earningsExpensesOverheadGroupingActionBarButton.render() }
            </div> );
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private helper: ApiCallExecutorHelper;

    private earningsExpensesOverheadApiCallExecutor: EarningsExpensesOverheadApiCallExecutor<Overview>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.earningsExpensesOverheadApiCallExecutor = new EarningsExpensesOverheadApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningsExpensesOverheadGetSum());
        orderOfCalls.push(this.addEarningsExpensesOverheadGetSavingRatio());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addEarningsExpensesOverheadGetSum() : IApiCallback {
        return {
            callback: this.earningsExpensesOverheadApiCallExecutor.getSum,
            pointerToApiCall: this.earningsExpensesOverheadApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addEarningsExpensesOverheadGetSavingRatio() : IApiCallback {
        return {
            callback: this.earningsExpensesOverheadApiCallExecutor.getSavingRatio,
            pointerToApiCall: this.earningsExpensesOverheadApiCallExecutor,
            variableNameInState: "savingRatio"
        };
    }
}

export default Overview;