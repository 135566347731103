import ExpenseGroup from '../../group/model/ExpenseGroup';

class Expense {
    id!: number;
    group!: ExpenseGroup;
    description!: string;
    date!: Date;
    amount!: number;
    isOverhead!: boolean;  

    public constructor() {
        this.group      = new ExpenseGroup();
        this.isOverhead = false;
    }
}

export default Expense;