import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "cyclingTour";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static SUM_DISTANCE = "sumDistance";

    public static FORM_BIKE = "form.bike";
    public static FORM_DATE = "form.date";
    public static FORM_TRIP_DISTANCE_IN_KM = "form.tripDistanceInKm";
    public static FORM_RIDE_TIME = "form.rideTime";
    public static FORM_AVERAGE_SPEED_IN_KMH = "form.averageSpeedInKmh";
    public static FORM_MAX_SPEED_IN_KMH = "form.maxSpeedInKmh";
    public static FORM_AVERAGE_POWER_IN_WATT = "form.averagePowerInWatt";
    public static FORM_ALTITUDE_ASCENDING_IN_M = "form.altitudeAscendingInM";
    public static FORM_DISTANCE_ASCENDING_IN_KM = "form.distanceAscendingInKm";
    public static FORM_MAX_ALTITUDE_IN_M = "form.maxAltitudeInM";
    public static FORM_AVERAGE_HEART_RATE = "form.averageHeartRate";
    public static FORM_MAX_HEART_RATE = "form.maxHeartRate";
    public static FORM_KILO_CALORIES = "form.kiloCalories";
    public static FORM_NOTE = "form.note";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static SUM_DISTANCE_DEFAULT = DefaultJson.sumDistance;

    public static FORM_BIKE_DEFAULT = DefaultJson.form.bike;
    public static FORM_DATE_DEFAULT = DefaultJson.form.date;
    public static FORM_TRIP_DISTANCE_IN_KM_DEFAULT = DefaultJson.form.tripDistanceInKm;
    public static FORM_RIDE_TIME_DEFAULT = DefaultJson.form.rideTime;
    public static FORM_AVERAGE_SPEED_IN_KMH_DEFAULT = DefaultJson.form.averageSpeedInKmh;
    public static FORM_MAX_SPEED_IN_KMH_DEFAULT = DefaultJson.form.maxSpeedInKmh;
    public static FORM_AVERAGE_POWER_IN_WATT_DEFAULT = DefaultJson.form.averagePowerInWatt;
    public static FORM_ALTITUDE_ASCENDING_IN_M_DEFAULT = DefaultJson.form.altitudeAscendingInM;
    public static FORM_DISTANCE_ASCENDING_IN_KM_DEFAULT = DefaultJson.form.distanceAscendingInKm;
    public static FORM_MAX_ALTITUDE_IN_M_DEFAULT = DefaultJson.form.maxAltitudeInM;
    public static FORM_AVERAGE_HEART_RATE_DEFAULT = DefaultJson.form.averageHeartRate;
    public static FORM_MAX_HEART_RATE_DEFAULT = DefaultJson.form.maxHeartRate;
    public static FORM_KILO_CALORIES_DEFAULT = DefaultJson.form.kiloCalories;
    public static FORM_NOTE_DEFAULT = DefaultJson.form.note;
}

export default I18nConstants;