import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import ShoppingList from '../../model/ShoppingList';
import ShoppingListApi from '../../api/ShoppingListApi';
import ShoppingListUrlPath from '../ShoppingListUrlPath';

import ShoppingListGroupUrlPath from '../../../group/ui/ShoppingListGroupUrlPath';

import OverviewBase from '../../../../commons/ui/page/template/overview/OverviewBase';

import ShoppingListGroupActionBarButton from '../../../group/ui/fragment/ActionBarButton';
import ShoppingListKindOfQuantitiesActionBarButton from '../../../kindOfQuantity/ui/fragment/ActionBarButton';

class Overview extends OverviewBase<ShoppingList, ShoppingListApi> {
    private groupActionBarButton: ShoppingListGroupActionBarButton;
    private kindOfQuantitiesActionBarButton: ShoppingListKindOfQuantitiesActionBarButton;
    
    public constructor(props) {
        super(props,
              ShoppingListApi.getInstance());

        this.setCreateUrl(ShoppingListUrlPath.CREATE);
        this.setGroupsOverviewUrl(ShoppingListGroupUrlPath.BASE);

        this.groupActionBarButton = new ShoppingListGroupActionBarButton(props.history);
        this.kindOfQuantitiesActionBarButton = new ShoppingListKindOfQuantitiesActionBarButton(props.history);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE}>
                    {I18nConstants.TITLE_DEFAULT}
                </Trans>
            </h1> );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.CHECKBOX_IS_ADDED_TO_CHART}>
                        {I18nConstants.CHECKBOX_IS_ADDED_TO_CHART_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_QUANTITY}>
                        {I18nConstants.FORM_QUANTITY_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AMOUNT}>
                        {I18nConstants.FORM_AMOUNT_DEFAULT}
                    </Trans>
                </th>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_GROUP}>
                        {I18nConstants.FORM_GROUP_DEFAULT}
                    </Trans>
                </th>

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td id="checkbox">
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" />
                        </Label>
                    </FormGroup>
                </td>
                <td>
                    {(item as any).description}
                </td>

                <td>
                    {(item as any).quantity} {(item as any).kindOfQuantity.name}
                </td>

                <td>
                    {(item as any).amount}
                </td>

                <td>
                    {(item as any).group.name}
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }

    protected getActionBar() {
        return (
            <div>
                { this.groupActionBarButton.render() }
                { this.kindOfQuantitiesActionBarButton.render() }
            </div> );
    }
}

export default Overview;