import I18nConstants from '../../../../../i18n/I18nConstants';
import OverviewButtonBase from "./OverviewButtonBase";

class OverviewButtonGroups extends OverviewButtonBase {
    public static render(pointerToComponentOrUtil, callback) {
        return OverviewButtonBase.render(
                    I18nConstants.FORM_GROUP_TITLE,
                    I18nConstants.FORM_GROUP_TITLE_DEFAULT,
                    pointerToComponentOrUtil,
                    callback,
                    -1,
                    true
        );
    }
}

export default OverviewButtonGroups;