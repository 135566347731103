import React from 'react';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import ShoppingListKindOfQuantity from '../../model/ShoppingListKindOfQuantity';
import ShoppingListKindOfQuantityApi from '../../api/ShoppingListKindOfQuantityApi';
import ShoppingListKindOfQuantityUrlPath from '../ShoppingListKindOfQuantityUrlPath';

import UpdateBase from '../../../../commons/ui/page/template/group/update/UpdateBase';

class Update extends UpdateBase<ShoppingListKindOfQuantity, ShoppingListKindOfQuantityApi> {
    public constructor(props) {
        super(props,
              new ShoppingListKindOfQuantity(),
              ShoppingListKindOfQuantityApi.getInstance(),
              ShoppingListKindOfQuantityUrlPath.BASE);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.CRUD_UPDATE}>
                    {I18nConstants.CRUD_UPDATE_DEFAULT}
                </Trans>
            </h1> );
    }
}

export default Update;