import ToDoListGroup from '../../model/ToDoListGroup';
import ToDoListGroupApi from '../../api/ToDoListGroupApi';

import OverviewBase from '../../../../commons/ui/page/template/group/overview/OverviewBase';

class Overview extends OverviewBase<ToDoListGroup, ToDoListGroupApi> {
    public constructor(props) {
        super(props,
              ToDoListGroupApi.getInstance());
    }
}
 
export default Overview;