import IModelMapper from '../../../../commons/model/IModelMapper';
import Expense from './Expense';

class ExpenseModelMapper implements IModelMapper<Expense> {
    fromJsonToObject(json: any): Expense {
        let expense = new Expense();

        expense.id          = json.id;
        expense.group       = json.group;
        expense.description = json.description;
        expense.date        = json.date;
        expense.amount      = json.amount;
        expense.isOverhead  = json.isOverhead;

        return expense;
    }

    fromObjectToJson(object: Expense) {
        return {
            id:          object.id,
            group:       (object.group || undefined),
            description: (object.description || ""),
            date:        (object.date || "0001-01-01"),
            amount:      (object.amount || 0),
            isOverhead:  (object.isOverhead || false)
        };
    }
}

export default ExpenseModelMapper;