import IModelMapper from "../../../../commons/model/IModelMapper";
import FortuneExpensePlanned from './FortuneExpensePlanned';

class FortuneExpensePlannedModelMapper implements IModelMapper<FortuneExpensePlanned> {
    fromJsonToObject(json: any): FortuneExpensePlanned {
        let fortuneExpensesPlanned = new FortuneExpensePlanned();

        fortuneExpensesPlanned.id          = json.id;
        fortuneExpensesPlanned.group       = json.group;
        fortuneExpensesPlanned.description = json.description;
        fortuneExpensesPlanned.amount      = json.amount;

        return fortuneExpensesPlanned;
    }

    fromObjectToJson(object: FortuneExpensePlanned) {
        return {
            id:          object.id,
            group:       (object.group || undefined),
            description: (object.description || ""),
            amount:      (object.amount || 0)
        }
    }
}

export default FortuneExpensePlannedModelMapper;