import React from 'react';
import { Component } from 'react';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import Navigation from '../../../../commons/ui/fragment/Navigation';
import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';
import FortuneGroupedByRiskClassesAndGroupsOverview from './FortuneGroupedByRiskClassesAndGroupsOverview';
import FortuneGroupedByInvestmentPlanOverview from './FortuneGroupedByInvestmentPlanOverview';
import FortuneGroupedBySavingPlanOverview from './FortuneGroupedBySavingPlanOverview';
import FortuneSavingPlanMonthlyRateByInvestmentPlanOverview from './FortuneSavingPlanMonthlyRateByInvestmentPlanOverview';
import FortuneSavingPlanMonthlyRateBySavingPlanOverview from './FortuneSavingPlanMonthlyRateBySavingPlanOverview';
import FortuneSavingRatioOverview from './FortuneSavingRatioOverview';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading: boolean;
}

class Overview extends Component<IProps, IState> {
    private showNavbar: boolean;

    public constructor(props) {
        super(props);

        this.showNavbar = true;

        this.state = {
            isLoading: false
        };
    }

    public render() {
        if (this.state.isLoading) {
            return LoadingScreen.render();
        } else {
            return (
                <div>
                    { this.getNavbar() }

                    <h1>
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.TITLE}>
                            {I18nConstants.TITLE_DEFAULT}
                        </Trans>
                    </h1>

                    <FortuneGroupedByRiskClassesAndGroupsOverview
                        history={this.props.history}
                        location={this.props.location}
                        {...this.state} />

                    <FortuneGroupedByInvestmentPlanOverview 
                        history={this.props.history}
                        location={this.props.location}
                        {...this.state} />

                    <FortuneGroupedBySavingPlanOverview 
                        history={this.props.history}
                        location={this.props.location}
                        {...this.state} />

                    <FortuneSavingPlanMonthlyRateByInvestmentPlanOverview 
                        history={this.props.history}
                        location={this.props.location}
                        {...this.state} />

                    <FortuneSavingPlanMonthlyRateBySavingPlanOverview 
                        history={this.props.history}
                        location={this.props.location}
                        {...this.state} />

                    <FortuneSavingRatioOverview
                        history={this.props.history}
                        location={this.props.location}
                        {...this.state} />
                </div> );
        }
    }

    private getNavbar() {
        if (this.showNavbar) {
            return ( <Navigation /> );
        } else {
            return ( <div></div> );
        }
    }

    // --------------------------------------------------------------------------------------------

    // TODO: Action bar
}

export default Overview;