import FormEventHandlerBase from './FormEventHandlerBase';

class FormEventHandlerUpdate extends FormEventHandlerBase {
    public constructor(pointerToComponent, api, nextPageUrl) {
        super(pointerToComponent, api, nextPageUrl);
    }

    protected executeSubmitApiCall(item) {
        item = this.executeModelMapperIfNecessary(item);

        (this.api as any).update((item as any).id, item)
        .then(  result => { 
                    (this.pointerToComponent as any).props.history.push(this.nextPageUrl);
                })
        .catch( error =>  { 
                    console.log("TODO");
                });
    }
}

export default FormEventHandlerUpdate;