import React from 'react';
import { Button } from 'reactstrap';
import { Trans } from 'react-i18next';

import I18nConstants from '../../../../item/i18n/I18nConstants';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import ExpenseOverheadApiCallExecutor from '../../api/ExpenseOverheadApiCallExecutor';
import ExpenseOverheadGroupApiCallExecutor from '../../../group/api/ExpenseOverheadGroupApiCallExecutor';

import ExpenseOverhead from '../../model/ExpenseOverhead';
import ExpenseOverheadApi from '../../api/ExpenseOverheadApi';
import ExpenseOverheadUrlPath from '../EarningsExpensesOverheadExpenseUrlPath';

import OverviewBase from '../../../../../commons/ui/page/template/overview/OverviewBase';

import PieChartWithSumAndDiagram from '../../../../../commons/ui/page/diagram/PieChartWithSumAndPercentage';

class Overview extends OverviewBase<ExpenseOverhead, ExpenseOverheadApi> {
    private apiCalls: ApiCallExecutor;
    private diagram: PieChartWithSumAndDiagram;
    
    public constructor(props) {
        super(props,
              ExpenseOverheadApi.getInstance());

        this.diagram = new PieChartWithSumAndDiagram();
        this.apiCalls = new ApiCallExecutor(this, this.diagram);

        this.setCreateUrl(ExpenseOverheadUrlPath.CREATE);
        this.setUpdateUrl(ExpenseOverheadUrlPath.UPDATE);

        this.setShowNavbar(false);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.EXPENSES}>
                    {I18nConstants.EXPENSES_DEFAULT}
                </Trans>
            </h1> );
    }

    protected getSum() {
        return (
            <div>
                <p>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.SUM}>
                        {I18nConstants.SUM_DEFAULT}
                    </Trans>
                    : {this.state.sum}
                </p>
            </div> );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_GROUP}>
                        {I18nConstants.FORM_GROUP_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_AMOUNT}>
                        {I18nConstants.FORM_AMOUNT_DEFAULT}
                    </Trans>
                </th>

                { this.getTransactionHeadingWrappedInThTag() }

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr> );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).description}
                </td>

                <td>
                    {(item as any).group.name}
                </td>

                <td>
                    {(item as any).amount}
                </td>

                { this.getTransactionButtonWrappedInTdTag((item as any).id) }

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr>);
    }

    protected getDiagramAfterContent() {
        return this.diagram.getHtmlElement(this.state.diagram);
    }

    protected getInitialValuesForListInState() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    private getTransactionHeadingWrappedInThTag() {
        return (
            <th>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TRANSACTIONS}>
                    {I18nConstants.TRANSACTIONS_DEFAULT}
                </Trans>
            </th> );
    }

    private getTransactionButtonWrappedInTdTag(id) {
        return (
            <td>
                <Button onClick={ () => this.onAddTransaction(id) }>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.TRANSACTIONS}>
                        {I18nConstants.TRANSACTIONS_DEFAULT}
                    </Trans>
                </Button>
            </td> );
    }

    // --------------------------------------------------------------------------------------------

    protected onAddTransaction(id) {
        let addTransactionUrl = "/earningsExpenses/overhead/transaction/expense";

        addTransactionUrl += "?overheadId=" + id.toString();

        this.props.history.push(addTransactionUrl);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;
    private pointerToDiagram: PieChartWithSumAndDiagram;

    private helper: ApiCallExecutorHelper;

    private expenseOverheadApiCallExecutor: ExpenseOverheadApiCallExecutor<Overview>;
    private expenseOverheadGroupApiCallExecutor: ExpenseOverheadGroupApiCallExecutor<Overview>;

    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.expenseOverheadApiCallExecutor = new ExpenseOverheadApiCallExecutor(pointerToComponent);
        this.expenseOverheadGroupApiCallExecutor = new ExpenseOverheadGroupApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addExpenseOverheadGetAll());
        orderOfCalls.push(this.addExpenseOverheadGetSum());
        orderOfCalls.push(this.addExpenseOverheadGroupGetSumAsList());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addExpenseOverheadGetAll() : IApiCallback {
        return {
            callback: this.expenseOverheadApiCallExecutor.getAll,
            pointerToApiCall: this.expenseOverheadApiCallExecutor,
            variableNameInState: "List"
        };
    }

    private addExpenseOverheadGetSum() : IApiCallback {
        return {
            callback: this.expenseOverheadApiCallExecutor.getSum,
            pointerToApiCall: this.expenseOverheadApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addExpenseOverheadGroupGetSumAsList() : IApiCallback {
        return {
            callback: this.expenseOverheadGroupApiCallExecutor.getSumAsList,
            pointerToApiCall: this.expenseOverheadGroupApiCallExecutor,
            variableNameInState: "Group"
        };
    }

    private addDiagramData() : IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagram"
        };
    }
}

export default Overview;