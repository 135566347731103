import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "linkListItem";

    public static TITLE = "title";

    public static FORM_NAME = "form.name";
    public static FORM_DESCRIPTION = "form.description";
    public static FORM_GROUP = "form.group";
    public static FORM_LINK = "form.link";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;

    public static FORM_NAME_DEFAULT = DefaultJson.form.name;
    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
    public static FORM_GROUP_DEFAULT = DefaultJson.form.group;
    public static FORM_LINK_DEFAULT = DefaultJson.form.link;
}

export default I18nConstants;