import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';

import FortunePriorityModelMapper from '../model/FortunePriorityModelMapper';

class FortunePriorityApi implements IGenericApi {
    private static instance: FortunePriorityApi;

    private static apiUrlPath = "fortune/priority";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): FortunePriorityApi {
        if (!FortunePriorityApi.instance) {
            FortunePriorityApi.instance = new FortunePriorityApi();
        }

        return FortunePriorityApi.instance;
    }
    
    public getAll() {
        return GenericApi.executeGetAllCall(
                    FortunePriorityApi.apiUrlPath,
                    new FortunePriorityModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    FortunePriorityApi.apiUrlPath,
                    id,
                    new FortunePriorityModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    FortunePriorityApi.apiUrlPath,
                    body,
                    new FortunePriorityModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    FortunePriorityApi.apiUrlPath,
                    id,
                    body,
                    new FortunePriorityModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    FortunePriorityApi.apiUrlPath,
                    id,
                    new FortunePriorityModelMapper());
    }

    public getUrl() {
        return FortunePriorityApi.apiUrlPath;
    }
}

export default FortunePriorityApi;