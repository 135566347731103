import OverviewBase from '../../../../commons/ui/page/template/group/overview/OverviewBase';
import InsuranceListGroupApi from '../../api/InsuranceListGroupApi';
import InsuranceListGroup from '../../model/InsuranceListGroup';

class Overview extends OverviewBase<InsuranceListGroup, InsuranceListGroupApi> {
    public constructor(props) {
        super(
            props,
            InsuranceListGroupApi.getInstance()
        );
    }
}

export default Overview;