import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';

import FuelUnitModelMapper from '../model/FuelUnitModelMapper';

class FuelUnitApi implements IGenericApi {
    private static instance: FuelUnitApi;

    private static apiUrlPath = "/fuelConsumption/vehicle/fuelUnit";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): FuelUnitApi {
        if (!FuelUnitApi.instance) {
            FuelUnitApi.instance = new FuelUnitApi();
        }

        return FuelUnitApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    FuelUnitApi.apiUrlPath,
                    new FuelUnitModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    FuelUnitApi.apiUrlPath,
                    id,
                    new FuelUnitModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    FuelUnitApi.apiUrlPath,
                    body,
                    new FuelUnitModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    FuelUnitApi.apiUrlPath,
                    id,
                    body,
                    new FuelUnitModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    FuelUnitApi.apiUrlPath,
                    id,
                    new FuelUnitModelMapper());
    }

    public getUrl() {
        return FuelUnitApi.apiUrlPath;
    }
}

export default FuelUnitApi;