import IModelMapper from '../../../../../commons/model/IModelMapper';

import EarningOverheadGroupSum from './EarningOverheadGroupSum';

class EarningOverheadGroupSumModelMapper implements IModelMapper<EarningOverheadGroupSum> {
    fromJsonToObject(json: any): EarningOverheadGroupSum {
        let earningOverheadGroupSum = new EarningOverheadGroupSum();

        earningOverheadGroupSum.name = json.name;
        earningOverheadGroupSum.sum  = json.sum;

        return earningOverheadGroupSum;
    }

    fromObjectToJson(object: EarningOverheadGroupSum) {
        return {
            name: (object.name || ""),
            sum:  (object.sum || 0)
        };
    }
}

export default EarningOverheadGroupSumModelMapper;