import LetterSiganture from './LetterSignature';
import LetterSignatureModelMapper from './LetterSignatureModelMapper';

class LetterSignatureUndefinedObjectDeletionMapper
 extends LetterSignatureModelMapper {
    fromObjectToJson(object: LetterSiganture) {
        let jsonObject = super.fromObjectToJson(object);

        // Check for empty sender - person and set it to 'null'.
        if (     (jsonObject.senderPerson.id === undefined)
             || ((jsonObject.senderPerson.id as any) === null)
             || ((jsonObject.senderPerson.id as any) === "-1")
             || ((jsonObject.senderPerson.id as any) === "none")) {
            jsonObject.senderPerson = (null as any);
        }

        // Check for empty sender - company and set it to 'null'.
        if (     (jsonObject.senderCompany.id === undefined)
             || ((jsonObject.senderCompany.id as any) === null)
             || ((jsonObject.senderCompany.id as any) === "-1")
             || ((jsonObject.senderCompany.id as any) === "none")) {
            jsonObject.senderCompany = (null as any);
        }

        return jsonObject;
    }
}

export default LetterSignatureUndefinedObjectDeletionMapper;