import React from 'react';
import { Component } from 'react';
import { Form } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import AuthenticationApi from '../../../../../security/api/AuthenticationApi';
import LoadingScreen from '../../loadingScreen/LoadingScreen';
import LoginUrlPath from '../../../../../login/ui/LoginUrlPath';
import NavigationRenderer from '../../../fragment/NavigationRenderer';
import FormEventHandlerUpdate from '../form/eventHandler/FormEventHandlerUpdate';
import FormValidator from '../form/validator/FormValidator';
import FormGroupRenderer from '../form/group/FormGroupRenderer';

interface IProps<Item, Group> {
    history: any;
    location: any;
}

interface IState<Item, Group> {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: Item;
    Groups: Group[];
}

abstract class UpdateBaseWithGroup<Item, Group, ApiForItem, ApiForGroup>
  extends Component<IProps<Item, Group>, IState<Item, Group>> {
    private eventHandler: FormEventHandlerUpdate;
    private formValidator: FormValidator;
    private formGroupRenderer: FormGroupRenderer;

    protected item: Item;
    protected apiItem: ApiForItem;
    protected apiGroup: ApiForGroup;

    protected idOfItemToBeUpdated: number;
    protected nextPageUrl: string;

    private showNavbar: boolean;

    // --------------------------------------------------------------------------------------------
    // --- Constructor ---

    public constructor(props, itemObject, listService, groupService, 
                       idOfItemToBeUpdated, nextPageUrl) {
        super(props);

        this.item = itemObject;
        this.apiItem = listService;
        this.apiGroup = groupService;

        this.idOfItemToBeUpdated = idOfItemToBeUpdated;
        this.nextPageUrl = nextPageUrl;

        this.showNavbar = true;

        this.eventHandler = new FormEventHandlerUpdate(this, this.apiItem, this.nextPageUrl);
        this.formValidator = new FormValidator(this);
        this.formGroupRenderer = new FormGroupRenderer();

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: this.item,
            Groups: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // --------------------------------------------------------------------------------------------
    // --- Render ---

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return (
                <div>
                    { NavigationRenderer.renderIfNecessary(this.showNavbar) }

                    <h1>

                    </h1>

                    <div>
                        <Form onSubmit={this.handleSubmit}>
                            { this.getFormGroupContent() }
                        </Form>
                    </div>
                </div> 
            );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different Sections (for sub-classes) - Mandatory ---

    protected abstract getFormGroupContent();

    protected abstract isFormValid() : boolean;

    // --------------------------------------------------------------------------------------------
    // --- Render - Group-specific - Mandatory ---

    protected getGroupSelectValues() {
        return this.formGroupRenderer.getValuesWrappedInOptionTag(this.state.Groups);
    }

    // --------------------------------------------------------------------------------------------
    // --- Form validations ---

    protected isInvalidValue(valueToBeChecked) : boolean {
        return this.formValidator.isInvalidValue(valueToBeChecked);
    }

    protected isInvalidGroupValue(valueToBeChecked) : boolean {
        return this.formValidator.isInvalidDropdownValue(valueToBeChecked);
    }

    // --------------------------------------------------------------------------------------------
    // --- Event handlers ---

    protected handleChange(event) {
        this.eventHandler.handleChange(event);
    }

    private handleSubmit(event) {
        this.eventHandler.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------
    // --- Get values (via API) ---

    public async componentDidMount() {
        // Load all groups first ...
        (this.apiGroup as any).getAll()
        .then(  result => {
                    this.setState({
                        Groups: result
                    });

                    // ... then the item
                    (this.apiItem as any).getById(this.idOfItemToBeUpdated)
                    .then( response => {
                                this.setState({
                                    isLoading: false,
                                    Item: response
                                });
                            })
                    .catch( error   => { console.log("TODO"); } );
                })
        .catch( error => { console.log("TODO"); } );
    }
}

export default UpdateBaseWithGroup;