import UpdateBase from '../../../../../commons/ui/page/template/group/update/UpdateBase';

import ExpenseGroup from '../../model/ExpenseGroup';
import ExpenseGroupApi from '../../api/ExpenseGroupApi';
import ExpenseGroupUrlPath from '../../ui/EarningsExpensesExpenseGroupUrlPath';

class Update extends UpdateBase<ExpenseGroup, ExpenseGroupApi> {
    public constructor(props) {
        super(props,
              new ExpenseGroup(),
              ExpenseGroupApi.getInstance(),
              ExpenseGroupUrlPath.BASE);
    }
}

export default Update;