import CreateBase from '../../../../../commons/ui/page/template/group/create/CreateBase';

import VehicleType from '../../model/VehicleType';
import VehicleTypeApi from '../../api/VehicleTypeApi';
import VehicleTypeUrlPath from '../VehicleTypeUrlPath';

class Create extends CreateBase<VehicleType, VehicleTypeApi> {
    public constructor(props) {
        super(props, 
              new VehicleType(),
              VehicleTypeApi.getInstance(),
              VehicleTypeUrlPath.BASE);
    }
}

export default Create;