import React from 'react';

import FormGroupRiskClassDropdown from '../fragment/FormGroupRiskClassDropdown';
import FormGroupName from '../fragment/FormGroupName';
import FormGroupAverageProfits from '../fragment/FormGroupAverageProfits';
import FormGroupMonthlySavingRate from '../fragment/FormGroupMonthlySavingRate';

class Form {
    private formGroupRiskClass:         FormGroupRiskClassDropdown;
    private formGroupName:              FormGroupName;
    private formGroupAverageProfits:    FormGroupAverageProfits;
    private formGroupMonthlySavingRate: FormGroupMonthlySavingRate; 
    
    public constructor(props, pointerToComponent) {
        this.formGroupRiskClass         = new FormGroupRiskClassDropdown(props, pointerToComponent);
        this.formGroupName              = new FormGroupName(props, pointerToComponent);
        this.formGroupAverageProfits    = new FormGroupAverageProfits(props, pointerToComponent);
        this.formGroupMonthlySavingRate = new FormGroupMonthlySavingRate(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupRiskClass.render() }
                { this.formGroupName.render() }
                { this.formGroupAverageProfits.render() }
                { this.formGroupMonthlySavingRate.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.formGroupRiskClass.isInvalidValue()
             || this.formGroupName.isInvalidValue()
             || this.formGroupAverageProfits.isInvalidValue() 
             || this.formGroupMonthlySavingRate.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;