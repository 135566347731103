import I18nConstants from '../../../../../i18n/I18nConstantsBase';
import FormButtonBase from './FormButtonBase';

class FormButtonCreate extends FormButtonBase {
    public static render() {
        return FormButtonBase.renderByI18nKeyAndDefaultValue(
            I18nConstants.ACTION_CREATE,
            I18nConstants.ACTION_CREATE_DEFAULT
        );
    }
}

export default FormButtonCreate;