import React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';

import AuthenticationApi from '../../../security/api/AuthenticationApi';

import LoginUrlPath from '../../../login/ui/LoginUrlPath';

interface IProps {
}

interface IState {
    loggedOut: boolean;
}

class Logout extends Component<IProps, IState> {
    public constructor(props) {
        super(props);

        this.state = {
            loggedOut: false
        };
    }

    public componentWillMount() {
        AuthenticationApi.logout();

        this.setState({ loggedOut: true });
    }

    public render() {
        const loggedOut = this.state.loggedOut;
        
        if (loggedOut) {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }
}

export default Logout;