import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneRiskClassApiCallExecutor from '../../../../riskClass/api/FortuneRiskClassApiCallExecutor';
import FortuneSavingPlanGroupApiCallExecutor from '../../../../savingPlan/group/api/FortuneSavingPlanGroupApiCallExecutor';

import Utils from '../../../../../utilities/Utils';

import FortuneSavingPlanGroup from '../../model/FortuneSavingPlanGroup';
import FortuneSavingPlanGroupApi from '../../api/FortuneSavingPlanGroupApi';
import FortuneSavingPlanGroupUrlPath from '../FortuneSavingPlanGroupUrlPath';

import FortuneRiskClass from '../../../../riskClass/model/FortuneRiskClass';

import UpdateUtils from '../../../../../commons/ui/page/template/update/UpdateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:   boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item:        FortuneSavingPlanGroup;
    riskClasses: FortuneRiskClass[];
}

class Update extends Component<IProps, IState> {
    private apiCalls:            ApiCallExecutor;
    private api:             FortuneSavingPlanGroupApi;
    private form:                Form;
    private updateUtils:         UpdateUtils;

    private idOfItemToBeUpdated: number;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = FortuneSavingPlanGroupApi.getInstance();

        this.idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        this.state = {
            isLoading:   true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item:        new FortuneSavingPlanGroup(),
            riskClasses: []
        };

        this.form = new Form(props, this);

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            FortuneSavingPlanGroupUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.updateUtils.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------

    public getIdToBeUpdated() {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private fortuneRiskClassApiCallExecutor: FortuneRiskClassApiCallExecutor<Update>;
    private fortuneSavingPlanGroupApiCallExecutor: FortuneSavingPlanGroupApiCallExecutor<Update>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneRiskClassApiCallExecutor = 
                new FortuneRiskClassApiCallExecutor(pointerToComponent);
        this.fortuneSavingPlanGroupApiCallExecutor =
                new FortuneSavingPlanGroupApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneSavingPlanGroupGetById());
        orderOfCalls.push(this.addFortuneRiskClassGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "fortuneSavingPlanGroupId", 
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneSavingPlanGroupGetById() : IApiCallback {
        return {
            callback: this.fortuneSavingPlanGroupApiCallExecutor.getById,
            pointerToApiCall: this.fortuneSavingPlanGroupApiCallExecutor,
            variableNameInState: "Item"
        };
    }

    private addFortuneRiskClassGetAll() : IApiCallback {
        return {
            callback: this.fortuneRiskClassApiCallExecutor.getAll,
            pointerToApiCall: this.fortuneRiskClassApiCallExecutor,
            variableNameInState: "riskClasses"
        };
    }    
}

export default Update;