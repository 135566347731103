import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';

import ExpenseOverheadTransactionModelMapper from '../model/ExpenseOverheadTransactionModelMapper';

class ExpenseOverheadTransactionApi implements IGenericApi {
    private static instance: ExpenseOverheadTransactionApi;

    private static apiUrlPath = "expense/overhead/transaction";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): ExpenseOverheadTransactionApi {
        if (!ExpenseOverheadTransactionApi.instance) {
            ExpenseOverheadTransactionApi.instance = new ExpenseOverheadTransactionApi();
        }

        return ExpenseOverheadTransactionApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    ExpenseOverheadTransactionApi.apiUrlPath,
                    new ExpenseOverheadTransactionModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    ExpenseOverheadTransactionApi.apiUrlPath,
                    id,
                    new ExpenseOverheadTransactionModelMapper());
    }

    public getByOverheadId(id) {
        let filter = "overheadId" + "=" + id.toString();

        return GenericApi.executeGetCallWithFilter(
                    ExpenseOverheadTransactionApi.apiUrlPath,
                    filter,
                    new ExpenseOverheadTransactionModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    ExpenseOverheadTransactionApi.apiUrlPath,
                    body,
                    new ExpenseOverheadTransactionModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    ExpenseOverheadTransactionApi.apiUrlPath,
                    id,
                    body,
                    new ExpenseOverheadTransactionModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    ExpenseOverheadTransactionApi.apiUrlPath,
                    id,
                    new ExpenseOverheadTransactionModelMapper());
    }

    public getUrl() {
        return ExpenseOverheadTransactionApi.apiUrlPath;
    }
}

export default ExpenseOverheadTransactionApi;