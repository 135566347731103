import React from 'react';
import { Component } from 'react';
import { Form } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import LoadingScreen from '../../loadingScreen/LoadingScreen';
import AuthenticationApi from '../../../../../security/api/AuthenticationApi';
import LoginUrlPath from '../../../../../login/ui/LoginUrlPath';
import NavigationRenderer from '../../../fragment/NavigationRenderer';
import FormEventHandlerCreate from '../form/eventHandler/FormEventHandlerCreate';
import FormValidator from '../form/validator/FormValidator';
import FormGroupRenderer from '../form/group/FormGroupRenderer';

interface IProps<Item, Group> {
    history: any;
}

interface IState<Item, Group> {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: Item;
    Groups: Group[];
}

abstract class CreateBaseWithGroup<Item, Group, ApiForItem, ApiForGroup> 
  extends Component<IProps<Item, Group>, IState<Item, Group>> {
    private eventHandler: FormEventHandlerCreate;
    private formValidator: FormValidator;
    private formGroupRenderer: FormGroupRenderer;

    protected item: Item;
    protected apiItem: ApiForItem;
    protected apiGroup: ApiForGroup;

    protected nextPageUrl: string;

    private showNavbar: boolean;

    // --------------------------------------------------------------------------------------------
    // --- Constructor ---

    public constructor(props, item, apiItem, apiGroup, nextPageUrl) {
        super(props);

        this.item = item;
        this.apiItem = apiItem;
        this.apiGroup = apiGroup;

        this.nextPageUrl = nextPageUrl;

        this.showNavbar = true;

        this.eventHandler = new FormEventHandlerCreate(this, this.apiItem, this.nextPageUrl);
        this.formValidator = new FormValidator(this);
        this.formGroupRenderer = new FormGroupRenderer();

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: this.item,
            Groups: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // --------------------------------------------------------------------------------------------
    // --- Render ---

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return (
                <div>
                    { NavigationRenderer.renderIfNecessary(this.showNavbar) }

                    <h1>

                    </h1>

                    <div>
                        <Form onSubmit={this.handleSubmit}>
                            { this.getFormGroupContent() }
                        </Form>
                    </div>
                </div> 
            );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different Sections (for sub-classes) - Mandatory ---

    protected abstract getFormGroupContent();

    protected abstract isFormValid() : boolean;

    // --------------------------------------------------------------------------------------------
    // --- Render - Group-specific - Mandatory ---

    protected getGroupSelectValues() {
        return this.formGroupRenderer.getValuesWrappedInOptionTag(this.state.Groups);
    }

    // --------------------------------------------------------------------------------------------
    // --- Form validations ---

    protected isInvalidValue(valueToBeChecked) : boolean {
        return this.formValidator.isInvalidValue(valueToBeChecked);
    }

    protected isInvalidGroupValue(valueToBeChecked) : boolean {
        return this.formValidator.isInvalidDropdownValue(valueToBeChecked);
    }

    // --------------------------------------------------------------------------------------------
    // --- Event handlers ---

    protected handleChange(event) {
        this.eventHandler.handleChange(event);
    }

    private handleSubmit(event) {
        this.eventHandler.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------
    // --- Get values (via API) ---

    public async componentDidMount() {
        this.getInitialValueForGroupsInState();
    }

    private getInitialValueForGroupsInState() {
        (this.apiGroup as any).getAll()
        .then(  result => {
                    this.setState({
                        isLoading: false,
                        Groups: result
                    });
                })
        .catch( error  => { console.log("TODO"); } );
    }
}

export default CreateBaseWithGroup;