import IModelMapper from "../../../../../commons/model/IModelMapper";
import FortuneSavingPlanMonthlyRateBySavingPlan from './FortuneSavingPlanMonthlyRateBySavingPlan';

class FortuneSavingPlanMonthlyRateBySavingPlanModelMapper
    implements IModelMapper<FortuneSavingPlanMonthlyRateBySavingPlan> {
    fromJsonToObject(json: any): FortuneSavingPlanMonthlyRateBySavingPlan {
        let fortuneSavingPlanMonthlyRateBySavingPlan = 
            new FortuneSavingPlanMonthlyRateBySavingPlan();

        fortuneSavingPlanMonthlyRateBySavingPlan.name = json.name;
        fortuneSavingPlanMonthlyRateBySavingPlan.monthlyRate = json.monthlyRate;

        return fortuneSavingPlanMonthlyRateBySavingPlan;
    }

    fromObjectToJson(object: FortuneSavingPlanMonthlyRateBySavingPlan) {
        return {
            name: (object.name || ""),
            monthlyRate: (object.monthlyRate || 0)
        };
    }
}

export default FortuneSavingPlanMonthlyRateBySavingPlanModelMapper;