import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import VehicleTypeApiCallExecutor from '../../../type/api/VehicleTypeApiCallExecutor';
import FuelUnitApiCallExecutor from '../../../fuelUnit/api/FuelUnitApiCallExecutor';
import KindOfFuelApiCallExecutor from '../../../kindOfFuel/api/KindOfFuelApiCallExecutor';

import Vehicle from '../../model/Vehicle';
import VehicleApi from '../../api/VehicleApi';
import VehicleUrlPath from '../VehicleUrlPath';

import VehicleType from "../../../type/model/VehicleType";
import FuelUnit from "../../../fuelUnit/model/FuelUnit";
import KindOfFuel from "../../../kindOfFuel/model/KindOfFuel";

import CreateUtils from '../../../../../commons/ui/page/template/create/CreateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:    boolean;
    wasHandleSubmitClickedAtLeastOnce:  boolean;
    Item:         Vehicle;
    VehicleTypes: VehicleType[];
    FuelUnits:    FuelUnit[];
    KindOfFuels:  KindOfFuel[];
}

class Create extends Component<IProps, IState> {
    private apiCalls:    ApiCallExecutor;
    private api:     VehicleApi;
    private form:        Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);
        this.api = VehicleApi.getInstance();

        this.state = {
            isLoading:    true,
            wasHandleSubmitClickedAtLeastOnce:  false,
            Item:         new Vehicle(),
            VehicleTypes: [],
            FuelUnits:    [],
            KindOfFuels:  []
        };

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            VehicleUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return (
            <h1>

            </h1>
        );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private vehicleTypeApiCallExecutor: VehicleTypeApiCallExecutor<Create>;
    private fuelUnitApiCallExecutor: FuelUnitApiCallExecutor<Create>;
    private kindOfFuelApiCallExecutor: KindOfFuelApiCallExecutor<Create>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.vehicleTypeApiCallExecutor = new VehicleTypeApiCallExecutor(pointerToComponent);
        this.fuelUnitApiCallExecutor = new FuelUnitApiCallExecutor(pointerToComponent);
        this.kindOfFuelApiCallExecutor = new KindOfFuelApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addVehicleTypeGetAll());
        orderOfCalls.push(this.addFuelUnitGetAll());
        orderOfCalls.push(this.addKindOfFuelGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addVehicleTypeGetAll() : IApiCallback {
        return {
            callback: this.vehicleTypeApiCallExecutor.getAll,
            pointerToApiCall: this.vehicleTypeApiCallExecutor,
            variableNameInState: "VehicleTypes"
        };
    }

    private addFuelUnitGetAll() : IApiCallback {
        return {
            callback: this.fuelUnitApiCallExecutor.getAll,
            pointerToApiCall: this.fuelUnitApiCallExecutor,
            variableNameInState: "FuelUnits"
        };
    }

    private addKindOfFuelGetAll() : IApiCallback {
        return {
            callback: this.kindOfFuelApiCallExecutor.getAll,
            pointerToApiCall: this.kindOfFuelApiCallExecutor,
            variableNameInState: "KindOfFuels"
        };
    }
}

export default Create;