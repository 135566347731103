import React from 'react';

import FormGroupDayDropdown from '../../../item/ui/fragment/FormGroupDayDropdown';
import FormGroupMonthDropdown from '../../../item/ui/fragment/FormGroupMonthDropdown';
import FormGroupRecurrenceDropdown from '../../../item/ui/fragment/FormGroupRecurrenceDropdown';
import FormGroupAmount from '../../../../item/ui/fragment/FormGroupAmount';
import FormGroupMustBeChanged from '../../../item/ui/fragment/FormGroupMustBeChanged';

class Form {
    private isUpdateUsed:           boolean;

    private formGroupDay:           FormGroupDayDropdown;
    private formGroupMonth:         FormGroupMonthDropdown;
    private formGroupRecurrence:    FormGroupRecurrenceDropdown;
    private formGroupAmount:        FormGroupAmount;
    private formGroupMustBeChanged: FormGroupMustBeChanged;

    public constructor(props, pointerToComponent, isUpdateUsed = false) {
        this.isUpdateUsed           = isUpdateUsed;

        this.formGroupDay           = new FormGroupDayDropdown(props, pointerToComponent);
        this.formGroupMonth         = new FormGroupMonthDropdown(props, pointerToComponent);
        this.formGroupRecurrence    = new FormGroupRecurrenceDropdown(props, pointerToComponent);
        this.formGroupAmount        = new FormGroupAmount(props, pointerToComponent);
        this.formGroupMustBeChanged = new FormGroupMustBeChanged(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupDay.render() }
                { this.formGroupMonth.render() }
                { this.isUpdateUsed === false 
                  ? this.formGroupRecurrence.render() 
                  : undefined }
                { this.formGroupAmount.render() }
                { this.formGroupMustBeChanged.render() }

                { renderButtonCallback() }
            </div>
        );
    }

    public isFormValid(): boolean {
        if (this.isUpdateUsed) {
            return this.isFormValidForUpdate();
        } else {
            return this.isFormValidForInsert();
        }
    }

    private isFormValidForInsert(): boolean {
        if (    this.formGroupDay.isInvalidValue()
             || this.formGroupMonth.isInvalidValue()
             || this.formGroupRecurrence.isInvalidValue()
             || this.formGroupAmount.isInvalidValue()
             || this.formGroupMustBeChanged.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }

    private isFormValidForUpdate(): boolean {
        if (    this.formGroupDay.isInvalidValue()
             || this.formGroupMonth.isInvalidValue()
             || this.formGroupAmount.isInvalidValue()
             || this.formGroupMustBeChanged.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;