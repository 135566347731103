import React from 'react';
import FormGroupBikeDropdown from '../fragment/FormGroupBikeDropdown';
import FormGroupDate from '../fragment/FormGroupDate';
import FormGroupTripDistanceInKm from '../fragment/FormGroupTripDistanceInKm';
import FormGroupRideTime from '../fragment/FormGroupRideTime';
import FormGroupAverageSpeedInKmh from '../fragment/FormGroupAverageSpeedInKmh';
import FormGroupMaxSpeedInKmh from '../fragment/FormGroupMaxSpeedInKmh';
import FormGroupAveragePowerInWatt from '../fragment/FormGroupAveragePowerInWatt';
import FormGroupAltitudeAscendingInM from '../fragment/FormGroupAltitudeAscendingInM';
import FormGroupDistanceAscendingInKm from '../fragment/FormGroupDistanceAscendingInKm';
import FormGroupMaxAltitudeInM from '../fragment/FormGroupMaxAltitudeInM';
import FormGroupAverageHeartRate from '../fragment/FormGroupAverageHeartRate';
import FormGroupMaxHeartRate from '../fragment/FormGroupMaxHeartRate';
import FormGroupKiloCalories from '../fragment/FormGroupKiloCalories';
import FormGroupNote from '../fragment/FormGroupNote';

class Form {
    private formGroupBikeDropdown: FormGroupBikeDropdown;
    private formGroupDate: FormGroupDate;
    private formGroupTripDistanceInKm: FormGroupTripDistanceInKm;
    private formGroupRideTime: FormGroupRideTime;
    private formGroupAverageSpeedInKmh: FormGroupAverageSpeedInKmh;
    private formGroupMaxSpeedInKmh: FormGroupMaxSpeedInKmh;
    private formGroupAveragePowerInWatt: FormGroupAveragePowerInWatt;
    private formGroupAltitudeAscendingInM: FormGroupAltitudeAscendingInM;
    private formGroupDistanceAscendingInKm: FormGroupDistanceAscendingInKm;
    private formGroupMaxAltitudeInM: FormGroupMaxAltitudeInM;
    private formGroupAverageHeartRate: FormGroupAverageHeartRate;
    private formGroupMaxHeartRate: FormGroupMaxHeartRate;
    private formGroupKiloCalories: FormGroupKiloCalories;
    private formGroupNote: FormGroupNote;

    public constructor(props, pointerToComponent) {
        this.formGroupBikeDropdown = new FormGroupBikeDropdown(props, pointerToComponent);
        this.formGroupDate = new FormGroupDate(props, pointerToComponent);
        this.formGroupTripDistanceInKm = new FormGroupTripDistanceInKm(props, pointerToComponent);
        this.formGroupRideTime = new FormGroupRideTime(props, pointerToComponent);
        this.formGroupAverageSpeedInKmh = new FormGroupAverageSpeedInKmh(props, pointerToComponent);
        this.formGroupMaxSpeedInKmh = new FormGroupMaxSpeedInKmh(props, pointerToComponent);
        this.formGroupAveragePowerInWatt = new FormGroupAveragePowerInWatt(props, pointerToComponent);
        this.formGroupAltitudeAscendingInM = new FormGroupAltitudeAscendingInM(props, pointerToComponent);
        this.formGroupDistanceAscendingInKm = new FormGroupDistanceAscendingInKm(props, pointerToComponent);
        this.formGroupMaxAltitudeInM = new FormGroupMaxAltitudeInM(props, pointerToComponent);
        this.formGroupAverageHeartRate = new FormGroupAverageHeartRate(props, pointerToComponent);
        this.formGroupMaxHeartRate = new FormGroupMaxHeartRate(props, pointerToComponent);
        this.formGroupKiloCalories = new FormGroupKiloCalories(props, pointerToComponent);
        this.formGroupNote = new FormGroupNote(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupBikeDropdown.render() }
                { this.formGroupDate.render() }
                { this.formGroupTripDistanceInKm.render() }
                { this.formGroupRideTime.render() }
                { this.formGroupAverageSpeedInKmh.render() }
                { this.formGroupMaxSpeedInKmh.render() }
                { this.formGroupAveragePowerInWatt.render() }
                { this.formGroupAltitudeAscendingInM.render() }
                { this.formGroupDistanceAscendingInKm.render() }
                { this.formGroupMaxAltitudeInM.render() }
                { this.formGroupAverageHeartRate.render() }
                { this.formGroupMaxHeartRate.render() }
                { this.formGroupKiloCalories.render() }
                { this.formGroupNote.render() }

                { renderButtonCallback() }
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupBikeDropdown.isInvalidValue() 
             || this.formGroupDate.isInvalidValue()
             || this.formGroupTripDistanceInKm.isInvalidValue()
             || this.formGroupRideTime.isInvalidValue()
             || this.formGroupAverageSpeedInKmh.isInvalidValue()
             || this.formGroupMaxSpeedInKmh.isInvalidValue()
             || this.formGroupAveragePowerInWatt.isInvalidValue()
             || this.formGroupAltitudeAscendingInM.isInvalidValue()
             || this.formGroupDistanceAscendingInKm.isInvalidValue()
             || this.formGroupMaxAltitudeInM.isInvalidValue()
             || this.formGroupAverageHeartRate.isInvalidValue()
             || this.formGroupMaxHeartRate.isInvalidValue()
             || this.formGroupKiloCalories.isInvalidValue()
             || this.formGroupNote.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;