import React from 'react';

import FormGroupSenderPersonDropdown from '../fragment/FormGroupSenderPersonDropdown';
import FormGroupSenderCompanyDropdown from '../fragment/FormGroupSenderCompanyDropdown';
import FormGroupAddresseePersonDropdown from '../fragment/FormGroupAddresseePersonDropdown';
import FormGroupAddresseeCompanyDropdown from '../fragment/FormGroupAddresseeCompanyDropdown';
import FormGroupTemplateDropdown from '../fragment/FormGroupTemplateDropdown';
import FormGroupDate from '../fragment/FormGroupDate';
import FormGroupSubject from '../fragment/FormGroupSubject';
import FormGroupText from '../fragment/FormGroupText';
import FormGroupEnclosures from '../fragment/FormGroupEnclosures';
import FormGroupUseSignature from '../fragment/FormGroupUseSignature';

class Form {
    private formGroupSenderPersonDropdown: FormGroupSenderPersonDropdown;
    private formGroupSenderCompanyDropdown: FormGroupSenderCompanyDropdown;
    private formGroupAddresseePersonDropdown: FormGroupAddresseePersonDropdown;
    private formGroupAddresseeCompanyDropdown: FormGroupAddresseeCompanyDropdown;
    private formGroupTemplateDropdown: FormGroupTemplateDropdown;
    private formGroupDate: FormGroupDate;
    private formGroupSubject: FormGroupSubject;
    private formGroupText: FormGroupText;
    private formGroupEnclosures: FormGroupEnclosures;
    private formGroupUseSignature: FormGroupUseSignature;

    public constructor(props, pointerToComponent) {
        this.formGroupSenderPersonDropdown = new FormGroupSenderPersonDropdown(props, pointerToComponent);
        this.formGroupSenderCompanyDropdown = new FormGroupSenderCompanyDropdown(props, pointerToComponent);
        this.formGroupAddresseePersonDropdown = new FormGroupAddresseePersonDropdown(props, pointerToComponent);
        this.formGroupAddresseeCompanyDropdown = new FormGroupAddresseeCompanyDropdown(props, pointerToComponent);
        this.formGroupTemplateDropdown = new FormGroupTemplateDropdown(props, pointerToComponent);
        this.formGroupDate = new FormGroupDate(props, pointerToComponent);
        this.formGroupSubject = new FormGroupSubject(props, pointerToComponent);
        this.formGroupText = new FormGroupText(props, pointerToComponent);
        this.formGroupEnclosures = new FormGroupEnclosures(props, pointerToComponent);
        this.formGroupUseSignature = new FormGroupUseSignature(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupSenderPersonDropdown.render() }
                { this.formGroupSenderCompanyDropdown.render() }
                { this.formGroupAddresseePersonDropdown.render() }
                { this.formGroupAddresseeCompanyDropdown.render() }
                { this.formGroupTemplateDropdown.render() }
                { this.formGroupDate.render() }
                { this.formGroupSubject.render() }
                { this.formGroupText.render() }
                { this.formGroupEnclosures.render() }
                { this.formGroupUseSignature.render() }

                { renderButtonCallback() } 
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupSenderPersonDropdown.isInvalidValue()
             || this.formGroupSenderCompanyDropdown.isInvalidValue()
             || this.formGroupAddresseePersonDropdown.isInvalidValue()
             || this.formGroupAddresseeCompanyDropdown.isInvalidValue()
             || this.formGroupTemplateDropdown.isInvalidValue()
             || this.formGroupDate.isInvalidValue()
             || this.formGroupSubject.isInvalidValue()
             || this.formGroupText.isInvalidValue()
             || this.formGroupEnclosures.isInvalidValue()
             || this.formGroupUseSignature.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;