import React from 'react';
import { Component } from 'react';
import { Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneInvestmentPlanApiCallExecutor from '../../../investmentPlan/api/FortuneInvestmentPlanApiCallExecutor';

import AuthenticationApi from '../../../../security/api/AuthenticationApi';

import FortuneInvestmentPlanApi from '../../api/FortuneInvestmentPlanApi';

import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';

import FortuneInvestmentPlanUrlPath from '../FortuneInvestmentPlanUrlPath';

import LoginUrlPath from '../../../../login/ui/LoginUrlPath';

import OverviewUtils from '../../../../commons/ui/page/template/overview/OverviewUtils';

import FortuneOverviewActionBarButton from '../../../dashboard/ui/fragment/ActionBarButton';
import FortuneInvestmentAimActionBarButton from '../../../investmentAim/ui/fragment/ActionBarButton';
import FortuneSavingPlanActionBarButton from '../../../savingPlan/item/ui/fragment/ActionBarButton';
import FortuneSavingRateActionBarButton from '../../../savingRate/ui/fragment/ActionBarButton';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading: boolean;
    List:      [];
}

class Overview extends Component<IProps, IState> {
    private api:            FortuneInvestmentPlanApi;
    private apiCalls:           ApiCallExecutor;

    private overviewUtils:      OverviewUtils;

    private fortuneOverviewActionBarButton: FortuneOverviewActionBarButton;
    private fortuneInvestmentAimActionBarButton: FortuneInvestmentAimActionBarButton;
    private fortuneSavingPlanActionBarButton: FortuneSavingPlanActionBarButton;
    private fortuneSavingRateActionBarButton: FortuneSavingRateActionBarButton;

    public constructor(props) {
        super(props);

        this.api = FortuneInvestmentPlanApi.getInstance();

        this.apiCalls = new ApiCallExecutor(this);

        this.fortuneOverviewActionBarButton = new FortuneOverviewActionBarButton(props.history);
        this.fortuneInvestmentAimActionBarButton = new FortuneInvestmentAimActionBarButton(props.history);
        this.fortuneSavingPlanActionBarButton = new FortuneSavingPlanActionBarButton(props.history);
        this.fortuneSavingRateActionBarButton = new FortuneSavingRateActionBarButton(props.history);

        this.state = {
            isLoading: true,
            List:      []
        };

        this.overviewUtils = new OverviewUtils(
            this,
            this.getHeading(),
            FortuneInvestmentPlanUrlPath.CREATE,
            FortuneInvestmentPlanUrlPath.UPDATE,
            undefined,
            true,
            this.getActionBar());
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return(
                <div>
                    { this.overviewUtils.render(
                        this.getTableHead(),
                        this.getTableBody()) }
                </div> );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return(
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> );
    }

    private getActionBar() {
        return (
            <div>
                { this.fortuneOverviewActionBarButton.render() }
                { this.fortuneInvestmentAimActionBarButton.render() }
                { this.fortuneSavingPlanActionBarButton.render() }
                { this.fortuneSavingRateActionBarButton.render() }
            </div> );
    }

    private getTableHead() {
        return(
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_PRIORITY}>
                        {I18nConstants.FORM_PRIORITY_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_ISSUE_FEE}>
                        {I18nConstants.FORM_ISSUE_FEE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_TER}>
                        {I18nConstants.FORM_TER_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_FONDS_AMOUNT_IN_MIO}>
                        {I18nConstants.FORM_FONDS_AMOUNT_IN_MIO_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DISTRIBUTION_TYPE}>
                        {I18nConstants.FORM_DISTRIBUTION_TYPE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_REPLICATION_METHOD}>
                        {I18nConstants.FORM_REPLICATION_METHOD_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_BANK_NAME}>
                        {I18nConstants.FORM_BANK_NAME_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_VENDOR}>
                        {I18nConstants.FORM_VENDOR_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_COUNTRY_LOCATION}>
                        {I18nConstants.FORM_COUNTRY_LOCATION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_ISIN}>
                        {I18nConstants.FORM_ISIN_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_FIRST_ISSUE_DATE}>
                        {I18nConstants.FORM_FIRST_ISSUE_DATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_LINK}>
                        {I18nConstants.FORM_LINK_DEFAULT}
                    </Trans>
                </th>

                { this.overviewUtils.getUpdateHeadingWrappedInThTag() }
                { this.overviewUtils.getDeleteHeadingWrappedInThTag() }
            </tr> );
    }

    private getTableBody() {
        return this.state.List.map( item => 
            <tr key={(item as any).id}>
                <td>
                    {(item as any).priority.description}
                </td>

                <td>
                    {(item as any).description}
                </td>

                <td>
                    {(item as any).issueFee}
                </td>

                <td>
                    {(item as any).ter}
                </td>

                <td>
                    {(item as any).fondsAmountInMio}
                </td>

                <td>
                    {(item as any).distributionType}
                </td>

                <td>
                    {(item as any).replicationMethod}
                </td>

                <td>
                    {(item as any).bankName}
                </td>

                <td>
                    {(item as any).vendor}
                </td>

                <td>
                    {(item as any).countryLocation}
                </td>

                <td>
                    {(item as any).isin}
                </td>

                <td>
                    {(item as any).firstIssueDate}
                </td>

                <td>
                    <a href={(item as any).url} target="_blank" rel="noopener noreferrer">
                        {(item as any).url}
                    </a>
                </td>

                { this.overviewUtils.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.overviewUtils.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private helper: ApiCallExecutorHelper;

    private fortuneInvestmentPlanApiCallExecutor: FortuneInvestmentPlanApiCallExecutor<Overview>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneInvestmentPlanApiCallExecutor = 
                new FortuneInvestmentPlanApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneInvestmentPlanGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneInvestmentPlanGetAll() : IApiCallback {
        return {
            callback: this.fortuneInvestmentPlanApiCallExecutor.getAll,
            pointerToApiCall: this.fortuneInvestmentPlanApiCallExecutor,
            variableNameInState: "List"
        };
    }
}

export default Overview;