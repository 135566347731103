import React from 'react';

import FormGroupVehicleType from '../fragment/FormGroupVehicleType';
import FormGroupBrand from '../fragment/FormGroupBrand';
import FormGroupModel from '../fragment/FormGroupModel';
import FormGroupDescription from '../fragment/FormGroupDescription';
import FormGroupVehicleIdentificationNumber from '../fragment/FormGroupVehicleIdentificationNumber';
import FormGroupKindOfFuel from '../fragment/FormGroupKindOfFuel';
import FormGroupFuelUnit from '../fragment/FormGroupFuelUnit';

class Form {
    private formGroupVehicleType: FormGroupVehicleType;
    private formGroupBrand: FormGroupBrand;
    private formGroupModel: FormGroupModel;
    private formGroupDescription: FormGroupDescription;
    private formGroupVehicleIdentificationNumber: FormGroupVehicleIdentificationNumber;
    private formGroupKindOfFuel: FormGroupKindOfFuel;
    private formGroupFuelUnit: FormGroupFuelUnit;

    public constructor(props, pointerToComponent) {
        this.formGroupVehicleType = new FormGroupVehicleType(props, pointerToComponent);
        this.formGroupBrand = new FormGroupBrand(props, pointerToComponent);
        this.formGroupModel = new FormGroupModel(props, pointerToComponent);
        this.formGroupDescription = new FormGroupDescription(props, pointerToComponent);
        this.formGroupVehicleIdentificationNumber = new FormGroupVehicleIdentificationNumber(props, pointerToComponent);
        this.formGroupKindOfFuel = new FormGroupKindOfFuel(props, pointerToComponent);
        this.formGroupFuelUnit = new FormGroupFuelUnit(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupVehicleType.render() }
                { this.formGroupBrand.render() }
                { this.formGroupModel.render() }
                { this.formGroupDescription.render() }
                { this.formGroupVehicleIdentificationNumber.render() }
                { this.formGroupKindOfFuel.render() }
                { this.formGroupFuelUnit.render() }

                { renderButtonCallback() }
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupVehicleType.isInvalidValue()
             || this.formGroupBrand.isInvalidValue() 
             || this.formGroupModel.isInvalidValue()
             || this.formGroupDescription.isInvalidValue()
             || this.formGroupVehicleIdentificationNumber.isInvalidValue()
             || this.formGroupKindOfFuel.isInvalidValue() 
             || this.formGroupFuelUnit.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;