import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import ExpenseOverheadModelMapper from '../model/ExpenseOverheadModelMapper';

class ExpenseOverheadApi implements IGenericApi {
    private static instance: ExpenseOverheadApi;

    private static apiUrlPath = "expense/overhead";

    private static endpointSum = "/sum";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): ExpenseOverheadApi {
        if (!ExpenseOverheadApi.instance) {
            ExpenseOverheadApi.instance = new ExpenseOverheadApi();
        }

        return ExpenseOverheadApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    ExpenseOverheadApi.apiUrlPath,
                    new ExpenseOverheadModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    ExpenseOverheadApi.apiUrlPath,
                    id,
                    new ExpenseOverheadModelMapper());
    }

    public getSum() {
        let endpoint = ExpenseOverheadApi.endpointSum;

        return GenericApi.executeGetAllCall(
                    ExpenseOverheadApi.apiUrlPath + endpoint,
                    null);
    }

    public create(body) {
        return GenericApi.executePostCall(
                    ExpenseOverheadApi.apiUrlPath,
                    body,
                    new ExpenseOverheadModelMapper());
    }

    public update(id ,body) {
        return GenericApi.executePutCall(
                    ExpenseOverheadApi.apiUrlPath,
                    id,
                    body,
                    new ExpenseOverheadModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    ExpenseOverheadApi.apiUrlPath,
                    id,
                    new ExpenseOverheadModelMapper());
    }

    public getUrl() {
        return ExpenseOverheadApi.apiUrlPath;
    }
}

export default ExpenseOverheadApi;