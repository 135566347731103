import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "earningsExpensesItem";

    public static TITLE = "title";

    public static EARNINGS = "earnings";
    public static EXPENSES = "expenses";

    public static SUM = "sum";
    public static PROFIT_OR_LOSS = "profitOrLoss";
    public static SAVING_RATIO = "savingRatio";
    public static POST_OVERHEAD_CURRENT_MONTH = "postOverheadCurrentMonth";

    public static FORM_DESCRIPTION = "form.description";
    public static FORM_DATE = "form.date";
    public static FORM_GROUP = "form.group";
    public static FORM_AMOUNT = "form.amount";
    public static FORM_IS_OVERHEAD = "form.isOverhead";
    public static FORM_PERCENTAGE = "form.percentage";

    public static ACTION_BAR_GROUPED_BY_MONTH_AND_YEAR = "actionBar.groupedByMonthAndYear";
    public static ACTION_BAR_GROUPED_BY_YEAR = "actionBar.groupedByYear";
    public static ACTION_BAR_DIALOG_POST_OVERHEAD_CURRENT_MONTH = "actionBar.dialogPostOverheadCurrentMonth";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;

    public static EARNINGS_DEFAULT = DefaultJson.earnings;
    public static EXPENSES_DEFAULT = DefaultJson.expenses;

    public static SUM_DEFAULT = DefaultJson.sum;
    public static PROFIT_OR_LOSS_DEFAULT = DefaultJson.profitOrLoss;
    public static SAVING_RATIO_DEFAULT = DefaultJson.savingRatio;
    public static POST_OVERHEAD_CURRENT_MONTH_DEFAULT = DefaultJson.postOverheadCurrentMonth;

    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
    public static FORM_DATE_DEFAULT = DefaultJson.form.date;
    public static FORM_GROUP_DEFAULT = DefaultJson.form.group;
    public static FORM_AMOUNT_DEFAULT = DefaultJson.form.amount;
    public static FORM_IS_OVERHEAD_DEFAULT = DefaultJson.form.isOverhead;
    public static FORM_PERCENTAGE_DEFAULT = DefaultJson.form.percentage;

    public static ACTION_BAR_GROUPED_BY_MONTH_AND_YEAR_DEFAULT = DefaultJson.actionBar.groupedByMonthAndYear;
    public static ACTION_BAR_GROUPED_BY_YEAR_DEFAULT = DefaultJson.actionBar.groupedByYear;
    public static ACTION_BAR_DIALOG_POST_OVERHEAD_CURRENT_MONTH_DEFAULT = DefaultJson.actionBar.dialogPostOverheadCurrentMonth;
}

export default I18nConstants;