import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from "../../../../commons/ui/page/template/form/FormGroupBase";

class FormGroupHeight extends FormGroupBase {
    private ID_AND_NAME = "height";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_HEIGHT,
                    I18nConstants.FORM_HEIGHT_DEFAULT) }

                <Input
                    type="number" 
                    id={this.ID_AND_NAME}
                    name={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.height}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()} />

                { this.renderFormFeedbackWithInsertValidValueMessage() }
            </FormGroup> );
    }

    public isInvalidValue(): boolean {
        // It isn't a mandatory parameter.
        return false;
    }
}

export default FormGroupHeight;