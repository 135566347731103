import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import LetterPersonTitleModelMapper from '../model/LetterPersonTitleModelMapper';

class LetterPersonTitleApi implements IGenericApi {
    private static instance: LetterPersonTitleApi;

    private static apiUrlPath = "letter/person/title";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): LetterPersonTitleApi {
        if (!LetterPersonTitleApi.instance) {
            LetterPersonTitleApi.instance = new LetterPersonTitleApi();
        }

        return LetterPersonTitleApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    LetterPersonTitleApi.apiUrlPath,
                    new LetterPersonTitleModelMapper()
        );
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    LetterPersonTitleApi.apiUrlPath,
                    id,
                    new LetterPersonTitleModelMapper()
        );
    }

    public create(body) {
        return GenericApi.executePostCall(
                    LetterPersonTitleApi.apiUrlPath,
                    body,
                    new LetterPersonTitleModelMapper()
        );
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    LetterPersonTitleApi.apiUrlPath,
                    id,
                    body,
                    new LetterPersonTitleModelMapper()
        );
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    LetterPersonTitleApi.apiUrlPath,
                    id,
                    new LetterPersonTitleModelMapper()
        );
    }

    public getUrl() {
        return LetterPersonTitleApi.apiUrlPath;
    }
}

export default LetterPersonTitleApi;