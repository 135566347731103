import IModelMapper from '../../../../commons/model/IModelMapper';

import EarningOverhead from './EarningOverhead';

class EarningOverheadModelMapper implements IModelMapper<EarningOverhead> {
    fromJsonToObject(json: any): EarningOverhead {
        let earningOverhead = new EarningOverhead();

        earningOverhead.id          = json.id;
        earningOverhead.group       = json.group;
        earningOverhead.description = json.description;
        earningOverhead.amount      = json.amount;

        return earningOverhead;
    }

    fromObjectToJson(object: EarningOverhead) {
        return {
            id:          object.id,
            group:       (object.group || undefined),
            description: (object.description || ""),
            amount:      (object.amount || 0)
        };
    }
}

export default EarningOverheadModelMapper;