import FortuneSavingPlan from "../../savingPlan/item/model/FortuneSavingPlan";

class FortuneSavingRate {
    id!:               number;
    savingPlan!:       FortuneSavingPlan;
    description!:      string;
    amount!:           number;
    date!:             Date;
    currentQuotation!: number;
    investmentAmount!: number; 

    public constructor() {
        this.savingPlan = new FortuneSavingPlan();
    }
}

export default FortuneSavingRate;