import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';

import VehicleModelMapper from '../model/VehicleModelMapper';

class VehicleApi implements IGenericApi {
    private static instance: VehicleApi;

    private static apiUrlPath = "fuelConsumption/vehicle";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): VehicleApi {
        if (!VehicleApi.instance) {
            VehicleApi.instance = new VehicleApi();
        }

        return VehicleApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    VehicleApi.apiUrlPath,
                    new VehicleModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    VehicleApi.apiUrlPath,
                    id,
                    new VehicleModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    VehicleApi.apiUrlPath,
                    body,
                    new VehicleModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    VehicleApi.apiUrlPath,
                    id,
                    body,
                    new VehicleModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    VehicleApi.apiUrlPath,
                    id,
                    new VehicleModelMapper());
    }

    public getUrl() {
        return VehicleApi.apiUrlPath;
    }
}

export default VehicleApi;