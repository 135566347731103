import ApiCallExecutorBase from "../../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';
import FortuneSavingPlanApi from './FortuneSavingPlanApi';

class FortuneSavingPlanApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private fortuneSavingPlanApi: FortuneSavingPlanApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.fortuneSavingPlanApi = FortuneSavingPlanApi.getInstance();
    }

    public getAll(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingPlanApi.getAll()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getById(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const id = (context.pointerToHelper.getParameterInArrayWithObjects(
                        context.parameters, "fortuneSavingPlanId") as number);

        context.currentData.pointerToApiCall.fortuneSavingPlanApi.getById(id)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSumById(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const id = (context.pointerToHelper.getParameterInArrayWithObjects(
                        context.parameters, "fortuneSavingPlanId") as number);

        context.currentData.pointerToApiCall.fortuneSavingPlanApi.getSumById(id)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as number));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getMonthlyRateByInvestmentPlan(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingPlanApi.getMonthlyRateByInvestmentPlan()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getMonthlyRateBySavingPlan(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingPlanApi.getMonthlyRateBySavingPlan()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSumMonthlyRateByInvestmentPlan(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingPlanApi.getSumMonthlyRateByInvestmentPlan()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSumMonthlyRateBySavingPlan(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneSavingPlanApi.getSumMonthlyRateBySavingPlan()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);
                    
                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public setFirstSavingPlanToParametersAndState(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        // Get the first saving plan.
        const currentSavingPlanSelection = context.pointerToComponent.state.savingPlans[0];

        const state = context.pointerToHelper.createStateObject(
            context.currentData.variableNameInState,
            currentSavingPlanSelection);

        context.pointerToComponent.setState(state);

        context.pointerToHelper.addParameterToContext(context, 
                                    "fortuneSavingPlanId", currentSavingPlanSelection.id);

        try {
            successorCallback.callback(context);
        } catch (error) { 
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }

    public setMonthlyRateByInvestmentPlanToParameters(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);
        
        const monthlyRateInvestmentPlan = context.pointerToComponent.state.MonthlyRateByInvestmentPlan;

        let diagramData: object[] = [];

        monthlyRateInvestmentPlan.forEach(function(item) {
            if (parseInt(item.monthlyRate) > 0) {
                let newItem = {};

                newItem["name"] = item.name;
                newItem["sum"] = item.monthlyRate;

                diagramData.push(newItem);
            }
        });

        context.pointerToHelper.addParameterToContext(
                                    context,
                                    context.currentData.variableNameInState, 
                                    diagramData);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }

    public setMonthlyRateBySavingPlanToParameters(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);
        
        const monthlyRateSavingPlan = context.pointerToComponent.state.MonthlyRateBySavingPlan;

        let diagramData: object[] = [];

        monthlyRateSavingPlan.forEach(function(item) {
            if (parseInt(item.monthlyRate) > 0) {
                let newItem = {};

                newItem["name"] = item.name;
                newItem["sum"] = item.monthlyRate;

                diagramData.push(newItem);
            }
        });

        context.pointerToHelper.addParameterToContext(
                                    context,
                                    context.currentData.variableNameInState, 
                                    diagramData);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }
}

export default FortuneSavingPlanApiCallExecutor;