import React from 'react';
import { Component } from 'react';
import { Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18n from '../../../../commons/i18n/i18n';
import I18nConstants from '../../i18n/I18nConstants';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import IApiCallContext from '../../../../commons/ui/page/apiCallExecutor/IApiCallContext';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneSavingRatioApiCallExecutor from '../../../savingRatio/api/FortuneSavingRatioApiCallExecutor';

import Utils from '../../../../utilities/Utils';

import AuthenticationApi from '../../../../security/api/AuthenticationApi';

import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';

import LoginUrlPath from '../../../../login/ui/LoginUrlPath';

import FortuneSavingRatio from '../../../savingRatio/model/FortuneSavingRatio';

import XYCoordinateSystemTime from '../../../../commons/ui/page/diagram/XYCoordinateSystemTime';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:              boolean;
    averageCurrentYear:     number;
    averageTotal:           number;
    savingRatioCurrentYear: FortuneSavingRatio[],
    diagram:                [];
}

class FortuneSavingRatioOverview extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;

    private diagram: Diagram;
    
    public constructor(props) {
        super(props);

        this.state = {
            isLoading:              false,
            averageCurrentYear:     0.0,
            averageTotal:           0.0,
            savingRatioCurrentYear: [],
            diagram:                []
        };

        this.diagram = new Diagram();

        this.apiCalls = new ApiCallExecutor(this, this.diagram);
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            } else {
                return (
                    <div>
                        { this.getTableWithAverageValues() }
                        { this.getDiagramDataElement() }
                    </div> );
            }
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getTableWithAverageValues() {
        return (
            <Table>
                <tbody>
                    <tr>
                        <td>
                            <Trans ns={I18nConstants.NS}
                                   i18nKey={I18nConstants.SAVING_RATIO_CURRENT_YEAR}>
                                {I18nConstants.SAVING_RATIO_CURRENT_YEAR_DEFAULT}
                            </Trans>
                        </td>

                        <td>
                            { Utils.roundTwoDecimalPlaces(this.state.averageCurrentYear) } %
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Trans ns={I18nConstants.NS}
                                   i18nKey={I18nConstants.SAVING_RATIO_TOTAL}>
                                {I18nConstants.SAVING_RATIO_TOTAL_DEFAULT}
                            </Trans>
                        </td>

                        <td>
                            { Utils.roundTwoDecimalPlaces(this.state.averageTotal)} %
                        </td>
                    </tr>
                </tbody>
            </Table> );
    }

    private getDiagramDataElement() {
        const xAxisTitle = i18n.t(I18nConstants.NS + ":" +
                                  I18nConstants.SAVING_RATIO_DIAGRAM_DATE);
        const yAxisTitle = i18n.t(I18nConstants.NS + ":" +
                                  I18nConstants.SAVING_RATIO_DIAGRAM_PERCENTAGE);

        return this.diagram.getHtmlElement(
                        this.state.diagram, 
                        xAxisTitle, 
                        yAxisTitle);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: FortuneSavingRatioOverview;
    private pointerToDiagram: XYCoordinateSystemTime;

    private helper: ApiCallExecutorHelper;

    private fortuneSavingRatioApiCallExecutor: 
                FortuneSavingRatioApiCallExecutor<FortuneSavingRatioOverview>;

    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneSavingRatioApiCallExecutor = 
                new FortuneSavingRatioApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addSavingRatioGetAverageByYear());
        orderOfCalls.push(this.addSavingRatioGetAverageTotal());
        orderOfCalls.push(this.addSavingRatioGetByYear());
        orderOfCalls.push(this.addSavingRatioSetDiagramDataAllYearsFromOverviewToParameters());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        context.pointerToHelper.addParameterToContext(context, "year", Utils.getCurrentYear());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addSavingRatioGetAverageByYear() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.getAverageByYear,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "averageCurrentYear"
        };
    }

    private addSavingRatioGetAverageTotal() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.getAverageTotal,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "averageTotal"
        };
    }

    private addSavingRatioGetByYear() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.getByYear,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "savingRatioCurrentYear"
        };
    }

    private addSavingRatioSetDiagramDataAllYearsFromOverviewToParameters() : IApiCallback {
        return {
            callback: this.fortuneSavingRatioApiCallExecutor.setDiagramDataAllYearsFromOverviewToParameters,
            pointerToApiCall: this.fortuneSavingRatioApiCallExecutor,
            variableNameInState: "listDataForDiagram"
        };
    }

    private addDiagramData() : IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagram"
        };
    }
}

// ================================================================================================

class Diagram extends XYCoordinateSystemTime {
    public updateDiagramData(context: IApiCallContext) {
        let date;

        let month       = 0;
        let year        = 0;
        let day         = 1;
        let savingRatio = 0;

        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const listData = (context.pointerToHelper.getParameterInArrayWithObjects(
                                context.parameters, "listDataForDiagram") as any);

        const diagramData = listData.map( item => {
            month       = item.month;
            year        = item.year;
            savingRatio = (item.earnings - item.expenses) * 100 / item.earnings;
            savingRatio = Utils.roundTwoDecimalPlaces(savingRatio);

            date = Utils.createDateByDayMonthYear(day, month, year);

            return { x: date,
                     y: savingRatio };
        });

        const state = context.pointerToHelper.createStateObject(
                            context.currentData.variableNameInState,
                            diagramData);

        context.pointerToComponent.setState(state);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }
}

export default FortuneSavingRatioOverview;