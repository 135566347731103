import ApiCallExecutorBase from "../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../commons/ui/page/apiCallExecutor/IApiCallContext';

import EarningsExpensesApi from "./EarningsExpensesApi";

import Utils from '../../../utilities/Utils';

class EarningsExpensesApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private earningsExpensesApi: EarningsExpensesApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.earningsExpensesApi = EarningsExpensesApi.getInstance();
    }

    public getYearsAndMonthsAsStringLastMonth(context: IApiCallContext) {
        let yearAndMonthSelection = "";
        let monthAndYearObject = {};

        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.earningsExpensesApi.getYearsAndMonthsAsString()
        .then(  response => {
                    // Get the last month and year combination.
                    // The last one in the list is always the latest one.
                    yearAndMonthSelection = (response as any)[(response as any).length - 1];

                    monthAndYearObject = Utils.getMonthAndYearFromStringSeparatedBySlash(
                                                    yearAndMonthSelection);

                    if (monthAndYearObject) {
                        context.pointerToHelper.addParameterToContext(context, 
                                                    "monthAndYearObject", monthAndYearObject);

                        if (context.currentData.variableNameInState) {
                            const state = context.pointerToHelper.createStateObject(
                                                context.currentData.variableNameInState,
                                                (response as any));
        
                            context.pointerToComponent.setState(state);
                        }

                        try {
                            successorCallback.callback(context);
                        } catch (error) { 
                            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                        }
                    }
                })
        .catch( error   => {
                    console.log("TODO");
            });
    }

    public getYearsAsStringLastYear(context: IApiCallContext) {
        let yearSelection = "";

        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.earningsExpensesApi.getYearsAsString()
        .then( response => {
                    // Get the last year.
                    // The last one in the list is always the latest one.
                    yearSelection = (response as any)[(response as any).length - 1];

                    if (yearSelection) {
                        context.pointerToHelper.addParameterToContext(context, 
                                                    "year", yearSelection);

                        if (context.currentData.variableNameInState) {
                            const state = context.pointerToHelper.createStateObject(
                                                context.currentData.variableNameInState,
                                                (response as any));
        
                            context.pointerToComponent.setState(state);
                        }

                        try {
                            successorCallback.callback(context);
                        } catch (error) { 
                            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                        }
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSumByMonthAndYear(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const monthAndYearObject = (context.pointerToHelper.getParameterInArrayWithObjects(
                                        context.parameters, "monthAndYearObject") as any);

        context.currentData.pointerToApiCall.earningsExpensesApi.getSumByMonthAndYear(
                                                                    monthAndYearObject.month,
                                                                    monthAndYearObject.year)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    Utils.roundTwoDecimalPlaces(response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
               }) 
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSumByYear(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const year = (context.pointerToHelper.getParameterInArrayWithObjects(
                        context.parameters, "year") as string);

        context.currentData.pointerToApiCall.earningsExpensesApi.getSumByYear(year)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
               }) 
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSavingRatioByMonthAndYear(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const monthAndYearObject = (context.pointerToHelper.getParameterInArrayWithObjects(
                                        context.parameters, "monthAndYearObject") as any);

        context.currentData.pointerToApiCall.earningsExpensesApi.getSavingRatioByMonthAndYear(
                                                                    monthAndYearObject.month,
                                                                    monthAndYearObject.year)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    Utils.roundTwoDecimalPlaces(response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
               })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getSavingRatioByYear(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const year = (context.pointerToHelper.getParameterInArrayWithObjects(
                        context.parameters, "year") as string);

        context.currentData.pointerToApiCall.earningsExpensesApi.getSavingRatioByYear(year)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    Utils.roundTwoDecimalPlaces(response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
               }) 
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public setMonthAndYearObjectFromParametersToState(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const monthAndYearObject = (context.pointerToHelper.getParameterInArrayWithObjects(
                                        context.parameters, "monthAndYearObject") as any);

        const state = context.pointerToHelper.createStateObject(
                        context.currentData.variableNameInState,
                        Utils.convertMonthAndYearObjectToStringSeparatedBySlash(monthAndYearObject));

        context.pointerToComponent.setState(state);

        try {
            successorCallback.callback(context);
        } catch (error) { 
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }

    public setYearFromParameterToState(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const year = (context.pointerToHelper.getParameterInArrayWithObjects(
                            context.parameters, "year") as string);        

        const state = context.pointerToHelper.createStateObject(
                        context.currentData.variableNameInState, year);

        context.pointerToComponent.setState(state);

        try {
            successorCallback.callback(context);
        } catch (error) { 
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }
}

export default EarningsExpensesApiCallExecutor;