import IModelMapper from "../../../commons/model/IModelMapper";

import LinkList from './LinkList';

class LinkListModelMapper implements IModelMapper<LinkList> {
    fromJsonToObject(json: any): LinkList {
        let linkList = new LinkList();

        linkList.id          = json.id;
        linkList.group       = json.group;
        linkList.name        = json.name;
        linkList.description = json.description;
        linkList.link        = json.link;

        return linkList;
    }

    fromObjectToJson(object: LinkList) {
        return {
            id:          object.id,
            group:       (object.group || undefined),
            name:        (object.name || ""),
            description: (object.description || ""),
            link:        (object.link || "")
        }
    }
}

export default LinkListModelMapper;