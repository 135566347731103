import Utils from '../../../../../utilities/Utils';
import ExpenseOverhead from '../../model/ExpenseOverhead';
import ExpenseOverheadApi from '../../api/ExpenseOverheadApi';
import ExpenseGroup from '../../../../../earningsExpenses/expense/group/model/ExpenseGroup';
import ExpenseGroupApi from '../../../../../earningsExpenses/expense/group/api/ExpenseGroupApi';
import EarningsExpensesOverheadUrlPath from '../../../../item/ui/EarningsExpensesOverheadUrlPath';
import UpdateBaseWithGroup from '../../../../../commons/ui/page/template/update/UpdateBaseWithGroup';
import Form from './Form';
import FormButtonUpdate from '../../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBaseWithGroup<ExpenseOverhead, ExpenseGroup,
ExpenseOverheadApi, ExpenseGroupApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new ExpenseOverhead(),
              ExpenseOverheadApi.getInstance(),
              ExpenseGroupApi.getInstance(),
              idOfItemToBeUpdated,
              EarningsExpensesOverheadUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;