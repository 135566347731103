import Utils from '../../../../utilities/Utils';
import FortuneRiskClass from '../../model/FortuneRiskClass';
import FortuneRiskClassApi from '../../api/FortuneRiskClassApi';
import FortuneRiskClassUrlPath from '../FortuneRiskClassUrlPath';
import UpdateBase from '../../../../commons/ui/page/template/update/UpdateBase';
import Form from './Form';
import FormButtonUpdate from '../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBase<FortuneRiskClass, FortuneRiskClassApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new FortuneRiskClass(),
              FortuneRiskClassApi.getInstance(),
              idOfItemToBeUpdated,
              FortuneRiskClassUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;