import IModelMapper from '../../../../commons/model/IModelMapper';
import Earning from './Earning';

class EarningModelMapper implements IModelMapper<Earning> {
    fromJsonToObject(json: any): Earning {
        let earning = new Earning();

        earning.id = json.id;
        earning.group = json.group;
        earning.description = json.description;
        earning.date = json.date;
        earning.amount = json.amount;
        earning.isOverhead = json.isOverhead;

        return earning;
    }

    fromObjectToJson(object: Earning) {
        return {
            id: object.id,
            group: (object.group || undefined),
            description: (object.description || ""),
            date: (object.date || "0001-01-01"),
            amount: (object.amount || 0),
            isOverhead:  (object.isOverhead || false)
        };
    }
}

export default EarningModelMapper;