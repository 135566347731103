import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "earningsExpensesOverheadItem";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static GROUPED_TITLE = "groupedTitle";
    public static GROUPED_TITLE_SHORT = "groupedTitleShort";

    public static EARNINGS = "earnings";
    public static EXPENSES = "expenses";

    public static TRANSACTIONS = "transactions";

    public static SUM = "sum";
    public static PROFIT_OR_LOSS = "profitOrLoss";
    public static SAVING_RATIO = "savingRatio";

    public static FORM_DESCRIPTION = "form.description";
    public static FORM_GROUP = "form.group";
    public static FORM_AMOUNT = "form.amount";
    public static FORM_PERCENTAGE = "form.percentage";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static GROUPED_TITLE_DEFAULT = DefaultJson.groupedTitle;
    public static GROUPED_TITLE_SHORT_DEFAULT = DefaultJson.groupedTitleShort;

    public static EARNINGS_DEFAULT = DefaultJson.earnings;
    public static EXPENSES_DEFAULT = DefaultJson.expenses;

    public static TRANSACTIONS_DEFAULT = DefaultJson.transactions;

    public static SUM_DEFAULT = DefaultJson.sum;
    public static PROFIT_OR_LOSS_DEFAULT = DefaultJson.profitOrLoss;
    public static SAVING_RATIO_DEFAULT = DefaultJson.savingRatio;

    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
    public static FORM_GROUP_DEFAULT = DefaultJson.form.group;
    public static FORM_AMOUNT_DEFAULT = DefaultJson.form.amount;
    public static FORM_PERCENTAGE_DEFAULT = DefaultJson.form.percentage;
}

export default I18nConstants;