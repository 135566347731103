import IModelMapper from '../../../../commons/model/IModelMapper';
import LetterPersonTitle from '../../../person/title/model/LetterPersonTitle';
import LetterAddresseePerson from "./LetterAddresseePerson";

class LetterAddresseePersonModelMapper implements IModelMapper<LetterAddresseePerson> {
    fromJsonToObject(json: any): LetterAddresseePerson {
        let letterAddresseePerson = new LetterAddresseePerson();

        letterAddresseePerson.id = json.id;
        letterAddresseePerson.salutation = json.salutation;
        // Must be done like this, because on UPDATE, it could be that it has
        // a null-value because it isn't a mandatory parameter.
        letterAddresseePerson.title = (json.title == null)
                                       ? new LetterPersonTitle()
                                       : json.title;
        letterAddresseePerson.firstName = json.firstName;
        letterAddresseePerson.lastName = json.lastName;
        letterAddresseePerson.street = json.street;
        letterAddresseePerson.houseNumber = json.houseNumber;
        letterAddresseePerson.zip = json.zip;
        letterAddresseePerson.city = json.city;
        letterAddresseePerson.postOfficeBox = json.postOfficeBox;
        letterAddresseePerson.country = json.country;
        
        return letterAddresseePerson;
    }

    fromObjectToJson(object: LetterAddresseePerson) {
        return {
            id: object.id,
            salutation: (object.salutation || undefined),
            title: (object.title || undefined),
            firstName: (object.firstName || ""),
            lastName: (object.lastName || ""),
            street: (object.street || ""),
            houseNumber: (object.houseNumber || ""),
            zip: (object.zip || ""),
            city: (object.city || ""),
            postOfficeBox: (object.postOfficeBox || ""),
            country: (object.country || undefined)
        };
    }
}

export default LetterAddresseePersonModelMapper;