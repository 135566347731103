import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import EarningModelMapper from '../model/EarningModelMapper';

class EarningApi implements IGenericApi {
    private static instance: EarningApi;

    private static apiUrlPath = "earning";

    private static endpointSum = "/sum";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): EarningApi {
        if (!EarningApi.instance) {
            EarningApi.instance = new EarningApi();
        }

        return EarningApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    EarningApi.apiUrlPath,
                    new EarningModelMapper());
    }

    public getByMonthAndYear(month, year) {
        let filterAsString = "month" + "=" + month.toString() + "&" +
                             "year"  + "=" + year.toString(); 
        
        return GenericApi.executeGetCallWithFilter(
                    EarningApi.apiUrlPath,
                    filterAsString,
                    new EarningModelMapper());
    }

    public getSumByMonthAndYear(month, year) {
        let endpoint = EarningApi.endpointSum;

        let filterAsString = "month" + "=" + month.toString() + "&" +
                             "year"  + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                    EarningApi.apiUrlPath + endpoint,
                    filterAsString,
                    null);   // No mapper needed, because the result is just a double-value.
    }

    public getSumByYear(year) {
        let endpoint = EarningApi.endpointSum;

        let filterAsString = "year" + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                    EarningApi.apiUrlPath + endpoint,
                    filterAsString,
                    null);   // No mapper needed, because the result is just a double-value.
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    EarningApi.apiUrlPath,
                    id,
                    new EarningModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    EarningApi.apiUrlPath,
                    body,
                    new EarningModelMapper());
    }

    public update(id ,body) {
        return GenericApi.executePutCall(
                    EarningApi.apiUrlPath,
                    id,
                    body,
                    new EarningModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    EarningApi.apiUrlPath,
                    id,
                    new EarningModelMapper());
    }

    public getUrl() {
        return EarningApi.apiUrlPath;
    }
}

export default EarningApi;