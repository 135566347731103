import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupSenderPersonDropdown extends FormGroupBase {
    private ID_AND_NAME = "senderPerson";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const senderPeople = this.getSenderPeopleSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_SENDER_PERSON,
                    I18nConstants.FORM_SENDER_PERSON_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.senderPerson.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {senderPeople}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {  
        return false;
    }

    protected getSenderPeopleSelectValues() {
        const defaultValue = this.getDefaultSelectValue();

        const { SenderPeople } = this.pointerToComponent.state;

        let values = SenderPeople.map( person =>
            <option value={person.id}>
                {(person as any).firstName}&nbsp;
                {(person as any).lastName}&nbsp;/&nbsp;
                {(person as any).street}&nbsp;
                {(person as any).houseNumber}&nbsp;/&nbsp;
                {(person as any).zip}&nbsp;
                {(person as any).city}
            </option>
        );

        values.unshift(defaultValue);

        return values;
    }
}

export default FormGroupSenderPersonDropdown;