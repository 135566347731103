import IModelMapper from '../../../../commons/model/IModelMapper';
import BikeType from './BikeType';

class BikeTypeModelMapper implements IModelMapper<BikeType> {
    fromJsonToObject(json: any): BikeType {
        let bikeType = new BikeType();

        bikeType.id = json.id;
        bikeType.name = json.name;

        return bikeType;
    }

    fromObjectToJson(object: BikeType) {
        return {
            id: object.id,
            name: (object.name || "") 
        };
    }
}

export default BikeTypeModelMapper;