import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import VehicleApiCallExecutor from '../../../vehicle/item/api/VehicleApiCallExecutor';
import TireApiCallExecutor from '../../../tire/api/TireApiCallExecutor';
import KindOfStreetApiCallExecutor from '../../../kindOfStreet/api/KindOfStreetApiCallExecutor';
import DrivingStyleApiCallExecutor from '../../../drivingStyle/api/DrivingStyleApiCallExecutor';

import FuelConsumption from '../../model/FuelConsumption';
import FuelConsumptionUrlPath from '../FuelConsumptionUrlPath';
import FuelConsumptionApi from '../../api/FuelConsumptionApi';

import Vehicle from '../../../vehicle/item/model/Vehicle';
import Tire from '../../../tire/model/Tire';
import KindOfStreet from '../../../kindOfStreet/model/KindOfStreet';
import DrivingStyle from '../../../drivingStyle/model/DrivingStyle';

import CreateUtils from '../../../../commons/ui/page/template/create/CreateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:     boolean;
    wasHandleSubmitClickedAtLeastOnce:   boolean;
    Item:          FuelConsumption;
    Vehicles:      Vehicle[];
    Tires:         Tire[];
    KindOfStreets: KindOfStreet[];
    DrivingStyles: DrivingStyle[];
}

class Create extends Component<IProps, IState> {
    private api:     FuelConsumptionApi;
    private apiCalls:    ApiCallExecutor;
    private form:        Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.api = FuelConsumptionApi.getInstance();

        this.apiCalls = new ApiCallExecutor(this);

        this.state = {
            isLoading:     true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item:          new FuelConsumption(),
            Vehicles:      [],
            Tires:         [],
            KindOfStreets: [],
            DrivingStyles: []
        };

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            FuelConsumptionUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    public getHeading() {
        return (
            <h1>

            </h1>
        );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private vehicleApiCallExecutor: VehicleApiCallExecutor<Create>;
    private tireApiCallExecutor: TireApiCallExecutor<Create>;
    private kindOfStreetApiCallExecutor: KindOfStreetApiCallExecutor<Create>;
    private drivingStyleApiCallExecutor: DrivingStyleApiCallExecutor<Create>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.vehicleApiCallExecutor = new VehicleApiCallExecutor(pointerToComponent);
        this.tireApiCallExecutor = new TireApiCallExecutor(pointerToComponent);
        this.kindOfStreetApiCallExecutor = new KindOfStreetApiCallExecutor(pointerToComponent);
        this.drivingStyleApiCallExecutor = new DrivingStyleApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();
    
        orderOfCalls.push(this.addVehicleGetAll());
        orderOfCalls.push(this.addTireGetAll());
        orderOfCalls.push(this.addKindOfStreetGetAll());
        orderOfCalls.push(this.addDrivingStyleGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addVehicleGetAll() : IApiCallback {
        return {
            callback: this.vehicleApiCallExecutor.getAll,
            pointerToApiCall: this.vehicleApiCallExecutor,
            variableNameInState: "Vehicles"
        };
    }

    private addTireGetAll() : IApiCallback {
        return {
            callback: this.tireApiCallExecutor.getAll,
            pointerToApiCall: this.tireApiCallExecutor,
            variableNameInState: "Tires"
        };
    }

    private addKindOfStreetGetAll() : IApiCallback {
        return {
            callback: this.kindOfStreetApiCallExecutor.getAll,
            pointerToApiCall: this.kindOfStreetApiCallExecutor,
            variableNameInState: "KindOfStreets"
        };
    }

    private addDrivingStyleGetAll() : IApiCallback {
        return {
            callback: this.drivingStyleApiCallExecutor.getAll,
            pointerToApiCall: this.drivingStyleApiCallExecutor,
            variableNameInState: "DrivingStyles"
        };
    }
}

export default Create;