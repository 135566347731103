import UpdateBase from '../../../../commons/ui/page/template/group/update/UpdateBase';

import LinkListGroup from '../../model/LinkListGroup';
import LinkListGroupApi from '../../api/LinkListGroupApi';
import LinkListGroupUrlPath from '../LinkListGroupUrlPath';

class Update extends UpdateBase<LinkListGroup, LinkListGroupApi> {
    public constructor(props) {
        super(props,
              new LinkListGroup(),
              LinkListGroupApi.getInstance(),
              LinkListGroupUrlPath.BASE);
    }
}

export default Update;