class FortuneSavingRateUrlPath {
    public static BASE = "/fortune/savingRate";

    public static CREATE = FortuneSavingRateUrlPath.BASE + "/create";
    public static UPDATE = FortuneSavingRateUrlPath.BASE + "/update";

    public static CREATE_IN_WIZARD_FOR_ALL_SAVING_PLANS = FortuneSavingRateUrlPath.BASE + 
                    "/createInWizardForAllSavingPlans";
}

export default FortuneSavingRateUrlPath;