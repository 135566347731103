import IModelMapper from "../../../../commons/model/IModelMapper";
import FortuneSavingRateSumGroupedBySavingPlanName from './FortuneSavingRateSumGroupedBySavingPlanName';

class FortuneSavingRateSumGroupedBySavingPlanNameModelMapper
        implements IModelMapper<FortuneSavingRateSumGroupedBySavingPlanName> {
    fromJsonToObject(json: any): FortuneSavingRateSumGroupedBySavingPlanName {
        let fortuneSavingRateSumGroupedBySavingPlanName =
            new FortuneSavingRateSumGroupedBySavingPlanName();

        fortuneSavingRateSumGroupedBySavingPlanName.name = json.name;
        fortuneSavingRateSumGroupedBySavingPlanName.sum = json.sum;

        return fortuneSavingRateSumGroupedBySavingPlanName;
    }

    fromObjectToJson(object: FortuneSavingRateSumGroupedBySavingPlanName) {
        return {
            name: (object.name || ""),
            sum: (object.sum || 0)
        };
    }
}

export default FortuneSavingRateSumGroupedBySavingPlanNameModelMapper;