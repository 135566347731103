import I18nConstantsBase from '../../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "earningsExpensesOverheadTransactionItem";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static FORM_DAY = "form.day";
    public static FORM_MONTH = "form.month";
    public static FORM_RECURRENCE = "form.recurrence";
    public static FORM_AMOUNT = "form.amount";
    public static FORM_MUST_BE_CHANGED = "form.mustBeChanged";

    public static FORM_RECURRENCE_DROPDOWN_VALUE_LAST_DAY_OF_MONTH = "form.recurrenceDropdownValue.lastDayOfMonth";
    public static FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_MONTH = "form.recurrenceDropdownValue.everyMonth";
    public static FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_QUARTER = "form.recurrenceDropdownValue.everyQuarter";
    public static FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_HALF_YEAR = "form.recurrenceDropdownValue.everyHalfYear";
    public static FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_YEAR = "form.recurrenceDropdownValue.everyYear";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static FORM_DAY_DEFAULT = DefaultJson.form.day;
    public static FORM_MONTH_DEFAULT = DefaultJson.form.month;
    public static FORM_RECURRENCE_DEFAULT = DefaultJson.form.recurrence;
    public static FORM_AMOUNT_DEFAULT = DefaultJson.form.amount;
    public static FORM_MUST_BE_CHANGED_DEFAULT = DefaultJson.form.mustBeChanged;

    public static FORM_RECURRENCE_DROPDOWN_VALUE_LAST_DAY_OF_MONTH_DEFAULT = DefaultJson.form.recurrenceDropdownValue.lastDayOfMonth;
    public static FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_MONTH_DEFAULT = DefaultJson.form.recurrenceDropdownValue.everyMonth;
    public static FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_QUARTER_DEFAULT = DefaultJson.form.recurrenceDropdownValue.everyQuarter;
    public static FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_HALF_YEAR_DEFAULT = DefaultJson.form.recurrenceDropdownValue.everyHalfYear;
    public static FORM_RECURRENCE_DROPDOWN_VALUE_EVERY_YEAR_DEFAULT = DefaultJson.form.recurrenceDropdownValue.everyYear;
}

export default I18nConstants;