import IModelMapper from '../../commons/model/IModelMapper';

import User from './User';

class UserModelMapper implements IModelMapper<User> {
    fromJsonToObject(json: any): User {
        let user = new User();

        user.id       = json.id;
        user.username = json.username;

        return user;
    }

    fromObjectToJson(object: User) {
        return {
            id:       object.id,
            username: (object.username || "") };
    }
}

export default UserModelMapper;