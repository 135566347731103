import VehicleType from "../../type/model/VehicleType";
import FuelUnit from "../../fuelUnit/model/FuelUnit";
import KindOfFuel from "../../kindOfFuel/model/KindOfFuel";

class Vehicle {
    id!: number;
    vehicleType!: VehicleType;
    brand!: string;
    model!: string;
    description!: string;
    vehicleIdentificationNumber!: string;
    kindOfFuel!: KindOfFuel;
    fuelUnit!: FuelUnit;

    public constructor() {
        this.vehicleType = new VehicleType();
        this.kindOfFuel  = new KindOfFuel();
        this.fuelUnit    = new FuelUnit();
    }
}

export default Vehicle;