import UpdateBase from '../../../../commons/ui/page/template/group/update/UpdateBase';
import ShoppingListGroup from '../../model/ShoppingListGroup';
import ShoppingListGroupApi from '../../api/ShoppingListGroupApi';
import ShoppingListGroupUrlPath from '../ShoppingListGroupUrlPath';

class Update extends UpdateBase<ShoppingListGroup, ShoppingListGroupApi> {
    public constructor(props) {
        super(props,
              new ShoppingListGroup(),
              ShoppingListGroupApi.getInstance(),
              ShoppingListGroupUrlPath.BASE);
    }
}

export default Update;