import EarningGroup from '../../model/EarningGroup';
import EarningGroupApi from '../../api/EarningGroupApi';
import OverviewBase from '../../../../../commons/ui/page/template/group/overview/OverviewBase';

class Overview extends OverviewBase<EarningGroup, EarningGroupApi> {
    public constructor(props) {
        super(props,
              EarningGroupApi.getInstance()
        );
    }
}

export default Overview;