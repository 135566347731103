import IModelMapper from "../../../commons/model/IModelMapper";
import InsuranceList from './InsuranceList';

class InsuranceListModelMapper implements IModelMapper<InsuranceList> {
    fromJsonToObject(json: any): InsuranceList {
        let insuranceList = new InsuranceList();

        insuranceList.id = json.id;
        insuranceList.group = json.group;
        insuranceList.company = json.company;
        insuranceList.name = json.name;
        insuranceList.number = json.number;
        insuranceList.dateOfBegin = json.dateOfBegin;
        insuranceList.dueDateYearly = json.dueDateYearly;
        insuranceList.amountYearly = json.amountYearly;
        insuranceList.paymentInterval = json.paymentInterval;
        insuranceList.description = json.description;

        return insuranceList;
    }

    fromObjectToJson(object: InsuranceList) {
        return {
            id: object.id,
            group: (object.group || undefined),
            company: (object.company || ""),
            name: (object.name || ""),
            number: (object.number || ""),
            dateOfBegin: (object.dateOfBegin || "0001-01-01"),
            dueDateYearly: (object.dueDateYearly || ""),
            amountYearly: (object.amountYearly || 0),
            paymentInterval: (object.paymentInterval || undefined),
            description: (object.description || "")
        };
    }
}

export default InsuranceListModelMapper;