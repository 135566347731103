import React from 'react';
import { Trans } from 'react-i18next';
import I18nConstants from '../../../../../i18n/I18nConstantsBase';

class OverviewHeadingBase {
    protected static renderByI18nKeyAndDefaultValue(i18nKey, defaultValue) {
        return (
            <th>
                <Trans ns={I18nConstants.NS_BASE}
                       i18nKey={i18nKey}>
                    {defaultValue}
                </Trans>
            </th>
        );
    }
}

export default OverviewHeadingBase;