import React from 'react';
import { Component } from 'react';
import { Navbar, Nav, NavbarBrand } from 'reactstrap';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import LogoutNavigationItem from '../../../logout/ui/fragment/NavigationItem';

class Navigation extends Component {
    public render() { 
        return ( 
            <div>
                <Navbar color="dark" dark expand="md">
                    <NavbarBrand href="/">
                        <Trans ns={I18nConstants.NS}
                               i18nKey={I18nConstants.APP_TITLE}>
                            {I18nConstants.APP_TITLE_DEFAULT}
                        </Trans>
                    </NavbarBrand>
                    
                    <Nav className="ml-auto" navbar>
                        { new LogoutNavigationItem().render() }
                    </Nav>
                </Navbar>
            </div>
         );
    }
}
 
export default Navigation;