import IModelMapper from "../../../commons/model/IModelMapper";

import FortuneSavingRatio from './FortuneSavingRatio';

class FortuneSavingRatioModelMapper implements IModelMapper<FortuneSavingRatio> {
    fromJsonToObject(json: any): FortuneSavingRatio {
        let fortuneSavingRatio = new FortuneSavingRatio();

        fortuneSavingRatio.id       = json.id;
        fortuneSavingRatio.month    = json.month;
        fortuneSavingRatio.year     = json.year;
        fortuneSavingRatio.earnings = json.earnings;
        fortuneSavingRatio.expenses = json.expenses;

        return fortuneSavingRatio;
    }

    fromObjectToJson(object: FortuneSavingRatio) {
        return {
            id:       object.id,
            month:    (object.month || 0),
            year:     (object.year || 0),
            earnings: (object.earnings || 0),
            expenses: (object.expenses || 0)
        };
    }
}

export default FortuneSavingRatioModelMapper;