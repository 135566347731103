import IModelMapper from '../../../commons/model/IModelMapper';
import BankingListGroup from './BankingListGroup';

class BankingListGroupModelMapper implements IModelMapper<BankingListGroup> {
    fromJsonToObject(json: any): BankingListGroup {
        let bankingListGroup = new BankingListGroup();

        bankingListGroup.id = json.id;
        bankingListGroup.name = json.name;

        return bankingListGroup;
    }

    fromObjectToJson(object: BankingListGroup) {
        return {
            id: object.id,
            name: (object.name || "") 
        };
    }
}

export default BankingListGroupModelMapper;