import React from 'react';
import { Button } from 'reactstrap';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import Vehicle from '../../model/Vehicle';
import VehicleApi from '../../api/VehicleApi';
import VehicleUrlPath from '../VehicleUrlPath';
import OverviewBase from '../../../../../commons/ui/page/template/overview/OverviewBase';

import FuelConsumptionVehicleTypeActionBarButton from '../../../../vehicle/type/ui/fragment/ActionBarButton';
import FuelConsumptionVehicleKindOfFuelActionBarButton from '../../../../vehicle/kindOfFuel/ui/fragment/ActionBarButton';
import FuelConsumptionVehicleFuelUnitActionBarButton from '../../../../vehicle/fuelUnit/ui/fragment/ActionBarButton';

class Overview extends OverviewBase<Vehicle, VehicleApi> {
    private vehicleTypeActionBarButton: FuelConsumptionVehicleTypeActionBarButton;
    private vehicleKindOfFuelActionBarButton: FuelConsumptionVehicleKindOfFuelActionBarButton;
    private vehicleFuelUnitActionBarButton: FuelConsumptionVehicleFuelUnitActionBarButton;

    public constructor(props) {
        super(props,
              VehicleApi.getInstance());

        this.setCreateUrl(VehicleUrlPath.CREATE);

        this.vehicleTypeActionBarButton = new FuelConsumptionVehicleTypeActionBarButton(props.history);
        this.vehicleKindOfFuelActionBarButton = new FuelConsumptionVehicleKindOfFuelActionBarButton(props.history);
        this.vehicleFuelUnitActionBarButton = new FuelConsumptionVehicleFuelUnitActionBarButton(props.history);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_TYPE}>
                        {I18nConstants.FORM_TYPE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_BRAND}>
                        {I18nConstants.FORM_BRAND_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_MODEL}>
                        {I18nConstants.FORM_MODEL_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_VIN}>
                        {I18nConstants.FORM_VIN_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_KIND_OF_FUEL}>
                        {I18nConstants.FORM_KIND_OF_FUEL_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_FUEL_UNIT}>
                        {I18nConstants.FORM_FUEL_UNIT_DEFAULT}
                    </Trans>
                </th>

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).vehicleType.name}
                </td>

                <td>
                    {(item as any).brand}
                </td>

                <td>
                    {(item as any).model}
                </td>

                <td>
                    {(item as any).description}
                </td>

                <td>
                    {(item as any).vehicleIdentificationNumber}
                </td>

                <td>
                    {(item as any).kindOfFuel.name}
                </td>

                <td>
                    {(item as any).fuelUnit.name}
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr>
        );
    }

    protected getActionBar() {
        return (
            <div>
                { this.vehicleTypeActionBarButton.render() }
                { this.vehicleKindOfFuelActionBarButton.render() }
                { this.vehicleFuelUnitActionBarButton.render() }
            </div>
        );
    }
}

export default Overview;