import React from 'react';
import { Button } from 'reactstrap';
import { Trans } from 'react-i18next';

class ActionBarButtonBase {
    private propsHistory: any;

    protected url: string;

    protected i18nNamespace: string;
    protected i18nKey: string;
    protected nameInEnglish: string;

    public constructor(propsHistory, url, i18nNamespace, i18nKey, nameInEnglish) {
        this.propsHistory = propsHistory;

        this.url = url;

        this.i18nNamespace = i18nNamespace;
        this.i18nKey = i18nKey;
        this.nameInEnglish = nameInEnglish;
    }

    public render() {
        return (
            <Button onClick={ () => this.onClick() }>
               <Trans ns={this.i18nNamespace}
                      i18nKey={this.i18nKey}>
                    {this.nameInEnglish}
                </Trans> 
            </Button> 
        );
    }

    private onClick() {
        this.propsHistory.push(this.url);
    }
}

export default ActionBarButtonBase;