import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';

import ToDoListModelMapper from '../model/ToDoListModelMapper';

class ToDoListApi implements IGenericApi {
    private static instance: ToDoListApi;

    private static apiUrlPath = "toDoList";

    private static endpointNotStarted = "/notStarted";
    private static endpointInProcess = "/inProcess";
    private static endpointFinished = "/finished";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): ToDoListApi {
        if (!ToDoListApi.instance) {
            ToDoListApi.instance = new ToDoListApi();
        }

        return ToDoListApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    ToDoListApi.apiUrlPath,
                    new ToDoListModelMapper());
    }

    public getAllInProcess() {
        let endpoint = ToDoListApi.endpointInProcess;

        return GenericApi.executeGetAllCall(
                    ToDoListApi.apiUrlPath + endpoint,
                    new ToDoListModelMapper());
    }

    public getAllNotStarted() {
        let endpoint = ToDoListApi.endpointNotStarted;

        return GenericApi.executeGetAllCall(
                    ToDoListApi.apiUrlPath + endpoint,
                    new ToDoListModelMapper());
    }

    public getAllFinished() {
        let endpoint = ToDoListApi.endpointFinished;

        return GenericApi.executeGetAllCall(
                    ToDoListApi.apiUrlPath + endpoint,
                    new ToDoListModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    ToDoListApi.apiUrlPath,
                    id,
                    new ToDoListModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    ToDoListApi.apiUrlPath,
                    body,
                    new ToDoListModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    ToDoListApi.apiUrlPath,
                    id,
                    body,
                    new ToDoListModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    ToDoListApi.apiUrlPath,
                    id,
                    new ToDoListModelMapper());
    }

    public getUrl() {
        return ToDoListApi.apiUrlPath;
    }
}

export default ToDoListApi;