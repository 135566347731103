import Utils from '../../../../utilities/Utils';
import LetterTemplateApi from '../../api/LetterTemplateApi';
import LetterTemplate from '../../model/LetterTemplate';
import LetterTemplateUrlPath from '../LetterTemplateUrlPath';
import UpdateBase from '../../../../commons/ui/page/template/update/UpdateBase';
import Form from './Form';
import FormButtonUpdate from '../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBase<LetterTemplate, LetterTemplateApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new LetterTemplate(),
              LetterTemplateApi.getInstance(),
              idOfItemToBeUpdated,
              LetterTemplateUrlPath.BASE
        );

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;