import UpdateBase from '../../../../../commons/ui/page/template/group/update/UpdateBase';
import BikeType from '../../model/BikeType';
import BikeTypeApi from '../../api/BikeTypeApi';
import BikeTypeUrlPath from '../../ui/BikeTypeUrlPath';

class Update extends UpdateBase<BikeType, BikeTypeApi> {
    public constructor(props) {
        super(props,
              new BikeType(),
              BikeTypeApi.getInstance(),
              BikeTypeUrlPath.BASE
        );
    }
}

export default Update;