import React from 'react';
import { Button } from 'reactstrap';
import { Trans } from 'react-i18next';
import I18nConstants from '../../../i18n/I18nConstants';
import EarningsExpensesPostOverheadCurrentMonthDialog from './PostOverheadCurrentMonthDialog';

class ActionBarButtonPostOverheadCurrentMonth {
    private propsHistory: any;

    private postTransactionsDialog: EarningsExpensesPostOverheadCurrentMonthDialog;

    public constructor(propsHistory, props) {
        this.propsHistory = propsHistory;

        this.postTransactionsDialog = new EarningsExpensesPostOverheadCurrentMonthDialog(props);
    }

    public render() {
        return (
            <Button onClick={ () => this.onClick() }>
               <Trans ns={I18nConstants.NS}
                      i18nKey={I18nConstants.ACTION_BAR_DIALOG_POST_OVERHEAD_CURRENT_MONTH}>
                    {I18nConstants.ACTION_BAR_DIALOG_POST_OVERHEAD_CURRENT_MONTH_DEFAULT}
                </Trans> 
            </Button> );
    }

    private onClick() {
        this.postTransactionsDialog.renderMessageBoxOnly();
    }
}

export default ActionBarButtonPostOverheadCurrentMonth;