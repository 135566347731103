import React from 'react';
import FormGroupFolderDropdown from '../fragment/FormGroupFolderDropdown';
import FormGroupDescription from '../fragment/FormGroupDescription';
import FormGroupUsername from '../fragment/FormGroupUsername';
import FormGroupPassword from '../fragment/FormGroupPassword';
import FormGroupLink from '../fragment/FormGroupLink';
import FormGroupNotes from '../fragment/FormGroupNotes';

class Form {
    private formGroupFolder: FormGroupFolderDropdown;
    private formGroupDescription: FormGroupDescription;
    private formGroupUsername: FormGroupUsername;
    private formGroupPassword: FormGroupPassword;
    private formGroupLink: FormGroupLink;
    private formGroupNotes: FormGroupNotes;

    public constructor(props, pointerToComponent) {
        this.formGroupFolder = new FormGroupFolderDropdown(props, pointerToComponent);
        this.formGroupDescription = new FormGroupDescription(props, pointerToComponent);
        this.formGroupUsername = new FormGroupUsername(props, pointerToComponent);
        this.formGroupPassword = new FormGroupPassword(props, pointerToComponent);
        this.formGroupLink = new FormGroupLink(props, pointerToComponent);
        this.formGroupNotes = new FormGroupNotes(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupFolder.render() }
                { this.formGroupDescription.render() }
                { this.formGroupUsername.render() }
                { this.formGroupPassword.render() }
                { this.formGroupLink.render() }
                { this.formGroupNotes.render() }

                { renderButtonCallback() }
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupFolder.isInvalidValue()
             || this.formGroupDescription.isInvalidValue()
             || this.formGroupUsername.isInvalidValue()
             || this.formGroupPassword.isInvalidValue()
             || this.formGroupLink.isInvalidValue()
             || this.formGroupNotes.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;