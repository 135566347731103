import React from 'react';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import BankingList from '../../model/BankingList';
import BankingListApi from '../../api/BankingListApi';
import BankingListUrlPath from '../BankingListUrlPath';
import BankingListGroupUrlPath from '../../../group/ui/BankingListGroupUrlPath';
import OverviewBase from '../../../../commons/ui/page/template/overview/OverviewBase';

class Overview extends OverviewBase<BankingList, BankingListApi> {
    public constructor(props) {
        super(props,
              BankingListApi.getInstance()
        );

        this.setCreateUrl(BankingListUrlPath.CREATE);
        this.setGroupsOverviewUrl(BankingListGroupUrlPath.BASE);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS} 
                       i18nKey={I18nConstants.TITLE} >
                    {I18nConstants.TITLE_DEFAULT}
                </Trans>
            </h1> 
        );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_GROUP}>
                        {I18nConstants.FORM_GROUP_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_BANK_NAME}>
                        {I18nConstants.FORM_BANK_NAME_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_IBAN}>
                        {I18nConstants.FORM_IBAN_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_BIC}>
                        {I18nConstants.FORM_BIC_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_NOTE}>
                        {I18nConstants.FORM_NOTE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_LINK}>
                        {I18nConstants.FORM_LINK_DEFAULT}
                    </Trans>
                </th>

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).group.name}
                </td>

                <td>
                    {(item as any).bankName}
                </td>

                <td>
                    {(item as any).iban}
                </td>

                <td>
                    {(item as any).bic}
                </td>

                <td>
                    {(item as any).note}
                </td>

                <td>
                    <a href={(item as any).link} target="_blank" rel="noopener noreferrer">
                        {(item as any).link}
                    </a>
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> 
        );
    }

    protected getActionBar() {
        return (
            <div>
                { this.getGroupsOverviewButton() }
            </div> 
        );
    }
}

export default Overview;