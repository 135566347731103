import I18nConstantsBase from '../../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "cyclingTourBikeItem";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static FORM_TYPE = "form.type";
    public static FORM_BRAND = "form.brand";
    public static FORM_MODEL = "form.model";
    public static FORM_IDENTIFICATION_NUMBER = "form.identificationNumber";
    public static FORM_DESCRIPTION = "form.description";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static FORM_TYPE_DEFAULT = DefaultJson.form.type;
    public static FORM_BRAND_DEFAULT = DefaultJson.form.brand;
    public static FORM_MODEL_DEFAULT = DefaultJson.form.model;
    public static FORM_IDENTIFICATION_NUMBER_DEFAULT = DefaultJson.form.identificationNumber;
    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
}

export default I18nConstants;