import IModelMapper from "../../../../commons/model/IModelMapper";
import FortuneSavingRateSumGroupedByGroupName from './FortuneSavingRateSumGroupedByGroupName';

class FortuneSavingRateSumGroupedByGroupNameModelMapper
        implements IModelMapper<FortuneSavingRateSumGroupedByGroupName> {
    fromJsonToObject(json: any): FortuneSavingRateSumGroupedByGroupName {
        let fortuneSavingRateSumGroupedByGroupName =
            new FortuneSavingRateSumGroupedByGroupName();

        fortuneSavingRateSumGroupedByGroupName.name = json.name;
        fortuneSavingRateSumGroupedByGroupName.sum  = json.sum;

        return fortuneSavingRateSumGroupedByGroupName;
    }

    fromObjectToJson(object: FortuneSavingRateSumGroupedByGroupName) {
        return {
            name: (object.name || ""),
            sum:  (object.sum || 0)
        };
    }
}

export default FortuneSavingRateSumGroupedByGroupNameModelMapper;