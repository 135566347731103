import IModelMapper from '../../../commons/model/IModelMapper';
import InsuranceListPaymentInterval from './InsuranceListPaymentInterval';

class InsuranceListPaymentIntervalModelMapper implements IModelMapper<InsuranceListPaymentInterval> {
    fromJsonToObject(json: any): InsuranceListPaymentInterval {
        let insuranceListPaymentInterval = new InsuranceListPaymentInterval();

        insuranceListPaymentInterval.id = json.id;
        insuranceListPaymentInterval.name = json.name;

        return insuranceListPaymentInterval;
    }

    fromObjectToJson(object: InsuranceListPaymentInterval) {
        return {
            id: object.id,
            name: (object.name || "")
        };
    }
}

export default InsuranceListPaymentIntervalModelMapper;