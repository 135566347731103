import React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';
import DeleteConfirm from '../../../../commons/ui/page/template/delete/DeleteConfirm';
import OverviewApiCallDelete from '../../../../commons/ui/page/template/overview/apiCall/OverviewApiCallDelete';
import OverviewButtonDelete from '../../../../commons/ui/page/template/overview/button/OverviewButtonDelete';
import OverviewButtonUpdate from '../../../../commons/ui/page/template/overview/button/OverviewButtonUpdate';
import OverviewEventHandlerDelete from '../../../../commons/ui/page/template/overview/eventHandler/OverviewEventHandlerDelete';
import OverviewEventHandlerUpdate from '../../../../commons/ui/page/template/overview/eventHandler/OverviewEventHandlerUpdate';
import OverviewHeadingDelete from '../../../../commons/ui/page/template/overview/heading/OverviewHeadingDelete';
import OverviewHeadingUpdate from '../../../../commons/ui/page/template/overview/heading/OverviewHeadingUpdate';
import LoginUrlPath from '../../../../login/ui/LoginUrlPath';
import AuthenticationApi from '../../../../security/api/AuthenticationApi';
import ToDoListApi from '../../api/ToDoListApi';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    ItemsNotStarted: [];
    ItemsInProcess: [];
    ItemsFinished: [];
}

abstract class OverviewBase extends Component<IProps, IState> {
    private deleteConfirm: DeleteConfirm;   // Used within callbacks.
    
    private api: ToDoListApi;   // Used within callbacks.

    public constructor(props) {
        super(props);

        this.deleteConfirm = new DeleteConfirm(props);

        this.api = ToDoListApi.getInstance();

        this.state = {
            isLoading: true,
            ItemsNotStarted: [],
            ItemsInProcess: [],
            ItemsFinished: []
        };
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            } else {
                return (
                    <div>
                        { this.getHeading() }
                        { this.getTable() }
                    </div>
                );
            }
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    // --------------------------------------------------------------------------------------------

    protected abstract getHeading();
    protected abstract getTable();

    // --------------------------------------------------------------------------------------------

    protected getUpdateHeadingWrappedInThTag() {
        return OverviewHeadingUpdate.render();
    }

    protected getDeleteHeadingWrappedInThTag() {
        return OverviewHeadingDelete.render();
    }

    protected getUpdateButtonWrappedInTdTag(id) {
        let eventHandlerUpdate = new OverviewEventHandlerUpdate();

        return OverviewButtonUpdate.render(this, eventHandlerUpdate.onAction, id);
    } 

    protected getDeleteButtonWrappedInTdTag(id) {
        let eventHandlerDelete = new OverviewEventHandlerDelete();

        return OverviewButtonDelete.render(this, eventHandlerDelete.onAction, id);
    }

    // --------------------------------------------------------------------------------------------

    public deleteItemById(pointerToComponent, id) {
        // This is a method which is used as a callback for delete confirm dialog.
        new OverviewApiCallDelete().execute(pointerToComponent, pointerToComponent.api, id);
    }
}

export default OverviewBase;
