import ApiCallExecutorBase from "../../../commons/ui/page/apiCallExecutor/ApiCallExecutor";
import IApiCallContext from '../../../commons/ui/page/apiCallExecutor/IApiCallContext';

import FortuneInvestmentPlanApi from './FortuneInvestmentPlanApi';

class FortuneInvestmentPlanApiCallExecutor<T> extends ApiCallExecutorBase<T> {
    private fortuneInvestmentPlanApi: FortuneInvestmentPlanApi;

    public constructor(pointerToComponent) {
        super(pointerToComponent);

        this.fortuneInvestmentPlanApi = FortuneInvestmentPlanApi.getInstance();
    }

    public getAll(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        context.currentData.pointerToApiCall.fortuneInvestmentPlanApi.getAll()
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }

    public getById(context: IApiCallContext) {
        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const id = (context.pointerToHelper.getParameterInArrayWithObjects(
                        context.parameters, "fortuneInvestmentPlanId") as number);

        context.currentData.pointerToApiCall.fortuneInvestmentPlanApi.getById(id)
        .then( response => {
                    const state = context.pointerToHelper.createStateObject(
                                    context.currentData.variableNameInState,
                                    (response as any));

                    context.pointerToComponent.setState(state);

                    try {
                        successorCallback.callback(context);
                    } catch (error) {
                        context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
                    }
                })
        .catch( error   => {
                    console.log("TODO");
                });
    }
}

export default FortuneInvestmentPlanApiCallExecutor;