import InsuranceListGroup from "../../group/model/InsuranceListGroup";
import InsuranceListPaymentInterval from "../../paymentInterval/model/InsuranceListPaymentInterval";

class InsuranceList {
    id!: number;
    group!: InsuranceListGroup;
    company!: string;
    name!: string;
    number!: string;
    dateOfBegin!: Date;
    dueDateYearly!: string;
    amountYearly!: number;
    paymentInterval!: InsuranceListPaymentInterval;
    description!: string;

    public constructor() {
        this.group = new InsuranceListGroup();
        this.paymentInterval = new InsuranceListPaymentInterval();
    }
}

export default InsuranceList;