import LinkListGroup from '../../group/model/LinkListGroup';

class LinkList {
    id!: number;
    group!: LinkListGroup;
    name!: string;
    description!: string;
    link!: string;

    public constructor() {
        this.group = new LinkListGroup();
    }
};

export default LinkList;