import React from 'react';
import { Component } from 'react';
import { Trans } from 'react-i18next';
import I18nConstants from '../../../i18n/I18nConstants';

class LoadingScreen extends Component {
    public static render() {
        return (
            <div>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.LOADING_SCREEN_MESSAGE}>
                    {I18nConstants.LOADING_SCREEN_MESSAGE_DEFAULT}
                </Trans>
            </div>
        );
    }
}

export default LoadingScreen;