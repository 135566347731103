import React from 'react';

import FormGroupContent from '../fragment/FormGroupContent';
import FormGroupWidth from '../fragment/FormGroupWidth';
import FormGroupHeight from '../fragment/FormGroupHeight';
import FormGroupSenderPersonDropdown from '../fragment/FormGroupSenderPersonDropdown';
import FormGroupSenderCompanyDropdown from '../fragment/FormGroupSenderCompanyDropdown';

class Form {
    private formGroupContent: FormGroupContent;
    private formGroupWidth: FormGroupWidth;
    private formGroupHeight: FormGroupHeight;
    private formGroupSenderPersonDropdown: FormGroupSenderPersonDropdown;
    private formGroupSenderCompanyDropdown: FormGroupSenderCompanyDropdown;

    public constructor(props, pointerToComponent) {
        this.formGroupContent = new FormGroupContent(props, pointerToComponent);
        this.formGroupWidth = new FormGroupWidth(props, pointerToComponent);
        this.formGroupHeight = new FormGroupHeight(props, pointerToComponent);
        this.formGroupSenderPersonDropdown = new FormGroupSenderPersonDropdown(props, pointerToComponent);
        this.formGroupSenderCompanyDropdown = new FormGroupSenderCompanyDropdown(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupContent.render() }
                { this.formGroupWidth.render() }
                { this.formGroupHeight.render() }
                { this.formGroupSenderPersonDropdown.render() }
                { this.formGroupSenderCompanyDropdown.render() }

                { renderButtonCallback() } 
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupContent.isInvalidValue()
             || this.formGroupWidth.isInvalidValue()
             || this.formGroupHeight.isInvalidValue()
             || this.formGroupSenderPersonDropdown.isInvalidValue()
             || this.formGroupSenderCompanyDropdown.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;