import FortuneSavingPlanGroup from "../../group/model/FortuneSavingPlanGroup";
import FortunePriority from "../../../priority/model/FortunePriority";
import FortuneInvestmentPlan from "../../../investmentPlan/model/FortuneInvestmentPlan";

class FortuneSavingPlan {
    id!:               number;
    group!:            FortuneSavingPlanGroup;
    priority!:         FortunePriority;
    description!:      string;
    monthlyRate!:      number;
    plannedEndDate!:   Date;
    plannedAmountMin!: number;
    plannedAmountMax!: number;
    addToFortune!:     boolean;
    investmentPlan!:   FortuneInvestmentPlan;

    public constructor() {
        this.group          = new FortuneSavingPlanGroup();
        this.priority       = new FortunePriority();
        this.investmentPlan = new FortuneInvestmentPlan();
    }
}

export default FortuneSavingPlan;