import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import i18n from '../../../../../commons/i18n/i18n';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupDayDropdown extends FormGroupBase {
    private ID_AND_NAME = "day";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const dayValues = this.getDayValues();

        return(
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_DAY,
                    I18nConstants.FORM_DAY_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.day}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {dayValues}
                </Input>
            </FormGroup> );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getDayValues() {
        let dayValues: string[] = [];

        for (let i = 0; i < 31; ++i) {
            dayValues.push((i +1).toString());
        } 

        const defaultValue   = this.getDefaultValue();
        const lastDayOfMonth = this.getLastDayOfMonthValue();

        let optionValues = dayValues.map( item =>
            <option value={item}>{item}</option> );

        optionValues.unshift(lastDayOfMonth);
        optionValues.unshift(defaultValue);

        return optionValues;
    }

    private getDefaultValue() {
        let optionValue = i18n.t(I18nConstants.NS_BASE + ":" + 
                                 I18nConstants.FORM_DROPDOWN_VALUES_DEFAULT);

        return (
            <option value="-1">
                --- {optionValue} ---
            </option> );
    }

    private getLastDayOfMonthValue() {
        let optionValue = i18n.t(I18nConstants.NS + ":" + 
                                 I18nConstants.FORM_RECURRENCE_DROPDOWN_VALUE_LAST_DAY_OF_MONTH);

        return (
            <option value="32">
                {optionValue}
            </option> );
    }
}

export default FormGroupDayDropdown;