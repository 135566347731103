import BankingListGroup from '../../group/model/BankingListGroup';

class BankingList {
    id!: number;
    group!: BankingListGroup;
    bankName!: string;
    iban!: string;
    bic!: string;
    note!: string;
    link!: string;

    public constructor() {
        this.group = new BankingListGroup();
    }
}

export default BankingList;