import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import ExpenseModelMapper from '../model/ExpenseModelMapper';

class ExpenseApi implements IGenericApi {
    private static instance: ExpenseApi;

    private static apiUrlPath = "expense";

    private static endpointSum = "/sum";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): ExpenseApi {
        if (!ExpenseApi.instance) {
            ExpenseApi.instance = new ExpenseApi();
        }

        return ExpenseApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    ExpenseApi.apiUrlPath,
                    new ExpenseModelMapper());
    }

    public getByMonthAndYear(month, year) {
        let filterAsString = "month" + "=" + month.toString() + "&" +
                             "year"  + "=" + year.toString(); 
        
        return GenericApi.executeGetCallWithFilter(
                    ExpenseApi.apiUrlPath,
                    filterAsString,
                    new ExpenseModelMapper());
    }

    public getSumByMonthAndYear(month, year) {
        let endpoint = ExpenseApi.endpointSum;

        let filterAsString = "month" + "=" + month.toString() + "&" +
                             "year"  + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                    ExpenseApi.apiUrlPath + endpoint,
                    filterAsString,
                    null);   // No mapper needed, because the result is just a double-value.
    }

    public getSumByYear(year) {
        let endpoint = ExpenseApi.endpointSum;

        let filterAsString = "year" + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                    ExpenseApi.apiUrlPath + endpoint,
                    filterAsString,
                    null);   // No mapper needed, because the result is just a double-value.
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    ExpenseApi.apiUrlPath,
                    id,
                    new ExpenseModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    ExpenseApi.apiUrlPath,
                    body,
                    new ExpenseModelMapper());
    }

    public update(id ,body) {
        return GenericApi.executePutCall(
                    ExpenseApi.apiUrlPath,
                    id,
                    body,
                    new ExpenseModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    ExpenseApi.apiUrlPath,
                    id,
                    new ExpenseModelMapper());
    }

    public getUrl() {
        return ExpenseApi.apiUrlPath;
    }
}

export default ExpenseApi;