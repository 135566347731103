import React from 'react';
import { Component } from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortuneSavingPlanApiCallExecutor from '../../api/FortuneSavingPlanApiCallExecutor';

import AuthenticationApi from '../../../../../security/api/AuthenticationApi';

import FortuneSavingPlanApi from '../../api/FortuneSavingPlanApi';

import LoadingScreen from '../../../../../commons/ui/page/loadingScreen/LoadingScreen';

import FortuneSavingPlanUrlPath from '../FortuneSavingPlanUrlPath';

import LoginUrlPath from '../../../../../login/ui/LoginUrlPath';

import OverviewUtils from '../../../../../commons/ui/page/template/overview/OverviewUtils';

import FortuneOverviewActionBarButton from '../../../../dashboard/ui/fragment/ActionBarButton';
import FortuneSavingRateActionBarButton from '../../../../savingRate/ui/fragment/ActionBarButton';
import FortuneInvestmentPlanActionBarButton from '../../../../investmentPlan/ui/fragment/ActionBarButton';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading: boolean;
    List:      [];
}

class Overview extends Component<IProps, IState> {
    private api:            FortuneSavingPlanApi;
    private apiCalls:           ApiCallExecutor;

    private overviewUtils:      OverviewUtils;

    private fortuneOverviewActionBarButton: FortuneOverviewActionBarButton;
    private fortuneSavingRateActionBarButton: FortuneSavingRateActionBarButton;
    private fortuneInvestmentPlanActionBarButton: FortuneInvestmentPlanActionBarButton;

    public constructor(props) {
        super(props);

        this.api = FortuneSavingPlanApi.getInstance();

        this.apiCalls = new ApiCallExecutor(this);

        this.fortuneOverviewActionBarButton = new FortuneOverviewActionBarButton(props.history);
        this.fortuneSavingRateActionBarButton = new FortuneSavingRateActionBarButton(props.history);
        this.fortuneInvestmentPlanActionBarButton = new FortuneInvestmentPlanActionBarButton(props.history);

        this.state = {
            isLoading: true,
            List:      []
        };

        this.overviewUtils = new OverviewUtils(
            this,
            this.getHeading(),
            FortuneSavingPlanUrlPath.CREATE,
            FortuneSavingPlanUrlPath.UPDATE,
            undefined,
            true,
            this.getActionBar());
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return(
                <div>
                    { this.overviewUtils.render(
                        this.getTableHead(),
                        this.getTableBody()) }
                </div> );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return(
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1>) ;
    }

    private getActionBar() {
        return (
            <div>
                { this.fortuneOverviewActionBarButton.render() }
                { this.fortuneSavingRateActionBarButton.render() }
                { this.fortuneInvestmentPlanActionBarButton.render() }
            </div> );
    }

    private getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_PRIORITY}>
                        {I18nConstants.FORM_PRIORITY_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_GROUP}>
                        {I18nConstants.FORM_GROUP_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.FORM_MONTHLY_RATE}>
                    {I18nConstants.FORM_MONTHLY_RATE_DEFAULT}
                </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_PLANNED_END_DATE}>
                        {I18nConstants.FORM_PLANNED_END_DATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_PLANNED_AMOUNT_MIN}>
                        {I18nConstants.FORM_PLANNED_AMOUNT_MIN_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_PLANNED_AMOUNT_MAX}>
                        {I18nConstants.FORM_PLANNED_AMOUNT_MAX_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_INVESTMENT_PLAN}>
                        {I18nConstants.FORM_INVESTMENT_PLAN_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS} 
                           i18nKey={I18nConstants.FORM_ADD_TO_FORTUNE}>
                        {I18nConstants.FORM_ADD_TO_FORTUNE_DEFAULT}
                    </Trans>
                </th>

                { this.overviewUtils.getUpdateHeadingWrappedInThTag() }
                { this.overviewUtils.getDeleteHeadingWrappedInThTag() }
            </tr> );
    }

    private getTableBody() {
        return this.state.List.map( item => 
            <tr key={(item as any).id}>
                <td>
                    {(item as any).priority.description}
                </td>

                <td>
                    {(item as any).group.name}
                </td>

                <td>
                    {(item as any).description}
                </td>

                <td>
                    {(item as any).monthlyRate}
                </td>

                <td>
                    {(item as any).plannedEndDate}
                </td>

                <td>
                    {(item as any).plannedAmountMin}
                </td>

                <td>
                    {(item as any).plannedAmountMax}
                </td>

                <td>
                    {((item as any).investmentPlan == undefined) 
                    ? "" 
                    : (item as any).investmentPlan.description}
                </td>

                <td id="checkbox">
                    <FormGroup check>
                        <Label check>
                            <Input 
                                type="checkbox"
                                checked={(item as any).addToFortune}
                                disabled="true" />
                        </Label>
                    </FormGroup>
                </td>

                { this.overviewUtils.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.overviewUtils.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private helper: ApiCallExecutorHelper;

    private fortuneSavingPlanApiCallExecutor: FortuneSavingPlanApiCallExecutor<Overview>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.fortuneSavingPlanApiCallExecutor = 
                new FortuneSavingPlanApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortuneSavingPlanGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortuneSavingPlanGetAll() : IApiCallback {
        return {
            callback: this.fortuneSavingPlanApiCallExecutor.getAll,
            pointerToApiCall: this.fortuneSavingPlanApiCallExecutor,
            variableNameInState: "List"
        };
    }
}

export default Overview;