import I18nConstantsBase from "../../../commons/i18n/I18nConstantsBase";
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "letterSignature";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static FORM_CONTENT = "form.content";
    public static FORM_CONTENT_TYPE = "form.contentType";
    public static FORM_WIDTH = "form.width";
    public static FORM_HEIGHT = "form.height";
    public static FORM_SENDER_PERSON = "form.sender.person";
    public static FORM_SENDER_COMPANY = "form.sender.company";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static FORM_CONTENT_DEFAULT = DefaultJson.form.content;
    public static FORM_CONTENT_TYPE_DEFAULT = DefaultJson.form.contentType;
    public static FORM_WIDTH_DEFAULT = DefaultJson.form.width;
    public static FORM_HEIGHT_DEFAULT = DefaultJson.form.height;
    public static FORM_SENDER_PERSON_DEFAULT = DefaultJson.form.sender.person;
    public static FORM_SENDER_COMPANY_DEFAULT = DefaultJson.form.sender.company;
}

export default I18nConstants;