import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import InsuranceListGroupModelMapper from '../model/InsuranceListGroupModelMapper';

class InsuranceListGroupApi implements IGenericApi {
    private static instance: InsuranceListGroupApi;

    private static apiUrlPath = "insuranceList/group";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): InsuranceListGroupApi {
        if (!InsuranceListGroupApi.instance) {
            InsuranceListGroupApi.instance = new InsuranceListGroupApi();
        }

        return InsuranceListGroupApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    InsuranceListGroupApi.apiUrlPath,
                    new InsuranceListGroupModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    InsuranceListGroupApi.apiUrlPath,
                    id,
                    new InsuranceListGroupModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    InsuranceListGroupApi.apiUrlPath,
                    body,
                    new InsuranceListGroupModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    InsuranceListGroupApi.apiUrlPath,
                    id,
                    body,
                    new InsuranceListGroupModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    InsuranceListGroupApi.apiUrlPath,
                    id,
                    new InsuranceListGroupModelMapper());
    }

    public getUrl() {
        return InsuranceListGroupApi.apiUrlPath;
    }
}

export default InsuranceListGroupApi;