import GenericApi from '../../../../commons/api/GenericApi';
import EarningsExpensesOverheadTransactionCreatorModelMapper from '../model/EarningsExpensesOverheadTransactionCreatorModelMapper';

class EarningsExpensesOverheadTransactionApi {
    private static instance: EarningsExpensesOverheadTransactionApi;

    private static apiUrlPath = "earningsExpenses/overhead/transaction";

    private static endpointCurrentMonthAndYear = "/currentMonthAndYear";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): EarningsExpensesOverheadTransactionApi {
        if (!EarningsExpensesOverheadTransactionApi.instance) {
            EarningsExpensesOverheadTransactionApi.instance = 
                new EarningsExpensesOverheadTransactionApi();
        }

        return EarningsExpensesOverheadTransactionApi.instance;
    }

    getPostingsForCurrentMonthAndYear() {
        let endpoint = EarningsExpensesOverheadTransactionApi.endpointCurrentMonthAndYear;

        return GenericApi.executeGetCallWithoutId(
                    EarningsExpensesOverheadTransactionApi.apiUrlPath + endpoint,
                    new EarningsExpensesOverheadTransactionCreatorModelMapper());
    }

    createPostingsForCurrentMonthAndYear() {
        let endpoint = EarningsExpensesOverheadTransactionApi.endpointCurrentMonthAndYear;

        return GenericApi.executePostCall(
                    EarningsExpensesOverheadTransactionApi.apiUrlPath + endpoint,
                    undefined,
                    new EarningsExpensesOverheadTransactionCreatorModelMapper());
    }
}

export default EarningsExpensesOverheadTransactionApi;