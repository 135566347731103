import BankingList from '../../model/BankingList';
import BankingListUrlPath from '../BankingListUrlPath';
import BankingListGroup from '../../../group/model/BankingListGroup';
import BankingListApi from '../../api/BankingListApi';
import BankingListGroupApi from '../../../group/api/BankingListGroupApi';
import CreateBaseWithGroup from '../../../../commons/ui/page/template/create/CreateBaseWithGroup';
import Form from './Form';
import FormGroupCreate from '../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBaseWithGroup<BankingList, BankingListGroup, 
                                         BankingListApi, BankingListGroupApi> {
    private form: Form;

    public constructor(props) {
        super(props,
              new BankingList(),
              BankingListApi.getInstance(),
              BankingListGroupApi.getInstance(),
              BankingListUrlPath.BASE
        );

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormGroupCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;