import ExpenseOverhead from '../../../expense/item/model/ExpenseOverhead';

class ExpenseOverheadTransaction {
    id!:              number;
    expenseOverhead!: ExpenseOverhead;
    day!:             number;
    month!:           number;
    recurrence!:      string;
    amount!:          number;
    mustBeChanged!:   boolean;

    public constructor() {
        this.mustBeChanged   = false;

        this.expenseOverhead = new ExpenseOverhead();
    }
}

export default ExpenseOverheadTransaction;