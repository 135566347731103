import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupAverageProfits extends FormGroupBase {
    private ID_AND_NAME = "averageProfits";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_AVERAGE_PROFITS,
                    I18nConstants.FORM_AVERAGE_PROFITS_DEFAULT) }

                <Input
                    type="number"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.averageProfits}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()} />
                
                { this.renderFormFeedbackWithInsertValidValueMessage() }
            </FormGroup> );
    }

    public isInvalidValue(): boolean {
        return this.pointerToComponent.isInvalidValue(this.pointerToComponent.state.Item.averageProfits);
    }
}

export default FormGroupAverageProfits;