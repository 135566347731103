import OverviewEventHandlerBase from './OverviewEventHandlerBase';
import Utils from '../../../../../../utilities/Utils';

class OverviewEventHandlerCreate extends OverviewEventHandlerBase {
    public onAction(pointerToComponentOrUtil, id = -1) {
        let createUrl = "";

        if (pointerToComponentOrUtil.createUrl) {
            createUrl = pointerToComponentOrUtil.createUrl;
        } else {
            createUrl = Utils.getCreateUrl(pointerToComponentOrUtil.props.location.pathname);
        }

        if (pointerToComponentOrUtil.props === undefined) {
            // For OverviewUtils
            pointerToComponentOrUtil.propsHistory.push(createUrl);
        } else {
            // For OverviewBase
            pointerToComponentOrUtil.props.history.push(createUrl);
        }
        
    }
}

export default OverviewEventHandlerCreate;