import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import ExpenseGroupModelMapper from '../model/ExpenseGroupModelMapper';
import ExpenseGroupSumModelMapper from '../model/helper/ExpenseGroupSumModelMapper';

class ExpenseGroupApi implements IGenericApi {
    private static instance: ExpenseGroupApi;

    private static apiUrlPath = "expense/group";

    private static endpointSum = "/sumAsList";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): ExpenseGroupApi {
        if (!ExpenseGroupApi.instance) {
            ExpenseGroupApi.instance = new ExpenseGroupApi();
        }

        return ExpenseGroupApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    ExpenseGroupApi.apiUrlPath,
                    new ExpenseGroupModelMapper()
        );
    }

    public getSumAsListByMonthAndYear(month, year) {
        let endpoint = ExpenseGroupApi.endpointSum;

        let filterAsString = "month" + "=" + month.toString() + "&" +
                             "year"  + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
            ExpenseGroupApi.apiUrlPath + endpoint,
            filterAsString,
            new ExpenseGroupSumModelMapper()
        );
    }

    public getSumAsListByYear(year) {
        let endpoint = ExpenseGroupApi.endpointSum;

        let filterAsString = "year" + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
            ExpenseGroupApi.apiUrlPath + endpoint,
            filterAsString,
            new ExpenseGroupSumModelMapper()
        );
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    ExpenseGroupApi.apiUrlPath,
                    id,
                    new ExpenseGroupModelMapper()
        );
    }

    public create(body) {
        return GenericApi.executePostCall(
                    ExpenseGroupApi.apiUrlPath,
                    body,
                    new ExpenseGroupModelMapper()
        );
    }

    public update(id ,body) {
        return GenericApi.executePutCall(
                    ExpenseGroupApi.apiUrlPath,
                    id,
                    body,
                    new ExpenseGroupModelMapper()
        );
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    ExpenseGroupApi.apiUrlPath,
                    id,
                    new ExpenseGroupModelMapper()
        );
    }

    public getUrl() {
        return ExpenseGroupApi.apiUrlPath;
    }
}

export default ExpenseGroupApi;