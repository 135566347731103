import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "bankingListItem";

    public static TITLE = "title";

    public static FORM_GROUP = "form.group";
    public static FORM_BANK_NAME = "form.bankName";
    public static FORM_IBAN = "form.iban";
    public static FORM_BIC = "form.bic";
    public static FORM_NOTE = "form.note";
    public static FORM_LINK = "form.link";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;

    public static FORM_GROUP_DEFAULT = DefaultJson.form.group;
    public static FORM_BANK_NAME_DEFAULT = DefaultJson.form.bankName;
    public static FORM_IBAN_DEFAULT = DefaultJson.form.iban;
    public static FORM_BIC_DEFAULT = DefaultJson.form.bic;
    public static FORM_NOTE_DEFAULT = DefaultJson.form.note;
    public static FORM_LINK_DEFAULT = DefaultJson.form.link;
}

export default I18nConstants;