import I18nConstantsBase from '../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "login";

    public static TITLE = "title";

    public static FORM_USERNAME = "form.username";
    public static FORM_PASSWORD = "form.password";

    public static FORM_BUTTON_LOGIN = "form.button.login";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;

    public static FORM_USERNAME_DEFAULT = DefaultJson.form.username;
    public static FORM_PASSWORD_DEFAULT = DefaultJson.form.password;

    public static FORM_BUTTON_LOGIN_DEFAULT = DefaultJson.form.button.login;
}

export default I18nConstants;