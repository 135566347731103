import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';

import KindOfStreetModelMapper from '../model/KindOfStreetModelMapper';

class KindOfStreetApi implements IGenericApi {
    private static instance: KindOfStreetApi;

    private static apiUrlPath = "/fuelConsumption/kindOfStreet";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): KindOfStreetApi {
        if (!KindOfStreetApi.instance) {
            KindOfStreetApi.instance = new KindOfStreetApi();
        }

        return KindOfStreetApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    KindOfStreetApi.apiUrlPath,
                    new KindOfStreetModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    KindOfStreetApi.apiUrlPath,
                    id,
                    new KindOfStreetModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    KindOfStreetApi.apiUrlPath,
                    body,
                    new KindOfStreetModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    KindOfStreetApi.apiUrlPath,
                    id,
                    body,
                    new KindOfStreetModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    KindOfStreetApi.apiUrlPath,
                    id,
                    new KindOfStreetModelMapper());
    }

    public getUrl() {
        return KindOfStreetApi.apiUrlPath;
    }
}

export default KindOfStreetApi;