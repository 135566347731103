import IModelMapper from '../../../commons/model/IModelMapper';
import FuelConsumption from './FuelConsumption';

class FuelConsumptionModelMapper implements IModelMapper<FuelConsumption> {
    fromJsonToObject(json: any): FuelConsumption {
        let fuelConsumption = new FuelConsumption();

        fuelConsumption.id                  = json.id;
        fuelConsumption.vehicle             = json.vehicle;
        fuelConsumption.odometerMileage     = json.odometerMileage;
        fuelConsumption.drivenKm            = json.drivenKm;
        fuelConsumption.mileage             = json.mileage;
        fuelConsumption.expense             = json.expense;
        fuelConsumption.pricePerUnit        = json.pricePerUnit;
        fuelConsumption.date                = json.date;
        fuelConsumption.airConditioningUsed = json.airConditioningUsed;
        fuelConsumption.tire                = json.tire;
        fuelConsumption.kindOfStreet        = json.kindOfStreet;
        fuelConsumption.drivingStyle        = json.drivingStyle;
        fuelConsumption.note                = json.note;

        return fuelConsumption;
    }

    fromObjectToJson(object: FuelConsumption) {
        return {
            id:                  object.id,
            vehicle:             (object.vehicle || undefined),
            odometerMileage:     (object.odometerMileage || 0),
            drivenKm:            (object.drivenKm || 0),
            mileage:             (object.mileage || 0),
            expense:             (object.expense || 0),
            pricePerUnit:        (object.pricePerUnit || 0),
            date:                (object.date || "0001-01-01"),
            airConditioningUsed: (object.airConditioningUsed || false),
            tire:                (object.tire || undefined),
            kindOfStreet:        (object.kindOfStreet || undefined),
            drivingStyle:        (object.drivingStyle || undefined),
            note:                (object.note || "")
        };
    }
}

export default FuelConsumptionModelMapper;