import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import FortuneExpensePlannedModelMapper from '../model/FortuneExpensePlannedModelMapper';

class FortuneExpensePlannedApi implements IGenericApi {
    private static instance: FortuneExpensePlannedApi;

    private static apiUrlPath = "fortune/expensePlanned";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): FortuneExpensePlannedApi {
        if (!FortuneExpensePlannedApi.instance) {
            FortuneExpensePlannedApi.instance = new FortuneExpensePlannedApi();
        }

        return FortuneExpensePlannedApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    FortuneExpensePlannedApi.apiUrlPath,
                    new FortuneExpensePlannedModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    FortuneExpensePlannedApi.apiUrlPath,
                    id,
                    new FortuneExpensePlannedModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    FortuneExpensePlannedApi.apiUrlPath,
                    body,
                    new FortuneExpensePlannedModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    FortuneExpensePlannedApi.apiUrlPath,
                    id,
                    body,
                    new FortuneExpensePlannedModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    FortuneExpensePlannedApi.apiUrlPath,
                    id,
                    new FortuneExpensePlannedModelMapper());
    }

    public getUrl() {
        return FortuneExpensePlannedApi.apiUrlPath;
    }
}

export default FortuneExpensePlannedApi;