import ToDoListGroup from '../../group/model/ToDoListGroup';

class ToDoList {
    id!: number;
    group!: ToDoListGroup;
    description!: string;
    toDoUntil!: Date;
    statusInPercent!: number;

    public constructor() {
        this.group = new ToDoListGroup();
    }
}

export default ToDoList;