import Utils from '../../../../utilities/Utils';
import FortuneSavingRatio from '../../model/FortuneSavingRatio';
import FortuneSavingRatioApi from '../../api/FortuneSavingRatioApi';
import FortuneSavingRatioUrlPath from '../FortuneSavingRatioUrlPath';
import UpdateBase from '../../../../commons/ui/page/template/update/UpdateBase';
import Form from './Form';
import FormButtonUpdate from '../../../../commons/ui/page/template/form/button/FormButtonUpdate';

class Update extends UpdateBase<FortuneSavingRatio, FortuneSavingRatioApi> {
    private form: Form;

    public constructor(props) {
        const idOfItemToBeUpdated = 
            Utils.getIdFromGetParameters(props.location.search);

        super(props,
              new FortuneSavingRatio(),
              FortuneSavingRatioApi.getInstance(),
              idOfItemToBeUpdated,
              FortuneSavingRatioUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonUpdate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Update;