import IModelMapper from '../../../commons/model/IModelMapper';
import BankingList from './BankingList';

class BankingListModelMapper implements IModelMapper<BankingList> {
    fromJsonToObject(json: any): BankingList {
        let bankingList = new BankingList();

        bankingList.id = json.id;
        bankingList.group = json.group;
        bankingList.bankName = json.bankName;
        bankingList.iban = json.iban;
        bankingList.bic = json.bic;
        bankingList.note = json.note;
        bankingList.link = json.link;

        return bankingList;
    }

    fromObjectToJson(object: BankingList) {
        return {
            id: object.id,
            group: (object.group || undefined),
            bankName: (object.bankName || ""),
            iban: (object.iban || ""),
            bic: (object.bic || ""),
            note: (object.note || ""),
            link: (object.link || "")
        };
    }
}

export default BankingListModelMapper;