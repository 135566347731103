import React from 'react';
import { Table } from 'reactstrap';
import NavigationRenderer from '../../../fragment/NavigationRenderer';
import DeleteConfirm from '../delete/DeleteConfirm';
import OverviewButtonCreate from './button/OverviewButtonCreate';
import OverviewEventHandlerCreate from './eventHandler/OverviewEventHandlerCreate';
import OverviewEventHandlerUpdate from './eventHandler/OverviewEventHandlerUpdate';
import OverviewEventHandlerDelete from './eventHandler/OverviewEventHandlerDelete';
import OverviewHeadingUpdate from './heading/OverviewHeadingUpdate';
import OverviewHeadingDelete from './heading/OverviewHeadingDelete';
import OverviewHeadingSaveAsPDF from './heading/OverviewHeadingSaveAsPDF';
import OverviewButtonUpdate from './button/OverviewButtonUpdate';
import OverviewButtonDelete from './button/OverviewButtonDelete';
import OverviewApiCallDelete from './apiCall/OverviewApiCallDelete';
import OverviewButtonSaveAsPDF from './button/OverviewButtonSaveAsPDF';
import OverviewHeadingShowDetails from './heading/OverviewHeadingShowDetails';
import OverviewButtonShowDetails from './button/OverviewButtonShowDetails';
import OverviewEventHandlerShowDetails from './eventHandler/OverviewEventHandlerShowDetails';

class OverviewUtils {
    private deleteConfirm: DeleteConfirm;   // Used within callbacks.

    private pointerToComponent: object;    // Used within callbacks.

    private propsHistory: object;   // Used within callbacks.

    private createUrl: string;  // Used within callbacks.
    private updateUrl: string;  // Used within callbacks.
    private showDetailsUrl: string; // Used within callbacks.

    private heading: object;

    private createButtonCallback: object;
    private actionBarCallback: object;

    private showNavbar: boolean;

    // --------------------------------------------------------------------------------------------
    // --- Constructor ---

    public constructor(pointerToComponent, heading, createUrl, updateUrl,
                       createButtonCallback = (undefined as any),
                       showNavbar = true,
                       actionBarCallback = (undefined as any),
                       showDetailsUrl = (undefined as any),) {
        this.deleteConfirm = new DeleteConfirm(pointerToComponent.props);

        this.pointerToComponent = pointerToComponent;
        this.propsHistory = pointerToComponent.props.history;

        this.heading = heading;

        this.createUrl = createUrl;
        this.updateUrl = updateUrl;
        this.showDetailsUrl = showDetailsUrl;

        this.createButtonCallback = createButtonCallback;

        this.actionBarCallback = actionBarCallback;

        this.showNavbar = showNavbar;
    }

    // --------------------------------------------------------------------------------------------
    // --- Render ---

    public render(tableHead, tableBody, 
                  initialSelections = (undefined as any)) {
        return (
            <div>
                { NavigationRenderer.renderIfNecessary(this.showNavbar) }

                {this.heading}

                <div>
                    {initialSelections}

                    { this.getActionBarFromCallbackOrNormalButton() }

                    { this.getCreateButtonFromCallbackOrNormalButton() }

                    <Table>
                        <thead>
                            {tableHead}
                        </thead>

                        <tbody>
                            {tableBody}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different Sections - Mandatory ---
    // --- (Could be changed by sub-classes via callback) ---

    private getCreateButtonFromCallbackOrNormalButton() {
        if (this.createButtonCallback) {
            return this.createButtonCallback;
        } else {
            return this.getCreateButton();
        }
    }

    private getActionBarFromCallbackOrNormalButton() {
        if (this.actionBarCallback) {
            return this.actionBarCallback;
        } else {
            return <div></div>;
        }
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different sections (for sub-classes) - Headings - Optional ---

    public getUpdateHeadingWrappedInThTag() {
        return OverviewHeadingUpdate.render();
    }

    public getDeleteHeadingWrappedInThTag() {
        return OverviewHeadingDelete.render();
    }

    public getShowDetailsWrappedInThTag() {
        return OverviewHeadingShowDetails.render();
    }

    public getSaveAsPDFWrappedInThTag() {
        return OverviewHeadingSaveAsPDF.render();
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different sections - Buttons (with event handlers) - Optional ---

    private getCreateButton() {
        let eventHandlerCreate = new OverviewEventHandlerCreate();

        return OverviewButtonCreate.render(this, eventHandlerCreate.onAction);
    }

    public getUpdateButtonWrappedInTdTag(id) {
        let eventHandlerUpdate = new OverviewEventHandlerUpdate();

        return OverviewButtonUpdate.render(this, eventHandlerUpdate.onAction, id);
    }

    public getDeleteButtonWrappedInTdTag(id) {
        let eventHandlerDelete = new OverviewEventHandlerDelete();

        return OverviewButtonDelete.render(this, eventHandlerDelete.onAction, id);
    }

    public getShowDetailsButtonWrappedInTdTag(id) {
        let eventHandlerShowDetails = new OverviewEventHandlerShowDetails();

        return OverviewButtonShowDetails.render(this, eventHandlerShowDetails.onAction, id);
    }

    public getSaveAsPDFButtonWrappedInTdTag(pointerToComponent, callback, id) {
        return OverviewButtonSaveAsPDF.render(pointerToComponent, callback, id);
    }

    // --------------------------------------------------------------------------------------------
    // --- Delete values (via API) ---

    public deleteItemById(pointerToComponent, id) {
        // This is a method which is used as a callback for delete confirm dialog.
        new OverviewApiCallDelete().execute(pointerToComponent, pointerToComponent.api, id);
    }
}

export default OverviewUtils;