import EarningOverhead from "../../../earning/item/model/EarningOverhead";

class EarningOverheadTransaction {
    id!:              number;
    earningOverhead!: EarningOverhead;
    day!:             number;
    month!:           number;
    recurrence!:      string;
    amount!:          number;
    mustBeChanged!:   boolean;

    public constructor() {
        this.mustBeChanged   = false;
        
        this.earningOverhead = new EarningOverhead();
    }
}

export default EarningOverheadTransaction;