import EarningGroup from '../../model/EarningGroup';
import EarningGroupApi from '../../api/EarningGroupApi';
import EarningGroupUrlPath from '../EarningsExpensesEarningGroupUrlPath';
import CreateBase from '../../../../../commons/ui/page/template/group/create/CreateBase';

class Create extends CreateBase<EarningGroup, EarningGroupApi> {
    public constructor(props) {
        super(props,
              new EarningGroup(),
              EarningGroupApi.getInstance(),
              EarningGroupUrlPath.BASE
        );
    }
}

export default Create;