import DefaultJson from './en/common.json';

class I18nConstantsBase {
    public static NS_BASE = "commons";

    public static FORM_ERROR_INSERT_VALID_VALUE = "form.error.insertValidValue";
    public static FORM_ERROR_CHOOSE_VALID_VALUE = "form.error.chooseValidValue";

    public static FORM_DROPDOWN_VALUES_DEFAULT = "form.dropdownValues.default";
    public static FORM_DROPDOWN_VALUES_NONE = "form.dropdownValues.none";

    public static MESSAGE_BOX_DELETE_TITLE = "messageBox.delete.title";
    public static MESSAGE_BOX_DELETE_MESSAGE = "messageBox.delete.message";

    public static ACTION_CREATE = "action.create";
    public static ACTION_UPDATE = "action.update";
    public static ACTION_DELETE = "action.delete";
    public static ACTION_SHOW_DETAILS = "action.showDetails";
    public static ACTION_SAVE = "action.save";
    public static ACTION_SAVE_AS_PDF = "action.saveAsPDF";
    public static ACTION_UPDATE_ALL = "action.updateAll";

    public static GENERAL_YES = "general.yes";
    public static GENERAL_NO = "general.no";
    public static GENERAL_SUM = "general.sum";

    public static GENERAL_MONTHS_1 = "general.months.january";
    public static GENERAL_MONTHS_2 = "general.months.february";
    public static GENERAL_MONTHS_3 = "general.months.march";
    public static GENERAL_MONTHS_4 = "general.months.april";
    public static GENERAL_MONTHS_5 = "general.months.may";
    public static GENERAL_MONTHS_6 = "general.months.june";
    public static GENERAL_MONTHS_7 = "general.months.july";
    public static GENERAL_MONTHS_8 = "general.months.august";
    public static GENERAL_MONTHS_9 = "general.months.september";
    public static GENERAL_MONTHS_10 = "general.months.october";
    public static GENERAL_MONTHS_11 = "general.months.november";
    public static GENERAL_MONTHS_12 = "general.months.december";

    // --------------------------------------------------------------------------------------------

    public static FORM_ERROR_INSERT_VALID_VALUE_DEFAULT = DefaultJson.form.error.insertValidValue;
    public static FORM_ERROR_CHOOSE_VALID_VALUE_DEFAULT = DefaultJson.form.error.chooseValidValue;

    public static FORM_DROPDOWN_VALUES_DEFAULT_DEFAULT = DefaultJson.form.dropdownValues.default;
    public static FORM_DROPDOWN_VALUES_NONE_DEFAULT = DefaultJson.form.dropdownValues.none;

    public static MESSAGE_BOX_DELETE_TITLE_DEFAULT = DefaultJson.messageBox.delete.title;
    public static MESSAGE_BOX_DELETE_MESSAGE_DEFAULT = DefaultJson.messageBox.delete.message;

    public static ACTION_CREATE_DEFAULT = DefaultJson.action.create;
    public static ACTION_UPDATE_DEFAULT = DefaultJson.action.update;
    public static ACTION_DELETE_DEFAULT = DefaultJson.action.delete;
    public static ACTION_SHOW_DETAILS_DEFAULT = DefaultJson.action.showDetails;
    public static ACTION_SAVE_DEFAULT = DefaultJson.action.save;
    public static ACTION_SAVE_AS_PDF_DEFAULT = DefaultJson.action.saveAsPDF;
    public static ACTION_UPDATE_ALL_DEFAULT = DefaultJson.action.updateAll;

    public static GENERAL_YES_DEFAULT = DefaultJson.general.yes;
    public static GENERAL_NO_DEFAULT = DefaultJson.general.no;
    public static GENERAL_SUM_DEFAULT = DefaultJson.general.sum;

    public static GENERAL_MONTHS_1_DEFAULT = DefaultJson.general.months.january;
    public static GENERAL_MONTHS_2_DEFAULT = DefaultJson.general.months.february;
    public static GENERAL_MONTHS_3_DEFAULT = DefaultJson.general.months.march;
    public static GENERAL_MONTHS_4_DEFAULT = DefaultJson.general.months.april;
    public static GENERAL_MONTHS_5_DEFAULT = DefaultJson.general.months.may;
    public static GENERAL_MONTHS_6_DEFAULT = DefaultJson.general.months.june;
    public static GENERAL_MONTHS_7_DEFAULT = DefaultJson.general.months.july;
    public static GENERAL_MONTHS_8_DEFAULT = DefaultJson.general.months.august;
    public static GENERAL_MONTHS_9_DEFAULT = DefaultJson.general.months.september;
    public static GENERAL_MONTHS_10_DEFAULT = DefaultJson.general.months.october;
    public static GENERAL_MONTHS_11_DEFAULT = DefaultJson.general.months.november;
    public static GENERAL_MONTHS_12_DEFAULT = DefaultJson.general.months.december;
}

export default I18nConstantsBase;