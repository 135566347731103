import NavigationLinkBase from "../../../../commons/ui/page/navigationLink/NavigationLinkBase";
import I18nConstants from '../../i18n/I18nConstants';
import FortuneInvestmentAimUrlPath from "../FortuneInvestmentAimUrlPath";

class NavigationLink extends NavigationLinkBase {
    public constructor() {
        super(
            FortuneInvestmentAimUrlPath.BASE,
            I18nConstants.NS,
            I18nConstants.TITLE,
            I18nConstants.TITLE_DEFAULT
        );
    }
}

export default NavigationLink;