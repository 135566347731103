import GenericApi from "../../../commons/api/GenericApi";
import IGenericApi from "../../../commons/api/IGenericApi";
import LetterSignatureModelMapper from "../model/LetterSignatureModelMapper";

class LetterSignatureApi implements IGenericApi {
    private static instance: LetterSignatureApi;

    private static apiUrlPath = "/letter/signature";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): LetterSignatureApi {
        if (!LetterSignatureApi.instance) {
            LetterSignatureApi.instance = new LetterSignatureApi();
        }

        return LetterSignatureApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    LetterSignatureApi.apiUrlPath,
                    new LetterSignatureModelMapper()
        );
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    LetterSignatureApi.apiUrlPath,
                    id,
                    new LetterSignatureModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    LetterSignatureApi.apiUrlPath,
                    body,
                    new LetterSignatureModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    LetterSignatureApi.apiUrlPath,
                    id,
                    body,
                    new LetterSignatureModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    LetterSignatureApi.apiUrlPath,
                    id,
                    new LetterSignatureModelMapper());
    }

    public getUrl() {
        return LetterSignatureApi.apiUrlPath;
    }
}

export default LetterSignatureApi;