import IModelMapper from '../../../../commons/model/IModelMapper';
import Bike from './Bike';

class BikeModelMapper implements IModelMapper<Bike> {
    fromJsonToObject(json: any): Bike {
        let bike = new Bike();

        bike.id = json.id;
        bike.bikeType = json.bikeType;
        bike.brand = json.brand;
        bike.model = json.model;
        bike.identificationNumber = json.identificationNumber;
        bike.description = json.description;

        return bike;
    }

    fromObjectToJson(object: Bike) {
        return {
            id: object.id,
            bikeType: (object.bikeType || undefined),
            brand: (object.brand || ""),
            model: (object.model || ""),
            identificationNumber: (object.identificationNumber || ""),
            description: (object.description || "")
        };
    }
}

export default BikeModelMapper;