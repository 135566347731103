import FortunePriority from '../../model/FortunePriority';
import FortunePriorityApi from '../../api/FortunePriorityApi';
import FortunePriorityUrlPath from '../FortunePriorityUrlPath';
import CreateBase from '../../../../commons/ui/page/template/create/CreateBase';
import Form from './Form';
import FormButtonCreate from '../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBase<FortunePriority, FortunePriorityApi> {
    private form: Form;

    public constructor(props) {
        super(props, 
              new FortunePriority(),
              FortunePriorityApi.getInstance(),
              FortunePriorityUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;