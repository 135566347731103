import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "fortuneSavingRatio";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static CREATE = "create";

    public static AVERAGE_CURRENT_YEAR = "averageCurrentYear";
    public static AVERAGE_TOTAL = "averageTotal";

    public static FORM_MONTH = "form.month";
    public static FORM_YEAR = "form.year";
    public static FORM_SAVING_RATIO = "form.savingRatio";
    public static FORM_EARNINGS = "form.earnings";
    public static FORM_EXPENSES = "form.expenses";

    public static DIAGRAM_DATE = "diagram.date";
    public static DIAGRAM_PERCENTAGE = "diagram.percentage";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static CREATE_DEFAULT = DefaultJson.create;

    public static AVERAGE_CURRENT_YEAR_DEFAULT = DefaultJson.averageCurrentYear;
    public static AVERAGE_TOTAL_DEFAULT = DefaultJson.averageTotal;

    public static FORM_MONTH_DEFAULT = DefaultJson.form.month;
    public static FORM_YEAR_DEFAULT = DefaultJson.form.year;
    public static FORM_SAVING_RATIO_DEFAULT = DefaultJson.form.savingRatio;
    public static FORM_EARNINGS_DEFAULT = DefaultJson.form.earnings;
    public static FORM_EXPENSES_DEFAULT = DefaultJson.form.expenses;

    public static DIAGRAM_DATE_DEFAULT = DefaultJson.diagram.date;
    public static DIAGRAM_PERCENTAGE_DEFAULT = DefaultJson.diagram.percentage;
}

export default I18nConstants;