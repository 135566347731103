import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';

import ToDoListGroupModelMapper from '../model/ToDoListGroupModelMapper';

class ToDoListGroupApi implements IGenericApi {
    private static instance: ToDoListGroupApi;

    private static apiUrlPath = "toDoList/group";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): ToDoListGroupApi {
        if (!ToDoListGroupApi.instance) {
            ToDoListGroupApi.instance = new ToDoListGroupApi();
        }

        return ToDoListGroupApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    ToDoListGroupApi.apiUrlPath,
                    new ToDoListGroupModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    ToDoListGroupApi.apiUrlPath,
                    id,
                    new ToDoListGroupModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    ToDoListGroupApi.apiUrlPath,
                    body,
                    new ToDoListGroupModelMapper());
    }

    public update(id ,body) {
        return GenericApi.executePutCall(
                    ToDoListGroupApi.apiUrlPath,
                    id,
                    body,
                    new ToDoListGroupModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    ToDoListGroupApi.apiUrlPath,
                    id,
                    new ToDoListGroupModelMapper());
    }

    public getUrl() {
        return ToDoListGroupApi.apiUrlPath;
    }
}

export default ToDoListGroupApi;