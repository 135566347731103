import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import FortunePriorityApiCallExecutor from '../../../priority/api/FortunePriorityApiCallExecutor';

import FortuneInvestmentPlan from '../../model/FortuneInvestmentPlan';
import FortuneInvestmentPlanApi from '../../api/FortuneInvestmentPlanApi';
import FortuneInvestmentPlanUrlPath from '../FortuneInvestmentPlanUrlPath';

import FortunePriority from '../../../priority/model/FortunePriority';

import CreateUtils from '../../../../commons/ui/page/template/create/CreateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:   boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item:        FortuneInvestmentPlan;
    Priorities:  FortunePriority[];
}

class Create extends Component<IProps, IState> {
    private apiCalls:    ApiCallExecutor;
    private api:     FortuneInvestmentPlanApi;
    private form:        Form;
    private createUtils: CreateUtils;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = FortuneInvestmentPlanApi.getInstance();

        this.state = {
            isLoading:   true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item:        new FortuneInvestmentPlan(),
            Priorities:  []
        };

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            FortuneInvestmentPlanUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.createUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1> );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.createUtils.handleSubmit(event);
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Create;

    private helper: ApiCallExecutorHelper;

    private fortunePriorityApiCallExecutor: FortunePriorityApiCallExecutor<Create>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.fortunePriorityApiCallExecutor = new FortunePriorityApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addFortunePriorityGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addFortunePriorityGetAll() : IApiCallback {
        return {
            callback: this.fortunePriorityApiCallExecutor.getAll,
            pointerToApiCall: this.fortunePriorityApiCallExecutor,
            variableNameInState: "Priorities"
        };
    }
}

export default Create;