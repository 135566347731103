class FormValidator {
    private pointerToComponent: object;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        // Initial state: No submit was clicked by user and valueToBeChecked is 'undefined'.
        if (    (!this.wasHandleSubmitClickedAtLeastOnce())
             && (valueToBeChecked === undefined)) {
            return false;
        }

        // Submit was clicked at least once, but no valueToBeChecked was inserted.
        if (    (this.wasHandleSubmitClickedAtLeastOnce())
             && (valueToBeChecked === undefined)) {
            return true;
        }

        // A valueToBeChecked wasn't inserted.
        if (valueToBeChecked.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    public isInvalidDropdownValue(valueToBeChecked) : boolean {
        // Initial state: No submit was clicked by user and valueToBeChecked is 'undefined'.
        if (    (!this.wasHandleSubmitClickedAtLeastOnce())
             && (valueToBeChecked === undefined)) {
            return false;
        }

        // Submit was clicked at least once, but no valueToBeChecked was inserted.
        if (    (this.wasHandleSubmitClickedAtLeastOnce())
             && (valueToBeChecked === undefined)) {
            return true;
        }

        // A valueToBeChecked wasn't inserted.
        if (    (!valueToBeChecked)
             || (valueToBeChecked == -1)) {
            return true;
        } else {
            return false;
        }
    }

    private wasHandleSubmitClickedAtLeastOnce() : boolean {
        return (this.pointerToComponent as any).state.wasHandleSubmitClickedAtLeastOnce;
    }
}

export default FormValidator;