import UpdateBase from '../../../../commons/ui/page/template/group/update/UpdateBase';

import ToDoListGroup from '../../model/ToDoListGroup';
import ToDoListGroupApi from '../../api/ToDoListGroupApi';
import ToDoListGroupUrlPath from '../ToDoListGroupUrlPath';

class Update extends UpdateBase<ToDoListGroup, ToDoListGroupApi> {
    public constructor(props) {
        super(props,
              new ToDoListGroup(),
              ToDoListGroupApi.getInstance(),
              ToDoListGroupUrlPath.BASE);
    }
}

export default Update;