import FortuneRiskClass from "../../../riskClass/model/FortuneRiskClass";

class FortuneSavingPlanGroup {
    id!:                number;
    riskClass!:         FortuneRiskClass;
    name!:              string;
    averageProfits!:    number;
    monthlySavingRate!: number;

    public constructor() {
        this.riskClass = new FortuneRiskClass();
    }
}

export default FortuneSavingPlanGroup;