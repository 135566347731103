import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupTire extends FormGroupBase {
    private ID_AND_NAME = "tire";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const tires = this.getTiresSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_TIRE,
                    I18nConstants.FORM_TIRE_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.tire.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()}>
                    {tires}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getTiresSelectValues() {
        const { Tires } = this.pointerToComponent.state;

        return this.getSelectValues(Tires, "name");
    }
}

export default FormGroupTire;