import React from 'react';
import { Button } from 'reactstrap';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';

import FortuneInvestmentAim from '../../model/FortuneInvestmentAim';
import FortuneInvestmentAimApi from '../../api/FortuneInvestmentAimApi';
import FortuneInvestmentAimUrlPath from '../FortuneInvestmentAimUrlPath';

import OverviewBase from '../../../../commons/ui/page/template/overview/OverviewBase';

import FortuneOverviewActionBarButton from '../../../dashboard/ui/fragment/ActionBarButton';
import FortuneInvestmentPlanActionBarButton from '../../../investmentPlan/ui/fragment/ActionBarButton';

class Overview extends OverviewBase<FortuneInvestmentAim, FortuneInvestmentAimApi> {
    private fortuneOverviewActionBarButton: FortuneOverviewActionBarButton;
    private fortuneInvestmentPlanActionBarButton: FortuneInvestmentPlanActionBarButton;
    
    public constructor(props) {
        super(props,
              FortuneInvestmentAimApi.getInstance());

        this.setCreateUrl(FortuneInvestmentAimUrlPath.CREATE);

        this.fortuneOverviewActionBarButton = new FortuneOverviewActionBarButton(props.history);
        this.fortuneInvestmentPlanActionBarButton = new FortuneInvestmentPlanActionBarButton(props.history);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> );
    }

    protected getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DESCRIPTION}>
                        {I18nConstants.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_PLANNED_AMOUNT}>
                        {I18nConstants.FORM_PLANNED_AMOUNT_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_PLANNED_DATE}>
                        {I18nConstants.FORM_PLANNED_DATE_DEFAULT}
                    </Trans>
                </th>

                { this.getUpdateHeadingWrappedInThTag() }
                { this.getDeleteHeadingWrappedInThTag() }
            </tr> );
    }

    protected getTableBody() {
        return this.state.List.map( item =>
            <tr key={(item as any).id}>
                <td>
                    {(item as any).description}
                </td>

                <td>
                    {(item as any).plannedAmount}
                </td>

                <td>
                    {(item as any).plannedDate}
                </td>

                { this.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr> );
    }

    protected getActionBar() {
        return (
            <div>
                { this.fortuneOverviewActionBarButton.render() }
                { this.fortuneInvestmentPlanActionBarButton.render() }
            </div> );
    }
}

export default Overview;