import React from 'react';
import { Trans } from 'react-i18next';

import I18nConstants from '../../i18n/I18nConstants';
import I18nConstantsEarningsExpenses from '../../../../item/i18n/I18nConstants';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import ExpenseApiCallExecutor from '../../api/ExpenseApiCallExecutor';
import ExpenseGroupApiCallExecutor from '../../../group/api/ExpenseGroupApiCallExecutor';
import EarningsExpensesApiCallExecutor from '../../../../item/api/EarningsExpensesApiCallExecutor';

import Utils from '../../../../../utilities/Utils';

import ExpenseGroup from "../../../group/model/ExpenseGroup";
import ExpenseGroupApi from "../../../group/api/ExpenseGroupApi";

import OverviewBase from '../../../../../commons/ui/page/template/overview/OverviewBase';

import PieChartWithSumAndDiagram from '../../../../../commons/ui/page/diagram/PieChartWithSumAndPercentage';

class OverviewGroupedByYear extends OverviewBase<ExpenseGroup, ExpenseGroupApi> {
    private apiCalls: ApiCallExecutor;
    private diagram: PieChartWithSumAndDiagram;

    public constructor(props) {
        super(props,
              ExpenseGroupApi.getInstance());

        this.diagram = new PieChartWithSumAndDiagram();
        this.apiCalls = new ApiCallExecutor(this, this.diagram);

        this.setShowNavbar(false);
    }

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> );
    }

    protected getSum() {
        return(
            <div>
                <p>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                        i18nKey={I18nConstantsEarningsExpenses.SUM}>
                        {I18nConstantsEarningsExpenses.SUM_DEFAULT}
                    </Trans>
                    : {this.state.sum}
                </p>
            </div> );
    }

    protected getTableHead() {
        return(
            <tr>
                <th>
                    <Trans ns={I18nConstantsEarningsExpenses.NS} 
                           i18nKey={I18nConstantsEarningsExpenses.FORM_DESCRIPTION}>
                        {I18nConstantsEarningsExpenses.FORM_DESCRIPTION_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                           i18nKey={I18nConstantsEarningsExpenses.FORM_PERCENTAGE}>
                        {I18nConstantsEarningsExpenses.FORM_PERCENTAGE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstantsEarningsExpenses.NS}
                           i18nKey={I18nConstantsEarningsExpenses.FORM_AMOUNT}>
                        {I18nConstantsEarningsExpenses.FORM_AMOUNT_DEFAULT}
                    </Trans>
                </th>
            </tr>
        );
    }

    protected getTableBody() {
        return this.state.List.map( item => 
            <tr key={(item as any).id}>
                <td>
                    {(item as any).name}
                </td>

                <td>
                    {Utils.roundTwoDecimalPlaces((item as any).sum * 100 / this.state.sum)}
                </td>

                <td>
                    {(item as any).sum}
                </td>
            </tr> );
    }

    protected getDiagramAfterContent() {
        return this.diagram.getHtmlElement(this.state.diagram);
    }

    protected getInitialValuesForListInState() {
        this.apiCalls.executeAll();
    }

    protected updateListInState(nextProps) {
        let currentYearSelection = nextProps.currentYearSelection;

        if (currentYearSelection) {
            this.apiCalls.executeStartingAfterYearSelection(currentYearSelection);
        } else {
            console.log("TODO");
        }
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: OverviewGroupedByYear;
    private pointerToDiagram: PieChartWithSumAndDiagram;

    private helper: ApiCallExecutorHelper;

    private earningsExpensesApiCallExecutor: EarningsExpensesApiCallExecutor<OverviewGroupedByYear>;
    private expenseApiCallExecutor: ExpenseApiCallExecutor<OverviewGroupedByYear>;
    private expenseGroupApiCallExecutor: ExpenseGroupApiCallExecutor<OverviewGroupedByYear>;

    public constructor(pointerToComponent, pointerToDiagram) {
        this.pointerToComponent = pointerToComponent;
        this.pointerToDiagram = pointerToDiagram;

        this.helper = new ApiCallExecutorHelper();

        this.earningsExpensesApiCallExecutor = new EarningsExpensesApiCallExecutor(pointerToComponent);
        this.expenseApiCallExecutor = new ExpenseApiCallExecutor(pointerToComponent);
        this.expenseGroupApiCallExecutor = new ExpenseGroupApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------
 
    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningsExpensesGetYearsAsString());
        orderOfCalls.push(this.addExpenseGroupGetSumAsListByYear());
        orderOfCalls.push(this.addExpenseGetSumByYear());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
            this.pointerToComponent,
            this.helper, 
            orderOfCalls, 
            parameters);

        this.helper.startWithFirstCall(context);
    }

    public executeStartingAfterYearSelection(year) {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addExpenseGroupGetSumAsListByYear());
        orderOfCalls.push(this.addExpenseGetSumByYear());
        orderOfCalls.push(this.addDiagramData());

        let context = this.helper.createContext(
            this.pointerToComponent,
            this.helper, 
            orderOfCalls, 
            parameters);

        this.helper.addParameterToContext(context, "year", year);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addEarningsExpensesGetYearsAsString() : IApiCallback {
        return {
            callback: this.earningsExpensesApiCallExecutor.getYearsAsStringLastYear,
            pointerToApiCall: this.earningsExpensesApiCallExecutor,
            variableNameInState: ""
        }
    }

    private addExpenseGroupGetSumAsListByYear() : IApiCallback {
        return {
            callback: this.expenseGroupApiCallExecutor.getSumAsListByYear,
            pointerToApiCall: this.expenseGroupApiCallExecutor,
            variableNameInState: "List"
        };
    }

    private addExpenseGetSumByYear() : IApiCallback {
        return {
            callback: this.expenseApiCallExecutor.getSumByYear,
            pointerToApiCall: this.expenseApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addDiagramData() : IApiCallback {
        return {
            callback: this.pointerToDiagram.updateDiagramData,
            pointerToApiCall: this.pointerToDiagram,
            variableNameInState: "diagram"
        };
    }
}

export default OverviewGroupedByYear;