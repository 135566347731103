import IModelMapper from "../../../../../commons/model/IModelMapper";
import FortuneSavingPlanMonthlyRateByInvestmentPlan from './FortuneSavingPlanMonthlyRateByInvestmentPlan';

class FortuneSavingPlanMonthlyRateByInvestmentPlanModelMapper
    implements IModelMapper<FortuneSavingPlanMonthlyRateByInvestmentPlan> {
    fromJsonToObject(json: any): FortuneSavingPlanMonthlyRateByInvestmentPlan {
        let fortuneSavingPlanMonthlyRateByInvestmentPlan = 
            new FortuneSavingPlanMonthlyRateByInvestmentPlan();

        fortuneSavingPlanMonthlyRateByInvestmentPlan.name = json.name;
        fortuneSavingPlanMonthlyRateByInvestmentPlan.monthlyRate = json.monthlyRate;

        return fortuneSavingPlanMonthlyRateByInvestmentPlan;
    }

    fromObjectToJson(object: FortuneSavingPlanMonthlyRateByInvestmentPlan) {
        return {
            name: (object.name || ""),
            monthlyRate: (object.monthlyRate || 0)
        };
    }
}

export default FortuneSavingPlanMonthlyRateByInvestmentPlanModelMapper;