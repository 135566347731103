import IModelMapper from "../../../../commons/model/IModelMapper";

import FortuneSavingPlanGroup from './FortuneSavingPlanGroup';

class FortuneSavingPlanGroupModelMapper implements IModelMapper<FortuneSavingPlanGroup> {
    fromJsonToObject(json: any): FortuneSavingPlanGroup {
        let fortuneSavingPlanGroup = new FortuneSavingPlanGroup();

        fortuneSavingPlanGroup.id                = json.id;
        fortuneSavingPlanGroup.riskClass         = json.riskClass;
        fortuneSavingPlanGroup.name              = json.name;
        fortuneSavingPlanGroup.averageProfits    = json.averageProfits;
        fortuneSavingPlanGroup.monthlySavingRate = json.monthlySavingRate;

        return fortuneSavingPlanGroup;
    }

    fromObjectToJson(object: FortuneSavingPlanGroup) {
        return {
            id:                object.id,
            riskClass:         (object.riskClass || undefined),
            name:              (object.name || ""),
            averageProfits:    (object.averageProfits || 0),
            monthlySavingRate: (object.monthlySavingRate || 0)
        };
    }
}

export default FortuneSavingPlanGroupModelMapper;