import React from 'react';

import FormGroupDescription from '../fragment/FormGroupDescription';
import FormGroupPlannedDate from '../fragment/FormGroupPlannedDate';
import FormGroupPlannedAmount from '../fragment/FormGroupPlannedAmount';

class Form {
    private formGroupDescription:   FormGroupDescription;
    private formGroupPlannedDate:   FormGroupPlannedDate;
    private formGroupPlannedAmount: FormGroupPlannedAmount;

    public constructor(props, pointerToComponent) {
        this.formGroupDescription   = new FormGroupDescription(props, pointerToComponent);
        this.formGroupPlannedDate   = new FormGroupPlannedDate(props, pointerToComponent);
        this.formGroupPlannedAmount = new FormGroupPlannedAmount(props, pointerToComponent);
    }
    
    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupDescription.render() }
                { this.formGroupPlannedDate.render() }
                { this.formGroupPlannedAmount.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.formGroupDescription.isInvalidValue()
             || this.formGroupPlannedDate.isInvalidValue()
             || this.formGroupPlannedAmount.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;