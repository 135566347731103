import Expense from '../../model/Expense';
import ExpenseGroup from '../../../group/model/ExpenseGroup';
import ExpenseApi from '../../api/ExpenseApi';
import ExpenseGroupApi from '../../../group/api/ExpenseGroupApi';
import EarningsExpensesUrlPath from '../../../../item/ui/EarningsExpensesUrlPath';
import CreateBaseWithGroup from '../../../../../commons/ui/page/template/create/CreateBaseWithGroup';
import Form from './Form';
import FormButtonCreate from '../../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBaseWithGroup<Expense, ExpenseGroup, ExpenseApi, ExpenseGroupApi> {
    private form: Form;

    public constructor(props) {
        super(props, 
              new Expense(),
              ExpenseApi.getInstance(),
              ExpenseGroupApi.getInstance(),
              EarningsExpensesUrlPath.BASE);

        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;