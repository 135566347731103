import IModelMapper from '../../../commons/model/IModelMapper';
import Letter from './Letter';

class LetterModelMapper implements IModelMapper<Letter> {
    fromJsonToObject(json: any): Letter {
        let letter = new Letter();

        letter.id = json.id;
        letter.senderPerson = json.senderPerson;
        letter.senderCompany = json.senderCompany;
        letter.addresseePerson = json.addresseePerson;
        letter.addresseeCompany = json.addresseeCompany;
        letter.template = json.template;
        letter.date = json.date;
        letter.subject = json.subject;
        letter.text = json.text;
        letter.enclosure = json.enclosure;
        letter.useSignature = json.useSignature;

        return letter;
    }

    fromObjectToJson(object: Letter) {
        return {
            id: object.id,
            senderPerson: (object.senderPerson || undefined),
            senderCompany: (object.senderCompany || undefined),
            addresseePerson: (object.addresseePerson || undefined),
            addresseeCompany: (object.addresseeCompany || undefined),
            template: (object.template || undefined),
            date: (object.date || "0001-01-01"),
            subject: (object.subject || ""),
            text: (object.text || ""),
            enclosure: (object.enclosure || ""),
            useSignature: (object.useSignature || false)
        };
    }
}

export default LetterModelMapper;