import FortuneExpensesPlannedGroup from "../../group/model/FortuneExpensePlannedGroup";

class FortuneExpensePlanned {
    id!:          number;
    group!:       FortuneExpensesPlannedGroup;
    description!: string;
    amount!:      number;

    public constructor() {
        this.group = new FortuneExpensesPlannedGroup();
    }
}

export default FortuneExpensePlanned;