import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import LinkListGroupModelMapper from '../model/LinkListGroupModelMapper';

class LinkListGroupApi implements IGenericApi {
    private static instance: LinkListGroupApi;

    private static apiUrlPath = "linkList/group";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): LinkListGroupApi {
        if (!LinkListGroupApi.instance) {
            LinkListGroupApi.instance = new LinkListGroupApi();
        }

        return LinkListGroupApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    LinkListGroupApi.apiUrlPath,
                    new LinkListGroupModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    LinkListGroupApi.apiUrlPath,
                    id,
                    new LinkListGroupModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    LinkListGroupApi.apiUrlPath,
                    body,
                    new LinkListGroupModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    LinkListGroupApi.apiUrlPath,
                    id,
                    body,
                    new LinkListGroupModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    LinkListGroupApi.apiUrlPath,
                    id,
                    new LinkListGroupModelMapper());
    }

    public getUrl() {
        return LinkListGroupApi.apiUrlPath;
    }
}

export default LinkListGroupApi;