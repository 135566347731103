import React from 'react';
import { Component } from 'react';
import { Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import I18nConstants from '../../../../../i18n/I18nConstants';
import AuthenticationApi from '../../../../../../security/api/AuthenticationApi';
import LoadingScreen from '../../../loadingScreen/LoadingScreen';
import LoginUrlPath from '../../../../../../login/ui/LoginUrlPath';
import DeleteConfirm from '../../delete/DeleteConfirm';
import NavigationRenderer from '../../../../fragment/NavigationRenderer';
import OverviewEventHandlerCreate from '../../overview/eventHandler/OverviewEventHandlerCreate';
import OverviewEventHandlerUpdate from '../../overview/eventHandler/OverviewEventHandlerUpdate';
import OverviewEventHandlerDelete from '../../overview/eventHandler/OverviewEventHandlerDelete';
import OverviewButtonCreate from '../../overview/button/OverviewButtonCreate';
import OverviewButtonUpdate from '../../overview/button/OverviewButtonUpdate';
import OverviewButtonDelete from '../../overview/button/OverviewButtonDelete';
import OverviewApiCallGetAll from './apiCall/OverviewApiCallGetAll';
import OverviewApiCallDelete from './apiCall/OverviewApiCallDelete';

interface IProps<Group> {
    history: any;
    location: any;
}

interface IState<Group> {
    isLoading: boolean;
    Groups: Group[]; 
}

class OverviewBase<Group, ApiForGroup> 
  extends Component<IProps<Group>, IState<Group>> {
    private api: ApiForGroup;

    private deleteConfirm: DeleteConfirm;   // Used within callbacks.

    private createUrl: string;  // Used within callbacks.
    private updateUrl: string;  // Used within callbacks.

    private showNavbar: boolean;

    // --------------------------------------------------------------------------------------------
    // --- Constructor ---

    public constructor(props, groupService) {
        super(props);

        this.api = groupService;

        this.deleteConfirm = new DeleteConfirm(props);

        // --- Could by changed by setters ---
        this.createUrl = "";
        this.updateUrl = "";
        // --- END - Could by changed by setters ---

        this.showNavbar = true;

        this.state = {
            isLoading: true,
            Groups: []
        };
    }

    // --------------------------------------------------------------------------------------------
    // --- Members - Could be set in sub-classes ---

    public setCreateUrl(url) {
        this.createUrl = url;
    }

    public setUpdateUrl(url) {
        this.updateUrl = url;
    }

    // --------------------------------------------------------------------------------------------
    // --- Render ---
    
    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return (
                <div>
                    { NavigationRenderer.renderIfNecessary(this.showNavbar) }

                    { this.getHeading() }

                    <div>
                        { this.getCreateButton() }

                        <Table>
                            <thead>
                                { this.getTableHead() }
                            </thead>

                            <tbody>
                                { this.getTableContent() }
                            </tbody>
                        </Table>
                    </div>
                </div>
            );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different Sections (couldn't be overwritten) - Mandatory ---

    private getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_NAME}>
                        {I18nConstants.FORM_NAME_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.ACTION_UPDATE}>
                        {I18nConstants.ACTION_UPDATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.ACTION_DELETE}>
                        {I18nConstants.ACTION_DELETE_DEFAULT}
                    </Trans>
                </th>
            </tr>
        );
    }

    private getTableContent() {
        return this.state.Groups.map( group =>
            <tr key={(group as any).id}>
                <td>
                    {(group as any).name}
                </td>

                { this.getUpdateButtonWrappedInTdTag((group as any).id) }
                { this.getDeleteButtonWrappedInTdTag((group as any).id) }
            </tr>
        );
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different Sections (for sub-classes) - Optional ---

    protected getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.FORM_GROUP_TITLE}>
                    {I18nConstants.FORM_GROUP_TITLE_DEFAULT}
                </Trans>
            </h1> 
        );
    }

    // --------------------------------------------------------------------------------------------
    // --- Render - Different sections - Buttons (with event handlers) - Not to be overwritten ---

    private getCreateButton() {
        let eventHandlerCreate = new OverviewEventHandlerCreate();

        return OverviewButtonCreate.render(this, eventHandlerCreate.onAction);
    }

    private getUpdateButtonWrappedInTdTag(id) {
        let eventHandlerUpdate = new OverviewEventHandlerUpdate();

        return OverviewButtonUpdate.render(this, eventHandlerUpdate.onAction, id);
    }

    private getDeleteButtonWrappedInTdTag(id) {
        let eventHandlerDelete = new OverviewEventHandlerDelete();

        return OverviewButtonDelete.render(this, eventHandlerDelete.onAction, id);
    }

    // --------------------------------------------------------------------------------------------
    // --- Get/delete values (via API) ---

    public async componentDidMount() {
        this.getInitialValuesForGroupInState();
    }

    private getInitialValuesForGroupInState() {
        new OverviewApiCallGetAll().execute(this, this.api);
    }

    public deleteItemById(pointerToComponent, id) {
        // This is a method which is used as a callback for delete confirm dialog.
        new OverviewApiCallDelete().execute(pointerToComponent, pointerToComponent.api, id);
    }
};

export default OverviewBase;