import React from 'react';

import FormGroupGroupValuesByMethod from '../fragment/FormGroupGroupDropdown';
import FormGroupDescription from '../fragment/FormGroupDescription';
import FormGroupQuantity from '../fragment/FormGroupQuantity';
import FormGroupKindOfQuantity from '../fragment/FormGroupKindOfQuantity';
import FormGroupAmount from '../fragment/FormGroupAmount';

class Form {
    private formGroupGroup:          FormGroupGroupValuesByMethod;
    private formGroupDescription:    FormGroupDescription;
    private formGroupQuantity:       FormGroupQuantity;
    private formGroupKindOfQuantity: FormGroupKindOfQuantity;
    private formGroupAmount:         FormGroupAmount;

    public constructor(props, pointerToComponent) {
        this.formGroupGroup          = new FormGroupGroupValuesByMethod(props, pointerToComponent);
        this.formGroupDescription    = new FormGroupDescription(props, pointerToComponent);
        this.formGroupQuantity       = new FormGroupQuantity(props, pointerToComponent);
        this.formGroupKindOfQuantity = new FormGroupKindOfQuantity(props, pointerToComponent);
        this.formGroupAmount         = new FormGroupAmount(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupGroup.render() }
                { this.formGroupDescription.render() }
                { this.formGroupQuantity.render() }
                { this.formGroupKindOfQuantity.render() }
                { this.formGroupAmount.render() }

                { renderButtonCallback() }
            </div> );
    }

    public isFormValid(): boolean {
        if (    this.formGroupGroup.isInvalidValue()
             || this.formGroupDescription.isInvalidValue()
             || this.formGroupQuantity.isInvalidValue() 
             || this.formGroupKindOfQuantity.isInvalidValue()
             || this.formGroupAmount.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;