import IModelMapper from '../../../../commons/model/IModelMapper';

import VehicleType from './VehicleType';

class VehicleTypeModelMapper implements IModelMapper<VehicleType> {
    fromJsonToObject(json: any): VehicleType {
        let vehicleType = new VehicleType();

        vehicleType.id   = json.id;
        vehicleType.name = json.name;

        return vehicleType;
    }

    fromObjectToJson(object: VehicleType) {
        return {
            id: object.id,
            name: (object.name || "") };
    }
}

export default VehicleTypeModelMapper;