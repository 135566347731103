import ActionBarButtonBase from '../../../../commons/ui/page/actionBar/ActionBarButtonBase';
import LetterSignatureUrlPath from "../LetterSignatureUrlPath";
import I18nConstants from '../../i18n/I18nConstants';

class ActionBarButton extends ActionBarButtonBase {
    public constructor(propsHistory) {
        super(propsHistory,
              LetterSignatureUrlPath.BASE,
              I18nConstants.NS,
              I18nConstants.TITLE_SHORT,
              I18nConstants.TITLE_SHORT_DEFAULT);
    }
}

export default ActionBarButton;