import LocalStorageToken from './LocalStorageToken';
import GenericApi from "../../commons/api/GenericApi";
import UserModelMapper from "../model/UserModelMapper";

class AuthenticationApi {
    public constructor() {
        throw new Error('Constructor can\'t be called, because it\'s a singleton!');
    }

    public static login(username, password) {
        return new Promise((resolve, reject) => {
            GenericApi.executePostCall(
                "login",
                { "username": username,
                  "password": password },
                new UserModelMapper(),
                false)
                    .then(response => {
                                AuthenticationApi.createResponse(response, resolve);                             
                           })
                    .catch(error => reject(error));
        });
    }

    public static isLoggedIn() {
        const token = LocalStorageToken.get();

        if (    (token != null)
             && (token.length > 0)) {
            return true;
        } else {
            return false;
        }
    }

    public static logout() {
        LocalStorageToken.clear();
    }

    // --------------------------------------------------------------------------------------------
    // Helpers
    // --------------------------------------------------------------------------------------------

    private static createResponse(response, resolve) {
        if (GenericApi.isValidStatusCode(response.status)) {
            const token = response.headers.authorization;

            if (token) {
                LocalStorageToken.set(token);
                resolve(true);
            }
        } else {
            resolve(response.data);
        }
    }
}

export default AuthenticationApi;