import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupReplicationMethod extends FormGroupBase {
    private ID_AND_NAME = "replicationMethod";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_REPLICATION_METHOD,
                    I18nConstants.FORM_REPLICATION_METHOD_DEFAULT) }

                <Input 
                    type="text" 
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.replicationMethod}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()} />
                    
                { this.renderFormFeedbackWithInsertValidValueMessage() }
            </FormGroup> );
    }

    public isInvalidValue(): boolean {
        return this.pointerToComponent.isInvalidValue(this.pointerToComponent.state.Item.replicationMethod);
    }
}

export default FormGroupReplicationMethod;