import React from 'react';
import { Component } from 'react';

import Utils from '../../../../../utilities/Utils';

import CreateUtils from '../../../../../commons/ui/page/template/create/CreateUtils';

import EarningOverheadTransaction from '../../model/EarningOverheadTransaction';
import EarningOverheadTransactionUrlPath from '../EarningsExpensesOverheadTransactionEarningUrlPath';
import EarningOverheadTransactionApi from '../../api/EarningOverheadTransactionApi';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item:        EarningOverheadTransaction;
}

class Create extends Component<IProps, IState> {
    private api:     EarningOverheadTransactionApi;
    private form:        Form;
    private createUtils: CreateUtils;

    private overheadId:  number;

    public constructor(props) {
        super(props);

        this.state = {
            wasHandleSubmitClickedAtLeastOnce: false,
            Item:        new EarningOverheadTransaction()
        };

        this.overheadId = 
            Utils.getOverheadIdFromGetParameters(props.location.search);

        this.api = EarningOverheadTransactionApi.getInstance();

        this.form = new Form(props, this);

        this.createUtils = new CreateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            EarningOverheadTransactionUrlPath.BASE + 
            "?overheadId=" + this.overheadId.toString());

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        if (    (!this.overheadId)
             || (this.overheadId <= 0)) {
            // TODO
            return(<div>No ID specified!</div>)
        } 
        
        return this.createUtils.render();
    }

    private getHeading() {
        return(
            <h1>
                
            </h1>) ;
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.createUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.createUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.createUtils.handleChange(event);
    }

    private handleSubmit(event) {
        const { Item } = this.state;
        Item.earningOverhead.id = this.overheadId;
        this.setState({ Item: Item });

        this.createUtils.handleSubmit(event);
    }
}

export default Create;