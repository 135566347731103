import IGenericApi from '../../../../commons/api/IGenericApi';
import GenericApi from '../../../../commons/api/GenericApi';
import EarningGroupModelMapper from '../model/EarningGroupModelMapper';
import EarningGroupSumModelMapper from '../model/helper/EarningGroupSumModelMapper';

class EarningGroupApi implements IGenericApi {
    private static instance: EarningGroupApi;

    private static apiUrlPath = "earning/group";

    private static endpointSum = "/sumAsList";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): EarningGroupApi {
        if (!EarningGroupApi.instance) {
            EarningGroupApi.instance = new EarningGroupApi();
        }

        return EarningGroupApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    EarningGroupApi.apiUrlPath,
                    new EarningGroupModelMapper());
    }

    public getSumAsListByMonthAndYear(month, year) {
        let endpoint = EarningGroupApi.endpointSum;

        let filterAsString = "month" + "=" + month.toString() + "&" +
                             "year"  + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                                EarningGroupApi.apiUrlPath + endpoint,
                                filterAsString,
                                new EarningGroupSumModelMapper());
    }

    public getSumAsListByYear(year) {
        let endpoint = EarningGroupApi.endpointSum;

        let filterAsString = "year" + "=" + year.toString();

        return GenericApi.executeGetCallWithFilter(
                                EarningGroupApi.apiUrlPath + endpoint,
                                filterAsString,
                                new EarningGroupSumModelMapper());
        }

    public getById(id) {
        return GenericApi.executeGetCall(
                                EarningGroupApi.apiUrlPath,
                                id,
                                new EarningGroupModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                                EarningGroupApi.apiUrlPath,
                                body,
                                new EarningGroupModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                                EarningGroupApi.apiUrlPath,
                                id,
                                body,
                                new EarningGroupModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                                EarningGroupApi.apiUrlPath,
                                id,
                                new EarningGroupModelMapper());
    }

    public getUrl() {
        return EarningGroupApi.apiUrlPath;
    }
}

export default EarningGroupApi;