import IModelMapper from '../../../../commons/model/IModelMapper';
import LetterPersonTitle from './LetterPersonTitle';

class LetterPersonTitleModelMapper implements IModelMapper<LetterPersonTitle> {
    fromJsonToObject(json: any): LetterPersonTitle {
        let letterPersonTitle = new LetterPersonTitle();

        letterPersonTitle.id = json.id;
        letterPersonTitle.name = json.name;

        return letterPersonTitle;
    }

    fromObjectToJson(object: LetterPersonTitle) {
        return {
            id: object.id,
            name: (object.name || "")
        };
    }
}

export default LetterPersonTitleModelMapper;