import LetterAddresseeCompany from "../../addressee/company/model/LetterAddresseeCompany";
import LetterAddresseePerson from "../../addressee/person/model/LetterAddresseePerson";
import LetterSenderCompany from "../../sender/company/model/LetterSenderCompany";
import LetterSenderPerson from "../../sender/person/model/LetterSenderPerson";
import LetterTemplate from "../../template/model/LetterTemplate";


class Letter {
    id!: number;
    senderPerson!: LetterSenderPerson;
    senderCompany!: LetterSenderCompany;
    addresseePerson!: LetterAddresseePerson;
    addresseeCompany!: LetterAddresseeCompany;
    template!: LetterTemplate;
    date!: Date;
    subject!: string;
    text!: string;
    enclosure!: string;
    useSignature!: boolean;

    public constructor() {
        this.senderPerson = new LetterSenderPerson();
        this.senderCompany = new LetterSenderCompany();
        this.addresseePerson = new LetterAddresseePerson();
        this.addresseeCompany = new LetterAddresseeCompany();
        this.template = new LetterTemplate();
    }
}

export default Letter;