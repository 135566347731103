import CreateBase from '../../../../../commons/ui/page/template/group/create/CreateBase';
import BikeType from '../../model/BikeType';
import BikeTypeApi from '../../api/BikeTypeApi';
import BikeTypeUrlPath from '../../ui/BikeTypeUrlPath';

class Create extends CreateBase<BikeType, BikeTypeApi> {
    public constructor(props) {
        super(props,
              new BikeType(),
              BikeTypeApi.getInstance(),
              BikeTypeUrlPath.BASE
        );
    }
}

export default Create;