import IModelMapper from '../../../commons/model/IModelMapper';
import LinkListGroup from './LinkListGroup';

class LinkListGroupModelMapper implements IModelMapper<LinkListGroup> {
    fromJsonToObject(json: any): LinkListGroup {
        let linkListGroup = new LinkListGroup();

        linkListGroup.id   = json.id;
        linkListGroup.name = json.name;

        return linkListGroup;
    }

    fromObjectToJson(object: LinkListGroup) {
        return {
            id:   object.id,
            name: (object.name || "") 
        };
    }
}

export default LinkListGroupModelMapper;