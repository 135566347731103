import ExpenseGroup from '../../../../earningsExpenses/expense/group/model/ExpenseGroup';

class ExpenseOverhead {
    id!: number;
    group!: ExpenseGroup;
    description!: string;
    amount!: number;

    public constructor() {
        this.group = new ExpenseGroup();
    }
}

export default ExpenseOverhead;