import OverviewEventHandlerBase from './OverviewEventHandlerBase';

class OverviewEventHandlerGroups extends OverviewEventHandlerBase {
    public onAction(pointerToComponentOrUtils) {
        let groupsOverviewUrl = pointerToComponentOrUtils.groupsOverviewUrl;

        if (groupsOverviewUrl) {
            pointerToComponentOrUtils.props.history.push(groupsOverviewUrl);
        }
    }
}

export default OverviewEventHandlerGroups;