import React from 'react';
import FormGroupDescription from '../fragment/FormGroupDescription';
import FormGroupContent from '../fragment/FormGroupContent';

class Form {
    private formGroupDescription: FormGroupDescription;
    private formGroupContent: FormGroupContent;

    public constructor(props, pointerToComponent) {
        this.formGroupDescription = new FormGroupDescription(props, pointerToComponent);
        this.formGroupContent = new FormGroupContent(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupDescription.render() }
                { this.formGroupContent.render() }

                { renderButtonCallback() }
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupDescription.isInvalidValue()
             || this.formGroupContent.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;