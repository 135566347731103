import IModelMapper from '../../../../commons/model/IModelMapper';

import EarningOverheadTransaction from './EarningOverheadTransaction';

class EarningOverheadTransactionModelMapper implements IModelMapper<EarningOverheadTransaction> {
    fromJsonToObject(json: any): EarningOverheadTransaction {
        let earningOverheadTransaction = new EarningOverheadTransaction();
        
        earningOverheadTransaction.id              = json.id;
        earningOverheadTransaction.earningOverhead = json.earningOverhead;
        earningOverheadTransaction.day             = json.day;
        earningOverheadTransaction.month           = json.month;
        earningOverheadTransaction.recurrence      = json.recurrence;
        earningOverheadTransaction.amount          = json.amount;
        earningOverheadTransaction.mustBeChanged   = json.mustBeChanged;

        return earningOverheadTransaction;
    }

    fromObjectToJson(object: EarningOverheadTransaction) {
        return {
            id:              object.id,
            earningOverhead: (object.earningOverhead || undefined),
            day:             (object.day || 1),
            month:           (object.month || ""),
            recurrence:      (object.recurrence || ""),
            amount:          (object.amount || 0),
            mustBeChanged:   (object.mustBeChanged || false)
        }
    }
}

export default EarningOverheadTransactionModelMapper;