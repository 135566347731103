import GenericApi from "../../../commons/api/GenericApi";
import IGenericApi from "../../../commons/api/IGenericApi";
import InsuranceListPaymentIntervalModelMapper from "../model/InsuranceListPaymentIntervalModelMapper";

class InsuranceListPaymentIntervalApi implements IGenericApi {
    private static instance: InsuranceListPaymentIntervalApi;

    private static apiUrlPath = "insuranceList/paymentInterval";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): InsuranceListPaymentIntervalApi {
        if (!InsuranceListPaymentIntervalApi.instance) {
            InsuranceListPaymentIntervalApi.instance = new InsuranceListPaymentIntervalApi();
        }

        return InsuranceListPaymentIntervalApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    InsuranceListPaymentIntervalApi.apiUrlPath,
                    new InsuranceListPaymentIntervalModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    InsuranceListPaymentIntervalApi.apiUrlPath,
                    id,
                    new InsuranceListPaymentIntervalModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    InsuranceListPaymentIntervalApi.apiUrlPath,
                    body,
                    new InsuranceListPaymentIntervalModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    InsuranceListPaymentIntervalApi.apiUrlPath,
                    id,
                    body,
                    new InsuranceListPaymentIntervalModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    InsuranceListPaymentIntervalApi.apiUrlPath,
                    id,
                    new InsuranceListPaymentIntervalModelMapper());
    }

    public getUrl() {
        return InsuranceListPaymentIntervalApi.apiUrlPath;
    }
}

export default InsuranceListPaymentIntervalApi;