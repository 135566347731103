import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import BankingListGroupModelMapper from '../model/BankingListGroupModelMapper';

class BankingListGroupApi implements IGenericApi {
    private static instance: BankingListGroupApi;

    private static apiUrlPath = "bankingList/group";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): BankingListGroupApi {
        if (!BankingListGroupApi.instance) {
            BankingListGroupApi.instance = new BankingListGroupApi();
        }

        return BankingListGroupApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    BankingListGroupApi.apiUrlPath,
                    new BankingListGroupModelMapper());
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    BankingListGroupApi.apiUrlPath,
                    id,
                    new BankingListGroupModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    BankingListGroupApi.apiUrlPath,
                    body,
                    new BankingListGroupModelMapper());
    }

    public update(id ,body) {
        return GenericApi.executePutCall(
                    BankingListGroupApi.apiUrlPath,
                    id,
                    body,
                    new BankingListGroupModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    BankingListGroupApi.apiUrlPath,
                    id,
                    new BankingListGroupModelMapper());
    }

    public getUrl() {
        return BankingListGroupApi.apiUrlPath;
    }
}

export default BankingListGroupApi;