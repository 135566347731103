import LetterSenderCompany from "../../sender/company/model/LetterSenderCompany";
import LetterSenderPerson from "../../sender/person/model/LetterSenderPerson";

class LetterSignature {
    id!: number;
    content!: string;
    contentType!: string;
    width!: number;
    height!: number;
    senderPerson!: LetterSenderPerson;
    senderCompany!: LetterSenderCompany;

    public constructor() {
        this.senderPerson = new LetterSenderPerson();
        this.senderCompany = new LetterSenderCompany();
    }
}

export default LetterSignature;