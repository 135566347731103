import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import CyclingTourModelMapper from '../model/CyclingTourModelMapper';

class CyclingTourApi implements IGenericApi {
    private static instance: CyclingTourApi;

    private static apiUrlPath = "cyclingTour";

    private static endpointByBikeId = "/byBike";
    private static endpointSumByBikeId = "/sumDistance";
    private static endpointSumByYearAndBikeId = "/sumDistanceByYear";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): CyclingTourApi {
        if (!CyclingTourApi.instance) {
            CyclingTourApi.instance = new CyclingTourApi();
        }

        return CyclingTourApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    CyclingTourApi.apiUrlPath,
                    new CyclingTourModelMapper());
    }

    public getAllByBikeId(bikeId) {
        return GenericApi.executeGetAllCall(
                    CyclingTourApi.apiUrlPath + 
                    CyclingTourApi.endpointByBikeId + "/" + bikeId,
                    new CyclingTourModelMapper());
    }

    public getSumByBikeId(bikeId) {
        let filterAsString = "bikeId" + "=" + bikeId;

        return GenericApi.executeGetCallWithFilter(
                    CyclingTourApi.apiUrlPath +
                    CyclingTourApi.endpointSumByBikeId,
                    filterAsString,
                    null    // No mapper needed, because the result is just a double-value.
        );
    }

    public getSumByYearAndBikeId(year, bikeId) {
        let filterAsString = "year" + "=" + year.toString() + "&" +
                             "bikeId" + "=" + bikeId;

        return GenericApi.executeGetCallWithFilter(
                    CyclingTourApi.apiUrlPath +
                    CyclingTourApi.endpointSumByYearAndBikeId,
                    filterAsString,
                    null    // No mapper needed, because the result is just a double-value.
        );
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    CyclingTourApi.apiUrlPath,
                    id,
                    new CyclingTourModelMapper());
    }

    public create(body) {
        return GenericApi.executePostCall(
                    CyclingTourApi.apiUrlPath,
                    body,
                    new CyclingTourModelMapper());
    }

    public update(id, body) {
        return GenericApi.executePutCall(
                    CyclingTourApi.apiUrlPath,
                    id,
                    body,
                    new CyclingTourModelMapper());
    }

    public delete(id) {
        return GenericApi.executeDeleteCall(
                    CyclingTourApi.apiUrlPath,
                    id,
                    new CyclingTourModelMapper());
    }

    public getUrl() {
        return CyclingTourApi.apiUrlPath;
    }
}

export default CyclingTourApi;