import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import EarningsExpensesOverheadApiCallExecutor from '../../api/EarningsExpensesOverheadApiCallExecutor';

import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';

import Navigation from '../../../../commons/ui/fragment/Navigation';

import EarningOverheadOverviewGrouped from '../../../earning/item/ui/page/OverviewGrouped';
import ExpenseOverheadOverviewGrouped from '../../../expense/item/ui/page/OverviewGrouped';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:   boolean;
    sum:         number;
    savingRatio: number;
}

class OverviewGrouped extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;

    private showNavbar: boolean;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.showNavbar = true;

        this.state = {
            isLoading:  true,
            sum:        0.0,
            savingRatio: 0.0
        };
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (this.state.isLoading) {
            return LoadingScreen.render();
        } else {
            return (
                <div>
                    { this.getNavbar() }

                    <p>{this.state.sum} ({this.state.savingRatio} %)</p>

                    <EarningOverheadOverviewGrouped history={this.props.history} location={this.props.location} {...this.state} />
                    <ExpenseOverheadOverviewGrouped history={this.props.history} location={this.props.location} {...this.state} />
                </div>
            );
        }
    }

    private getNavbar() {
        if (this.showNavbar) {
            return ( <Navigation /> );
        } else {
            return ( <div></div> );
        }
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: OverviewGrouped;

    private helper: ApiCallExecutorHelper;

    private earningsExpensesOverheadApiCallExecutor: EarningsExpensesOverheadApiCallExecutor<OverviewGrouped>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.earningsExpensesOverheadApiCallExecutor = new EarningsExpensesOverheadApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addEarningsExpensesOverheadGetSum());
        orderOfCalls.push(this.addEarningsExpensesOverheadGetSavingRatio());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addEarningsExpensesOverheadGetSum() : IApiCallback {
        return {
            callback: this.earningsExpensesOverheadApiCallExecutor.getSum,
            pointerToApiCall: this.earningsExpensesOverheadApiCallExecutor,
            variableNameInState: "sum"
        };
    }

    private addEarningsExpensesOverheadGetSavingRatio() : IApiCallback {
        return {
            callback: this.earningsExpensesOverheadApiCallExecutor.getSavingRatio,
            pointerToApiCall: this.earningsExpensesOverheadApiCallExecutor,
            variableNameInState: "savingRatio"
        };
    }
}

export default OverviewGrouped;