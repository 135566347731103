import React from 'react';
import FormGroupGroup from '../form/group/FormGroupGroup';

class GroupForm {
    private formGroupGroup: FormGroupGroup;

    public constructor(props, pointerToComponent) {
        this.formGroupGroup = new FormGroupGroup(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return (
            <div>
                { this.formGroupGroup.render() }

                { renderButtonCallback() }
            </div>
        );
    }

    public isFormValid(): boolean {
        if (this.formGroupGroup.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default GroupForm;