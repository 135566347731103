import React from 'react';
import { Component } from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import download from 'downloadjs';
import Utils from '../../../../utilities/Utils';
import I18nConstants from '../../i18n/I18nConstants';
import Letter from '../../model/Letter';
import OverviewUtils from '../../../../commons/ui/page/template/overview/OverviewUtils';
import LetterUrlPath from '../LetterUrlPath';
import AuthenticationApi from '../../../../security/api/AuthenticationApi';
import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';
import LoginUrlPath from '../../../../login/ui/LoginUrlPath';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import LetterApi from '../../api/LetterApi';
import LetterApiCallExecutor from '../../api/LetterApiCallExecutor';
import LetterSenderPersonActionBarButton from '../../../sender/person/ui/fragment/ActionBarButton';
import LetterSenderCompanyActionBarButton from '../../../sender/company/ui/fragment/ActionBarButton';
import LetterAddresseePersonActionBarButton from '../../../addressee/person/ui/fragment/ActionBarButton';
import LetterAddresseeCompanyActionBarButton from '../../../addressee/company/ui/fragment/ActionBarButton';
import LetterSignatureActionBarButton from '../../../signature/ui/fragment/ActionBarButton';
import LetterTemplateActionBarButton from '../../../template/ui/fragment/ActionBarButton';
import LetterPersonSalutationActionBarButton from '../../../person/salutation/ui/fragment/ActionBarButton';
import LetterPersonTitleActionBarButton from '../../../person/title/ui/fragment/ActionBarButton';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    List: Letter[];
}

class Overview extends Component<IProps, IState> {
    private api: LetterApi;
    private apiCalls: ApiCallExecutor;

    private overviewUtils: OverviewUtils;

    private senderPersonActionBarButton: LetterSenderPersonActionBarButton;
    private senderCompanyActionBarButton: LetterSenderCompanyActionBarButton;
    private addresseePersonActionBarButton: LetterAddresseePersonActionBarButton;
    private addresseeCompanyActionBarButton: LetterAddresseeCompanyActionBarButton;
    private signatureActionBarButton: LetterSignatureActionBarButton;
    private templateActionBarButton: LetterTemplateActionBarButton;
    private personSalutationActionBarButton: LetterPersonSalutationActionBarButton;
    private personTitleActionBarButton: LetterPersonTitleActionBarButton;

    public constructor(props) {
        super(props);

        this.api = LetterApi.getInstance();

        this.apiCalls = new ApiCallExecutor(this);

        this.senderPersonActionBarButton = new LetterSenderPersonActionBarButton(props.history);
        this.senderCompanyActionBarButton = new LetterSenderCompanyActionBarButton(props.history);
        this.addresseePersonActionBarButton = new LetterAddresseePersonActionBarButton(props.history);
        this.addresseeCompanyActionBarButton = new LetterAddresseeCompanyActionBarButton(props.history);
        this.signatureActionBarButton = new LetterSignatureActionBarButton(props.history);
        this.templateActionBarButton = new LetterTemplateActionBarButton(props.history);
        this.personSalutationActionBarButton = new LetterPersonSalutationActionBarButton(props.history);
        this.personTitleActionBarButton = new LetterPersonTitleActionBarButton(props.history);

        this.state = {
            isLoading: true,
            List: []
        };

        this.overviewUtils = new OverviewUtils(
            this,
            this.getHeading(),
            LetterUrlPath.CREATE,
            LetterUrlPath.UPDATE,
            undefined,
            true,
            this.getActionBar(),
            LetterUrlPath.DETAILS
        );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return (
                <div>
                    { this.overviewUtils.render(
                        this.getTableHead(),
                        this.getTableBody()) }
                </div> );
        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS}
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1> );
    }

    private getTableHead() {
        return (
            <tr>
                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_SENDER_PERSON}>
                        {I18nConstants.FORM_SENDER_PERSON_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_SENDER_COMPANY}>
                        {I18nConstants.FORM_SENDER_COMPANY_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_ADDRESSEE_PERSON}>
                        {I18nConstants.FORM_ADDRESSEE_PERSON_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_ADDRESSEE_COMPANY}>
                        {I18nConstants.FORM_ADDRESSEE_COMPANY_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_TEMPLATE}>
                        {I18nConstants.FORM_TEMPLATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_DATE}>
                        {I18nConstants.FORM_DATE_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_SUBJECT}>
                        {I18nConstants.FORM_SUBJECT_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_TEXT}>
                        {I18nConstants.FORM_TEXT_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_ENCLOSURES}>
                        {I18nConstants.FORM_ENCLOSURES_DEFAULT}
                    </Trans>
                </th>

                <th>
                    <Trans ns={I18nConstants.NS}
                           i18nKey={I18nConstants.FORM_USE_SIGNATURE}>
                        {I18nConstants.FORM_USE_SIGNATURE_DEFAULT}
                    </Trans>
                </th>

                { this.overviewUtils.getShowDetailsWrappedInThTag() }
                { this.overviewUtils.getSaveAsPDFWrappedInThTag() }
                { this.overviewUtils.getUpdateHeadingWrappedInThTag() }
                { this.overviewUtils.getDeleteHeadingWrappedInThTag() }
            </tr>
        );
    }

    private getTableBody() {
        return this.state.List.map( item => 
            <tr key={(item as any).id}>
                <td>
                    {((item as any).senderPerson == undefined)
                     ? ""
                     : ((item as any).senderPerson.firstName + " " +
                        (item as any).senderPerson.lastName) }
                </td>

                <td>
                    {((item as any).senderCompany == undefined)
                      ? ""
                      : (item as any).senderCompany.name}
                </td>

                <td>
                    {((item as any).addresseePerson == undefined)
                     ? ""
                     : ((item as any).addresseePerson.firstName + " " +
                        (item as any).addresseePerson.lastName) }
                </td>

                <td>
                    {((item as any).addresseeCompany == undefined)
                      ? ""
                      : (item as any).addresseeCompany.name}
                </td>

                <td>
                    {((item as any).template == undefined)
                      ? ""
                      : (item as any).template.description}
                </td>

                <td>
                    {(item as any).date}
                </td>

                <td>
                    {(item as any).subject}
                </td>

                <td>
                    {(item as any).text.toString().substring(0, 100)}
                </td>

                <td>
                    {(item as any).enclosure.toString().substring(0, 50)}
                </td>

                <td id="checkbox">
                    <FormGroup check>
                        <Label check>
                            <Input 
                            type="checkbox"
                            checked={(item as any).useSignature}
                            disabled="true" />
                        </Label>
                    </FormGroup>
                </td>

                { this.overviewUtils.getShowDetailsButtonWrappedInTdTag((item as any).id) }
                { this.overviewUtils.getSaveAsPDFButtonWrappedInTdTag(this, 
                                        this.saveAsPDFButtonClicked, (item as any).id) }
                { this.overviewUtils.getUpdateButtonWrappedInTdTag((item as any).id) }
                { this.overviewUtils.getDeleteButtonWrappedInTdTag((item as any).id) }
            </tr>
        );
    }

    private getActionBar() {
        return (
            <div>
                { this.senderPersonActionBarButton.render() }
                { this.senderCompanyActionBarButton.render() }
                { this.addresseePersonActionBarButton.render() }
                { this.addresseeCompanyActionBarButton.render() }
                { this.signatureActionBarButton.render() }
                { this.templateActionBarButton.render() }
                { this.personSalutationActionBarButton.render() }
                { this.personTitleActionBarButton.render() }
            </div>
        );
    }

    private saveAsPDFButtonClicked(pointerToComponent, id) {
        pointerToComponent.api.createPDF(id)
        .then( response => {
                    const responseAsBlob = new Blob([response], { type: "application/pdf" });
                    const fileName = Utils.convertDateToIsoString(new Date());

                    download(responseAsBlob, fileName, "application/pdf");
               })
        .catch( error    => {
                    console.log("TODO");
               });
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Overview;

    private helper: ApiCallExecutorHelper;

    private letterApiCallExecutor: LetterApiCallExecutor<Overview>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.letterApiCallExecutor = new LetterApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addLetterGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addLetterGetAll() : IApiCallback {
        return {
            callback: this.letterApiCallExecutor.getAll,
            pointerToApiCall: this.letterApiCallExecutor,
            variableNameInState: "List"
        }
    }
}

export default Overview;