import I18nConstants from '../../../../../i18n/I18nConstantsBase';
import FormButtonBase from './FormButtonBase';

class FormButtonUpdate extends FormButtonBase {
    public static render() {
        return FormButtonBase.renderByI18nKeyAndDefaultValue(
            I18nConstants.ACTION_UPDATE,
            I18nConstants.ACTION_UPDATE_DEFAULT
        );
    }
}

export default FormButtonUpdate;