import I18nConstantsBase from '../../../commons/i18n/I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "fortuneInvestmentPlan";

    public static TITLE = "title";
    public static TITLE_SHORT = "titleShort";

    public static FORM_PRIORITY = "form.priority";
    public static FORM_DESCRIPTION = "form.description";
    public static FORM_ISSUE_FEE = "form.issueFee";
    public static FORM_TER = "form.ter";
    public static FORM_FONDS_AMOUNT_IN_MIO = "form.fondsAmountInMio";
    public static FORM_DISTRIBUTION_TYPE = "form.distributionType";
    public static FORM_REPLICATION_METHOD = "form.replicationMethod";
    public static FORM_BANK_NAME = "form.bankName";
    public static FORM_VENDOR = "form.vendor";
    public static FORM_COUNTRY_LOCATION = "form.countryLocation";
    public static FORM_ISIN = "form.isin";
    public static FORM_FIRST_ISSUE_DATE = "form.firstIssueDate";
    public static FORM_LINK = "form.link";

    // --------------------------------------------------------------------------------------------

    public static TITLE_DEFAULT = DefaultJson.title;
    public static TITLE_SHORT_DEFAULT = DefaultJson.titleShort;

    public static FORM_PRIORITY_DEFAULT = DefaultJson.form.priority;
    public static FORM_DESCRIPTION_DEFAULT = DefaultJson.form.description;
    public static FORM_ISSUE_FEE_DEFAULT = DefaultJson.form.issueFee;
    public static FORM_TER_DEFAULT = DefaultJson.form.ter;
    public static FORM_FONDS_AMOUNT_IN_MIO_DEFAULT = DefaultJson.form.fondsAmountInMio;
    public static FORM_DISTRIBUTION_TYPE_DEFAULT = DefaultJson.form.distributionType;
    public static FORM_REPLICATION_METHOD_DEFAULT = DefaultJson.form.replicationMethod;
    public static FORM_BANK_NAME_DEFAULT = DefaultJson.form.bankName;
    public static FORM_VENDOR_DEFAULT = DefaultJson.form.vendor;
    public static FORM_COUNTRY_LOCATION_DEFAULT = DefaultJson.form.countryLocation;
    public static FORM_ISIN_DEFAULT = DefaultJson.form.isin;
    public static FORM_FIRST_ISSUE_DATE_DEFAULT = DefaultJson.form.firstIssueDate;
    public static FORM_LINK_DEFAULT = DefaultJson.form.link;
}

export default I18nConstants;