import ExpenseOverhead from '../../model/ExpenseOverhead';
import ExpenseOverheadApi from '../../api/ExpenseOverheadApi';
import ExpenseGroup from '../../../../../earningsExpenses/expense/group/model/ExpenseGroup';
import ExpenseGroupApi from '../../../../../earningsExpenses/expense/group/api/ExpenseGroupApi';
import EarningsExpensesOverheadUrlPath from '../../../../item/ui/EarningsExpensesOverheadUrlPath';
import CreateBaseWithGroup from '../../../../../commons/ui/page/template/create/CreateBaseWithGroup';
import Form from './Form';
import FormButtonCreate from '../../../../../commons/ui/page/template/form/button/FormButtonCreate';

class Create extends CreateBaseWithGroup<ExpenseOverhead, ExpenseGroup, 
                                         ExpenseOverheadApi, ExpenseGroupApi> {
    private form: Form;

    public constructor(props) {
        super(props, 
              new ExpenseOverhead(),
              ExpenseOverheadApi.getInstance(),
              ExpenseGroupApi.getInstance(),
              EarningsExpensesOverheadUrlPath.BASE);
              
        this.form = new Form(props, this);
    }

    protected getFormGroupContent() {
        return this.form.getFormGroupContent(FormButtonCreate.render);
    }

    protected isFormValid(): boolean {
        return this.form.isFormValid();
    }
}

export default Create;