import IModelMapper from '../../../commons/model/IModelMapper';

import ToDoListGroup from './ToDoListGroup';

class ToDoListGroupModelMapper implements IModelMapper<ToDoListGroup> {
    fromJsonToObject(json: any): ToDoListGroup {
        let toDoListGroup = new ToDoListGroup();

        toDoListGroup.id   = json.id;
        toDoListGroup.name = json.name;

        return toDoListGroup;
    }

    fromObjectToJson(object: ToDoListGroup) {
        return {
            id:   object.id,
            name: (object.name || "") };
    }
}

export default ToDoListGroupModelMapper;