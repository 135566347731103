import React from 'react';
import { Component } from 'react';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import CyclingTourApiCallExecutor from '../../api/CyclingTourApiCallExecutor';
import BikeApiCallExecutor from '../../../bike/item/api/BikeApiCallExecutor';
import Utils from '../../../../utilities/Utils';
import CyclingTourApi from '../../api/CyclingTourApi';
import CyclingTour from '../../model/CyclingTour';
import CyclingTourUrlPath from '../CyclingTourUrlPath';
import UpdateUtils from '../../../../commons/ui/page/template/update/UpdateUtils';
import Form from './Form';

interface IProps {
    history: any;
    location: any;
}

interface IState {
    isLoading: boolean;
    wasHandleSubmitClickedAtLeastOnce: boolean;
    Item: CyclingTour;
    Bikes: [];
}

class Update extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
    private api: CyclingTourApi;
    private form: Form;
    private updateUtils: UpdateUtils;

    private idOfItemToBeUpdated: number;

    public constructor(props) {
        super(props);

        this.idOfItemToBeUpdated =
            Utils.getIdFromGetParameters(props.location.search);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = CyclingTourApi.getInstance();

        this.state = {
            isLoading: true,
            wasHandleSubmitClickedAtLeastOnce: false,
            Item: new CyclingTour(),
            Bikes: []
        };

        this.form = new Form(props, this);

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            CyclingTourUrlPath.BASE
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return (
            <h1>

            </h1>
        );
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.updateUtils.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------

    public getIdToBeUpdated() {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private cyclingTourApiCallExector: CyclingTourApiCallExecutor<Update>;
    private bikeApiCallExecutor: BikeApiCallExecutor<Update>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.cyclingTourApiCallExector = new CyclingTourApiCallExecutor(pointerToComponent);
        this.bikeApiCallExecutor = new BikeApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addCyclingTourGetById());
        orderOfCalls.push(this.addBikeGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "cyclingTourId", 
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addCyclingTourGetById() : IApiCallback {
        return {
            callback: this.cyclingTourApiCallExector.getById,
            pointerToApiCall: this.cyclingTourApiCallExector,
            variableNameInState: "Item"
        };
    }

    private addBikeGetAll() : IApiCallback {
        return {
            callback: this.bikeApiCallExecutor.getAll,
            pointerToApiCall: this.bikeApiCallExecutor,
            variableNameInState: "Bikes"
        };
    }
}

export default Update;