import IModelMapper from "../../../commons/model/IModelMapper";
import PasswordList from "./PasswordList";

class PasswordListModelMapper implements IModelMapper<PasswordList> {
    fromJsonToObject(json: any): PasswordList {
        let passwordList = new PasswordList();

        passwordList.id = json.id;
        passwordList.folder = json.folder;
        passwordList.description = json.description;
        passwordList.username = json.username;
        passwordList.password = json.password;
        passwordList.link = json.link;
        passwordList.notes = json.notes;

        return passwordList;
    }

    fromObjectToJson(object: PasswordList) {
        return {
            id: object.id,
            folder: (object.folder || undefined),
            description: (object.description || ""),
            username: (object.username || ""),
            password: (object.password || ""),
            link: (object.link || ""),
            notes: (object.notes || "")
        };
    }
}

export default PasswordListModelMapper;