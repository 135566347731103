import OverviewBase from '../../../../commons/ui/page/template/group/overview/OverviewBase';
import ShoppingListGroup from '../../model/ShoppingListGroup';
import ShoppingListGroupApi from '../../api/ShoppingListGroupApi';

class Overview extends OverviewBase<ShoppingListGroup, ShoppingListGroupApi> {
    public constructor(props) {
        super(props,
              ShoppingListGroupApi.getInstance());
    }
}

export default Overview;