import React from 'react';
import { Component } from 'react';
import { Label, Input, FormGroup } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import I18nConstants from '../../i18n/I18nConstants';
import Letter from "../../model/Letter";
import ApiCallExecutorHelper from '../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import LetterApiCallExecutor from '../../api/LetterApiCallExecutor';
import IApiCallback from '../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import Utils from '../../../../utilities/Utils';
import AuthenticationApi from '../../../../security/api/AuthenticationApi';
import LoadingScreen from '../../../../commons/ui/page/loadingScreen/LoadingScreen';
import LoginUrlPath from '../../../../login/ui/LoginUrlPath';
import NavigationRenderer from '../../../../commons/ui/fragment/NavigationRenderer';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:   boolean;
    Letter:      Letter;
}

class Details extends Component<IProps, IState> {
    private apiCalls: ApiCallExecutor;
 
    private idToBeDisplayed: number;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.idToBeDisplayed =
            Utils.getIdFromGetParameters(props.location.search);

        this.state = {
            isLoading: true,
            Letter: new Letter()
        }
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    public render() {
        if (AuthenticationApi.isLoggedIn()) {
            if (    (!this.idToBeDisplayed)
                 || (this.idToBeDisplayed <= 0)) {
                // TODO: i18n
                return (<div>No ID specified!</div>);
            }

            if (this.state.isLoading) {
                return LoadingScreen.render();
            }

            return this.renderContent();

        } else {
            return <Redirect to={LoginUrlPath.BASE} />;
        }
    }

    private renderContent() {
        return (
            <div id="letterContent">
                { NavigationRenderer.renderIfNecessary(true) }

                { this.getHeading() }
                <p>&nbsp;</p>
                { this.getDate() }
                <p>&nbsp;</p>
                { this.getSenderPersonIfNecessary() }
                { this.getSenderCompanyIfNecessary() }
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                { this.getAddresseePersonIfNecessary() }
                { this.getAddresseeCompanyIfNecessary() }
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                { this.getSubject() }
                <p>&nbsp;</p>
                { this.getText() }
                <p>&nbsp;</p>
                { this.getEnclosuresIfNecessary() }
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                { this.getUseSignature() }
                { this.getTemplate() }
            </div>
        );
    }

    private getHeading() {
        return (
            <h1>
                <Trans ns={I18nConstants.NS} 
                       i18nKey={I18nConstants.TITLE_SHORT}>
                    {I18nConstants.TITLE_SHORT_DEFAULT}
                </Trans>
            </h1>
        );
    }

    private getDate() {
        return (
            <div>
                <p>{this.state.Letter.date}</p>
            </div>
        );
    }

    // --- Sender - Person ----------------------------------------------------

    private getSenderPersonIfNecessary() {
        if (this.state.Letter.senderPerson) {
            return (
                <div>
                    <p>
                        {this.state.Letter.senderPerson.salutation.name}&nbsp;
                        {this.state.Letter.senderPerson.title == null 
                         ? '' : this.state.Letter.senderPerson.title.name}&nbsp;
                        {this.state.Letter.senderPerson.firstName}&nbsp;
                        {this.state.Letter.senderPerson.lastName}
                    </p>
                    
                    <p>
                        {this.state.Letter.senderPerson.street}&nbsp;
                        {this.state.Letter.senderPerson.houseNumber}
                    </p>
                    
                    <p>
                        {this.state.Letter.senderPerson.zip}&nbsp;
                        {this.state.Letter.senderPerson.city}
                    </p>

                    {this.getSenderPersonPostOfficeBox()}
                    {this.getSenderPersonCountry()}
                    {this.getSenderPersonPhone()}
                    {this.getSenderPersonMail()}
                    {this.getSenderPersonUrl()}
                </div>
            );
        }
    }

    private getSenderPersonPostOfficeBox() {
        const postOfficeBox = this.state.Letter.senderPerson.postOfficeBox;
        const city = this.state.Letter.senderPerson.city;

        if (postOfficeBox)
        {
            return (
                <p>
                    {postOfficeBox}&nbsp;
                    {city}
                </p>
            );
        }
    }

    private getSenderPersonCountry() {
        const country = this.state.Letter.senderPerson.country.isoCode;

        if (country != "DE") {
            return (
                <p>
                    {country}
                </p>
            );
        }
    }

    private getSenderPersonPhone() {
        const phone = this.state.Letter.senderPerson.phone;

        if (phone) {
            return (
                <p>
                    {phone}
                </p>
            );
        }
    }

    private getSenderPersonMail() {
        const mail = this.state.Letter.senderPerson.mail;

        if (mail) {
            return (
                <p>
                    {mail}
                </p>
            );
        }
    }

    private getSenderPersonUrl() {
        const url = this.state.Letter.senderPerson.url;

        if (url) {
            return (
                <p>
                    {url}
                </p>
            );
        }
    }

    // --- Sender - Company ---------------------------------------------------

    private getSenderCompanyIfNecessary() {
        if (this.state.Letter.senderCompany) {
            return (
                <div>
                    <p>{this.state.Letter.senderCompany.name}</p>

                    {this.getSenderCompanyDepartment()}
                    {this.getSenderCompanyContactPerson()}
                    {this.getSenderCompanyStreetAndHouseNumber()}
                    {this.getSenderCompanyZipAndCity()}
                    {this.getSenderCompanyPostOfficeBox()}
                    {this.getSenderCompanyCountry()}
                    {this.getSenderCompanyPhone()}
                    {this.getSenderCompanyMail()}
                    {this.getSenderCompanyUrl()}
                </div>
            );
        }
    }

    private getSenderCompanyDepartment() {
        const department = this.state.Letter.senderCompany.department;

        if (department) {
            return (
                <p>
                    {department}
                </p>
            );
        }
    }

    private getSenderCompanyContactPerson() {
        const salutation = 
            this.state.Letter.senderCompany.contactSalutation == null ?
            '' : this.state.Letter.senderCompany.contactSalutation.name;
        const title = 
            this.state.Letter.senderCompany.contactTitle == null ?
            '' : this.state.Letter.senderCompany.contactTitle.name;
        const firstName = this.state.Letter.senderCompany.contactFirstName;
        const lastName = this.state.Letter.senderCompany.contactLastName;

        if (firstName && lastName) {
            return (
                <p>
                    {salutation}&nbsp;
                    {title}&nbsp;
                    {firstName}&nbsp;
                    {lastName}
                </p>
            );
        }
    }

    private getSenderCompanyStreetAndHouseNumber() {
        const street = this.state.Letter.senderCompany.street;
        const houseNumber = this.state.Letter.senderCompany.houseNumber;

        if (street && houseNumber) {
            return (
                <p>
                    {street}&nbsp;
                    {houseNumber}
                </p>
            );
        }
    }

    private getSenderCompanyZipAndCity() {
        const zip = this.state.Letter.senderCompany.zip;
        const city = this.state.Letter.senderCompany.city;

        if (zip && city) {
            return (
                <p>
                    {zip}&nbsp;
                    {city}
                </p>
            );
        }
    }

    private getSenderCompanyPostOfficeBox() {
        const postOfficeBox = this.state.Letter.senderCompany.postOfficeBox;
        const city = this.state.Letter.senderCompany.city;

        if (postOfficeBox) {
            return (
                <p>
                    {postOfficeBox}&nbsp;
                    {city}
                </p>
            );
        }
    }

    private getSenderCompanyCountry() {
        const country = this.state.Letter.senderCompany.country.isoCode;

        if (country != "DE") {
            return (
                <p>
                    {country}
                </p>
            );
        }
    }

    private getSenderCompanyPhone() {
        const phone = this.state.Letter.senderCompany.phone;

        if (phone) {
            return (
                <p>
                    {phone}
                </p>
            );
        }
    }

    private getSenderCompanyMail() {
        const mail = this.state.Letter.senderCompany.mail;

        if (mail) {
            return (
                <p>
                    {mail}
                </p>
            );
        }
    }

    private getSenderCompanyUrl() {
        const url = this.state.Letter.senderCompany.url;

        if (url) {
            return (
                <p>
                    {url}
                </p>
            );
        }
    }

    // --- Addresse - Person --------------------------------------------------

    private getAddresseePersonIfNecessary() {
        if (this.state.Letter.addresseePerson) {
            return (
                <div>
                    <p>
                        {this.state.Letter.addresseePerson.salutation.name}&nbsp;
                        {this.state.Letter.addresseePerson.title == null 
                         ? '' : this.state.Letter.addresseePerson.title.name}&nbsp;
                        {this.state.Letter.addresseePerson.firstName}&nbsp;
                        {this.state.Letter.addresseePerson.lastName}
                    </p>
                    
                    <p>
                        {this.state.Letter.addresseePerson.street}&nbsp;
                        {this.state.Letter.addresseePerson.houseNumber}
                    </p>
                    
                    <p>
                        {this.state.Letter.addresseePerson.zip}&nbsp;
                        {this.state.Letter.addresseePerson.city}
                    </p>

                    {this.getAddresseePersonPostOfficeBox()}
                    {this.getAddresseePersonCountry()}
                </div>
            );
        }
    }

    private getAddresseePersonPostOfficeBox() {
        const postOfficeBox = this.state.Letter.addresseePerson.postOfficeBox;
        const city = this.state.Letter.addresseePerson.city;

        if (postOfficeBox)
        {
            return (
                <p>
                    {postOfficeBox}&nbsp;
                    {city}
                </p>
            );
        }
    }

    private getAddresseePersonCountry() {
        const country = this.state.Letter.addresseePerson.country.isoCode;

        if (country != "DE") {
            return (
                <p>
                    {country}
                </p>
            );
        }
    }

    // --- Addresse - Company -------------------------------------------------

    private getAddresseeCompanyIfNecessary() {
        if (this.state.Letter.addresseeCompany) {
            return (
                <div>
                    <p>{this.state.Letter.addresseeCompany.name}</p>

                    {this.getAddresseeCompanyDepartment()}
                    {this.getAddresseeCompanyContactPerson()}
                    {this.getAddresseeCompanyStreetAndHouseNumber()}
                    {this.getAddresseeCompanyZipAndCity()}
                    {this.getAddresseeCompanyPostOfficeBox()}
                    { /* TODO: country, phone, mail, url */ }
                </div>
            );
        }
    }

    private getAddresseeCompanyDepartment() {
        const department = this.state.Letter.addresseeCompany.department;

        if (department) {
            return (
                <p>
                    {department}
                </p>
            );
        }
    }

    private getAddresseeCompanyContactPerson() {
        const salutation =
            this.state.Letter.addresseeCompany.contactSalutation == null ? 
            '' : this.state.Letter.addresseeCompany.contactSalutation.name;
        const title = 
            this.state.Letter.addresseeCompany.contactTitle == null ?
            '' : this.state.Letter.addresseeCompany.contactTitle.name;
        const firstName = this.state.Letter.addresseeCompany.contactFirstName;
        const lastName = this.state.Letter.addresseeCompany.contactLastName;

        if (firstName && lastName) {
            return (
                <p>
                    {salutation}&nbsp;
                    {title}&nbsp;
                    {firstName}&nbsp;
                    {lastName}
                </p>
            );
        }
    }

    private getAddresseeCompanyStreetAndHouseNumber() {
        const street = this.state.Letter.addresseeCompany.street;
        const houseNumber = this.state.Letter.addresseeCompany.houseNumber;

        if (street && houseNumber) {
            return (
                <p>
                    {street}&nbsp;
                    {houseNumber}
                </p>
            );
        }
    }

    private getAddresseeCompanyZipAndCity() {
        const zip = this.state.Letter.addresseeCompany.zip;
        const city = this.state.Letter.addresseeCompany.city;

        if (zip && city) {
            return (
                <p>
                    {zip}&nbsp;
                    {city}
                </p>
            );
        }
    }

    private getAddresseeCompanyPostOfficeBox() {
        const postOfficeBox = this.state.Letter.addresseeCompany.postOfficeBox;
        const city = this.state.Letter.addresseeCompany.city;

        if (postOfficeBox) {
            return (
                <p>
                    {postOfficeBox}&nbsp;
                    {city}
                </p>
            );
        }
    }

    // --- Others -------------------------------------------------------------

    private getSubject() {
        return (
            <div>
                <p><b>{this.state.Letter.subject}</b></p>
            </div>
        );
    }

    private getText() {
        let letterTextSplittedByLineBreak = 
                Utils.convertTextWithLineBreaksToArray(this.state.Letter.text);
        
        let letter = letterTextSplittedByLineBreak.map(this.textCallback);

        return (
            <div>
                {letter}
            </div>
        );
    }

    private textCallback(line) {
        if (line === "") {
            return (<p>&nbsp;</p>);
        } else {
            return (<p>{line}</p>)
        }
    }

    private getEnclosuresIfNecessary() {
        if (this.state.Letter.enclosure) {
            let enclosureSplittedByLineBreak = 
                    Utils.convertTextWithLineBreaksToArray(this.state.Letter.enclosure);

            let enclosures = enclosureSplittedByLineBreak.map( enclosure => 
                <p>
                    {enclosure}
                </p>
            );

            return (
                <div>
                    <p>
                        <Trans ns={I18nConstants.NS}
                            i18nKey={I18nConstants.FORM_ENCLOSURES}>
                            {I18nConstants.FORM_ENCLOSURES_DEFAULT}
                        </Trans>
                    </p>

                    {enclosures}
                </div>
            );
        }
    }

    private getUseSignature() {
        return (
            <div>
                <FormGroup check>
                    <Label check>
                        <Input 
                            type="checkbox"
                            checked={this.state.Letter.useSignature}
                            disabled="true" />{ ' ' }
                        <Trans ns={I18nConstants.NS}
                            i18nKey={I18nConstants.FORM_USE_SIGNATURE}>
                            {I18nConstants.FORM_USE_SIGNATURE_DEFAULT}
                        </Trans>
                    </Label>
                </FormGroup>
            </div>
        );
    }

    private getTemplate() {
        return (
            <div>
                <p>
                    <Trans ns={I18nConstants.NS}
                                i18nKey={I18nConstants.FORM_TEMPLATE}>
                                {I18nConstants.FORM_TEMPLATE_DEFAULT}
                    </Trans>:&nbsp;
                    {this.state.Letter.template.description}
                </p>
            </div>
        );
    }

    // --------------------------------------------------------------------------------------------
    
    public getIdToBeDisplayed() : number {
        return this.idToBeDisplayed;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Details;

    private helper: ApiCallExecutorHelper;

    private letterApiCallExecutor: LetterApiCallExecutor<Details>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.letterApiCallExecutor = new LetterApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addLetterById());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "letterId", 
                                          this.pointerToComponent.getIdToBeDisplayed());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addLetterById() : IApiCallback {
        return {
            callback: this.letterApiCallExecutor.getById,
            pointerToApiCall: this.letterApiCallExecutor,
            variableNameInState: "Letter"
        };
    }
}

export default Details;