import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupMaxSpeedInKmh extends FormGroupBase {
    private ID_AND_NAME = "maxSpeedInKmh";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_MAX_SPEED_IN_KMH,
                    I18nConstants.FORM_MAX_SPEED_IN_KMH_DEFAULT) }

                <Input 
                    type="text"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.maxSpeedInKmh}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue()} />

                { this.renderFormFeedbackWithInsertValidValueMessage() }
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }
}

export default FormGroupMaxSpeedInKmh;