import IModelMapper from '../../../../commons/model/IModelMapper';
import LetterPersonSalutation from '../../../person/salutation/model/LetterPersonSalutation';
import LetterPersonTitle from '../../../person/title/model/LetterPersonTitle';
import LetterAddresseeCompany from './LetterAddresseeCompany';

class LetterAddresseeCompanyModelMapper implements IModelMapper<LetterAddresseeCompany> {
    fromJsonToObject(json: any): LetterAddresseeCompany {
        let letterAddresseeCompany = new LetterAddresseeCompany();

        letterAddresseeCompany.id = json.id;
        letterAddresseeCompany.name = json.name;
        letterAddresseeCompany.department = json.department;
        // Must be done like this, because on UPDATE, it could be that it has
        // a null-value because it isn't a mandatory parameter.
        letterAddresseeCompany.contactSalutation = (json.contactSalutation == null)
                                                    ? new LetterPersonSalutation()
                                                    : json.contactSalutation;
        letterAddresseeCompany.contactTitle = (json.contactTitle == null)
                                               ? new LetterPersonTitle()
                                               : json.contactTitle;
        letterAddresseeCompany.contactFirstName = json.contactFirstName;
        letterAddresseeCompany.contactLastName = json.contactLastName;
        letterAddresseeCompany.street = json.street;
        letterAddresseeCompany.houseNumber = json.houseNumber;
        letterAddresseeCompany.zip = json.zip;
        letterAddresseeCompany.city = json.city;
        letterAddresseeCompany.postOfficeBox = json.postOfficeBox;
        letterAddresseeCompany.country = json.country;

        return letterAddresseeCompany;
    }

    fromObjectToJson(object: LetterAddresseeCompany) {
        return {
            id: object.id,
            name: (object.name || ""),
            department: (object.department || ""),
            contactSalutation: (object.contactSalutation || undefined),
            contactTitle: (object.contactTitle || undefined),
            contactFirstName: (object.contactFirstName || ""),
            contactLastName: (object.contactLastName || ""),
            street: (object.street || ""),
            houseNumber: (object.houseNumber || ""),
            zip: (object.zip || ""),
            city: (object.city || ""),
            postOfficeBox: (object.postOfficeBox || ""),
            country: (object.country || undefined)
        };
    }
}

export default LetterAddresseeCompanyModelMapper;