import React from 'react';
import { Component } from 'react';

import IApiCallback from '../../../../../commons/ui/page/apiCallExecutor/IApiCallback';
import ApiCallExecutorHelper from '../../../../../commons/ui/page/apiCallExecutor/ApiCallExecutorHelper';
import VehicleApiCallExecutor from '../../api/VehicleApiCallExecutor';
import VehicleTypeApiCallExecutor from '../../../type/api/VehicleTypeApiCallExecutor';
import FuelUnitApiCallExecutor from '../../../fuelUnit/api/FuelUnitApiCallExecutor';
import KindOfFuelApiCallExecutor from '../../../kindOfFuel/api/KindOfFuelApiCallExecutor';

import Utils from '../../../../../utilities/Utils';

import Vehicle from '../../model/Vehicle';
import VehicleApi from '../../api/VehicleApi';
import VehicleUrlPath from '../VehicleUrlPath';

import VehicleType from "../../../type/model/VehicleType";
import FuelUnit from "../../../fuelUnit/model/FuelUnit";
import KindOfFuel from "../../../kindOfFuel/model/KindOfFuel";

import UpdateUtils from '../../../../../commons/ui/page/template/update/UpdateUtils';

import Form from './Form';

interface IProps {
    history:  any;
    location: any;
}

interface IState {
    isLoading:    boolean;
    wasHandleSubmitClickedAtLeastOnce:  boolean;
    Item:         Vehicle;
    VehicleTypes: VehicleType[];
    FuelUnits:    FuelUnit[];
    KindOfFuels:  KindOfFuel[];
}

class Update extends Component<IProps, IState> {
    private apiCalls:    ApiCallExecutor;
    private api:     VehicleApi;
    private form:        Form;
    private updateUtils: UpdateUtils;

    private idOfItemToBeUpdated: number;

    public constructor(props) {
        super(props);

        this.apiCalls = new ApiCallExecutor(this);

        this.api = VehicleApi.getInstance();

        this.idOfItemToBeUpdated =
            Utils.getIdFromGetParameters(props.location.search);

        this.state = {
            isLoading:    true,
            wasHandleSubmitClickedAtLeastOnce:  false,
            Item:         new Vehicle(),
            VehicleTypes: [],
            FuelUnits:    [],
            KindOfFuels:  []
        };

        this.form = new Form(props, this);

        this.updateUtils = new UpdateUtils(
            this,
            this.api,
            this.form,
            this.getHeading(),
            VehicleUrlPath.BASE);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return this.updateUtils.render();
    }

    private getHeading() {
        return(
            <h1>

            </h1>) ;
    }

    public async componentDidMount() {
        this.apiCalls.executeAll();
    }

    // --------------------------------------------------------------------------------------------

    public isFormValid(): boolean {
        return this.updateUtils.isFormValid();
    }

    public isInvalidValue(valueToBeChecked) : boolean {
        return this.updateUtils.isInvalidValue(valueToBeChecked);
    }

    private handleChange(event) {
        this.updateUtils.handleChange(event);
    }

    private handleSubmit(event) {
        this.updateUtils.handleSubmit(event);
    }

    // --------------------------------------------------------------------------------------------

    public getIdToBeUpdated() {
        return this.idOfItemToBeUpdated;
    }
}

// ================================================================================================

class ApiCallExecutor {
    private pointerToComponent: Update;

    private helper: ApiCallExecutorHelper;

    private vehicleApiCallExecutor: VehicleApiCallExecutor<Update>;
    private vehicleTypeApiCallExecutor: VehicleTypeApiCallExecutor<Update>;
    private fuelUnitApiCallExecutor: FuelUnitApiCallExecutor<Update>;
    private kindOfFuelApiCallExecutor: KindOfFuelApiCallExecutor<Update>;

    public constructor(pointerToComponent) {
        this.pointerToComponent = pointerToComponent;

        this.helper = new ApiCallExecutorHelper();

        this.vehicleApiCallExecutor = new VehicleApiCallExecutor(pointerToComponent);
        this.vehicleTypeApiCallExecutor = new VehicleTypeApiCallExecutor(pointerToComponent);
        this.fuelUnitApiCallExecutor = new FuelUnitApiCallExecutor(pointerToComponent);
        this.kindOfFuelApiCallExecutor = new KindOfFuelApiCallExecutor(pointerToComponent);
    }

    // --------------------------------------------------------------------------------------------

    public executeAll() {
        let parameters = Array();
        let orderOfCalls : Array<IApiCallback> = Array();

        orderOfCalls.push(this.addVehicleGetById());
        orderOfCalls.push(this.addVehicleTypeGetAll());
        orderOfCalls.push(this.addFuelUnitGetAll());
        orderOfCalls.push(this.addKindOfFuelGetAll());

        let context = this.helper.createContext(
                                    this.pointerToComponent,
                                    this.helper, 
                                    orderOfCalls, 
                                    parameters);

        this.helper.addParameterToContext(context, "vehicleId", 
                                          this.pointerToComponent.getIdToBeUpdated());

        this.helper.startWithFirstCall(context);
    }

    // --------------------------------------------------------------------------------------------

    private addVehicleGetById() : IApiCallback {
        return {
            callback: this.vehicleApiCallExecutor.getById,
            pointerToApiCall: this.vehicleApiCallExecutor,
            variableNameInState: "Item"
        };
    }

    private addVehicleTypeGetAll() : IApiCallback {
        return {
            callback: this.vehicleTypeApiCallExecutor.getAll,
            pointerToApiCall: this.vehicleTypeApiCallExecutor,
            variableNameInState: "VehicleTypes"
        };
    }

    private addFuelUnitGetAll() : IApiCallback {
        return {
            callback: this.fuelUnitApiCallExecutor.getAll,
            pointerToApiCall: this.fuelUnitApiCallExecutor,
            variableNameInState: "FuelUnits"
        };
    }

    private addKindOfFuelGetAll() : IApiCallback {
        return {
            callback: this.kindOfFuelApiCallExecutor.getAll,
            pointerToApiCall: this.kindOfFuelApiCallExecutor,
            variableNameInState: "KindOfFuels"
        };
    }
}

export default Update;