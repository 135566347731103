import IGenericApi from '../../../commons/api/IGenericApi';
import GenericApi from '../../../commons/api/GenericApi';
import CountryModelMapper from '../model/CountryModelMapper';

class CountryApi {
    private static instance: CountryApi;

    private static apiUrlPath = "general/country?languageId=1";

    private constructor() {
        // Singleton, so the constructor can't be called!
    }

    public static getInstance(): CountryApi {
        if (!CountryApi.instance) {
            CountryApi.instance = new CountryApi();
        }

        return CountryApi.instance;
    }

    public getAll() {
        return GenericApi.executeGetAllCall(
                    CountryApi.apiUrlPath,
                    new CountryModelMapper()
        );
    }

    public getById(id) {
        return GenericApi.executeGetCall(
                    CountryApi.apiUrlPath,
                    id,
                    new CountryModelMapper()
        );
    }

    public getUrl() {
        return CountryApi.apiUrlPath;
    }
}

export default CountryApi;