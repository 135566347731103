import React from 'react';
import FormGroupBikeType from '../fragment/FormGroupBikeType';
import FormGroupBrand from '../fragment/FormGroupBrand';
import FormGroupModel from '../fragment/FormGroupModel';
import FormGroupIdentificationNumber from '../fragment/FormGroupIdentificationNumber';
import FormGroupDescription from '../fragment/FormGroupDescription';

class Form {
    private formGroupBikeType: FormGroupBikeType;
    private formGroupBrand: FormGroupBrand;
    private formGroupModel: FormGroupModel;
    private formGroupIdentificationNumber: FormGroupIdentificationNumber;
    private formGroupDescription: FormGroupDescription;

    public constructor(props, pointerToComponent) {
        this.formGroupBikeType = new FormGroupBikeType(props, pointerToComponent);
        this.formGroupBrand = new FormGroupBrand(props, pointerToComponent);
        this.formGroupModel = new FormGroupModel(props, pointerToComponent);
        this.formGroupIdentificationNumber = new FormGroupIdentificationNumber(props, pointerToComponent);
        this.formGroupDescription = new FormGroupDescription(props, pointerToComponent);
    }

    public getFormGroupContent(renderButtonCallback) {
        return(
            <div>
                { this.formGroupBikeType.render() }
                { this.formGroupBrand.render() }
                { this.formGroupModel.render() }
                { this.formGroupIdentificationNumber.render() }
                { this.formGroupDescription.render() }

                { renderButtonCallback() }
            </div>
        );
    }

    public isFormValid(): boolean {
        if (    this.formGroupBikeType.isInvalidValue()
             || this.formGroupBrand.isInvalidValue()
             || this.formGroupModel.isInvalidValue()
             || this.formGroupIdentificationNumber.isInvalidValue()
             || this.formGroupDescription.isInvalidValue()) {
            return false;
        } else {
            return true;
        }
    }
}

export default Form;