import React from 'react';
import { RadialChart } from 'react-vis';
import IApiCallContext from '../apiCallExecutor/IApiCallContext';
import Utils from '../../../../utilities/Utils';

class PieChartWithSumAndDiagram {
    private static WIDTH = 300;
    private static HEIGHT = 300;

    public getHtmlElement(data) {
        return (
            <RadialChart
                data={data}
                width={PieChartWithSumAndDiagram.WIDTH}
                height={PieChartWithSumAndDiagram.HEIGHT}
                showLabels={true} /> 
        );
    }

    public updateDiagramData(context: IApiCallContext) {
        let sum = 0;
        let percent = 0;

        const successorCallback = context.pointerToHelper.getCurrentSuccessorAndRemoveFromList(context);

        const listData = (context.pointerToHelper.getParameterInArrayWithObjects(
                                context.parameters, "listDataForDiagram") as any);

        listData.forEach( item => {
            sum += item.sum;
        });

        const diagramData = listData.map( item => {
            percent = item.sum * 100 / sum;
            percent = Utils.roundTwoDecimalPlaces(percent);

            return { 
                angle: item.sum,
                label: (item.name + " [" + percent + " %]") 
            };
        });

        const state = context.pointerToHelper.createStateObject(
                        context.currentData.variableNameInState,
                        diagramData);

        context.pointerToComponent.setState(state);

        try {
            successorCallback.callback(context);
        } catch (error) {
            context.pointerToHelper.setIsLoadingToFalseIfNecessary(error, context);
        }
    }
}

export default PieChartWithSumAndDiagram;