import I18nConstantsBase from './I18nConstantsBase';
import DefaultJson from './en/common.json';

class I18nConstants extends I18nConstantsBase {
    public static NS = "commons";

    public static APP_TITLE = "appTitle";

    public static LOADING_SCREEN_MESSAGE = "loadingScreenMessage";

    public static FORM_NAME = "form.name";

    public static FORM_GROUP_TITLE = "form.group.title";
    public static FORM_GROUP_ADD = "form.group.add";
    public static FORM_GROUP_UPDATE = "form.group.update";
    public static FORM_GROUP_DELETE = "form.group.delete";

    // --------------------------------------------------------------------------------------------

    public static APP_TITLE_DEFAULT = DefaultJson.appTitle;

    public static LOADING_SCREEN_MESSAGE_DEFAULT = DefaultJson.loadingScreenMessage;

    public static FORM_NAME_DEFAULT = DefaultJson.form.name;

    public static FORM_GROUP_TITLE_DEFAULT = DefaultJson.form.group.title;
    public static FORM_GROUP_ADD_DEFAULT = DefaultJson.form.group.add;
    public static FORM_GROUP_UPDATE_DEFAULT = DefaultJson.form.group.update;
    public static FORM_GROUP_DELETE_DEFAULT = DefaultJson.form.group.delete;
}

export default I18nConstants;