import IModelMapper from '../../../../commons/model/IModelMapper';

import FuelUnit from './FuelUnit';

class FuelUnitModelMapper implements IModelMapper<FuelUnit> {
    fromJsonToObject(json: any): FuelUnit {
        let fuelUnit = new FuelUnit();

        fuelUnit.id   = json.id;
        fuelUnit.name = json.name;

        return fuelUnit;
    }

    fromObjectToJson(object: FuelUnit) {
        return {
            id: object.id,
            name: (object.name || "") };
    }
}

export default FuelUnitModelMapper;