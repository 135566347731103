import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import I18nConstants from '../../i18n/I18nConstants';
import FormGroupBase from '../../../../commons/ui/page/template/form/FormGroupBase';

class FormGroupBikeDropdown extends FormGroupBase {
    private ID_AND_NAME = "bike";

    public constructor(props, pointerToComponent) {
        super(props, pointerToComponent);
    }

    public render() {
        const bikes = this.getBikesSelectValues();

        return (
            <FormGroup>
                { this.renderLabelForInput(
                    this.ID_AND_NAME, 
                    I18nConstants.NS,
                    I18nConstants.FORM_BIKE,
                    I18nConstants.FORM_BIKE_DEFAULT) }

                <Input
                    type="select"
                    name={this.ID_AND_NAME}
                    id={this.ID_AND_NAME}
                    value={this.pointerToComponent.state.Item.bike.id}
                    onChange={this.pointerToComponent.handleChange}
                    invalid={this.isInvalidValue}>
                    {bikes}
                </Input>
            </FormGroup>
        );
    }

    public isInvalidValue(): boolean {
        return false;
    }

    protected getBikesSelectValues() {
        const defaultValue = this.getDefaultSelectValue();

        const { Bikes } = this.pointerToComponent.state;

        let values = Bikes.map( bike => 
            <option value={bike.id}>
                {(bike as any).brand}&nbsp;
                {(bike as any).model}&nbsp;
            </option>    
        );

        values.unshift(defaultValue);

        return values;
    }
}

export default FormGroupBikeDropdown;